import React, { useState, useEffect, useMemo } from "react";
import useGlobal from "../../../GlobalState/Store/Store.js";
import useHover from "../../../Utils/useHover.js";

import { Typography } from "@mui/material";

import { ReactComponent as PushPin } from "../../../Assets/pushpin.svg";

import { useDrag } from "react-dnd";
import ItemTypes from "../../DND/ItemTypes";
import { getEmptyImage } from "react-dnd-html5-backend";

import defaultFavIconLight from "../../../Assets/defaultFaviconLight.svg";
import defaultFavIconDark from "../../../Assets/defaultFaviconDark.svg";
import useDarkMode from "../../../Utils/theme/useDarkMode";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

import { useTheme } from "@mui/material/styles";

function CollectionTab(props) {
  const [globalState, globalActions] = useGlobal();
  const { isDarkMode } = useDarkMode("light");

  // const collection = props.collection
  // const tab = props.tab
  // const index = props.index
  // const previewMax = props.previewMax

  const { collectionInSync, collectionCurrentlyOpen, collection, tab, index, previewMax } = props;

  const theme = useTheme();

  const [hoverRef, hover] = useHover();

  const FaviconURL =
    tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
      ? ""
      : tab.favIconUrl.replace(/^http:\/\//, "https://");

  function extractHostname(url) {
    var hostname = url;
    if (url && url !== undefined) {
      var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false;

      if (isUrl) {
        //---- OLD FULL REMOVAL METHOD ----
        //find & remove protocol (http, ftp, etc.) and get hostname
        // if (url.indexOf("//") > -1) {
        //   hostname = url.split('/')[2];
        // }
        // else {
        //   //console.log("found / ", hostname)
        //   hostname = url.split('/')[0];
        // }
        // //removes a remaing http or www.
        // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
        //---------------------//
        if (url.indexOf("https://") > -1) {
          hostname = url.split("https://")[1];
        } else if (url.indexOf("http://") > -1) {
          hostname = url.split("http://")[1];
        } else {
          hostname = url;
        }
        // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
        if (hostname) {
          hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""); //.split('/')[0];
        }
      } else {
        return url;
      }
    }
    return hostname;
  }

  const tabURL = useMemo(() => extractHostname(tab?.url), [tab.url]);

  const widthRef = React.useRef(null);

  function OpenTab(e) {
    e.preventDefault();
    e.stopPropagation();
    //console.log(e)

    let shiftKey = e.shiftKey;
    let metaKey = e.metaKey || e.ctrlKey;
    if (collectionCurrentlyOpen) {
      switchToTab(e);
    } else if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({ type: "openTab", tab: tab, shiftKey: shiftKey, metaKey: metaKey });
    } else {
      window.open(tab.url);
    }

    //if(!shiftKey) setHover(false)
  }
  const handleMiddleClicks = (event) => {
    //console.log(event.button)
    if (collectionCurrentlyOpen) {
      switchToTab(event);
    } else if (event.button === 1) {
      // do something on middle mouse button click
      event.metaKey = true;
      OpenTab(event);
    }
  };
  const [, drag, preview] = useDrag({
    item: {
      type: ItemTypes.COLLECTIONTAB,
      tab: tab,
      tabWidthRef: widthRef, //not use widthRef anymore, just using this
      collectionId: collection._id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    let isTabMounted = true;
    if (isTabMounted) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
    return () => {
      //cleanup
      preview(null);
      isTabMounted = false;
    };
  }, []);
  //drag(hoverRef) //<-- was commented out before addign cleanup below

  useEffect(() => {
    return () => {
      //cleanup
      drag(null);
    };
  }, [drag]);

  //OPENS the tab in the current window if the collection is open
  const windowId = collectionInSync?.windowId || null;

  function switchToTab(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log({ collectionInSync, tab });
    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({ type: "switchToTab", tab: { url: tab.url }, parentWindow: { id: windowId } });
    }
  }

  function mergeRefs(refs) {
    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      });
    };
  }

  return (
    <div ref={mergeRefs([hoverRef, widthRef])} style={{ flexGrow: 1 }}>
      <div
        onClick={OpenTab}
        onMouseDown={handleMiddleClicks}
        ref={drag}
        style={{
          // width: '100%',
          flexGrow: 1,
          border: `1px solid ${theme.palette.divider}`,
          // border: `1px solid ${theme.palette.divider}`,
          backgroundColor: hover ? theme.palette.collection.hover : theme.palette.background.paper,
          boxShadow: theme.palette.shadows.small,
          borderRadius: 4,
          marginBottom: index + 1 === props.allTabs.length && previewMax !== "max" ? "25px" : null, //&& props.allTabs.length > 3
          cursor: "pointer",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 4,
          paddingBottom: 4,
          marginTop: 6,
        }}
      >
        {tab.pinned ? (
          <div
            style={{
              width: "16px",
              height: "16px",
              marginRight: "5px",
              color: "#3D5AFE",
              backgroundColor: "rgba(61, 90, 254, 0.1)",
              borderRadius: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: "14px",
              top: "2px",
            }}
          >
            <PushPin style={{ height: "14px", fill: "#3D5AFE" }} />
          </div>
        ) : null}
        {FaviconURL !== "" ? (
          <img
            color={theme.palette.text.secondary}
            fill={theme.palette.text.secondary}
            height={props.in == "collection" ? 20 : 24}
            mode="fit"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              isDarkMode ? (currentTarget.src = defaultFavIconDark) : (currentTarget.src = defaultFavIconLight);
            }}
            src={FaviconURL}
            style={{ minWidth: props.in == "collection" ? 20 : 24, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
            width={props.in == "collection" ? 20 : 24}
          />
        ) : (
          <LanguageRoundedIcon sx={{ color: "text.secondary", width: props.in == "collection" ? 20 : 24, height: props.in == "collection" ? 20 : 24 }} />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "flex-start",
            marginLeft: "10px",
            width: "88%",
          }}
        >
          <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
            <Typography
              m={0}
              noWrap
              style={{
                fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
                color: theme.palette.text.primary,
                margin: 0,
                fontSize: 12,
                marginBottom: -8,
              }}
              variant="subtitle2"
            >
              {tab.title}
            </Typography>
            <Typography
              m={0}
              noWrap
              style={{
                color: theme.palette.text.secondary,
                margin: 0,
                fontSize: 11,
                fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
              }}
              variant="caption"
            >
              {tabURL}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionTab;
