/*global chrome*/
import React, { useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";

import { Drawer, ClickAwayListener, Paper, Typography, Divider, IconButton, Link, Button } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TabIcon from "@mui/icons-material/Tab";

import CurWindowComponent from "../../Components/CurWindowComponent.js";
import ShadowScrollbars from "../../Components/ShadowScrollbars.js";

import NoTabsOpen from "../../Assets/NoOpenTabs.png";
import useGlobal from "../../GlobalState/Store/Store";

import Skeleton from "@mui/material/Skeleton";

import RegularSnackbar from "../../Components/Snackbars/RegularSnackbar.js";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import CustomTabDragLayer from "../../Components/DND/CustomTabDragLayer.js";

//FOR browser extension APIs

const drawerWidth = 300;

export default function CurrentTabsMenu(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const open = props.open;
  const toggleMenu = props.toggleMenu;
  let isComponentMounted = true;
  //const [windows, setWindows] = useState(globalState.browserWindows)
  const windows = globalState.browserWindows;
  const [options, setOptions] = useState({});
  const [menuTab, setMenuTab] = useState(0);

  const [expanded, setExpanded] = React.useState([]);
  const pathnames = props.pathnames;

  var subscription = globalState.userTrial.subscription;
  //When the device limit diaglor appears everything should be disabled

  //for history panels
  const handleExpand = (panel) => () => {
    setExpanded(
      expanded.map((p) => {
        if (p.id === panel.id) {
          return { ...panel, expanded: panel.expanded };
        } else {
          return p;
        }
      })
    );
  };

  //clear selected on open/close
  useEffect(() => {
    if (isComponentMounted) {
      //console.log("browser storage was changed")
      props.clearSelectedTabs();
    }
    // cancel subscription to useEffect
    return () => (isComponentMounted = false);
  }, [open]);

  const handleChangeTab = (event, newValue) => {
    setMenuTab(newValue);
    props.clearSelectedTabs();
  };

  //   var tabstosend = []
  //   //only perform update once you get the current tabs
  //   if(process.env.NODE_ENV !== 'development') {
  //     browser.tabs.query({}, function(tabs){
  //       tabstosend = tabs
  //       //updated current tabs now perform request
  //       var now = new Date()
  //       var dateTime = now.toString()
  //       var fields = dateTime.split("GMT")
  //       var readableString = fields[0]
  //       var dateTimeString = readableString.slice(0, readableString.length - 4)
  //       var curatedName = "Tabs from " + dateTimeString
  //
  //       const data = {
  //         name: curatedName,
  //         workspaceId: globalState.activeWorkspace._id,
  //         tabs: tabstosend,
  //         method: "Create new with all tabs button"
  //       }
  //
  //       axios
  //         .post("/collections/create", data)
  //         .then(res => {
  //           //console.log("Created Workspace")
  //           GetCollectionsbyId(globalState.activeWorkspace)
  //         })
  //         .catch(err => {
  //           console.log(err)
  //           if(err.code ===206){
  //             //globalActions.setGlobalSnackbarOpen(true)
  //             globalActions.setGlobalSnackbarState({
  //               open: true,
  //               status: "error",
  //               message: "🛑 Cannot save the new tab page or duplicates ",
  //               closeTime: 4500
  //             })
  //           }
  //         });
  //     })
  //
  //   }
  // }

  //Multisewlect and drag
  var selectedTabs = props.selectedTabs;
  var setSelectedTabs = props.setSelectedTabs;
  //The selected tabs state is held in maincontainer so that functions like {reset} can be passed to other components outside of the current tabs menu
  const SelectTabs = (tab, type, parentWindow, shiftKey, commandKey) => {
    // let selectedCardsIds = [];
    // let activeCardId;
    //console.log(tab)
    if (type === "pastTab") {
      parentWindow.tabs.forEach((t) => {
        if (t.sessionId) {
          t.id = t.sessionId + t.index;
          //console.log("set past tab id to " + t.id)
        } else {
          t.id = t.windowId + t.index;
          //console.log("set past tab id to " + t.id)
        }
      });
    }
    //console.log(tab.id, parentWindow, {shiftKey, commandKey})
    //console.log({selectedTabs})

    let selected = selectedTabs.slice();
    //selectedTabs.forEach(t => console.log(tab.id))
    //let selectedTabIds = selectedTabs.map(t => tab.id)

    if (commandKey && shiftKey) {
      if (selected.findIndex((t) => t.id === tab.id) > -1) {
        //deselect the tab
        selected = selected.filter((t) => t.id !== tab.id);
      } else {
        //select the tab if not yet selected
        selected = [...selected, tab];
      }
    } else if (commandKey) {
      if (selected.findIndex((t) => t.id === tab.id) > -1) {
        //deselect the tab
        selected = [];
      } else {
        //select the tab if not yet selected
        selected = [tab];
      }
    } else if (shiftKey) {
      //console.log("Shift key true, multi select")
      //const curWindow = windows.find(w => w.id === parentWindow.id)
      let lastSelected = selected[selected.length - 1];
      if (lastSelected) {
        const activeTabIndex = parentWindow.tabs.findIndex((t) => t.id === lastSelected.id);
        const currentTabIndex = parentWindow.tabs.findIndex((t) => t.id === tab.id);
        const lowerIndex = Math.min(activeTabIndex, currentTabIndex);
        const upperIndex = Math.max(activeTabIndex, currentTabIndex);

        //there exists a previously selected tab, proceed to select multiple
        //console.log({activeTabIndex, currentTabIndex, lowerIndex, upperIndex})
        selected = parentWindow.tabs.slice(lowerIndex, upperIndex + 1); //.map(c => c.id);
      } else {
        selected = [...selected, tab];
      }
    }

    //const updated = selected.filter(t => selectedTabIds.includes(t.id))
    const updated = selected;
    //console.log({updated})
    setSelectedTabs(updated);
  };

  const handleClickInMenu = () => {
    //console.log(event.target)
    props.clearSelectedTabs();
  };
  const handleClickOutsideWindow = (event) => {
    //console.log("CLICK OUTSIDE")
    var shiftKey = event.shiftKey;
    var commandKey = event.ctrlKey || event.metaKey ? true : false;

    if (selectedTabs && selectedTabs.length > 0 && !shiftKey && !commandKey) props.clearSelectedTabs();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CustomTabDragLayer />
      <Drawer
        anchor="right"
        id="currentTabsDrawer"
        onClick={handleClickInMenu}
        style={{
          zIndex: 100,
          width: 300,
          right: open ? null : -20,
          // transition: theme.transitions.create('width', {
          //   easing: theme.transitions.easing.sharp,
          //   duration: theme.transitions.duration.enteringScreen,
          // }),
          background: theme.palette.background.default,
          flexShrink: 0,
          whiteSpace: "nowrap",
          overflowX: "hidden",
        }}
        sx={{
          ".MuiDrawer-paper": {
            zIndex: 100,
            width: open ? drawerWidth : 0,
            right: open ? null : -20,
            // transition: theme.transitions.create('width', {
            //   easing: theme.transitions.easing.sharp,
            //   duration: theme.transitions.duration.enteringScreen,
            // }),
            background: theme.palette.background.default,
            flexShrink: 0,
            whiteSpace: "nowrap",
            overflowX: "hidden",
          },
        }}
        variant="permanent"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "75px",
            backgroundColor: theme.palette.background.sidebar,
            paddingTop: "1px",
          }}
        >
          <Paper square style={{ width: "100%", maxWidth: "100%", height: "100%", backgroundColor: theme.palette.background.sidebar, zIndex: 999 }}>
            <IconButton
              onClick={toggleMenu}
              size="small"
              sx={{
                position: "absolute",
                top: 3,
                left: 3,
                zIndex: 9999,
              }}
            >
              {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            <Button
              onClick={(e) => handleChangeTab(e, 0)}
              startIcon={<TabIcon />}
              style={{
                height: "100%",
                width: "100%",
                color: menuTab === 0 ? "#3d5AFE" : theme.palette.text.secondary,
                borderRadius: "0px",
                borderBottom: `1px solid ${theme.palette.divider}`,
                // borderBottom: "1px solid",
                // borderColor: menuTab === 0 ? "#3d5AFE" : theme.palette.divider,
                paddingBottom: "0px",
                paddingTop: "25px",
                backgroundColor: "transparent",
              }}
            >
              Current Tabs
            </Button>
          </Paper>
        </div>
        {open ? (
          <>
            <ShadowScrollbars style={{ height: menuTab === 1 ? "100%" : pathnames.length >= 2 ? "calc(100% - 150px)" : "calc(100% - 10px)" }}>
              <ClickAwayListener onClickAway={(e) => handleClickOutsideWindow(e)}>
                {menuTab === 0 ? ( // on current tabs tab
                  <div style={{ padding: 16 }}>
                    {windows ? (
                      windows.length > 0 ? (
                        windows.map((window, index) => {
                          if (window.tabs && window.tabs.length > 0)
                            return (
                              <CurWindowComponent
                                SelectTabs={SelectTabs}
                                allWindows={windows}
                                clearSelectedTabs={props.clearSelectedTabs}
                                index={index}
                                key={index}
                                selectedTabs={selectedTabs}
                                window={window}
                              />
                            );
                          else return null;
                        })
                      ) : (
                        <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                          <img alt="no tabs open" src={NoTabsOpen} style={{ width: "200px" }} />
                        </div>
                      )
                    ) : (
                      <div style={{ fontSize: 20, lineHeight: 1.5 }}>
                        <Skeleton height={150} width={290} />
                        <div style={{ width: "5px", marginBottom: "5px" }} />
                      </div>
                    )}
                  </div>
                ) : null}
              </ClickAwayListener>
            </ShadowScrollbars>
            <RegularSnackbar />
          </>
        ) : null}
      </Drawer>
    </DndProvider>
  );
}
