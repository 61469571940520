import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Paper,
  InputBase,
  Typography,

} from '@mui/material';

export default function EmojiSearchbar(props) {
  const searchFieldRef = props.searchFieldRef
  const theme = useTheme();

  const [externalFocus, setExternalFocus] = useState(false)
  const [searchHover, setSearchHover] = useState(false)

  const { searchInput, setSearchInput } = props

  const handleChangeSearch = (event) => {
    if(event && event.target) setSearchInput(event.target.value)
  }
  const focusSearch = () => {
    if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
  }

  let reset = props.reset
  if(reset) setSearchInput('')

  const handlePreventHotKey = props.handlePreventHotKey

  return (
      <Paper
          elevation={0}
          onChange={props.onChange}
          onClick={focusSearch}

          onMouseEnter={() => {setSearchHover(true)}}
          onMouseLeave={() => {setSearchHover(false)}}

          style={{
            backgroundColor: externalFocus ? theme.palette.divider : searchHover ? theme.palette.action.selected : theme.palette.action.hover,
            transition: 'background-color 0.25s ease',
            width: "100%",
            paddingBottom: 6,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
          //   alignItems: 'center',
            //opacity: externalFocus ? 1 : 0.25,
          }}
          // sx={{
          //   padding: '8px 4px 8px 2px',
          //   display: 'flex',
          //   alignItems: 'center',
          //   backgroundColor: 'action.hover',
          //   '&:hover': {
          //     backgroundColor: 'action.selected'
          //   },
          // }}
          
      >
          <InputBase
              autoFocus={props.shouldAutoFocus == false ? false : true}
              fullWidth
              inputProps={{ 'aria-label': 'partizion extension search' }}
              inputRef={searchFieldRef}
              onBlur={() => {
          setExternalFocus(false)
        }}
              onChange={handleChangeSearch}
              onFocus={() => {

          setExternalFocus(true)
        }}
              onKeyDown={handlePreventHotKey}
              placeholder="Find an Emoji"
              style={{
                flex: 1,
                fontSize: 14,
                height: 20
              }}

              value={searchInput}
          />
          {
        searchInput && searchInput.length > 0 && (
        <Typography
            onClick={() => props.clearSearch()}
            style={{fontSize: 10, color: theme.palette.text.secondary, backgroundColor: theme.palette.action.hover, paddingTop: '1px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '2px', marginRight: 6, cursor: 'pointer'}}
            variant="caption"
        >
            ESC to clear
        </Typography>
        )
      }
      </Paper>
  );
}