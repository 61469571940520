import React, {useEffect, useState} from 'react';


import {
  Paper,
  InputBase,
  IconButton,
  Typography,

} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

export default function AddTabsSearchBar(props) {
  const searchFieldRef = props.searchFieldRef

  const [externalFocus, setExternalFocus] = useState(false)
  const [searchHover, setSearchHover] = useState(false)

  const { searchInput, setSearchInput } = props

  const handleChangeSearch = (event) => {
    if(event && event.target) setSearchInput(event.target.value)
  }
  const focusSearch = () => {
    if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
  }

  let reset = props.reset
  if(reset) setSearchInput('')

  const handlePreventHotKey = props.handlePreventHotKey

  return (
      <Paper
          elevation={0}
          onChange={props.onChange}
          onClick={focusSearch}

          onMouseEnter={() => {setSearchHover(true)}}
          onMouseLeave={() => {setSearchHover(false)}}

          style={{
            backgroundColor: externalFocus ? 'rgba(196, 196, 196, 0.25)' : searchHover ? 'rgba(196, 196, 196, 0.18)' : 'rgba(196, 196, 196, 0.1)',
            transition: 'background-color 0.25s ease',
            width: "100%",
            //opacity: externalFocus ? 1 : 0.25,
          }}
          sx={{
            padding: '8px 4px 8px 2px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'action.hover',
            '&:hover': {
              backgroundColor: 'action.selected'
            },
          }}
      >
          <IconButton
              aria-label="search"
              size="large"
              style={{padding: 4}}
          >
              <SearchIcon style={{fontSize: 18, opacity: externalFocus ? 1 : 0.5,}} />
          </IconButton>
          <InputBase
              autoFocus={props.shouldAutoFocus == false ? false : true}
              fullWidth
              inputProps={{ 'aria-label': 'partizion extension search' }}
              inputRef={searchFieldRef}
              onBlur={() => {
        setExternalFocus(false)
      }}
              onChange={handleChangeSearch}
              onFocus={() => {

        setExternalFocus(true)
      }}
              onKeyDown={handlePreventHotKey}
              placeholder="Filter tabs"
              style={{
                flex: 1,
                fontSize: 14,
                height: 24
              }}

              value={searchInput}
          />
          {
      searchInput && searchInput.length > 0 && (
      <Typography
          style={{fontSize: 10, color: '#6E6E6E', backgroundColor: 'rgba(196, 196, 196, 0.25', paddingTop: '1px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '2px', marginRight: 8}}
          variant="caption"
      >
          ESC to clear
      </Typography>
      )
    }
      </Paper>
  );
}
