import React, { useState} from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Paper,
  InputBase,
  IconButton,
  Typography,

} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar(props) {
  const searchFieldRef = props.searchFieldRef
  const filterSelected = props.filterSelected
  const theme = useTheme();
  

  const [externalFocus, setExternalFocus] = useState(false)
  const [searchHover, setSearchHover] = useState(false)

  const { searchInput, setSearchInput } = props

  const handleChangeSearch = (event) => {
    if(event && event.target) setSearchInput(event.target.value)
  }
  const focusSearch = () => {
    if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
  }

  let reset = props.reset
  if(reset) setSearchInput('')

  const handlePreventHotKey = props.handlePreventHotKey

  return (
      <Paper
          elevation={0}
          onChange={props.onChange}
          onClick={focusSearch}

          onMouseEnter={() => {setSearchHover(true)}}
          onMouseLeave={() => {setSearchHover(false)}}
          style={{
            backgroundColor: externalFocus ? theme.palette.divider : searchHover ? theme.palette.collection.bgDark : theme.palette.collection.hover,
            transition: 'background-color 0.25s ease',
            width: "100%",
            paddingBottom: 6,
            paddingTop: 8,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            alignItems: 'center',
            //opacity: externalFocus ? 1 : 0.25,
          }}
      >
          <IconButton
              aria-label="search"
              size="large"
              style={{padding: 4,}}
          >
              <SearchIcon style={{fontSize: 18, opacity: externalFocus ? 1 : 0.5,}} />
          </IconButton>
          <InputBase
              autoFocus={props.shouldAutoFocus == false ? false : true}
              fullWidth
              inputProps={{ 'aria-label': 'partizion extension search' }}
              inputRef={searchFieldRef}
              onBlur={() => {
        setExternalFocus(false)
      }}
              onChange={handleChangeSearch}
              onFocus={() => {

        setExternalFocus(true)
      }}
              onKeyDown={handlePreventHotKey}
              placeholder={filterSelected && filterSelected.length == 1 && filterSelected.indexOf(1) > -1 ? "Search Collections" : filterSelected && filterSelected.length == 1 && filterSelected.indexOf(2) > -1 ? "Search Tabs" : filterSelected && filterSelected.length == 1 && filterSelected.indexOf(3) > -1 ? "Search Tabs" : "Search anything..."}
              style={{
                flex: 1,
                fontSize: 14,
                height: 24
              }}

              value={searchInput}
          />
          {
      searchInput && searchInput.length > 0 && (
      <div style={{
          backgroundColor: theme.palette.action.hover, paddingTop: '1px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '2px', marginRight: 6, cursor: 'pointer',
         
        }}
      >
          <Typography
              onClick={() => props.clearSearch()}
              style={{display: 'block', fontSize: 10, color: theme.palette.text.secondary}}
              variant="caption"
          >
              ESC to clear
          </Typography>
      </div>
      )
    }
      </Paper>
  );
}
