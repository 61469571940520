/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import useGlobal from '../../GlobalState/Store/Store.js';
import axios from 'axios'

import {
  Button,
  Popover,
  Paper,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemSecondaryAction,

} from '@mui/material';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

import useWorkspaces from '../../Hooks/useWorkspaces.js'
import { mutate } from 'swr'
import update from 'immutability-helper';

import { styled, useTheme } from '@mui/material/styles';
import { DefaultMenu } from '../Menus/DefaultMenu.js';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));


export default function GroupByButton(props){
  const theme = useTheme()
  const [globalState, globalActions] = useGlobal()
  const { workspacesData } = useWorkspaces(globalState.isAuth, {revalidateOnMount: false})
  
  let isButtonMounted = true //use for cleanup

  //MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : []
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  const possibleProperties = props.possibleProperties

  //set GROUP BY
  const handleChangeGroupBy = (event, property) => {
    event.preventDefault()
    event.stopPropagation()

    if(property){
      const data = {
        viewId: activeView._id,
        kanbanProperties: {
          groupBy: property
        }
      }

      axios
        .post("/views/boardproperties", data)
        .then(res => {
          //console.log(res)
          let view = res.data.view
          globalActions.setActiveView(res.data.view)

          if(view && view._id && view.workspaceId){
            const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex(w => w._id == view.workspaceId) : -1
            const viewIndex = workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views ? workspacesData.workspaces[workspaceIndex].views.findIndex(v => v._id == view._id) : -1
            if(workspaceIndex > -1 && viewIndex > -1){
              const updatedData = update(workspacesData, {
                workspaces: {[workspaceIndex]: {views: {[viewIndex]: {$set: view}}}}
              })
              //console.log(updatedData.workspaces[workspaceIndex])
              mutate(`/workspaces`, updatedData, false)
            }
          }

          handleClose()
        })
        .catch(err => {
          //console.log("Could not update workspace name")
          console.log(err)
        });
    }


  };

  const [customProperties, setCustomProperties] = useState({})




  useEffect(() => {
    
    if(globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0){
      if(isButtonMounted) setCustomProperties(globalState.activeWorkspace.collectionProperties)
    } else {
      if(isButtonMounted) setCustomProperties([])
    }

    return () => { isButtonMounted = false }

  }, [globalState.activeView])


  //EDITING DEFAULT VIEW
  var isEditDefault = !activeView || !views || views && views.length < 1 ? true : false

  const EditDefaultView = (event, property, value) => {
    //console.log({property, value})
    let defaultProperties = {
      size: "medium",
      preview: "tabs",
      showLastUpdated: false,
      showCreatedOn: false,
      showTabCount: true,
    }
    //console.log({property, value})
    let customProps = customProperties
    customProps[property._id] = !value

    if(defaultProperties.hasOwnProperty(property)){
      if(property === "preview"){
        if(value === true){
          defaultProperties["preview"] = "tabs"
        } else {
          defaultProperties["preview"] = "none"
        }
      } else if(property === "size"){
        defaultProperties[property] = value.value
      }
      else {
        defaultProperties[property] = value
      }
    }
    //console.log({defaultProperties})
    //console.log({customProps})

    const viewObj = {
      name: "Grid View",
      viewType: "Grid",
      properties: defaultProperties,
      customProperties: customProps,
      sort: [],
      filter: []
    }

    props.EditDefaultView(viewObj) //handleClose
  }


  let groupBy =
  globalState.activeWorkspace &&  globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0 ?
  globalState.activeView && globalState.activeView.kanbanProperties && globalState.activeView.kanbanProperties.groupBy &&  globalState.activeWorkspace.collectionProperties.findIndex(property => property._id == globalState.activeView.kanbanProperties.groupBy._id) > -1
  && globalState.activeWorkspace.collectionProperties.find(property => property._id == globalState.activeView.kanbanProperties.groupBy._id).type == "singleSelect" ?
  globalState.activeView.kanbanProperties.groupBy
  :
  globalState.activeWorkspace.collectionProperties.find(property => property.type == "singleSelect") ?
  globalState.activeWorkspace.collectionProperties.find(property => property.type == "singleSelect")
  : null
  :
  null


  return (
      <>
          <PlainButton
              
              disableFocusRipple
              disableRipple
              onClick={openMenu}
              style={{marginRight: 8, textOverflow: "ellipsis", overflow: "hidden"}}
          >
              <Typography
                  noWrap
                  style={{maxWidth: 150, textOverflow: "ellipsis", overflow: "hidden",}}
                  variant="button"
              >
                  Group by 
                  {' '}
                  <span style={{color: theme.palette.text.primary}}> 
                      {' '}
                      {groupBy ? groupBy.name : "no group"}
                      {' '}
                  </span>
              </Typography>
          </PlainButton>
          <DefaultMenu
              anchorEl={anchorEl}
              onClose={handleClose}
              open={open}
              style={{zIndex: 999, maxHeight: 500, minWidth: 250, maxWidth: 450, marginTop: 5}}
              transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
          >
              <List
                  dense
                  id="menu-list-grow"
                  style={{textOverflow: "ellipsis", overflow: "hidden",}}
              >
                  <ListItem>
                      <Typography
                          color="textSecondary"
                          variant="caption"
                      >
                          You can create a kanban board and group your collections with 
                          {' '}
                          <strong>
                              {' '}
                              Single Select
                              {' '}
                          </strong>
                          {' '}
                          properties
                      </Typography>
                  </ListItem>
                  <Divider />
                  {
              possibleProperties && possibleProperties.length > 0 ?
              possibleProperties.map((property, index) => (
                  <ListItem
                      button
                      disabled={property._id === groupBy._id}
                      key={index}
                      onClick={(e) => isEditDefault ? EditDefaultView(e, property, customProperties[property._id]) : handleChangeGroupBy(e, property)}

                      property={property}
                      selected={property._id === groupBy._id}
                  >
                      {
                    property.type == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                        size="small"
                        style={{width: 18, color: '#6E6E6E', marginLeft: 4, marginRight: 6}}
                                                      />
                    :
                    property.type == "multiSelect" ? <ListRoundedIcon
                        size="small"
                        style={{width: 18, color: '#6E6E6E', marginLeft: 4, marginRight: 6}}
                                                     />
                    :
                    null
                  }
                      <Typography
                          noWrap
                          style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: '75%'}}
                          variant='body2'
                      >
                          {property.name}
                      </Typography>
                      {
                    property._id === groupBy._id ?
                        <ListItemSecondaryAction>
                            <CheckRoundedIcon style={{width: 18, color: '#6E6E6E', marginTop: 2}} />
                        </ListItemSecondaryAction>
                    :
                    null
                  }

                  </ListItem>
              ))
              :
              null
            }
              </List>
          </DefaultMenu>
      </>
  )
}
