import React, { useState } from "react";
import axios from "axios";
import useGlobal from "../../GlobalState/Store/Store.js";

import { Button, IconButton, Typography, Box, MenuItem, ListItemIcon, Divider, ListItemSecondaryAction, Tooltip } from "@mui/material";

import { DefaultMenu } from "../Menus/DefaultMenu.js";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import ViewDayRoundedIcon from "@mui/icons-material/ViewDayRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Add from "@mui/icons-material/Add";
import MoreVert from "@mui/icons-material/MoreVert";

import CreateViewMenu from "../Menus/CreateViewMenu.js";

import useWorkspaces from "../../Hooks/useWorkspaces.js";
import { mutate } from "swr";
import update from "immutability-helper";

import { useTheme, styled } from "@mui/material/styles";
const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

export default function ViewSelectButton(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();

  const { workspacesData } = useWorkspaces(globalState.isAuth, { revalidateOnMount: false });

  //MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  //VIEWS
  let workspace =
    workspacesData && workspacesData.workspaces && globalState.activeWorkspaceIndex
      ? workspacesData.workspaces[globalState.activeWorkspaceIndex]
      : globalState.activeWorkspace;
  const activeView = globalState.activeView; //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  //console.log({workspace})

  //HELP
  const helpIcon = {
    //url: 'https://www.partizion.io/guides/'
  };

  const [createViewAnchorEl, setCreateViewAnchorEl] = useState(null);
  const [mount, setMount] = useState("bottom");
  const [editView, setEditView] = useState(null);

  const handleOpenCreateView = (event, sideMount, editView) => {
    event.preventDefault();
    event.stopPropagation();

    setCreateViewAnchorEl(event.currentTarget);
    setMount(sideMount);
    setEditView(editView);
  };

  const handleCloseCreateView = () => {
    setCreateViewAnchorEl(null);
    // handleClose()
    setMount("bottom");
    setEditView(null);
  };

  function handleCloseAll() {
    handleClose();
    handleCloseCreateView();
  }

  //EDIT / CREATE VIEW MENU STATE
  const [view, setView] = useState("Full");
  const [name, setName] = useState("");

  function SelectView(view) {
    //FOr quicker perceived change
    let resetView = globalState.activeView;
    globalActions.setActiveView(view);

    //update the active view in the workspaces data
    const workspaceIndex =
      workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;

    if (workspaceIndex > -1) {
      const updatedWorkspacesData = update(workspacesData, {
        workspaces: {
          [workspaceIndex]: {
            activeView: {
              $set: view._id,
            },
          },
        },
      });
      mutate("/workspaces", updatedWorkspacesData, false);
    } else {
      //revalidate after call to api
    }

    // var updatedWS = globalState.activeWorkspace
    // updatedWS.activeView = view._id
    //globalActions.saveActiveWorkspace(updatedWS)

    handleClose();

    if (view._id !== globalState.activeView._id) {
      globalActions.setActiveView(view);

      axios
        .post("/workspaces/updateactiveview", { workspaceId: workspace._id, newActiveViewId: view._id })
        .then((res) => {
          if (workspaceIndex === -1) mutate(`/workspaces`);
        })
        .catch((err) => {
          //console.log("Could not update workspace name")
          console.log(err);
          globalActions.setActiveView(resetView);

          // setNewNameReq({
          //   loading: null,
          //   isError: index
          // })
          // setEditWorkspaceName(false);
          // setNewWorkspaceName("")
        });
    }
  }

  function DeleteView(view) {
    //for a perceived quicker change

    var isCurrent = globalState.activeView._id === view._id ? true : false;
    // if(!isCurrent){
    //   handleCloseAll()
    // }

    const workspaceIndex =
      workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;
    const updatedViews =
      workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views
        ? workspacesData.workspaces[workspaceIndex].views.filter((v) => v._id != view._id)
        : -1;
    const newActiveViewId =
      workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views && workspacesData.workspaces[workspaceIndex].views.length > 0
        ? workspacesData.workspaces[workspaceIndex].views[workspacesData.workspaces[workspaceIndex].views.length - 1]._id
        : null;
    const updatedWorkspaces =
      workspaceIndex > -1
        ? update(workspacesData, {
            workspaces: { [workspaceIndex]: { views: { $set: updatedViews } } },
            // eslint-disable-next-line no-dupe-keys
            workspaces: { [workspaceIndex]: { activeView: { $set: newActiveViewId } } },
          })
        : null;

    const data = {
      viewId: view._id,
      curViews: globalState.activeWorkspace.views,
      workspaceId: globalState.activeWorkspace._id,
      isCurrent: isCurrent,
    };

    if (workspaceIndex > -1) {
      const newActiveView = workspace && workspace.views && workspace.views.length > 1 ? workspace.views[workspace.views.length - 2] : {};
      //console.log(newActiveView)
      globalActions.setActiveView(newActiveView);
      mutate(`/workspaces`, updatedWorkspaces, false);
    }

    handleCloseAll();

    axios
      .post("/views/delete", data)
      .then((res) => {
        //console.log(res.data)

        // if(isCurrent){
        //
        //   const newActiveView = res.data.activeView ? res.data.activeView : {}
        //   globalActions.setActiveView(newActiveView)
        //   var newActiveViewId = res.data.activeView
        //   var updatedWS = globalState.activeWorkspace
        //   updatedWS.activeView = newActiveViewId
        //   globalActions.saveActiveWorkspace(updatedWS)
        // }

        //revalidate the workspaces data
        mutate(`/workspaces`);

        handleCloseAll();
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  }

  function UpdateView(view, viewType, name) {
    var editingCurrentView = globalState && globalState.activeView && globalState.activeView._id === view._id ? true : false;

    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate
    let newView = { ...view, name: name, viewType: viewType };
    const workspaceIndex =
      workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;
    const viewIndex =
      workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views
        ? workspacesData.workspaces[workspaceIndex].views.findIndex((v) => v._id == newView._id)
        : -1;
    const updatedWorkspaces = update(workspacesData, { workspaces: { [workspaceIndex]: { views: { [viewIndex]: { $set: newView } } } } });

    if (viewIndex > -1) {
      globalActions.setActiveView(newView);
      mutate("/workspaces", { ...workspacesData, workspaces: updatedWorkspaces.workspaces }, false);
    }

    handleCloseAll();

    const data = {
      viewId: view._id,
      viewType: viewType,
      newName: name,
    };

    axios
      .post("/views/update", data)
      .then((res) => {
        //console.log(res)
        if (editingCurrentView) {
          globalActions.setActiveView(res.data.view);
        }

        // const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex(w => w._id === globalState.activeWorkspace._id) : -1
        // const viewIndex = workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views ? workspacesData.workspaces[workspaceIndex].views.findIndex(v => v._id == res.data.view._id) : -1
        // const updatedWorkspaces = update(workspacesData, {workspaces: {[workspaceIndex]: {views: {[viewIndex]: {$set: res.data.view}} }} })

        // if(viewIndex > -1){
        //   mutate('/workspaces', {...workspacesData, workspaces: updatedWorkspaces.workspaces}, false)
        // }

        //revalidate
        mutate("/workspaces");

        handleCloseAll();
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        //revalidate
        mutate("/workspaces");
        console.log(err);
      });
  }

  function DuplicateView(view) {
    //console.log(view)
    const data = {
      view: view,
    };

    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate
    let newView = { ...view, name: "Copy of " + view.name };
    const workspaceIndex =
      workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;
    const updatedWorkspaces = update(workspacesData, { workspaces: { [workspaceIndex]: { views: { $push: [newView] } } } });

    if (workspaceIndex > -1) {
      globalActions.setActiveView(newView);
      mutate("/workspaces", { ...workspacesData, workspaces: updatedWorkspaces.workspaces }, false);
    }

    handleCloseAll();

    axios
      .post("/views/duplicate", data)
      .then((res) => {
        //console.log(res)
        globalActions.setActiveView(res.data.view);

        mutate("/workspaces");
        handleCloseAll();
      })
      .catch((err) => {
        mutate("/workspaces");
        //console.log("Could not update workspace name")
        console.log(err);
      });
  }

  return (
    <>
      <Tooltip disableInteractive placement="bottom-start" title={activeView ? "" : "A view allows you to see your collections in different ways"}>
        <PlainButton
          disableFocusRipple
          disableRipple
          endIcon={activeView && activeView._id && activeView.viewType ? <ExpandMoreRoundedIcon /> : null}
          onClick={activeView ? openMenu : (e) => handleOpenCreateView(e, "bottom")}
          startIcon={
            activeView && activeView._id && activeView.viewType ? (
              activeView.viewType === "Grid" ? (
                <GridViewRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
              ) : activeView.viewType === "Kanban" ? (
                <ViewKanbanOutlinedIcon color={theme.palette.text.primary} style={{ width: 20, height: 20, margin: 0 }} />
              ) : activeView.viewType === "Full" ? (
                <ViewDayRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
              ) : (
                <MenuRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
              )
            ) : (
              <Add fontSize="small" style={{ width: 18, margin: -4 }} />
            )
          }
          style={{
            marginRight: 8,
            maxWidth: 250,
            minWidth: activeView && activeView._id && activeView.viewType ? 0 : 125,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {activeView && activeView._id && activeView.viewType ? (
            <Typography
              noWrap
              style={{ maxWidth: "85%", textOverflow: "ellipsis", overflow: "hidden" }}
              sx={{
                color: activeView && activeView._id && activeView.viewType ? "text.primary" : "inherit",
              }}
              variant="button"
            >
              {activeView.name}
            </Typography>
          ) : (
            <Typography noWrap style={{ maxWidth: "85%", textOverflow: "ellipsis", overflow: "hidden" }} variant="button">
              Create view
            </Typography>
          )}
        </PlainButton>
      </Tooltip>

      <DefaultMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
        open={open}
        style={{ zIndex: 999, maxHeight: 500, minWidth: 350, maxWidth: 400, marginTop: 5 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box style={{ minWidth: 350 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 6,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <Typography
              color="textSecondary"
              noWrap
              style={{ maxWidth: "85%", display: "flex", flexDirection: "row", alignItems: "center", textOverflow: "ellipsis", overflow: "hidden" }}
              variant="subtitle2"
            >
              Views for
              <Box color="text.primary" style={{ marginLeft: 4, textOverflow: "ellipsis", overflow: "hidden" }}>
                {workspace && workspace.name ? workspace.name : "Default view"}
              </Box>
            </Typography>
            {helpIcon && helpIcon.url ? (
              <IconButton aria-label="delete" href={helpIcon.url} size="small" target="_blank">
                <HelpOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </div>
          <Divider style={{ marginTop: 4, marginBottom: 6 }} />

          {workspace && workspace.views && workspace.views.length > 0
            ? workspace.views.map((v, i) => {
                var isActiveView = v._id === workspace.activeView ? true : false;
                return (
                  <MenuItem button disableRipple key={i} onClick={() => SelectView(v)} selected={isActiveView} style={{ marginBottom: 4 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "grab", marginRight: 6 }}>
                      {v.viewType === "Grid" ? (
                        <GridViewRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
                      ) : v.viewType === "Kanban" ? (
                        <ViewKanbanOutlinedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
                      ) : v.viewType === "Full" ? (
                        <ViewDayRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
                      ) : (
                        <MenuRoundedIcon color={theme.palette.text.primary} style={{ width: 18, height: 18, margin: 0 }} />
                      )}
                    </div>
                    <Typography noWrap style={{ maxWidth: "85%", textOverflow: "ellipsis", overflow: "hidden" }} variant="body2">
                      {v.name}
                    </Typography>
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="edit"
                        onClick={(e) => handleOpenCreateView(e, "top", v)}
                        size="small"
                        style={{
                          margin: 0,
                          borderRadius: 4,
                          padding: 3,
                          marginRight: -8,
                        }}
                      >
                        <MoreVert fontSize="inherit" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                );
              })
            : null}
          <Divider style={{ marginBottom: 4 }} />
          <MenuItem button disableRipple onClick={(e) => handleOpenCreateView(e, "side")} style={{ color: "#3D5AFE" }}>
            <ListItemIcon style={{ color: "#3D5AFE" }}>
              <Add />
            </ListItemIcon>
            Add a view
          </MenuItem>
        </Box>
      </DefaultMenu>

      <CreateViewMenu
        DeleteView={DeleteView}
        DuplicateView={DuplicateView}
        UpdateView={UpdateView}
        activeView={activeView}
        anchorEl={createViewAnchorEl}
        collectionsData={props.collectionsData}
        editView={editView}
        handleClose={handleCloseCreateView}
        handleCloseAll={handleCloseAll}
        mount={mount}
        name={name}
        setName={setName}
        setView={setView}
        view={view}
        workspace={workspace}
      />
    </>
  );
}

// <DragIndicatorIcon fontSize="small" style={{width: 16, color: '#C4C4C4'}}/>
