import { useEffect } from "react";

export default function usePageMeta(title, description, faviconURL) {
  const defaultTitle = "Partizion";
  const defaultDesc = "Partizion — organize and manage your tabs and sessions in the browser";

  useEffect(() => {
    // console.log(document)
    document.title = title || defaultTitle;
    // document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);

    //set the favicon, if included
    // let link = document.querySelector("link[rel~='icon']");
    // if (!link) {
    //   link = document.createElement("link");
    //   link.rel = "icon";
    //   document.getElementsByTagName("head")[0].appendChild(link);
    // }
    // if (faviconURL) {
    //   link.href = faviconURL;
    // } else {
    //   link.href = "./public/favicon.ico";
    // }
  }, [title, description]);
}
