import React, { useState, useEffect } from "react";

import { useTheme, styled } from "@mui/material/styles";

import { Paper, Typography, Tooltip, ButtonGroup, Button, Fade } from "@mui/material";

import defaultFavIconLight from "../../Assets/defaultFaviconLight.svg";
import defaultFavIconDark from "../../Assets/defaultFaviconDark.svg";
import useDarkMode from "../../Utils/theme/useDarkMode";

import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

import MoreHorizRounded from "@mui/icons-material/MoreHorizRounded";
import CloseRounded from "@mui/icons-material/CloseRounded";
import useHoverGroup from "../../Utils/useHoverGroup";
import useGlobal from "../../GlobalState/Store/Store";

const OutlinedButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,

  ".MuiButtonGroup-root": {
    flexShrink: 0,
  },
  //individual button styles
  ".MuiButton-contained": {
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
    minHeight: 0,
    minWidth: 0,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    // boxShadow: theme.palette.shadows.medium,
    "&:hover": {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.selected,
    },
    "&:active": {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    ":not(:last-of-type)": {
      borderRight: `0px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  //icon styles
  ".MuiSvgIcon-root": {
    color: theme.palette.text.secondary,
    minWidth: 2,
    width: 18, //'0.75em',
    height: 18, //'0.75em'
  },
}));

function SessionTab({ tab, showSessionHistory }) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();

  const { isDarkMode } = useDarkMode("light");

  const FaviconURL =
    tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
      ? ""
      : tab.favIconUrl.replace(/^http:\/\//, "https://");

  const [hoverRef, hover] = useHoverGroup();

  //FUNCTIONS
  const handleMiddleClicks = (event) => {
    //console.log(event.button)

    if (event.button === 1) {
      // do something on middle mouse button click
      event.metaKey = true;
      OpenTab(event);
    }
  };
  function OpenTab(e) {
    //console.log(e)

    e.preventDefault();
    e.stopPropagation();
    //console.log(e)
    //var charCode = e.which || e.charCode || e.keyCode || e.nativeEvent.which || e.nativeEvent.charCode || e.nativeEvent.keycode
    //var CMDorCNTLKey = charCode == 224 || charCode == 17 || charCode == 93 || charCode == 91 ? true : false

    if (menuAnchorEl !== null) return;

    let shiftKey = e.shiftKey;
    let metaKey = e.metaKey || e.ctrlKey;

    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({ type: "openTab", tab: tab, shiftKey: shiftKey, metaKey: metaKey });
    } else {
      window.open(tab.url);
    }
  }

  //TAB MENU
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [selected, setSelected] = useState(false);
  const [clickPosition, setClickPosition] = useState(initialState);
  const openMenu = (event, trigger) => {
    //console.log("Attempting to open collection menu")
    event.preventDefault();
    event.stopPropagation();

    // console.log({trigger})
    //console.log(event.currentTarget)

    if (trigger && trigger == "rightclick") {
      //console.log("setting click pos", event.clientX, event.clientY)
      setClickPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
    setMenuAnchorEl(event.currentTarget);
    setSelected(true);
  };
  const handleCloseMenu = (event) => {
    setMenuAnchorEl(null);
    setClickPosition(initialState);
  };

  const buttons = [
    <Tooltip
      disableInteractive
      enterDelay={750}
      placement="bottom-end"
      title={<div style={{ display: "flex", flexDirection: "row" }}>Pin Tab, copy link, and more</div>}
    >
      <Button key="more" onClick={openMenu}>
        <MoreHorizRounded />
      </Button>
    </Tooltip>,
    <Tooltip
      disableInteractive
      enterDelay={750}
      placement="bottom-end"
      title={<div style={{ display: "flex", flexDirection: "row" }}>Remove tab from session</div>}
    >
      <Button key="close">
        <CloseRounded />
      </Button>
    </Tooltip>,
  ];

  return (
    <Paper
      key={tab._id}
      //onMouseDown={(e) => setTabSelected(e, "body click")}
      onClick={OpenTab}
      onMouseDown={handleMiddleClicks}
      ref={hoverRef}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 6,
        marginTop: 6,
        marginBottom: 6,
        width: "100%",

        border: `1.5px solid ${theme.palette.divider}`, //rgb(196, 196, 196, 0.35)
        backgroundColor: hover ? theme.palette.action.hover : theme.palette.background.paper,
        boxShadow: theme.palette.shadows.small,
        borderRadius: 4,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {FaviconURL !== "" ? (
          <img
            color={theme.palette.text.secondary}
            fill={theme.palette.text.secondary}
            height={24}
            mode="fit"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              isDarkMode ? (currentTarget.src = defaultFavIconDark) : (currentTarget.src = defaultFavIconLight);
            }}
            src={FaviconURL}
            style={{ minWidth: 24, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
            width={24}
          />
        ) : (
          <LanguageRoundedIcon sx={{ color: "text.secondary", width: 24, height: 24 }} />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "flex-start",
            marginLeft: 10,
            //minWidth: '100%',//'calc(100% - 0px)', //used to be - 80px
            flexGrow: 1,
            width: "100%",
            //maxWidth: 'calc(100% - 50)',
            minWidth: 0,
            //minWidth: 'calc(100% - 75px)',
            // width: '100%',
            // maxWidth: 'calc(25% - 38px)',
          }}
        >
          <div style={{ textOverflow: "ellipsis", overflow: "hidden", minWidth: 0, width: "100%" }}>
            <Typography
              m={0}
              noWrap
              style={{
                fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                fontWeight: 600,
                color: theme.palette.text.primary,
                fontSize: 13,
                margin: 0,
                minHeight: 0,
                marginBottom: -6,
              }}
              variant="subtitle2"
            >
              {tab.title}
            </Typography>
            <Typography
              m={0}
              noWrap
              style={{
                fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                color: theme.palette.text.secondary,
                fontSize: 11,
                margin: 0,
                minHeight: 0,
              }}
              variant="caption"
            >
              {tab.url}
            </Typography>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default React.memo(SessionTab);

{
  /* <SessionTabMenu 
              anchorEl={menuAnchorEl}
              handleCloseMenu={handleCloseMenu}
              session={session}
              tab={tab}
          /> */
}

// {
//   !showSessionHistory && (
//   <Fade
//       in={hover}
//       key="sessions-loading-state"
//       style={{
//         transitionDelay: hover ? '50ms' : '0ms',
//     }}
//       unmountOnExit
//   >
//       <OutlinedButtonGroup
//           aria-label="small button group"
//           size="small"
//           variant="contained"
//       >
//           {buttons}
//       </OutlinedButtonGroup>
//   </Fade>
//   )
//  }
