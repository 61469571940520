import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ClickAwayListener from "@mui/base/ClickAwayListener";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  padding: 0,
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: `2px solid transparent`,
    //   minHeight: 36,
    //   fontSize: 36,
    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 700,
    cursor: "text",
    //   lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //marginBottom: 8,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    //transition: 'border-color background-color 5s ease',
    color: theme.palette.text.primary,
    "&:hover": {
      // color: theme.palette.text.primary,
      //backgroundColor: theme.palette.collection.bgLight,
      border: `2px solid ${theme.palette.collection.hover}`,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      // color: theme.palette.text.primary,
      // backgroundColor: theme.palette.collection.hover,
    },
  },
}));

//a controlled, reuseable input that handles keystrokes and focus
const RegularInput = ({
  key,
  fullWidth,
  multiline,
  inputRef,
  edit,
  setEdit,
  newName,
  setNewName,
  originalName,
  objectId,
  renameFunction,
  style,
  selectRangeOnFocus,
  autoFocus,
}) => {
  const handleInputTyping = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;

    if (charCode === 13) {
      //ENTER KEY
      let typedName = newName.trim() || "";
      if (typedName === null || typedName === undefined || typedName === "" || typedName === originalName) {
        //cancel the edit
        //console.log("cancel the edit")
        inputRef?.current?.blur();
        setEdit(false);
        setNewName("");
        return;
      }
      inputRef?.current?.blur();
      renameFunction(event, objectId, event.target.value);
    } else if (charCode === 27) {
      event.stopPropagation();
      //ESC KEY, cancel the edit
      inputRef?.current?.blur();

      setEdit(false);
      setNewName("");
    }
  };
  const handleClickaway = (event) => {
    //console.log(event)
    //only rename if it is different
    let typedName = newName.trim() || "";
    if (typedName === null || typedName === undefined || typedName === "" || typedName === originalName) {
      //cancel the edit
      //console.log("cancel the edit")
      // inputRef?.current?.blur()
      setEdit(false);
      setNewName("");
      return;
    }

    // inputRef?.current?.blur()
    renameFunction(event, objectId, typedName);
  };
  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const handleCLickInput = (event) => {
    //sometimes clickign JUST outside the input breifly blurs and then focused the input
    //need to prevent this to prevent weird behaviour by resetting the input
    // event.stopPropagation()
    // event.preventDefault()
    setEdit(true);
    if (!newName) {
      setNewName(originalName);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickaway}>
      <BootstrapInput
        autoFocus={autoFocus === true ? true : false}
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        inputRef={inputRef || null}
        key={key || "input"}
        margin="dense"
        multiline={multiline !== undefined ? multiline : false}
        onChange={handleChange}
        onClick={handleCLickInput}
        onFocus={selectRangeOnFocus ? (e) => e.target.setSelectionRange(0, 9999999999) : null}
        onKeyDown={handleInputTyping}
        placeholder="Untitled"
        size="small"
        // variant="h4"
        style={{ ...style }}
        value={edit ? newName : originalName ? originalName : "untitled"}
      />
    </ClickAwayListener>
  );
};

export default RegularInput;
