import { useRef, useState, useCallback } from 'react'

export default function useHover(){
    const [isHovering, setIsHovering] = useState(false);
  
    const handleMouseOver = useCallback(() => setIsHovering(true), []);
    const handleMouseOut = useCallback(() => setIsHovering(false), []);
  
    const nodeRef = useRef();
  
    const callbackRef = useCallback(
      node => {
        if (nodeRef.current) {
          nodeRef.current.removeEventListener('mouseenter', handleMouseOver);
          nodeRef.current.removeEventListener('mouseleave', handleMouseOut);
        }
  
        nodeRef.current = node;
  
        if (nodeRef.current) {
          nodeRef.current.addEventListener('mouseenter', handleMouseOver);
          nodeRef.current.addEventListener('mouseleave', handleMouseOut);
        }
      },
      [handleMouseOver, handleMouseOut]
    );
  
    return [callbackRef, isHovering];
  }