import React, { useEffect, useRef, useState } from "react";

import { useTheme, styled } from "@mui/material/styles";
import sessionTimeString from "../../Utils/sessionTimeString";
import sessionHistoryTimeString from "../../Utils/sessionHistoryTimeString";

//MUI COMPONENTS
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import SessionTab from "./SessionTab";
import Skeleton from "@mui/material/Skeleton";

//CUSTOM COMPONENTS
import SessionNavbar from "./SessionNavbar";
import SessionMenu from "./SessionMenu";
import { useHotkeys } from "react-hotkeys-hook";
import { alpha } from "@mui/material";
import axios from "axios";
import useRecentSessions from "../../Hooks/useRecentSessions";
import useGlobal from "../../GlobalState/Store/Store";
import { ChangeHistoryRounded } from "@mui/icons-material";
import BigInput from "../../Components/Inputs/BigInput";
import SaveSessionMenu from "../../Components/Menus/SaveSessionMenu";
import { useSnackbar } from "notistack";
import { useSWRConfig } from "swr";
import PlainSnackBar from "../../Components/Notistack/PlainSnackBar";
import useSessionHistory from "../../Hooks/useSessionHistory";
import { Virtuoso } from "react-virtuoso";
import useEncodeCollectionURL from "../../Hooks/useEncodeCollectionURL";

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  ".MuiButton-startIcon": {
    //color: theme.palette.text.secondary,
    minWidth: 2,
    marginRight: 3,
    ".MuiSvgIcon-root": {
      fontSize: 12,
    },
  },

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

const SessionWindow = (props) => {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();

  const {
    sessions,
    selectedSession,
    isError,
    isLoading,
    handleSelectNext,
    handleSelectPrevious,
    toggleShowSessionHistory,
    showSessionHistory,
    sessionHistory,
    selectedSessionHistory,
    sessionMenuClickPosition,
    setSessionMenuClickPosition,
    focusSelected,
    setFocusSelected,
    handleSelectSession,
    menuAnchorEl,
    handleOpenMenu,
    handleCloseMenu,
    keyId,
  } = props;

  const focusSelectedSession = focusSelected !== null ? sessions.find((s) => s._id === focusSelected) : selectedSession;

  // console.log({focusSelected, focusSelectedSession})

  const { mutate } = useSWRConfig(); //for workspaces and collections
  const { mutate: mutateSessions } = useRecentSessions(globalState.isAuth); //{revalidateAll: true}
  const { mutate: mutateSessionHistory } = useSessionHistory(
    globalState.isAuth,
    selectedSession && selectedSession._sessionId && showSessionHistory ? selectedSession._sessionId : null
  );

  const { enqueueSnackbar } = useSnackbar();

  const { getLinkToCollection } = useEncodeCollectionURL();

  //HOTKEYS
  const openHistoryEnabled = () => {
    if (showSessionHistory === null && selectedSession && selectedSession._sessionId) return true;
    else return false;
  };
  useHotkeys(
    "h",
    () => {
      //console.log(HotkeysEvent)
      //   console.log("H key hit ")
      toggleShowSessionHistory(selectedSession._id);
    },
    {
      enabled: openHistoryEnabled(),
    },
    [selectedSession]
  );

  const closeSessionHistoryEnabled = () => {
    if (showSessionHistory) return true;
    else return false;
  };
  useHotkeys(
    "esc",
    () => {
      //console.log(HotkeysEvent)
      //console.log("ESC key hit ")
      toggleShowSessionHistory(selectedSession._id);
    },
    {
      enabled: closeSessionHistoryEnabled(),
    },
    [selectedSession]
  );

  //RENAMING SESSION
  const [editSessionName, setEditSessionName] = useState(false);
  const [newSessionName, setNewSessionName] = useState("");
  const sessionNameInput = React.useRef(null);
  const RenameSession = async (event, _sessionId, newName) => {
    event.preventDefault();

    //helper
    function findIndices(arr, sessionId) {
      if (!arr || !arr.length) return [-1, -1];
      const initialIndex = arr.findIndex((innerArr) => innerArr.sessions.findIndex((obj) => obj._sessionId === sessionId) !== -1);
      if (initialIndex === -1) return [-1, -1];
      const nestedIndex = arr[initialIndex].sessions.findIndex((obj) => obj._sessionId === sessionId);
      return [initialIndex, nestedIndex];
    }

    const data = {
      _sessionId: _sessionId,
      newName: newName,
    };
    setEditSessionName(false);
    setNewSessionName("");
    // console.log({sessionNameInput})
    sessionNameInput?.current?.blur();
    // const renameFunction = async () => axios.post('/sessions/rename', data) //.then((result) => result.doc)
    // try {
    //   // Update the local state immediately and fire the
    //   // request. the API returns updated data so we set revalidate to false
    //   // SWR automatically rollsback on error

    //   await mutate(renameFunction, {
    //     optimisticData: sessionsData => {
    //       console.log({sessionsData})
    //       if(sessionsData){

    //         let [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId)
    //         if(nestedIndex && nestedIndex > -1){
    //             const updatedNestedArray = [...sessionsData[initialIndex].slice(0, nestedIndex),
    //                                     {...sessionsData[initialIndex][nestedIndex], name: newName},
    //                                     ...sessionsData[initialIndex].slice(nestedIndex + 1)];
    //             const updatedArray = [...sessionsData.slice(0, initialIndex), updatedNestedArray, ...sessionsData.slice(initialIndex + 1)]
    //             return updatedArray;

    //         } else {
    //             console.log("didn't find session")
    //             return sessionsData
    //         }

    //       } else {
    //         console.log("no mutate on delete")
    //         return sessionsData
    //       }
    //     },
    //     rollbackOnError: true,
    //     populateCache: true,
    //     revalidate: true
    //   })

    // } catch (err) {
    //   console.log(err)
    // }

    // console.log(_sessionId)
    mutateSessions(
      async (sessionsData) => {
        // remove the collection
        // console.log({sessionsData})
        if (sessionsData) {
          let [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId);
          if (nestedIndex > -1) {
            const updatedNestedArray = [
              ...sessionsData[initialIndex].sessions.slice(0, nestedIndex),
              { ...sessionsData[initialIndex].sessions[nestedIndex], name: newName },
              ...sessionsData[initialIndex].sessions.slice(nestedIndex + 1),
            ];
            const updatedArray = [
              ...sessionsData.slice(0, initialIndex),
              { ...sessionsData[initialIndex], sessions: updatedNestedArray },
              ...sessionsData.slice(initialIndex + 1),
            ];
            // console.log({updatedNestedArray, updatedArray})

            return updatedArray;
          } else {
            // console.log("didn't find session")
            return sessionsData;
          }
        } else {
          // console.log("no mutate on delete")
          return sessionsData;
        }
      },
      { revalidate: false }
    );

    axios
      .post("/sessions/rename", data)
      .then(() => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutateSessions();
      })
      .catch((err) => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutateSessions();
        //   console.log(err)
      });
  };

  //SAVING SESSION AS COLLECTION
  const [saveSessionMenuAnchorEl, setSaveSessionMenuAnchorEl] = React.useState(null); //the anchor for the workspace select menu
  const handleOpenSaveSessionMenu = (event) => {
    // console.log(event.currentTarget)
    if (menuAnchorEl !== null) {
      setSaveSessionMenuAnchorEl(menuAnchorEl);
    } else {
      setSaveSessionMenuAnchorEl(event.currentTarget);
    }
    handleCloseMenu(event);
  };

  const handleCloseSaveSessionMenu = (event) => {
    handleCloseMenu(event);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setSaveSessionMenuAnchorEl(null);
  };

  //CONVERTING THE SESSION
  const handleSaveAsCollection = (event, workspace) => {
    //console.log({event, workspace, index})

    // let _sessionId = selectedSession._sessionId
    let _sessionId = focusSelectedSession._sessionId;
    const data = {
      _sessionId: _sessionId,
      workspaceId: workspace._id,
    };

    //Same as delete session, remove from cache
    //helper
    function findIndices(arr, sessionId) {
      const initialIndex = arr.findIndex((innerArr) => innerArr.sessions.findIndex((obj) => obj._sessionId === sessionId) !== -1);
      if (initialIndex === -1) return [-1, -1];
      const nestedIndex = arr[initialIndex].sessions.findIndex((obj) => obj._sessionId === sessionId);
      return [initialIndex, nestedIndex];
    }

    // console.log(_sessionId)
    mutateSessions(
      async (sessionsData) => {
        // remove the collection

        if (sessionsData) {
          // console.log({sessionsData})
          const [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId);

          //console.log({initialIndex, nestedIndex})

          if (nestedIndex > -1) {
            const updatedNestedArray = [
              ...sessionsData[initialIndex].sessions.slice(0, nestedIndex),
              ...sessionsData[initialIndex].sessions.slice(nestedIndex + 1),
            ];
            const updatedArray = [
              ...sessionsData.slice(0, initialIndex),
              { ...sessionsData[initialIndex], sessions: updatedNestedArray },
              ...sessionsData.slice(initialIndex + 1),
            ];

            // console.log({updatedNestedArray, updatedArray})

            return updatedArray;
          } else {
            // console.log("didn't find session")
            return sessionsData;
          }
        } else {
          // console.log("no mutate on delete")
          return sessionsData;
        }
      },
      { revalidate: false }
    );
    handleSelectPrevious();
    handleCloseSaveSessionMenu();

    // send a request to the API to update the source
    axios
      .post("/sessions/saveascollection", data)
      .then((result) => {
        // trigger a revalidation (refetch) to make sure our local data is correct
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate(`/workspaces`);
        mutateSessions();

        // console.log({result})
        if (result && result.data && result.data.collection) {
          let message = "Saved Session as Collection";
          let LinkToCollection = getLinkToCollection(result.data.collection?._collectionId, result.data.collection?.name);
          enqueueSnackbar(message, {
            content: (key, message) => <PlainSnackBar id={key} link={LinkToCollection} message={message} showLinkToButton />,
          });
        }
      })
      .catch((err) => {
        let message = "Could not save session as Collection";
        enqueueSnackbar(message, {
          content: (key, message) => <PlainSnackBar id={key} message={message} />,
          variant: "error",
        });
        mutateSessions();
        // console.log(err)
      });
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    //reset the window scroll when a new session is selected
    scrollRef?.current?.scrollTo(0, 0);
  }, [keyId]);

  //   console.log("SESSION WINDOW KEY IS ", keyId)
  return (
    <div
      style={{
        flexGrow: 1,
        minWidth: 0,
        width: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.default,
        // transition: 'flex-grow 0.5s cubic-bezier(0.4, 0.0, 0.2, 1), width 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
        // border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <SessionNavbar
        handleOpenSaveSessionMenu={handleOpenSaveSessionMenu}
        handleOpenSessionMenu={handleOpenMenu}
        handleSelectNext={handleSelectNext}
        handleSelectPrevious={handleSelectPrevious}
        isError={isError}
        isLoading={isLoading}
        session={selectedSession}
        sessions={sessions}
        showSessionHistory={showSessionHistory}
        toggleShowSessionHistory={toggleShowSessionHistory}
      />
      <SessionMenu
        anchorEl={menuAnchorEl}
        clickPosition={sessionMenuClickPosition}
        handleClose={handleCloseMenu}
        handleOpenSaveSessionMenu={handleOpenSaveSessionMenu}
        handleSelectPrevious={handleSelectPrevious}
        handleSelectSession={handleSelectSession}
        session={focusSelectedSession}
        setFocusSelected={setFocusSelected}
        setSessionMenuClickPosition={setSessionMenuClickPosition}
        toggleShowSessionHistory={toggleShowSessionHistory}
      />
      <SaveSessionMenu
        anchorEl={saveSessionMenuAnchorEl}
        handleClose={handleCloseSaveSessionMenu}
        handleSaveAsCollection={handleSaveAsCollection}
        sessionMenuClickPosition={sessionMenuClickPosition}
      />
      <div
        ref={scrollRef}
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
          padding: 24,
          paddingBottom: 60,
        }}
      >
        {showSessionHistory ? (
          <>
            {selectedSessionHistory && selectedSessionHistory._id && selectedSessionHistory.tabs ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    //width: '100%',
                    marginTop: 2,
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 500,
                    }}
                    variant="body2"
                  >
                    {selectedSessionHistory && sessionHistory && sessionHistory[0].version === selectedSessionHistory.version ? (
                      <div
                        style={{
                          backgroundColor: alpha(theme.palette.primary.main, 0.25),
                          border: `0.5px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                          color: theme.palette.primary.main,
                          paddingLeft: 6,
                          paddingRight: 6,
                          paddingTop: 2,
                          paddingBottom: 2,
                          borderRadius: 2,
                        }}
                      >
                        Current Version
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: alpha(theme.palette.text.primary, 0.05),
                          border: `0.5px solid ${alpha(theme.palette.text.primary, 0.1)}`,
                          paddingLeft: 6,
                          paddingRight: 6,
                          paddingTop: 2,
                          paddingBottom: 2,
                          borderRadius: 2,
                        }}
                      >
                        Version {selectedSessionHistory.version + 1}
                      </div>
                    )}
                  </Typography>
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      marginLeft: 12,
                    }}
                    variant="body2"
                  >
                    {sessionHistoryTimeString(selectedSessionHistory.lastUpdated)}
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: 18,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 120,
                  }}
                >
                  <Typography sx={{ color: "text.secondary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 14 }} variant="body2">
                    {selectedSessionHistory.tabs
                      ? selectedSessionHistory.tabs.length > 1
                        ? selectedSessionHistory.tabs.length + " TABS"
                        : selectedSessionHistory.tabs.length + " TAB"
                      : null}
                  </Typography>
                  <Virtuoso
                    customScrollParent={scrollRef.current}
                    data={selectedSessionHistory?.tabs || []}
                    initialTopMostItemIndex={0}
                    // keyId={keyId}
                    itemContent={(index) => {
                      let tab = selectedSessionHistory.tabs[index];
                      return <SessionTab showSessionHistory={showSessionHistory} tab={tab} />;
                    }}
                    overscan={500}
                    useWindowScroll
                  />
                  {/* {
                                        selectedSessionHistory.tabs.map(tab => (
                                            <SessionTab
                                                showSessionHistory={showSessionHistory}
                                                tab={tab}
                                            />
                                        ))
                                    } */}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            {isLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "center",
                  margin: -1,
                }}
              >
                <Skeleton height={60} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
                <Skeleton height={18} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="50%" />
              </Box>
            ) : isError ? (
              <div>
                <Typography
                  align="center"
                  sx={{
                    padding: 2,
                    color: "error.main",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  Dang, looks like the browser gods have failed us. There was an error loading your sessions. Please try again later.
                </Typography>
              </div>
            ) : selectedSession && selectedSession._id && selectedSession.tabs ? (
              <>
                <BigInput
                  edit={editSessionName}
                  fullWidth
                  inputRef={sessionNameInput}
                  key={selectedSession._id}
                  newName={newSessionName}
                  objectId={selectedSession._sessionId}
                  originalName={selectedSession && selectedSession.name ? selectedSession.name : null}
                  renameFunction={RenameSession}
                  setEdit={setEditSessionName}
                  setNewName={setNewSessionName}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    //width: '100%',
                    marginTop: 2,
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      marginRight: 12,
                    }}
                    variant="body2"
                  >
                    <span style={{ color: theme.palette.text.secondary }}>Opened</span> {sessionTimeString(selectedSession.createdOn)}
                  </Typography>
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      marginRight: 12,
                    }}
                    variant="body2"
                  >
                    <span style={{ color: theme.palette.text.secondary }}>Updated</span> {sessionTimeString(selectedSession.lastUpdated)}
                  </Typography>
                  <PlainButton
                    onClick={() => toggleShowSessionHistory(selectedSession._id)}
                    startIcon={<ChangeHistoryRounded />}
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 500,
                      border: `0.5px solid ${alpha(theme.palette.text.primary, 0.1)}`,
                    }}
                  >
                    Version {selectedSession.version + 1 || 1}
                  </PlainButton>
                </div>
                <div
                  style={{
                    marginTop: 18,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 120,
                  }}
                >
                  <Typography sx={{ color: "text.secondary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 14 }} variant="body2">
                    {selectedSession.tabs
                      ? selectedSession.tabs.length > 1
                        ? selectedSession.tabs.length + " TABS"
                        : selectedSession.tabs.length + " TAB"
                      : null}
                  </Typography>
                  <Virtuoso
                    customScrollParent={scrollRef.current}
                    data={selectedSession.tabs}
                    initialTopMostItemIndex={0}
                    //   data={selectedSession?.tabs || []}
                    itemContent={(index) => {
                      let tab = selectedSession.tabs[index];
                      return <SessionTab key={tab._id} showSessionHistory={showSessionHistory} tab={tab} />;
                    }}
                    //   keyId={keyId}
                    overscan={500}
                    useWindowScroll
                  />
                  {/* {
                            selectedSession.tabs.map(tab => (
                                <SessionTab
                                    key={tab._id}
                                    session={selectedSession}
                                    tab={tab}
                                />
                              ))
                          } */}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default SessionWindow;
