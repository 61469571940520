import { useState } from "react";
import useSWR from "swr";
import axios from "axios";

const fetcher = ([url, workspaceId, viewId]) => {
  let reqBody = {
    workspaceId: workspaceId,
    viewId: viewId ? viewId : null,
  };

  return axios.post(url, reqBody).then((res) => res.data);
};

function useCollections(workspaceId, viewId, options) {
  const { data, error, isValidating, mutate } = useSWR(workspaceId ? [`/collections`, workspaceId, viewId] : null, fetcher, options ? options : null);

  return {
    collectionsData: data,
    isLoadingCollections: !error && !data,
    isCollectionsError: error,
    mutateCollections: mutate,
  };
}

export default useCollections;
