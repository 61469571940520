import update from "immutability-helper";

export const addToCounter = (store, amount) => {
  const counter = store.state.counter + amount;
  store.setState({ counter });
};

export const saveActiveWorkspaceIndex = (store, index) => {
  const activeWorkspaceIndex = index;
  localStorage.setItem("activeWorkspaceIndex", index);
  store.setState({ activeWorkspaceIndex });
};

export const saveActiveWorkspace = (store, ws, reason) => {
  console.log("active workspace updated: " + reason);
  const workspace = ws && ws._id ? ws : { _id: null };
  const activeWorkspace = update(store, { $set: workspace });
  store.setState({ activeWorkspace });
};

export const setActiveView = (store, view) => {
  const activeView = update(store, { $set: view });
  //store.setState({ activeView });
  // let curWorkspaceState = [...store.state.workspaces]
  // const workspaceIndex = curWorkspaceState && curWorkspaceState.length > 0 ? curWorkspaceState.findIndex(w => w._id == view.workspaceId) : -1
  // const viewIndex = workspaceIndex > -1 && curWorkspaceState && curWorkspaceState.length > 0 && curWorkspaceState[workspaceIndex] && curWorkspaceState[workspaceIndex].views ?
  // curWorkspaceState[workspaceIndex].views.findIndex(v => v._id == view._id) : -1
  //
  // console.log()
  //
  // if(viewIndex > -1) {
  //   const state = update(store, {
  //     workspaces: {[workspaceIndex]: {views: {[viewIndex]: {$set: view} }}},
  //     $set: view
  //   })
  //   store.setState({ state });
  // } else {
  //   const activeView = update(store, {$set: view})
  //   store.setState({ activeView });
  // }
  // const curWorkspaces = [...store.state.workspaces]
  const curActiveWorkspace = { ...store.state.activeWorkspace };

  //const workspaceIndex = view && view.workspaceId ? curWorkspaces.findIndex(w => w._id == view.workspaceId) : -1
  const viewIndex = curActiveWorkspace && curActiveWorkspace.views && view && view._id ? curActiveWorkspace.views.findIndex((v) => v._id == view._id) : -1;

  //const workspaces = viewIndex > -1 ? update(curWorkspaces, {[workspaceIndex]: {views: {[viewIndex]: {$set: view} }} }) : -1
  const activeWorkspace = viewIndex > -1 ? update(curActiveWorkspace, { views: { [viewIndex]: { $set: view } } }) : -1;

  if (viewIndex > -1) {
    store.setState({ activeView, activeWorkspace });
  } else {
    store.setState({ activeView });
  }
};

export const saveCollections = (store, collectionsInWS) => {
  //console.log("GLOBAL STATE UPDATE: ", collectionsInWS)
  const collections = update(store, { $set: collectionsInWS });
  //const collections = collectionsInWS;
  store.setState({ collections });
};

export const setTabsMenuOpen = (store, boolean) => {
  const tabsMenuOpen = boolean;
  localStorage.setItem("tabsMenuOpen", boolean);
  store.setState({ tabsMenuOpen });
};

export const LogInOut = (store, isLoggedIn) => {
  const isAuth = isLoggedIn;
  store.setState({ isAuth });
};
export const validatePrivateRoutePrivilege = (store, isLoggedIn) => {
  const allowPrivateRoute = isLoggedIn;
  store.setState({ allowPrivateRoute });
};

export const setUser = (store, userObj) => {
  const user = userObj;
  store.setState({ user });
};

export const setUserTrial = (store, trialObj) => {
  const userTrial = trialObj;
  store.setState({ userTrial });
};

export const setWorkSpaces = (store, workspacesObj) => {
  const workspaces = update(store, { $set: workspacesObj });
  store.setState({ workspaces });
};

export const setBrowsers = (store, browsersObj) => {
  const browsers = browsersObj;
  store.setState({ browsers });
};

export const setSelectedTabs = (store, tabsArray) => {
  const selectedTabs = tabsArray;
  store.setState({ selectedTabs });
};

export const setSelectedWorkspace = (store, workspace) => {
  const selectedWorkspace = workspace;
  store.setState({ selectedWorkspace });
};

export const setSelectedCollection = (store, collection) => {
  const selectedCollection = collection;
  store.setState({ selectedCollection });
};

export const setAppLocation = (store, location) => {
  const appLocation = location;
  store.setState({ appLocation });
};

export const setAppIsDev = (store, boolean) => {
  const isDev = boolean;
  store.setState({ isDev });
};

export const setGlobalSnackbarState = (store, state) => {
  const globalSnackbarState = state;
  store.setState({ globalSnackbarState });
};

export const setDeviceLimiter = (store, obj) => {
  const deviceLimiter = obj;
  store.setState({ deviceLimiter });
};

export const setIsRefreshing = (store, boolean) => {
  const isRefreshing = boolean;
  store.setState({ isRefreshing });
};

export const setCallUser = (store, boolean) => {
  const callUser = boolean;
  store.setState({ callUser });
};

export const setEmojiPicker = (store, obj) => {
  const emojiPicker = obj;
  store.setState({ emojiPicker });
}; //not used anymore?

export const setCommands = (store, commandsArray) => {
  const commands = commandsArray;
  store.setState({ commands });
};

export const setLoginUserEmail = (store, email) => {
  const loginUserEmail = email;
  store.setState({ loginUserEmail });
};

export const setUpgradeRef = (store, prompt) => {
  const upgradeRef = prompt;
  store.setState({ upgradeRef });
};

export const setExtensionConnection = (store, obj) => {
  const extensionConnection = obj;
  store.setState({ extensionConnection });
};

export const setBrowserWindows = (store, arr) => {
  const browserWindows = update(store, { $set: arr });
  store.setState({ browserWindows });
};

export const setBrowserTabs = (store, arr) => {
  const browserTabs = arr;
  store.setState({ browserTabs });
};

export const setBrowserStorage = (store, obj) => {
  const browserStorage = update(store, { $set: obj });
  store.setState({ browserStorage });
};

export const setBrowserCommands = (store, arr) => {
  const browserCommands = arr;
  store.setState({ browserCommands });
};
export const setExtensionCurrentCollections = (store, arr) => {
  const extensionCurrentCollections = update(store, { $set: arr });
  store.setState({ extensionCurrentCollections });
};
