import React from 'react';
import { useDragLayer } from 'react-dnd';
import ItemTypes from './ItemTypes.js';
import TabsDragPreview from './TabsDragPreview.js';

//zIndex has to be so high to be above currentTabMenu
const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 999999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const getTabStyle = (isDragging) => {
  const style = {
    borderStyle: 'dashed',
    borderWidth: 1,
    height: 30,
    margin: 5,
  };
  //style.backgroundColor = selected ? 'pink' : '#87cefa';
  style.opacity = isDragging ? 0.5 : 1;
  return style;
};

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;
    // if (isSnapToGrid) {
    //     x -= initialOffset.x;
    //     y -= initialOffset.y;
    //     [x, y] = snapToGrid(x, y);
    //     x += initialOffset.x;
    //     y += initialOffset.y;
    // }
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

const CustomTabDragLayer = (props) => {
  //console.log("DRAG LAYER", {props})
    const { itemType, isDragging, item, initialOffset, currentOffset, canDrop } = useDragLayer((monitor) => ({
     item: monitor.getItem(),
     itemType: monitor.getItemType(),
     initialOffset: monitor.getInitialSourceClientOffset(),
     currentOffset: monitor.getSourceClientOffset(),
     isDragging: monitor.isDragging(),
    }));
    //<BoxDragPreview title={item.title}/>;
    function renderItem() {
      //console.log("render item from drag layer", item, itemType)
      switch (itemType) {
          case ItemTypes.CURTAB:
              return (
                <TabsDragPreview item={item.tab} tabs={item.selectedTabs} type={item.type} tabWidthRef={item.tabWidthRef}/>
              )
          case ItemTypes.PASTTAB:
              return (
                <TabsDragPreview item={item.tab} tabs={item.selectedTabs} type={item.type} tabWidthRef={item.tabWidthRef}/>
              )
          case ItemTypes.CURWINDOW:
              return (
                <TabsDragPreview item={item.window} tabs={item.window.tabs} type={item.type} tabWidthRef={item.tabWidthRef}/>
              )
          case ItemTypes.PASTSESSION:
              return (
                <TabsDragPreview item={item.session.window} tabs={item.tabs} type={item.type} tabWidthRef={item.tabWidthRef}/>
              )
          default:
              return null;
      }
    }
    if (!isDragging) {
        return null;
    }
    return (<div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, null)}>
				{renderItem()}
			</div>
		</div>);
};

export default CustomTabDragLayer
