import React from "react";
import { useTheme } from "@mui/material/styles";

//MUI COMPONENTS
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

//MUI ICONS
import HistoryIcon from "@mui/icons-material/History";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

//CUSTOM COMPONENTS
import HotkeyChip from "../../Components/Hotkey/HotkeyChip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import useGlobal from "../../GlobalState/Store/Store";

import Fade from "@mui/material/Fade";

export default function SessionNavbar({
  sessions,
  session,
  handleOpenSessionMenu,
  handleSelectNext,
  handleSelectPrevious,
  isLoading,
  isError,
  toggleShowSessionHistory,
  showSessionHistory,
  handleOpenSaveSessionMenu,
}) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();

  const sessionIndex = sessions && session && sessions.length ? sessions.findIndex((s) => s._id === session._id) : -1;

  const handleOpenTabs = (event, type) => {
    event.stopPropagation();
    event.preventDefault();

    if (globalState.extensionConnection.isConnected) {
      var shiftKey = event.shiftKey ? true : false;
      globalState.extensionConnection.port.postMessage({
        type: "openSession",
        shiftKey: shiftKey,
        session: session,
        openType: type,
        windowId: globalState.extensionConnection.windowId,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        height: 50,
        minHeight: 50,
      }}
    >
      {isLoading || isError || !session ? null : !showSessionHistory ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              disableInteractive
              enterDelay={450}
              title={
                sessionIndex === -1 || sessionIndex === 0 ? null : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    go to previous session
                    <HotkeyChip
                      fixed
                      keys={["K", "or", <ArrowUpwardRoundedIcon style={{ fontSize: 14, color: theme.palette.text.secondary }} />]}
                      style={{ marginLeft: 6 }}
                    />
                  </div>
                )
              }
            >
              <span>
                <IconButton
                  disableRipple
                  disabled={sessionIndex === -1 || sessionIndex === 0}
                  onClick={handleSelectPrevious}
                  style={{ marginRight: 6 }}
                  sx={{
                    "&.Mui-disabled": {
                      background: "pallette.actions.hover",
                      //color: "#c0c0c0",
                      opacity: 0.5,
                      ".MuiSvgIcon-root": {
                        color: "#c0c0c0",
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <ArrowUpwardRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              disableInteractive
              enterDelay={450}
              title={
                sessionIndex === -1 || sessionIndex === sessions.length - 1 ? null : (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    go to next session
                    <HotkeyChip
                      fixed
                      keys={["J", "or", <ArrowDownwardRoundedIcon style={{ fontSize: 14, color: theme.palette.text.secondary }} />]}
                      style={{ marginLeft: 6 }}
                    />
                  </div>
                )
              }
            >
              <span>
                <IconButton
                  disableRipple
                  disabled={sessionIndex === -1 || sessionIndex === sessions.length - 1}
                  onClick={handleSelectNext}
                  style={{ marginRight: 6 }}
                  sx={{
                    "&.Mui-disabled": {
                      background: "pallette.actions.hover",
                      //color: "#c0c0c0",
                      opacity: 0.5,
                      ".MuiSvgIcon-root": {
                        color: "#c0c0c0",
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <ArrowDownwardRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              aria-label="add"
              classes={{ tooltip: { maxWidth: 213 } }}
              disableFocusListener
              disableInteractive
              enterDelay={1000}
              title={
                !globalState.extensionConnection.isConnected ? (
                  "Connect to the extension to open sessions"
                ) : session && session.tabs && session.tabs.length < 1 ? (
                  "No tabs in this session"
                ) : (
                  <React.Fragment>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      Open tabs in current window{" "}
                      <Typography style={{ fontSize: 10, marginTop: 2, display: "flex", alignItems: "center" }} variant="caption">
                        Hold
                        <HotkeyChip dense keys={["SHIFT"]} />
                        to open in new window
                      </Typography>
                    </div>
                  </React.Fragment>
                )
              }
            >
              <span>
                <Button
                  disableRipple
                  disabled={(session && session.tabs && session.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                  //   endIcon={<HotkeyChip keys={["⌘", "O"]} />}
                  onClick={(e) => handleOpenTabs(e, "add")}
                  size="medium"
                  startIcon={<LaunchRoundedIcon />}
                  style={{ marginRight: 6 }}
                  variant="outlined"
                >
                  Open
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              aria-label="open"
              classes={{ tooltip: { maxWidth: 213 } }}
              disableFocusListener
              disableInteractive
              enterDelay={1000}
              title={
                !globalState.extensionConnection.isConnected
                  ? "Connect to the extension to open sessions"
                  : session && session.tabs && session.tabs.length < 1
                    ? "No tabs in this session"
                    : "Close current tabs and open these"
              }
            >
              <span>
                <Button
                  disableRipple
                  disabled={(session && session.tabs && session.tabs.length < 1) || !globalState.extensionConnection.isConnected}
                  // endIcon={<HotkeyChip keys={['⌘', 'O']} />}
                  onClick={(e) => handleOpenTabs(e, "replace")}
                  size="medium"
                  startIcon={<RepeatRoundedIcon />}
                  style={{ marginRight: 6 }}
                  variant="outlined"
                >
                  Switch to
                </Button>
              </span>
            </Tooltip>

            <Button color="primary" onClick={handleOpenSaveSessionMenu} size="medium" style={{ marginRight: 6 }} variant="contained">
              New Collection{" "}
            </Button>
            <Tooltip
              disableInteractive
              enterDelay={750}
              placement="bottom-end"
              title={
                <div style={{ display: "flex", flexDirection: "row" }}>
                  Show Session History
                  <HotkeyChip keys={["H"]} style={{ marginLeft: 6 }} />
                </div>
              }
            >
              <IconButton onClick={() => toggleShowSessionHistory(session._id)} style={{ marginRight: 6 }} variant="outlined">
                <HistoryIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              disableInteractive
              enterDelay={750}
              placement="bottom-end"
              title={<div style={{ display: "flex", flexDirection: "row" }}>Save, Copy Links, and more</div>}
            >
              <IconButton disableRipple onClick={handleOpenSessionMenu} style={{ marginRight: 6 }} variant="outlined">
                <MoreHorizRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : null}
      <Fade in={showSessionHistory} style={{ width: showSessionHistory ? "100%" : 0 }} timeout={{ enter: 1000, exit: 0 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              // transform: showSessionHistory ? "none" : 'translateX(100%)',
              // visibility: showSessionHistory ? 'visible' : 'hidden',
              // transition: 'visibility 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
            }}
          >
            <Button
              disableRipple
              onClick={toggleShowSessionHistory}
              startIcon={<KeyboardBackspaceRoundedIcon />}
              style={{ marginRight: 12, flexShrink: 0 }}
              variant="outlined"
            >
              Back
            </Button>
            <Breadcrumbs>
              <Typography noWrap style={{ textOverflow: "ellipsis", overflow: "hidden", color: theme.palette.text.secondary }}>
                Sessions
              </Typography>
              <Typography color="text.primary" noWrap style={{ fontWeight: 600, textOverflow: "ellipsis", overflow: "hidden" }}>
                {session && session.name && session.name}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
      </Fade>
    </div>
  );
}
