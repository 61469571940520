import React, {} from 'react';
import { styled } from '@mui/material/styles';

import {
  FormControl,
  InputBase,

} from '@mui/material';

// const CustomInput = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: 24,
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: 14,
//     padding: '5px 6px 5px 6px',
//     // borderColor: isEntryError ? 'red' : null,
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:focus': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//     },
//     '&:hover': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//     },
//   },
// }))(InputBase);

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    
  },
}));


export default function CustomTextField(props) {
  const options = props.options

  // const [selected, setSelected] = useState('');
  // //initialize value
  // useEffect(() => {
  //   if(options && options.length > 0){
  //     setSelected(options[0].value)
  //   }
  // }, [])
  //
  // const handleChange = (event) => {
  //   setSelected(event.target.value);
  // };
  const selected = props.selected
  const handleChange = props.handleChange

  const item = props.item
  // console.log("NUMBER = " + props.item.value.value)
  // console.log("isNaN: " + isNaN(props.item.value.value))
  // console.log("isInt: " + IsInt(props.item.value.value))
  function IsInt(num){
    var result =  props.item.value.value % 1
    if(result != 0){
      return false
    } else {
      return true
    }
  }
  var isEntryError = props.item && props.item.value.type === "number" ? isNaN(props.item.value.value) ? true : !IsInt(props.item.value.value) ? true : props.item.value.value !== null && props.item.value.value.includes(".") ? true : false : true


  //width: props.InListItem || props.smallWidth ? null : 100,
  return (
      <FormControl
          className={props.InListItem || props.smallSelect ? {margin: 0} : {margin: 8}}
          style={{ margin: props.smallSelect ? 0 : 4, marginTop: 0, marginBottom: 0, width: '100%'}}
          variant="standard"
      >
          <BootstrapInput
              onChange={(e) => handleChange(e, props.item, props.itemKey, null, isEntryError)}
              style={{border: isEntryError ? '1px solid red' : '1px solid transparent', borderRadius: 4}}
              value={props.value ? props.value : ""}
          />
      </FormControl>
  );

}

//<InputLabel id="demo-customized-select-label">Age</InputLabel>
