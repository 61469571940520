export const colors = [
    {
      name: 'indigo',
      solidHex: '#909FF1',
      hex: '#3D5AFE',
      darker: '#00179A'
    },
    {
      name: 'blue',
      solidHex: '#98C7FF',
      hex: '#3391FF',
      darker: '#004EA9'
    },
    {
      name: 'purple',
      solidHex: '#C9A7FF',
      hex: '#9451FF',
      darker: '#3C019A'
    },
    {
      name: 'red',
      solidHex: '#FD8686',
      hex: '#FC0D0D',
      darker: '#6B0000'
    },
    {
      name: 'orange',
      solidHex: '#FFC086',
      hex: '#FF810D',
      darker: '#854000'
    },
    {
      name: 'yellow',
      solidHex: '#FFDC8D',
      hex: '#FFBA1D',
      darker: '#684900'
    },
    {
      name: 'green',
      solidHex: '#85D9BE',
      hex: '#0DB47F',
      darker: '#00593D'
    },
    {
      name: 'default',
      solidHex: '#C3C3C3',
      hex: '#888888',
      darker: '#181818'
    },
  ]