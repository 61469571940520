/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import useGlobal from "../GlobalState/Store/Store.js";
import CollectionList from "../Components/Collections/CollectionList.js";
import { Typography, Grid, Paper, CircularProgress } from "@mui/material";

import update from "immutability-helper";

import useCollections from "../Hooks/useCollections.js";
import useCollectionById from "../Hooks/useCollectionById.js";

import { mutate } from "swr";

//for dragging
import { useDrop } from "react-dnd";
import ItemTypes from "../Components/DND/ItemTypes";

import { useNavigate, useParams } from "react-router-dom";
import Collection from "./Collection.js";

import useWindowSize from "../Utils/useWindowSize.js";

import { CollectionContainerDragLayer } from "../Components/DND/CollectionContainerDragLayer.js";
import { useSnackbar } from "notistack";
import PlainSnackBar from "../Components/Notistack/PlainSnackBar.js";
import useEncodeCollectionURL from "../Hooks/useEncodeCollectionURL";

function CollectionsContainer(props) {
  const [globalState, globalActions] = useGlobal();
  const { enqueueSnackbar } = useSnackbar();

  const { getLinkToCollection } = useEncodeCollectionURL();

  let navigate = useNavigate();
  let params = useParams();
  let resetScrollTop = props.resetScrollTop;

  // const matchWorkspaceId = useMatch("/:workspaceid");
  // const matchCollectionId = useMatch("/:workspaceid/:collectionid");

  // const workspaceId = matchWorkspaceId?.params?.workspaceid;
  // const collectionId = matchCollectionId?.params?.collectionid;

  // console.log({params, workspaceId, collectionId})

  const { collectionsData, isLoadingCollections, isCollectionsError, mutateCollections } = useCollections(
    globalState.activeWorkspace._id,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null
  );

  const { collectionData, isLoadingCollection, isCollectionError } = useCollectionById(
    globalState.isAuth,
    // params && params.workspaceid ? params.workspaceid : null,
    params && params.collectionid ? params.collectionid : null
  ); //{revalidateOnMount: true}

  const ref = useRef(null);
  const windowSize = useWindowSize();
  //const collections = globalState.collections

  const GetWorkspacebyID = props.GetWorkspacebyID;
  const tabsMenuOpen = globalState.tabsMenuOpen;
  const clearSelectedTabs = props.clearSelectedTabs;

  const handleAddSnackbarToStack = props.handleAddSnackbarToStack;

  //FOR COLLECTION LIST AND DRAGCOLLECTIONCOMP STATE
  const [collections, setCollections] = useState([]);
  const [orderedBackup, setOrderedBackup] = useState([]);

  //everytime the workspace changes make sure to update collections
  useEffect(() => {
    let isContainerMounted = true;
    if (collectionsData && collectionsData.collections) {
      //console.log("Global collections changed")
      if (isContainerMounted) {
        setCollections(
          update(collections, {
            $set: collectionsData.collections,
          })
        );
        setOrderedBackup(
          update(orderedBackup, {
            $set: collectionsData.collections,
          })
        );
      }
    }

    return () => {
      //cleanup
      isContainerMounted = false;
    };
  }, [collectionsData]);

  //drag state order is now handled at the collection level
  const moveCollectionCard = useCallback(
    (dragIndex, hoverIndex) => {
      //console.log("we want to move " + dragIndex + " to " + hoverIndex)
      const dragCollection = collections[dragIndex];
      setCollections(
        update(collections, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCollection],
          ],
        })
      );
    },
    [collections]
  );
  const updateCollectionOrder = useCallback(
    (updated) => {
      //console.log("MASTER reset order")
      setCollections(
        update(collections, {
          $set: updated,
        })
      );
    },
    [collections]
  );

  const resetCollectionOrder = () => {
    //console.log("MASTER reset order")
    setCollections(
      update(collections, {
        $set: orderedBackup,
      })
    );
  };

  const [createReq, setCreateReq] = useState({
    isLoading: false,
    isError: false,
    error: null,
    columnId: null,
  });

  function CreateCollection(addTabs, type, item, properties) {
    //var name = "My awesome new collection ⭐"
    //console.log('CREATE COLLECCTION WITH DRAG CALLED', item, properties)

    if (!properties) properties = [];

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let timeNowBase = new Date();
    let shortMonth = months[timeNowBase.getMonth()];
    let date = timeNowBase.getDate();
    let year = timeNowBase.getFullYear();
    let time = timeNowBase.toLocaleTimeString([], { timeStyle: "short" });
    let name = "Tabs from " + shortMonth + " " + date + ", " + year + " " + time;

    var method;
    //console.log("Trying to create collection named " + name + " with " + addTabs.length + " tabs in workspace " + globalState.activeWorkspace._id)

    //need to add any properties necessary to exist in the current view with filters
    //console.log(globalState.activeView)
    if (
      globalState.activeView &&
      globalState.activeView.filter &&
      globalState.activeView.filter.filters &&
      globalState.activeView.filter.filters.length > 0 &&
      globalState.activeView.filter.filters.some((f) => (f.field && f.field.type == "singleSelect") || (f.field && f.field.type == "multiSelect"))
    ) {
      //there exists som efilters wiuth single or multiselect properties
      let customerPropertyFilters = globalState.activeView.filter.filters.filter(
        (f) =>
          (f.field && f.field.type == "singleSelect" && f.operation == "is" && !!f.value.value) ||
          (f.field && f.field.type == "multiSelect" && f.operation == "contains" && !!f.value.value)
      );

      if (customerPropertyFilters && customerPropertyFilters.length > 0) {
        customerPropertyFilters.forEach((f) => {
          let propertyInArray = properties && properties.length > 0 ? properties.findIndex((p) => p.propertyId == f.field.value) : -1;

          if (propertyInArray > -1) {
            if (properties[propertyInArray].values) {
              if ((f.field.type == "singleSelect" && properties[propertyInArray].values.length < 1) || f.field.type == "multiSelect") {
                properties[propertyInArray].values.push(f.value.value);
              }
            } else {
              properties[propertyInArray].values = [f.value.value];
            }
          } else {
            properties.push({
              propertyId: f.field.value,
              values: [f.value.value],
            });
          }
        });
      }
    }

    setCreateReq({
      isLoading: false, //swr so not necessary
      isError: false,
      error: null,
      columnId: properties && properties[0] && properties[0].values && properties[0].values[0] ? properties[0].values[0] : null,
    });
    if (type === "curtab") {
      method = "Dragging current tab";
    } else if (type === "curwindow") {
      method = "Dragging current window";
    } else if (type === "pasttab") {
      method = "Dragging past tab";
    } else if (type === "pastsession") {
      method = "Dragging past session";
    }

    const data = {
      name: name,
      workspaceId: globalState.activeWorkspace._id,
      tabs: addTabs,
      method: method,
      properties: properties ? properties : null,
    };

    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        // filter the list, and return it without the moved collection
        const now = new Date();
        const newCollection = {
          name: name,
          createdOn: now,
          lastEdited: now,
          tabs: addTabs.filter((t, i) => !(i == 0 && t.url.includes("app.partizion.io"))),
          properties: properties ? properties : null,
        };
        if (collectionsData && collectionsData.collections) {
          return {
            ...collectionsData,
            collections: [...collectionsData.collections, newCollection],
          };
        } else {
          return collectionsData;
        }
      },
      false
    );

    let inActiveTabs;
    let closeTabs = [];

    axios
      .post("/collections/create", data)
      .then((res) => {
        //console.log("Created collection")
        function StopLoading() {
          //console.log("Stop loading")
          setCreateReq({
            isLoading: false,
            isError: false,
            error: null,
          });
        }
        //close the tabs that you just used to create a new collection
        StopLoading();
        if (res.status === 200) {
          if (res && res.data && res.data.collection)
            mutate(
              [`/collections`, globalState.activeWorkspace._id, globalState.activeView._id],
              {
                ...collectionsData,
                collections: [...collectionsData.collections, res.data.collection],
              },
              false
            );
          else mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView._id]);

          //after saving the tab close it
          //if it was succesfully saved and user is NOT holding shift
          if (type == "curwindow" || type == "curtab") {
            if (addTabs.length === 1) {
              //console.log("close that single tab")
              //browser.tabs.remove(addTabs[0].id)
              //  let closeTabs = []
              closeTabs.push(addTabs[0].id);
              let showButton = res.data.collection.tabs.length > 0 ? true : false;

              let message = "Created collection";
              if (type !== "pasttab" && type !== "pastsession") {
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton={showButton} tabsToClose={closeTabs} />,
                });
              }
            } else if (item.windows && item.windows.length === 1) {
              //reset window
              //console.log("Close all except new tab")
              inActiveTabs = item.tabs.filter(function (tab) {
                return tab.active === false;
              }); //AKA not the dashboard so it stays open
              closeTabs = inActiveTabs.map((tab) => tab.id);
              //console.log(closetabs)
              //browser.tabs.remove(closetabs)
              let message = "Created collection";
              if (type !== "pasttab" && type !== "pastsession") {
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                });
              }
            } else if (item.windows && item.windows.length > 1) {
              //console.log("close that window")
              //browser.windows.remove(item.window.id)
            } else if (item.selectedTabs && item.selectedTabs.length >= 1) {
              //user manually selected tabs to save
              inActiveTabs = addTabs.filter(function (tab) {
                return tab.active === false;
              });
              closeTabs = inActiveTabs.map((tab) => tab.id);
              //console.log(closetabs)
              //browser.tabs.remove(closetabs)
              let message = "Created collection";
              if (type !== "pasttab" && type !== "pastsession") {
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                });
              }
            }
          }

          clearSelectedTabs();
        }
      })
      .catch((err) => {
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        console.log(err);
        setCreateReq({
          isLoading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  }

  const removeCollectionsFromState = (removeIds) => {
    setCollections((collections) => {
      return collections.filter((c) => removeIds.indexOf(c._id) < 0);
    });
    setOrderedBackup((collections) => {
      return collections.filter((c) => removeIds.indexOf(c._id) < 0);
    });
  };

  //FOR DRAGGING
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.CURWINDOW, ItemTypes.CURTAB, ItemTypes.PASTSESSION, ItemTypes.PASTTAB],
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      isReadytoDrop: monitor.isReadytoDrop,
    }),
    hover(item, monitor) {
      //console.log(item + " is hovering collections container")
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      //setCreateDragCollection(true)
    },
    drop(item, monitor) {
      let tabsToAdd = [];
      if (isOver) {
        const didDropInContainer = monitor && !monitor.didDrop() ? true : false;
        //console.log({didDropInContainer})

        if (item.type === "curtab") {
          if (item.selectedTabs && item.selectedTabs.length > 0) {
            tabsToAdd = item.selectedTabs;
          } else {
            tabsToAdd.push(item.tab);
          }
          //console.log({tabsToAdd})

          if (didDropInContainer) CreateCollection(tabsToAdd, "curtab", item);
        }
        if (item.type === "pasttab") {
          if (item.selectedTabs && item.selectedTabs.length > 0) {
            tabsToAdd = item.selectedTabs;
          } else {
            tabsToAdd.push(item.tab);
          }
          if (didDropInContainer) CreateCollection(tabsToAdd, "pasttab", item);
        }
        if (item.type === "curwindow") {
          //AddTabsToCollection(item.tabs, item.shiftKeyActive, item)
          if (didDropInContainer) CreateCollection(item.tabs, "curwindow", item);
        }
        if (item.type === "pastsession") {
          //AddTabsToCollection(item.tabs, item.shiftKeyActive, item)
          if (didDropInContainer) CreateCollection(item.tabs, "pastsession", item);
        }
        //CREATE COLLECTION BY DRAGGING IN KANBAN IS HADNLED ON THE KANBAN COLUMN COMPONENT
      }
    },
  });

  //drop(ref)

  useEffect(() => {
    drop(ref);
    return () => {
      //cleanup
      drop(null);
    };
  }, [drop, ref]);

  //Whether or not you can drop the tabs in the collection container
  const [createDragCollection, setCreateDragCollection] = useState(isOver);
  useEffect(() => {
    let isContainerMounted = true;
    if (!isOver) {
      //change immediatley
      //console.log("do now")
      if (isContainerMounted) setCreateDragCollection(false);
    } else {
      //delay the change becuase it could be dragging between collections
      //console.log('delay')
      const update = setTimeout(() => {
        if (isContainerMounted) setCreateDragCollection(isOver);
      }, 25);
      return () => clearTimeout(update);
    }
    return () => {
      clearTimeout(update);
      isContainerMounted = false;
    };
  }, [isOver]);

  //REDIRECT TO WORKSPACE IF COLLECTIONID is wrong
  useEffect(() => {
    //console.log(params, window.location.href)
    if (!isLoadingCollection && globalState.isAuth) {
      // console.log("Not loading collection and we are authenticated", { params, collectionData });
      if (
        (params && params.collectionid && !collectionData) ||
        (isCollectionError && isCollectionError.response && isCollectionError.response.status === 404)
      ) {
        //must be a bad collection url
        // console.log("Go back to workspace", isLoadingCollection, collectionData, isCollectionError, globalState.activeWorkspace);
        if (globalState.activeWorkspace) {
          navigate(`/${globalState.activeWorkspace._id}`);
        }
      }
    }
  }, [collectionData, isLoadingCollection, globalState.isAuth]);

  // redirect old collection urls to new one
  useEffect(() => {
    if (!isLoadingCollections && collectionsData && params["*"]) {
      // console.log(params["*"].split("/")[1], collectionsData, isLoadingCollections);
      const urlId = params["*"]?.split("/")?.[1] ?? "";

      const collectionWithUrlId = collectionsData.collections.find((c) => c.urlId === urlId);
      if (collectionWithUrlId) {
        const LinkToCollection = getLinkToCollection(collectionWithUrlId?._collectionId, collectionWithUrlId?.name);
        navigate(LinkToCollection);
      } else {
        navigate(`/${globalState.activeWorkspace._id}`);
      }
    }
  }, [collectionsData, params]);

  const toggleAutoUpdate = async (collection) => {
    //this function updates the server / db
    let boolean = !collection.autoUpdateTabs;
    let state = "disabled";
    if (boolean) {
      state = "enabled";
    }

    const data = {
      collection: collection,
      state: state,
    };
    try {
      const updatedCollection = await axios.post("/collections/toggleautoupdatetabs", data);
      return updatedCollection;
    } catch (error) {
      return error;
      // Handle an error while updating the user here
    }
  };

  const handleToggleAutoUpdateTabs = (event, collection) => {
    //this is the optimisic UI function
    event.preventDefault();
    event.stopPropagation();

    const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;

    if (collectionIndex > -1) {
      // {...collectionsData, collections: [...collectionsData.collections, [collectionIndex]: {...collectionsData.collections[collectionIndex], autoUpdateTabs: !collection.autoUpdateTabs}]}
      const optimisticState = {
        ...collectionsData,
        collections: [
          ...collectionsData.collections.slice(0, collectionIndex),
          { ...collectionsData.collections[collectionIndex], ...{ autoUpdateTabs: !collection.autoUpdateTabs } },
          ...collectionsData.collections.slice(collectionIndex + 1),
        ],
      };

      mutateCollections(toggleAutoUpdate(collection), {
        optimisticData: optimisticState, //[...collectionsData, "New Item"],
        populateCache: false,
        // revalidate: false,
      });

      //update the collection sync in the extension
      if (globalState.extensionConnection.isConnected) {
        globalState.extensionConnection.port.postMessage({
          type: "toggleAutoUpdateTabs",
          state: !collection.autoUpdateTabs,
          collection: collection,
        });
      }
    } else {
      console.log("Cannot find that collection in the local state to updates it's autoupdate tabs property");
    }
  };

  useEffect(() => {
    if (!(params && params.collectionid)) {
      document.title = "Partizion Dashboard";
    }
  }, [params]);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        minHeight: "100%",
      }}
    >
      <CollectionContainerDragLayer />

      {isLoadingCollections ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#C4C4C4",
            minHeight: "65vh",
            width: "100%",
          }}
        >
          <CircularProgress color="inherit" size={24} />
        </div>
      ) : isCollectionsError ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(50%)",
          }}
        >
          <Typography color="secondary" style={{ marginBottom: "20px" }} variant="h6">
            {" "}
            There was an error retrieving your collections :({" "}
          </Typography>
          <Typography style={{ marginBottom: "20px", color: "#6E6E6E" }} variant="body2">
            {" "}
            Error: {isCollectionsError.message ? isCollectionsError.message : "Please reload the page"}
          </Typography>
        </div>
      ) : collections ? (
        <div
          style={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {params && params.collectionid ? (
            <Collection
              {...props}
              GetWorkspacebyID={GetWorkspacebyID}
              clearSelectedTabs={clearSelectedTabs}
              collectionData={collectionData}
              handleAddSnackbarToStack={handleAddSnackbarToStack}
              isCollectionError={isCollectionError}
              isLoadingCollection={isLoadingCollection}
              resetScrollTop={resetScrollTop}
              tabsMenuOpen={tabsMenuOpen}
            />
          ) : (
            <>
              <CollectionList
                CreateCollection={CreateCollection}
                GetWorkspacebyID={GetWorkspacebyID}
                clearSelectedTabs={clearSelectedTabs}
                collections={collections} //for the kanban drop handler only
                createDragCollection={createDragCollection}
                createReq={createReq}
                drawerWidth={props.drawerWidth}
                handleAddSnackbarToStack={handleAddSnackbarToStack}
                handleClickSelectWorkspace={props.handleClickSelectWorkspace}
                handleToggleAutoUpdateTabs={handleToggleAutoUpdateTabs}
                moveCollectionCard={moveCollectionCard}
                openAddTabsDialog={props.openAddTabsDialog}
                openCollectionDialog={props.openCollectionDialog}
                orderedBackup={orderedBackup}
                params={params}
                removeCollectionsFromState={removeCollectionsFromState}
                resetCollectionOrder={resetCollectionOrder}
                tabsMenuOpen={props.tabsMenuOpen}
                updateCollectionOrder={updateCollectionOrder}
                windowSize={windowSize}
              />
            </>
          )}
          {/* <Routes>
                      <Route
                          element={
                              <CollectionList
                                  CreateCollection={CreateCollection}
                                  GetWorkspacebyID={GetWorkspacebyID}
                                  clearSelectedTabs={clearSelectedTabs}
                                  collections={collections} //for the kanban drop handler only

                                  createDragCollection={createDragCollection}
                                  createReq={createReq}
                                  drawerWidth={props.drawerWidth}

                                  handleAddSnackbarToStack={handleAddSnackbarToStack}
                                  handleClickSelectWorkspace={props.handleClickSelectWorkspace}
                                  moveCollectionCard={moveCollectionCard}
                                  openAddTabsDialog={props.openAddTabsDialog}

                                  openCollectionDialog={props.openCollectionDialog}
                                  orderedBackup={orderedBackup}
                                  params={params}
                                  removeCollectionsFromState={removeCollectionsFromState}
                                  resetCollectionOrder={resetCollectionOrder}

                                  tabsMenuOpen={props.tabsMenuOpen}
                                  updateCollectionOrder={updateCollectionOrder}
                                  windowSize={windowSize}
                              />
                      }
                          path=':workspaceid?'
                      />
                      <Route
                          element={
                              <Collection
                                  {...props}
                                  GetWorkspacebyID={GetWorkspacebyID}
                                  clearSelectedTabs={clearSelectedTabs}
                                  collectionData={collectionData}
                                  handleAddSnackbarToStack={handleAddSnackbarToStack}
                                  isCollectionError={isCollectionError}
                                  isLoadingCollection={isLoadingCollection}
                                  tabsMenuOpen={tabsMenuOpen}
                              />}
                          path=':workspaceid/:collectionid?'
                      />

                  </Routes> */}
        </div>
      ) : globalState.workspaces.length === 0 ? null : ( //hasn't intiated, display nothing until loads
        <>
          {isLoadingCollections ? (
            <Grid item lg={tabsMenuOpen ? 6 : 4} md={tabsMenuOpen ? 12 : 6} sm={12} xl={tabsMenuOpen ? 4 : 3}>
              <Paper
                style={{
                  width: "99%",
                  minHeight: "255px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ededed",
                  borderRadius: "8px",
                  opacity: 0.5,
                }}
              >
                <CircularProgress color="primary" />
              </Paper>
            </Grid>
          ) : createDragCollection ? (
            <Grid item lg={tabsMenuOpen ? 6 : 4} md={tabsMenuOpen ? 12 : 6} sm={12} xl={tabsMenuOpen ? 4 : 3}>
              <div
                style={{
                  border: "2px solid",
                  borderRadius: "6px",
                  borderColor: "rgb(61, 90, 254, 0.4)",
                  width: "100%",
                  minHeight: "255px",
                  heigth: "100%",
                  backgroundColor: "rgb(61, 90, 254, 0.20)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ opacity: 0.4 }}> Create new collection </Typography>
              </div>
            </Grid>
          ) : null}
        </>
      )}
    </div>
  );
}

export default CollectionsContainer;
