import React, {useState, useEffect} from 'react';
import useGlobal from '../../GlobalState/Store/Store.js';
import { useTheme, styled } from '@mui/material/styles';

import EmojiComponent from '../Emojis/EmojiComponent';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import {
  Typography,
  InputBase,
  MenuItem,
  Popper,
  Popover,
  Menu,

} from '@mui/material';

import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import useWorkspaces from '../../Hooks/useWorkspaces.js';
import { DefaultPopover } from './DefaultPopover.js';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    borderRadius: 0,
    border: 'none'
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 4,
    paddingBottom: 4,
    overflowX: 'hidden',
    [`& .${autocompleteClasses.option}`]: {
      minWidth: 0,
      minHeight: 'auto',
      alignItems: 'flex-start',
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4, 
      fontSize: 14,
      // borderBottom: `1px solid  ${
      //   theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      // }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

// function PopperComponent(props) {
//   const { disablePortal, anchorEl, open, ...other } = props;
//   return <StyledAutocompletePopper {...other} />;
// }

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popover)(({ theme }) => ({
  overflow: 'hidden',
  // border: `1px solid ${theme.palette.divider}`,
  // boxShadow: theme.palette.shadows.xl,
  // borderRadius: 6,
  // width: 350,
  zIndex: theme.zIndex.modal,

}));

export default function MoveCollectionMenu({
    anchorEl,
    handleClose,
    handleMoveCollection,
    inDialog
}) {
  const [globalState, globalActions] = useGlobal()
  const theme = useTheme();
  const open = Boolean(anchorEl); // props.open


  const { workspacesData, isLoadingWorkspaces } = useWorkspaces(globalState.isAuth, {revalidateOnMount: false})
  let workspaces = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.filter(w => w && w._id !== globalState.activeWorkspace._id) : []
  let loading = isLoadingWorkspaces


  const handleAutoCompleteKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode
    //console.log({event, charCode})
    if(charCode == 13 || charCode === 27 || charCode === 38 || charCode === 40) {
        // keep current escape, and arrow key behaviour, ENTER
        event.stopPropagation()
    } 
}
const id = open ? 'move-collection' : undefined;
  return (
      <>
          {
            inDialog ?   
                <DefaultPopover
                    PaperProps={{
                      style: {
                        marginLeft: 12,
                        // marginTop: -2
                      }
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    id={id}
                    onClose={handleClose}
                    open={open}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                >
                    <Autocomplete 
                        PopperComponent={PopperComponent}
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        loading={loading}
                        noOptionsText="No workspaces"

                        onChange={((event, selected, reason) => handleMoveCollection(event, selected))}
                        onClose={(event, reason) => {
                          if (reason === 'escape') {
                            handleClose(event);
                          }
                        }}
                        onKeyDown={handleAutoCompleteKeyPress}
                        open={open}
                        options={workspaces}
                        renderInput={(params) => (
                            <div style={{width: 350}}>
                                <BootstrapInput
                                    autoFocus
                                    fullWidth
                                    inputProps={params.inputProps}
                                    placeholder="Move to..."
                                    ref={params.InputProps.ref}
                                    style={{
                          padding: 10,
                          width: '100%',
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          '& input': {
                            borderRadius: 4,
                            backgroundColor: theme.palette.background.paper,
                            padding: 8,
                            },
                        }}
                                />
                            </div>
              )}
                        renderOption={(props, workspace, { selected }) => (
                            <MenuItem
                                {...props}
                                key={workspace._id}
                            >
                                <div 
                                    style={{
                  borderRadius: 4,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 4,
                  marginRight: 4, 
                  fontSize: 14,
                  width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textOverflow: "ellipsis", overflow: "hidden"
                  }}
                                >
                                    <div
                                        style={{
                    width: '24px',
                    // height: '24px',
                    borderRadius: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '5px',
                    //border: '0.5px solid'
                  }}
                                    >
                                        {
      //EMOJI
      workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ?
          <EmojiComponent
              size={18}
              symbol={workspace.icon.emoji.emoji}
          />
      :
      //COLOR
      workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ?
          <div style={{width: 12, height: 12, backgroundColor:  workspace.icon.colorIcon.hex, borderRadius: '100%',}} />
      :
          <FolderOpenRoundedIcon fontSize="small" />
      }

                                    </div>
                                    <Typography
                                        noWrap
                                        sx={{color: 'text.primary', marginBottom: 3, margin: 0,}}
                                        variant="body2"
                                    >
                                        {workspace.name}
                                    </Typography>

                                </div>
                            </MenuItem>
              )}
                        renderTags={() => null}
                    />
                </DefaultPopover>
            :
                <Autocomplete 
                    PopperComponent={PopperComponent}
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    loading={loading}
                    noOptionsText="No workspaces"

                    onChange={((event, selected, reason) => handleMoveCollection(event, selected))}
                    onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose(event);
                }
              }}
                    onKeyDown={handleAutoCompleteKeyPress}
                    open={open}
                    options={workspaces}
                    renderInput={(params) => (
                        <div style={{width: 350}}>
                            <BootstrapInput
                                autoFocus
                                fullWidth
                                inputProps={params.inputProps}
                                placeholder="Move to..."
                                ref={params.InputProps.ref}
                                style={{
            padding: 10,
            width: '100%',
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& input': {
              borderRadius: 4,
              backgroundColor: theme.palette.background.paper,
              padding: 8,
              },
          }}
                            />
                        </div>
)}
                    renderOption={(props, workspace, { selected }) => (
                        <MenuItem
                            {...props}
                            key={workspace._id}
                        >
                            <div 
                                style={{
    borderRadius: 4,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 4,
    marginRight: 4, 
    fontSize: 14,
    width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textOverflow: "ellipsis", overflow: "hidden"
    }}
                            >
                                <div
                                    style={{
      width: '24px',
      // height: '24px',
      borderRadius: '2px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5px',
      //border: '0.5px solid'
    }}
                                >
                                    {
//EMOJI
workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ?
    <EmojiComponent
        size={18}
        symbol={workspace.icon.emoji.emoji}
    />
:
//COLOR
workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ?
    <div style={{width: 12, height: 12, backgroundColor:  workspace.icon.colorIcon.hex, borderRadius: '100%',}} />
:
    <FolderOpenRoundedIcon fontSize="small" />
}

                                </div>
                                <Typography
                                    noWrap
                                    sx={{color: 'text.primary', marginBottom: 3, margin: 0,}}
                                    variant="body2"
                                >
                                    {workspace.name}
                                </Typography>

                            </div>
                        </MenuItem>
)}
                    renderTags={() => null}
                />
          }
      </>
     
  );
}


//WORKS BUT ANCHOR SUCKS
{/* <StyledPopper
          anchorEl={anchorEl}
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right',
          // }}
          id={id}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [100, -60], //icon button offset: [-160, -28],
              },
            },
          ]}
          //placement="bottom-end"
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right',
          // }}
          open={open}
      >
          <ClickAwayListener onClickAway={handleClose}>
              <div>
                  <Autocomplete
                      PopperComponent={PopperComponent}
                      classes={{
                        root: { width: 350, fontSize: 13, height: 400},
                        paper: {
                          boxShadow: 'none',
                          margin: 0,
                          color: '#586069',
                          fontSize: 13,
                        },
                        option: {
                          minHeight: 18,
                          alignItems: 'flex-start',
                          padding: 4,
                          '&[aria-selected="true"]': {
                            backgroundColor: 'transparent',
                          },
                          '&[data-focus="true"]': {
                            backgroundColor: theme.palette.action.hover,
                          },
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                        popperDisablePortal: { position: 'relative', border: '1px solid black'},
                        groupLabel: {
                          opacity: 1,
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                          textOverflow: "ellipsis", overflow: "hidden",
                          padding: 12,
                          lineHeight: 1.25,
                          backgroundColor: theme.palette.background.default,
                          borderRadius: 0
                        },
                      }}
                      disablePortal
                      fullWidth
                      getOptionLabel={(option) => option.name}
                      loading={loading} 
                      noOptionsText="No workspaces"
                      onChange={((event, selected, reason) => handleMoveCollection(event, selected))}

                      onClose={handleClose}
                      open={open}
                      options={workspaces}
                      renderInput={(params) => (
                          <BootstrapInput
                              autoFocus
                              fullWidth
                              inputProps={params.inputProps}
                              placeholder="Move to..."
                              ref={params.InputProps.ref}
                              style={{
                        padding: 10,
                        width: '100%',
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        '& input': {
                          borderRadius: 4,
                          backgroundColor: theme.palette.background.paper,
                          padding: 8,
                          },
                      }}
                          />
                      )}

                      renderOption={(props, workspace, { selected }) => (
                          <MenuItem {...props}>
                              <div 
                                  style={{
                                  borderRadius: 4,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  marginLeft: 4,
                                  marginRight: 4, 
                                  fontSize: 14,
                                  width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textOverflow: "ellipsis", overflow: "hidden"
                                  }}
                              >
                                  <div
                                      style={{
                                    width: '24px',
                                    // height: '24px',
                                    backgroundColor: 'rgba(110, 110, 110, 0)',
                                    borderRadius: '2px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '5px',
                                    //border: '0.5px solid'
                                  }}
                                  >
                                      {
                      //EMOJI
                      workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ?
                          <EmojiComponent
                              size={18}
                              symbol={workspace.icon.emoji.emoji}
                          />
                      :
                      //COLOR
                      workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ?
                          <div style={{width: 12, height: 12, backgroundColor:  workspace.icon.colorIcon.hex, borderRadius: '100%',}} />
                      :
                          <FolderOpenRoundedIcon fontSize="small" />
                    }
                  
                                  </div>
                                  <Typography
                                      noWrap
                                      sx={{color: 'text.primary', marginBottom: 3, margin: 0,}}
                                      variant="body2"
                                  >
                                      {workspace.name}
                                  </Typography>

                              </div>
                          </MenuItem>
                      )}

                      renderTags={() => null}
                  />
              </div>
          </ClickAwayListener>
      </StyledPopper> */}



// <Popper
// PaperProps={{
//   root: {
//     overflow: 'none'
//   },
//   sx: {
//     boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     border: `2px solid ${theme.palette.primary.main}`,
//     borderRadius: 1,
//     padding: 0,
//     overflow: 'none',
//   }
// }}
// anchorEl={anchorEl ? anchorEl : null}
// anchorOrigin={{ vertical: props.anchorOriginY ? props.anchorOriginY : "top", horizontal: "right" }}
// anchorPosition={
// props.secondClickPosition ?
// props.secondClickPosition.mouseY !== null && props.secondClickPosition.mouseX !== null
//   ? { top: props.secondClickPosition.mouseY, left: props.secondClickPosition.mouseX}
//   : undefined
// :
// null
// }
// anchorReference={props.secondClickPosition && props.secondClickPosition.mouseY !== null ? "anchorPosition" : "anchorEl"}
// id="long-menu"
// keepMounted
// onClose={handleClose}
// open={open}
// transformOrigin={{ vertical: "top", horizontal: props.secondClickPosition && props.secondClickPosition.mouseY !== null ? "left" : "right" }} 
// >
// <Autocomplete
    // ListboxProps={
    //       {
    //         style:{
                
    //             border: '1px solid red'
    //         }
    //       }
    //     }
//     classes={{
//           root: { width: 350, fontSize: 13, height: 400},
//           paper: {
//             boxShadow: 'none',
//             margin: 0,
//             color: '#586069',
//             fontSize: 13,
//           },
//           option: {
//             minHeight: 18,
//             alignItems: 'flex-start',
//             padding: 4,
//             '&[aria-selected="true"]': {
//               backgroundColor: 'transparent',
//             },
//             '&[data-focus="true"]': {
//               backgroundColor: theme.palette.action.hover,
//             },
//             borderBottom: `1px solid ${theme.palette.divider}`,
//           },
//           popperDisablePortal: { position: 'relative', border: '1px solid black'},
//           groupLabel: {
//             opacity: 1,
//             color: theme.palette.text.secondary,
//             fontSize: 12,
//             textOverflow: "ellipsis", overflow: "hidden",
//             padding: 12,
//             lineHeight: 1.25,
//             backgroundColor: theme.palette.background.default,
//             borderRadius: 0
//           },
//         }}
//     disablePortal
//     fullWidth
//     getOptionLabel={(option) => option.name}
//     loading={loading} 
//     noOptionsText="No workspaces"
//     onChange={((event, selected, reason) => handleMoveCollection(event, selected))}

//     onClose={handleClose}
//     open={open}
//     options={workspaces}
//     renderInput={(params) => (
//         <BootstrapInput
//             autoFocus
//             fullWidth
//             inputProps={params.inputProps}
//             placeholder="Move to..."
//             ref={params.InputProps.ref}
//             style={{
//               padding: 10,
//               width: '100%',
//               borderBottom: `1px solid ${theme.palette.divider}`,
//               '& input': {
//                 borderRadius: 4,
//                 backgroundColor: theme.palette.background.paper,
//                 padding: 8,
//                 },
//             }}
//         />
//         )}

//     renderOption={(props, workspace, { selected }) => (
//         <MenuItem {...props}>
//             <div style={{padding: 4, paddingRight: 0, paddingLeft: 10, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textOverflow: "ellipsis", overflow: "hidden"}}>
//                 <div
//                     style={{
//                     width: '24px',
//                     // height: '24px',
//                     backgroundColor: 'rgba(110, 110, 110, 0)',
//                     borderRadius: '2px',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     marginRight: '5px',
//                     //border: '0.5px solid'
//                   }}
//                 >
//                     {
//             //EMOJI
//             workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ?
//                 <EmojiComponent
//                     size={18}
//                     symbol={workspace.icon.emoji.emoji}
//                 />
//             :
//             //COLOR
//             workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ?
//                 <div style={{width: 12, height: 12, backgroundColor:  workspace.icon.colorIcon.hex, borderRadius: '100%',}} />
//             :
//                 <FolderOpenRoundedIcon fontSize="small" />
//           }
        
//                 </div>
//                 <Typography
//                     noWrap
//                     sx={{color: 'text.primary', marginBottom: 3, margin: 0,}}
//                     variant="body2"
//                 >
//                     {workspace.name}
//                 </Typography>

//             </div>
//         </MenuItem>
//         )}

//     renderTags={() => null}
// />
// </Popper>