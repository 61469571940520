import React from "react";
import axios from "axios";

import { ListItemIcon, Typography, Divider, MenuItem } from "@mui/material";

import { DeleteOutlineRounded } from "@mui/icons-material";

import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";

// import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import useGlobal from "../../GlobalState/Store/Store.js";

import { useSnackbar } from "notistack";

import { DefaultMenu } from "../../Components/Menus/DefaultMenu.js";
import sessionTimeString from "../../Utils/sessionTimeString.js";
import useRecentSessions from "../../Hooks/useRecentSessions.js";
import PlainSnackBar from "../../Components/Notistack/PlainSnackBar.js";

const SessionMenu = ({
  anchorEl,
  clickPosition,
  setSessionMenuClickPosition,
  session,
  handleClose,
  toggleShowSessionHistory,
  handleSelectPrevious,
  handleOpenSaveSessionMenu,
  setFocusSelected,
  handleSelectSession,
}) => {
  const [globalState, globalActions] = useGlobal();
  const { mutate } = useRecentSessions(globalState.isAuth, { revalidateAll: true });
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl) || clickPosition !== null;
  //console.log({session})

  //COPY URL
  const copyURLsToClipBoard = async () => {
    try {
      const urls = session.tabs.map((t) => t.url).join("\n");
      // console.log(urls)
      await navigator.clipboard.writeText(urls);
      let message = session.tabs.length > 1 ? `🔗 Copied ${session.tabs.length} links to clipboard` : "🔗 Copied 1 link to clipboard";
      const success = () => {
        handleMenuClosed(); //handleClose()
        // enqueueSnackbar(message, {variant: 'plain'})
        enqueueSnackbar(message, {
          content: (key, message) => <PlainSnackBar id={key} message={message} />,
        });
      };
      return success();
      //setCopySuccess('Copied!');
    } catch (err) {
      return false;
      //setCopySuccess('Failed to copy!');
    }
  };

  const handleMenuClosed = (event) => {
    // props.handleCloseMoveCollection(event)
    setSessionMenuClickPosition(null);
    setFocusSelected(null);
    handleClose(event);

    // var charCode = event.which || event.charCode || event.keyCode
    // // if(charCode == 27){
    // //   //ESC Key
    // //   handleClose()
    // //   return
    // // }
  };

  const popoverActions = React.useRef();

  if (popoverActions.current) {
    popoverActions.current.updatePosition();
  }

  //SESSION HISTORY
  const handleClickShowSessionHistory = (event, id) => {
    handleSelectSession(session._id);
    handleMenuClosed(event); //handleClose()
    toggleShowSessionHistory(id);
  };

  //DELETE SESSION AND IT's HISTORY
  const DeleteSession = async (event, _sessionId) => {
    event.preventDefault();

    //helper
    function findIndices(arr, sessionId) {
      const initialIndex = arr.findIndex((innerArr) => innerArr.sessions.findIndex((obj) => obj._sessionId === sessionId) !== -1);
      if (initialIndex === -1) return [-1, -1];
      const nestedIndex = arr[initialIndex].sessions.findIndex((obj) => obj._sessionId === sessionId);
      return [initialIndex, nestedIndex];
    }

    // console.log(_sessionId)
    mutate(
      async (sessionsData) => {
        // remove the collection

        if (sessionsData) {
          // console.log({ sessionsData });
          const [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId);

          //console.log({initialIndex, nestedIndex})

          if (nestedIndex > -1) {
            const updatedNestedArray = [
              ...sessionsData[initialIndex].sessions.slice(0, nestedIndex),
              ...sessionsData[initialIndex].sessions.slice(nestedIndex + 1),
            ];
            const updatedArray = [
              ...sessionsData.slice(0, initialIndex),
              { ...sessionsData[initialIndex], sessions: updatedNestedArray },
              ...sessionsData.slice(initialIndex + 1),
            ];

            // console.log({updatedNestedArray, updatedArray})

            return updatedArray;
          } else {
            console.log("didn't find session");
            return sessionsData;
          }
        } else {
          // console.log("no mutate on delete")
          return sessionsData;
        }
      },
      { revalidate: false }
    );

    handleSelectPrevious();

    const data = {
      _sessionId: _sessionId,
    };

    axios
      .post("/sessions/delete", data)
      .then(() => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutate();
      })
      .catch((err) => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutate();
        console.log(err);
      });

    if (open) handleMenuClosed(); //handleClose()
  };

  return (
    <div>
      <DefaultMenu
        action={popoverActions}
        anchorEl={anchorEl}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        anchorOrigin={{
          vertical: clickPosition !== null && clickPosition.mouseY !== null ? "center" : "bottom", //'bottom',
          horizontal: "right", //clickPosition !== null && clickPosition.mouseY !== null ? 'left' : 'right',
        }}
        anchorPosition={clickPosition !== null ? { top: clickPosition.mouseY, left: clickPosition.mouseX } : undefined}
        // anchorReference={clickPosition !== null && clickPosition.mouseY !== null ? "anchorPosition" : "anchorEl"}
        disableScrollLock
        id="move-session-menu"
        onClose={handleMenuClosed}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5 }}
          variant="body2"
        >
          SESSION ACTIONS
        </Typography>
        <MenuItem
          button
          //disabled={session.tabs && session.tabs.length ? false : true}
          onClick={handleOpenSaveSessionMenu}
          style={{ minWidth: 250 }}
        >
          <ListItemIcon>
            <SaveAltRoundedIcon fontSize="small" />
          </ListItemIcon>
          Save as Collection
        </MenuItem>
        <MenuItem
          button
          //disabled={session.tabs && session.tabs.length ? false : true}
          onClick={(e) => copyURLsToClipBoard(e)}
          style={{ minWidth: 250 }}
        >
          <ListItemIcon>
            <InsertLinkRoundedIcon fontSize="small" style={{ transform: "rotate(-45deg)" }} />
          </ListItemIcon>
          Copy all Links
        </MenuItem>
        <Divider />
        <MenuItem
          button
          //disabled={session.tabs && session.tabs.length ? false : true}
          onClick={(e) => handleClickShowSessionHistory(e, session._id)}
          style={{ minWidth: 250 }}
        >
          <ListItemIcon>
            <HistoryRoundedIcon fontSize="small" />
          </ListItemIcon>
          Show Session History
        </MenuItem>
        <Divider />
        <MenuItem button onClick={(e) => DeleteSession(e, session._sessionId)} style={{ color: "#f44336" }}>
          <ListItemIcon>
            <DeleteOutlineRounded fontSize="small" style={{ color: "#f44336" }} />
          </ListItemIcon>
          Delete session
        </MenuItem>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 4,
            paddingBottom: 4,
          }}
        >
          {session && session.createdOn && (
            <Typography sx={{ color: "text.tertiary" }} variant="caption">
              Created {sessionTimeString(session.createdOn)}
            </Typography>
          )}
          {session && session.createdOn && (
            <Typography sx={{ color: "text.tertiary" }} variant="caption">
              Updated {sessionTimeString(session.lastUpdated)}
            </Typography>
          )}
        </div>
      </DefaultMenu>
    </div>
  );
};

export default SessionMenu;

{
  /* <MenuItem
button
onClick={handleDuplicate}
>
<ListItemIcon>
    <FileCopyOutlinedIcon fontSize="small" />
</ListItemIcon>
Duplicate session
</MenuItem> */
}
