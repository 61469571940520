import { useState, useRef, useEffect, MutableRefObject } from "react";

export function useIntersectionObserver(ref, options, forward) {
  const [element, setElement] = useState(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef(null);

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  // useEffect(() => {
  //   console.log(ref)
  //   setElement(ref.current);
  // }, [ref]);

  useEffect(() => {
    if (!ref.current) return;
    // cleanOb();
    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanOb();
        }
      },
      { ...options }
    ));
    ob.observe(ref.current);
    return () => {
      cleanOb();
    };
  }, [ref, options]);

  return isIntersecting;
}
