import React, { useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";

import { Paper, Typography, Checkbox, Tooltip } from "@mui/material";

import defaultFavIconLight from "../Assets/defaultFaviconLight.svg";
import defaultFavIconDark from "../Assets/defaultFaviconDark.svg";
import useDarkMode from "../Utils/theme/useDarkMode";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

function LinkCard(props) {
  const theme = useTheme();
  const { isDarkMode } = useDarkMode("light");

  const tab = props.tab;
  const parentWindow = props.window;
  const [hover, setHover] = useState(false);
  const selected = props.selected;
  const setSelected = props.setSelected;
  const isSelected =
    selected && selected.find((window) => window.tabs.find((tabinarray) => tabinarray.id == tab.id))
      ? selected.find((window) => window.tabs.find((tabinarray) => tabinarray.id == tab.id))
      : false; //selected.find(window => window.tabs.indexOf(tab.id) != -1)
  const FaviconURL =
    tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
      ? ""
      : tab.favIconUrl.replace(/^http:\/\//, "https://");

  // console.log(window)
  // console.log(selected)

  function setTabSelected(e, from) {
    e.preventDefault();
    e.stopPropagation();

    setSelected(parentWindow.id, tab);
  }

  return (
    <Paper
      onMouseDown={(e) => setTabSelected(e, "body click")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 4,
        marginTop: 6,
        marginBottom: 6,
        width: "100%",

        border: isSelected ? "2px solid #3D5AFE" : `2px solid ${theme.palette.divider}`, //rgb(196, 196, 196, 0.35)

        backgroundColor: hover ? theme.palette.collection.hover : theme.palette.background.paper,
        boxShadow: theme.palette.shadows.small,
        borderRadius: 4,
        cursor: "pointer",
      }}
    >
      <Tooltip aria-label="add" disableInteractive title="Select tab">
        <Checkbox
          checked={isSelected !== false}
          //onChange={(e) => setTabSelected(e, "checkbox")}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          size="small"
        />
      </Tooltip>
      {FaviconURL !== "" ? (
        <img
          color={theme.palette.text.secondary}
          fill={theme.palette.text.secondary}
          height={props.in == "collection" ? 20 : 24}
          mode="fit"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            isDarkMode ? (currentTarget.src = defaultFavIconDark) : (currentTarget.src = defaultFavIconLight);
          }}
          src={FaviconURL}
          style={{ minWidth: props.in == "collection" ? 20 : 24, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
          width={props.in == "collection" ? 20 : 24}
        />
      ) : (
        <LanguageRoundedIcon sx={{ color: "text.secondary", width: props.in == "collection" ? 20 : 24, height: props.in == "collection" ? 20 : 24 }} />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          marginLeft: "10px",
          //minWidth: '100%',//'calc(100% - 0px)', //used to be - 80px
          maxWidth: "calc(100% - 75px)",
          minWidth: "calc(100% - 75px)",
        }}
      >
        <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
          <Typography
            m={0}
            noWrap
            style={{
              fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
              fontWeight: 600,
              color: theme.palette.text.primary,
              fontSize: 13,
            }}
            variant="subtitle2"
          >
            {tab.title}
          </Typography>
          <Typography
            m={0}
            noWrap
            style={{
              fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              color: theme.palette.text.secondary,
              fontSize: 11,
            }}
            variant="caption"
          >
            {tab.url}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default LinkCard;
