import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,

  Typography,

} from '@mui/material';

export default function ConfirmDeletePropertyDialog(props) {

  const open = props.open
  const handleClick = props.handleClick
  const handleClose = props.handleClose
  const confirm = props.confirm

  const isLoading = props.isLoading

  const property = props.property

  const state = props.state

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode
    //console.log("key " + charCode + " pressed")
    if(charCode == 13){
      //console.log("Enter key pressed")
       //if enter is pressed login
      confirm(event)
    }
  }

  return (
      <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleClose}
          open={open}
          style={{padding: 20}}
      >
          <DialogContent style={{maxWidth: 350, width: 350}}>
              <DialogContentText
                  id="alert-dialog-description"
                  style={{marginBottom: 25,}}
                  sx={{
                    color: 'text.primary]',
                }}
              >
                  Are you sure you want to delete
                  {
            props && props.property && props.property.name ?
                <strong>
                    {' '}
                    '
                    {props.property.name}
                    '
                    {' '}
                </strong>
            :
            " this property"
          }
                  ?
              </DialogContentText>
              {
            state.isDeleteColumn ?
                <Typography
                    style={{ marginBottom: 25}}
                    sx={{
                    color: 'text.secondary',
                }}
                    variant="body2"
                >
                    All collections inside this column will be deleted
                </Typography>
            :
            null
          }

              <div style={{
                 margin: "24px, 0px, 0px, 0px",
                 position: 'relative',
              }}
              >
                  <Button
                      autoFocus
                      color="secondary"
                      disabled={isLoading}
                      fullWidth
                      onClick={(e) => confirm(e)}
                      style={{marginBottom: 10}}
                      variant="contained"
                  >
                      {state.confirmText}
                  </Button>
                  {isLoading && <CircularProgress
                      color="secondary"
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                                />}
              </div>
              <Button
                  color="error"
                  fullWidth
                  onClick={handleClose}
                  style={{marginBottom: 10}}
                  variant="outlined"
              >
                  {state.cancelText ? state.cancelText : "Cancel"}
              </Button>
          </DialogContent>
      </Dialog>
  );
}
