import { useState } from "react";
// import useSWR from 'swr'
import useSWRInfinite from "swr/infinite";
import axios from "axios";

const getKey = (pageIndex, previousPageData) => {
  //console.log(pageIndex, previousPageData)
  // reached the end
  if (previousPageData && !previousPageData.cursor) {
    //console.log("end of data")
    return null;
  }

  // first page, we don't have `previousPageData`
  if (pageIndex === 0) return `/sessions/recent`; //`/users?limit=10`

  // add the cursor to the API endpoint
  return `/sessions/recent?cursor=${previousPageData.cursor}`;
};

const fetcher = (url) => axios.get(url, { withCredentials: true }).then((res) => res.data);

function useRecentSessions(isAuth, options, skipFetch) {
  const { data, error, isValidating, size, setSize, mutate } = useSWRInfinite(isAuth && !skipFetch ? getKey : null, fetcher, options ? options : null);

  //isAuth ? `/sessions/recent` : null //getKey replace this
  // console.log({data})
  let allSessionsArray = data && data.length ? data.reduce((acc, item) => acc.concat(item.sessions), []) : [];
  let hasMoreSessions = data && data.length ? !!data[data.length - 1].cursor : false;

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined") ? true : false;
  const isEmpty = data?.[0]?.length === 0;

  return {
    allSessions: allSessionsArray,
    hasMoreSessions: hasMoreSessions,
    isLoadingInitialData,
    isLoadingMore,
    isLoadingSessions: !error && !data,
    isValidatingSessions: isValidating,
    isSessionsError: error,
    pageSize: size,
    setPageSize: setSize,
    mutate: mutate,
  };
}

export default useRecentSessions;
