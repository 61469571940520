export default function sessionHistoryTimeString(isoDate){ 
    let baseDate = new Date(isoDate)
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    let today =  new Date()
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let month = baseDate.getMonth()
    let shortMonth = shortMonths[month]
    let date = baseDate.getDate()
    let day = days[baseDate.getDay()]
    let year = baseDate.getFullYear()
    let time = baseDate.toLocaleTimeString([], {timeStyle: 'short'})

    let diffToToday = (today - baseDate) / 1000 //returns difference in seconds
    const diffMins = Math.floor(diffToToday / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffYears = Math.floor(diffDays / 365);

    let isToday = date == today.getDate() && month == today.getMonth() && year == today.getFullYear()
    let isYesterday = date == yesterday.getDate() && month == yesterday.getMonth() && year == yesterday.getFullYear()
    
    if(diffMins < 1) {
        return "Just now"
    }else if(diffMins == 1) {
        return diffMins + " min" + " ago"
    } else if(diffMins > 1 && diffMins < 60) {
        return diffMins + " mins" + " ago"
    } else if(diffHours >= 1 && diffHours < 24 && isToday) {
        return "Today at " + time
    } else if(isYesterday) {
        return "Yesterday at " + time
    } else if(diffHours >= 24 && diffDays <= 7) {
        return day + " at " + time
    } else if(diffYears < 1){
        return shortMonth + " " + date + " - " + time
        //+ " — " + time    
    }
    else if (diffYears >= 1) {
        return shortMonth + " " + date + ", " + year
    }
    else{
        return shortMonth + " " + date + ", " + year + " " + time     
    } 

}