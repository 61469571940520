import React from "react";

import { Menu, MenuItem, ListItemIcon, Divider, ListItemText } from "@mui/material";

import { DefaultMenu } from "./DefaultMenu";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

export default function HelpFabMenu(props) {
  const handleClose = props.handleClose;
  const anchorEl = props.anchor;

  return (
    <DefaultMenu
      MenuListProps={{ dense: true }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MenuItem
        //   button
        component="a"
        dense
        href="https://www.partizion.io/guide/tour"
        target="_blank"
      >
        <ListItemIcon>
          <MenuBookRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Help and Guides" />
      </MenuItem>

      <MenuItem
        //   button
        component="a"
        dense
        href="mailto:support@partizion.io?subject=Partizion App Support"
        target="_blank"
      >
        <ListItemIcon size="small">
          <ChatBubbleOutlineRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Support" />
      </MenuItem>
      <MenuItem button component="a" dense href="mailto:support@partizion.io?subject=💡 Feature Request" target="_blank">
        <ListItemIcon>
          <EmojiObjectsOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Suggest a feature" />
      </MenuItem>
      <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      <MenuItem
        //   button
        component="a"
        dense
        href="https://www.partizion.io/changelog"
        target="_blank"
      >
        <ListItemIcon>
          <AutoAwesomeRoundedIcon fontSize="small" style={{ color: "#FF9800" }} />
        </ListItemIcon>
        <ListItemText primary="What's new?" />
      </MenuItem>
      <MenuItem
        //   button
        component="a"
        dense
        href="https://chrome.google.com/webstore/detail/partizion/ldimfpkkjopddckaglpeakpaepclcljn"
        target="_blank"
      >
        <ListItemIcon>
          <StarRoundedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Enjoying Partizion? Rate us!" />
      </MenuItem>

      <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      <MenuItem
        //   button
        component="a"
        dense
        href="https://twitter.com/partizion"
        target="_blank"
      >
        <ListItemText primary="Twitter — @partizion" sx={{ color: "text.tertiary" }} />
      </MenuItem>
    </DefaultMenu>
  );
}
