import React, { useState, useEffect, useRef} from 'react'

import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from '../../DND/ItemTypes'

import {
  CircularProgress,
  Grid,
  Typography,
  Button,
} from '@mui/material';

import Add from '@mui/icons-material/Add';
import InboxRoundedIcon from '@mui/icons-material/InboxRounded';

import {colors as labelColors} from '../../../Utils/StandardColors.js';
import { useTheme, styled } from '@mui/material/styles';

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  '&:hover': {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
  
}));

const PlainColumnButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.button.plain,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  minHeight: 0,

  '&:hover': {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
  
}));

function KanbanColumn(props){
  let kanbanColumnMounted = true
  const theme = useTheme();

  const createDragCollection = props.createDragCollection
  const columnWidth = props.columnWidth
  const columnIndex = props.columnIndex

  let dragMousePosition = props.dragMousePosition

  let group = props.group
  // const [group, setGroup] = useState(props.group)
  //
  // useEffect(() => {
  //   console.log(props.group)
  //   console.log(props)
  //   setGroup(props.group)
  // }, [props.groupedCollections])

  const groupBy = props.groupBy
  const createReq = props.createReq
  const renderListItem = props.renderListItem
  const handleOpenEditValue = props.handleOpenEditValue

  const columnDragActive = props.columnDragActive
  const setColumnDragActive = props.setColumnDragActive
  const collectionDragActive = props.collectionDragActive

  const moveColumn = props.moveColumn
  const SaveColumnPosition = props.SaveColumnPosition
  const moveCollectionCardInKanban = props.moveCollectionCardInKanban

  const [hover, setHover] = useState(false)
  const showDropInColumn = props.showDropInColumn
  const setShowDropInColumn = props.setShowDropInColumn
  const setShowCreateCollectionWithDrag = props.setShowCreateCollectionWithDrag
  const showCreateCollectionWithDrag = props.showCreateCollectionWithDrag

  const columnRef = useRef(null)
  const columnHeaderRef = useRef(null)

  const [{isOver}, drop] = useDrop({
    accept: [ItemTypes.COLLECTIONCARD, ItemTypes.KANBANCOLUMN, ItemTypes.CURTAB, ItemTypes.PASTTAB, ItemTypes.CURWINDOW, ItemTypes.PASTSESSION],
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor && columnRef.current ? monitor.canDrop() : false,
      isReadytoDrop: monitor && columnRef.current ? monitor.isReadytoDrop : false,
    }),
    hover(item, monitor) {
      // isHovered: monitor.isOver()

      const isHoverColumn = monitor && monitor.isOver({ shallow: true }) ? true : false
      //console.log(item.type, isHoverColumn, showCreateCollectionWithDrag !== group._id)
      //console.log(item.type + " is hovering", {isHoverColumn, showCreateCollectionWithDrag})
      //console.log({group, groupBy}, props.id)

      if (!columnRef.current) {
        return
      }
      const dragIndex = item.columnIndex
      const hoverIndex = columnIndex
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      if(item.type === 'kanbancolumn'){
        //console.log({dragIndex, hoverIndex, item})
        //only rearrange collections if its another collection being dragged on it
        moveColumn(dragIndex, hoverIndex, item.id, props.id)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.columnIndex = hoverIndex

      }
      else if(item.type === 'collectioncard' && group._id !== item.kanbanGroup._id && group.collections && isHoverColumn){
        if(!!dragMousePosition && showDropInColumn || isHoverColumn == false && showDropInColumn){
          setShowDropInColumn(false)
        }
        else if(item.type === 'collectioncard' && !showDropInColumn && !dragMousePosition){ //&& group.collections.length == 0 HANDLE HOVERIGN OVER AN EMPTY COLUMN
          setShowDropInColumn(group._id)
        }
      }
      else if(
        item.type === 'curtab' && isHoverColumn && showCreateCollectionWithDrag !== group._id
        || item.type === 'pasttab' && isHoverColumn && showCreateCollectionWithDrag !== group._id
        || item.type === 'curwindow' && isHoverColumn && showCreateCollectionWithDrag !== group._id
        || item.type === 'pastsession' && isHoverColumn && showCreateCollectionWithDrag !== group._id
      ){ //&& group.collections.length == 0 HANDLE HOVERIGN OVER AN EMPTY COLUMN
        //console.log("create colleciton by tragging tab into column")
        setShowCreateCollectionWithDrag(group._id)
      }
      else if(isHoverColumn == false && showDropInColumn){
        //console.log("RESET SHOW DROP IN COLUMN")
        setShowDropInColumn(false)
      }

    },
    drop(item, monitor){
      setShowCreateCollectionWithDrag(null)
      const didDropInColumn = monitor && !monitor.didDrop() ? true : false

      //console.log(item.type + " droppped in kanabn", didDropInColumn)
      const dragIndex = item.index
      setShowDropInColumn(false)
      if(item.type === 'kanbancolumn'){
        SaveColumnPosition()
      } else if(item.type === 'collectioncard' && group.collections && didDropInColumn){ //&& group.collections.length == 0

        let properDestIndex = group && group.collections && group.collections.length ? group.collections.length : 0 //this is the case where there are no collections in the column
        moveCollectionCardInKanban(null, item.collection, group, item.kanbanGroup._id, dragIndex, properDestIndex) //(collectionId, destColumnId, curColumnIndex, destCollectionIndex, curCollectionIndex)
      } else if(item.type === "curtab" || item.type === "pasttab" || item.type === "curwindow" || item.type === "pastsession"){
        //console.log(item.type + " dropped in KANBAN")
        //console.log(item)
        var tabsToAdd = []
        if(item.selectedTabs && item.selectedTabs.length > 0){
          tabsToAdd = item.selectedTabs
        } else if (item.tabs && item.tabs.length > 0){
          tabsToAdd = item.tabs
        }
        else {
          tabsToAdd.push(item.tab)
        }
        //pass this value to be created as the
        // properties: [...]
        // in the new collection
        let properties = [
          {
            propertyId: groupBy._id,
            values: [group.property._id]
          }
        ]
        if(didDropInColumn) props.CreateCollection(tabsToAdd, item.type, item, properties, groupBy)

        //console.log({group, tabsToAdd, properties})
      }
    }
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.KANBANCOLUMN, id: props.id, columnIndex: props.columnIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin(){
      setColumnDragActive(props.id)
    },
    canDrag(){
      // if(editCollectionName){
      //   return false
      // } else {
      //   return true
      // }
      if(group && group._id == "justcreated"){
        return false
      } else {
        return true
      }
    },
    end(item){
      //console.log("END")
      //MoveWorkspace(targetIndex, workspace, )
      //handleListItemClick(targetIndex, workspace)
      setColumnDragActive(false)
    },
    // canDrag(props) {
    //   return !editTitle
    // },

  })

  
  // drag(columnHeaderRef)
  // drop(columnRef)

  useEffect(() => {
    drag(columnHeaderRef)
    drop(columnRef)
    return () => {
      //celanup
      drag(null);
      drop(null);
    };
  }, [drag, drop, columnHeaderRef, columnRef]);


  //reseting preview line
  useEffect(() => {
    if(kanbanColumnMounted){
      if(!isOver) setShowDropInColumn(null)
    }

    return () => { 
      //cleanup
      kanbanColumnMounted = false 
    }

  }, [isOver])

  return (
      <div
          key={props.id}
          ref={columnRef}
          style={{
        width: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
        minWidth: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
        maxWidth: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
        paddingRight: 20, //columnIndex == groupedCollections.length -1 ? 20 : 0,
        // height: '100%',
        minHeight: 300,
        // position: 'relative',
        //border: 'solid green',
      }}
      >
          <div
              style={{
        height: 40,
        width: '100%',
        position: 'sticky',
        top: 0, //35,
        marginBottom: -40,
        backgroundColor: theme.palette.background.default,
        //borderBottom: '1px solid rgb(196, 196, 196, 0.45)',
        zIndex: 1,

      }}
          />
          <div
              ref={columnHeaderRef}
              style={{
            height: 40,
            width: '100%',
            position: 'sticky',
            top: 0, //35,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'noWrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            //paddingLeft: 10,
            paddingRight: 10,//columnIndex == groupedCollections.length -1 ? 115 : 15,
            //borderBottom: '1px solid rgb(196, 196, 196, 0.45)',
            zIndex: 2,
            borderRadius: 4,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          >
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'noWrap', alignItems: 'center', maxWidth: '70%'}}>
                  <PlainColumnButton
                      disableFocusRipple
                      disableRipple
                      disabled={columnDragActive || group._id == "justcreated" ? true : false}
                      onContextMenu={(e) => handleOpenEditValue(e, {...group.property, propertyId: groupBy._id})}
                      onMouseUp={(e) => handleOpenEditValue(e, {...group.property, propertyId: groupBy._id})}
                      size="small"
                      style={{minWidth: 0}}
                      sx={{
                        backgroundColor: 'transparent',
                        "&:hover": {
                          backgroundColor: props.group.none ? theme.palette.action.hover : theme.palette.mode === "dark" && props.bg && props.bg.r ? `rgba(${props.bg.r}, ${props.bg.g}, ${props.bg.b}, 0.3)` : props.bg && props.bg.r ? `rgba(${props.bg.r}, ${props.bg.g}, ${props.bg.b}, 0.1)` : props.bg,
                        }
                      }}
                  >
                      <div
                          style={{
                          backgroundColor: props.group.none ? 'transparent' : theme.palette.mode === "dark" && props.bg && props.bg.r ? `rgba(${props.bg.r}, ${props.bg.g}, ${props.bg.b}, 0.5)` : props.bg && props.bg.r ? `rgba(${props.bg.r}, ${props.bg.g}, ${props.bg.b}, 0.3)` : props.bg,
                          margin: 0,
                          paddingTop: 2,
                          paddingBottom: 1,
                          paddingLeft: 4,
                          paddingRight: 4,
                          minWidth: 10,
                          borderRadius: 2,

                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                          {
                  props.group.none ?
                      <InboxRoundedIcon style={{width: 18, height: 18, marginRight: 4, color: theme.palette.text.primary}} />
                  :
                  null
                }
                          <Typography
                              noWrap
                              style={{color: props.group.none || theme.palette.mode === "dark" ? theme.palette.text.primary : labelColors.findIndex(l => l.name == props.group.property.color) > -1 ? labelColors.find(l => l.name == props.group.property.color).darker : "#191919", fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600}}
                              variant='body2'
                          >
                              {
                    props.group.property && !props.group.none ?
                    props.group.property.name
                    :
                    props.group.property ?
                    "No " + props.group.property.name 
                    :
                    'None'
                  }
                          </Typography>
                      </div>
                  </PlainColumnButton>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 4, marginLeft: 4, color: theme.palette.text.tertiary}}>
                      <Typography
                          color="textSecondary"
                          noWrap
                          style={{margin: 0}}
                          variant="body2"
                      >
                          {
                group.collections && group.collections.length > 0 ?
                group.collections.length
                :
                0
              }
                      </Typography>
                  </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'noWrap', alignItems: 'center', justifyContent: 'flex-end'}}>
                  <PlainButton
                      disableFocusRipple
                      disabled={group._id == "justcreated"}
                      onClick={(e) => {
                e.target.blur()
                props.openCollectionDialog(e, {properties: [ {propertyId: groupBy._id, values: [group._id]} ] })}

              }
                      size="small"
                      style={{pading: 2, width: 24, height: 24, maxWidth: 24, minWidth: 0, marginLeft: 4}}
                  >
                      <Add style={{width: 20, height: 20, color: theme.palette.text.tertiary, margin: 0}} />
                  </PlainButton>
              </div>
          </div>

          <div
              style={{
          width: '100%',
          maxWidth: columnWidth,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          flexWrap: 'noWrap',
          paddingRight: 10,
          paddingTop: 8,

          position: 'relative'
        }}
          >

              {
          group && group.collections && group.collections.length > 0 && kanbanColumnMounted ?
          group.collections.map((collection, i) => collection ? renderListItem(collection, i, group, showDropInColumn) : null)
          :
          null

        }
              {
          showDropInColumn && showDropInColumn == group._id && !dragMousePosition ?
              <div style={{height: 4, marginLeft: 4, backgroundColor: '#3D5AFE' , width: 'calc(100% - 8px)', left: 0, top: 2, opacity: 0.5}} />
          :
              <div style={{height: 4}} />
        }
              {
          createReq.isLoading && createReq.columnId === group.property._id ?
              <Grid
                  item
                  style={{with: '100%', margin: 3}}
              >
                  <div
                      style={{
                        borderRadius: createDragCollection && isOver ? 6 : 4,
                        border: createDragCollection && isOver ? '2px solid #3D5AFE' : 'transparent',//'1px solid rgba(196, 196, 196, 0.45)',
                        backgroundColor: createDragCollection && isOver ? 'rgb(61, 90, 254, 0.20)' : hover ? theme.palette.collection.hover : theme.palette.collection.default,
                        //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                      }}
                  >
                      <CircularProgress
                          size={24}
                          style={{margin: 10, color: theme.palette.text.tertiary}}
                      />
                  </div>
              </Grid>
          :
              <Grid
                  item
                  style={{with: '100%', margin: 3}}
              >
                  <div
                      onClick={(e) => props.openCollectionDialog(e, group._id && group._id !== 'none' ? {properties: [ {propertyId: groupBy._id, values: [group._id]} ]} : null)}
                      onMouseLeave={() => setHover(null)}
                      onMouseOver={() => setHover(true)}
                      style={{
                        border: showCreateCollectionWithDrag && showCreateCollectionWithDrag === group._id && isOver ? '2px solid #3D5AFE' : '2px solid transparent',//'1px solid rgba(196, 196, 196, 0.45)',
                        borderRadius: showCreateCollectionWithDrag && showCreateCollectionWithDrag === group._id && isOver ? 6 : 4,
                        //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

                        backgroundColor: showCreateCollectionWithDrag && showCreateCollectionWithDrag === group._id && isOver ? 'rgb(61, 90, 254, 0.2)' : hover && !columnDragActive && !collectionDragActive ? theme.palette.action.hover : theme.palette.collection.default,
                        width: '100%',
                        height: '100%',
                        //minHeight: 40,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: 6,
                        cursor: 'pointer',
                      }}
                  >
                      <Add
                          color="disabled"
                          style={{marginRight: 8, color: showCreateCollectionWithDrag && showCreateCollectionWithDrag === group._id && isOver ? '#3d5AFE' : null}}
                      />
                      <Typography
                          color="textSecondary"
                          style={{opacity: 0.75, fontSize: 14, color: showCreateCollectionWithDrag && showCreateCollectionWithDrag === group._id && isOver ? '#3d5AFE' : null}}
                      >
                          {' '}
                          Add collection
                          {' '}
                      </Typography>
                  </div>
              </Grid>
        }
          </div>
      </div>
  )
}

export default KanbanColumn

//MORE VERT BUTTON ON COLUMN
// <Button
//   size="small"
//   disableFocusRipple={true}
//   style={{pading: 2, width: 24, height: 24, maxWidth: 24, minWidth: 0, marginLeft: 4}}
//   onMouseDown={(e) => handleOpenEditValue(e, group.property)}
//   disabled={group._id == "justcreated"}
// >
//   <MoreVert style={{width: 20, height: 20, color: '#C4C4C4', margin: 0}}/>
// </Button>
