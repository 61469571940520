import React from "react";
import useGlobal from "../GlobalState/Store/Store.js";
import axios from "axios";
//BUTTONS
import ViewSelectButton from "../Components/Buttons/ViewSelectButton.js";
import PropertiesButton from "../Components/Buttons/PropertiesButton.js";
import SortButton from "../Components/Buttons/SortButton.js";
import FilterButton from "../Components/Buttons/FilterButton.js";
import GroupByButton from "../Components/Buttons/GroupByButton.js";
import ExpandButton from "../Components/Buttons/ExpandButton.js";
import CollapseButton from "../Components/Buttons/CollapseButton.js";

import useCollections from "../Hooks/useCollections.js";
import useWorkspaces from "../Hooks/useWorkspaces.js";
import { mutate } from "swr";
import update from "immutability-helper";

import { useTheme } from "@mui/material/styles";

import { Typography, Grid, Divider, Button, Tooltip, Skeleton } from "@mui/material";
import HotkeyChip from "../Components/Hotkey/HotkeyChip.js";

export default function ViewBar(props) {
  const theme = useTheme();
  const { GetWorkspacebyID, openCreateCollectionDialog, createCollectionOpen } = props;

  const [globalState, globalActions] = useGlobal();

  const { workspacesData, isLoadingWorkspaces } = useWorkspaces(globalState.isAuth);
  const { collectionsData } = useCollections(
    globalState.activeWorkspace && globalState.activeWorkspace._id ? globalState.activeWorkspace._id : null,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null,
    { revalidateOnMount: false }
  );

  const collectionsLength = collectionsData && collectionsData.collections && collectionsData.collections.length ? collectionsData.collections.length : 0;

  const activeWorkspace =
    workspacesData && workspacesData.workspaces && globalState.activeWorkspaceIndex
      ? workspacesData.workspaces[globalState.activeWorkspaceIndex]
      : globalState.activeWorkspace;

  function EditDefaultView(viewObj, callback) {
    //console.log({viewObj})
    const data = {
      workspaceId: globalState.activeWorkspace._id,
      name: viewObj.name,
      viewType: viewObj.viewType,
      properties: viewObj.properties,
      customProperties: viewObj.customProperties,
      sort: viewObj.sort,
      filter: viewObj.filter,
    };

    axios
      .post("/views/create", data)
      .then((res) => {
        //console.log(res)
        globalActions.setActiveView(res.data.view);

        const workspaceIndex =
          workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;

        //const updatedViewWorkspace = workspaceIndex > -1 ? workspacesData.workspaces[workspaceIndex].views = [...res.data.view] : null

        //mutate(`/workspaces`, {...workspacesData, workspaces: {workspaceIndex: updatedViewWorkspace} }, false)

        //Revalidate
        mutate(`/workspaces`);

        //revalidate the collections
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  }

  let groupBy =
    globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
      ? globalState.activeView &&
        globalState.activeView.kanbanProperties &&
        globalState.activeView.kanbanProperties.groupBy &&
        globalState.activeWorkspace.collectionProperties.findIndex((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id) > -1
        ? globalState.activeView.kanbanProperties.groupBy
        : globalState.activeWorkspace.collectionProperties[0]
          ? globalState.activeWorkspace.collectionProperties[0]
          : null
      : null;

  let possibleProperties =
    globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
      ? globalState.activeWorkspace.collectionProperties.filter((property) => property.type == "singleSelect")
      : [];

  //FULL VIEW
  const collapesExpandFunction = async (e, state) => {
    //console.log("Set " + collectionId + " to " + state)
    e.preventDefault();
    e.stopPropagation();

    let updatedIds = [];
    //let reset = [...expandedCollectionIds]

    if (state === true) {
      //expandall
      updatedIds = collectionsData && collectionsData.collections ? collectionsData.collections.map((c) => c._id) : [];
    }

    // const updatedView = {...globalState.activeView, 'fullViewProperties.expandedCollections': updatedIds}
    // globalActions.setActiveView(updatedView)
    // const updatedView = {...globalState.activeView, 'fullViewProperties.expandedCollections': updatedIds}
    // globalActions.setActiveView(updatedView)

    const data = {
      viewId: globalState.activeView._id,
      expandedCollections: updatedIds,
    };

    //optimistically update UI
    mutate(
      `/workspaces`,
      async (workspacesData) => {
        // let's update the todo with ID `1` to be completed,
        // this API returns the updated data
        const workspaceIndex =
          workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id === globalState.activeWorkspace._id) : -1;
        const viewIndex =
          workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views
            ? workspacesData.workspaces[workspaceIndex].views.findIndex((v) => v._id === globalState.activeView._id)
            : -1;

        if (viewIndex > -1) {
          const updatedView = update(globalState.activeView, { fullViewProperties: { expandedCollections: { $set: updatedIds } } });
          globalActions.setActiveView(updatedView);
          //return workspacesData
          return update(workspacesData, {
            workspaces: { [workspaceIndex]: { views: { [viewIndex]: { fullViewProperties: { expandedCollections: { $set: updatedIds } } } } } },
          });
        } else {
          return workspacesData;
        }
      },
      false
    );

    //call API
    await axios.post("/views/setexpandedcollections", data);

    //Revalidate
    mutate(`/workspaces`);
  };

  //HOTKEYS
  // const createCollectionHotkeyEnabled = () => {
  //   if(!createCollectionOpen) return true
  //   else return false
  // }
  // useHotkeys('c', (HotkeysEvent) => {
  //   //console.log(HotkeysEvent)
  //   HotkeysEvent.preventDefault()
  //   HotkeysEvent.stopPropagation()

  //   console.log("C key hit ")
  //   openCreateCollectionDialog()
  // }, [createCollectionOpen])
  // {
  //   enabled: createCollectionHotkeyEnabled()
  // },
  // },

  return (
    <Grid alignItems="center" container direction="row" justifyContent="flex-start" spacing={3}>
      <Grid item md={4} sm={12}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {isLoadingWorkspaces ? (
            <Skeleton
              height={32}
              style={{
                marginBottom: 8,
                backgroundColor: theme.palette.action.hover,
              }}
              variant="rounded"
              width="150px"
            />
          ) : (
            <ViewSelectButton
              collectionsData={collectionsData} //{globalState.activeWorkspace}
              workspace={activeWorkspace}
            />
          )}
          {collectionsData && collectionsData.collections ? (
            <Typography color="textSecondary" noWrap variant="body2">
              {collectionsData.collections.length} {collectionsData.collections.length == 1 ? "Collection" : "Collections"}
            </Typography>
          ) : null}
        </div>
      </Grid>
      <Grid item md={8} sm={12}>
        <div
          style={{
            mindWidth: 300,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingBottom: 4,
          }}
        >
          {globalState.activeView &&
          globalState.activeView.viewType === "Kanban" &&
          groupBy !== null &&
          !!possibleProperties &&
          possibleProperties.length > 0 ? (
            <>
              <GroupByButton
                EditDefaultView={EditDefaultView}
                GetWorkspacebyID={GetWorkspacebyID}
                possibleProperties={possibleProperties}
                workspace={activeWorkspace}
              />
              <Divider flexItem orientation="vertical" style={{ marginTop: 4, marginBottom: 4 }} />
            </>
          ) : globalState.activeView && globalState.activeView.viewType === "Full" ? (
            <>
              <ExpandButton collapesExpandFunction={collapesExpandFunction} collectionsLength={collectionsLength} />
              <CollapseButton collapesExpandFunction={collapesExpandFunction} />
              <Divider flexItem orientation="vertical" style={{ marginTop: 4, marginBottom: 4 }} />
            </>
          ) : null}
          <PropertiesButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <SortButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <FilterButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <Tooltip
            aria-label="newcollection"
            disableInteractive
            enterDelay={500}
            placement="bottom-end"
            title={
              <React.Fragment>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  Create New Collection{" "}
                  <HotkeyChip
                    // hotkeyTheme="white"
                    keys={["C"]}
                    style={{ marginLeft: 6 }}
                  />
                </div>
              </React.Fragment>
            }
          >
            <span>
              <Button
                color="primary"
                disableRipple
                disabled={isLoadingWorkspaces}
                // disabled={!globalState.extensionConnection.isConnected}
                onClick={openCreateCollectionDialog}
                size="small"
                // startIcon={<AddRoundedIcon />}
                style={{ marginLeft: 6 }}
                variant="contained"
              >
                New Collection{" "}
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
  return (
    <Grid alignItems="center" container direction="row" justifyContent="flex-start" spacing={3}>
      <Grid item md={4} sm={12}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <ViewSelectButton
            collectionsData={collectionsData} //{globalState.activeWorkspace}
            workspace={activeWorkspace}
          />
          {collectionsData && collectionsData.collections ? (
            <Typography color="textSecondary" noWrap variant="body2">
              {collectionsData.collections.length} {collectionsData.collections.length == 1 ? "Collection" : "Collections"}
            </Typography>
          ) : null}
        </div>
      </Grid>
      <Grid item md={8} sm={12}>
        <div
          style={{
            mindWidth: 300,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingBottom: 4,
          }}
        >
          {globalState.activeView &&
          globalState.activeView.viewType === "Kanban" &&
          groupBy !== null &&
          !!possibleProperties &&
          possibleProperties.length > 0 ? (
            <>
              <GroupByButton
                EditDefaultView={EditDefaultView}
                GetWorkspacebyID={GetWorkspacebyID}
                possibleProperties={possibleProperties}
                workspace={activeWorkspace}
              />
              <Divider flexItem orientation="vertical" style={{ marginTop: 4, marginBottom: 4 }} />
            </>
          ) : globalState.activeView && globalState.activeView.viewType === "Full" ? (
            <>
              <ExpandButton collapesExpandFunction={collapesExpandFunction} collectionsLength={collectionsLength} />
              <CollapseButton collapesExpandFunction={collapesExpandFunction} />
              <Divider flexItem orientation="vertical" style={{ marginTop: 4, marginBottom: 4 }} />
            </>
          ) : null}
          <PropertiesButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <SortButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <FilterButton EditDefaultView={EditDefaultView} GetWorkspacebyID={GetWorkspacebyID} workspace={activeWorkspace} />
          <Tooltip
            aria-label="newcollection"
            disableInteractive
            enterDelay={500}
            placement="bottom-end"
            title={
              <React.Fragment>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  Create New Collection{" "}
                  <HotkeyChip
                    // hotkeyTheme="white"
                    keys={["C"]}
                    style={{ marginLeft: 6 }}
                  />
                </div>
              </React.Fragment>
            }
          >
            <span>
              <Button
                color="primary"
                disableRipple
                // disabled={!globalState.extensionConnection.isConnected}
                onClick={openCreateCollectionDialog}
                size="small"
                // startIcon={<AddRoundedIcon />}
                style={{ marginLeft: 6 }}
                variant="contained"
              >
                New Collection{" "}
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
}

// {
//   viewChangeReq.isLoading ?
//   null
//   :
//   <>
//   </>
// }
