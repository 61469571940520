import React, { useEffect, useCallback, memo, useState, useRef } from "react";

import { useTheme } from "@mui/material/styles";

//MUI COMPONENTS
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import HistoryIcon from "@mui/icons-material/History";
import Fade from "@mui/material/Fade";
import { alpha, Link, Tooltip } from "@mui/material";

//CUSTOM COMPONENTS
import Skeleton from "@mui/material/Skeleton";
import sessionTimeString from "../../../Utils/sessionTimeString";

import { motion, AnimatePresence, useIsPresent } from "framer-motion";

import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";

import extractHostname from "../../../Utils/extractHostname";
import axios from "axios";

//VIRTUALIZING
import useWindowSize from "../../../Utils/useWindowSize.js";
import { GroupedVirtuoso } from "react-virtuoso";
import useGlobal from "../../../GlobalState/Store/Store";

const SessionListComponent = ({
  session,
  index,
  handleSelectSession,
  selectedSession,
  lastSession,
  getListRefsMap,
  setSessionMenuClickPosition,
  setFocusSelected,
  focusSelected,
  isScrolling,
  handleOpenSessionMenu,
}) => {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  let isPresent = useIsPresent();
  // const { session, handleSelectSession, selectedSession } = props
  let isSelected = selectedSession && selectedSession._id === session._id ? true : false;

  const URLsString = (tabs) => {
    let arr = tabs.map((t) => {
      return extractHostname(t.url);
    });

    return arr.join(", ");
  };

  const handleContextMenu = (event, id) => {
    // console.log("OPEN RIGHT CLICK SESSION MENU");
    event.preventDefault();
    // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
    // Other native context menus might behave different.
    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
    setFocusSelected(id);
    handleOpenSessionMenu(event);
    setSessionMenuClickPosition((prev) => {
      // console.log(prev);
      if (prev === null) {
        return {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        };
      } else return prev;
    });
  };

  const handleOpenSessionInNewWindow = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "openSession",
        shiftKey: true,
        session: session,
        openType: "add",
        windowId: globalState.extensionConnection.windowId,
      });
    }
  };

  if (session && session._id) {
    return (
      <motion.li
        animate={{ opacity: 1 }}
        exit={!isScrolling ? { opacity: 0 } : { opacity: 1 }}
        initial={!isScrolling ? { opacity: 0 } : { opacity: 1 }}
        layout
        style={{
          position: isPresent ? "relative" : "absolute",
          listStyle: "none",
        }}
        transition={{ duration: !isScrolling ? 0.35 : 0 }}
      >
        <Box
          key={session._id}
          onClick={(e) => handleSelectSession(session._id)}
          onContextMenu={(e) => handleContextMenu(e, session._id)}
          onDoubleClick={(e) => handleOpenSessionInNewWindow(e)}
          //ref={el => listRefs.current[session._id] = el}
          ref={(node) => {
            const map = getListRefsMap();
            if (node) {
              map.set(session._id, node);
            } else {
              map.delete(session._id);
            }
          }}
          style={{
            borderRadius: 4,
            marginBottom: 8,
            marginTop: index === 0 ? 10 : 0,
            marginLeft: 12,
            marginRight: 12,
            fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12,
            cursor: "pointer",
            height: 83,
          }}
          sx={{
            backgroundColor: isSelected ? "primary.main" : focusSelected === session._id ? "action.hover" : "background.paper",
            "&:hover": {
              backgroundColor: isSelected ? "primary.main" : "action.hover",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              noWrap
              style={{ color: isSelected ? "rgba(255, 255, 255, 1)" : theme.palette.text.primary, fontWeight: 600, fontSize: 15 }}
              variant="body1"
            >
              {session.name}
            </Typography>
            <Typography
              noWrap
              style={{
                color: isSelected ? "rgba(255, 255, 255, 0.75)" : theme.palette.text.secondary,
                fontSize: 13,
                fontWeight: 500,
                flexShrink: 0,
                marginLeft: 4,
              }}
              variant="caption"
            >
              {session.tabs ? (session.tabs.length > 1 ? session.tabs.length + " tabs" : session.tabs.length + " tab") : null}
            </Typography>
          </div>
          <Typography noWrap style={{ color: isSelected ? "rgba(255, 255, 255, 1)" : theme.palette.text.primary, flexShrink: 0 }} variant="caption">
            {sessionTimeString(session.lastUpdated)}
          </Typography>
          <Typography
            noWrap
            style={{
              color: isSelected ? "rgba(255, 255, 255, 0.75)" : theme.palette.text.secondary,
              fontSize: 13,
              fontWeight: 400,
              lineHeight: 1.15,
              margin: 0,
            }}
            variant="body1"
          >
            {URLsString(session.tabs)}
          </Typography>
          <Divider style={{ marginTop: 8, borderColor: isSelected || lastSession ? "transparent" : theme.palette.collection.hover }} />
        </Box>
      </motion.li>
    );
  } else return null;
};

const Section = memo(function Section({ section, firstSection }) {
  const theme = useTheme();

  return (
    <Box
      style={{
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "row",
        zIndex: 100000000000000000,
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: firstSection ? 0 : 24,
        marginBottom: 8,
        backgroundColor: alpha(theme.palette.background.paper, 0.75),
        backdropFilter: "blur(8px)",
        borderBottom: `0.5px solid ${theme.palette.divider}`,
        // boxShadow: theme.palette.mode === 'dark' ? '0px 4px 24px 0px rgba(0,0,0,1)' : '0px 2px 8px 0px rgba(0,0,0,0.10)'
      }}
      variant="body2"
    >
      <Typography
        sx={{ paddingTop: 0, paddingBottom: 1, color: "text.secondary", marginTop: 1, fontWeight: 600, fontSize: 12, paddingLeft: 2, paddingRight: 2 }}
      >
        {section && section._id && section._id}
      </Typography>
      <Typography
        sx={{ paddingTop: 0, paddingBottom: 1, color: "text.secondary", marginTop: 1, fontWeight: 600, fontSize: 12, paddingLeft: 2, paddingRight: 2 }}
      >
        {section.sessions ? section.sessions.length : section.sessionsLength}
      </Typography>
    </Box>
  );
});

const SessionsList = memo(function SessionList(props) {
  const theme = useTheme();
  const {
    sections,
    allSessions,
    isLoading,
    isError,
    handleSelectSession,
    selectedSession,
    showSessionHistory,
    listRefs,
    getListRefsMap,
    loadMoreSessions,
    hasMoreSessions,
    isLoadingMore,
    listWidth,
    virtuosoRef,
    setSessionMenuClickPosition,
    setFocusSelected,
    focusSelected,
    handleOpenSessionMenu,
    handleOpenSettingsDialog,
  } = props;
  const [globalState, globalActions] = useGlobal();
  const windowSize = useWindowSize();
  const listHeight = windowSize.height;

  // console.log("Auto Save: " + globalState.browserStorage.autoSaveSessions)

  let shadow = theme.palette.mode === "dark" ? "0px 4px 24px 0px rgba(0,0,0,1)" : "0px 2px 8px 0px rgba(0,0,0,0.10)";

  const GenerateDummies = () => {
    axios.get("/sessions/gendummy");
  };

  const DeleteDummies = () => {
    axios.get("/sessions/deletedummy");
  };

  //VIRTUALIZATION
  const groupCounts = sections.map((section) => section.sessions?.length || 0);
  const groups = [...sections];

  const LoadingFooter = () => {
    return (
      <div>
        {isLoadingMore && (
          <div
            style={{ width: "100%", minWidth: "100%", minHeight: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <Fade
              in={isLoadingMore}
              key="sessions-loading-state"
              style={{
                transitionDelay: isLoadingMore ? "250ms" : "0ms",
              }}
              timeout={1000}
              unmountOnExit
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 2,
                }}
              >
                <Skeleton height={83} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
                <Skeleton height={83} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
                <Skeleton height={83} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
              </Box>
              {/* <CircularProgress
                            size={24}
                            style={{margin: 10, color: theme.palette.text.tertiary}}
                        /> */}
            </Fade>
          </div>
        )}
        <div
          style={{
            width: "100%",
            minWidth: "100%",
            minHeight: 75,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
            paddingBottom: 12,
          }}
        >
          {allSessions && allSessions.length > 10 && !isError && !isLoading && !isLoadingMore && (
            <Typography color="text.tertiary" variant="caption">
              {" "}
              That's all of em!{" "}
            </Typography>
          )}
        </div>
      </div>
    );
  };

  const handleLoadMoreSessions = () => {
    if (hasMoreSessions && !isLoadingMore) {
      loadMoreSessions();
    }
  };
  const [loady, setLoady] = useState(false);
  const toggleLoady = () => setLoady((prev) => !prev);

  //ANIMATION
  const [isScrolling, setIsScrolling] = useState(false);

  const timeoutScrolling = useRef(0);
  // Disable animation on scroll or Virtuoso will break while scrolling
  const onScrollingStateChange = useCallback((value) => {
    // console.log("SCROLLING STATE CHNAGE, ", { value });
    if (value) {
      //set to scrolling = true right away
      setIsScrolling(value);
    } else {
      //delay setting to false
      if (timeoutScrolling.current) {
        clearTimeout(timeoutScrolling.current);
      }
      timeoutScrolling.current = setTimeout(() => setIsScrolling(false), 1750);
    }
  });
  // computeItemKey is necessary for animation to ensure Virtuoso reuses the same elements
  const computeItemKey = useCallback(
    (index) => {
      return allSessions[index]?._id;
    },
    [allSessions]
  );

  const [autoSaveSessions, setAutoSaveSessions] = useState(true);
  useEffect(() => {
    if (globalState.extensionConnection.isConnected && globalState.browserStorage) {
      if (globalState.browserStorage.autoSaveSessions !== undefined) {
        setAutoSaveSessions(globalState.browserStorage.autoSaveSessions);
      }
    }
  }, [globalState.extensionConnection.isConnected, globalState.browserStorage]);

  // console.log({ autoSaveSessions }, globalState.browserStorage.autoSaveSessions);

  return (
    <div
      //ref={sessionListRootRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // overflowY: 'auto',//'overlay',
        paddingBottom: 60,
      }}
    >
      <Fade
        in={!showSessionHistory}
        //style={{width: showSessionHistory ? '100%' : 0,}}
        timeout={{ enter: 350, exit: 0 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: 50,
            height: 50,
            backgroundColor: alpha(theme.palette.background.paper, 0.75),
            backdropFilter: "blur(8px)",
            position: "sticky",
            top: 0,
            zIndex: 100,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <HistoryIcon style={{ fontSize: 20, marginRight: 6 }} />
            <Typography style={{ fontSize: 16, fontWeight: "bold" }} variant="caption">
              Sessions
            </Typography>
          </div>
          <Tooltip
            interactive="true"
            title={
              <React.Fragment>
                <div style={{ color: "#D8D8D8" }}>
                  You have auto-save turned off, only sessions manually closed and saved by you will be saved.
                  <br />
                  <Link
                    color="inherit"
                    // href='https://www.partizion.io/guide/connect-to-the-extension'
                    onClick={() => handleOpenSettingsDialog("sessions")}
                    // rel="noreferrer"
                    style={{ color: "#FFFFFF", fontWeight: "bold" }}
                    // target="_blank"
                    underline="hover"
                  >
                    Edit settings
                  </Link>
                </div>
              </React.Fragment>
            }
          >
            <div
              style={{
                color: theme.palette.text.tertiary,
                display: globalState.isAuth && !autoSaveSessions ? "flex" : "none",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 4,
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <CloudOffRoundedIcon style={{ fontSize: 16 }} />
              <Typography style={{ marginLeft: 4 }} variant="caption">
                Auto Save OFF
              </Typography>
            </div>
          </Tooltip>
        </div>
      </Fade>
      {isLoading || loady ? (
        <div
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              position: "sticky",
              top: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 5,
              paddingBottom: 5,
              marginBottom: 4,
              width: "100%",
              borderBottom: `0.5px solid ${theme.palette.divider}`,
              opacity: 0.35,
              // boxShadow: theme.palette.mode === 'dark' ? '0px 4px 24px 0px rgba(0,0,0,1)' : '0px 2px 8px 0px rgba(0,0,0,0.10)'
            }}
            variant="body2"
          >
            <Skeleton sx={{ fontSize: "1rem" }} variant="text" width={125} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 1.5,
              paddingRight: 1.5,
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            <Skeleton
              height={83}
              style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover, borderBottom: `0.5px solid ${theme.palette.divider}` }}
              variant="rounded"
              width="100%"
            />
            <Skeleton
              height={83}
              style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover, borderBottom: `0.5px solid ${theme.palette.divider}` }}
              variant="rounded"
              width="100%"
            />
            <Skeleton
              height={83}
              style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover, borderBottom: `0.5px solid ${theme.palette.divider}` }}
              variant="rounded"
              width="100%"
            />
            <Skeleton
              height={83}
              style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover, borderBottom: `0.5px solid ${theme.palette.divider}` }}
              variant="rounded"
              width="100%"
            />
          </Box>
        </div>
      ) : sections && sections.length > 0 ? (
        <div>
          {/* <TransitionGroup> */}

          <GroupedVirtuoso
            components={{ Footer: LoadingFooter }}
            computeItemKey={computeItemKey} // List: TransitionGroupContainer, Group: TransitionGroupContainer
            // customScrollParent={scrollParentRef.current}
            endReached={handleLoadMoreSessions}
            groupContent={(index) => {
              let section = groups[index];
              let firstSection = index === 0;
              return <Section firstSection={firstSection} section={section} />;
            }}
            groupCounts={groupCounts}
            isScrolling={onScrollingStateChange}
            itemContent={(index) => {
              let session = allSessions[index];
              // let lastSession = index === section.sessions.length -1
              return (
                <AnimatePresence initial={false} mode="popLayout">
                  <SessionListComponent
                    focusSelected={focusSelected}
                    getListRefsMap={getListRefsMap}
                    handleOpenSessionMenu={handleOpenSessionMenu}
                    handleSelectSession={handleSelectSession}
                    index={index}
                    isScrolling={isScrolling}
                    lastSession={false}
                    listRefs={listRefs}
                    selectedSession={selectedSession}
                    session={session}
                    setFocusSelected={setFocusSelected}
                    setSessionMenuClickPosition={setSessionMenuClickPosition}
                  />
                </AnimatePresence>
              );
            }}
            overscan={900}
            ref={virtuosoRef}
            style={{ height: listHeight - 50, overflowY: "scroll" }}
          />
          {/* </TransitionGroup> */}
        </div>
      ) : isError ? null : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px", marginTop: "25px" }}>
          <Typography noWrap={false} style={{ color: theme.palette.text.secondary }} variant="caption">
            {" "}
            No sessions yet.{" "}
          </Typography>
          <Typography noWrap={false} style={{ color: theme.palette.text.secondary }} variant="caption">
            {" "}
            Partizion will automatically save your{" "}
          </Typography>
          <Typography noWrap={false} style={{ color: theme.palette.text.secondary }} variant="caption">
            {" "}
            browser windows as sessions 📥{" "}
          </Typography>
        </div>
      )}
    </div>
  );
});

export default SessionsList;

{
  /* <Button
color="secondary"
onClick={GenerateDummies} //{toggleLoady}//{GenerateDummies}
variant="contained"
>
Generate them dummies
</Button>
{allSessions.length}
{/* <SessionSearchBar 
            isError={isError}
            isLoading={isLoading}
            shouldAutoFocus={false}
        /> */
}
