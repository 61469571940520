import React, { useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

import Delete from "@mui/icons-material/Delete";

import useGlobal from "../../GlobalState/Store/Store.js";
import { mutate } from "swr";

import StringCut from "../../Utils/stringTooLong.js";

export default function DeleteWorkspaceDialog(props) {
  const open = props.open;
  const [globalState, globalActions] = useGlobal();
  const handleClose = props.handleClose;
  const workspace = props.workspace;
  const deleteIndex = props.deleteIndex;
  //console.log(props)
  const title = workspace != null && workspace != undefined ? "Delete workspace: " + workspace.name + "?" : "Delete workspace?";
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null,
  });

  function DeleteWorkspace(event) {
    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    if (props.workspace && props.workspace._id) {
      const data = {
        workspaceId: workspace._id,
      };

      axios
        .post("/workspaces/delete", data)
        .then((res) => {
          // console.log("length: " + globalState.workspaces.length)
          // console.log("delete index: " + deleteIndex)
          // console.log("active index: " + globalState.activeWorkspaceIndex)
          var reloadCollections = {
            reload:
              deleteIndex < globalState.activeWorkspaceIndex ||
              globalState.activeWorkspaceIndex === deleteIndex ||
              globalState.workspaces.length - 1 === globalState.activeWorkspaceIndex
                ? true
                : false,
            wsIndex: globalState.activeWorkspaceIndex === deleteIndex ? 0 : globalState.activeWorkspaceIndex - 1, //for reloading collections when the active workspaces shifts after a delete
          };

          function StopLoading() {
            setReq({
              loading: false,
              isError: false,
              error: null,
            });

            if (globalState.activeWorkspaceIndex === deleteIndex) {
              globalActions.saveActiveWorkspaceIndex(0);
              localStorage.setItem("activeWorkspaceIndex", 0);
            } else if (globalState.workspaces.length - 1 === globalState.activeWorkspaceIndex) {
              //console.log("pushing up last")
              globalActions.saveActiveWorkspaceIndex(globalState.activeWorkspaceIndex - 1);
              localStorage.setItem("activeWorkspaceIndex", globalState.activeWorkspaceIndex - 1);
            } else if (deleteIndex < globalState.activeWorkspaceIndex) {
              //deleted workspace will shift the position of the current active workspace
              globalActions.saveActiveWorkspaceIndex(globalState.activeWorkspaceIndex - 1);
              localStorage.setItem("activeWorkspaceIndex", globalState.activeWorkspaceIndex - 1);
            } else {
              //null
            }

            handleClose();
          }

          StopLoading();
          mutate(`/workspaces`);
        })
        .catch((err) => {
          //console.log("Create workspace failed")
          setReq({
            loading: false,
            isError: true,
            error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
          });
        });
    }
  }

  return (
    <div>
      <Dialog aria-describedby="alert-dialog-description" aria-labelledby="alert-dialog-title" onClose={handleClose} open={open}>
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: "-1px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
            <Typography variant="h5">Delete workspace:</Typography>
            <Typography style={{ textDecoration: "underline", textDecorationColor: "#3D5AFE", marginLeft: "8px" }} variant="h5">
              {workspace && workspace != undefined ? " " + StringCut(workspace.name, 25) : ""}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete this workspace and ALL of its collections?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus color="primary" disabled={req.loading} onClick={(event) => DeleteWorkspace(event)} startIcon={<Delete />} variant="contained">
            Delete Workspace
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
