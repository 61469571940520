/*global chrome*/
import React, { useRef, useState, useEffect } from 'react'

import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from '../../DND/ItemTypes'
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useTheme } from '@mui/material/styles';

import useGlobal from '../../../GlobalState/Store/Store.js';

import useHoverGroup from '../../../Utils/useHoverGroup';

import {
  Typography,
  IconButton,
  Tooltip} from '@mui/material';

import CloseRounded from '@mui/icons-material/CloseRounded';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

//FOR browser extension APIs

import defaultFavIconLight from '../../../Assets/defaultFaviconLight.svg';
import defaultFavIconDark from '../../../Assets/defaultFaviconDark.svg';
import useDarkMode from '../../../Utils/theme/useDarkMode';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';


const CurTab = ({
  id,
  index,
  tab,
  tabsOrder,

  dragActive,
  setDragActive,
  parentWindow,

  SelectTabs,
  selectedTabs
}) => {
  const [globalState, globalActions] = useGlobal()
  const { isDarkMode } = useDarkMode("light") 
  
  let isTabMounted = true //use for cleanup
  const ref = useRef(null)
  const theme = useTheme()
  const FaviconURL = tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null ? "" : tab.favIconUrl.replace('http://','https://');


  const [oldOrder, setOldOrder] = useState([])

  const getUrl = tab.url || tab.pendingUrl

  const isSelected = selectedTabs && selectedTabs.findIndex(t => t.id === tab.id) > -1 ? true : false

  function extractHostname(url) {
    var hostname;
    if(url && url !== undefined){
      var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false

      if(isUrl){
        //---- OLD FULL REMOVAL METHOD ----
        //find & remove protocol (http, ftp, etc.) and get hostname
        // if (url.indexOf("//") > -1) {
        //   hostname = url.split('/')[2];
        // }
        // else {
        //   //console.log("found / ", hostname)
        //   hostname = url.split('/')[0];
        // }
        // //removes a remaing http or www.
        // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
        //---------------------//
        if(url.indexOf("https://") > -1){
          hostname = url.split('https://')[1];
        }
        else if(url.indexOf("http://") > -1){
          hostname = url.split('http://')[1];
        } else {
          hostname = url
        }
        // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
        if(hostname){
          hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")//.split('/')[0];
        }

      } else {
        return url
      }
    }
    return hostname;
  }
  
  const tabURL = tab && getUrl ? extractHostname(getUrl) : "no url"

  //on load set old collections for comparison
  function resetOldOrder(){
    if(tabsOrder){
      var old = []
      tabsOrder.map(t => {
        old.push({name: t.name, _id: t._id})
      })
      setOldOrder(old)
    }
  }

  useEffect(() => {
    if(isTabMounted){
      resetOldOrder()
    }
    // cancel subscription to useEffect
    return () => (isTabMounted = false)
  }, [])


  const widthRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: ItemTypes.COLLECTIONCARD,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isReadytoDrop: monitor.isReadytoDrop
    }),
    hover(item, monitor) {
      // isHovered: monitor.isOver()
      // console.log(isHovered)

      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2


      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Time to actually perform the action
      //moveTab(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.CURTAB,
      id,
      index,
      tab: tab,
      tabWidthRef: widthRef,
      selectedTabs: selectedTabs
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin(){
      //setInterval(() => {console.log(shiftKeyActive)}, 100)
      setDragActive(id)
    },
    end: () => {
      setDragActive(false)
    },
    // canDrag(props) {
    //   return !editTitle
    // },

  })
  useEffect(() => {
    if(isTabMounted){
      preview(getEmptyImage(), { captureDraggingState: true });
    }
    // cancel subscription to useEffect
    return () => (isTabMounted = false)

  }, []);

  //drag(ref)

  //HOVER LOGIC
  const [hoverRef, hover] = useHoverGroup()

  function switchToTab(e){
    e.stopPropagation()
    e.preventDefault()
    if(globalState.extensionConnection.isConnected){
      globalState.extensionConnection.port.postMessage({type: "switchToTab", tab: tab, parentWindow: parentWindow})
    }
  }

  function closeTab(e){
    e.stopPropagation()
    e.preventDefault()
    if(globalState.extensionConnection.isConnected){
      globalState.extensionConnection.port.postMessage({type: "closeTab", tab: tab})
    }
  }

  function handleTabClicked(event){
    //console.log(event)
    event.stopPropagation()
    event.preventDefault()

    var shiftKey = event.shiftKey
    var commandKey = event.ctrlKey || event.metaKey ? true : false

    //console.log(tab.title,{shiftKey, commandKey})
    if(shiftKey || commandKey){
      SelectTabs(tab, "curTab", parentWindow, shiftKey, commandKey)
    }
    else if(!tab.active) {
      switchToTab(event)
    }
  }

  function mergeRefs(refs){
    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      });
    };
  }

  return (
      <div
          ref={mergeRefs([hoverRef, widthRef])}
      >
          <div
              
              onClick={handleTabClicked}
              ref={drag}
              style={{opacity: isDragging || dragActive && isSelected ? 0 : 1, marginTop: 6}}
          >
              <div
                  style={{
                    borderRadius: 4,
                    cursor: isDragging ? 'grabbing' : 'pointer',
                    paddingTop: 8,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 6,
                    flexGrow: 1,
                    backgroundColor: isSelected ? 'rgba(236, 239, 255, 1)' : hover ? theme.palette.background.default : theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: theme.palette.shadows.small,
                  }}
              >
                  <DragIndicatorIcon
                      fontSize="small"
                      style={{color: '#C4C4C4', marginRight: '6px', opacity: 0.75, cursor: 'grab',}}
                  />
                  {
              FaviconURL !== "" ?
                  <img
                      color={theme.palette.text.secondary}
                      fill={theme.palette.text.secondary}
                      height={20}
                      mode='fit'
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        isDarkMode ? currentTarget.src=defaultFavIconDark : currentTarget.src=defaultFavIconLight
                      }}
                      src={FaviconURL}
                      style={{minWidth: 20, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary}}
                      width={20}
                  />
              :
                  <LanguageRoundedIcon sx={{color: 'text.secondary', width: 20, height: 20}} /> 
            }
                  <div
                      style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'flex-start',
                      marginLeft: '10px',
                      width: '80%',
                    }}
                  >
                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: '95%'}}>
                          <Typography
                              m={0}
                              noWrap
                              style={{
                                fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
                                fontWeight: 500, 
                                color: theme.palette.text.primary,  
                                lineHeight: 1,
                                fontSize: 14,
                                marginBottom: '-3px',
                                width: '165px',
                              }}
                              variant="subtitle2"
                          >
                              {tab.title}
                          </Typography>
                          <Typography
                              m={0}
                              noWrap
                              style={{
                                fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
                                fontWeight: 400, 
                                fontSize: 11,
                                color: theme.palette.text.secondary,
                                lineHeight: 1,
                                width: '165px',
                                margin: 0
                              }}
                              variant="caption"
                          >
                              {tabURL}
                          </Typography>
                      </div>
                      {
              hover ?
                  <div style={{position: 'absolute', right: 0, top: 0, backgroundColor: isSelected ?  'rgba(236, 239, 255, 1)' : theme.palette.background.default, paddingRight: 5, paddingTop: 5, height: '100%', borderRadius: '4px'}}>
                      <Tooltip
                          aria-label="add"
                          disableInteractive
                          title="Close Tab"
                      >
                          <IconButton
                              aria-label="delete"
                              onClick={(e) => closeTab(e)}
                              size="small"
                          >
                              <CloseRounded fontSize="inherit" />
                          </IconButton>
                      </Tooltip>
                  </div>
              :
              null
            }
                  </div>
              </div>

          </div>
      </div>
  )
}
export default React.memo(CurTab);

// <motion.div
//     ref={drag}
//     initial={false}
//     animate={isDragging ? onTop : flat}
//     animate={controls}
//     dragOriginY={dragOriginY}
//     dragElastic={10}
//     layoutTransition={dragActive === tab._id ? {type: "spring"} : 'none'} //damping: 1000, stiffness: 10000, mass: 10, velocity: 1000
//
//     //whileTap={{ scale: 1.03 }}
//     //onTap={{ scale: 1}}
//     style={{
//       scaleX,
//       scaleY,
//       zIndex: isDragging ? 1 : 0,
//       position: 'relative',
//       scale: isDragging ? 1.03 : 1,
//       opacity: isDragging ? 0 : 1,
//
//     }}
//   >
//   <motion.div
//     style={{...inverted}}
//   >
