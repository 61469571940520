import React from "react";

import useGlobal from "../../GlobalState/Store/Store.js";
import { useTheme } from "@mui/material/styles";

import { Typography, IconButton, Grid, Accordion, AccordionDetails } from "@mui/material";

import CollectionPropertyDiv from "../Collections/Properties/CollectionPropertyDiv.js";
import CollectionTab from "../Collections/tabs/CollectionTab.js";
import TabsGrid from "../Collections/tabs/TabsGrid.js";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EmptyCollection from "../../Assets/emptymailbox.png";

const CollectionsDragPreview = ({ collection, previewProps }) => {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  const tabsMenuOpen = globalState.tabsMenuOpen == "true" || globalState.tabsMenuOpen == true ? true : false;
  const opacity = 0.5; //0.45

  //   console.log("DRAG PREVIEW " + collection.name)
  const {
    viewType,
    showTabCount,
    showCreatedOn,
    showLastUpdated,
    tabPreview,
    previewMax,
    tabsLayout,
    maxTabsInList,
    cardTitlePosition,
    createdOnString,
    lastUpdatedString,
    showCustomProperties,
    displayTabs,
    cardMinWidth,
    cardSizePercentage,
    cardMinHeight,
    boundingRect,
    collectionExpanded,
  } = previewProps;

  if (viewType === "List") {
    return (
      <Grid
        item
        style={{
          //minWidth: '100%',
          maxWidth: tabsMenuOpen ? "calc(100% - 600px)" : "calc(100% - 300px)", //'100%',
          width: tabsMenuOpen ? "calc(100% - 600px)" : "calc(100% - 300px)",
          padding: 0,
          opacity: opacity,

          //position: 'relative',
        }}
      >
        <div
          style={{
            borderRadius: 4,
            cursor: "pointer",
            //height: '100%',
            //border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.palette.shadows.small,
            marginBottom: 2,
          }}
        >
          <div
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "35%" }}>
              <Typography
                noWrap
                style={{
                  fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                  maxWidth: "95%",
                  fontSize: 14,
                }}
                variant="body1"
              >
                {collection.name}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "65%",
              }}
            >
              {showLastUpdated ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    marginRight: 16,
                    marginBottom: 0,
                  }}
                  variant="body2"
                >
                  {lastUpdatedString}
                </Typography>
              ) : null}
              {showTabCount && collection.tabs ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    marginRight: 16,
                    marginBottom: 0,
                  }}
                  variant="body2"
                >
                  {collection.tabs.length} tab
                  {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                </Typography>
              ) : null}
              {globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
                ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                    if (showCustomProperties[property._id] == false) {
                      return null;
                    } else {
                      return <CollectionPropertyDiv collection={collection} inList key={index} property={property} />;
                    }
                  })
                : null}
            </div>
          </div>
        </div>
      </Grid>
    );
  } else if (viewType === "Full") {
    return (
      <Grid
        item
        style={{
          width: boundingRect && boundingRect.width ? boundingRect.width : cardSizePercentage,
          height: boundingRect && boundingRect.height ? boundingRect.height + 4 : "1fr",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            border: "solid",
            width: "100%",

            borderRadius: 4,
            borderColor: "transparent",
            height: "100%",
            marginBottom: collectionExpanded ? 12 : 4,
            padding: 0,
            opacity: opacity,
          }}
        >
          <Accordion
            expanded={collectionExpanded}
            style={{
              maxWidth: "100%",
              width: "100%",
              borderRadius: 3,
              maxHeight: "100%",
              //minHeight: cardMinHeight,
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.collection.default,
              boxShadow: theme.palette.shadows.small,

              padding: 0,
              margin: 0,
              //transition: 'background-color 0.05s linear 0.05s',
              display: "flex",
              flexDirection: (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? "column-reverse" : "column",
            }}
          >
            <div
              style={{
                padding: 0,
                cursor: "pointer",
                borderRadius: 3,
                width: "100%",
                height: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                backgroundColor: collectionExpanded ? theme.palette.collection.bgLight : theme.palette.collection.default,
              }}
            >
              <div
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                  paddingBottom: 12,
                  // borderBottom: tabPreview ? '1px solid rgb(196, 196, 196, 0.45)' : null,
                  borderTop:
                    (collectionExpanded && tabPreview && cardTitlePosition === "bottom") || (collectionExpanded && tabPreview && cardTitlePosition == null)
                      ? `1px solid ${theme.palette.divider}`
                      : null,
                  borderBottom: collectionExpanded && tabPreview && cardTitlePosition === "top" ? `1px solid ${theme.palette.divider}` : null,
                  height: "100%",
                  //minHeight: '100%',
                  maxHeight: "100%",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <div style={{ width: "inherit", maxWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                    <IconButton disableFocusRipple size="small" style={{ marginRight: 4, cursor: "pointer" }}>
                      <ExpandMoreIcon style={{ transform: collectionExpanded ? "rotate(180deg)" : "none", transition: "all 0.2s ease-out" }} />
                    </IconButton>
                    <Typography
                      noWrap
                      style={{
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        lineHeight: "18px",
                        maxWidth: "80%",
                      }}
                      variant="body1"
                    >
                      {collection.name}
                    </Typography>
                  </div>
                  {showLastUpdated ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginTop: 2,
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color: "#191919",
                          fontSize: 12,
                          width: "100%",
                          textAlign: "left",
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                        }}
                        variant="body2"
                      >
                        {lastUpdatedString}
                      </Typography>
                    </div>
                  ) : null}
                  {globalState.activeWorkspace &&
                  globalState.activeWorkspace.collectionProperties &&
                  globalState.activeWorkspace.collectionProperties.length > 0
                    ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                        if (showCustomProperties[property._id] == false) {
                          return null;
                        } else {
                          return <CollectionPropertyDiv collection={collection} key={index} property={property} />;
                        }
                      })
                    : null}
                </div>

                {showTabCount ? (
                  <div
                    style={{
                      display: "flex",

                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "16px",
                      marginBottom: 0,
                    }}
                  >
                    <Typography
                      style={{
                        color: theme.palette.text.secondary,
                        fontSize: 12,
                        width: "25%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 500,
                      }}
                      variant="body2"
                    >
                      {collection.tabs.length} tab
                      {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ height: 10 }} />
            <AccordionDetails style={{ height: "auto", display: "flex", flexDirection: "column" }}>
              <TabsGrid
                GetCollectionsbyId={null}
                collection={collection}
                handleAddSnackbarToStack={null}
                inAccordian
                openAddTabsDialog={null}
                tabs={collection.tabs}
                tabsLayout={tabsLayout}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    );
  } else {
    return (
      <div
        style={{
          zIndex: 999,
          padding: 4,
          minWidth: cardMinWidth,
          maxWidth: cardSizePercentage,
          width: boundingRect && boundingRect.width ? boundingRect.width : cardSizePercentage,
          height: boundingRect && boundingRect.height ? boundingRect.height + 4 : "1fr",
          minHeight: cardMinHeight,
          flexGrow: 1,
          position: "relative",
          opacity: opacity,
          transform: `rotateZ(${-2}deg)`,
          //border: 'solid'
        }}
      >
        <div
          style={{
            position: "relative",
            border: "solid",
            borderRadius: 4,
            borderColor: "transparent",
            height: "100%",
            // minHeight: '100%',
            marginBottom: 0,
            padding: 0,
          }}
        >
          <div
            style={{
              borderRadius: 4,
              cursor: "pointer",
              height: "100%",
              overflow: "hidden",
              //minHeight: '100%',

              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.collection.default,
              boxShadow: theme.palette.shadows.small,
              display: "flex",
              flexDirection: (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? "column" : "column-reverse",
            }}
          >
            {tabPreview ? (
              <div
                style={{
                  height: previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 12 : 75) : parseInt(previewMax) * 50 + 8) : 150,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ height: "100%", overflow: "hidden" }}>
                  {displayTabs.length > 0 ? (
                    <div
                      style={{
                        padding: "4px, 8px, 0px, 8px",
                      }}
                    >
                      {displayTabs.map((tab, index) => (
                        <CollectionTab
                          allTabs={displayTabs}
                          collection={collection}
                          in="collection"
                          index={index}
                          isCreateCollection={false}
                          key={index}
                          tab={tab}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={EmptyCollection} style={{ width: "10%", marginRight: "auto", marginLeft: "auto" }} />
                      <Typography style={{ color: "#BFBFBF" }} variant="body2">
                        {" "}
                        No tabs in this collection{" "}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div
              style={{
                padding: 12,
                paddingBottom: 6,
                borderTop:
                  (tabPreview && cardTitlePosition === "bottom") || (tabPreview && cardTitlePosition == null) ? `1px solid ${theme.palette.divider}` : null,
                borderBottom: tabPreview && cardTitlePosition === "top" ? `1px solid ${theme.palette.divider}` : null,
                height: tabPreview
                  ? `calc(100% - ${
                      previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 12 : 75) : parseInt(previewMax) * 50 + 12) : 150
                    })`
                  : "100%",
                flexGrow: 1,
                // minHeight: '100%',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  noWrap
                  style={{
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    lineHeight: "16px",
                    maxWidth: "98%",
                    marginBottom: 6,
                  }}
                  variant="body1"
                >
                  {collection.name}
                </Typography>
                {showCreatedOn ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginTop: 2,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        width: "100%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }}
                      variant="body2"
                    >
                      Created on {createdOnString}
                    </Typography>
                  </div>
                ) : null}
                {showLastUpdated ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginTop: 2,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        width: "100%",
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }}
                      variant="body2"
                    >
                      Last edited {lastUpdatedString}
                    </Typography>
                  </div>
                ) : null}
                {globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
                  ? globalState.activeWorkspace.collectionProperties.map((property, index) => {
                      if (showCustomProperties[property._id] == false) {
                        return null;
                      } else {
                        return <CollectionPropertyDiv collection={collection} key={index} property={property} />;
                      }
                    })
                  : null}
              </div>
              <div
                style={{
                  display: "flex",

                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: 0,
                }}
              >
                {showTabCount && collection.tabs ? (
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                      width: "25%",
                      textAlign: "left",
                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 500,
                    }}
                    variant="body2"
                  >
                    {collection.tabs.length} tab
                    {collection.tabs.length > 1 ? "s" : collection.tabs.length < 1 ? "s" : null}
                  </Typography>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CollectionsDragPreview;
