import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Menu } from '@mui/material';

export const DefaultMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(0.5),
    marginLeft: 0,
    minWidth: 250,
    color: theme.palette.text.primary,//color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    // boxShadow:
    //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    // boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 24px 0px, rgba(0, 0, 0, 0.10) 0px 4px 6px -2px',
    boxShadow: theme.palette.shadows.large,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 6,
    '& .MuiMenu-list': {
      paddingTop: 8,
      paddingBottom: 8,
    },
    '& .MuiMenuItem-root': {
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4, 
      fontSize: 14,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.primary,
      },
      '& .MuiListItemIcon-root': {

        minWidth: 1,
        marginRight: 6
      },
      '&:active': {
        backgroundColor: theme.palette.action.selected
      },
    }, 
  },
}));

// '&:active': {
//   backgroundColor: alpha(
//     theme.palette.primary.main,
//     theme.palette.action.selectedOpacity,
//   ),
// },
