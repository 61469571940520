import React from "react";
import { useDragLayer } from "react-dnd";
import ItemTypes from "../DND/ItemTypes";
import CollectionsDragPreview from "./CollectionsDragPreview.js";
import TabsDragPreview from "./TabsDragPreview.js";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 9999,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};
function getItemStyles(initialOffset, currentOffset, item) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;

  const isTabPreview =
    item &&
    item.previewProps &&
    (item.previewProps.viewType == "Grid" ||
      item.previewProps.viewType == "Kanban" ||
      (item.previewProps.viewType == "Full" && item.previewProps.collectionExpanded)) &&
    item.previewProps.tabPreview
      ? true
      : false;
  //^^ the collection component GRID item has a drag reg smaller than the drag preview (tab preview -> 150 adjusted)

  const previewMax = item && item.previewProps && item.previewProps.previewMax ? item.previewProps.previewMax : false;
  const maxTabsInList = item && item.previewProps && item.previewProps.maxTabsInList ? item.previewProps.maxTabsInList : false;
  const cardTitlePosition = item && item.previewProps && item.previewProps.cardTitlePosition ? item.previewProps.cardTitlePosition : "bottom";

  //calcuate the height of the preview component
  const previewHeight = previewMax ? (previewMax === "max" ? (maxTabsInList >= 1 ? maxTabsInList * 50 + 12 : 75) : parseInt(previewMax) * 50 + 8) : 150;

  const transform = isTabPreview
    ? cardTitlePosition == "top"
      ? `translate(${x}px, ${y}px)`
      : `translate(${x}px, ${y - previewHeight}px)`
    : `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
//{props.collection.name}
export const CollectionContainerDragLayer = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!itemType || !item) {
    return null;
  }
  function renderItem() {
    switch (itemType) {
      case ItemTypes.COLLECTIONCARD:
        if (item && item.previewProps) {
          return <CollectionsDragPreview collection={item.collection} previewProps={item.previewProps} />;
        } else {
          return null;
        }
      case ItemTypes.COLLECTIONTAB:
        return <TabsDragPreview item={item.tab} tabWidthRef={item.tabWidthRef} tabs={[]} type={item.type} />;
      case ItemTypes.TABCARD:
        return <TabsDragPreview item={item.tab} tabWidthRef={item.tabWidthRef} tabs={[]} type={item.type} />;
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, item)}>{renderItem()}</div>
    </div>
  );
};
