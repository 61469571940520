import React from 'react'
import useGlobal from '../../GlobalState/Store/Store.js';

import {
  Button,
  Typography,

} from '@mui/material';

import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
    fontWeight: 700, 
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function ExpandButton(props){

  const [globalState, globalActions] = useGlobal()

  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : []
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  const possibleProperties = props.possibleProperties

  const currentView = props.currentView

  //HELP
  const helpIcon = null

  //EDITING DEFAULT VIEW
  var isEditDefault = !activeView || !views || views && views.length < 1 ? true : false

  const disabled = activeView && activeView.fullViewProperties && activeView.fullViewProperties.expandedCollections && activeView.fullViewProperties.expandedCollections.length == props.collectionsLength && props.collectionsLength > 0 ? true : false

  return (
      <PlainButton
          
          disableFocusRipple
          disableRipple
          disabled={disabled}
          onClick={(e) => props.collapesExpandFunction(e, true)}
          startIcon={<UnfoldMoreRoundedIcon
              fontSize="small"
              style={{width: 18, marginRight: -6, color: "tertiary"}}
                     />}
          style={{paddingTop: 2, paddingBottom: 2, marginRight: 8, textOverflow: "ellipsis", overflow: "hidden", opacity: disabled ? 0.5 : null}}
      >
          <Typography
              noWrap
              variant="button"
          >
              Expand all
          </Typography>
      </PlainButton>
  )
}
