import React from 'react'
import useGlobal from '../../GlobalState/Store/Store.js';

import {
  Button,
  Typography,

} from '@mui/material';

import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';

import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function CollapseButton(props){
  const [globalState, globalActions] = useGlobal()


  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : []
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null


  //HELP

  //EDITING DEFAULT VIEW


  const disabled = activeView && activeView.fullViewProperties && activeView.fullViewProperties.expandedCollections && activeView.fullViewProperties.expandedCollections.length == 0 ? true : false

  return (
      <PlainButton
          
          disableFocusRipple
          disableRipple
          disabled={disabled}
          onClick={(e) => props.collapesExpandFunction(e, false)}
          startIcon={<UnfoldLessRoundedIcon
              fontSize="small"
              style={{width: 18, marginRight: -6, color: "#808080"}}
                     />}
          style={{paddingTop: 2, paddingBottom: 2, marginRight: 8, color: "#808080", textOverflow: "ellipsis", overflow: "hidden", opacity: disabled ? 0.5 : null}}
      >
          <Typography
              noWrap
              variant="button"
          >
              Collapse all
          </Typography>
      </PlainButton>
  )
}

