import { useState } from 'react';
import useSWR from 'swr'
import axios from 'axios'

const fetcher = url => axios.get(url, {withCredentials: true}).then(res => res.data)

function useAllCollections (isAuth, options) {
  const { data, error, isValidating } = useSWR(isAuth ? `/collections/allcollections` : null, fetcher, options ? options : null)

  return {
    data: data,
    isLoadingCollections: !error && !data,
    isFetchError: error,
    isValidatingCollections: isValidating
  }
}

export default useAllCollections;
