import { useCallback, useRef, useLayoutEffect} from 'react';

//https://github.com/facebook/react/issues/14099

function useEventCallback(fn) {
    let ref = useRef();
    useLayoutEffect(() => {
      ref.current = fn;
    });
    return useCallback((...args) => (0, ref.current)(...args), []);
  }

export default useEventCallback