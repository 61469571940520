import React from 'react';


import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,

} from '@mui/material';

import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function AlertDialog(props) {
  const open = props.open
  const handleClick = props.handleClick
  const handleClose = props.handleClose
  const confirm = props.confirm

  const state = props.state



  return (
      <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleClose}
          open={open}
          style={{padding: 20}}
      >
          <DialogContent>
              <DialogContentText
                  id="alert-dialog-description"
                  style={{marginBottom: 25}}
              >
                  Would you like to remove Sorting?
              </DialogContentText>
              <Button
                  autoFocus
                  color="primary"
                  disableFocusRipple
                  fullWidth
                  onClick={() => confirm()}
                  style={{marginBottom: 10}}
                  variant="contained"
              >
                  {state.confirmText}
              </Button>
              <PlainButton
                  fullWidth
                  onClick={handleClose}
                  style={{marginBottom: 10, padding: 6}}
              >
                  {state.cancelText}
              </PlainButton>
          </DialogContent>
      </Dialog>
  );
}
