/*global chrome*/
import React from "react";
import useGlobal from "../../GlobalState/Store/Store";

import { Button, Tooltip, Grid, Typography } from "@mui/material";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useHotkeys } from "react-hotkeys-hook";
import HotkeyChip from "../Hotkey/HotkeyChip";

function CollectionToolBar(props) {
  const [globalState, globalActions] = useGlobal();

  const { openAddTabsDialog, tabsMenuOpen, collection, collectionCurrentlyOpen } = props;

  useHotkeys(
    "a",
    (HotkeysEvent) => {
      //console.log(HotkeysEvent)
      HotkeysEvent.preventDefault();
      HotkeysEvent.stopPropagation();

      // console.log("A key hit ")
      openAddTabsDialog(HotkeysEvent, collection);
    },
    [collection]
  );

  return (
      <>
          {collection && collection.tabs && collection.createdOn ? (
              <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{ paddingLeft: 8 }}
              >
                  <Grid item>
                      <Typography style={{ color: "#6E6E6E" }}>
                          {collection.tabs.length > 0 ? (collection.tabs.length !== 1 ? collection.tabs.length + " tabs" : collection.tabs.length + " tab") : "0 tabs"}
                      </Typography>
                  </Grid>
                  <Grid item>
                      <Tooltip
                          aria-label="newcollection"
                          disableInteractive
                          enterDelay={500}
                          placement="bottom-end"
                          title={
                !globalState.extensionConnection.isConnected ? (
                  "Connect to the Partizion extension to add currently open tabs"
                ) : collectionCurrentlyOpen && collection?.autoUpdateTabs ? "Auto-update is on! just open and close tabs in your browser to update the collection" : (
                    <React.Fragment>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Add tabs to Collection
                            {" "}
                            <HotkeyChip
                        // hotkeyTheme="white"
                                keys={["A"]}
                                style={{ marginLeft: 6 }}
                            />
                        </div>
                    </React.Fragment>
                )
              }
                      >
                          <span>
                              <Button
                                  color="primary"
                                  disableRipple
                                  disabled={!globalState.extensionConnection.isConnected || (collectionCurrentlyOpen && collection?.autoUpdateTabs)}
                                  onClick={(e) => props.openAddTabsDialog(e, collection)}
                                  size="small"
                                  startIcon={<AddRoundedIcon />}
                                  variant="contained"
                              >
                                  Add Tabs
                                  {/* <Typography
                                  style={{fontSize: 10, color: '#FFF', backgroundColor: 'rgba(255, 255, 255, 0.15', paddingTop: '1px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px', borderRadius: '2px', marginLeft: 6}}
                                  variant="caption"
                              >
                                  A
                              </Typography> */}
                              </Button>
                          </span>
                      </Tooltip>
                  </Grid>
              </Grid>
      ) : null}
      </>
  );
}

export default CollectionToolBar;

// <Grid item sm={12} md={12} style={{border: 'solid'}}>
//   <div className={classes.dateInfo}>
//     <Typography variant="body2" style={{marginRight: "10px", color: '#6E6E6E'}}> Last edited: {ISOtoLocalString(collection.lastEdited)} </Typography>
//     <Typography variant="body2" style={{color: '#6E6E6E'}}> Created On: {ISOtoLocalString(collection.createdOn)} </Typography>
//   </div>
// </Grid>
