import axios from "axios";
const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    //console.log("token header set ")
    axios.defaults.headers.common["authorization"] = token;
  } else {
    // Delete auth header
    //console.log("remove token header called")
    delete axios.defaults.headers.common["authorization"];
  }
};
export default setAuthToken;
