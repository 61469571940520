import React from "react";
import { Button } from "@mui/material";
import useGlobal from "../../GlobalState/Store/Store";
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

function CurrentlyOpenBadge({ collectionInSync, size, variant, ...props }) {
  const [globalState, globalActions] = useGlobal();

  const windowId = collectionInSync?.windowId || null;
  const openInThisWindow = !!windowId && globalState.extensionConnection.isConnected && windowId === globalState.extensionConnection.windowId;

  function switchToWindow(e) {
    e.preventDefault();
    e.stopPropagation();
    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({ type: "switchToWindow", windowId: windowId });
    }
  }
  return (
      <Button
          color="green"
      // disabled={!globalState.extensionConnection.isConnected}
          endIcon={!openInThisWindow ? <ArrowOutwardRoundedIcon /> : undefined}
      // style={{paddingTop: 2, paddingBottom: 2, paddingLeft: 8, paddingRight: 8, marginLeft: 8, color: '#FFF'}}
          onClick={switchToWindow}
          size="small"
          startIcon={<div style={{ width: 8, height: 8, borderRadius: 100, backgroundColor: "#27CA40" }} />}
          style={{ marginRight: 6 }}
          variant={variant === "onTabsPreview" ? "currentlyOpen" : "soft"}
      >
          {openInThisWindow ? "Open in this window" : "Currently Open"}
      </Button>
  );
}

export default CurrentlyOpenBadge;
