import React from "react";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import { keys } from "lodash";
import useDarkMode from "../../Utils/theme/useDarkMode";
import { alpha } from "@mui/material";

export default function HotkeyChip({ keys, dimmed, fixed, dense, hotkeyTheme, style }) {
  const theme = useTheme();
  const { isDarkMode } = useDarkMode("light");
  // console.log(keys[0], {style})

  //   console.log(keys);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: 2,
        marginLeft: 2,
        ...style,
      }}
    >
      {keys.map((key, index) => {
        //console.log(key)
        if ((key && key === "then") || (key && key === "or")) {
          return (
            <div
              key={`hotkey-${key}`}
              style={{
                //backgroundColor: theme.palette.collection.bgLight,
                // transition: 'background-color 0.25s ease',
                //border: `1px solid ${theme.palette.divider}`,
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 2,
                paddingRight: 2,

                display: "flex",
                alignItems: "center",
                color: hotkeyTheme === "white" ? "rgba(255, 255, 255, 0.75)" : isDarkMode ? theme.palette.text.secondary : theme.palette.text.tertiary,
                borderRadius: 3,
                //boxShadow: theme.palette.shadows.small,
                filter: "brightness(0.95)",
                marginRight: 1,
                marginLeft: 1,
                fontSize: 10,
                lineHeight: 1.5,
                opacity: dimmed ? 0.5 : 0.9,
              }}
            >
              <Typography variant="fontFamilyMonospaced">{key}</Typography>
            </div>
          );
        } else {
          return (
            <div
              key={key && key.type ? `hotkey-${index}` : `hotkey-${key}`}
              style={{
                backgroundColor: hotkeyTheme === "white" ? "rgba(255, 255, 255, 0.25)" : theme.palette.collection.bgLight,
                transition: "background-color 0.25s ease",
                border: hotkeyTheme === "white" ? "1px solid rgba(255, 255, 255, 0.5)" : `1px solid ${theme.palette.divider}`,
                paddingBottom: dense ? 0 : 1,
                paddingTop: dense ? 0 : 1,
                paddingLeft: dense ? 2 : 3,
                paddingRight: dense ? 2 : 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: hotkeyTheme === "white" ? "#FFF" : theme.palette.text.secondary,
                borderRadius: 3,
                //   boxShadow: theme.palette.shadows.small,
                boxShadow:
                  theme.palette.mode === "dark"
                    ? `0px 1px 0px ${alpha(theme.palette.text.secondary, 0.45)}, inset 0px 2px 0px ${alpha("#FFF", 0.03)}`
                    : hotkeyTheme === "white"
                    ? `0px 1px 0px ${alpha("#FFF", 0.2)}`
                    : `0px 1px 0px ${alpha(theme.palette.text.secondary, 0.2)}, inset 0px 2px 0px ${alpha("#FFF", 0.15)}`,
                filter: hotkeyTheme === "white" ? "none" : "brightness(0.95)",
                marginRight: 1,
                marginLeft: 1,
                fontSize: 10,
                lineHeight: 1.5,
                opacity: dimmed ? 0.6 : hotkeyTheme === "white" ? 1 : 0.9,
                width: key.length === 1 || fixed ? 16 : "auto",
                height: 16,
              }}
            >
              {key && key.type ? <>{key}</> : <Typography variant="fontFamilyMonospaced">{key}</Typography>}
            </div>
          );
        }
      })}
    </div>
  );
}
