import React from "react";

import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import EmojiComponent from "../../Emojis/EmojiComponent";

import { Paper, Typography } from "@mui/material";

import defaultFavIcon from "../../../Assets/noFavIcon2.png";

export default function TabResultObject(props) {
  const tab = props.result && props.result.tab ? props.result.tab : {};
  // console.log(props.result)

  //CONSTANTS
  function extractHostname(url) {
    var hostname;
    if (url && url !== undefined) {
      var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false;

      if (isUrl) {
        //---- OLD FULL REMOVAL METHOD ----
        //find & remove protocol (http, ftp, etc.) and get hostname
        // if (url.indexOf("//") > -1) {
        //   hostname = url.split('/')[2];
        // }
        // else {
        //   //console.log("found / ", hostname)
        //   hostname = url.split('/')[0];
        // }
        // //removes a remaing http or www.
        // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
        //---------------------//
        if (url.indexOf("https://") > -1) {
          hostname = url.split("https://")[1];
        } else if (url.indexOf("http://") > -1) {
          hostname = url.split("http://")[1];
        } else {
          hostname = url;
        }
        // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
        if (hostname) {
          hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""); //.split('/')[0];
        }
      } else {
        return url;
      }
    }
    return hostname;
  }
  const FaviconURL =
    tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
      ? defaultFavIcon
      : tab.favIconUrl.replace(/^http:\/\//, "https://");
  const tabURL = tab && tab.url ? extractHostname(tab.url) : "no url";

  const focused =
    props.focusSelected ==
    props.allMatches.findIndex((r) => r && r.tab && r.tab._id == props.result.tab._id && r.collection._id == props.result.collection._id);

  return (
    <>
      {props.result && props.result.tab && (
        <Paper
          className="selectableResultListItem"
          elevation={0}
          onClick={(e) => props.handleOpenTab(props.result.tab, e.metaKey || e.ctrlKey, e.shiftKey)}
          onMouseMove={(e) => {
            // console.log(e)
            if (!focused) {
              //console.log(props.allMatches.findIndex(r => r && r.tab && r.tab._id == props.result.tab._id && r.collection._id == props.result.collection._id))
              props.handleMouseSelected(
                props.allMatches.findIndex((r) => r && r.tab && r.tab._id == props.result.tab._id && r.collection._id == props.result.collection._id)
              );
            }
          }}
          ref={focused ? props.focusedRef : null}
          style={{
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
          }}
          sx={{
            backgroundColor: focused ? "action.hover" : "transparent",
          }}
        >
          <div style={{ marginRight: 18, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {FaviconURL ? <img height={24} mode="fit" src={FaviconURL} width={24} /> : null}
          </div>
          <div style={{ display: "flex", flexDirection: "column", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
              <Typography noWrap style={{ fontWeight: 500 }}>
                {props.result.tab.title}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0 }}>
              <Typography
                m={0}
                noWrap
                style={{ fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif', fontWeight: 400, color: "#585858", lineHeight: "25px" }}
                variant="caption"
              >
                {tabURL}
              </Typography>
              <Typography noWrap style={{ color: "#C4C4C4", fontWeight: 500, marginLeft: 4, marginRight: 4 }} variant="subtitle2" />
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 0, maxWidth: "60%" }}>
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    backgroundColor: "rgba(110, 110, 110, 0)",
                    borderRadius: "2px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 2,
                    //border: '0.5px solid'
                  }}
                >
                  {
                    //EMOJI
                    props.result.workspace.icon && props.result.workspace.icon.emoji && props.result.workspace.icon.emoji.emoji ? (
                      <EmojiComponent size={12} symbol={props.result.workspace.icon.emoji.emoji} />
                    ) : //COLOR
                    props.result.workspace.icon && props.result.workspace.icon.colorIcon && props.result.workspace.icon.colorIcon.hex ? (
                      <div style={{ width: 10, height: 10, backgroundColor: props.result.workspace.icon.colorIcon.hex, borderRadius: "100%" }} />
                    ) : (
                      <FolderOpenRoundedIcon style={{ fontSize: 14 }} />
                    )
                  }
                </div>
                <Typography noWrap style={{ color: "#C4C4C4", fontWeight: 500, fontSize: 13 }} variant="subtitle2">
                  {props.result.workspace && props.result.workspace.name}
                </Typography>
                <Typography noWrap style={{ color: "#C4C4C4", fontWeight: 500, marginLeft: 3, marginRight: 3, fontSize: 13 }} variant="subtitle2">
                  /
                </Typography>
                <Typography
                  noWrap
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.openCollection(props.result.collection, props.result.workspace, e.metaKey || e.ctrlKey);
                  }}
                  sx={{
                    color: "text.tertiary",
                    fontWeight: 500,
                    fontSize: 13,
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  variant="subtitle2"
                >
                  {props.result.collection && props.result.collection.name}
                </Typography>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
}
