import { useEffect, useState } from "react";

const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

function useWindowFocused() {
  const [windowFocused, setWindowFocused] = useState(hasFocus); //focus for first render

  // User has switched back to the tab
  const onFocus = () => {
    // console.log("Tab is in focus");
    setWindowFocused(true);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    // console.log("Tab is blurred");
    setWindowFocused(false);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    //onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return windowFocused;
}

export default useWindowFocused;
