export default function StringCut(string, maxLen){
  var newString = ''
  var splice = maxLen - 3
  if(string.length < maxLen){
    return string
  }
  if(string.length > maxLen) {
    newString = string.slice(0, splice) + "..."
  }

  if(newString !== ''){
    return newString
  } else {
    return string
  }
}
