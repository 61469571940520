import React, {useState} from 'react';
import { useTheme, styled } from '@mui/material/styles';
import axios from 'axios'
import useGlobal from '../../GlobalState/Store/Store.js';

import {
  Button,
  Popover,
  ListItem,
  MenuItem,
  Divider,
  InputBase,
  CircularProgress,
  Typography,
  
} from '@mui/material';


import { DefaultMenu } from './DefaultMenu.js';


import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

function CreatePropertyMenu(props) {
  const [globalState, globalActions] = useGlobal()

  const open = Boolean(props.anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const mount = props.mount ? props.mount : 'bottom'

  //CHOOSE PROPERTY TYPE POPOVER
  const [propertyType, setPropertyType] = useState("singleSelect")
  const handleChangePropertyType = (type) => {
    setPropertyType(type)
    handleCloseChooseType()
  }

  const [typeAnchorEl, setTypeAnchorEl] = React.useState(null);

  const handleOpenChooseType = (event) => {
    //console.log("open choose property type")
    setTypeAnchorEl(event.currentTarget);
  };

  const handleCloseChooseType = () => {
    setTypeAnchorEl(null);
  };

  const chooseTypeOpen = Boolean(typeAnchorEl);

  function SelectTypePopover(){
    //console.log("mounted")
    return (
        <DefaultMenu
            anchorEl={typeAnchorEl}
            anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
            classes={{
          paper: { 
            paddingTop: 8,
            paddingBottom: 8,
          }
        }}
            id="mouse-over-popover"
            onClose={handleCloseChooseType}
            open={chooseTypeOpen}
            transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        >
            <MenuItem
                button
                disabled={propertyType == "singleSelect"}
                onClick={() => handleChangePropertyType("singleSelect")}
                selected={propertyType == "singleSelect"}
            >
                <ArrowDropDownCircleRoundedIcon
                    fontSize="small"
                    style={{width: 18, color: '#000'}}
                />
                <div style={{marginLeft: 8,}}>
                    <Typography
                        style={{marginBottom: 0}}
                        variant="body2"
                    >
                        {' '}
                        Single select
                        {' '}
                    </Typography>
                </div>
            </MenuItem>

            <MenuItem
                button
                disabled={propertyType == "multiSelect"}
                onClick={() => handleChangePropertyType("multiSelect")}
                selected={propertyType == "multiSelect"}
            >
                <ListRoundedIcon
                    fontSize="small"
                    style={{width: 18, color: '#000'}}
                />
                <div style={{marginLeft: 8,}}>
                    <Typography
                        style={{marginBottom: 0}}
                        variant="body2"
                    >
                        {' '}
                        Multi-select
                        {' '}
                    </Typography>
                </div>
            </MenuItem>

        </DefaultMenu>
    )
  }


  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: 24,
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.text.light}`,
      fontSize: 14,
      lineHeight: 1.45,
      paddingBottom: 4,
      paddingTop: 4,
      paddingLeft: 6,
      paddingRight: 6,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      color: theme.palette.text.secondary,
      '&:focus': {
        border: `2px solid ${theme.palette.text.tertiary}`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.collection.bgLight,
      },
      
    },
  }));

  function handleCloseMenu(){
    const curView = props.editView
    if(curView){
      // if(view !== curView.viewType || name !== curView.name){
      //   //console.log({view, name, curView})
      //   props.UpdateView(curView, view, name)
      // }
    }

    setPropertyType('singleSelect')
    setName('PropertyName')
    setCreatePropReq({
      isLoading: false,
      isError: false
    })

    props.handleClose()
    handleCloseChooseType()
  }

  const selectInputText = (event) => {
    // console.log(event)
    // console.log(event.type)
    // console.log(event.detail)
    // console.log(event.eventPhase)
    if(event.target.value == "PropertyName") event.target.select()
    // if(inputRef && inputRef.current){
    //   inputRef.current.select()
    // }
  }

  const [name, setName] = useState("PropertyName")
  const handleChangeName = (event) => {
    //console.log(event)
    setName(event.target.value);
  };

  const [createPropReq, setCreatePropReq] = useState({
    isLoading: false,
    isError: false
  })
  const handleCreateProperty = () => {
    setCreatePropReq({
      isLoading: true,
      isError: false
    })

    const data = {
      workspaceId: globalState.activeWorkspace._id,
      type: propertyType,
      name: name,
      values: []
    }

    function StopLoadingCreateProperty(){
      setPropertyType('singleSelect')
      setName('PropertyName')
      setCreatePropReq({
        isLoading: false,
        isError: false
      })
    }


    axios
      .post("/workspaces/collectionProperties/create", data)
      .then(res => {
        //console.log(res)
        if(res.data && res.data.workspace){
          globalActions.saveActiveWorkspace(res.data.workspace)
        }
        StopLoadingCreateProperty()
        props.handleClose()
      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        setCreatePropReq({
          isLoading: false,
          isError: true
        })
      });
  }


  const handleKeyPress = (e) => {
    e.stopPropagation()
    var charCode = e.which || e.charCode || e.keyCode
    if(charCode == 13){
      handleCreateProperty()
    }
  }


  return (
      <DefaultMenu
          anchorEl={props.anchorEl}
          anchorOrigin={{
        vertical: mount == "side" ? 'center' : mount == "top" ? 'center' :  'bottom',
        horizontal: mount == "side" ? 'right' : mount == "top" ? 'right' :  'left',
      }}
          id={id}
          onClose={handleCloseMenu}
          onKeyPress={handleKeyPress}
          open={open}
          style={{marginTop: mount == 'bottom' ? 5 : 0}}
          transformOrigin={{
        vertical: mount == "side" ? 'center' : mount == "top" ? 'top' : 'top',
        horizontal: mount == "side" ? 'left' : mount == "top" ? 'left' :  'left',
      }}
      >
          <div
              onClick={handleCloseChooseType}
              style={{
                width: 300,
                outline: 'none',
                padding: 8
              }}
          >
              <BootstrapInput
                  autoFocus
                  fullWidth
                  id="outlined-basic"
                  label="property name"
                  onChange={handleChangeName}
                  onFocus={selectInputText}
                  onKeyDown={e => e.stopPropagation()}
                  placeholder="Name property"
                  size="small"
                  value={name}
                  variant="outlined"
              />
          </div>
          <Divider />

          <Typography
              sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5}}
              variant="body2"
          >
              Property Type
          </Typography>
          <MenuItem
              button
              // onMouseEnter={handleOpenChooseType}
              // onMouseLeave={handleCloseChooseType}
              onClick={handleOpenChooseType}


              selected={chooseTypeOpen}
          >
              {
                  propertyType == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                      fontSize="small"
                      style={{width: 18, color: '#000'}}
                                                   />
                  :
                  propertyType == "multiSelect" ? <ListRoundedIcon
                      fontSize="small"
                      style={{width: 18, color: '#000'}}
                                                  />
                  :
                  null
                }
              <div style={{marginLeft: 8,}}>
                  <Typography
                      style={{marginBottom: 0}}
                      variant="body2"
                  >
                      {propertyType == "multiSelect" ? "Multi-select" : propertyType == "singleSelect" ? "Single select" : "no type"}
                      {' '}
                  </Typography>
              </div>
              <ArrowForwardIosRoundedIcon
                  fontSize="small"
                  style={{width: 14, color: 'text.secondary', position: 'absolute', right: 6}}
              />
          </MenuItem>
          <SelectTypePopover />
          <Divider />
          <ListItem>
              <div
                  style={{
                  margin: 0,
                  position: 'relative',
                  width: '100%'
                }}
              >
                  <Button
                      color="primary"
                      disabled={createPropReq.isLoading || name == null || name == "" || name == " " || name == undefined}
                      fullWidth
                      onClick={handleCreateProperty}
                      size="small"
                      variant="contained"
                  >
                      Create property
                  </Button>
                  {createPropReq.isLoading && <CircularProgress
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                      }}
                                              />}
              </div>
          </ListItem>
      </DefaultMenu>
  );
}

export default React.memo(CreatePropertyMenu)


// <ListItem button onClick={handleOpenSelectMenu} onMouseEnter={handleCloseChooseType}>
//   <SettingsRoundedIcon fontSize="small" style={{width: 18, color: '#000'}}/>
//   <div style={{marginLeft: 8}}>
//     <Typography variant="body2" style={{marginBottom: 0}}> Configure options </Typography>
//   </div>
// </ListItem>
