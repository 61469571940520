// eslint-disable-next-line no-unused-vars
/* global chrome */
import React, { useState, useEffect, useRef, lazy, Suspense, useCallback } from "react";
import axios from "axios";
import { useTheme, styled } from "@mui/material/styles";
import useDarkMode from "../Utils/theme/useDarkMode.js";
import DetectClientInfo from "../Utils/DetectClientInfo.js";

import EmojiComponent from "../Components/Emojis/EmojiComponent";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";

import { useHotkeys } from "react-hotkeys-hook";
import useWindowSize from "../Utils/useWindowSize.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

const SettingsDialog = lazy(() => import("./Settings/SettingsDialog.js"));
const StepTipCard = lazy(() => import("../Components/Tips/StepTipCard.js"));

import CollectionNavbarButtons from "../Components/Collections/CollectionNavbarButtons.js";

import HotkeyChip from "../Components/Hotkey/HotkeyChip";

import { Drawer, Tooltip, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Avatar, Link, Fab, Button, Fade, Box } from "@mui/material";

import CustomEmojiPicker from "../Components/Emojis/CustomEmojiPicker.js";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/MenuRounded";

import Add from "@mui/icons-material/Add";

import useGlobal from "../GlobalState/Store/Store.js";

import CurrentTabsMenu from "./CurrentTabsMenu/CurrentTabsMenu.js";
import CollectionsContainer from "./CollectionsContainer.js";
import SessionsContainer from "./Sessions/SessionsContainer.js";

import PageBreadCrumbs from "./PageBreadCrumbs.js";

import ViewBar from "./ViewBar.js";

import { paramCase } from "change-case";
import AccountMenu from "../Components/Menus/AccountMenu.js";

import WorkspaceList from "../Components/Workspaces/WorkspaceList.js";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import CreateWorkSpaceDialog from "../Components/Dialogs/CreateWorkSpaceDialog.js";
import CreateCollectionDialog from "../Components/Dialogs/CreateCollectionDialog.js";
import AddTabsDialog from "../Components/Dialogs/AddTabsDialog.js";
import DeleteWorkspaceDialogue from "../Components/Dialogs/DeleteWorkspaceDialog.js";

import SearchDialog from "../Components/Search/SearchDialog.js";

import ShadowScrollbars from "../Components/ShadowScrollbars.js";
import Skeleton from "@mui/material/Skeleton";

import HelpFabMenu from "../Components/Menus/HelpFabMenu.js";
import SearchIcon from "@mui/icons-material/Search";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";

import withScrolling, { createVerticalStrength } from "react-dnd-scrolling";

import update from "immutability-helper";

import useWorkspaces from "../Hooks/useWorkspaces.js";
import useCollections from "../Hooks/useCollections.js";
import { mutate } from "swr";
import { useSWRConfig } from "swr";
import BigInput from "../Components/Inputs/BigInput.js";
import CodeLoading from "../CodeLoading.js";
import useCustomerPortal from "../Hooks/useCustomerPortal.js";
import { ArrowOutwardRounded } from "@mui/icons-material";
import useCollectionById from "../Hooks/useCollectionById.js";

//import { ReactComponent as Logo } from '../Assets/logo@150.png';

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));
const UpgradeButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  // color: theme.palette.button.plain,
  color: theme.palette.text.primary,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,
  backgroundColor: theme.palette.action.hover,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.focus,
  },
  "&:active": {
    backgroundColor: theme.palette.action.focus,
    // color: theme.palette.text.primary,
  },
}));

//autoscrolls with drag and drop
const ScrollingComponent = withScrolling("div");
const vScrollStrength = createVerticalStrength(500);

export default function MainContainerCache(props) {
  const [globalState, globalActions] = useGlobal();
  const windowSize = useWindowSize();
  const drawerWidth = windowSize && windowSize.width && windowSize.width > 1500 ? 300 : 240;
  const theme = useTheme();
  const { isDarkMode } = useDarkMode("light");
  const settingsFullscreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); //useMediaQuery(theme.breakpoints.down("sm"));

  let { workspaceid, collectionid } = useParams();

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const { workspacesData, isLoadingWorkspaces } = useWorkspaces(globalState.isAuth);
  const { isLoadingCollections } = useCollections(
    globalState.activeWorkspace && globalState.activeWorkspace._id ? globalState.activeWorkspace._id : null,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null
  );
  //used only for setting the active workspace
  const { collectionData } = useCollectionById(globalState.isAuth, collectionid ? collectionid : null);

  //only needed to reset cache when sessions are navigated away from
  // const { setPageSize } = useRecentSessions(globalState.isAuth, {}, true) //passing skipFetch === true won't call the API (unessesary calling when not on sessions page)
  const { cache } = useSWRConfig();

  //sets active view
  const compareObj = (objects) => {
    if (objects && objects.length > 1 && objects.every((o) => !!o)) {
      const res = objects.map((item) => {
        return Object.entries(item).flat().join();
      });
      return res.every((a) => {
        return a === res[0];
      });
    } else return false;
  };
  useEffect(() => {
    let useWorkspaceIndex;
    let activeWorkspace;

    if (mainComponentMounted) {
      if (workspacesData && workspacesData.workspaces) {
        useWorkspaceIndex =
          workspaceid && workspacesData && workspacesData.workspaces
            ? workspacesData.workspaces.findIndex((w) => w._id === workspaceid)
            : globalState.activeWorkspaceIndex;
        //if the workspaces id is invalid, redirect
        // console.log({ workspaceid, collectionid, useWorkspaceIndex, collectionData });
        if (workspaceid && workspacesData.workspaces.findIndex((w) => w._id === workspaceid) === -1) {
          console.log("INVALID WS ID, redireting to home");
          navigate("/");
        }

        //ON collection URL
        if (collectionid && collectionData && collectionData.collection) {
          //make sure the active workspace matches the workspace from the loaded collection (from URL)
          const activeWorkspaceIDFromURL = collectionData.collection.workspace;
          // console.log({ activeWorkspaceIDFromURL });

          if (activeWorkspaceIDFromURL !== globalState.activeWorkspace._id) {
            const activeWorkspaceIndexFromURL = workspacesData.workspaces.findIndex((w) => w._id === activeWorkspaceIDFromURL);

            console.log({ workspacesData, activeWorkspaceIndexFromURL });
            if (activeWorkspaceIndexFromURL > -1) {
              console.log("SET ACTIVE WORKSPACE BASED ON COLLECTION URL");
              const activeWorkspaceFromURL = workspacesData.workspaces[activeWorkspaceIndexFromURL];
              globalActions.saveActiveWorkspace(activeWorkspaceFromURL, "/loading collection URL");
              globalActions.saveActiveWorkspaceIndex(activeWorkspaceIndexFromURL);
              return;
            }
          }
        }

        //console.log("Active WS Index: " + useWorkspaceIndex)
        activeWorkspace = workspacesData.workspaces[useWorkspaceIndex] ? workspacesData.workspaces[useWorkspaceIndex] : workspacesData.workspaces[0]; //reset if active Index is too high

        //console.log({activeWorkspace})
        const activeView =
          activeWorkspace && activeWorkspace.views
            ? activeWorkspace.activeView
              ? activeWorkspace.views.find((v) => v._id === activeWorkspace.activeView)
              : activeWorkspace.views[0]
            : null;
        //console.log({activeView})

        //only update thigns if the workspaces data is actually different
        //console.log("new active view incoming, dif: " + compareObj([activeView, globalState.activeView]))
        if (!compareObj([activeView, globalState.activeView])) {
          globalActions.setActiveView(activeView);
        }

        // globalActions.setWorkSpaces(workspacesData.workspaces)
        globalActions.saveActiveWorkspace(activeWorkspace, "/getworkspaces");

        var findActiveIndex = workspacesData.workspaces.findIndex((w) => w._id === activeWorkspace._id);
        if (findActiveIndex !== -1 && globalState.activeWorkspaceIndex !== findActiveIndex && !sessionsOpen) {
          //reset the acitve ws index
          globalActions.saveActiveWorkspaceIndex(findActiveIndex);
          localStorage.setItem("activeWorkspaceIndex", findActiveIndex);
        }
      }
    }

    return () => {
      mainComponentMounted = false;
    };
  }, [workspacesData, collectionData]);

  let mainComponentMounted = true;

  const [tabsMenuOpen, setTabsMenuOpen] = useState(false); //props.initialTabsMenuOpen)
  const [workspaceMenuAnchorEl, setWorkspaceMenuAnchorEl] = React.useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [deleteWorkspaceAlertOpen, setDeleteWorkspaceAlertOpen] = useState(false);

  const [workspaceAlertOpen, setWorkspaceAlertOpen] = useState(false);
  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const [addTabsOpen, setAddTabsOpen] = useState(false);
  const [refThisWorkspace, setRefThisWorkspace] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const [pastDueOpen, setPastDueOpen] = useState(false);

  //for device checks
  const { OSName } = DetectClientInfo();
  const [commandOrControl, setCommandOrControl] = useState("⌘");
  useEffect(() => {
    if (OSName === "MacOS") {
      setCommandOrControl("⌘");
    } else {
      setCommandOrControl("CTRL");
    }
  }, []);

  //EDITING WORKSPACE NAME
  const [editWorkSpaceName, setEditWorkspaceName] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState("");
  const workspaceNameInputRef = React.createRef();
  const RenameWorkspace = async (e, workspaceId, newName) => {
    var wasWSButtonClick = e.path
      ? e.path.findIndex((obj) => {
          return obj.id === "workspacebutton";
        })
      : -1;

    if (wasWSButtonClick !== -1) {
      //console.log("CANCEL")
      //cancel req
      setEditWorkspaceName(false);
    } else {
      setEditWorkspaceName(false);
      //console.log("You are attempting to change the workspace " + workspace._id + " to " + newName)
      //update local state immediately

      const workspaceIndex = workspacesData.workspaces.findIndex((w) => w._id == workspaceId);
      const newData =
        workspaceIndex > -1
          ? update(workspacesData, {
              workspaces: { [workspaceIndex]: { name: { $set: newName } } },
            })
          : null;

      if (workspaceIndex > -1) {
        mutate("/workspaces", { ...workspacesData, workspaces: newData.workspaces }, false);
        setEditWorkspaceName(false);
      }

      // send a request to the API to update the source
      try {
        await axios.post("/workspaces/update", {
          workspaceId: workspaceId,
          newName: newName,
        });
        setEditWorkspaceName(false);
      } catch (error) {
        // Handle an error while updating the user here
      }
      // trigger a revalidation (refetch) to make sure our local data is correct
      mutate("/workspaces");
    }
  };

  var subscription =
    globalState.userTrial.subscription || (globalState.user.stripeSubscription && globalState.user.stripeSubscription.status === "active") ? true : false;

  // console.log("sub: " + subscription)
  // console.log("devLim: " + globalState.deviceLimiter.status)

  //When the device limit diaglor appears everything should be disabled
  const functionsDisabled = globalState.deviceLimiter.status && !subscription && !globalState.userTrial.trialing;

  //non-global state emoji picker state
  const [emojiPickerState, setEmojiPickerState] = useState({
    open: false,
    anchor: 0,
    anchorEl: null,
    x: null,
    bottomSpace: 0,
    workspace: {},
  });
  const chooseEmoji = async (emoji, colorIcon) => {
    //console.log(emoji, colorIcon)
    const data = {
      workspaceId: emojiPickerState.workspace._id,
      icon: {
        emoji: emoji,
        colorIcon: colorIcon,
      },
    };
    setEmojiPickerState({
      open: false,
      anchor: 0,
      anchorEl: null,
      bottomSpace: 0,
      workspace: {},
    });
    //update local state immediately
    const workspaceIndex = workspacesData.workspaces.findIndex((w) => w._id == emojiPickerState.workspace._id);
    const newData = update(workspacesData, {
      workspaces: {
        [workspaceIndex]: {
          icon: { $set: { emoji: emoji, colorIcon: colorIcon } },
        },
      },
    });
    mutate("/workspaces", { ...workspacesData, workspaces: newData.workspaces }, false);

    // send a request to the API to update the source
    try {
      await axios.post("/workspaces/updateicon", data);
    } catch (error) {
      // Handle an error while updating the user here
    }
    // trigger a revalidation (refetch) to make sure our local data is correct
    mutate("/workspaces");
  };
  const handleCloseEmojiPicker = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // globalActions.setEmojiPickerState({
    // open: false,
    // anchor: 0,
    // bottomSpace: 0,
    // workspace: {}
    // })
    setEmojiPickerState({
      open: false,
      anchor: 0,
      anchorEl: null,
      bottomSpace: 0,
      workspace: {},
    });
  };
  const handleOpenEmojiPicker = (event, workspace) => {
    event.preventDefault();
    event.stopPropagation();

    setEmojiPickerState({
      open: true,
      anchor: null,
      anchorEl: event.currentTarget,
      bottomSpace: 0,
      workspace: workspace,
    });
  };

  //from lsit item
  const [emojiHovered, setEmojiHovered] = useState(false);
  const toggleHoverEmoji = (boolean) => {
    setEmojiHovered(boolean);
  };
  const handleEmojiClicked = (event, workspace, where) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log(event.pageY)
    //console.log(workspace)
    //console.log("window height: " + window.innerHeight)
    //console.log("target top: " + event.pageY)
    const rect = event.target.getBoundingClientRect();
    const anchorY = rect.y + 40;
    const anchorX = rect.x;
    // coordinates of location.

    var spaceToBottom = window.innerHeight - event.pageY;
    //console.log(spaceToBottom)
    if (spaceToBottom < 460) {
      //console.log("Not a lot of bottom space")
    }
    // globalActions.setEmojiPickerState({
    //   open: true,
    //   anchor: event.pageY + 15,
    //   bottomSpace: spaceToBottom,
    //   workspace: workspace
    // })
    if (where == "workspaceMainContainer") {
      setEmojiPickerState({
        open: true,
        anchor: anchorY,
        anchorEl: event.currentTarget,
        x: anchorX,
        bottomSpace: spaceToBottom,
        workspace: workspace,
      });
    } else {
      setEmojiPickerState({
        open: true,
        anchor: event.pageY + 15,
        anchorEl: event.currentTarget,
        x: event.pageX,
        bottomSpace: spaceToBottom,
        workspace: workspace,
      });
    }
  };

  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [wsMenuClickPosition, setWsMenuClickPosition] = useState(initialState);

  const handleWorkspaceMenuClick = (event, index, workspace, trigger) => {
    //console.log("Workspace " + workspace.name + " menu clicked from ")
    //console.log(event.target)
    event.preventDefault();
    event.stopPropagation();

    if (trigger && trigger == "rightclick") {
      //console.log("setting click pos", event.clientX, event.clientY)
      setWsMenuClickPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }

    setRefThisWorkspace(workspace);
    setDeleteIndex(index);
    setWorkspaceMenuAnchorEl(event.currentTarget);
  };
  const handleWorkspaceMenuClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setWorkspaceMenuAnchorEl(null);
    setWsMenuClickPosition(initialState);
  };

  const openDeleteWorkspaceDialog = () => {
    //console.log("call to open delete dialog")
    setDeleteWorkspaceAlertOpen(true);
  };

  const closeDeleteWorkspaceDialog = () => {
    setDeleteIndex(null);
    setRefThisWorkspace(null);
    setDeleteWorkspaceAlertOpen(false);
  };

  //FOr creating a workspace
  const openWorkspaceDialog = (event) => {
    event.target.blur();
    setWorkspaceAlertOpen(true);
  };

  const closeWorkspaceDialog = () => {
    setWorkspaceAlertOpen(false);
  };

  //For creating a collection
  const [createCollectionConfig, setCreateCollectionConfig] = useState({});
  const openCollectionDialog = (event, config) => {
    // if(!tabsMenuOpen && globalState.extensionConnection.isConnected){
    //   //console.log("updating browser state")
    //   globalState.extensionConnection.port.postMessage({type: "updateCurrentTabs", windowId: globalState.extensionConnection.windowId, tabId: globalState.extensionConnection.tabId})
    // }
    if (config) {
      //console.log("recieved config from open call", config)
      setCreateCollectionConfig(config);
    }

    if (!tabsMenuOpen && globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAddTabsMenu",
        state: true,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
      });
    }

    if (event && event.target) event.target.blur();
    setCreateCollectionOpen(true);
  };

  const closeCollectionDialog = () => {
    if (!tabsMenuOpen && globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAddTabsMenu",
        state: false,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
      });
    }
    setCreateCollectionConfig({});
    setCreateCollectionOpen(false);
  };

  //For adding tabs to a collection
  const [addTabsTo, setAddTabsTo] = useState({});
  const openAddTabsDialog = (event, collection) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    //console.log("Add tabs dialog open triggered")
    //console.log({tabsMenuOpen}, globalState.extensionConnection.isConnected)
    //update the browser state incase tabs Menu isnt open

    // if(!tabsMenuOpen && globalState.extensionConnection.isConnected){
    //   //console.log("updating browser state")
    //   globalState.extensionConnection.port.postMessage({type: "updateCurrentTabs", windowId: globalState.extensionConnection.windowId, tabId: globalState.extensionConnection.tabId})
    // }

    if (!tabsMenuOpen && globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAddTabsMenu",
        state: true,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
      });
    }

    if (globalState.extensionConnection.isConnected) {
      //only open it if the extension is connected
      event.target.blur();
      setAddTabsTo(collection);
      setAddTabsOpen(true);
    }
  };

  const closeAddTabsDialog = () => {
    if (!tabsMenuOpen && globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAddTabsMenu",
        state: false,
        windowId: globalState.extensionConnection.windowId,
        tabId: globalState.extensionConnection.tabId,
      });
    }
    setAddTabsTo({});
    setAddTabsOpen(false);
  };

  function GetWorkspacebyID(workspace, loadCollections, callback) {
    //console.log(callback)
    var newView;
    axios
      .get("/workspaces/getone", { params: { workspaceId: workspace._id } }, { withCredentials: true })
      .then((res) => {
        //console.log(res.data)
        globalActions.saveActiveWorkspace(res.data.workspace[0], "workspace by ID [0]");
        let activeWorkspace = res.data.workspace[0];
        //console.log(activeWorkspace, loadCollections)
        const activeView =
          activeWorkspace && activeWorkspace.views
            ? activeWorkspace.activeView
              ? activeWorkspace.views.find((v) => v._id === activeWorkspace.activeView)
              : activeWorkspace.views[0]
            : null;
        newView = activeView !== undefined && activeView !== null ? activeView : {};

        globalActions.setActiveView(activeView);

        const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id == activeWorkspace._id) : -1;
        if (workspaceIndex > -1) {
          const updatedData = update(workspacesData, {
            workspaces: { [workspaceIndex]: { $set: res.data.workspace[0] } },
          });

          //console.log(updatedData.workspaces[workspaceIndex])
          mutate(`/workspaces`, updatedData, false);
        }
      })
      .catch((err) => {
        //console.log("Workspaces req failed")
        console.log(err);
      });
  }

  // function getActiveView(workspace) {
  //   //console.log("Gettign active view for" + workspace.name)
  //   if (workspace && workspace.views && workspace.views.length > 0) {
  //     if (workspace.activeView) {
  //       var findIndex = workspace.views.findIndex((v) => v._id === workspace.activeView);
  //       if (findIndex !== -1) {
  //         return workspace.views[findIndex];
  //       } else {
  //         return workspace.views[0];
  //       }
  //     } else {
  //       return workspace.views[0];
  //     }
  //   } else if (workspace.activeView) {
  //     const index =
  //       workspacesData && workspacesData.workspaces && workspacesData.workspaces.length > 0
  //         ? workspacesData.workspaces.findIndex((w) => w._id == workspace._id)
  //         : -1;
  //     const viewIndex =
  //       index > -1 && workspacesData.workspaces[index].views ? workspacesData.workspaces[index].views.findIndex((v) => v._id === workspace.activeView) : -1;

  //     if (viewIndex > -1) return workspacesData.workspaces[index].views[viewIndex];
  //     else return {};
  //   } else {
  //     //workspace has no views -> use the default view
  //     //console.log("ws has no views yet")
  //     return {};
  //   }
  // }

  //NAVIGATING
  const handleClickSelectWorkspace = (index, workspace) => {
    //setActiveWorkspaceIndex(index);
    //console.log(workspace.name + " should now be active")

    //if already on the workspace, don't update
    // BUT if you're on the workspace AND in a collection view,
    //clicking on workspace list item shoudl bring you back to workspace view
    if (index !== globalState.activeWorkspaceIndex || (index === globalState.activeWorkspaceIndex && collectionid)) {
      // globalActions.saveActiveWorkspaceIndex(index)
      // localStorage.setItem("activeWorkspaceIndex", index)

      // globalActions.saveActiveWorkspace(workspace)

      // const activeView =  workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null
      // globalActions.setActiveView(activeView)

      handleCloseSessions();
      navigate(`/${paramCase(workspace._id)}`);
      // GetCollectionsbyId(workspace)
      setMobileDrawerOpen(!mobileDrawerOpen);
    }
  };

  useEffect(() => {
    if (workspacesData && workspacesData.workspaces && workspacesData.workspaces.length > 0) {
      const index = workspacesData.workspaces.findIndex((w) => w._id == workspaceid);

      // console.log({ workspaceid, collectionid });

      if (!!workspaceid || !!collectionid) handleCloseSessions();

      // this is where I could use a "documentid" instead of workspaceid and check for either workspace, collection, or session
      if (index > -1) {
        const workspace = workspacesData.workspaces[index];

        globalActions.saveActiveWorkspaceIndex(index);
        localStorage.setItem("activeWorkspaceIndex", index);

        globalActions.saveActiveWorkspace(workspace);
        const activeView =
          workspace && workspace.views ? (workspace.activeView ? workspace.views.find((v) => v._id === workspace.activeView) : workspace.views[0]) : null;
        globalActions.setActiveView(activeView);
      } else if (collectionid) {
        //we're on a collection link, set the active workspace by finding the workspace with the collection
        // console.log("Set active workspace by collection URL");
        const workspaceId = collectionData?.collection?.workspace;
        let workspaceIndex = workspacesData.workspaces.findIndex((w) => w._id == workspaceId);

        if (workspaceIndex > -1) {
          const workspace = workspacesData.workspaces[workspaceIndex];

          globalActions.saveActiveWorkspaceIndex(workspaceIndex);
          localStorage.setItem("activeWorkspaceIndex", workspaceIndex);

          globalActions.saveActiveWorkspace(workspace);
          const activeView =
            workspace && workspace.views ? (workspace.activeView ? workspace.views.find((v) => v._id === workspace.activeView) : workspace.views[0]) : null;
          globalActions.setActiveView(activeView);
        }
      }
    }
    //console.log({workspaceid}, "Changed workspace")
  }, [workspaceid, collectionid]);

  //SELECTING TABS
  const [selectedTabs, setSelectedTabs] = useState([]);
  function clearSelectedTabs() {
    setSelectedTabs([]);
  }

  //check for tabsopen preference - then set local variable
  useEffect(() => {
    if (mainComponentMounted) {
      if (!globalState.extensionConnection.isConnected) {
        setTabsMenuOpen(false);
      }
    }

    return () => {
      mainComponentMounted = false;
    };
  }, [globalState.extensionConnection.isConnected]);

  useEffect(() => {
    if (mainComponentMounted) {
      if (globalState.extensionConnection.isConnected && globalState.browserStorage) {
        if (!globalState.browserStorage.tabsMenuOpen) {
          setTabsMenuOpen(false);
        } else if (globalState.browserStorage.tabsMenuOpen === true) {
          setTabsMenuOpen(true);
          //console.log("from useffect")
        } else {
          setTabsMenuOpen(false);
        }
      }
    }

    return () => {
      mainComponentMounted = false;
    };
  }, [globalState.browserStorage]);

  function toggleOpenTabsMenu() {
    //console.log("toggle menu " + tabsMenuOpen)
    if (globalState.extensionConnection.isConnected) {
      clearSelectedTabs();
      setTabsMenuOpen(!tabsMenuOpen);
      globalActions.setTabsMenuOpen(!tabsMenuOpen);
      if (tabsMenuOpen) {
        globalState.extensionConnection.port.postMessage({
          type: "toggleTabsMenuOpen",
          state: false,
          windowId: globalState.extensionConnection.windowId,
          tabId: globalState.extensionConnection.tabId,
        });
      } else {
        globalState.extensionConnection.port.postMessage({
          type: "toggleTabsMenuOpen",
          state: true,
          windowId: globalState.extensionConnection.windowId,
          tabId: globalState.extensionConnection.tabId,
        });
      }
    }
  }

  //ANALYTICS
  function RegisterAppView(from) {
    //console.log("registering view")
    const data = {
      type: "view",
      from: from,
    };
    axios
      .post("/analytics/webapp", data)
      .then(() => {
        //openLink(`https://app.partizion.io/pricing?ref=historysearch&e=${globalState.user.email}`)
        //console.log(res)
      })
      .catch(() => {
        //console.log(err)
      });
  }

  //ONBOARDING
  const [showOnboarding, setShowOnboarding] = useState(false);
  const initiateOnboarding = () => {
    //console.log("show onboard func")
    setShowOnboarding(true);
  };
  useEffect(() => {
    if (mainComponentMounted) {
      if (globalState.user && globalState.user.onBoarding && globalState.user.onBoarding.initialWalkthrough === false) {
        //console.log(storage)
        //console.log(globalState.user.onBoarding)
        setTimeout(initiateOnboarding, 1250);
      } else {
        setShowOnboarding(false);
      }
    }

    return () => {
      mainComponentMounted = false;
    };
  }, [globalState.user.onBoarding]);

  //console.log(globalState.userTrial)

  const stripeActiveSubscription = globalState?.user?.stripeSubscription && globalState?.user?.stripeSubscription?.status.toLowerCase() === "active";

  const showTrialBadge =
    globalState.user &&
    globalState.userTrial &&
    globalState.user.trialEnd &&
    !stripeActiveSubscription &&
    !globalState.userTrial.betaUser &&
    !subscription &&
    !showOnboarding &&
    globalState?.userTrial.trialEndsDays > 9;

  const showLearnMoreAboutProPrompt =
    globalState.user &&
    globalState.userTrial &&
    globalState.user.trialEnd &&
    !stripeActiveSubscription &&
    !globalState.userTrial.betaUser &&
    !subscription &&
    !showOnboarding &&
    globalState?.userTrial.trialEndsDays <= 9;

  // const showNewFeaturePrompt = true;

  //HELP FAB
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const handleClickHelp = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };
  const handleCloseHelp = () => {
    setHelpAnchorEl(null);
  };

  //SEARCH
  const [searchListItemHover, setSearchListItemHover] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleOpenSearch = () => {
    setSearchOpen(true);
  };
  const handleCloseSearch = () => {
    setSearchOpen(false);
  };
  const openSearchEnabled = true;
  useHotkeys(
    "meta+k",
    () => {
      //console.log(HotkeysEvent)
      setSearchOpen(true);
    },
    { openSearchEnabled }
  );

  //SESSIONS
  const [sessionListItemHover, setSessionListItemHover] = useState(false);
  const [sessionsOpen, setSessionsOpen] = useState(false);

  const handleOpenSessions = () => {
    navigate("/");
    globalActions.setActiveView(null);
    globalActions.saveActiveWorkspace(null);
    globalActions.saveActiveWorkspaceIndex(null);
    setSessionsOpen(true);
  };
  const clearSessionsCache = () => {
    let cursorKeys = [...cache.keys()]?.filter((key) => typeof key === "string" && key.startsWith("/sessions/recent?cursor"));
    //console.log({cursorKeys})
    if (cursorKeys && cursorKeys.length) {
      // cursorKeys.forEach((k) => console.log(k, cache.delete(k)));
    }
    let infCache = cache.get("$inf$/sessions/recent");
    if (infCache && infCache.data) {
      // console.log(infCache)
      // console.log([...infCache.data.slice(0, 1)])
      cache.set("$inf$/sessions/recent", {
        ...infCache,
        data: [{ ...infCache.data[0], cursor: undefined }],
      }); //, cursor: undefined
    }
    // setPageSize(1)
  };

  const handleCloseSessions = () => {
    clearSessionsCache();
    setSessionsOpen(false);
  };

  //USER MENU
  const [userMenuHover, setUserMenuHover] = useState(false);
  //<Typography variant="subtitle" style={{lineHeight: 1, color: '#6E6E6E',fontSize: 10}}>Good Morning, </Typography>
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const handleAccountMenuClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null);
  };
  // const openSettingsEnabled = true
  // useHotkeys('Command+8,Control+/', (HotkeysEvent) => {
  //   console.log("open settings")
  //   handleOpenSettingsDialog()
  // }, {
  //   splitKey: '_',
  //   filter: (KeyboardEvent => KeyboardEvent)
  // },
  //   [openSettingsEnabled]
  // )

  //NAVBAR DIVIDER LOGIC
  const MainScrollWindow = useRef(null);
  const [mainContainerScroll, setMainContainerScroll] = useState(0);
  const handleScrollMainWindow = (event) => {
    //console.log(event);
    if (event && event.target && event.target.id === "mainWindowScrollbars" && MainScrollWindow && MainScrollWindow.current) {
      //console.log(event.target, MainScrollWindow.current.scrollTop)
      setMainContainerScroll(MainScrollWindow.current.scrollTop);
    }
  };

  //SETTINGS DIALOG
  const [isSettingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [initialSettingsPage, setInitialSettingsPage] = useState(null);

  const handleOpenSettingsDialog = (page) => {
    setSettingsDialogOpen(true);
    if (page) setInitialSettingsPage(page);
  };
  const handleCloseSettingsDialog = () => {
    setSettingsDialogOpen(false);
    setInitialSettingsPage(null);
  };

  //resetscroll to top of page
  const mainPageScroller = useRef(null);
  const resetScrollTop = () => {
    if (mainPageScroller && mainPageScroller.current) {
      //console.log(MainScrollWindow.current.scrollTop)
      //window.scrollTo(0,0)
      MainScrollWindow.current.scrollTo(0, 0);
    }
  };

  //GLOBAL HOTKEYS
  //this key will be used to navigate to places with combinations like G then S
  useHotkeys("g", () => {
    // console.log("G KEY")
    onGPressed(true);
  });

  const [goToKeyPressed, setGoToKeyPressed] = useState(false);
  const timeoutGPressed = useRef(0);

  const onGPressed = useCallback((value) => {
    //set to g key pressed = true
    setGoToKeyPressed(value);

    //delay setting to false so that we can listen for other keys
    if (timeoutGPressed.current) {
      clearTimeout(timeoutGPressed.current);
    }
    timeoutGPressed.current = setTimeout(() => setGoToKeyPressed(false), 500);
  });

  const openSessionsEnabled = true;
  useHotkeys(
    "s",
    (HotkeysEvent, handler) => {
      // console.log(HotkeysEvent);
      //only fire event if G key was pressed before hand
      if (goToKeyPressed) handleOpenSessions();
    },
    {
      openSessionsEnabled,
    },
    [goToKeyPressed]
  );

  //----- END global Hotkeys ------
  const { stripeCustomerId, customerPortalStatus, GoToCustomerPortal } = useCustomerPortal();

  return (
    <Box
      id="RootDOM"
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        zIndex: 100,
        backgroundColor: "background.default", //theme.palette.background.default
      }}
    >
      {/* <CssBaseline /> */}
      {searchOpen && <SearchDialog handleClose={handleCloseSearch} open={searchOpen} />}

      {globalState.user.stripeSubscription ? (
        globalState.user.stripeSubscription.status === "unpaid" || globalState.user.stripeSubscription.status === "past_due" ? (
          <>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100vh",
                backdropFilter: "blur(2px)",
                boxShadow: "inset 0 0 2000px rgba(255, 255, 255, .5)",
                zIndex: 9997,
                backgroundColor: "rgba(255, 255, 255, 0.70)",
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                marginBottom: "25px",
                width: "100%",
                height: "100vh",
                padding: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.2",
                backdropFilter: "blur(8px)",
                zIndex: 9998,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  left: 0,
                  top: 0,
                  marginBottom: "25px",
                  width: "100%",
                  height: "auto",
                  padding: "10px",
                  backgroundColor: "#F50057",
                  zIndex: 9999,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="body2">
                  Your subscription is unpaid.
                  <Link
                    color="inherit"
                    // component={RouterLink}
                    // to="/manage-subscription"
                    onClick={() => GoToCustomerPortal("payment_method_update")}
                    style={{
                      marginLeft: "4px",
                      marginRight: "4px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    underline="hover"
                  >
                    Update your billing info
                  </Link>
                  to regain access to your account.
                </Typography>
              </div>
            </div>
          </>
        ) : null
      ) : null}
      {emojiPickerState.open ? (
        <CustomEmojiPicker
          anchor={emojiPickerState.anchorEl}
          chooseEmoji={chooseEmoji}
          handleClose={handleCloseEmojiPicker}
          open={emojiPickerState.open}
          placement="bottom-start"
          workspace={emojiPickerState.workspace}
        />
      ) : null}
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "inherit",
          },
        }} //</div>"permanent"
        anchor="left"
        onClose={handleMobileDrawerToggle}
        open={mobileDrawerOpen}
        sx={{
          flexShrink: 0,
          whiteSpace: "nowrap",
          zIndex: 200,
          width: drawerWidth,
          // marginRight: 3, //marginright: 3 because view bar is overlapping >:(
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          height: "100%",
          maxHeight: "100vh",
          backgroundColor: "background.sidebar",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& .MuiPaper-root": {
            zIndex: 200,
            width: drawerWidth,
            marginRight: 3, //marginright: 3 because view bar is overlapping >:(
            //backgroundColor: 'background.sidebar',
            "&::-webkit-scrollbar": {
              display: "none",
            },
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
        }}
        variant={windowSize && windowSize.width && windowSize.width < 500 ? "temporary" : "permanent"}
      >
        <div style={{ width: drawerWidth }}>
          <Box
            onClick={handleAccountMenuClick}
            onMouseLeave={() => setUserMenuHover(false)}
            onMouseOver={() => setUserMenuHover(true)}
            sx={{
              transition: "background-color 0.15s ease",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: "4px",
              backgroundColor: "rgba(216, 216, 216, 0)",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              width: "95%",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              //padding: theme.spacing(1, 2, 1, 2),
              px: 2,
              py: 1,
              // marginBottom: 16,
              cursor: "pointer",
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                maxWidth: "100%",
                marginRight: -10,
              }}
            >
              {/* <a
                            href='https://www.partizion.io'
                            rel="noreferrer"
                            style={{width: 28, height: 28, marginRight: 6}}
                            target='_blank'
                        >
                            <img
                                alt="Partizion-Logo-Mark"
                                src={LogoMark}
                                style={{ width: 26, height: 26 }}
                            />
                            
                        </a> */}
              <Avatar
                style={{
                  width: 32,
                  height: 32,
                  opacity: globalState.user.name ? 1 : 0.4,
                  marginRight: 6,
                }}
              >
                {globalState.user.name ? globalState.user.name.charAt(0).toUpperCase() : ""}
              </Avatar>
              {globalState.isAuth && globalState.user && globalState.user.name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "82%",
                  }}
                >
                  <Typography
                    style={{
                      margin: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "100%",
                      color: userMenuHover ? theme.palette.text.primary : theme.palette.text.secondary,
                      transition: "color 0.2s ease",
                    }}
                    variant="body2"
                  >
                    {" "}
                    {globalState.user.name}{" "}
                  </Typography>
                </div>
              )}
            </div>
            <UnfoldMoreRoundedIcon
              style={{
                color: userMenuHover ? theme.palette.text.secondary : "rgba(216, 216, 216, 0)",
                transition: "color 0.2s ease",
              }}
            />
          </Box>
        </div>

        <div style={{ width: drawerWidth }}>
          <Box
            elevation={0}
            onClick={handleOpenSearch}
            onMouseEnter={() => setSearchListItemHover(true)}
            onMouseLeave={() => setSearchListItemHover(false)}
            sx={{
              transition: "background-color 0.15s ease",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: "4px",
              backgroundColor: "action.hover",
              "&:hover": {
                backgroundColor: "action.selected",
              },
              width: "95%",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 0.45,
              paddingBottom: 0.45,
              paddingLeft: 2,
              paddingRight: 2,
              marginTop: 1,
              cursor: "pointer",
              color: "text.secondary",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SearchIcon
                style={{
                  fontSize: 18,
                  marginRight: 6,
                  opacity: searchListItemHover ? 0.8 : 0.5,
                  transition: "opacity 0.25s ease",
                }}
              />
              <Typography
                style={{
                  fontSize: 14,
                  marginTop: 1,
                  opacity: searchListItemHover ? 0.8 : 0.5,
                  transition: "opacity 0.25s ease",
                }}
                variant="caption"
              >
                Search
              </Typography>
            </div>
            <HotkeyChip dimmed keys={[`${commandOrControl}`, "K"]} />
          </Box>
          <Tooltip
            disableInteractive
            enterDelay={750}
            placement="right"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "white", marginRight: 6 }}> Sessions</span>
                <HotkeyChip keys={["G", "then", "S"]} />
              </div>
            }
          >
            <Box
              elevation={0}
              onClick={handleOpenSessions}
              onMouseEnter={() => setSessionListItemHover(true)}
              onMouseLeave={() => setSessionListItemHover(false)}
              style={{
                backgroundColor: sessionsOpen ? theme.palette.action.selected : sessionListItemHover ? theme.palette.action.hover : "transparent",
                color: sessionsOpen ? theme.palette.text.primary : "inherit",
              }}
              sx={{
                // transition: 'background-color 0.15s ease',
                marginRight: "auto",
                marginLeft: "auto",
                borderRadius: "4px",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "action.hover",
                },
                width: "95%",
                maxWidth: "95%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 0.45,
                paddingBottom: 0.45,
                paddingLeft: 2,
                paddingRight: 2,
                marginBottom: 2,
                marginTop: 1,
                cursor: "pointer",
                color: "text.secondary",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <HistoryRoundedIcon
                  style={{ fontSize: 18, marginRight: 6 }}
                  sx={{
                    color: sessionsOpen ? "text.primary" : "text.secondary",
                  }}
                />
                <Typography
                  style={{ fontSize: 14, marginTop: 1 }}
                  sx={{
                    color: sessionsOpen ? "text.primary" : "text.secondary",
                  }}
                  variant="caption"
                >
                  Sessions
                </Typography>
              </div>
            </Box>
          </Tooltip>
        </div>
        <Typography
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            paddingBottom: 1,
            fontSize: 12,
            marginTop: "8px",
            fontWeight: 600,
            color: "text.tertiary",
          }}
          variant="body2"
        >
          WORKSPACES
        </Typography>
        <ShadowScrollbars autoHide darkmode={isDarkMode.toString()} line linecolor={theme.palette.divider} style={{ height: "100%" }}>
          <DndProvider backend={HTML5Backend}>
            {isLoadingWorkspaces ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  paddingLeft: 1,
                  paddingRight: 1,
                  fontSize: 20,
                  lineHeight: 1.5,
                  paddingTop: "10px",
                }}
              >
                <Skeleton
                  height={24}
                  style={{
                    marginBottom: 8,
                    backgroundColor: theme.palette.action.hover,
                  }}
                  variant="rounded"
                  width="100%"
                />
                <Skeleton
                  height={24}
                  style={{
                    marginBottom: 8,
                    backgroundColor: theme.palette.action.hover,
                  }}
                  variant="rounded"
                  width="100%"
                />
                <Skeleton
                  height={24}
                  style={{
                    marginBottom: 8,
                    backgroundColor: theme.palette.action.hover,
                  }}
                  variant="rounded"
                  width="100%"
                />
                <Skeleton
                  height={24}
                  style={{
                    marginBottom: 8,
                    backgroundColor: theme.palette.action.hover,
                  }}
                  variant="rounded"
                  width="100%"
                />
              </Box>
            ) : workspacesData && workspacesData.workspaces && workspacesData.workspaces.length > 0 ? (
              <WorkspaceList
                handleClickSelectWorkspace={handleClickSelectWorkspace}
                handleWorkspaceMenuClick={handleWorkspaceMenuClick}
                handleWorkspaceMenuClose={handleWorkspaceMenuClose}
                openDeleteWorkspaceDialog={openDeleteWorkspaceDialog}
                openEmojiMenu={handleOpenEmojiPicker}
                pathnames={pathnames}
                setEmojiPickerState={setEmojiPickerState}
                workspaceMenuAnchorEl={workspaceMenuAnchorEl}
                wsMenuClickPosition={wsMenuClickPosition}
              />
            ) : (
              <div style={{ padding: 24, textOverflow: "wrap" }}>
                <div
                  style={{
                    backgroundColor: "#C0C0C0",
                    width: "100%",
                    height: "25px",
                    marginBottom: "10px",
                    opacity: 0.5,
                    borderRadius: "2px",
                  }}
                />
                <div
                  style={{
                    backgroundColor: "#C0C0C0",
                    width: "100%",
                    height: "25px",
                    marginBottom: "10px",
                    opacity: 0.25,
                    borderRadius: "2px",
                  }}
                />
                <div
                  style={{
                    backgroundColor: "#C0C0C0",
                    width: "100%",
                    height: "25px",
                    marginBottom: "10px",
                    opacity: 0.125,
                    borderRadius: "2px",
                  }}
                />
                <Typography
                  style={{
                    width: "100%",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                  variant="body2"
                >
                  {" "}
                  No Workspaces...
                </Typography>
                <Button color="primary" onClick={openWorkspaceDialog} size="small">
                  {" "}
                  Create your first workspace{" "}
                </Button>
              </div>
            )}
          </DndProvider>
        </ShadowScrollbars>
        {/* {showNewFeaturePrompt && (
          <Box
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: "4px",
              width: "95%",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "column",

              padding: 1,
              marginBottom: 2,
              border: "1px solid",
              borderRadius: 1,
              borderColor: theme.palette.divider,
              backgroundColor: theme.palette.background.paper,
              overflowWrap: "break-word",
            }}
          >
            <Typography style={{ marginBottom: 6, color: "#3d5afe" }} variant="body2">
              NEW
            </Typography>
            <Typography color={theme.palette.text.secondary} noWrap={false} style={{ fontSize: 13 }} variant="body2">
              We launched auto-updating collections!
            </Typography>
            <UpgradeButton
              color="neutral"
              component={RouterLink}
              onClick={() => RegisterAppView("Learn more sidebar prompt")}
              size="small"
              startIcon={<ArrowOutwardRounded fontSize="inherit" />}
              style={{ marginTop: 12 }}
              target="_blank"
              to="www.partizion.io/blog/introducing-auto-updating-tab-collections"
            >
              Learn more
            </UpgradeButton>
          </Box>
        )} */}
        {showLearnMoreAboutProPrompt && (
          <Box
            sx={{
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: "4px",
              width: "95%",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "column",

              padding: 1,
              marginBottom: 2,
              border: "1px solid",
              borderRadius: 1,
              borderColor: theme.palette.divider,
              overflowWrap: "break-word",
            }}
          >
            <Typography style={{ marginBottom: 6 }} variant="body2">
              Upgrade to PRO
            </Typography>
            <Typography color={theme.palette.text.secondary} noWrap={false} style={{ fontSize: 13 }} variant="body2">
              {globalState.userTrial.trialEndInString}
            </Typography>
            <Typography color={theme.palette.text.secondary} noWrap={false} style={{ fontSize: 13 }} variant="body2">
              Upgrade for limited-time beta pricing,
              <br />
              unlimited sessions, collections, and tabs.
            </Typography>
            <UpgradeButton
              color="neutral"
              component={RouterLink}
              onClick={() => RegisterAppView("Learn more sidebar prompt")}
              size="small"
              startIcon={<ArrowOutwardRounded fontSize="inherit" />}
              style={{ marginTop: 12 }}
              to="/pricing"
            >
              Learn more
            </UpgradeButton>
          </Box>
        )}
        <Box
          sx={{
            width: drawerWidth,
            bottom: 0,
            left: 0,
            paddingBottom: "1px",
            //width: drawerWidth,
            height: 50,
            flexShrink: 0,
          }}
        >
          <Divider />
          <ListItem
            button
            dense
            disabled={functionsDisabled}
            focusRipple={false}
            id="AddWorkspaceButton"
            onClick={(event) => openWorkspaceDialog(event)}
            style={{ height: "100%", paddingLeft: 16, paddingRight: 16 }}
          >
            <ListItemIcon>
              <Add color="primary" />
            </ListItemIcon>
            <ListItemText primary="Add Workspace" />
          </ListItem>
        </Box>
      </Drawer>
      <div
        style={{
          position: "relative",
          width: "100%", //'calc(100% - 240px)',
          minHeight: "100vh",
          maxHeight: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {sessionsOpen ? (
          <SessionsContainer handleOpenSettingsDialog={handleOpenSettingsDialog} key={workspaceid} sessionsOpen={sessionsOpen} />
        ) : (
          <>
            <Box
              sx={{
                position: "relative",
                top: 0,
                left: 0,
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                //padding: theme.spacing(1, 3, 0, 3),
                pt: 1,
                px: 3,
                pb: 0,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 0,
                  textOverflow: "ellipsis",
                  gap: 8,
                  flexWrap: collectionid ? "wrap" : "nowrap",
                }}
              >
                {isLoadingWorkspaces && collectionid === undefined ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "center",
                      height: 61.75,
                    }}
                  >
                    <Skeleton height={50} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
                  </Box>
                ) : isLoadingCollections && collectionid ? (
                  <div
                    style={{
                      fontSize: 20,
                      lineHeight: 1.5,
                      paddingBottom: "3px",
                      maxWidth: "calc(100% - 350px)",
                      // width: "100%",
                      height: 31,
                    }}
                  >
                    <Skeleton height={30} style={{ backgroundColor: theme.palette.action.hover }} variant="rounded" width={225} />
                  </div>
                ) : workspacesData &&
                  workspacesData.workspaces &&
                  workspacesData.workspaces.length > 0 &&
                  workspacesData.workspaces[globalState.activeWorkspaceIndex] &&
                  workspacesData.workspaces[globalState.activeWorkspaceIndex].name != null &&
                  pathnames &&
                  pathnames.length < 2 &&
                  globalState.activeWorkspace &&
                  globalState.activeWorkspace.name ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 3,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {windowSize && windowSize.width && windowSize.width < 500 && (
                      <IconButton
                        aria-label="open drawer"
                        color="inherit"
                        onClick={handleMobileDrawerToggle}
                        size="large"
                        style={{
                          margin: 0,
                          borderRadius: 4,
                          padding: 6,
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                    <div
                      onClick={(e) => handleEmojiClicked(e, globalState.activeWorkspace, "workspaceMainContainer")}
                      onMouseEnter={() => toggleHoverEmoji(true)}
                      onMouseLeave={() => toggleHoverEmoji(false)}
                      style={{
                        width: 42,
                        minWidth: 42,
                        height: 42,
                        backgroundColor:
                          globalState.activeWorkspace.icon && globalState.activeWorkspace.icon.id
                            ? emojiHovered
                              ? "rgba(110, 110, 110, 0.2)"
                              : "rgba(110, 110, 110, 0)"
                            : emojiHovered
                              ? "rgba(110, 110, 110, 0.2)"
                              : "rgba(110, 110, 110, 0)",
                        marginRight: 4,
                        borderRadius: "4px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      {
                        //EMOJI
                        globalState.activeWorkspace.icon && globalState.activeWorkspace.icon.emoji && globalState.activeWorkspace.icon.emoji.emoji ? (
                          <EmojiComponent size={32} symbol={globalState.activeWorkspace.icon.emoji.emoji} />
                        ) : //COLOR
                        globalState.activeWorkspace.icon && globalState.activeWorkspace.icon.colorIcon && globalState.activeWorkspace.icon.colorIcon.hex ? (
                          <div
                            style={{
                              // width: 36,
                              // height: 36,
                              backgroundColor: "rgba(110, 110, 110, 0)",
                              borderRadius: "2px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              //border: '0.5px solid'
                            }}
                          >
                            <div
                              style={{
                                width: 22,
                                height: 22,
                                backgroundColor: globalState.activeWorkspace.icon.colorIcon.hex,
                                borderRadius: "100%",
                              }}
                            />
                          </div>
                        ) : (
                          //<div style={{width: 12, height: 12, borderRadius: '100%', backgroundColor: '#888', opacity: isSelected ? 0.75 : isHover || emojiHovered ? 0.25 : 0.15}} />
                          <FolderOpenRoundedIcon style={{ fontSize: 32 }} />
                        )
                      }
                    </div>
                    <BigInput
                      edit={editWorkSpaceName}
                      fullWidth
                      inputRef={workspaceNameInputRef}
                      key={globalState.activeWorkspace?._id}
                      multiline={false}
                      newName={newWorkspaceName}
                      objectId={globalState.activeWorkspace?._id}
                      originalName={globalState.activeWorkspace && globalState.activeWorkspace.name ? globalState.activeWorkspace.name : null}
                      renameFunction={RenameWorkspace}
                      setEdit={setEditWorkspaceName}
                      setNewName={setNewWorkspaceName}
                    />
                  </div>
                ) : (
                  <PageBreadCrumbs params={{ workspaceid, collectionid }} pathnames={pathnames} />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexShrink: 0,
                    flexWrap: "wrap",
                    maxWidth: "100%",
                  }}
                >
                  {collectionid != null && (
                    <CollectionNavbarButtons collectionid={collectionid} handleClickSelectWorkspace={handleClickSelectWorkspace} workspaceid={workspaceid} />
                  )}
                  {!isMobile && (
                    <Fade in={globalState.extensionConnection.isConnected} timeout={1000}>
                      <Tooltip
                        interactive="true"
                        title={
                          <React.Fragment>
                            <div style={{ color: "#D8D8D8" }}>
                              Unable to connect to the Partizion extension.
                              <br />
                              <Link
                                color="inherit"
                                href="https://www.partizion.io/guide/connect-to-the-extension"
                                rel="noreferrer"
                                style={{ color: "#FFFFFF", fontWeight: "bold" }}
                                target="_blank"
                                underline="hover"
                              >
                                Learn more
                              </Link>{" "}
                              about connecting the extension to your dashboard
                            </div>
                          </React.Fragment>
                        }
                      >
                        <div
                          style={{
                            display:
                              globalState.isAuth && !globalState.extensionConnection.isConnected && globalState.extensionConnection.isReconnecting !== null
                                ? "flex"
                                : "none",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderRadius: 4,
                            border: globalState.extensionConnection.isReconnecting ? "1px solid transparent" : "1px solid rgb(196, 196, 196, 0.45)",
                            marginRight: 6,
                            marginLeft: 6,
                          }}
                        >
                          {/* <div style={{ width: 8, height: 8, backgroundColor: "#FC0D0D", borderRadius: '100%', marginRight: 4}} />
                            <div style={{ width: 8, height: 8, backgroundColor: "#FFBA1D", borderRadius: '100%', marginRight: 4}} />
                            <div style={{ width: 8, height: 8, backgroundColor: "#0DB47F", borderRadius: '100%', marginRight: 4}} /> */}
                          <Typography
                            style={{
                              color: globalState.extensionConnection.isReconnecting ? theme.palette.action.selected : theme.palette.text.secondary,
                            }}
                            variant="caption"
                          >
                            {globalState.extensionConnection.isReconnecting ? "Reconnecting..." : "Extension Offline"}
                          </Typography>
                        </div>
                      </Tooltip>
                    </Fade>
                  )}

                  {showTrialBadge && (
                    <Tooltip
                      title={
                        <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                          <div>
                            {globalState?.userTrial?.trialEndInString}
                            <br />
                            <RouterLink
                              onClick={() => RegisterAppView("trial badge tooltip")}
                              style={{
                                color: "rgba(255,255,255, 0.5)",
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                  color: "rgba(255,255,255, 0.75)",
                                },
                              }}
                              to="/pricing"
                            >
                              <span>See pricing and subscribe</span> <br />
                            </RouterLink>
                          </div>
                        </div>
                      }
                    >
                      <Button
                        color="neutral"
                        component={RouterLink}
                        onClick={() => RegisterAppView("trial badge button")}
                        size="small"
                        style={{ marginRight: 6, marginLeft: 6, border: `1px solid ${theme.palette.divider}` }}
                        to="/pricing"
                      >
                        PRO trial
                      </Button>
                    </Tooltip>
                  )}

                  {tabsMenuOpen || isMobile ? null : (
                    <Tooltip
                      disableInteractive
                      placement="bottom-end"
                      title={
                        globalState.extensionConnection.isConnected ? "" : "Connect to the Partizion extension to view your current open tabs and past sessions"
                      }
                    >
                      <Button
                        align="right"
                        color="neutral"
                        disableFocusRipple
                        disableRipple
                        onClick={toggleOpenTabsMenu}
                        size="small"
                        style={{
                          opacity: 0.95,
                          minWidth: 108,
                          paddingTop: 2,
                          paddingBottom: 2,
                          marginRight: 4,
                        }}
                      >
                        {" "}
                        View open tabs{" "}
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div
                id="navDivider"
                style={{
                  marginTop: 8,
                  transition: "background-color 0.5s ease",
                  backgroundColor: mainContainerScroll >= 20 && collectionid != null ? theme.palette.divider : "transparent",
                  height: 1,
                  width: "100%",
                }}
              />
            </Box>
            {!pathnames || (pathnames && pathnames.length >= 2) ? null : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  //padding: theme.spacing(0, 3, 0, 3),
                  px: 3,
                  minHeight: 35,
                  marginTop: 2,
                  zIndex: globalState.activeView && globalState.activeView.viewType === "Kanban" ? 99 : 199,
                }}
              >
                {pathnames[0] != "settings" && pathnames[0] != "account" ? (
                  <ViewBar GetWorkspacebyID={GetWorkspacebyID} createCollectionOpen={createCollectionOpen} openCreateCollectionDialog={openCollectionDialog} />
                ) : null}
                <Divider style={{ marginTop: 4, width: "100%", marginBottom: 0 }} />
              </Box>
            )}
            <div
              id="mainWindowScrollbars"
              onScroll={handleScrollMainWindow}
              ref={MainScrollWindow}
              style={{ height: "100%", overflow: "overlay", width: "100%" }}
            >
              <DndProvider backend={HTML5Backend}>
                <ScrollingComponent
                  horizontalStrength={vScrollStrength}
                  ref={mainPageScroller}
                  strengthMultiplier={100}
                  style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    width: "100%",
                    marginBottom: 25,
                    paddingBottom: 150,
                  }}
                  verticalStrength={vScrollStrength}
                >
                  <CollectionsContainer
                    {...props}
                    GetWorkspacebyID={GetWorkspacebyID}
                    clearSelectedTabs={clearSelectedTabs}
                    drawerWidth={drawerWidth}
                    handleClickSelectWorkspace={handleClickSelectWorkspace}
                    openAddTabsDialog={openAddTabsDialog}
                    openCollectionDialog={openCollectionDialog}
                    resetScrollTop={resetScrollTop}
                    tabsMenuOpen={tabsMenuOpen}
                  />
                </ScrollingComponent>
              </DndProvider>
            </div>
          </>
        )}
      </div>

      {tabsMenuOpen ? (
        <CurrentTabsMenu
          clearSelectedTabs={clearSelectedTabs}
          inCollection={pathnames.length == 3 ? true : false}
          open={tabsMenuOpen}
          pathnames={pathnames}
          selectedTabs={selectedTabs}
          setSelectedTabs={setSelectedTabs}
          toggleMenu={toggleOpenTabsMenu}
        />
      ) : null}
      <AccountMenu
        anchor={accountAnchorEl}
        handleClick={handleAccountMenuClick}
        handleClose={handleAccountMenuClose}
        handleOpenSettingsDialog={handleOpenSettingsDialog}
        setAnchor={setAccountAnchorEl}
      />
      <DeleteWorkspaceDialogue
        deleteIndex={deleteIndex}
        handleClose={closeDeleteWorkspaceDialog}
        handleOpen={openDeleteWorkspaceDialog}
        open={deleteWorkspaceAlertOpen}
        workspace={refThisWorkspace}
      />
      <CreateWorkSpaceDialog handleClose={closeWorkspaceDialog} handleOpen={openWorkspaceDialog} open={workspaceAlertOpen} />
      <CreateCollectionDialog
        collectionConfig={createCollectionConfig}
        handleClose={closeCollectionDialog}
        handleOpen={openCollectionDialog}
        open={createCollectionOpen}
      />
      <AddTabsDialog collection={addTabsTo} handleClose={closeAddTabsDialog} handleOpen={openAddTabsDialog} open={addTabsOpen} urlId={pathnames[2]} />
      {showOnboarding ? (
        <Suspense fallback={CodeLoading}>
          <StepTipCard />
        </Suspense>
      ) : null}
      {isSettingsDialogOpen ? (
        <Suspense fallback={CodeLoading}>
          <SettingsDialog
            fullScreen={settingsFullscreen}
            handleClose={handleCloseSettingsDialog}
            initialPage={initialSettingsPage}
            open={isSettingsDialogOpen}
          />
        </Suspense>
      ) : null}
      <HelpFabMenu anchor={helpAnchorEl} handleClick={handleClickHelp} handleClose={handleCloseHelp} />
      <div
        style={{
          position: "absolute",
          bottom: 20,
          right: tabsMenuOpen ? 320 : 20,
          marginLeft: "auto",
        }}
      >
        <Fab
          aria-label="add"
          disableFocusRipple
          onClick={handleClickHelp}
          size="small"
          sx={{
            boxShadow: "shadows.small",
            color: "text.secondary",
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: "collection.default",
            "&:hover": {
              backgroundColor: "collection.hover",
            },
          }}
        >
          <QuestionMarkRoundedIcon color="text.secondary" fontSize="small" />
        </Fab>
      </div>
    </Box>
  );
}
