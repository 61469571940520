import React, { useState, useEffect } from "react";
import { useTheme, styled } from "@mui/material/styles";
import axios from "axios";
import useGlobal from "../../GlobalState/Store/Store.js";

import { Button, Popover, Typography, List, ListItem, Divider, InputBase, CircularProgress, MenuItem } from "@mui/material";

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import ViewDayRoundedIcon from "@mui/icons-material/ViewDayRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";
import { DefaultPopover } from "./DefaultPopover.js";
import { DefaultMenu } from "./DefaultMenu.js";

export default function CreateViewMenu(props) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();

  const collectionsData = props.collectionsData;

  const open = Boolean(props.anchorEl);
  const id = open ? "simple-popover" : undefined;

  const mount = props.mount ? props.mount : "bottom";
  const isEdit = props.editView !== null && props.editView !== undefined ? true : false;

  const view = props.view;
  const setView = props.setView;
  const name = props.name;
  const setName = props.setName;

  useEffect(() => {
    if (props.editView !== null && props.editView !== undefined) {
      setName(props.editView.name);
      //console.log(props.editView.viewType)
      setView(props.editView.viewType);
    } else {
      setName("");
    }
  }, [props.editView]);
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: 24,
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.text.light}`,
      fontSize: 14,
      lineHeight: 1.45,
      paddingBottom: 4,
      paddingTop: 4,
      paddingLeft: 6,
      paddingRight: 6,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.palette.text.secondary,
      "&:focus": {
        border: `2px solid ${theme.palette.text.tertiary}`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.collection.bgLight,
      },
    },
  }));

  const [createViewReqLoading, setCreateViewReqLoading] = useState(false);
  const CreateView = () => {
    setCreateViewReqLoading(true);

    const data = {
      workspaceId: props.workspace._id,
      name: name,
      viewType: view,
      fullViewProperties:
        view && view == "Full"
          ? {
              expandedCollections: collectionsData && collectionsData.collections ? collectionsData.collections.map((c) => c._id) : [],
            }
          : null,
    };

    function callAPI() {
      axios
        .post("/views/create", data)
        .then((res) => {
          setCreateViewReqLoading(false);
          //console.log(res)
          globalActions.setActiveView(res.data.view);
          var updatedWorkspace = globalState.activeWorkspace;
          updatedWorkspace.views.push(res.data.view);
          updatedWorkspace.activeView = res.data.view._id;
          globalActions.saveActiveWorkspace(updatedWorkspace);
          //globalActions.saveActiveWorkspace(res.data.workspace)

          // setViewChangeReq({
          //   isLoading: false,
          //   isError: false
          // })
          props.handleCloseAll();
        })
        .catch((err) => {
          setCreateViewReqLoading(false);
          //console.log("Could not update workspace name")
          console.log(err);
          // setViewChangeReq({
          //   isLoading: false,
          //   isError: true
          // })
        });
    }

    callAPI();
  };

  function handleCloseMenu() {
    const curView = props.editView;
    if (curView) {
      if (view !== curView.viewType || name !== curView.name) {
        //console.log({view, name, curView})
        props.UpdateView(curView, view, name);
      }
    }

    props.handleClose();
  }

  const handleKeyPress = (e) => {
    var charCode = e.which || e.charCode || e.keyCode;
    if (charCode == 13) {
      if (isEdit) {
        handleCloseMenu();
      } else if (!createViewReqLoading) {
        CreateView();
      }
    }
  };

  return (
    <DefaultMenu
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: mount == "side" ? "center" : mount == "top" ? "center" : "bottom",
        horizontal: mount == "side" ? "right" : mount == "top" ? "right" : "left",
      }}
      id={id}
      onClose={handleCloseMenu}
      onKeyPress={handleKeyPress}
      open={open}
      style={{ marginTop: mount == "bottom" ? 5 : 0 }}
      transformOrigin={{
        vertical: mount == "side" ? "center" : mount == "top" ? "top" : "top",
        horizontal: mount == "side" ? "left" : mount == "top" ? "left" : "left",
      }}
    >
      <List style={{ width: 350 }}>
        <div style={{ padding: 8 }}>
          <BootstrapInput
            autoFocus
            fullWidth
            id="outlined-basic"
            label="View name"
            onChange={handleChangeName}
            placeholder="View name"
            size="small"
            value={name}
            variant="outlined"
          />
        </div>
      </List>
      <Divider />
      <List style={{ width: 350 }}>
        <MenuItem button onClick={() => setView("Full")} selected={view == "Full"} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <ViewDayRoundedIcon
            color={view == "Full" ? "primary" : theme.palette.text.primary}
            fontSize="small"
            style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
          />
          <div style={{ marginLeft: 8, display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              Full View{" "}
            </Typography>
            <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
              {" "}
              View your collections as expandable, full width
            </Typography>
            <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
              cards. Can view all tabs within collections.{" "}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem button onClick={() => setView("List")} selected={view == "List"}>
          <MenuRoundedIcon
            color={view == "List" ? "primary" : theme.palette.text.primary}
            fontSize="small"
            style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
          />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              List{" "}
            </Typography>
            <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
              {" "}
              View your collections in a vertical list{" "}
            </Typography>
          </div>
        </MenuItem>

        <MenuItem button onClick={() => setView("Grid")} selected={view == "Grid"}>
          <GridViewRoundedIcon
            color={view == "Grid" ? "primary" : theme.palette.text.primary}
            fontSize="small"
            style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
          />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              Grid{" "}
            </Typography>
            <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
              {" "}
              View your collections as cards in a grid{" "}
            </Typography>
          </div>
        </MenuItem>

        <MenuItem button onClick={() => setView("Kanban")} selected={view == "Kanban"}>
          <ViewKanbanOutlinedIcon
            color={view == "Kanban" ? "primary" : theme.palette.text.primary}
            fontSize="small"
            style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
          />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              Kanban{" "}
            </Typography>
            <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
              {" "}
              View your collections as cards on a board{" "}
            </Typography>
          </div>
        </MenuItem>

        {isEdit ? (
          <>
            <Divider />
            <MenuItem button onClick={() => props.DuplicateView(props.editView)}>
              <FileCopyOutlinedIcon color={theme.palette.text.primary} fontSize="small" style={{ width: 18 }} />
              <div style={{ marginLeft: 8 }}>
                <Typography style={{ marginBottom: 0 }} variant="body2">
                  {" "}
                  Duplicate{" "}
                </Typography>
              </div>
            </MenuItem>
            <MenuItem button onClick={() => props.DeleteView(props.editView)}>
              <DeleteOutlineRounded fontSize="small" style={{ width: 18, color: "#f44336" }} />
              <div style={{ marginLeft: 8 }}>
                <Typography style={{ marginBottom: 0, color: "#f44336" }} variant="body2">
                  {" "}
                  Delete{" "}
                </Typography>
              </div>
            </MenuItem>
          </>
        ) : (
          <>
            <Divider />
            <div
              style={{
                width: "100%",
                //   margin: "12px, 0px, 0px, 0px",
                position: "relative",
                padding: 8,
              }}
            >
              <Button
                color="primary"
                disabled={createViewReqLoading === true}
                fullWidth
                onClick={CreateView}
                size="small"
                style={{ height: 30, maxHeight: 30, minHeight: 30 }}
                variant="contained"
              >
                Create
              </Button>
              {createViewReqLoading && (
                <CircularProgress
                  size={20}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -14,
                    marginLeft: -14,
                  }}
                />
              )}
            </div>
          </>
        )}
      </List>
    </DefaultMenu>
  );
}

// <DefaultPopover
// anchorEl={props.anchorEl}
// anchorOrigin={{
//   vertical: mount == "side" ? "center" : mount == "top" ? "center" : "bottom",
//   horizontal: mount == "side" ? "right" : mount == "top" ? "right" : "left",
// }}
// id={id}
// onClose={handleCloseMenu}
// onKeyPress={handleKeyPress}
// open={open}
// style={{ marginTop: mount == "bottom" ? 5 : 0 }}
// transformOrigin={{
//   vertical: mount == "side" ? "center" : mount == "top" ? "top" : "top",
//   horizontal: mount == "side" ? "left" : mount == "top" ? "left" : "left",
// }}
// >
// <List style={{ width: 350 }}>
//   <ListItem>
//     <BootstrapInput
//       autoFocus
//       fullWidth
//       id="outlined-basic"
//       label="View name"
//       onChange={handleChangeName}
//       placeholder="View name"
//       size="small"
//       value={name}
//       variant="outlined"
//     />
//   </ListItem>
// </List>
// <Divider />
// <List style={{ width: 350 }}>
//   <ListItem button onClick={() => setView("Full")} selected={view == "Full"}>
//     <ViewDayRoundedIcon
//       color={view == "Full" ? "primary" : theme.palette.text.primary}
//       fontSize="small"
//       style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
//     />
//     <div style={{ marginLeft: 8, display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
//       <Typography style={{ marginBottom: 0 }} variant="body2">
//         {" "}
//         Full View{" "}
//       </Typography>
//       <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
//         {" "}
//         View your collections as expandable, full width cards. Can view all tabs within collections.{" "}
//       </Typography>
//     </div>
//   </ListItem>
//   <ListItem button onClick={() => setView("List")} selected={view == "List"}>
//     <MenuRoundedIcon
//       color={view == "List" ? "primary" : theme.palette.text.primary}
//       fontSize="small"
//       style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
//     />
//     <div style={{ marginLeft: 8 }}>
//       <Typography style={{ marginBottom: 0 }} variant="body2">
//         {" "}
//         List{" "}
//       </Typography>
//       <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
//         {" "}
//         View your collections in a vertical list{" "}
//       </Typography>
//     </div>
//   </ListItem>

//   <ListItem button onClick={() => setView("Grid")} selected={view == "Grid"}>
//     <GridViewRoundedIcon
//       color={view == "Grid" ? "primary" : theme.palette.text.primary}
//       fontSize="small"
//       style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
//     />
//     <div style={{ marginLeft: 8 }}>
//       <Typography style={{ marginBottom: 0 }} variant="body2">
//         {" "}
//         Grid{" "}
//       </Typography>
//       <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
//         {" "}
//         View your collections as cards in a grid{" "}
//       </Typography>
//     </div>
//   </ListItem>

//   <ListItem button onClick={() => setView("Kanban")} selected={view == "Kanban"}>
//     <ViewKanbanOutlinedIcon
//       color={view == "Kanban" ? "primary" : theme.palette.text.primary}
//       fontSize="small"
//       style={{ width: 20, height: 20, margin: 0, marginRight: 4 }}
//     />
//     <div style={{ marginLeft: 8 }}>
//       <Typography style={{ marginBottom: 0 }} variant="body2">
//         {" "}
//         Kanban{" "}
//       </Typography>
//       <Typography color="textSecondary" style={{ marginTop: 0 }} variant="caption">
//         {" "}
//         View your collections as cards on a board{" "}
//       </Typography>
//     </div>
//   </ListItem>

//   {isEdit ? (
//     <>
//       <Divider />
//       <ListItem button onClick={() => props.DuplicateView(props.editView)}>
//         <FileCopyOutlinedIcon color={theme.palette.text.primary} fontSize="small" style={{ width: 18 }} />
//         <div style={{ marginLeft: 8 }}>
//           <Typography style={{ marginBottom: 0 }} variant="body2">
//             {" "}
//             Duplicate{" "}
//           </Typography>
//         </div>
//       </ListItem>
//       <ListItem button onClick={() => props.DeleteView(props.editView)}>
//         <DeleteOutlineRounded fontSize="small" style={{ width: 18, color: "#f44336" }} />
//         <div style={{ marginLeft: 8 }}>
//           <Typography style={{ marginBottom: 0, color: "#f44336" }} variant="body2">
//             {" "}
//             Delete{" "}
//           </Typography>
//         </div>
//       </ListItem>
//     </>
//   ) : (
//     <ListItem>
//       <div
//         style={{
//           width: "100%",
//           margin: "24px, 0px, 0px, 0px",
//           position: "relative",
//         }}
//       >
//         <Button
//           color="primary"
//           disabled={createViewReqLoading === true}
//           fullWidth
//           onClick={CreateView}
//           size="small"
//           style={{ marginBottom: 10, height: 30, maxHeight: 30, minHeight: 30 }}
//           variant="contained"
//         >
//           Create
//         </Button>
//         {createViewReqLoading && (
//           <CircularProgress
//             size={20}
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               marginTop: -14,
//               marginLeft: -14,
//             }}
//           />
//         )}
//       </div>
//     </ListItem>
//   )}
// </List>
// </DefaultPopover>
