import React, {useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';


// const CustomInput = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: 24,
//     },

//   },
//   input: {
//     display: 'flex', flexDirection: 'row', alignItems: 'center',
//     borderRadius: 4,
//     position: 'relative',
//     // backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: 14,
//     padding: '4px 26px 4px 12px',
//     // transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:focus': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0)',
//     },
//     '&:hover': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//     },
//   },
// }))(InputBase);

// const InListItem = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: 24,
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: 'relative',
//     //border: '1px solid #ced4da',
//     fontSize: 14,
//     padding: '4px 14px 4px 12px',
//     // transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:hover': {
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//       borderRadius: 4,
//     },
//     '&:focus': {
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//       borderRadius: 4,
//     },
//   },
// }))(InputBase);]

const InListItem = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    borderRadius: 4,
    position: 'relative',
    //backgroundColor: theme.palette.background.paper,
    border: `2px solid transparent`, //${theme.palette.text.light}
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    //transition: 'border-color background-color 5s ease',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    // '&:focus': {
    //   border: `2px solid ${theme.palette.text.tertiary}`,
    //   color: theme.palette.text.primary,
    //   backgroundColor: theme.palette.collection.bgLight,
    // },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    //transition: 'border-color background-color 5s ease',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      borderRadius: 4,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

function CustomSelect(props) {
  const theme = useTheme();
  const [options, setOptions] = useState(props.options || [])
  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  //console.log({options})
  // const [selected, setSelected] = useState('');
  // //initialize value
  // useEffect(() => {
  //   if(options && options.length > 0){
  //     setSelected(options[0].value)
  //   }
  // }, [])
  //
  // const handleChange = (event) => {
  //   setSelected(event.target.value);
  // };
  const selected = props.selected
  //console.log({selected})
  const handleChange = props.handleChange

  const item = props.item

  const [open, setOpen] = React.useState(false);
  const handleClose = (event) => {
    console.log(event)
    setOpen(false);
    if(props && props.closeTrigger) props.closeTrigger()
  };
  const handleOpen = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setOpen(true);
  };

  //externmally trigger an open
  useEffect(() => {
    if(props.triggerOpen) setOpen(true)
    //if(props && props.closeTrigger) props.closeTrigger()
  }, [props.triggerOpen])

  if(options){
    return (
        <FormControl
            className={props.InListItem || props.smallSelect ? {margin: 0} : {margin: 8}} 
            style={{ margin: props.smallSelect ? 0 : 4, marginTop: 0, marginBottom: 0, width: '100%'}}
            variant="standard"
        >
            <Select
                MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                }, 

              }}
                id="demo-customized-select"
                input={props.InListItem ? <InListItem /> : <BootstrapInput />}
                labelId="demo-customized-select-label"
                onChange={(e) => handleChange(e, item, props.itemKey, e.target.value)}
            //onChange={(e) => console.log(e)}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                value={options.find(o => o.value == selected) ? options.find(o => o.value == selected) : ''}
                variant="standard"
            >
                {props.InListItem}
                {
                options && options.length > 0 ?
                options.map((o, i) => (
                    <MenuItem
                        dense
                        key={i}
                        style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: 400}}
                        value={o}
                    >
                        {
                            o && o.type ?
                              o.type == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                                  size="small"
                                  style={{width: 16, height: 16, color: theme.palette.text.primary, marginLeft: 0, marginRight: 4}}
                                                         />
                            :
                            o.type == "multiSelect" ? <ListRoundedIcon
                                size="small"
                                style={{width: 16, height: 16, color: theme.palette.text.primary, marginLeft: 0, marginRight: 4}}
                                                      />
                            :
                            null
                          :
                          null
                        }
                        <Typography
                            noWrap
                            variant="inherit"
                        >
                            {o.name}
                        </Typography>
                    </MenuItem>
          ))
          :
                <MenuItem dense>
                    <Typography
                        noWrap
                        variant="inherit"
                    >
                        No options
                    </Typography>
                </MenuItem>
        }
            </Select>
        </FormControl>
    );
  } else {
    return (
      null
    )
  }

}

export default React.memo(CustomSelect)

//<InputLabel id="demo-customized-select-label">Age</InputLabel>
