import { useEffect, useState } from 'react';
// import { useLocalStorage, useMediaQuery, useUpdateEffect } from 'usehooks-ts'

import useLocalStorage from './useLocalStorage';
import useMediaQuery from './useMediaQuery';

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'

function useDarkMode(defaultValue) {
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY) // this checks what the system or browser preference is
    
  //the persisted state in localStorage — this checks if there is a value in localsotrage manually set by the user
  const [modePreference, setModePreference] = useLocalStorage(
    'mode_preference',
    defaultValue, 
  ) //defaultValue ?? isDarkOS ?? "light",
  
  //a boolean returned to the component using this hook
  const [isDarkMode, setDarkMode] = useState(false)

  // Update darkMode if os prefers changes OR if localsotrage is manually set
  useEffect(() => {
    //console.log({isDarkOS, modePreference})

    if(modePreference !== "system"){
        //console.log(modePreference)
        setModePreference(modePreference)
        if(modePreference === "light") setDarkMode(false)
        if(modePreference === "dark") setDarkMode(true)

    } else {
        //the user wants to keep a constant theme, not based on the system
        //console.log("defaulting to system preference: " + isDarkOS) 
        //setModePreference(isDarkOS ? "dark" : "light")
        setDarkMode(isDarkOS)
    }

  }, [isDarkOS, modePreference])

  return {
    isDarkMode,
    modePreference,
    enable: () => setModePreference("dark"),
    disable: () => setModePreference("light"),
    system: () => setModePreference("system")
  }
}

export default useDarkMode