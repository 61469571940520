import React from 'react'
import { SnackbarContent, useSnackbar } from 'notistack'
import { ButtonBase, Divider, IconButton } from '@mui/material'
import { CloseRounded, MinimizeTwoTone } from '@mui/icons-material'
import { useTheme, styled } from '@mui/material/styles';
import useGlobal from '../../GlobalState/Store/Store';

import { Link as RouterLink } from 'react-router-dom';

const PlainSnackBar = React.forwardRef((props, ref) => {
  const [globalState, globalActions] = useGlobal()
    const theme = useTheme()
    const { closeSnackbar } = useSnackbar()

  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    style,
    // as well as your own custom props 👇🏼
    showCloseTabsButton,
    tabsToClose,
    showLinkToButton,
    link,
    ...other
  } = props

  const handleClose = () => {
    closeSnackbar()
  }

  //CUSTOM
  const handleCloseTabs = () => {
    if(globalState.extensionConnection.isConnected){
      globalState.extensionConnection.port.postMessage({type: "closeTabs", tabs: tabsToClose})
    }
    handleClose()
  }

  const Line = () => {
    return(
        <Divider
            flexItem
            orientation="vertical"
            style={{
          borderColor: theme.palette.snackbar.divider,
          marginLeft: 6,
          marginRight: 6
        }}
        />
    )
  }

  // console.log({showCloseTabsButton, tabsToClose})
  return (
      <SnackbarContent
          ref={ref}
        //   role="alert"
          {...other}
          style={{
            ...style,
            backgroundColor: theme.palette.snackbar.background,
            color: theme.palette.snackbar.primary,
            //border: `1px solid ${theme.palette.snackbar.divider}`,
            paddingLeft: 12,
            paddingRight: 12,
            borderRadius: 4,
            boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.35)', //theme.palette.shadows.large
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 14,
            fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif",
            minWidth: 50,
          }}
      >
          <div style={{paddingTop: 8, paddingBottom: 8, paddingRight: 8}}>
              {message}
          </div>
          <Line />
          {
            showCloseTabsButton && tabsToClose && tabsToClose.length && (
                <>
                    <ButtonBase
                        onClick={handleCloseTabs}
                        style={{  fontSize: 14, fontWeight: 500,  fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", borderRadius: 4, paddingLeft: 8, paddingRight: 8, paddingTop: 5, paddingBottom: 5}}
                        sx={{
                  transition: 'all 0.2s ease',
                  color: 'snackbar.secondary',
                  '&:hover': {
                      color: 'snackbar.primary',
                      backgroundColor: 'transparent', //'snackbar.divider', //'#7BFD15'
                    },
                }}
                    >
                        {tabsToClose.length > 1 ? `Close ${tabsToClose.length} saved tabs` : "Close saved tab"}
                    </ButtonBase>
                    <Line />
                </>
            )
         }
          {
            showLinkToButton && !!link && (
                <>
                    <ButtonBase
                        component={RouterLink}
                        onClick={handleCloseTabs}
                        style={{  fontSize: 14, fontWeight: 500,  fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", borderRadius: 4, paddingLeft: 8, paddingRight: 8, paddingTop: 5, paddingBottom: 5}}
                        sx={{
                          transition: 'all 0.2s ease',
                          color: 'snackbar.secondary',
                          '&:hover': {
                              color: 'snackbar.primary',
                              backgroundColor: 'transparent', //'snackbar.divider', //'#7BFD15'
                            },
                        }}
                        to={link}
                    >
                        Visit
                    </ButtonBase>
                    <Line />
                </>
            )
         }
          <IconButton
              aria-label="dismiss-notification"
              size="small"
              style={{
                 borderRadius: 4, padding: 4,
            }}
              sx={{
                transition: 'all 0.2s ease',
                color: 'snackbar.secondary',
                '&:hover': {
                    color: 'snackbar.primary',
                    backgroundColor: 'transparent', //'snackbar.divider', //'#7BFD15'
                  },
              }}
          >
              <CloseRounded
                  fontSize="inherit"
                  onClick={handleClose}
                //   sx={{
                //     transition: 'all 0.2s ease',
                //     color: 'snackbar.secondary',
                //     '&:hover': {
                //         color: 'snackbar.primary'
                //       },
                //   }}
              />
          </IconButton>
      </SnackbarContent>
  )
})

export default PlainSnackBar