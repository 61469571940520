import React, {useEffect, useState} from 'react'
import useGlobal from '../../GlobalState/Store/Store.js';
import axios from 'axios'

import {
  Button,
  IconButton,
  Typography,
  Box,
  MenuItem,  
  ListItem,
  ListItemIcon,
  Divider,
  Tooltip,
  Grid
} from '@mui/material';

import { DefaultMenu } from '../Menus/DefaultMenu.js';


import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import ViewDayRoundedIcon from '@mui/icons-material/ViewDayRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Add from '@mui/icons-material/Add';
import CloseRounded from '@mui/icons-material/CloseRounded';

import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';

import CustomSelect from '../Selects/CustomSelect.js';

import useWorkspaces from '../../Hooks/useWorkspaces.js'
import { mutate } from 'swr'
import update from 'immutability-helper';

import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function SortButton(props){
  const [globalState, globalActions] = useGlobal()

  const { workspacesData } = useWorkspaces(globalState.isAuth)

  //MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);


  //HELP
  const helpIcon = null

  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : null
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  //color: "#808080"

  const orderOptions = [
    {
      name: "Ascending",
      value: "ascending"
    },
    {
      name: "Descending",
      value: "descending"
    }
  ]

  const soryByOptions = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Created',
      value: 'created'
    },
    {
      name: 'Last Updated',
      value: 'updated'
    },
    {
      name: '# of Tabs',
      value: 'tabsCount'
    },
  ]

  const sortActive = activeView && activeView.sort && activeView.sort.length > 0 ? true : false
  const ButtonText =  sortActive ? activeView.sort.length > 1 ? "Sorted by " + activeView.sort.length + " fields" : "Sorted by " + activeView.sort.length + " field"  : "Sort"

  // const handleSelectSize = (event) => {
  //   updateViewProperties("size", event.target.value)
  //   setSizePreference(event.target.value)
  //   setSelectedSize(event.target.value)
  // };


  function RemoveSort(sortId){
    var arr = []
    arr.push(sortId)
    const data = {
      viewId: activeView._id,
      action: 'remove',
      removeSorts: arr
    }

    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate
    let newView = {...globalState.activeView, sort: globalState.activeView.sort.filter(s => s._id != sortId)}

    const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex(w => w._id === globalState.activeWorkspace._id) : -1
    const viewIndex = workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views ? workspacesData.workspaces[workspaceIndex].views.findIndex(v => v._id == newView._id) : -1
    const updatedWorkspaces = update(workspacesData, {workspaces: {[workspaceIndex]: {views: {[viewIndex]: {$set: newView}} }} })

    if(viewIndex > -1){
      globalActions.setActiveView(newView)
      mutate('/workspaces', updatedWorkspaces, false)
    }

    axios
      .post("/views/sort", data)
      .then(res => {
        console.log(res)
        globalActions.setActiveView(res.data.view)

        mutate("/workspaces")
        mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])

      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        // setNewNameReq({
        //   loading: null,
        //   isError: index
        // })
        // setEditWorkspaceName(false);
        // setNewWorkspaceName("")
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: true
        // })
      });
  }
  function AddSort(){
    const data = {
      viewId: activeView._id,
      action: 'add',
      sort: {
        by: "name",
        order: 'ascending'
      }
    }
    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate


    axios
      .post("/views/sort", data)
      .then(res => {
        //console.log(res)
        globalActions.setActiveView(res.data.view)
        mutate("/workspaces")
        mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])

      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        // setNewNameReq({
        //   loading: null,
        //   isError: index
        // })
        // setEditWorkspaceName(false);
        // setNewWorkspaceName("")
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: true
        // })
      });
  }

  //Handling sorts
  const [sortValues, setSortValues] = useState([])
  //sync with global state
  useEffect(() => {
    if(globalState.activeView && globalState.activeView.sort){
      var sorts = []
      if(sortActive){
        activeView.sort.forEach(s => {
          sorts.push(s)
        })
      }
      setSortValues(sorts)
    }

    return () => {
      //cleanup
      setSortValues([])
    }
  }, [globalState.activeView])

  function handleChangeSorts(event, sort, key) {
    //console.log(sort)
    //console.log(event.target.value)
    let eventValue = event ? event.target.value.value : sort[key].value
    //console.log(key)
    //console.log({sort, key})
     let values = [...sortValues];
     let index = values.findIndex(s => s._id === sort._id)
     if(index !== -1){
       // eslint-disable-next-line no-prototype-builtins
       if(sort.hasOwnProperty(key)){
         values[index][key] = eventValue//event.target.value
         setSortValues(values)

         const data = {
           viewId: activeView._id,
           action: "update",
           sort: sort
         }

         axios
           .post("/views/sort", data)
           .then(res => {
             //console.log(res)
             globalActions.setActiveView(res.data.view)
             mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])

           })
           .catch(err => {
             //console.log("Could not update workspace name")
             console.log(err)
             // setNewNameReq({
             //   loading: null,
             //   isError: index
             // })
             // setEditWorkspaceName(false);
             // setNewWorkspaceName("")
             // setViewChangeReq({
             //   isLoading: false,
             //   isError: true
             // })
           });
        }
     }
  }


  //EDITING DEFAULT VIEW
  var isEditDefault = !activeView || !views || views && views.length < 1 ? true : false

  const EditDefaultView = (event, sort, key) => {
    //console.log("EDIT DEFUALT VIEW")
    var changedSort
    if(!sort || !key){
      changedSort = {by: "name", order: 'ascending'}
    } else {
      //console.log(sort)
      //console.log(event.target.value)
      //console.log(key)
      changedSort = sort

      // eslint-disable-next-line no-prototype-builtins
      if(changedSort.hasOwnProperty(key)){
        changedSort[key] = event.target.value
      }
    }
    // if(index !== -1){
    //   if(sort.hasOwnProperty(key)){
    //     values[index][key] = event.target.value
    //     setSortValues(values)
    //
    //     const data = {
    //       viewId: activeView._id,
    //       action: "update",
    //       sort: sort
    //     }
    //   }
    // }
    const viewObj = {
      name: "Grid View",
      viewType: "Grid",
      properites: {},
      sort: [
        changedSort
      ],
      filter: []
    }
    //console.log({viewObj})
    props.EditDefaultView(viewObj) //handleClose
  }

  return (<>
      <PlainButton
          
          disableFocusRipple
          disableRipple
          onClick={openMenu}
          startIcon={<ImportExportRoundedIcon
              fontSize="small"
              style={{width: 18, marginRight: -6,}}
              sx={{
                color: sortActive ? 'text.primary' : "button.plain",
              }}
                     />}
          style={{marginRight: 8, textOverflow: "ellipsis", overflow: "hidden"}}
      >
          <Typography
              noWrap
              sx={{
                color: sortActive ? 'text.primary' : "button.plain",
              }}
              variant="button"
          >
              {ButtonText}
          </Typography>
      </PlainButton>

      <DefaultMenu
          anchorEl={anchorEl}
          anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
          onClose={handleClose}
          open={open}
          style={{zIndex: 999, maxHeight: 500, minWidth: 450, marginTop: 5}}
          transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
      >
          <Box style={{ minWidth: 450, maxWidth: 450 }}>
              <div
                  style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 6,
          overflow: "hidden",
          textOverflow: "ellipsis",

        }}
              >
                  <Typography
                      color='textSecondary'
                      noWrap
                      style={{ width: 85, display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden"}}
                      variant="subtitle2"
                  >
                      Sorts for
                      <Box
                          color="theme.primary"
                          style={{marginLeft: 4, display: 'flex', flexDirection: 'row', alignItems: 'center',}}
                      >
                          {
              activeView && activeView.viewType === "List" ?
                  <MenuRoundedIcon style={{color: '#000', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
              activeView && activeView.viewType === "Kanban" ?
                  <ViewKanbanOutlinedIcon style={{color: '#000', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
              activeView && activeView.viewType === "Full" ?
                  <ViewDayRoundedIcon style={{width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
                  <GridViewRoundedIcon style={{color: '#000', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
            }
                      </Box>
                  </Typography>
                  <Typography
                      color='textSecondary'
                      noWrap
                      style={{maxWidth: 'calc(100% - 85px)', display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden"}}
                      variant="subtitle2"
                  >
                      <Box
                          color="theme.primary"
                          style={{textOverflow: "ellipsis", overflow: "hidden"}}
                      >
                          {activeView ? activeView.name : "Default view"}
                      </Box>
                  </Typography>
                  {
          helpIcon && helpIcon.url ?
              <IconButton
                  aria-label="delete"
                  href={helpIcon.url}
                  size="small"
                  target="_blank"
              >
                  <HelpOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
          :
          null
        }
              </div>
              <Divider style={{marginTop: 4, marginBottom: 6}} />
              {
        activeView && activeView.sort && activeView.sort.length > 0 ?
          activeView.sort.map((s, index) => (
              <ListItem
                  key={index}
                  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',}}
              >
                  <Grid
                      alignItems="center"
                      container
                      direction="row"
                      justifyContent="flex-start"
                      spacing={1}
                  >
                      <Grid
                          item
                          style={{minWidth: 125,}}
                          xs={6}
                      >
                          <CustomSelect
                              handleChange={isEditDefault ? (e) => EditDefaultView(e, s, "by") : handleChangeSorts}
                              item={s}
                              itemKey="by"
                              options={soryByOptions}
                              selected={s.by}
                          />
                      </Grid>
                      <Grid
                          item
                          style={{minWidth: 125,}}
                          xs={6}
                      >
                          <CustomSelect
                              handleChange={isEditDefault ? (e) => EditDefaultView(e, s, "order") : handleChangeSorts}
                              item={s}
                              itemKey="order"
                              options={orderOptions}
                              selected={s.order}
                          />
                      </Grid>
                  </Grid>
                  <div style={{width: 50, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginLeft: 4}}>
                      <Tooltip
                          disableInteractive
                          onClick={() => isEditDefault ? EditDefaultView() : RemoveSort(s._id)}
                          placement="top-end"
                          title="Remove sort rule"
                      >
                          <IconButton
                              aria-label="delete"
                              size="small"
                          >
                              <CloseRounded fontSize="inherit" />
                          </IconButton>
                      </Tooltip>
                  </div>
              </ListItem>
          ))
        :
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 12}}>
              <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
              >
                  {' '}
                  No sorts have been applied.
              </Typography>
              <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
              >
                  {' '}
                  Use sorts to order your collections.
              </Typography>
          </div>
      }


              <Divider style={{marginBottom: 4, marginTop: 4}} />
              <MenuItem
                  button
                  onClick={(e) => isEditDefault ? EditDefaultView(e) : AddSort(e)}
                  style={{color: '#3D5AFE'}}
              >
                  <ListItemIcon style={{color: '#3D5AFE'}}>
                      <Add />
                  </ListItemIcon>
                  Add a sort
              </MenuItem>
          </Box>
      </DefaultMenu>
          </>);
}
