/* eslint-disable react/no-multi-comp */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import useGlobal from "../../../GlobalState/Store/Store.js";

import useAutocomplete from "@mui/material/useAutocomplete";

import NoSsr from "@mui/material/NoSsr";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Button, Typography, IconButton, ClickAwayListener, Divider, MenuItem } from "@mui/material";

import EditValuePopover from "./EditValuePopover.js";
import { DefaultPopover } from "../../Menus/DefaultPopover.js";

import { useTheme, styled } from "@mui/material/styles";
import { colors as labelColors } from "../../../Utils/StandardColors.js";
import MoreVert from "@mui/icons-material/MoreVert";
import useCollections from "../../../Hooks/useCollections.js";

import { mutate } from "swr";

import update from "immutability-helper";

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,
  minWidth: 0,
  width: "100%",

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:focused": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },

  "& input": {
    fontSize: "14px",
    backgroundSolor: "transparent",
    boxSizing: "border-box",
    padding: "4px 6px",
    width: 0,
    minWidth: "30px",
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },
}));

// const Label = styled('label')`
//   padding: 0 0 4px;
//   line-height: 1.5;
//   display: block;
// `;

// const InputWrapper = styled(Button)`
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   cursor: pointer;

//   &.focused {
//     background-color: #F2F2F2;
//   }

//   & input {
//     font-size: 14px;
//     background-color: transparent;

//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `;

const Tag = ({ label, bg, focused, value, property, workspace, collection, selected, setSelected, onDelete, ...props }) => {
  const theme = useTheme();

  const removeValue = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log("remove " + value.name + " from ")

    const data = {
      collectionIds: [collection._id],
      workspaceId: workspace._id,
      propertyId: property._id,
      propertyValues: [value._id],
    };

    if (property.type == "multiSelect") {
      setSelected(selected.filter((v) => v._id != value._id));
    } else {
      setSelected([]);
    }

    // mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView._id], async collectionsData => {
    //
    //  const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex(c => c._id == collection._id) : -1
    //  const propertyIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].properties.findIndex(p => p.propertyId == propertyId) : -1
    //
    //  if(propertyIndex > -1){
    //     let updatedCollections = update(collectionsData.collections, {
    //       [collectionIndex]: {tabs: {
    //         [tabIndex]: {
    //           url: {$set: newURL}
    //         }
    //       }}
    //     })
    //
    //     // console.log({updatedCollections})
    //     // console.log(updatedCollections[collectionIndex].tabs[tabIndex])
    //    return {...collectionsData, collections: updatedCollections}
    //  } else {
    //    return collectionsData
    //  }
    // }, false)

    //console.log(data)
    axios
      .post("/collections/properties/values/deselect", data)
      .then((res) => {
        //console.log(res)
        //GetCollectionsbyId(workspace, null, null, false)
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  return (
    <div
      style={{
        backgroundColor:
          theme.palette.mode === "dark" && bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
        //backgroundColor: bg,
        paddingTop: 3,
        paddingBottom: 2,
        paddingLeft: 5,
        paddingRight: focused ? 3 : 5,
        borderRadius: 2,
        marginBottom: 2,
        marginTop: 2,
        marginRight: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <Typography
        noWrap
        style={{
          color:
            theme.palette.mode === "dark"
              ? theme.palette.text.primary
              : labelColors.findIndex((l) => l.name == value.color) > -1
                ? labelColors.find((l) => l.name == value.color).darker
                : "#191919",
          fontSize: 12,
          width: "100%",
          textAlign: "left",
          fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
          fontWeight: 600,
        }}
        variant="body2"
      >
        {label}
      </Typography>
      {focused ? <CloseIcon onClick={(e) => removeValue(e)} style={{ width: 14, height: 14, marginLeft: 6, cursor: "pointer" }} /> : null}
    </div>
  );
};

const Listbox = styled("ul")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  //boxShadow: theme.shadows[8], //'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  //border: `1px solid ${theme.palette.action.hover}`,
  // borderRadius: 6,
  zIndex: 1,
  //position: 'absolute',
  //top: -12,

  // paddingBottom: 8,
  // paddingTop: 8,
  // paddingLeft: 8,
  // paddingRight: 8,
  margin: 0,
  width: "100%",

  "& li": {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 4,
    marginRight: 4,
    fontSize: 14,

    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    '& li[aria-selected="true"]': {
      backgroundColor: theme.palette.action.hover,
    },

    "& span": {
      flexGrow: 1,
    },
  },

  // "& li.Mui-focused": {
  //   backgroundColor: theme.palette.action.hover,
  // },

  "& .MuiMenuItem-root": {
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 4,
    marginRight: 4,
    fontSize: 14,
  },
}));

function CollectionPropertySelect(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const emptyArray = [];
  const [possibleValues, setPossibleValues] = useState(props.property && props.property.values ? props.property.values : emptyArray);

  const [selected, setSelected] = useState([]);

  const { collectionsData } = useCollections(
    globalState.activeWorkspace && globalState.activeWorkspace._id ? globalState.activeWorkspace._id : null,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null,
    { revalidateOnMount: false }
  );

  useEffect(() => {
    //console.log(globalState.activeWorkspace.collectionProperties)
    if (props.properties) {
      setSelected(props.properties);
    } else {
      setSelected([]);
    }
  }, [props.properties]);

  useEffect(() => {
    setPossibleValues(props.property.values);
  }, [props.property]);

  useEffect(() => {
    if (props.property) {
      //console.log(props.externalTriggerOpen)
      if (props.externalTriggerOpen === props.property._id) {
        // setPopupOpen(true)
        handleOpenPopover();
      }
    }
  }, [props.externalTriggerOpen]);

  const original = props.properties ? props.properties : emptyArray;

  let value = props.properties ? props.properties : emptyArray;

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? "property-label-select" : undefined;
  const buttonRef = useRef(null);

  const handleOpenPopover = (event) => {
    // const target = event && event.currentTarget ? EventTarget.currentTarget : buttonRef?.current
    const target = buttonRef.current;
    setPopoverAnchorEl(target);
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const [inputValue, setInputValue] = React.useState("");

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    //value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: props.property && props.property._id ? props.property._id : "custom-propert-select",
    multiple: props.property && props.property.type == "multiSelect" ? true : false,
    options: possibleValues,
    value: selected,
    disableCloseOnSelect: props.property && props.property.type == "multiSelect" ? true : false,
    openOnFocus: false,
    getOptionLabel: (option) => (option.name ? option.name : ""),
    noOptionsText: "No options",
    clearOnEscape: true,
    onClose: (e) => handleCloseSelect(e, false),
    open: popoverOpen,
    setOpen: setPopupOpen,
    inputValue: inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
    },
    getOptionSelected: (option) => option && option._id && selected.findIndex((s) => s._id === option._id) > -1,
    //onChange: (event, value, reason) => handleChangeSelected(event, value, reason)
  });

  //for keeping track of when the user is clcing on a tag to delete it, so as not to close the popup all together
  const [tagsHovered, setTagsHovered] = useState(false);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const handleCloseSelect = (event, bypass) => {
    // console.log({ event, bypass });

    const { ref } = getInputProps();
    if (ref && ref.current) ref.current.blur();

    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    let notChanged = arraysEqual(original, selected);
    //console.log({notChanged, bypass, editValueOpen})

    if ((!notChanged && !editValueOpen) || bypass) {
      //console.log("updating values")
      mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
    } else if (!editValueOpen) {
      // setPopupOpen(false)
      handleClosePopover();
    }
  };

  const handleSelectValues = (event, option, isSelected) => {
    //console.log(event, option)
    //const isSelected = value.findIndex(v => v._id == option._id) > -1 ? true : false
    const data = {
      collectionIds: [props.collection._id],
      workspaceId: globalState.activeWorkspace._id,
      propertyId: props.property._id,
      propertyValues: [option._id],
    };

    let apiString = isSelected ? "deselect" : "select";

    let index = selected.findIndex((v) => v._id == option._id);

    if (isSelected && index > -1) {
      if (props.property.type == "multiSelect") {
        // let prev = selected
        // prev.splice(index, 1)
        // console.log(prev)
        setSelected(selected.filter((v) => v._id != option._id));
        // setSelected(prevArray => {
        //   prevArray.splice(index, 1)
        //   let sliced = prevArray
        //   return sliced
        // })
        //console.log("remove single value")
      } else {
        setSelected([]);
        //console.log("set to empty array")
      }
    } else {
      if (props.property.type == "multiSelect") {
        setSelected((prevSelected) => [...prevSelected, option]);
        //console.log("push single value to multiSelect")
      } else {
        setSelected([option]);
        // setPopupOpen(false)
        handleClosePopover();
        //console.log("set to single value")
      }
    }

    axios
      .post(`/collections/properties/values/${apiString}`, data)
      .then((res) => {
        if (props.property.type == "multiSelect") {
          //do nothing
        } else {
          handleCloseSelect(event, true);
          // setPopupOpen(false)
          handleClosePopover();
        }
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  const createOption = (event, inputValue) => {
    event.preventDefault();
    event.stopPropagation();
    let newValue = {
      creating: true,
      name: inputValue,
      color: createColorSelect,
      value: inputValue,
    };
    setInputValue("");
    const data = {
      collectionIds: [props.collection._id],
      workspaceId: globalState.activeWorkspace._id,
      propertyId: props.property._id,
      newValue: newValue,
    };

    if (props.property.type == "multiSelect") {
      setSelected((prevSelected) => [...prevSelected, newValue]);
      //console.log("push single value to multiSelect")
    } else {
      setSelected([newValue]);
      // setPopupOpen(false)
      handleClosePopover();
      //console.log("set to single value")
    }

    axios
      .post(`/collections/properties/values/create`, data)
      .then((res) => {
        //console.log(res)
        //console.log(props.property.type)
        if (res && res.data && res.data.newValue) {
          let option = res.data.newValue;
          if (props.property.type == "multiSelect") {
            const { ref } = getInputProps();
            if (ref && ref.current) ref.current.focus();
            //delete last item in array and add new option
            setSelected(
              update(selected, {
                $splice: [[selected.length, 1, option]],
              })
            );
            //setSelected(prevSelected => [...prevSelected.filter(v => !v.pending), option])
            mutate("/workspaces");
          } else {
            //console.log("Created single select option")
            mutate("/workspaces");
            setSelected([option]);
            // setPopupOpen(false)
            handleClosePopover();
            handleCloseSelect(event, true);
          }

          //also add the value to possible values
          setPossibleValues((prevValues) => [...prevValues, option]);
        }
      })
      .catch((err) => {
        //console.log("Could not create option value")
        console.log(err);
      });
    //console.log(inputValue + " created")
  };
  const deleteOption = (event, option) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: props.property._id,
      valueId: option._id,
    };
    let index = selected.findIndex((v) => v._id == option._id);

    if (index > -1) {
      if (props.property.type == "multiSelect") {
        setSelected(selected.filter((v) => v._id != option._id));
      } else {
        setSelected([]);
        //console.log("set to empty array")
      }
    }

    axios
      .post(`/workspaces/collectionProperties/value/delete`, data)
      .then((res) => {
        //console.log(res)
        if (index > -1) {
          if (props.property.type == "multiSelect") {
            setSelected(selected.filter((v) => v._id != option._id));
            //console.log("remove single value")
          } else {
            setSelected([]);
            //console.log("set to empty array")
          }
        }

        mutate("/workspaces");
        handleCloseEditValue();
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  //EDITING PROPERTY VALUE
  const [editValueAnchor, setEditValueAnchor] = React.useState(null);
  const [valueToEdit, setValueToEdit] = useState(null);

  //the actual update function that calls the apiz
  const updateValue = (updatedValue) => {
    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: props.property._id,
      updatedProperty: {
        values: [
          {
            _id: updatedValue._id,
            color: updatedValue.color,
            name: updatedValue.name,
            value: updatedValue.name,
          },
        ],
      },
    };

    if (updatedValue && updatedValue._id) {
      axios
        .post(`/workspaces/collectionProperties/update`, data)
        .then((res) => {
          mutate("/workspaces");
          handleCloseEditValue();
        })
        .catch((err) => {
          //console.log("Could not create option value")
          console.log(err);
        });
    }
  };

  const handleOpenEditValue = (event, value) => {
    event.preventDefault();
    event.stopPropagation();

    //console.log("editign ", value)
    setEditValueAnchor(event.currentTarget);
    setValueToEdit(value);
  };
  const handleCloseEditValue = (ableToUpdate, updatedValue) => {
    setEditValueAnchor(null);
    //console.log({ableToUpdate, updatedValue})
    if (ableToUpdate && updateValue) {
      updateValue(updatedValue);
    }
  };

  const editValueOpen = Boolean(editValueAnchor);
  //<ListItem key={color.hex} button onClick={() => handleChangeValueColor(color)} selected={isSelected}>

  // <ListItem onKeyPress={hanldeKeyPress} size="small">
  //   <Button onClick={() => updateValue({ _id: valueToEdit._id, name: valName, color: valColor})} color="primary" disabled={!ableToUpdate} fullWidth variant="contained">
  //     Update
  //   </Button>
  // </ListItem>

  //THESE functions update the state locally
  const handleChangeValueColor = (color) => {
    const valueIndex = valueToEdit && possibleValues ? possibleValues.findIndex((v) => v._id === valueToEdit._id) : -1;
    const valueSelectedIndex = valueToEdit && selected ? selected.findIndex((s) => s._id === valueToEdit._id) : -1;
    if (valueIndex > -1) {
      //console.log(valueIndex, possibleValues[valueIndex])
      setPossibleValues(
        update(possibleValues, {
          [valueIndex]: { color: { $set: color.name } },
        })
      );
    }
    if (valueSelectedIndex > -1) {
      setSelected(
        update(selected, {
          [valueSelectedIndex]: { color: { $set: color.name } },
        })
      );
    }
  };
  const handleChangeValueName = (name) => {
    const valueIndex = valueToEdit && possibleValues ? possibleValues.findIndex((v) => v._id === valueToEdit._id) : -1;
    const valueSelectedIndex = valueToEdit && selected ? selected.findIndex((s) => s._id === valueToEdit._id) : -1;
    if (valueIndex > -1) {
      setPossibleValues(
        update(possibleValues, {
          [valueIndex]: { name: { $set: name } },
        })
      );
    }
    if (valueSelectedIndex > -1) {
      setSelected(
        update(selected, {
          [valueSelectedIndex]: { name: { $set: name } },
        })
      );
    }
  };

  const handleSelectClickAway = (event) => {
    event.stopPropagation();
    event.preventDefault();
    //console.log(event, popupOpen, focused, editValueOpen, editValueAnchor)
    if (!editValueOpen && !tagsHovered) {
      // setPopupOpen(false)
      handleClosePopover();
      handleCloseSelect(event, false);
      //props.resetTriggerExternal() handled by settimeout now
    }
  };
  const handleInputEnterKey = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log("key " + charCode + " pressed")
    if (charCode == 13 && inputValue && inputValue != null && inputValue != " " && inputValue != "" && groupedOptions.length == 0) {
      //console.log("Enter key pressed")
      //if enter is pressed login
      if (event && event.target) event.target.blur();
      createOption(event, inputValue);
    }
    // else if (charCode == 9 && inputValue && inputValue != null && inputValue != " " && inputValue != "" && groupedOptions.length == 0){
    //   event.preventDefault()
    //   event.stopPropagation()
    //   //change the color on tab press
    //   setCreateColorSelect(curColor => {
    //     let curIndex = labelColors.findIndex(c => c.name == curColor)
    //
    //     if(curIndex > -1 && curIndex -1 != labelColors.length){
    //       return labelColors[curIndex + 1].name
    //     } else {
    //       return labelColors[0].name
    //     }
    //
    //   })
    // }
  };

  const [createColorSelect, setCreateColorSelect] = useState("default");
  const handleChooseCreateColor = (event, color) => {
    event.preventDefault();
    event.stopPropagation();

    setCreateColorSelect(color);
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%", maxWidth: "100%" }}>
        <div {...getRootProps()} style={{ width: "100%" }}>
          <PlainButton
            disableFocusRipple
            disableRipple={popoverOpen}
            onClick={handleOpenPopover}
            onMouseLeave={() => setTagsHovered(false)}
            onMouseOver={() => setTagsHovered(true)}
            // ref={buttonRef}
            ref={setAnchorEl}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                border: "solid",
              }}
            >
              {selected && selected.length > 0 ? (
                props.property.type == "singleSelect" ? (
                  selected.slice(0, 1).map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={props.collection}
                        focused={popoverOpen}
                        label={option.name}
                        property={props.property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                ) : (
                  selected.map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={props.collection}
                        focused={popoverOpen}
                        label={option.name}
                        property={props.property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                )
              ) : !popoverOpen ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    marginRight: 6,
                  }}
                  variant="body2"
                >
                  Empty
                </Typography>
              ) : null}
              <input {...getInputProps()} onKeyPress={handleInputEnterKey} style={{ cursor: "pointer" }} />
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: 200,
                  padding: 12,
                  paddingBottom: 12,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                  backgroundColor: theme.palette.action.hover,
                }}
              >
                {selected && selected.length > 0 ? (
                  props.property.type == "singleSelect" ? (
                    selected.slice(0, 1).map((option, index) => {
                      const bg =
                        labelColors.findIndex((l) => l.name == option.color) > -1
                          ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                          : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                      //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                      return (
                        <Tag
                          bg={bg}
                          collection={props.collection}
                          focused={popoverOpen}
                          label={option.name}
                          property={props.property}
                          selected={selected}
                          setSelected={setSelected}
                          value={option}
                          workspace={globalState.activeWorkspace}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  ) : (
                    selected.map((option, index) => {
                      const bg =
                        labelColors.findIndex((l) => l.name == option.color) > -1
                          ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                          : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                      //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                      return (
                        <Tag
                          bg={bg}
                          collection={props.collection}
                          focused={popoverOpen}
                          label={option.name}
                          property={props.property}
                          selected={selected}
                          setSelected={setSelected}
                          value={option}
                          workspace={globalState.activeWorkspace}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  )
                ) : !popoverOpen ? (
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.secondary,
                      marginRight: 6,
                    }}
                    variant="body2"
                  >
                    Empty
                  </Typography>
                ) : null}
                {!popoverOpen && (
                  <input
                    {...getInputProps()}
                    autoFocus
                    onKeyPress={handleInputEnterKey}
                    style={{
                      cursor: "pointer",
                      border: "transparent",
                      outline: "none",
                      flexGrow: 1,
                      backgroundColor: "transparent",
                      fontWeight: 600,
                      fontSize: 12,
                    }}
                  />
                )}
              </div>
            </div>
          </PlainButton>
        </div>

        <DefaultPopover
          PaperProps={{
            style: {
              width: (popoverAnchorEl && popoverAnchorEl.offsetWidth) || null,
              padding: 0,
            },
          }}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id={popoverId}
          onClose={handleClosePopover}
          open={popoverOpen}
        >
          <Listbox
            {...getListboxProps()}
            style={{
              width: "100%",
              maxWidth: "100%",
              overflow: "hidden",
              padding: 0,
              margin: 0,
            }}
          >
            <div
              style={{
                overflowY: "auto",
                maxHeight: 200,
                padding: 12,
                paddingBottom: 12,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                backgroundColor: theme.palette.action.hover,
              }}
            >
              {selected && selected.length > 0 ? (
                props.property.type == "singleSelect" ? (
                  selected.slice(0, 1).map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={props.collection}
                        focused={popoverOpen}
                        label={option.name}
                        property={props.property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                ) : (
                  selected.map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={props.collection}
                        focused={popoverOpen}
                        label={option.name}
                        property={props.property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                )
              ) : !popoverOpen ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    marginRight: 6,
                  }}
                  variant="body2"
                >
                  Empty
                </Typography>
              ) : null}
              {popoverOpen && (
                <input
                  // {...getInputProps()}
                  autoFocus
                  onKeyPress={handleInputEnterKey}
                  style={{
                    cursor: "pointer",
                    border: "transparent",
                    outline: "none",
                    flexGrow: 1,
                    backgroundColor: "transparent",
                    fontWeight: 600,
                    fontSize: 12,
                  }}
                />
              )}
            </div>
            <Divider
              style={{
                position: "relative",
                marginBottom: 2,
                marginLeft: -8,
                marginRight: -8,
              }}
            />
            <div style={{ overflowY: "auto", maxHeight: 300, padding: 8 }}>
              <div style={{ padding: 6, paddingLeft: 8 }}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontWeight: 600,
                    fontSize: 12,
                    paddingLeft: 0.2,
                    paddingRight: 0.2,
                  }}
                  variant="body2"
                >
                  Select an option or create one
                </Typography>
              </div>
              {groupedOptions.map((option, index) => {
                const isSelected = selected.findIndex((v) => v._id == option._id) > -1 ? true : false;

                const bg =
                  labelColors.findIndex((l) => l.name == option.color) > -1
                    ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                    : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                return (
                  <li
                    {...getOptionProps({ option, index })}
                    onClick={(e) => handleSelectValues(e, option, isSelected)}
                    style={{
                      padding: 2,
                      paddingLeft: 6,
                      margin: 0,
                      maxWidth: "100%",
                    }}
                  >
                    {isSelected ? <CheckIcon fontSize="small" /> : <CheckIcon fontSize="small" style={{ opacity: 0 }} />}

                    <span style={{ margin: 0, maxWidth: "90%" }}>
                      <div
                        style={{
                          backgroundColor:
                            theme.palette.mode === "dark" && bg && bg.r
                              ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)`
                              : bg && bg.r
                                ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)`
                                : bg,
                          //backgroundColor: solidColor,
                          paddingTop: 3,
                          paddingBottom: 2,
                          paddingLeft: 5,
                          paddingRight: 5,
                          borderRadius: 2,
                          marginLeft: 4,
                          marginBottom: 0,
                          marginTop: 6,
                          display: "inline-block",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "95%",
                        }}
                      >
                        <Typography
                          noWrap
                          style={{
                            color:
                              theme.palette.mode === "dark"
                                ? theme.palette.text.primary
                                : labelColors.findIndex((l) => l.name == option.color) > -1
                                  ? labelColors.find((l) => l.name == option.color).darker
                                  : theme.palette.text.primary,
                            fontSize: 12,
                            width: "100%",
                            textAlign: "left",
                            fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                            fontWeight: 600,
                          }}
                          variant="body2"
                        >
                          {option.name}
                        </Typography>
                      </div>
                    </span>
                    <IconButton
                      id="optionsbutton"
                      onMouseDown={(e) => handleOpenEditValue(e, option)}
                      size="small"
                      //onMouseDown={() => alert("clicked")}
                      // style={{margin: 0}}
                      style={{
                        margin: 3,
                        borderRadius: 4,
                        padding: 3,
                      }}
                    >
                      <MoreVert fontSize="inherit" />
                    </IconButton>
                  </li>
                );
              })}
              {inputValue && inputValue != null && inputValue != " " && inputValue != "" ? (
                groupedOptions.length == 1 && inputValue == groupedOptions[0].name ? null : (
                  <MenuItem
                    button
                    disableRipple
                    onClick={(e) => createOption(e, inputValue)}
                    style={{
                      padding: 6,
                      margin: 0,
                      paddingLeft: 8,
                      marginBottom: 6,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.secondary,
                        marginRight: 6,
                      }}
                      variant="body2"
                    >
                      Create
                    </Typography>
                    <div
                      style={{
                        backgroundColor: labelColors.find((l) => l.name == createColorSelect)
                          ? `rgba(${hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).r}, ${
                              hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).g
                            }, ${hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).b}, 0.5)`
                          : "rgba(195, 195, 195, 0.3)", //'#C3C3C3',
                        //backgroundColor: theme.palette.mode === 'dark' && bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                        paddingTop: 2,
                        paddingBottom: 1,
                        paddingLeft: 5,
                        paddingRight: 5,
                        borderRadius: 2,
                        marginLeft: 4,
                        marginBottom: 0,
                        display: "inline-block",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "75%",
                        marginRight: 10,
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? theme.palette.text.primary
                              : labelColors.findIndex((l) => l.name == createColorSelect) > -1
                                ? labelColors.find((l) => l.name == createColorSelect).darker
                                : "#191919",
                          fontSize: 12,
                          width: "100%",
                          textAlign: "left",
                          fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 500,
                        }}
                        variant="body2"
                      >
                        {inputValue}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {labelColors.map((color) => {
                        //border: isSelected ? '2px solid' : null
                        let isSelected = createColorSelect == color.name ? true : false;
                        return (
                          <div
                            key={color.solidHex}
                            onClick={(e) => handleChooseCreateColor(e, color.name)}
                            style={{
                              marginRight: 6,
                              width: 14,
                              height: 14,
                              backgroundColor: color.solidHex,
                              borderRadius: "100%",
                              border: isSelected ? `2px solid ${theme.palette.text.primary}` : null,
                            }}
                          />
                        );
                      })}
                    </div>
                  </MenuItem>
                )
              ) : null}
            </div>
          </Listbox>
        </DefaultPopover>
      </div>

      {editValueOpen ? (
        <EditValuePopover
          anchorEl={editValueAnchor}
          deleteOption={deleteOption}
          handleChangeColor={handleChangeValueColor}
          handleChangeName={handleChangeValueName}
          handleClose={handleCloseEditValue}
          labelColors={labelColors}
          valueToEdit={valueToEdit}
        />
      ) : null}
    </React.Fragment>
  );
}

export default React.memo(CollectionPropertySelect);

{
  /* <ClickAwayListener
mouseEvent="onMouseDown"
//  onClickAway={(e) => editValueOpen || tagsHovered ? null : handleSelectClickAway(e)}
onClickAway={(e) => handleSelectClickAway(e)}
ref={setAnchorEl}
>      
<Listbox
    {...getListboxProps()}
    style={{width: '100%', maxWidth: '100%', overflowX: 'hidden', position: 'absolute', padding: 0}}
>
    <div style={{overflowY: 'auto', maxHeight: 200, padding: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', backgroundColor: theme.palette.action.hover, }}>
        {
     selected && selected.length > 0 ?

   props.property.type == "singleSelect" ?
   selected.slice(0,1).map((option, index) => {
     const bg = labelColors.findIndex(l => l.name == option.color) > -1 ? hexToRgb(labelColors.find(l => l.name == option.color).hex) : 'rgba(195, 195, 195, 0.3)'//'#C3C3C3'
     //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

     return(
         <Tag
             bg={bg}
             collection={props.collection}
             focused={popoverOpen}
             label={option.name}
             property={props.property}
             selected={selected}
             setSelected={setSelected}
             value={option}
             workspace={globalState.activeWorkspace}
             {...getTagProps({ index })}
         />
     )
   })
   :
   selected.map((option, index) => {
     const bg = labelColors.findIndex(l => l.name == option.color) > -1 ? hexToRgb(labelColors.find(l => l.name == option.color).hex) : 'rgba(195, 195, 195, 0.3)'//'#C3C3C3'
     //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

     return(
         <Tag
             bg={bg}
             collection={props.collection}
             focused={popoverOpen}
             label={option.name}
             property={props.property}
             selected={selected}
             setSelected={setSelected}
             value={option}
             workspace={globalState.activeWorkspace}
             {...getTagProps({ index })}
         />
     )
   })
   :
   !popoverOpen ?
       <Typography
           noWrap
           style={{color: theme.palette.text.secondary, marginRight: 6}}
           variant='body2'
       >
           Empty
       </Typography>
   :
   null
 }
        <input
            {...getInputProps()}
            autoFocus
            onKeyPress={handleInputEnterKey}
            style={{
             cursor: 'pointer',
             border: 'transparent',
             outline: 'none',
             flexGrow: 1,
             backgroundColor: 'transparent',
             fontWeight: 600,
             fontSize: 12
           }}
        />
    </div>
    <Divider style={{position: 'relative', marginBottom: 2, marginLeft: -8, marginRight: -8}} />
    <div style={{padding: 8, overflowY: 'auto', maxHeight: 'auto',}}>

        <div style={{padding: 6, paddingLeft: 8}}>
            <Typography
                sx={{ color: "text.secondary", fontWeight: 600, fontSize: 12, paddingLeft: 0.2, paddingRight: 0.2}}
                variant="body2"
            >
                Select an option or create one
            </Typography>
        </div>
        {groupedOptions.map((option, index) => {
         const isSelected = selected.findIndex(v => v._id == option._id) > -1 ? true : false

         const bg = labelColors.findIndex(l => l.name == option.color) > -1 ? hexToRgb(labelColors.find(l => l.name == option.color).hex) : 'rgba(195, 195, 195, 0.3)'//'#C3C3C3'
         //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'


         return(
             <li
                 {...getOptionProps({ option, index })}
                 onClick={(e) => handleSelectValues(e, option, isSelected)}
                 style={{padding: 2, paddingLeft: 6, margin: 0, maxWidth: '100%'}}
             >
                 {
               isSelected ?
                   <CheckIcon fontSize="small" />
               :
                   <CheckIcon
                       fontSize="small"
                       style={{opacity: 0}}
                   />
             }

                 <span style={{margin: 0, maxWidth: "90%"}}>
                     <div
                         style={{
                   backgroundColor: theme.palette.mode === "dark" && bg && bg.r ?  `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                   //backgroundColor: solidColor,
                   paddingTop: 3,
                   paddingBottom: 2,
                   paddingLeft: 5,
                   paddingRight: 5,
                   borderRadius: 2,
                   marginLeft: 4,
                   marginBottom: 0,
                   marginTop: 6,
                   display: 'inline-block',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'center',
                   textOverflow: "ellipsis",
                   overflow: "hidden",
                   maxWidth: "95%"
                 }}
                     >
                         <Typography
                             noWrap
                             style={{
                               color: theme.palette.mode === "dark" ? theme.palette.text.primary : labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).darker : theme.palette.text.primary,
                             fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600,}}
                             variant='body2'
                         >
                             {option.name}
                         </Typography>
                     </div>
                 </span>
                 <IconButton
                     id="optionsbutton"
                     onMouseDown={(e) => handleOpenEditValue(e, option)}
                     size="small"
               //onMouseDown={() => alert("clicked")}
                     // style={{margin: 0}}
                     style={{
                       margin: 3, borderRadius: 4, padding: 3,
                     }} 
                 >
                     <MoreVert fontSize="inherit" />
                 </IconButton>

             </li>
         )
       })}
        {
         inputValue && inputValue != null && inputValue != " " && inputValue != "" ?

           groupedOptions.length == 1 && inputValue == groupedOptions[0].name ?
           null
           :
           <MenuItem
               button
               disableRipple
               onClick={(e) => createOption(e, inputValue)}
               style={{padding: 6, margin: 0, paddingLeft: 8, marginBottom: 6}}
           >
               <Typography
                   noWrap
                   style={{color: theme.palette.text.secondary, marginRight: 6}}
                   variant='body2'
               >
                   Create
               </Typography>
               <div
                   style={{
                   backgroundColor: labelColors.find(l => l.name == createColorSelect) ? 
                   
                   `rgba(${hexToRgb(labelColors.find(l => l.name == createColorSelect).hex).r}, ${hexToRgb(labelColors.find(l => l.name == createColorSelect).hex).g}, ${hexToRgb(labelColors.find(l => l.name == createColorSelect).hex).b}, 0.5)`
                   
                   : 'rgba(195, 195, 195, 0.3)', //'#C3C3C3',
                   //backgroundColor: theme.palette.mode === 'dark' && bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                   paddingTop: 2,
                   paddingBottom: 1,
                   paddingLeft: 5,
                   paddingRight: 5,
                   borderRadius: 2,
                   marginLeft: 4,
                   marginBottom: 0,
                   display: 'inline-block',
                   flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'center',
                   textOverflow: "ellipsis",
                   overflow: "hidden",
                   maxWidth: "75%",
                   marginRight: 10,
                 }}
               >
                   <Typography
                       noWrap
                       style={{
                         color:  theme.palette.mode === "dark" ? theme.palette.text.primary : labelColors.findIndex(l => l.name == createColorSelect) > -1 ? labelColors.find(l => l.name == createColorSelect).darker : "#191919", 
                         fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 500,}}
                       variant='body2'
                   >
                       {inputValue}
                   </Typography>
               </div>
               <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                   {
                   labelColors.map(color => {
                     //border: isSelected ? '2px solid' : null
                     let isSelected = createColorSelect == color.name ? true : false
                     return(
                         <div
                             key={color.solidHex}
                             onClick={(e) => handleChooseCreateColor(e, color.name)}
                             style={{marginRight: 6, width: 14, height: 14, backgroundColor: color.solidHex, borderRadius: '100%', border: isSelected ? `2px solid ${theme.palette.text.primary}` : null}}
                         />
                     )
                   })
                 }
               </div>
           </MenuItem>
         :
         null
       }
    </div>
</Listbox>
</ClickAwayListener> */
}
