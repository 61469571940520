import React, { useEffect, useState } from "react";
import useGlobal from "../GlobalState/Store/Store.js";
import { useTheme } from "@mui/material/styles";

import axios from "axios";
import update from "immutability-helper";
import { mutate } from "swr";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import Skeleton from "@mui/material/Skeleton";

import { Divider, CircularProgress, Box } from "@mui/material";

import CollectionToolBar from "../Components/Collections/CollectionToolBar.js";

import TabsGrid from "../Components/Collections/tabs/TabsGrid.js";
import BigInput from "../Components/Inputs/BigInput.js";

import { isCollectionCurrentlyOpen } from "../Utils/isCollectionCurrentlyOpen.js";

import sessionTimeString from "../Utils/sessionTimeString";
import Typography from "@mui/material/Typography";
import { BoltRounded } from "@mui/icons-material";

//RESET SCROLL
function ScrollToTopOnMount({ resetScrollTop }) {
  useEffect(() => {
    console.log("SCROLL TO TOP");
    resetScrollTop();
  }, []);
}

function Collection(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();

  //for unique collection links
  const { collectionData, isLoadingCollection, resetScrollTop } = props; //useCollectionById(params && params.workspaceid ? params.workspaceid : null, params && params.collectionid ? params.collectionid : null) //{revalidateOnMount: true}
  // using props because the collectionContainer is already makign this call. Just need to ensure that when collectionData updates, this component updates
  const collection = collectionData && collectionData.collection ? collectionData.collection : { tabs: [] };

  const tabsMenuOpen = props.tabsMenuOpen;
  const clearSelectedTabs = props.clearSelectedTabs;

  const handleAddSnackbarToStack = props.handleAddSnackbarToStack;

  const { collectionCurrentlyOpen } = isCollectionCurrentlyOpen(collection?._id);
  //console.log(props)
  //let collection = globalState.workspaces.find(workspace => workspace.name == sentenceCase(params.workspacename)).collections.find(collection => collection.urlId == params.collectionid)

  //CHANGING COLLECTION NAME
  const collectionNameInput = React.useRef(null);
  const [editCollectionName, setEditCollectionName] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const RenameCollection = (e, collectionId, newName) => {
    //console.log(e.path)
    var wasWSButtonClick = e.path
      ? e.path.findIndex((obj) => {
          return obj.id === "workspacebutton";
        })
      : -1;

    if (wasWSButtonClick !== -1) {
      //console.log("CANCEL")
      //cancel req
      setEditCollectionName(false);
    } else {
      setEditCollectionName(false);
      //console.log("You are attempting to change the workspace " + workspace._id + " to " + newName)
      let typedName = newName.trim() || "";
      if (typedName == collection.name || typedName == null || typedName == "" || typedName == " ") {
        //console.log("name wasn't different")
        setNewCollectionName("");
        setEditCollectionName(false);
      } else {
        //update the name in local state immediately
        mutate(
          [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
          async (collectionsData) => {
            const updatedCollectionIndex =
              collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id === collectionId) : -1;
            if (updatedCollectionIndex > -1) {
              const updatedCollections = update(collectionsData.collections, {
                [updatedCollectionIndex]: {
                  $set: { ...collection, name: typedName },
                },
              });
              return { ...collectionsData, collections: updatedCollections };
            } else {
              return collectionsData;
            }
          },
          false
        );

        mutate(
          [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
          async (collectionData) => {
            const updatedCollection = update(collectionData, {
              $set: { ...collection, name: typedName },
            });
            return { ...collectionData, collection: updatedCollection };
          },
          false
        );

        setEditCollectionName(false);

        axios
          .post("/collections/updatename", {
            collectionId: collectionId,
            newName: typedName,
          })
          .then(() => {
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
            mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
          })
          .catch((err) => {
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
            mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
            console.log(err);
          });
      }
    }
  };

  // update tab name
  useEffect(() => {
    if (collection?.name) document.title = collection?.name;
  }, [collection.name]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: 8,
      }}
    >
      <ScrollToTopOnMount resetScrollTop={resetScrollTop} />

      {isLoadingCollection ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            margin: -1,
          }}
        >
          <Skeleton height={56} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="80%" />
          <Skeleton height={18} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="40%" />
        </Box>
      ) : (
        <>
          <BigInput
            edit={editCollectionName}
            fullWidth
            inputRef={collectionNameInput}
            key={collection._id}
            newName={newCollectionName}
            objectId={collection._id}
            originalName={collection && collection.name ? collection.name : null}
            renameFunction={RenameCollection}
            setEdit={setEditCollectionName}
            setNewName={setNewCollectionName}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              //width: '100%',
              marginTop: 2,
            }}
          >
            {collection.lastUpdated && collection.lastUpdated !== collection.createdOn && collection?.autoUpdateTabs && (
              <>
                <BoltRounded style={{ fontSize: 12, color: theme.palette.text.secondary }} />
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    marginRight: 12,
                  }}
                  variant="body2"
                >
                  <span style={{ color: theme.palette.text.secondary }}>Auto-Updated</span> {sessionTimeString(collection?.lastUpdated)}
                </Typography>
              </>
            )}
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "noWrap",
          marginBottom: 10,
          marginTop: 32,
        }}
      >
        <CollectionToolBar
          collection={collection}
          collectionCurrentlyOpen={collectionCurrentlyOpen}
          openAddTabsDialog={props.openAddTabsDialog}
          tabsMenuOpen={tabsMenuOpen}
        />
      </div>
      <Divider />
      {isLoadingCollection ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#C4C4C4",
            minHeight: "65vh",
            width: "100%",
          }}
        >
          <CircularProgress color="inherit" size={24} />
        </div>
      ) : (
        <DndProvider backend={HTML5Backend} style={{ height: "100%" }}>
          <div
            style={{
              paddingTop: 16,
              height: "100%",
              width: "100%",
            }}
          >
            {collectionData && collectionData.collection && collectionData.collection.tabs ? (
              <TabsGrid
                clearSelectedTabs={clearSelectedTabs}
                collection={collectionData.collection}
                collectionCurrentlyOpen={collectionCurrentlyOpen}
                handleAddSnackbarToStack={handleAddSnackbarToStack}
                openAddTabsDialog={props.openAddTabsDialog}
              />
            ) : null}
          </div>
        </DndProvider>
      )}
    </div>
  );
}

export default Collection;
