/* eslint-disable no-redeclare */
/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@mui/material/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Divider,
  Tabs,
  Tab,
  Box,
  Container,
} from "@mui/material";

import useGlobal from "../../GlobalState/Store/Store.js";

import { useParams } from "react-router-dom";

import WindowComponent from "../WindowComponent.js";
import ShadowScrollbars from "../ShadowScrollbars.js";

import AddTabsSearchBar from "../Search/AddTabsSearchBar.js";

import { mutate } from "swr";
import { useHotkeys } from "react-hotkeys-hook";

import { matchSorter } from "match-sorter";
import HotkeyChip from "../Hotkey/HotkeyChip.js";

const exampleWindows = [
  {
    id: 0,
    focused: true,
    tabs: [
      {
        id: 6,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 7,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 8,
        title: "Double lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 9,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 10,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 11,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 12,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 13,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 14,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 6,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 15,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 16,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 17,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 6,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 18,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 6,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 19,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 20,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
      {
        id: 21,
        title: "Single lil tab :)",
        url: "www.docs.com/",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
    ],
  },
  {
    id: 1,
    tabs: [
      {
        id: 3,
        title: "Copper with a really long title that won't fit on the tab",
        url: "www.copper.com/",
        favIconUrl: "https://www.copper.com/favicon-32x32.png",
      },
      {
        id: 4,
        title: "Airtable",
        url: "www.airtable.com",
        favIconUrl: "https://airtable.com/images/favicon/baymax/favicon-32x32.png",
      },
      {
        id: 5,
        title: "Notion",
        url: "www.notion.com/",
        favIconUrl: "https://www.notion.so/images/logo-ios.png",
      },
      {
        id: 6,
        title: "Figma",
        url: "https://www.figma.com/file/wh89ErurFcINY5iL0jdFsG",
        favIconUrl: "https://figmaelements.com/wp-content/uploads/2019/09/favicon-1.png",
      },
    ],
  },
  {
    id: 2,
    tabs: [
      {
        id: 6,
        title: "Single lil tab :)",
        url: "www.docs.com/123",
        favIconUrl: "https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png",
      },
    ],
  },
  {
    id: 3,
    tabs: [],
  },
];

export default function AddTabsDialog(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const params = useParams();
  const open = props.open;
  const handleClickOpen = props.handleOpen;
  const handleClose = props.handleClose;

  const collection = props.collection; //props.collections ? props.collections.find(collection => collection.urlId == props.urlId) : null
  const addtabstitle = "ADD TABS TO";
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null,
  });

  const searchFieldRef = React.useRef();

  //const windows = globalState.browserWindows
  const [windows, setWindows] = useState([]);
  const [windowMatches, setWindowMatches] = useState([]);

  const [selectedWindow, setSelectedWindow] = useState(0);
  const [selected, setSelected] = useState([]);
  const [tabCount, setTabCount] = useState(0);
  const [createTabs, setCreateTabs] = useState([]);
  const disableCreate = tabCount > 0 ? true : false;

  const [closeAfterSave, setCloseAfterSave] = useState(true);
  function hanldeChangeCloseAfterSave() {
    if (globalState.extensionConnection && globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleCloseAfterSave",
        state: !closeAfterSave,
      });
      setCloseAfterSave(!closeAfterSave);
    }
  }
  useEffect(() => {
    if (globalState.extensionConnection.isConnected && globalState.browserStorage) {
      //console.log(globalState.browserStorage)
      if (!globalState.browserStorage.closeAfterSave) {
        setCloseAfterSave(false);
      } else if (globalState.browserStorage.closeAfterSave) {
        setCloseAfterSave(true);
      } else {
        setCloseAfterSave(true); //default to true
      }
    }
  }, [globalState.extensionConnection.isConnected, globalState.browserStorage]);

  const sortWindowsByActive = (firstWindow, secondWindow) => {
    if (firstWindow.focused && !secondWindow.focused) {
      return -1;
    } else if (!firstWindow.focused && secondWindow.focused) {
      return 1;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    // setWindows(exampleWindows)
    // setWindowMatches(exampleWindows)
    //console.log("window changed")

    setWindows(globalState.browserWindows.sort(sortWindowsByActive));
    setWindowMatches(globalState.browserWindows.sort(sortWindowsByActive));
  }, [globalState.browserWindows]);

  useEffect(() => {
    if (searchInput && searchInput != "") {
      handleChangeQuery({ target: { value: searchInput } });
    }
  }, [windows]);

  //get windows from //chrome on render
  // useEffect(() => {
  //   //console.log("useEffect called: AddTabsDialog | GET windows")
  //   if(globalState.extensionConnection.isConnected && globalState.browserWindows){
  //     setWindows(globalState.browserWindows)
  //   }
  //   //console.log(windows)
  // }, [globalState.extensionConnection.isConnected])

  function handleCloseDialog(event, reason) {
    if (reason && reason == "escapeKeyDown" && searchInput != null && searchInput != "") {
      event.preventDefault();
      event.stopPropagation();
      setSearchInput("");
      handleChangeQuery();
    } else {
      setSelected([]);
      handleClose();
      setSearchInput("");
    }
    var AddTabsButton = document.getElementById("OpenTabsFab");
    AddTabsButton?.blur();
  }

  function toggleSelected(windowId, tab, allTabs) {
    var newSelected;
    //console.log("Clicked " + windowId + " " + tab.id)

    if (searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus();

    // setSelectedWindow(curSelected => {
    //   //console.log(curSelected)

    //   if(windows && windows.length > 1 && curSelected == 0){

    //   } else {
    //     //reset the query
    //     setSearchInput('')
    //     handleChangeQuery()
    //   }

    //   if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
    //   const windowIndex = windows.findIndex(w => w.id === windowId)
    //   //console.log({selectedWindow, windowIndex})
    //   if(windowIndex >= 0){
    //     //console.log("SET TO " + windowIndex)
    //     if(windows && windows.length > 1 && curSelected == 0){
    //       return curSelected
    //     } else {
    //       return windows && windows.length > 1 ? windowIndex + 1 : windowIndex //&& selectedWindow != windowIndex
    //     }
    //   } else return curSelected
    // })

    if (tab == null && allTabs) {
      //window was Selected
      //console.log("window " + windowId + " was selected")
      //check if the window is selected
      if (selected.find((window) => window.id == windowId)) {
        const selectedWindowState = selected.find((w) => w.id == windowId);
        if (allTabs && selectedWindowState && selectedWindowState.tabs && selectedWindowState.tabs.length < allTabs.length) {
          //select ALL tabs in that window
          const allTabsWindow = {
            id: windowId,
            isSelected: true,
            tabs: allTabs,
          };
          newSelected = selected.filter((window) => window.id !== windowId).concat(allTabsWindow);
          setSelected(newSelected);
        } else {
          //unselect window
          //console.log("De-selecting window")
          newSelected = selected.filter((window) => window.id !== windowId);
          setSelected(newSelected);
        }
      } else {
        //select the window
        //console.log("window does not exist in arr")
        //console.log("Selecting window")
        newSelected = {
          id: windowId,
          isSelected: true,
          tabs: allTabs,
        };
        setSelected((selected) => [...selected, newSelected]);
      }
    } else {
      //tab was selected, check the window
      //console.log("tab " + tab.id + " within window " + windowId + " was selected")
      //console.log("Does window exist: " + selected.find(window => window.id == windowId) != undefined)
      if (selected.find((window) => window.id == windowId) != undefined) {
        //window exists, check if tab exists
        //console.log("Searching tabs array for tab " + tab.id + " found: ")
        //console.log(selected.find(window => window.tabs.find((tabinarray) => tabinarray.id == tab.id)))
        if (selected.find((window) => window.tabs.find((tabinarray) => tabinarray.id == tab.id))) {
          //console.log("tabs array has the selected tab")
          var selectedWindow = selected.find((window) => window.id == windowId);
          if (selectedWindow.tabs.length == 1) {
            //this is the only tab left in the array, so remove this window
            //console.log("last tab in array, so removed")
            //var deselectTabsId = selected.indexOf(selected.find(window => window.id == windowId))
            newSelected = selected.filter((window) => window.id !== windowId);
            setSelected(newSelected);
          } else {
            //deselect the tab
            var existing = Array.from(selected.find((window) => window.id == windowId).tabs);
            //console.log("existing tabs")
            //console.log(existing)

            function deselectTab(filtertab) {
              return filtertab.id != tab.id;
            }

            var newTabs = existing.filter(deselectTab);
            //console.log("new Tabs")
            //console.log(newTabs)

            var deselectTabsId = selected.indexOf(selected.find((window) => window.id == windowId));
            newSelected = [...selected];

            newSelected[deselectTabsId] = {
              id: windowId,
              isSelected: false,
              tabs: newTabs,
            };
            setSelected(newSelected);
          }
        } else {
          //window exists but tab was not found in tabs array
          //the tab was not found in the tabs array, add it to the array
          //console.log("the tab was not found in the tabs array")
          var existing = selected.find((window) => window.id == windowId);
          //console.log("existing tabs")
          //console.log(existing)
          var newTabs = Array.from(existing.tabs);
          newTabs.push(tab);
          var notabsID = selected.indexOf(selected.find((window) => window.id == windowId));
          newSelected = [...selected];

          newSelected[notabsID] = {
            id: windowId,
            isSelected: false,
            tabs: newTabs,
          };
          setSelected(newSelected); //((selected => [...selected, newSelected]))
        }
      } else {
        //window is not there, create it
        //console.log("Window is not there yet")
        newSelected = {
          id: windowId,
          isSelected: false,
          tabs: [tab],
        };
        setSelected((selected) => [...selected, newSelected]);
      }
    }
  }

  //count the tabs in the selected Array
  function CountTabs(windows) {
    var count = 0;
    for (var i = 0; i < windows.length; i++) {
      count += windows[i].tabs.length;
    }
    return count;
  }

  function createTabsArray(windows) {
    var tabsArray = [];
    //console.log("there are " + windows.length + " windows")
    for (var i = 0; i < windows.length; i++) {
      //windows[i].tabs.forEach(tab => console.log("Adding " + tab.url + " to tab array"))
      windows[i].tabs.forEach((tab) => tabsArray.push(tab));
    }
    return tabsArray;
  }

  //update tab counts
  useEffect(() => {
    //Count the total selected tabs and create an array for the api
    setTabCount(CountTabs(selected));
    setCreateTabs(createTabsArray(selected));
  }, [selected]);

  //reset on load
  useEffect(() => {
    setReq({
      loading: false,
      isError: false,
      error: null,
    });
  }, [open]);

  function AddTabsToCollection(event) {
    //console.log("Attempting to add tabs to collection " + collection.name + collection._id + "with tabs:")
    //console.log(createTabs)

    var AddTabsButton = document.getElementById("OpenTabsFab");

    setReq({
      loading: true,
      isError: false,
      error: null,
    });
    event.preventDefault();

    const data = {
      collectionId: collection._id,
      updateType: "add",
      newTabs: createTabs,
      method: "Add tabs dialog",
    };

    axios
      .post("/collections/updatetabs", data)
      .then((res) => {
        //console.log("Added tabs")
        function StopLoading() {
          setReq({
            loading: false,
            isError: false,
            error: null,
          });
        }

        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);

        StopLoading();
        //check if duplicate error is present
        // if (res.data.code === 206) {
        //   let message = res.data.message || "Those tabs are already saved to this collection"
        //   enqueueSnackbar(message, {
        //     content: (key, message) => (
        //         <PlainSnackBar
        //             id={key}
        //             message={message}
        //         />
        //     )
        //   })
        // }
      })
      .then(() => {
        if (!req.isError) {
          handleClose();
          setSelected([]);
        }

        //AddTabsButton.blur()
      })
      .then(() => {
        if (closeAfterSave && globalState.extensionConnection && globalState.extensionConnection.isConnected) {
          var closeTabs = createTabs.map((t) => t.id);
          globalState.extensionConnection.port.postMessage({
            type: "closeTabs",
            tabs: closeTabs,
          });
        }
      })
      .catch((err) => {
        console.log("Create collection error", err);
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error" },
        });
      });
  }
  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log("key " + charCode + " pressed")
    if (charCode == 13) {
      //console.log("Enter key pressed")
      //if enter is pressed save name
      if (disableCreate) {
        AddTabsToCollection(event);
      }
    }
  };

  useEffect(() => {
    //update tabs when mounted as well (See maincontainer.js -> onOpen calls an update, this is a second measure)
    //ref AddTabsDialog.js as well (same functionality)
    var tabsMenuOpen = globalState && globalState.browserStorage && globalState.browserStorage.tabsMenuOpen ? globalState.browserStorage.tabsMenuOpen : false;
    function requestTabs() {
      if (open) {
        //console.log("checking browser tabs")
        //windows && windows.every(w => w.tabs) ? console.log("All windows loaded") : console.log("not all windows have tabs")
        if (windows && windows.every((w) => w.tabs) && windows.every((w) => w.tabs.every((tab) => getCurrentTabsTimer))) {
          //console.log("all tabs are loaded")
          clearInterval(getCurrentTabsTimer);
        } else {
          if (globalState.extensionConnection.isConnected) {
            //console.log("updating browser state")
            //console.log("request tabs")
            globalState.extensionConnection.port.postMessage({
              type: "updateCurrentTabs",
              windowId: globalState.extensionConnection.windowId,
              tabId: globalState.extensionConnection.tabId,
            });
          }
        }
      } else {
        clearInterval(getCurrentTabsTimer);
      }
    }
    const getCurrentTabsTimer = setInterval(requestTabs, 500);

    return () => {
      clearInterval(getCurrentTabsTimer);
    };
  }, [open]);

  //SELECTING WIDNOWS
  const handleChangeWindow = (event, newValue) => {
    if (searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus();
    setSelectedWindow(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;

    return (
      <div aria-labelledby={`simple-tab-${index}`} hidden={value !== index} id={`simple-tabpanel-${index}`} role="tabpanel" {...other}>
        {value === index && (
          <Container>
            <Box>{children}</Box>
          </Container>
        )}
      </div>
    );
  }

  //FILTERIGN / SEARCHIGN TABS
  const [searchInput, setSearchInput] = useState("");

  const handleChangeQuery = (e) => {
    const query = e ? e.target.value : "";
    //console.log(windows)
    //&& charCode != 38 && charCode != 40
    const trimmed = query.trim();
    if (trimmed && trimmed != null && trimmed != "" && trimmed.length && trimmed.length > 0) {
      const keys = ["tabs.*.title", "tabs.*.url"];

      const splitQuery = query.split(" ").filter(Boolean);
      //console.log({splitQuery})
      const trimmed = query.trim();

      const tempMatches = matchSorter(windows, trimmed, { keys: keys });

      if (
        (tempMatches && tempMatches.length <= 0 && splitQuery && splitQuery.length > 1) ||
        (splitQuery && splitQuery.length > 1 && splitQuery.every((t) => t != null && t != "" && t.length > 0))
      ) {
        //console.log("try split")
        const multiWordMatches = splitQuery.reduceRight((results, term) => matchSorter(results, term, { keys }), windows);

        let mwm = multiWordMatches && multiWordMatches[0] && multiWordMatches[0].tabs ? multiWordMatches[0].tabs : [];
        let w = {
          tabs: splitQuery.reduceRight((results, term) => {
            return matchSorter(results, term, { keys: ["title", "url"] });
          }, mwm),
        };

        //console.log({w})

        const test = multiWordMatches.map((w, index) => {
          w = {
            ...w,
            tabs: splitQuery.reduceRight((results, term) => {
              return matchSorter(results, term, { keys: ["title", "url"] });
            }, w.tabs),
          };
          return w;
        });

        const filteredTabWindows = multiWordMatches.map((w, index) => {
          w = {
            ...w,
            tabs: matchSorter(w.tabs, splitQuery[1], {
              keys: ["title", "url"],
            }),
          };
          return w;
        });
        setWindowMatches(filteredTabWindows.sort(sortWindowsByActive));
      } else {
        const filteredTabWindows = tempMatches.map((w, index) => {
          w = {
            ...w,
            tabs: matchSorter(w.tabs, query, { keys: ["title", "url"] }),
          };
          return w;
        });

        setWindowMatches(filteredTabWindows.sort(sortWindowsByActive));
      }
    } else {
      //setWindowMatches(exampleWindows)
      setWindowMatches(globalState.browserWindows.sort(sortWindowsByActive));
    }
  };

  //select the matched tab panel
  useEffect(() => {
    if (windowMatches && windowMatches.length > 0) {
      if (windowMatches.length != windows.length) {
        //console.log("ONE")
        const index = windows.findIndex((w) => w.id == windowMatches[0].id);
        //console.log(index)
        if (index != selectedWindow) {
          handleChangeWindow(null, index);
        }
      }
    } else if (windowMatches && windowMatches.length < 1) {
      //console.log("THREE")
      //handleChangeWindow(null, -1)
    }
  }, [windowMatches]);

  //WINDOWS
  const scrollBarsDivRef = React.useRef();
  const incrementSelectedWindow = (hotkey) => {
    //console.log(hotkey, windowMatches.length)
    const totalLength = windows ? (windows.length > 1 ? windowMatches.length + 1 : windowMatches.length) : 0; //windows ? windows.length > 1 ? windows.length + 1 : windows.length : 0
    const matchedLength = windowMatches ? windowMatches.length : 0; //windows && windows.length > 1 ? windowMatches.length + 1 :

    if (matchedLength > 1 || windows.length > 1) {
      setSelectedWindow((prevVal) => {
        //console.log(totalLength, windows.length, prevVal)
        if (hotkey == "right") {
          //DOWN
          if (prevVal >= totalLength - 1 || prevVal == null) {
            //|| windows.findIndex(w => w.id === windowMatches[windowMatches.length - 1].id) <= prevVal
            //console.log("LEFTMOST")
            let firstMatchedIndex = windows.findIndex((w) => w.id === windowMatches[0].id);
            //console.log({firstMatchedIndex})
            return firstMatchedIndex >= 0 ? firstMatchedIndex : 0;
          } else {
            //console.log("+1")
            return prevVal + 1;
          }
        } else if (hotkey == "left") {
          if (prevVal <= 0 || prevVal == null) {
            //|| windows.findIndex(w => w.id === windowMatches[0].id) >= prevVal
            //console.log("RIGHTMOST")
            let lastMatchedIndex = windowMatches.findIndex((w) => w.id === windowMatches[windowMatches.length - 1].id); //windows.findIndex(w => w.id === windowMatches[windowMatches.length - 1].id)
            //console.log(lastMatchedIndex)
            return lastMatchedIndex >= 0 ? (windows.length > 1 ? lastMatchedIndex + 1 : lastMatchedIndex) : totalLength - 1;
          } else {
            //console.log("-1")
            return prevVal - 1;
          }
        }
      });
      if (scrollBarsDivRef && scrollBarsDivRef.current)
        scrollBarsDivRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
    }
  };
  useHotkeys("left, right", (KeyboardEvent, HotkeysEvent) => {
    //console.log(HotkeysEvent)
    //setDisableMouse(true)
    incrementSelectedWindow(HotkeysEvent.shortcut);
    // console.log(KeyboardEvent)
    // KeyboardEvent.target.setSelectionRange(1, 2)
  });
  const handleInputKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log(charCode)
    if (charCode == 37) {
      event.stopPropagation();
      event.preventDefault();
      incrementSelectedWindow("left");
    } else if (charCode == 39) {
      event.stopPropagation();
      event.preventDefault();
      incrementSelectedWindow("right");
    } else if (charCode == 9) {
      event.stopPropagation();
      event.preventDefault();
      if (searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus();
    }
  };

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseDialog}
        onKeyPress={(event) => hanldeKeyPress(event)}
        open={open}
      >
        <DialogTitle id="alert-dialog-title" style={{ marginBottom: "-15px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <Typography style={{ minWidth: 140, color: "#6E6E6E" }} variant="button">
              {addtabstitle}
            </Typography>
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "98%",
                paddingBottom: 6,
              }}
            >
              <Typography
                noWrap //borderBottom: '3px solid #3D5AFE', backgroundColor: 'rgba(61, 90, 254, 0.125)', paddingLeft: 6, paddingRight: 6, padding: 4, paddingBottom: 0
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  borderRadius: 2,
                  marginLeft: 0,
                  textDecoration: "none",
                  textDecorationColor: "#3D5AFE",
                  fontWeight: "bold",
                }}
                variant="h5"
              >
                {collection && collection != undefined && collection.name ? collection.name : ""}
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <div style={{ width: "100%", padding: 24, paddingTop: 8 }}>
          {req.isError ? <DialogContentText color="secondary">{req.error.message}</DialogContentText> : null}
          <Typography style={{ color: "#6E6E6E", marginTop: 10, marginBottom: 10 }} variant="body2">
            Select currently open tabs that you want to add to this collection
          </Typography>
          <AddTabsSearchBar
            handlePreventHotKey={(e) => handleInputKeyPress(e)}
            onChange={handleChangeQuery}
            searchFieldRef={searchFieldRef}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
        <Tabs indicatorColor="primary" onChange={handleChangeWindow} scrollButtons="auto" textColor="primary" value={selectedWindow} variant="scrollable">
          {windows && windows.length > 1 && (
            <Tab
              disableFocusRipple
              label={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }} variant="body2">
                    {" "}
                    All Windows{" "}
                  </Typography>
                </div>
              }
              originalwindoworder={windows}
            />
          )}
          {windows && windows.length > 0 && windowMatches
            ? windowMatches.map((window, index) => {
                const tabs = window.tabs;
                const disabled = windowMatches.findIndex((w) => w.id == window.id) < 0;
                // const totalLength = windows && windows.length ? windows.length : 0
                const matchedWindow = windowMatches.find((w) => w.id == window.id);
                const windowIndex = windows.findIndex((w) => w.id === window.id);
                // console.log("Window " + window.id + " is " + windowMatches.findIndex(w => w.id == window.id))
                // {
                //   window.focused ?
                //   <Typography variant="body2" style={{backgroundColor: 'rgba(61, 90, 254, 0.20)', color: 'rgba(61, 90, 254, 0.85)', fontSize: 12, marginLeft: '4px', paddingLeft: '3px', paddingRight: '3px', borderRadius: '2px'}}> Active </Typography>
                //   :
                //   null
                // }
                return (
                  <Tab
                    disableFocusRipple
                    disabled={disabled}
                    // icon={
                    //   window.focused && (<Typography variant="body2" style={{backgroundColor: 'rgba(61, 90, 254, 0.20)', color: 'rgba(61, 90, 254, 0.85)', fontSize: 12, marginLeft: '4px', paddingLeft: '3px', paddingRight: '3px', borderRadius: '2px'}}> Active </Typography>)
                    // }
                    // iconPosition="end"
                    key={index}
                    // matchedWindow={matchedWindow}
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        }}
                      >
                        <Typography style={{ fontWeight: "bold" }} variant="body2">
                          {" "}
                          {`Window ${windowIndex + 1}`}{" "}
                        </Typography>
                        <Typography style={{ marginLeft: 4 }} variant="body2">
                          {matchedWindow
                            ? `(${
                                matchedWindow.tabs
                                  ? matchedWindow.tabs.length == 1
                                    ? matchedWindow.tabs.length + " tab"
                                    : matchedWindow.tabs.length + " tabs"
                                  : null
                              })`
                            : `  0 tabs`}
                        </Typography>
                        {window.focused && (
                          <Typography
                            style={{
                              backgroundColor: "rgba(61, 90, 254, 0.20)",
                              color: "rgba(61, 90, 254, 0.85)",
                              fontSize: 12,
                              marginLeft: "4px",
                              paddingLeft: "3px",
                              paddingRight: "3px",
                              borderRadius: "2px",
                            }}
                            variant="body2"
                          >
                            {" "}
                            Active{" "}
                          </Typography>
                        )}
                      </div>
                    }
                    originalwindoworder={windows}
                    style={disabled ? { opacity: 0.5 } : null}
                  />
                );
              })
            : null}
        </Tabs>
        <Divider />
        <div style={{ minWidth: "600px" }}>
          <div
            style={{
              width: "100%",
              height: "400px",
            }}
          >
            <ShadowScrollbars autoHide={false} style={{ height: "100%" }}>
              <div
                ref={scrollBarsDivRef}
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
              >
                {windows && windows.length > 1 && windowMatches && windowMatches.length > 0 && (
                  <TabPanel as="div" index={0} value={selectedWindow}>
                    {windowMatches.map((w, index) => {
                      const matchedWindow = windowMatches.find((win) => win.id == w.id);
                      const windowIndex = windows.findIndex((win) => win.id === w.id);
                      return (
                        <div key={index}>
                          <WindowComponent
                            index={windowIndex}
                            isCreateCollection
                            key={index}
                            selected={selected}
                            setSelected={toggleSelected}
                            window={matchedWindow}
                          />
                          {index + 1 !== windowMatches.length ? (
                            <Divider
                              key={index + "_divider"}
                              style={{
                                marginBottom: "20px",
                                marginTop: "20px",
                              }}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </TabPanel>
                )}
                {windows && windows.length > 0 && windowMatches && windowMatches.length > 0 ? (
                  windowMatches.map((w, i) => {
                    const matchedWindow = windowMatches.find((win) => win.id == w.id);
                    const index = windows && windows.length > 1 ? i + 1 : i;
                    const labelIndex = i;
                    return (
                      <TabPanel as="div" index={index} key={index} value={selectedWindow}>
                        <WindowComponent
                          index={index}
                          isCreateCollection
                          key={index}
                          labelIndex={labelIndex}
                          originalwindoworder={windows}
                          selected={selected}
                          setSelected={toggleSelected}
                          window={matchedWindow}
                        />
                      </TabPanel>
                    );
                  })
                ) : windows && windows.length > 0 && windows[0].tabs && windows[0].tabs.length > 0 && windowMatches && windowMatches.length < 1 ? (
                  <div
                    style={{
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography align="center" noWrap style={{ color: "#6E6E6E", marginBottom: 10 }} variant="body1">
                      🔍 &nbsp; No matches
                    </Typography>
                    <Typography align="center" noWrap style={{ color: "#6E6E6E" }} variant="body2">
                      Try searching for a tab's <span style={{ color: "#000" }}> Title </span> or <span style={{ color: "#000" }}> URL </span>
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      minHeight: 100,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography align="center" noWrap style={{ color: "#6E6E6E", marginBottom: 10 }} variant="body1">
                      No Windows open
                    </Typography>
                  </div>
                )}
              </div>
            </ShadowScrollbars>
          </div>
        </div>

        <Divider />
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: globalState.extensionConnection && globalState.extensionConnection.isConnected ? "space-between" : "flex-end",
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          {globalState.extensionConnection && globalState.extensionConnection.isConnected ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={closeAfterSave}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  label="Close tabs after saving"
                  onChange={hanldeChangeCloseAfterSave}
                  size="small"
                />
              }
              label="Close tabs after saving"
            />
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button color="secondary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <div
              style={{
                position: "relative",
              }}
            >
              <Button
                color="primary"
                disableRipple={!disableCreate ? true : false}
                disabled={!disableCreate || req.loading}
                fullWidth
                onClick={(event) => AddTabsToCollection(event)}
                style={{ marginLeft: 8 }}
                variant="contained"
              >
                {selected.length > 0 ? (tabCount > 1 ? "Add " + tabCount + " tabs" : "Add " + tabCount + " tab") : "Select tabs"}
              </Button>
              {req.loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
