import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const WhyPay = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        marginTop: 48,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 48,
        paddingBottom: 48,
      }}
    >
      <div style={{ width: "100%", maxWidth: 800, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 24 }}>
        <Typography style={{ textAlign: "center", color: theme.palette.primary.main }} variant="h5">
          Why should you pay for Partizion?
        </Typography>
        <Typography style={{ textAlign: "center", color: theme.palette.text.primary, fontSize: 48, fontWeight: 500, marginBottom: 24 }} variant="h3">
          There is good reason <span style={{ fontStyle: "italic" }}>NOT</span> to support Partizion.
        </Typography>
        <div
          style={{
            marginBottom: 24,
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: 18,
          }}
        >
          <Typography style={{ fontSize: 18, color: theme.palette.text.secondary }} variant="body1">
            Not everyone can afford to pay for a tab manager. That’s why we have a generous free trial (Not to mention, there are plenty of free extensions out
            there).
          </Typography>
          <Typography style={{ fontSize: 18, color: theme.palette.text.primary }} variant="body1">
            But if you can, here are 5 good reasons support us:
          </Typography>
          <Typography style={{ marginLeft: 12, fontSize: 16, color: theme.palette.text.primary }} variant="body1">
            ✦ We don’t show Ads. We don’t — and will never — sell or share your data.
          </Typography>
          <Typography style={{ marginLeft: 12, fontSize: 16, color: theme.palette.text.primary }} variant="body1">
            ✦ Partizion is 100% backed by our generous users, and we’re here for the long haul — that means we’ll continue developing and maintaining Partizion.
          </Typography>
          <Typography style={{ marginLeft: 12, fontSize: 16, color: theme.palette.text.primary }} variant="body1">
            ✦ Partizion has been around 4 years and counting. Our users have saved over 1 Million Sessions and 10s of millions of tabs.
          </Typography>
          <Typography style={{ marginLeft: 12, fontSize: 16, color: theme.palette.text.primary }} variant="body1">
            ✦ We listen to feedback from our users — we've already shipped features from thousands of conversations with our users
          </Typography>
          <Typography style={{ marginLeft: 12, fontSize: 16, color: theme.palette.text.primary }} variant="body1">
            ✦ We're an independent company on a mission to make great software, thats it.
          </Typography>
        </div>
        <Typography style={{ fontSize: 18, fontWeight: 600, color: theme.palette.text.primary }} variant="body1">
          We care about your privacy and want to build a tool that is around for a long time — but only thanks to your support. If you believe in well designed
          software that doesn’t sell your data, please support us by subscribing to the PRO plan.
        </Typography>
      </div>
    </div>
  );
};

export default WhyPay;
