/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';

import { useTheme, styled } from '@mui/material/styles';

import EmojiComponent from '../Emojis/EmojiComponent';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';


import {
  Typography,
  InputBase,
  MenuItem,
} from '@mui/material';


const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  padding: 0,
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    borderRadius: 0,
    border: 'none'
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 4,
    paddingRight: 4,
    overflowX: 'hidden',
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      minWidth: 0,
      alignItems: 'flex-start',
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4, 
      fontSize: 14,
      // borderBottom: `1px solid  ${
      //   theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      // }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
 "& li.Mui-focused": {
    backgroundColor: theme.palette.action.hover,  
  },
}));


export default function MoveTabMenu(props) {
  const theme = useTheme();
  //const [anchorEl, setAnchorEl] = useState(null);
  const anchorEl = props.anchorEl
  const open = Boolean(anchorEl)

  const handleMoveTabs = props.handleMoveTabs
  //const [open, setOpen] = useState(true);

  const options = props.options
  const workspaces = props.workspaces
  const allMatches = props.allMatches
  const loading = props.loading

  const handleClick = props.handleClick
  const handleClose = props.handleClose

  const handleAutoCompleteKeyPress = (event) => {
      var charCode = event.which || event.charCode || event.keyCode
      console.log({event, charCode})
      if(charCode == 13 || charCode === 27 || charCode === 38 || charCode === 40) {
          // keep current escape, and arrow key behaviour, ENTER
          event.stopPropagation()
      } 
  }


  return (

      <Autocomplete
          PopperComponent={PopperComponent}
          disablePortal
          fullWidth
          getOptionLabel={(option) => option.name}
          groupBy={(option) => {
          const index = workspaces.findIndex(w => w._id === option.workspace)
          const isCurrent = option.workspace == props.activeWorkspace._id
          if(index > -1){
           return workspaces[index]
          } else {
            return
          }
        }}
          loading={loading}
          noOptionsText="No collections"
          onChange={((event, selected, reason) => handleMoveTabs(selected._id))}

        //options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          onClose={(event, reason) => {
            if (reason === 'escape') {
              handleClose(event);
            }
          }}
        // renderGroup={(options) => {
        //   console.log(options)
        //   return (
        //     <ListSubheader> {options.group} </ListSubheader>
        //   )
        //   }}
          onKeyDown={handleAutoCompleteKeyPress}
          open={open}
          options={options}

          renderGroup={(params) => {
                const index = workspaces.findIndex(w => w._id === params.group._id)
                const isCurrent = params.group._id == props.activeWorkspace._id
                const hasIcon = workspaces[index] && workspaces[index].icon && workspaces[index].icon.native
                return [
                    <div
                        key={params.group._id}
                        style={{
                          opacity: 1,
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                          textOverflow: "ellipsis", overflow: "hidden",
                          marginLeft: -4,
                          marginRight: -4,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 4,
                          paddingBottom: 4,
                          lineHeight: 1.25,
                          borderRadius: 0,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          //borderTop: index === 0 ? null : `1px solid ${theme.palette.divider}`,
                          marginTop: index === 0 ? 0 : 16,
                          position: 'relative',
                        }}
                    >
                        {
                          workspaces && workspaces[index] && workspaces[index].icon && !workspaces[index].icon.id && workspaces[index].icon.emoji && workspaces[index].icon.emoji.emoji || workspaces && workspaces[index] && workspaces[index].icon && !workspaces[index].icon.id && workspaces[index].icon.colorIcon && workspaces[index].icon.colorIcon.hex ?
                              <div style={{ minWidth: 24, minHeight: 24, display: 'flex', text: 'center', justifyContent: "center", alignItems: "center", marginRight: 2,}}>
                                  {
                                workspaces[index].icon.emoji && workspaces[index].icon.emoji.emoji ?
                                    <EmojiComponent
                                        size={16}
                                        symbol={workspaces[index].icon.emoji.emoji}
                                    />
                                  :
                                  workspaces[index].icon.colorIcon && workspaces[index].icon.colorIcon.hex ?
                                      <div style={{ width: 12, height: 12, backgroundColor: workspaces[index].icon.colorIcon.hex, borderRadius: '100%', }} />
                                    :
                                    null
                              }
                              </div>
                            :
                            null
                        }
                        <Typography
                            noWrap
                            style={{color: theme.palette.text.secondary, margin: 0, textOverflow: "ellipsis", overflow: "hidden", maxWidth: isCurrent ? '70%' : null, fontSize: 12, fontWeight: 600}}
                            variant="body2"
                        >
                            {workspaces[index].name.toUpperCase()}
                        </Typography>
                        {isCurrent ? <Typography
                            style={{backgroundColor: 'rgba(61, 90, 254, 0.20)', color: 'rgba(61, 90, 254, 0.85)', fontSize: 12, marginLeft: '4px', paddingLeft: '3px', paddingRight: '3px', borderRadius: '2px', position: 'absolute', right: 6}}
                            variant="body2"
                                     >
                            {' '}
                            Current
                            {' '}
                        </Typography> : null}
                    </div>,
            params.children,
          ]
        }}
          renderInput={(params) => (
              <div style={{width: 350,}}>
                  <BootstrapInput
                      autoFocus
                      fullWidth
                      inputProps={params.inputProps}
                      placeholder="Move to..."
                      ref={params.InputProps.ref}
                      style={{
                        padding: 10,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        '& input': {
                          borderRadius: 4,
                          backgroundColor: theme.palette.background.paper,
                          padding: 8,
                          },
                      }}
                  />
              </div>
             )}  
          renderOption={(props, option) => (
              <StyledMenuItem
                  component="li"
                  {...props}
                  key={option._id}
                  
              >
                  <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                        
                    }}
                  >
                      <Typography
                          noWrap
                              // style={{color: isSelected ? "rgba(255, 255, 255, 1)" : theme.palette.text.primary, fontWeight: 600, fontSize: 15}}
                          variant='body2'
                      >
                          {option.name}
                      </Typography>
                      {
                        option && option.tabs ?
                            <Typography
                                color="text.tertiary"
                                style={{flexShrink: 0, marginLeft: 4}}
                                variant="caption"
                            >
                                {option.tabs.length}
                                {' '}
                                tab
                                {option.tabs.length > 1 || option.tabs.length < 1 ? "s" : null}
                            </Typography>
                        :
                        null
                      }
                  </div>
                  
              </StyledMenuItem>
          )}

          // renderOption={(props, option) => {
          // const isCurCollection = options._id == props.collectionId
          // console.log(options)
          // if(isCurCollection) return
          // return (
          //     <MenuItem>
          //         <div 
          //             style={{
          //             borderRadius: 4,
          //             paddingLeft: 8,
          //             paddingRight: 8,
          //             marginLeft: 4,
          //             marginRight: 4, 
          //             fontSize: 14,
          //             width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', textOverflow: "ellipsis", overflow: "hidden"
          //             }}
          //         >
          //             <Typography
          //                 style={{color: '#000', marginBottom: 3, margin: 0}}
          //                 variant="body2"
          //             >
          //                 {options.name}
          //             </Typography>
          //             {
          //         options && options.tabs ?
          //             <Typography variant="caption">
          //                 {options.tabs.length}
          //                 {' '}
          //                 tab
          //                 {options.tabs.length > 1 || options.tabs.length < 1 ? "s" : null}
          //             </Typography>
          //         :
          //         null
          //       }
          //         </div>
          //     </MenuItem>
              
          // )
          // }}
          renderTags={() => null}

      />

  );
}
