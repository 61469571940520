import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import useGlobal from '../../GlobalState/Store/Store.js';

export default function SimpleSnackbar(props) {
  //const [open, setOpen] = React.useState(false);
  const [globalState, globalActions] = useGlobal()
  const open = globalState.globalSnackbarState.open ? globalState.globalSnackbarState.open : false
  //const [open, setOpen] = useState(false)
  const closeTime = globalState.globalSnackbarState.closeTime ? globalState.globalSnackbarState.closeTime : 3000

  const handleClick = () => {
    globalActions.setGlobalSnackbarState({
      open: true,
    })
    //setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
      globalActions.setGlobalSnackbarState({
        open: false,
      })
    //setOpen(false);
  };
  //console.log("snackbar mounted " + open)
  return (
      <div>
          <Snackbar
              action={
                  <React.Fragment>
                      <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                          size="small"
                      >
                          <CloseIcon fontSize="small" />
                      </IconButton>
                  </React.Fragment>
        }
              anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
              autoHideDuration={closeTime}
              message={globalState.globalSnackbarState.message}
              onClose={handleClose}
              open={open}
          />
      </div>
  );
}
//
// <Button color="secondary" size="small" onClick={handleClose}>
//   UNDO
// </Button>
