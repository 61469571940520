/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import useGlobal from '../../GlobalState/Store/Store.js';
import axios from 'axios'

import { styled, useTheme } from '@mui/material/styles';

import PropertyMenu from '../Menus/PropertyMenu.js';

import {
  Button,
  Popover,
  IconButton,
  Typography,
  Box,
  List,
  MenuItem,
  Divider,
  ListItemSecondaryAction,
  Switch,
  ListSubheader,

} from '@mui/material';


import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import ViewDayRoundedIcon from '@mui/icons-material/ViewDayRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';


import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import CustomSelect from '../Selects/CustomSelect.js';

import useWorkspaces from '../../Hooks/useWorkspaces.js'
import { mutate } from 'swr'
import update from 'immutability-helper';

import { DefaultMenu } from '../Menus/DefaultMenu.js';

const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function PropertiesButton(props){
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal()
  const { workspacesData } = useWorkspaces(globalState.isAuth, {revalidateOnMount: false})

  let isButtonMounted = true //use for cleanup

  //MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    closeTrigger()
  };
  const open = Boolean(anchorEl);

  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : []
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  const [sizePreference, setSizePreference] = useState("medium") //= activeView && activeView.properites ? activeView.properites.size : "medium"
  const [tabPreview, setTabPreview] = useState(true) //= activeView && activeView.properites ? activeView.properites.preview == "tabs" ? true : false : true
  const [previewMaxPreference, setPreviewMaxPreference] = useState("3")
  const [cardTitlePositionPreference, setCardTitlePositionPreference] = useState("bottom")

  const [listCard, setListCard] = useState(false) //= activeView && activeView.properites ? activeView.properites.listCard : false
  const [viewType, setViewType] = useState("Grid") //= activeView ? activeView.viewType : "Grid"
  const [showLastUpdated, setShowLastUpdated] = useState(false) //= activeView ? activeView.viewType : "Grid"
  const [showCreatedOn, setShowCreatedOn] = useState(false)
  const [showTabCount, setShowTabCount] = useState(true) //= activeView ? activeView.viewType : "Grid"

  const innerTabLayouts = [
    {
      name: "Grid",
      value: "Grid"
    },
    {
      name: "List",
      value: "List"
    },
  ]
  const [tabsLayout, setTabsLayout] = useState("Grid")





  //set default view properties
  useEffect(() => {

    if(isButtonMounted){
      if(globalState.activeView && globalState.activeView.properties){
        //console.log('Active view exists - Updating view propeties')
        //SIZE
        if(globalState.activeView.properties.hasOwnProperty('size')){
          setSizePreference(globalState.activeView.properties.size)
          var findSizeIndex = sizeOptions.findIndex(o => o.value === globalState.activeView.properties.size)
          if(findSizeIndex > -1) setSelectedSize(sizeOptions[findSizeIndex].value)
        } else {
          setSizePreference("medium")
          setSelectedSize(sizeOptions[1].value)
        }
        //PREVIEW MAX
        if(globalState.activeView.properties.hasOwnProperty('previewMax')){
          setPreviewMaxPreference(globalState.activeView.properties.previewMax)
          var findPreviewIndex = previewMaxOptions.findIndex(o => o.value === globalState.activeView.properties.previewMax)
          if(findPreviewIndex > -1) setSelectedPreviewMax(previewMaxOptions[findPreviewIndex].value)
        } else {
          setPreviewMaxPreference("3")
          setSelectedPreviewMax(previewMaxOptions[2].value)
        }
        //CARD TITLE POSITION
        if(globalState.activeView.properties.hasOwnProperty('cardTitlePosition')){
          setCardTitlePositionPreference(globalState.activeView.properties.cardTitlePosition)
          var findTitleIndex = cardTitlePositionOptions.findIndex(o => o.value === globalState.activeView.properties.cardTitlePosition)
          if(findTitleIndex > -1) setSelectedCardTitlePosition(cardTitlePositionOptions[findTitleIndex].value)
        } else {
          setCardTitlePositionPreference("bottom")
          setSelectedCardTitlePosition(cardTitlePositionOptions[1].value)
        }

        //TAB PREVIEW
        if(globalState.activeView.properties.preview == "tabs"){
          setTabPreview(true)
        } else {
          setTabPreview(false)
        }
        //SHOW TAB COUNT
        if(globalState.activeView.properties.hasOwnProperty('showTabCount')){
          setShowTabCount(globalState.activeView.properties.showTabCount)
        } else {
          setShowTabCount(true)
        }
        //SHOW LAST UPDATED
        if(globalState.activeView.properties.hasOwnProperty('showLastUpdated')){
          setShowLastUpdated(globalState.activeView.properties.showLastUpdated)
        } else {
          setShowLastUpdated(false)
        }
        //SHOW CREATED ON
        if(globalState.activeView.properties.hasOwnProperty('showCreatedOn')){
          setShowCreatedOn(globalState.activeView.properties.showCreatedOn)
        } else {
          setShowCreatedOn(false)
        }
        //LIST CARD
        setListCard(globalState.activeView.properties.listCard)
        //VIEW TYPE
        if(globalState.activeView.viewType){
          setViewType(globalState.activeView.viewType)
        } else {
          setViewType("Grid")
        }
      } else {
        //no active view reset to defauly properties
        //console.log("No active view - setting to default")
        setSizePreference("medium")
        setSelectedSize(sizeOptions[1].value)
        setTabPreview(true)
        setShowTabCount(true)
        setShowLastUpdated(true)
        setShowCreatedOn(false)
        setViewType("Grid")
      }

      //Full view
      if(globalState.activeView && globalState.activeView.fullViewProperties && globalState.activeView.fullViewProperties.tabsLayout){
        setTabsLayout(globalState.activeView.fullViewProperties.tabsLayout)
      } else {
        setTabsLayout("Grid")
      }
    }

    return () => { isButtonMounted = false }


  }, [globalState.activeView])

  //set CUSTOM view properties
  const [customProperties, setCustomProperties] = useState({})
  const handleChangeCustomProperty = (event, propertyId) => {
    event.preventDefault()
    event.stopPropagation()
    let value = customProperties[propertyId] != undefined ? !customProperties[propertyId] : false
    setCustomProperties({ ...customProperties, [propertyId]: value });


    var customProps = globalState.activeView.customProperties || {}
    customProps[propertyId] = value


    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate
    //let newActiveWorkspace = {...globalState.activeWorkspace, collectionProperties: { ...customProperties, [propertyId]: value }}
    let newActiveView = {...globalState.activeView, customProperties: { ...customProperties, [propertyId]: value }}

    //{views: {[viewIndex]: {customProperties: {$set: { ...customProperties, [propertyId]: value } }}}}
    const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex(w => w._id === globalState.activeWorkspace._id) : -1
    const viewIndex = workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views ? workspacesData.workspaces[workspaceIndex].views.findIndex(v => v._id == globalState.activeView._id) : -1
    const updatedWorkspaces = update(workspacesData, {
      workspaces: {
        [workspaceIndex]: {views: {[viewIndex]: {$set: newActiveView}}}
      }
    })

    if(viewIndex > -1){
      //globalActions.saveActiveWorkspace(newActiveWorkspace)
      globalActions.setActiveView(newActiveView)
      mutate('/workspaces', updatedWorkspaces, false)
    }


    axios
      .post("/views/updateproperties", {viewId: activeView._id, customProperties: customProps})
      .then(res => {
        //console.log(res)
        globalActions.setActiveView(res.data.view)

        mutate("/workspaces")
      })
      .catch(err => {
        mutate("/workspaces")
        //console.log("Could not update workspace name")
        //console.log(err)

        setCustomProperties({ ...customProperties, [propertyId]: !value });
        // setNewNameReq({
        //   loading: null,
        //   isError: index
        // })
        // setEditWorkspaceName(false);
        // setNewWorkspaceName("")
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: true
        // })
      });

  };


  useEffect(() => {

    if(isButtonMounted){
      if(globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0){

        if(globalState.activeView && globalState.activeView.customProperties){
          //the view has values set
          let initState = {}
          globalState.activeWorkspace.collectionProperties.forEach((property, index) => {
            initState[property._id] = true

            if(globalState.activeView.customProperties.hasOwnProperty(property._id)){
              initState[property._id] = globalState.activeView.customProperties[property._id]
            } else {
              initState[property._id] = true //defaults to true to show the custom properties
            }

            if(index == globalState.activeWorkspace.collectionProperties.length -1){
              setCustomProperties(initState)
            }
          })
        } else {
          //the view doesn't have any values set yet
          //console.log("there is no view")
          let initState = {}
          globalState.activeWorkspace.collectionProperties.forEach((property, index) => {
            initState[property._id] = true

            if(index == globalState.activeWorkspace.collectionProperties.length -1){
              setCustomProperties(initState)
            }
          })
        }
      }
    }

    return () => { isButtonMounted = false }


  }, [globalState.activeView])



  const currentView = props.currentView

  //HELP
  const helpIcon = null

  const updateViewProperties = (property, value) => {

    var newProperties = globalState.activeView.properties //INIT current properties

    if(property === "preview"){
      if(value === true){
        newProperties.preview = "tabs"
        setTabPreview(true)
      } else {
        newProperties.preview = "none"
        setTabPreview(false)
      }
    }
    if(property === "showLastUpdated"){
      newProperties.showLastUpdated = value
      setShowLastUpdated(value)
    }
    if(property === "showCreatedOn"){
      newProperties.showCreatedOn = value
      setShowCreatedOn(value)
    }
    if(property === "showTabCount"){
      newProperties.showTabCount = value
      setShowTabCount(value)
    }
    if(property === "size"){
      newProperties.size = value
      setSizePreference(value)
    }
    if(property === "previewMax"){
      newProperties.previewMax = value
      setPreviewMaxPreference(value)
    }
    if(property === "cardTitlePosition"){
      newProperties.cardTitlePosition = value
      setCardTitlePositionPreference(value)
    }

    //Edit trhe state on ENTER (or confirmed click) THEN, call the api and validate
    let newView = {...globalState.activeView, properties: newProperties}
    const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex(w => w._id === globalState.activeWorkspace._id) : -1
    const viewIndex = workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views ? workspacesData.workspaces[workspaceIndex].views.findIndex(v => v._id == globalState.activeView._id) : -1
    const updatedWorkspaces = update(workspacesData, {workspaces: {[workspaceIndex]: {views: {[viewIndex]: {properties: {$set: newProperties}}} }} })

    if(viewIndex > -1){
      globalActions.setActiveView(newView)
      mutate('/workspaces', updatedWorkspaces, false)
    }


    axios
      .post("/views/updateproperties", {viewId: activeView._id, newProperties: newProperties})
      .then(res => {
        //console.log(res)
        globalActions.setActiveView(res.data.view)
        mutate("/workspaces")

      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        //error, reset the value
        if(property === "preview"){
          setTabPreview(!value)
        }
        if(property === "showLastUpdated"){
          setShowLastUpdated(!value)
        }
        if(property === "showCreatedOn"){
          setShowCreatedOn(!value)
        }
        if(property === "showTabCount"){
          setShowTabCount(!value)
        }
        if(property === "size"){
          setSizePreference(!value)
        }

      });
  }

  const updateInnerTabsLayout = (value) => {
    //console.log(value)
    globalActions.setActiveView(update(globalState.activeView, {fullViewProperties: {tabsLayout: {$set: value} }}))

    axios
      .post("/views/tabslayout", {viewId: activeView._id, tabsLayout: value})
      .then(res => {
        //console.log(res)
        mutate("/workspaces")

      })
      .catch(err => {
        mutate("/workspaces")
      })
  }

  //SELECTING SIZE
  const sizeOptions = [
    {
      name: "Small",
      value: "small"
    },
    {
      name: "Medium",
      value: "medium"
    },
    {
      name: "Large",
      value: "large"
    },
  ]
  const [selectedSize, setSelectedSize] = useState(sizeOptions[0])
  //initialize value
  useEffect(() => {
    var findIndex = sizeOptions.findIndex(o => o.value === sizePreference)
    if(findIndex > -1){
      setSelectedSize(sizeOptions[findIndex].value)
    } else{
      setSelectedSize(sizeOptions[1].value)
    }

  }, [sizePreference])
  const handleSelectSize = (event) => {
    updateViewProperties("size", event.target.value.value)
    setSizePreference(event.target.value.value)
    setSelectedSize(event.target.value.value);
  };

   //SELECTING PREVIEW MAX
   const previewMaxOptions = [
    {
      name: "1",
      value: "1"
    },
    {
      name: "2",
      value: "2"
    },
    {
      name: "3",
      value: "3"
    },
    {
      name: "4",
      value: "4"
    },
    {
      name: "5",
      value: "5"
    },
    {
      name: "6",
      value: "6"
    },
    {
      name: "7",
      value: "7"
    },
    {
      name: "8",
      value: "8"
    },
    {
      name: "9",
      value: "9"
    },
    {
      name: "Max",
      value: "max"
    },
  ]
  const [selectedPreviewMax, setSelectedPreviewMax] = useState(previewMaxOptions[2].value)
  //initialize value
  useEffect(() => {
    var findIndex = previewMaxOptions.findIndex(o => o.value === previewMaxPreference)
    if(findIndex > -1){
      setSelectedPreviewMax(previewMaxOptions[findIndex].value)
    } else{
      setSelectedPreviewMax(previewMaxOptions[2].value)
    }

  }, [previewMaxPreference])

  const handleSelectPreviewMax = (event) => {
    updateViewProperties("previewMax", event.target.value.value)
    setPreviewMaxPreference(event.target.value.value)
    setSelectedPreviewMax(event.target.value.value);
  };

   //SELECTING CARD TITLE POSITION
   const cardTitlePositionOptions = [
    {
      name: "Top",
      value: "top"
    },
    {
      name: "Bottom",
      value: "bottom"
    },
  ]
  const [selectedCardTitlePosition, setSelectedCardTitlePosition] = useState(sizeOptions[1].value)
  //initialize value
  useEffect(() => {
    var findIndex = cardTitlePositionOptions.findIndex(o => o.value === cardTitlePositionPreference)
    if(findIndex > -1){
      setSelectedCardTitlePosition(cardTitlePositionOptions[findIndex].value)
    } else{
      setSelectedCardTitlePosition(cardTitlePositionOptions[1].value)
    }

  }, [selectedCardTitlePosition])

  const handleSelectCardTitlePosition = (event) => {
    updateViewProperties("cardTitlePosition", event.target.value.value)
    setCardTitlePositionPreference(event.target.value.value)
    setSelectedCardTitlePosition(event.target.value.value);
  };

  //SELECTING Inner tab view
  const handleSelectInnerTabLayout = (event) => {
    setTabsLayout(event.target.value.value)
    updateInnerTabsLayout(event.target.value.value)

  };

  //EDITING DEFAULT VIEW
  var isEditDefault = !activeView || !views || views && views.length < 1 ? true : false

  const EditDefaultView = (event, property, value) => {
    //console.log({property, value})
    event.preventDefault()
    event.stopPropagation()

    let defaultProperties = {
      size: "medium",
      preview: "tabs",
      previewMax: "3",
      cardTitlePosition: "bottom",
      showLastUpdated: false,
      showCreatedOn: false,
      showTabCount: true,
    }
    //console.log({property, value})
    let customProps = customProperties
    customProps[property._id] = !value

    if(defaultProperties.hasOwnProperty(property)){
      if(property === "preview"){
        if(value === true){
          defaultProperties["preview"] = "tabs"
        } else {
          defaultProperties["preview"] = "none"
        }
      } else if(property === "size"){
        defaultProperties[property] = value.value
      } else if(property === "tabsLayout"){
        defaultProperties.fullViewProperties[property] = value.value
      } else if(property === "previewMax"){
        defaultProperties[property] = value.value
      } else if(property === "cardTitlePosition"){
        defaultProperties[property] = value.value
      }
      else {
        defaultProperties[property] = value
      }
    }
    //console.log({defaultProperties})
    //console.log({customProps})

    const viewObj = {
      name: "Grid View",
      viewType: "Grid",
      properties: defaultProperties,
      customProperties: customProps,
      sort: [],
      filter: []
    }

    props.EditDefaultView(viewObj) //handleClose
  }



  //EDITING A CUSTOM PROPERTY
  const [propertyMenuAnchor, setPropertyMenuAnchor] = useState(false)
  const [editProperty, setEditProperty] = useState(null)
  const [editPropertyReq, setEditPropertyReq] = useState({
    property: null,
    isLoading: false,
    isError: false,
  })
  const handleOpenPropertyMenu = (event, property) => {
    // console.log(event)
    // console.log(event.target)
    // console.log(event.target.className)
    // console.log("HELLLLOOOO —————————————")
    if(event && event.target && event.target.className.includes('MuiMenuItem-root')){
      //event.target.contains('li.MuiButtonBase-root')
      setPropertyMenuAnchor(event.currentTarget)
      //console.log(property)
      setEditProperty(property)
    }
  }
  const handleClosePropertyMenu = () => {
    setPropertyMenuAnchor(null)
    setEditProperty(null)

    //handleClose()
  };
  const handleStopLoading = (error) => {
    setEditPropertyReq({
      property: null,
      isLoading: false,
      isError: error ? error : false,
    })
  }
  const handleUpdateProperty = (property, name, propertyType) => {
    setEditPropertyReq({
      property: property,
      isLoading: true,
      isError: false,
    })

    let updatedProperty = {}
    if(property.name != name){
      //console.log("property name was updated")
      updatedProperty.name = name
    }
    if(property.type != propertyType){
      //console.log("property type was updated")
      updatedProperty.type = propertyType
    }

    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      updatedProperty: updatedProperty
    }

    //console.log(data)

    axios
      .post("/workspaces/collectionProperties/update", data)
      .then(res => {
        //console.log(res)
        if(res.data && res.data.workspace){
          let curWorkspace = {...globalState.activeWorkspace, collectionProperties: res.data.workspace.collectionProperties}

          //curWorkspace.collectionProperties = res.data.workspace.collectionProperties
          //globalActions.saveActiveWorkspace(res.data.workspace)
          globalActions.saveActiveWorkspace(curWorkspace)
        }
        handleStopLoading()

      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        handleStopLoading(err)
      });

  }

  const [triggerOpenSelect, setTriggerOpenSelect] = useState(null)
  const handleOpenSelectMenu = (id) => {
    setTriggerOpenSelect(id)
  }
  const closeTrigger = () => {
    setTriggerOpenSelect(false)

  }

  const handleClickCustomSwitch = (event, property) => {
    event.preventDefault()
    event.stopPropagation()

    if(isEditDefault) {
      EditDefaultView(event, property, customProperties[property._id])
    } else {
      handleChangeCustomProperty(event, property._id)
    }
  }

  
  return (
      <>
          <PlainButton
              
              disableFocusRipple
              disableRipple
              onClick={openMenu}

              style={{marginRight: 8, marginLeft: 8, textOverflow: "ellipsis", overflow: "hidden"}}
          >
              <Typography
                  noWrap
                  variant="button"
              >
                  Properties
              </Typography>
          </PlainButton>

          <DefaultMenu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handleClose}
              open={open}
              style={{zIndex: 999, maxHeight: 500, minWidth: 350, maxWidth: 400, marginTop: 5}}
              transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
              <Box style={{ minWidth: 350, maxWidth: 350}}>
                  <div
                      style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 6,
                    }}
                  >
                      <Typography
                          color='textSecondary'
                          noWrap
                          style={{ width: 120, display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden"}}
                          variant="subtitle2"
                      >
                          Properties for
                          <Box
                              color="theme.primary"
                              style={{marginLeft: 4, display: 'flex', flexDirection: 'row', alignItems: 'center',}}
                          >
                              {
                              activeView && activeView.viewType === "List" ?
                                  <MenuRoundedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
                              :
                              activeView && activeView.viewType === "Kanban" ?
                                  <ViewKanbanOutlinedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
                              :
                              activeView && activeView.viewType === "Full" ?
                                  <ViewDayRoundedIcon style={{width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
                              :
                                  <GridViewRoundedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
                            }
                          </Box>
                      </Typography>
                      <Typography
                          color='textSecondary'
                          noWrap
                          style={{maxWidth: 'calc(100% - 120px)', display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden",}}
                          variant="subtitle2"
                      >
                          <Box
                              color='theme.primary'
                              style={{textOverflow: "ellipsis", overflow: "hidden"}}
                          >
                              {activeView ? activeView.name : "Default view"}
                          </Box>
                      </Typography>
                      {
              helpIcon && helpIcon.url ?
                  <IconButton
                      aria-label="delete"
                      href={helpIcon.url}
                      size="small"
                      target="_blank"
                  >
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
              :
              null
            }
                  </div>
                  <Divider style={{marginTop: 4, marginBottom: 6}} />
                  {
                    activeView && activeView.viewType == "Grid" || activeView && activeView.viewType == "Kanban" || isEditDefault ?
                        <>
                            <Typography
                                sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5}}
                                variant="body2"
                            >
                                CARD PROPERTIES
                            </Typography>
                            <MenuItem
                                button
                                onClick={(e) => isEditDefault ? EditDefaultView(e, "preview", !tabPreview) : updateViewProperties("preview", !tabPreview)}
                            >
                                <Typography
                                    noWrap
                                    style={{textOverflow: "ellipsis", overflow: "hidden",}}
                                    variant='body2'
                                >
                                    Tab preview
                                </Typography>
                                <ListItemSecondaryAction>
                                    <Switch
                                        checked={tabPreview}
                                        color="primary"
                                        edge="end"
                                        onChange={(e) => isEditDefault ? EditDefaultView(e, "preview", !tabPreview) : updateViewProperties("preview", !tabPreview)}
                                        size="small"
                                    />
                                </ListItemSecondaryAction>
                            </MenuItem>
                        
                            {tabPreview && (
                                <>
                                    <MenuItem >
                                        <Typography
                                            noWrap
                                            style={{textOverflow: "ellipsis", overflow: "hidden",}}
                                            variant='body2'
                                        >
                                            Max # of tabs shown
                                        </Typography>
                                        <ListItemSecondaryAction>
                                            <CustomSelect
                                                InListItem
                                                closeTrigger={closeTrigger}
                                                handleChange={(e) => isEditDefault ? EditDefaultView(e, "previewMax", e.target.value) : handleSelectPreviewMax(e)}
                                                options={previewMaxOptions}
                                                selected={selectedPreviewMax}
                                                triggerOpen={triggerOpenSelect === 0}
                                            />
                                        </ListItemSecondaryAction>
                                    </MenuItem>
                                    <MenuItem >
                                        <Typography
                                            noWrap
                                            style={{textOverflow: "ellipsis", overflow: "hidden",}}
                                            variant='body2'
                                        >
                                            Card title position
                                        </Typography>
                                        <ListItemSecondaryAction>
                                            <CustomSelect
                                                InListItem
                                                closeTrigger={closeTrigger}
                                                handleChange={(e) => isEditDefault ? EditDefaultView(e, "cardTitlePosition", e.target.value) : handleSelectCardTitlePosition(e)}
                                                options={cardTitlePositionOptions}
                                                selected={selectedCardTitlePosition}
                                                triggerOpen={triggerOpenSelect === 1}
                                            />
                                        </ListItemSecondaryAction>
                                    </MenuItem>
                                </>
                          )}
                            <MenuItem >
                                <Typography
                                    noWrap
                                    style={{textOverflow: "ellipsis", overflow: "hidden",}}
                                    variant='body2'
                                >
                                    Card size
                                </Typography>
                                <ListItemSecondaryAction>
                                    <CustomSelect
                                        InListItem
                                        closeTrigger={closeTrigger}
                                        handleChange={(e) => isEditDefault ? EditDefaultView(e, "size", e.target.value) : handleSelectSize(e)}
                                        options={sizeOptions}
                                        selected={selectedSize}
                                        triggerOpen={triggerOpenSelect === 2}
                                    />
                                </ListItemSecondaryAction>
                            </MenuItem>
                            <Divider />
                        </>
            :
            null
          }
                  {
            activeView && activeView.viewType == "Full" ?
                <>
                    <Typography
                        sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5}}
                        variant="body2"
                    >
                        CARD PROPERTIES
                    </Typography>
                    <MenuItem >
                        <Typography
                            noWrap
                            style={{textOverflow: "ellipsis", overflow: "hidden",}}
                            variant='body2'
                        >
                            Card title position
                        </Typography>
                        <ListItemSecondaryAction>
                            <CustomSelect
                                InListItem
                                closeTrigger={closeTrigger}
                                handleChange={(e) => isEditDefault ? EditDefaultView(e, "cardTitlePosition", e.target.value) : handleSelectCardTitlePosition(e)}
                                options={cardTitlePositionOptions}
                                selected={selectedCardTitlePosition}
                                triggerOpen={triggerOpenSelect === 1}
                            />
                        </ListItemSecondaryAction>
                    </MenuItem>
                    <MenuItem
                        button
                        onClick={() => handleOpenSelectMenu(3)}
                    >
                        <Typography
                            noWrap
                            style={{textOverflow: "ellipsis", overflow: "hidden",}}
                            variant='body2'
                        >
                            Inner Tab layout
                        </Typography>
                        <ListItemSecondaryAction>
                            <CustomSelect
                                InListItem
                                closeTrigger={closeTrigger}
                                handleChange={(e) => isEditDefault ? EditDefaultView(e, "tabsLayout", e.target.value) : handleSelectInnerTabLayout(e)}
                                options={innerTabLayouts}
                                selected={tabsLayout}
                                triggerOpen={triggerOpenSelect === 3}
                            />
                        </ListItemSecondaryAction>
                    </MenuItem>
                    <Divider />
                </>
            :
            null
          }
                  <Typography
                      sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5}}
                      variant="body2"
                  >
                      DEFAULT PROPERTIES
                  </Typography>
                  <MenuItem
                      button
                      onClick={(e) => isEditDefault ? EditDefaultView(e, "showCreatedOn", !showCreatedOn) : updateViewProperties("showCreatedOn", !showCreatedOn)}
                  >
                      <Typography
                          noWrap
                          style={{textOverflow: "ellipsis", overflow: "hidden",}}
                          variant='body2'
                      >
                          Created on
                      </Typography>
                      <ListItemSecondaryAction>
                          <Switch
                              checked={showCreatedOn}
                              color="primary"
                              edge="end"
                              onChange={(e) => isEditDefault ? EditDefaultView(e, "showCreatedOn", !showCreatedOn) : updateViewProperties("showCreatedOn", !showCreatedOn)}
                              size="small"
                          />
                      </ListItemSecondaryAction>
                  </MenuItem>
                  <MenuItem
                      button
                      onClick={(e) => isEditDefault ? EditDefaultView(e, "showLastUpdated", !showLastUpdated) : updateViewProperties("showLastUpdated", !showLastUpdated)}
                  >
                      <Typography
                          noWrap
                          style={{textOverflow: "ellipsis", overflow: "hidden",}}
                          variant='body2'
                      >
                          Last updated
                      </Typography>
                      <ListItemSecondaryAction>
                          <Switch
                              checked={showLastUpdated}
                              color="primary"
                              edge="end"
                              onChange={(e) => isEditDefault ? EditDefaultView(e, "showLastUpdated", !showLastUpdated) : updateViewProperties("showLastUpdated", !showLastUpdated)}
                              size="small"
                          />
                      </ListItemSecondaryAction>
                  </MenuItem>
                  <MenuItem
                      button
                      onClick={(e) => isEditDefault ? EditDefaultView(e, "showTabCount", !showTabCount) : updateViewProperties("showTabCount", !showTabCount)}
                  >
                      <Typography
                          noWrap
                          style={{textOverflow: "ellipsis", overflow: "hidden",}}
                          variant='body2'
                      >
                          Tab count
                      </Typography>
                      <ListItemSecondaryAction>
                          <Switch
                              checked={showTabCount}
                              color="primary"
                              edge="end"
                              onChange={(e) => isEditDefault ? EditDefaultView(e, "showTabCount", !showTabCount) : updateViewProperties("showTabCount", !showTabCount)}
                              size="small"
                          />
                      </ListItemSecondaryAction>
                  </MenuItem>

                  {
              globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0 ?
                  <>
                      <Divider />
                      <Typography
                          sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5}}
                          variant="body2"
                      >
                          CUSTOM PROPERTIES
                      </Typography>
                      {
                            globalState.activeWorkspace.collectionProperties.map((property, index) => (
                                <div key={index}>
                                    <MenuItem
                                        button
                                        onClick={(e) => handleOpenPropertyMenu(e, property)}

                                        property={property}
                                    >
                                        {
                                    property.type == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                                        size="small"
                                        style={{width: 18, color: '#6E6E6E', marginLeft: 4, marginRight: 6}}
                                                                      />
                                    :
                                    property.type == "multiSelect" ? <ListRoundedIcon
                                        size="small"
                                        style={{width: 18, color: '#6E6E6E', marginLeft: 4, marginRight: 6}}
                                                                     />
                                    :
                                    null
                                  }
                                        <Typography
                                            noWrap
                                            style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: '75%'}}
                                            variant='body2'
                                        >
                                            {property.name}
                                        </Typography>
                                        <ListItemSecondaryAction>
                                            <Switch
                                                checked={customProperties[property._id]}
                                                color="primary"
                                                edge="end"
                                                onChange={(e) => handleClickCustomSwitch(e, property)}
                                                size="small"
                                            />
                                        </ListItemSecondaryAction>
                                    </MenuItem>
                                </div>
                            ))
                          }
                      <PropertyMenu
                          anchorEl={propertyMenuAnchor}
                          handleClose={handleClosePropertyMenu}
                          handleUpdateProperty={handleUpdateProperty}
                          property={editProperty}
                      />
                  </>
              :
              null
            }
              </Box>
          </DefaultMenu>
      </>
  )
}
