import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link as RouterLink, useParams } from "react-router-dom";
import useGlobal from "../GlobalState/Store/Store.js";

import EmojiComponent from "../Components/Emojis/EmojiComponent";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";

import useWorkspaces from "../Hooks/useWorkspaces.js";
import useCollectionById from "../Hooks/useCollectionById.js";

import { styled } from "@mui/material/styles";
import { Breadcrumbs } from "@mui/material";
const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

// const StyledBreadCrumbs = styled(Breadcrumbs)`
//     .MuiBreadcrumbs-li {
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//     }
//     .MuiBreadcrumbs-ol {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: nowrap;
//     }
//     `;

export default function PageBreadCrumbs(props) {
  const [globalState, globalActions] = useGlobal();
  const pathnames = props.pathnames;
  let params = useParams();

  const { workspacesData } = useWorkspaces(globalState.isAuth);
  // const { collectionsData } = useCollections(
  //   globalState.activeWorkspace._id,
  //   globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null,
  //   { revalidateOnMount: false }
  // );
  const { collectionData } = useCollectionById(
    globalState.isAuth,
    // params && params.workspaceid ? params.workspaceid : null,
    params && params.collectionid ? params.collectionid : null
  ); //{revalidateOnMount: true}
  let isMounted = true;

  //   let workspace = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.find(workspace => workspace._id == pathnames[0]) : null

  //   //let collection = globalState.collections && globalState.collections.length > 0 ? globalState.collections.find(collection => collection.urlId == pathnames[1]) : null
  //   const collection = pathnames && pathnames[1] && collectionData && collectionData.collection ? collectionData.collection
  //   :
  //   collectionsData && collectionsData.collections ? collectionsData.collections.find(collection => collection.urlId == pathnames[1]) : null

  const [collection, setCollection] = useState({});
  const [workspace, setWorkspace] = useState({});

  useEffect(() => {
    const initWorkspace = (collection) => {
      // console.log({ collection });
      if (collection && workspacesData && workspacesData.workspaces) {
        let workspaceid = workspacesData.workspaces.findIndex((workspace) => workspace._id == collection.workspace);
        // let workspaceid = workspacesData.workspaces.findIndex((workspace) => workspace._id == pathnames[0]);
        if (workspaceid > -1 && isMounted) {
          setWorkspace(workspacesData.workspaces[workspaceid]);
        }
      }
    };

    if (collectionData && collectionData.collection) {
      if (isMounted) {
        // console.log(collectionData.collection);
        initWorkspace(collectionData.collection);
        setCollection(collectionData.collection);
      }
    }
    // else if (collectionsData && collectionsData.collections) {
    //   let collectionid = collectionsData.collections.findIndex((collection) => collection.urlId == pathnames[1]);
    //   if (collectionid > -1 && isMounted) {
    //     console.log(collectionsData.collections[collectionid]);
    //     initWorkspace(collectionsData.collections[collectionid]);
    //     setCollection(collectionsData.collections[collectionid]);
    //   }
    // }

    return () => {
      //cleanup
      isMounted = false;
    };
  }, [workspacesData, collectionData]);

  let crumbs = [workspace, collection];

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<div style={{ color: "#C4C4C4" }}> / </div>} style={{ marginRight: 12 }}>
      {crumbs.map((value, index) => {
        const first = index == 0 ? true : false;
        const last = index === pathnames.length - 1;
        // const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const backToWorkspace = `/${workspace._id}`;
        return first && workspace && workspace !== null && workspace.name ? (
          <PlainButton
            component={RouterLink}
            key={index}
            style={{
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 4,
              paddingRight: 6,
              minWidth: 0,
              margin: 0,
              width: "100%",
            }}
            to={backToWorkspace}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                backgroundColor: "rgba(110, 110, 110, 0)",
                borderRadius: "2px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "5px",

                //border: '0.5px solid'
              }}
            >
              {
                //EMOJI
                workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ? (
                  <EmojiComponent size={18} symbol={workspace.icon.emoji.emoji} />
                ) : //COLOR
                workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ? (
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: workspace.icon.colorIcon.hex,
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <FolderOpenRoundedIcon fontSize="small" />
                )
              }
            </div>
            <Typography
              //color="textPrimary"
              noWrap
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: "#6E6E6E",
              }}
              variant="body2"
            >
              {workspace.name}
            </Typography>
          </PlainButton>
        ) : collection && collection !== null && collection.name ? (
          <Typography color="textPrimary" key={index} noWrap style={{ textOverflow: "ellipsis", overflow: "hidden" }} variant="body2">
            {collection.name}
          </Typography>
        ) : null;
      })}
    </Breadcrumbs>
  );
}
