import React, { useState, useEffect } from "react";

import axios from "axios";
import LinkMenu from "../../Menus/LinkMenu.js";

import useGlobal from "../../../GlobalState/Store/Store.js";

import useHoverGroup from "../../../Utils/useHoverGroup.js";

import { useTheme } from "@mui/material/styles";

import { Typography, IconButton, Grid } from "@mui/material";

import MoreVert from "@mui/icons-material/MoreVert";
import { ReactComponent as PushPin } from "../../../Assets/pushpin.svg";

import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "../../DND/ItemTypes";
import { getEmptyImage } from "react-dnd-html5-backend";

import update from "immutability-helper";
import { mutate } from "swr";

import defaultFavIconLight from "../../../Assets/defaultFaviconLight.svg";
import defaultFavIconDark from "../../../Assets/defaultFaviconDark.svg";
import useDarkMode from "../../../Utils/theme/useDarkMode";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

const TabCard = ({
  id,
  index,
  moveTab,
  tab,
  tabsOrder,
  ResetTabs,

  collection,
  dragActive,
  setDragActive,
  inCollectionDialog,
  inAccordian,
  tabsLayout,

  collectionsData,
  SortTabs,
  params,
  // tooltipDelay,
  // handleTabHovered,
  // tabHovered,
}) => {
  const [globalState, globalActions] = useGlobal();
  const { isDarkMode } = useDarkMode("light");
  const tabsMenuOpen = globalState.tabsMenuOpen;
  const theme = useTheme();

  const [editTabTitle, setEditTabTitle] = useState(false);

  const FaviconURL =
    tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
      ? ""
      : tab.favIconUrl.replace(/^http:\/\//, "https://");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [oldOrder, setOldOrder] = useState(tabsOrder);

  const [hoverRef, hover] = useHoverGroup();

  //MAKING URLS PRETTIER
  function extractHostname(url) {
    var hostname;
    if (url && url !== undefined) {
      var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false;

      if (isUrl) {
        //---- OLD FULL REMOVAL METHOD ----
        //find & remove protocol (http, ftp, etc.) and get hostname
        // if (url.indexOf("//") > -1) {
        //   hostname = url.split('/')[2];
        // }
        // else {
        //   //console.log("found / ", hostname)
        //   hostname = url.split('/')[0];
        // }
        // //removes a remaing http or www.
        // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
        //---------------------//
        if (url.indexOf("https://") > -1) {
          hostname = url.split("https://")[1];
        } else if (url.indexOf("http://") > -1) {
          hostname = url.split("http://")[1];
        } else {
          hostname = url;
        }
        // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
        if (hostname) {
          hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""); //.split('/')[0];
        }
      } else {
        return url;
      }
    }
    return hostname;
  }

  const tabURL = tab && tab.url ? extractHostname(tab.url) : "no url";

  //on load set old collections for comparison (and every time actual tabs order changes)
  async function resetOldOrder() {
    const pinnedAllowed = await pinReorderAllowed(tabsOrder);
    // var old = []
    // tabsOrder.map(t => {
    //   old.push(t)
    // })
    //only update the "old order" if the change was allowed
    if (pinnedAllowed) {
      //console.log("updating old order")
      //console.log(tabsOrder)
      setOldOrder(tabsOrder);
    }
  }

  useEffect(() => {
    let isTabMounted = true;
    if (isTabMounted) {
      resetOldOrder();
    }
    return () => {
      isTabMounted = false;
    };
  }, []);

  function arraysEqual(a, b) {
    //console.log(a.map(t => t.title))
    //console.log(b.map(t => t.title))
    // if (a === b) return true;
    // if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    var idsEqual;
    for (var i = 0; i < a.length; ++i) {
      //console.log(a[i]._id)
      //console.log(b[i]._id)
      if (a[i]._id !== b[i]._id) {
        //console.log(a[i].url + " is not " + b[i].url)
        idsEqual = false;
        break;
      } else if (a[i]._id === b[i]._id && i == a.length - 1) {
        //last item
        //console.log("last item is equal")
        idsEqual = true;
      } else {
        idsEqual = false;
      }
    }
    if (idsEqual) {
      //console.log("arrays are the same")
    } else {
      //console.log("arrays are different")
    }
    return idsEqual;
  }

  async function pinReorderAllowed(array) {
    //console.log("Checking pin reorder")
    //console.log(array)

    const unpinned = (tab) => tab.pinned === false;
    const firstUnpinnedIndex = array.findIndex(unpinned); //if = -1 it means there are no UNpinned tabs

    const pinnedTabs = array.filter((tab) => tab.pinned === true);
    const lastPinnedRef = pinnedTabs && pinnedTabs.length ? pinnedTabs[pinnedTabs.length - 1] : null;
    const lastPinnedIndex = lastPinnedRef ? array.findIndex((tab) => tab._id === lastPinnedRef._id) : null;

    //console.log("Last pinned Index: " + lastPinnedIndex)
    //console.log("First unpinned Index: " + firstUnpinnedIndex)

    if (!lastPinnedIndex) {
      //console.log("there are no pinned tabs.. proceed")
      return true;
    } else if (firstUnpinnedIndex !== -1 && lastPinnedIndex > firstUnpinnedIndex) {
      return false;
    } else {
      return true;
    }
  }

  async function MoveTabs(dragIndex, properIndex) {
    //console.log({dragIndex, properIndex})
    //console.log("pinned allow: " + pinnedAllowed)
    const curOrder = [...tabsOrder];
    const dragTab = tabsOrder[dragIndex];
    let updatedTabOrder = update(tabsOrder, {
      $splice: [
        [dragIndex, 1],
        [properIndex, 0, dragTab],
      ],
    });

    const pinnedAllowed = await pinReorderAllowed(updatedTabOrder);

    //update local state
    moveTab(dragIndex, properIndex);

    var newOrder = updatedTabOrder.map((t) => {
      return { title: t.title, _id: t._id };
    });

    //console.log(updatedTabOrder)

    // const ArraysEqual =
    // console.log(ArraysEqual)
    if (!pinnedAllowed) {
      //trying to move pinned tab past other tabs - forbidden
      //console.log("forbidden tab reoder - pinned tabs")
      //globalActions.setGlobalSnackbarOpen(true)
      globalActions.setGlobalSnackbarState({
        open: true,
        status: "error",
        message: "🛑 Pinned tabs must stay at the front of your collection ",
        closeTime: 4500,
      });
      //console.log("Should reset order to:")
      //console.log(oldOrder)
      ResetTabs(curOrder);
    } else if (arraysEqual(newOrder, curOrder)) {
      //old method, buggy -> arraysEqual(newOrder, oldOrder)
      //console.log("no change in order")
      ResetTabs(curOrder);
      return;
    } else {
      //console.log({dragIndex, properIndex})
      //console.log(updatedTabOrder.map(t => t.url.substring(0, 20)))
      const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;
      if (collectionIndex > -1) {
        const newData = update(collectionsData, {
          collections: {
            [collectionIndex]: { tabs: { $set: updatedTabOrder } },
          },
        });

        mutate(
          [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
          newData,
          false
        );
      }

      if (collection && collection._collectionId) {
        mutate(
          [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
          async (collectionData) => {
            const singleCollectionIndex = collectionData && collectionData.collection ? collectionData.collection._id == collection._id : -1;

            if (singleCollectionIndex) {
              let updatedCollection = update(collectionData.collection, {
                tabs: { $set: updatedTabOrder },
              });
              return { ...collectionData, collection: updatedCollection };
            } else {
              return collectionData;
            }
          },
          false
        );
      }

      // send a request to the API to update the source
      try {
        await axios.post("/collections/reordertabs", { collectionId: collection._id, newTabOrder: newOrder });
        resetOldOrder();
      } catch (error) {
        // Handle an error while updating the user here
      }
      // trigger a revalidation (refetch) to make sure our local data is correct
      mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
      if (collection && collection._collectionId) mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
    }
  }

  const widthRef = React.useRef(null);
  const [dragMousePosition, setdragMousePosition] = useState(null);

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.TABCARD, ItemTypes.CURTAB],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isReadytoDrop: monitor.isReadytoDrop,
    }),
    hover(item, monitor) {
      // isHovered: monitor.isOver()

      //console.log(item)

      if (!widthRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = widthRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // const right = dragIndex < hoverIndex && hoverClientX > hoverMiddleX
      // const left = dragIndex > hoverIndex && hoverClientX < hoverMiddleX
      //
      const down = dragIndex < hoverIndex; //&& hoverClientY < hoverMiddleY
      const up = dragIndex > hoverIndex; //&& hoverClientY > hoverMiddleY

      const right = hoverClientX > hoverMiddleX;
      const left = hoverClientX < hoverMiddleX;
      const below = hoverClientY > hoverMiddleY;
      const above = hoverClientY < hoverMiddleY;

      // Time to actually perform the action
      if (item.type === "tabcard") {
        if (inAccordian && item && item.collectionId && item.collectionId !== collection._id) return;
        let properIndex =
          inAccordian && tabsLayout && tabsLayout == "List"
            ? up && hoverIndex + 1 != dragIndex
              ? hoverIndex + 1
              : down && hoverIndex - 1 != dragIndex
                ? hoverIndex - 1
                : hoverIndex
            : right && hoverClientX < hoverMiddleX
              ? hoverIndex - 1
              : left && hoverClientX > hoverMiddleX
                ? hoverIndex + 1
                : hoverIndex;

        if (
          !dragMousePosition ||
          (!!dragMousePosition && dragMousePosition.left != left) ||
          (!!dragMousePosition && dragMousePosition.right != right) ||
          (!!dragMousePosition && dragMousePosition.below != below) ||
          (!!dragMousePosition && dragMousePosition.above != above)
        ) {
          //only update preview line when it has actually changed
          setdragMousePosition({
            left: left,
            right: right,
            above: above,
            below: below,
            insertIndex: properIndex,
          });
        }

        //moveTab(dragIndex, hoverIndex)
      } else {
        setdragMousePosition(null);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //item.index = hoverIndex
    },

    drop(item, monitor) {
      setdragMousePosition(null);
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;
      // Determine rectangle on screen
      const hoverBoundingRect = widthRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // const right = dragIndex < hoverIndex && hoverClientX > hoverMiddleX
      // const left = dragIndex > hoverIndex && hoverClientX < hoverMiddleX
      //
      const right = dragIndex < hoverIndex; //&& hoverClientX > hoverMiddleX
      const left = dragIndex > hoverIndex; //&& hoverClientX < hoverMiddleX
      const down = dragIndex >= hoverIndex; //&& hoverClientY < hoverMiddleY
      const up = dragIndex <= hoverIndex; //&& hoverClientY > hoverMiddleY

      let properIndex =
        inAccordian && tabsLayout && tabsLayout == "List"
          ? up && hoverClientY < hoverMiddleY && hoverIndex != 0
            ? hoverIndex - 1
            : down && hoverClientY > hoverMiddleY
              ? hoverIndex + 1
              : hoverIndex
          : right && hoverClientX < hoverMiddleX
            ? hoverIndex - 1
            : left && hoverClientX > hoverMiddleX
              ? hoverIndex + 1
              : hoverIndex;

      //console.log(isAnimatingY)
      //console.log(isAnimatingX)
      MoveTabs(dragIndex, properIndex);
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.TABCARD,
      id: id,
      index: index,
      collectionId: collection ? collection._id : null,
      tab: tab,
      tabWidthRef: widthRef,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin() {
      setDragActive(id);
    },
    canDrag() {
      if (editTabTitle) {
        return false;
      } else {
        return true;
      }
    },
    end() {
      //console.log("END")
      //MoveWorkspace(targetIndex, workspace, )
      //handleListItemClick(targetIndex, workspace)
      setDragActive(false);
    },
    // canDrag(props) {
    //   return !editTitle
    // },
  });

  useEffect(() => {
    let isTabMounted = true;
    if (isTabMounted) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
    return () => {
      //cleanup
      preview(null);
      isTabMounted = false;
    };
  }, []);

  drop(widthRef);

  // useEffect(() => {
  //   drop(widthRef);
  //   return () => {
  //     //cleanup
  //     drop(null);
  //   };
  // }, [drop, widthRef]);

  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [selected, setSelected] = useState(false);
  const [clickPosition, setClickPosition] = useState(initialState);
  const openMenu = (event, trigger) => {
    //console.log("Attempting to open collection menu")
    event.preventDefault();
    event.stopPropagation();

    // console.log({trigger})
    //console.log(event.currentTarget)

    if (trigger && trigger == "rightclick") {
      //console.log("setting click pos", event.clientX, event.clientY)
      setClickPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
    setAnchorEl(event.currentTarget);
    setSelected(true);
  };
  const handleClose = (event, keepSelected) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (keepSelected === true) {
      //setSelected(true)
      //keep selected
    } else {
      setSelected(false);
    }
    setAnchorEl(null);

    setClickPosition(initialState);
  };

  //MOVING TABS
  const [secondClickPosition, setSecondClickPosition] = useState(initialState);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [moveTabsAnchorEl, setMoveTabAnchorEl] = React.useState(null); //the anchor for the workspace select menu
  let moveTabsMenuOpen = Boolean(moveTabsAnchorEl);

  const handleMoveTabs = async (targetCollectionId) => {
    const data = {
      newCollectionId: targetCollectionId,
      oldCollectionId: collection._id,
      tabs: [tab],
    };

    const oldCollectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;
    const newCollectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == targetCollectionId) : -1;

    //const tabIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].tabs.findIndex(t => t._id == tab._id) : -1
    if (oldCollectionIndex > -1) {
      if (newCollectionIndex > -1) {
        const newData = update(collectionsData, {
          collections: {
            [oldCollectionIndex]: { tabs: { $set: collectionsData.collections[oldCollectionIndex].tabs.filter((t) => t._id != tab._id) } },
            [newCollectionIndex]: { tabs: { $push: [tab] } },
          },
        });
        mutate(
          [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
          newData,
          false
        );

        const singleCollection = { collection: newData?.collections[oldCollectionIndex] };

        if (collection && collection._collectionId)
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null], singleCollection, false);
      } else {
        const newData = update(collectionsData, {
          collections: {
            [oldCollectionIndex]: { tabs: { $set: collectionsData.collections[oldCollectionIndex].tabs.filter((t) => t._id != tab._id) } },
          },
        });
        mutate(
          [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
          newData,
          false
        );
        const singleCollection = { collection: newData?.collections[oldCollectionIndex] };

        if (collection && collection._collectionId)
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null], singleCollection, false);
      }
    }

    // send a request to the API to update the source
    try {
      await axios.post("/collections/movetabs", data);
    } catch (error) {
      // Handle an error while updating the user here
    }
    // trigger a revalidation (refetch) to make sure our local data is correct
    mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
    if (collection && collection._collectionId) mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
  };
  const openMoveTabsMenu = () => {
    setMoveTabAnchorEl(anchorEl);
    //handleClose()
    setSecondClickPosition(clickPosition);
  };
  const closeMoveTabsMenu = () => {
    setMoveTabAnchorEl(null);
    handleClose();
    setSecondClickPosition(initialState);
  };
  function DeleteTab() {
    const data = {
      collectionId: collection._id,
      updateType: "remove",
      removeTabs: [
        {
          _id: tab._id,
        },
      ],
    };

    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        handleClose();

        const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;

        if (collectionIndex > -1) {
          let updatedCollections = update(collectionsData.collections, {
            [collectionIndex]: { tabs: { $set: collectionsData.collections[collectionIndex].tabs.filter((t) => t._id != tab._id) } },
          });
          return { ...collectionsData, collections: updatedCollections };
        } else {
          return collectionsData;
        }
      },
      false
    );

    if (collection && collection._collectionId) {
      mutate(
        [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
        async (collectionData) => {
          const collectionIndex = collectionData && collectionData.collection ? collectionData.collection._id == collection._id : -1;

          if (collectionIndex > -1) {
            let updatedCollection = update(collectionData.collection, {
              tabs: { $set: collectionData.collection.tabs.filter((t) => t._id != tab._id) },
            });
            return { ...collectionData, collection: updatedCollection };
          } else {
            return collectionData;
          }
        },
        false
      );
    }

    axios
      .post("/collections/updatetabs", data)
      .then(() => {
        //console.log(res)
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);

        resetOldOrder();
        handleClose();
      })
      .catch(() => {
        //console.log("Could not remove tab")
        //console.log(err)
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);

        handleClose();
      });
  }

  function ChangeTabName(newTabTitle, newURL) {
    const tempURL = newURL && newURL != null && newURL != "" ? newURL : tab.url;
    const data = {
      collectionId: collection._id,
      updateType: "tabTitle",
      tabId: tab._id,
      newTitle: newTabTitle,
    };

    if (tab.title == newTabTitle || newTabTitle == null || newTabTitle == "" || newTabTitle == " ") {
      //console.log("invalid/same tab name")
      setEditTabTitle(false);
    } else {
      //console.log("Change tab name to " + newTabTitle)

      //first optimistically update local state
      mutate(
        [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
        async (collectionsData) => {
          setEditTabTitle(false);

          const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;
          const tabIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].tabs.findIndex((t) => t._id == tab._id) : -1;

          // console.log(collectionIndex, tabIndex, newTabTitle)

          if (tabIndex > -1) {
            let updatedCollections = update(collectionsData.collections, {
              [collectionIndex]: {
                tabs: {
                  [tabIndex]: {
                    title: { $set: newTabTitle },
                    url: { $set: tempURL },
                  },
                },
              },
            });

            // console.log({updatedCollections})
            //console.log(updatedCollections[collectionIndex].tabs[tabIndex])

            return { ...collectionsData, collections: updatedCollections };
          } else {
            return collectionsData;
          }
        },
        false
      );

      if (collection && collection._collectionId) {
        mutate(
          [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
          async (collectionData) => {
            const tabIndex = collectionData.collection.tabs.findIndex((t) => t._id == tab._id);

            if (tabIndex > -1) {
              let updatedCollection = update(collectionData.collection, {
                tabs: {
                  [tabIndex]: {
                    title: { $set: newTabTitle },
                    url: { $set: tempURL },
                  },
                },
              });
              return { ...collectionData, collection: updatedCollection };
            } else {
              return collectionData;
            }
          },
          false
        );
      }

      //thjen call API and validate
      axios
        .post("/collections/updatetabs", data)
        .then(() => {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          if (collection && collection._collectionId)
            mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        })
        .catch(() => {
          //console.log("Could not remove tab")
          //console.log(err)
          setEditTabTitle(false);
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        });
    }
  }
  function ChangeTabURL(newURL, newTabTitle) {
    const tempName = newTabTitle && newTabTitle != null && newTabTitle != "" ? newTabTitle : tab.name;
    const data = {
      collectionId: collection._id,
      updateType: "tabURL",
      tabId: tab._id,
      newURL: newURL,
    };
    if (tab.url == newURL || newURL == null) {
      //console.log("invalid/same tab name")
      setEditTabTitle(false);
    } else {
      //first optimistically update local state
      mutate(
        [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
        async (collectionsData) => {
          setEditTabTitle(false);

          const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;
          const tabIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].tabs.findIndex((t) => t._id == tab._id) : -1;
          if (tabIndex > -1) {
            let updatedCollections = update(collectionsData.collections, {
              [collectionIndex]: {
                tabs: {
                  [tabIndex]: {
                    url: { $set: newURL },
                    title: { $set: tempName },
                  },
                },
              },
            });

            // console.log({updatedCollections})
            // console.log(updatedCollections[collectionIndex].tabs[tabIndex])
            return { ...collectionsData, collections: updatedCollections };
          } else {
            return collectionsData;
          }
        },
        false
      );

      if (collection && collection._collectionId) {
        mutate(
          [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
          async (collectionData) => {
            const tabIndex = collectionData.collection.tabs.findIndex((t) => t._id == tab._id);

            if (tabIndex > -1) {
              let updatedCollection = update(collectionData.collection, {
                tabs: {
                  [tabIndex]: {
                    title: { $set: tempName },
                    url: { $set: newURL },
                  },
                },
              });
              return { ...collectionData, collection: updatedCollection };
            } else {
              return collectionData;
            }
          },
          false
        );
      }

      //thjen call API and validate
      axios
        .post("/collections/updatetabs", data)
        .then(() => {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        })
        .catch(() => {
          //console.log("Could not remove tab")
          //console.log(err)
          setEditTabTitle(false);
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        });
    }
  }

  function OpenTab(e) {
    //console.log(e)

    e.preventDefault();
    e.stopPropagation();
    //console.log(e)
    //var charCode = e.which || e.charCode || e.keyCode || e.nativeEvent.which || e.nativeEvent.charCode || e.nativeEvent.keycode
    //var CMDorCNTLKey = charCode == 224 || charCode == 17 || charCode == 93 || charCode == 91 ? true : false

    let shiftKey = e.shiftKey;
    let metaKey = e.metaKey || e.ctrlKey;

    // console.log(charCode, CMDorCNTLKey, shiftKey)

    if (anchorEl == null) {
      //the tab menu isn't open
      if (globalState.extensionConnection.isConnected) {
        globalState.extensionConnection.port.postMessage({ type: "openTab", tab: tab, shiftKey: shiftKey, metaKey: metaKey });
      } else {
        window.open(tab.url);
      }
    }
    //console.log(tab, globalState.extensionConnection.isConnected)
  }

  //PREVIEW LINES
  const prevCardId = tabsOrder && index > 0 && tabsOrder[index - 1] ? tabsOrder[index - 1]._id : null;
  const nextCardId = tabsOrder && index < tabsOrder.length && tabsOrder[index + 1] ? tabsOrder[index + 1]._id : null;
  const isDraggingPrevCard = !!prevCardId && dragActive == prevCardId;
  const isDraggingNextCard = !!nextCardId && dragActive == nextCardId;

  //&& isOver or dragMousePosition.insertIndex === index
  const showPreviewLineOnLeft = !!dragMousePosition && isOver && !isDragging && !isDraggingPrevCard && dragMousePosition.left;
  const showPreviewLineOnRight = !!dragMousePosition && isOver && !isDragging && !isDraggingNextCard && dragMousePosition.right;
  const showPreviewLineBelow = !!dragMousePosition && isOver && !isDragging && !isDraggingNextCard && dragMousePosition.below;
  const showPreviewLineAbove = !!dragMousePosition && isOver && !isDragging && !isDraggingPrevCard && dragMousePosition.above;

  const handleMiddleClicks = (event) => {
    //console.log(event.button)
    if (event.button === 1) {
      //middle mouse button click
      event.metaKey = true;
      OpenTab(event);
    }
    // else if (event.button === 0 && event.metaKey) {
    //   event.shiftKey = true
    //   OpenTab(event)
    // }
  };

  function mergeRefs(refs) {
    return (value) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      });
    };
  }

  if (inAccordian && tabsLayout && tabsLayout == "List") {
    return (
      <Grid item ref={mergeRefs([hoverRef, widthRef])} style={{ position: "relative" }} xs={12}>
        {showPreviewLineAbove && (
          <div
            style={{ height: 4, marginLeft: 4, backgroundColor: "#3D5AFE", width: "calc(100% - 24px)", position: "absolute", left: 8, top: 0, opacity: 0.5 }}
          />
        )}
        <div
          onClick={(e) => (!editTabTitle && hover && !isDragging && !dragActive ? OpenTab(e) : null)}
          onMouseDown={handleMiddleClicks}
          ref={drag}
          style={{
            zIndex: isDragging ? 1 : 0,
            position: "relative",
            scale: isDragging ? 1.03 : 1,
          }}
        >
          <div
            onContextMenu={(e) => openMenu(e, "rightclick")}
            style={{
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: selected
                ? theme.palette.collection.selected
                : (hover && !isDragging && !dragActive) || isDragging
                  ? theme.palette.collection.hover
                  : theme.palette.background.paper,
              boxShadow: theme.palette.shadows.small,
              borderRadius: 4,
              cursor: isDragging ? "grabbing" : "pointer",
              opacity: isDragging ? 0.9 : 1,
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 6,
              paddingBottom: 6,
              flexGrow: 1,
              width: "100%",
              maxWidth: "100%",
            }}
          >
            {tab.pinned ? (
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "5px",
                  color: "#3D5AFE",
                  backgroundColor: "rgba(61, 90, 254, 0.1)",
                  borderRadius: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "16px",
                  top: "5px",
                }}
              >
                <PushPin style={{ height: "14px", fill: "#3D5AFE" }} />
              </div>
            ) : null}
            {FaviconURL !== "" ? (
              <img
                color={theme.palette.text.secondary}
                fill={theme.palette.text.secondary}
                height={24}
                mode="fit"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  isDarkMode ? (currentTarget.src = defaultFavIconDark) : (currentTarget.src = defaultFavIconLight);
                }}
                src={FaviconURL}
                style={{ minWidth: 24, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
                width={24}
              />
            ) : (
              <LanguageRoundedIcon sx={{ color: "text.secondary", width: 24, height: 24 }} />
            )}
            <div
              style={{
                width: "100%",
                maxWidth: "calc(100% - 38px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-start",
                marginLeft: "10px",
              }}
            >
              <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100%" }}>
                <Typography
                  m={0}
                  noWrap
                  style={{
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    lineHeight: 1.25,
                    fontSize: 14,
                  }}
                  variant="subtitle2"
                >
                  {tab.title}
                </Typography>
                <Typography
                  m={0}
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    lineHeight: 1.25,
                    width: "100%",
                    margin: 0,
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                  }}
                  variant="caption"
                >
                  {tabURL}
                </Typography>
              </div>
            </div>
            {editTabTitle ? null : (hover && !isDragging && !dragActive) || selected ? (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  backgroundColor: selected ? "transparent" : theme.palette.collection.hover,
                  paddingRight: 5,
                  height: "100%",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="settings"
                  onClick={(e) => openMenu(e)}
                  //style={{marginLeft: 4}}
                  size="small"
                  style={{
                    margin: 3,
                    borderRadius: 4,
                    padding: 3,
                  }}
                >
                  <MoreVert fontSize="inherit" />
                </IconButton>
              </div>
            ) : null}
            {anchorEl ? (
              <LinkMenu
                ChangeTabName={ChangeTabName}
                ChangeTabURL={ChangeTabURL}
                DeleteTab={DeleteTab}
                SortTabs={SortTabs}
                activeWorkspace={globalState.activeWorkspace}
                anchor={anchorEl}
                clickPosition={clickPosition}
                collection={collection}
                collectionId={collection._id}
                collectionsData={collectionsData}
                handleClick={openMenu}
                handleClose={handleClose}
                handleCloseMoveTabMenu={closeMoveTabsMenu}
                handleMoveTabs={handleMoveTabs}
                moveTabsAnchorEl={moveTabsAnchorEl}
                moveTabsMenuOpen={moveTabsMenuOpen}
                //for moving tab menu
                openMoveTabsMenu={openMoveTabsMenu}
                params={params}
                resetOldOrder={resetOldOrder}
                secondClickPosition={secondClickPosition}
                setEditTabTitle={setEditTabTitle}
                tab={tab}
              />
            ) : null}
            {/* {
                moveTabsMenuOpen ?
                    <MoveTabMenu
                        activeWorkspace={globalState.activeWorkspace}
                        anchorEl={moveTabsAnchorEl}
                        collectionId={collection._id}
                        handleClose={closeMoveTabsMenu}
                        handleMoveTabs={handleMoveTabs}
                        moveTabsMenuOpen={moveTabsMenuOpen}
                        secondClickPosition={secondClickPosition}
                    />
                :
                null
              } */}
          </div>
        </div>
        {showPreviewLineBelow && (
          <div
            style={{ height: 4, marginLeft: 4, backgroundColor: "#3D5AFE", width: "calc(100% - 24px)", position: "absolute", left: 8, bottom: 0, opacity: 0.5 }}
          />
        )}
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        lg={tabsMenuOpen || inCollectionDialog ? 4 : 3}
        md={tabsMenuOpen || inCollectionDialog ? 6 : 4}
        ref={mergeRefs([hoverRef, widthRef])}
        sm={tabsMenuOpen ? 12 : 6}
        style={{ position: "relative" }}
        xl={inCollectionDialog ? 4 : tabsMenuOpen ? 3 : 2}
        xs={12}
      >
        {showPreviewLineOnLeft && (
          <div
            style={{ height: "calc(100% - 16px)", marginTop: 4, backgroundColor: "#3D5AFE", width: 4, position: "absolute", top: 12, left: 6, opacity: 0.5 }}
          />
        )}
        <div
          onClick={(e) => (!editTabTitle && hover && !isDragging && !dragActive ? OpenTab(e) : null)}
          onMouseDown={handleMiddleClicks}
          ref={drag}
          style={{
            zIndex: isDragging ? 1 : 0,
            position: "relative",
            scale: isDragging ? 1.03 : 1,
          }}
        >
          <div
            onContextMenu={(e) => openMenu(e, "rightclick")}
            style={{
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: selected
                ? theme.palette.collection.selected
                : (hover && !isDragging && !dragActive) || isDragging
                  ? theme.palette.collection.hover
                  : theme.palette.background.paper,
              boxShadow: theme.palette.shadows.small,
              borderRadius: 4,
              cursor: isDragging ? "grabbing" : "pointer",
              opacity: isDragging ? 0.9 : 1,

              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 6,
              paddingBottom: 6,
              flexGrow: 1,
              width: "100%",
              maxWidth: "100%",
            }}
          >
            {tab.pinned ? (
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "5px",
                  color: "#3D5AFE",
                  backgroundColor: "rgba(61, 90, 254, 0.1)",
                  borderRadius: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: "16px",
                  top: "5px",
                }}
              >
                <PushPin style={{ height: "14px", fill: "#3D5AFE" }} />
              </div>
            ) : null}
            {FaviconURL !== "" ? (
              <img
                color={theme.palette.text.secondary}
                fill={theme.palette.text.secondary}
                height={24}
                mode="fit"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  isDarkMode ? (currentTarget.src = defaultFavIconDark) : (currentTarget.src = defaultFavIconLight);
                }}
                src={FaviconURL}
                style={{ minWidth: 24, textColor: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
                width={24}
              />
            ) : (
              <LanguageRoundedIcon sx={{ color: "text.secondary", width: 24, height: 24 }} />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-start",
                marginLeft: "10px",
                maxWidth: "calc(100% - 38px)",
                width: "100%",
              }}
            >
              <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100%" }}>
                <Typography
                  m={0}
                  noWrap
                  style={{
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    color: theme.palette.text.primary,
                    marginBottom: -6,
                    fontSize: 14,
                  }}
                  variant="subtitle2"
                >
                  {tab.title}
                </Typography>
                <Typography
                  m={0}
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    // lineHeight: 1.25,
                    width: "100%",
                    margin: 0,
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                  }}
                  variant="caption"
                >
                  {tabURL}
                </Typography>
              </div>
            </div>
            {editTabTitle ? null : (hover && !isDragging && !dragActive) || selected ? (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  backgroundColor: selected ? "transparent" : theme.palette.collection.hover,
                  paddingRight: 5,
                  height: "100%",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="settings"
                  onClick={(e) => openMenu(e)}
                  size="small"
                  // style={{marginLeft: 4}}
                  style={{
                    margin: 3,
                    borderRadius: 4,
                    padding: 3,
                  }}
                >
                  <MoreVert fontSize="inherit" />
                </IconButton>
              </div>
            ) : null}
            {anchorEl ? (
              <LinkMenu
                ChangeTabName={ChangeTabName}
                ChangeTabURL={ChangeTabURL}
                DeleteTab={DeleteTab}
                SortTabs={SortTabs}
                activeWorkspace={globalState.activeWorkspace}
                anchor={anchorEl}
                clickPosition={clickPosition}
                collection={collection}
                collectionId={collection._id}
                collectionsData={collectionsData}
                handleClick={openMenu}
                handleClose={handleClose}
                handleCloseMoveTabMenu={closeMoveTabsMenu}
                handleMoveTabs={handleMoveTabs}
                moveTabsAnchorEl={moveTabsAnchorEl}
                moveTabsMenuOpen={moveTabsMenuOpen}
                //for moving tab menu
                openMoveTabsMenu={openMoveTabsMenu}
                params={params}
                resetOldOrder={resetOldOrder}
                secondClickPosition={secondClickPosition}
                setEditTabTitle={setEditTabTitle}
                tab={tab}
              />
            ) : null}
            {/* {
                moveTabsMenuOpen ?
                    <MoveTabMenu
                        activeWorkspace={globalState.activeWorkspace}
                        anchorEl={moveTabsAnchorEl}
                        collectionId={collection._id}
                        handleClose={closeMoveTabsMenu}
                        handleMoveTabs={handleMoveTabs}
                        moveTabsMenuOpen={moveTabsMenuOpen}
                        secondClickPosition={secondClickPosition}
                    />
                :
                null
              } */}
          </div>
        </div>
        {showPreviewLineOnRight && (
          <div
            style={{ height: "calc(100% - 16px)", marginTop: 4, backgroundColor: "#3D5AFE", width: 4, position: "absolute", top: 12, right: -10, opacity: 0.5 }}
          />
        )}
      </Grid>
    );
  }
};
export default React.memo(TabCard);

//TOOLTIP
// <HtmlTooltip
//   enterDelay={2000}
//   enterNextDelay={0}
//   leaveDelay={100}
//   title={
//       !isDragging && !dragActive && anchorEl === null ?
//       <React.Fragment>
//         <Typography color="inherit" variant="subtitle2">{tab.title}</Typography>
//         <span> <a href={tab.url} target='_blank' style={{color: '#3D5AFE'}}> {tab.url} </a> </span>
//       </React.Fragment>
//       :
//       ""
//     }
//   interactive
//   onMouseLeave={(e) => TabHovered(tab, false)}
// >

// <Link
//   href={tab.url}
//   target="_blank"
//   style={{textDecoration: 'none'}}
//   disabled={editTabTitle}
// >
//   <IconButton aria-label="openTab" size="small">
//     <CallMade fontSize="inherit"/>
//   </IconButton>
// </Link>
