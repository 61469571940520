import React, {useEffect, useState} from 'react';

import { styled } from '@mui/material/styles';

import {
  FormControl,
  InputBase,
  TextField,
} from '@mui/material';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    
  },
}));


export default function CustomDateSelect(props) {
  const options = props.options

  const selected = props.selected
  const handleChange = props.handleChange

  const item = props.item

  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [selectedDate, handleDateChange] = useState(props.item.value.dateValue)

  const handleSelectDate = (dateObj) => {
    //console.log(dateObj)
    handleDateChange(dateObj._d)

    props.handleChange(null, props.item, props.itemKey, dateObj._d)
  }

  const renderWeekPickerDay = (
    date,
    selectedDates,
    pickersDayProps
  ) => {
    return (
        <PickersDay
            {...pickersDayProps}
            sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "#3d5AFE"
          }
        }}
        />
    );
  };

  const [open, setOpen] = React.useState(false);
  //width: props.InListItem || props.smallWidth ? null : 100,
  if(options){
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl
                className={props.InListItem || props.smallSelect ? {margin: 0} : {margin: 8}}
                style={{ margin: props.smallSelect ? 0 : 4, marginTop: 0, marginBottom: 0, width: '100%'}}
                variant="standard"
            >
                <DesktopDatePicker
                    //TextFieldComponent={BootstrapInput}
                    disableToolbar
                    format="MMM D, yyyy"
                    onChange={handleSelectDate}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    placeholder="Date"
                    renderDay={renderWeekPickerDay}
                    renderInput={props => (<BootstrapInput
                        onClick={(e) => setOpen(true)}
                        {...props}
                                           />)}
                    sx={{
                      "& .MuiPickersDay-dayWithMargin": {
                        color: "#00c676",
                        backgroundColor: "rgba(50, 136, 153)"
                      },
                    }}
                    value={selectedDate}
                    variant="inline"
                />
            </FormControl>
        </LocalizationProvider>
    );
  } else {
    return (
      null
    )
  }

}

// <MuiPickersUtilsProvider utils={MomentUtils}>
//     <DatePicker
//       disableToolbar
//       variant="inline"
//       open={datePickerOpen}
//       onOpen={() => setDatePickerOpen(true)}
//       onClose={() => setDatePickerOpen(false)}
//       format="d MMM yyyy"
//       value={selectedDate}
//       onChange={handleDateChange}
//       className={classes.customSelect}
//
//       TextFieldComponent={CustomInputBase}
//     />
// </MuiPickersUtilsProvider>

//<InputLabel id="demo-customized-select-label">Age</InputLabel>
