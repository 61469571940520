import { paramCase } from "change-case";

const useEncodeCollectionURL = () => {
  const decodeCollectionURLId = (path) => {
    if (path) {
      //   const match = path.match(/.*?-(.{8}-.{4}-.{4}-.{4}-.{12})/);
      const match = path.match(/\/?(?:([a-z0-9-]+)-)?([a-f0-9]{8})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{4})([a-f0-9]{12})/);
      const standardUUIDMatch = path.match(/^\/?(?:[a-z0-9-]+-)?([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/); // a backup incase just the _collectionId is passed

      // console.log({ path, match, standardUUIDMatch });
      if (match && match.length > 1) {
        const collectionId = `${match[2]}-${match[3]}-${match[4]}-${match[5]}-${match[6]}`;
        // console.log("Converting " + path + " to: " + collectionId);
        return collectionId;
      } else if (standardUUIDMatch && standardUUIDMatch.length > 1) {
        const collectionId = standardUUIDMatch[1];
        return collectionId;
      }
      return;
    }
    // console.log("Decode URL failed: " + path);
    return null;
  };

  const getLinkToCollection = (stringCollectionId, collectionName = "") => {
    // `/${paramCase(globalState.activeWorkspace._id)}/${collection.urlId}` : "/";
    let encodedName = "";

    if (stringCollectionId) {
      const formattedId = stringCollectionId.replace(/-/g, "");
      if (collectionName) {
        // const hyphenatedName = collectionName
        //   .trim()
        //   .toLowerCase()
        //   .replace(/\s+/g, "-")
        //   .replace(/[^a-z0-9-]/g, "");

        const hyphenatedName = paramCase(collectionName);
        encodedName = `${hyphenatedName}`;
      }
      let collectionURL = `/collection/${encodedName}-${formattedId}`;
      // console.log("create collection URL " + collectionURL);
      return collectionURL;
    }

    return `/`;
  };

  return { decodeCollectionURLId, getLinkToCollection };
};

export default useEncodeCollectionURL;
