import React, {useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import {colors as labelColors} from '../../Utils/StandardColors.js';

import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';


// const CustomInput = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: 24,
//     },

//   },
//   input: {
//     display: 'flex', flexDirection: 'row', alignItems: 'center',
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: 14,
//     padding: '4px 26px 4px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:focus': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//     },
//     '&:hover': {
//       borderRadius: 4,
//       backgroundColor: 'rgba(196, 196, 196, 0.15)',
//     },
//   },
// }))(InputBase);

// const InListItem = withStyles((theme) => ({
//   root: {
//     'label + &': {
//       marginTop: 24,
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: 'relative',
//     //border: '1px solid #ced4da',
//     fontSize: 14,
//     padding: '4px 14px 4px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     '&:focus': {
//       backgroundColor: 'rgba(255, 255, 255, 0)',
//     },
//   },
// }))(InputBase);

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 24,
  },
  '& .MuiInputBase-input': {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    //transition: 'border-color background-color 5s ease',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    '&:focus': {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

function CustomFieldSelect(props) {
  const theme = useTheme();
  const [options, setOptions] = useState(props.options || [])
  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  const isTag = props.isTag


  //console.log({options})
  // const [selected, setSelected] = useState('');
  // //initialize value
  // useEffect(() => {
  //   if(options && options.length > 0){
  //     setSelected(options[0].value)
  //   }
  // }, [])
  //
  // const handleChange = (event) => {
  //   setSelected(event.target.value);
  // };

  
  
  const selected = props.selected
  // console.log(selected, isTag)

  const handleChange = props.handleChange

  const item = props.item
  //if(isTag) console.log({item, selected, isTag})

  // console.log({selected, item})

  function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : null;
    }

  if(options){
    return (
        <FormControl
            className={props.InListItem || props.smallSelect ? {margin: 0} : {margin: 8}}
            style={{ margin: props.smallSelect ? 0 : 4, marginTop: 0, marginBottom: 0, width: '100%'}}
            variant="standard"
        >
            <Select
                MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
            
                classes: { paper: {maxHeight: 400} }
              }}
                id="demo-customized-select"
                input={props.InListItem ? <BootstrapInput /> : <BootstrapInput />}
                labelId="demo-customized-select-label"
                onChange={(e) => handleChange(e, item, props.itemKey, e.target.value, isTag)}
            //onChange={(e) => console.log(e)}
                renderValue={(selected) => {
        if (selected == " " || selected == "" || selected == null || selected == undefined || selected == '') {
          return (<em>
              Select an option
          </em>);
        }
        else if(isTag){
          const bg = labelColors.findIndex(l => l.name == selected.color) > -1 ? hexToRgb(labelColors.find(l => l.name == selected.color).hex) : '#C3C3C3'
          return(
              <div
                  style={{
                  backgroundColor: bg && bg.r && theme.palette.mode === "dark" ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                  //backgroundColor: solidColor,
                  paddingTop: 3,
                  paddingBottom: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 2,
                  marginBottom: 0,
                  margin: 0,
                  display: 'inline-block',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: 125
                }}
              >
                  <Typography
                      noWrap
                      style={{
                        color: theme.palette.mode === "dark" ? theme.palette.text.primary : labelColors.findIndex(l => l.name == selected.color) > -1 ? labelColors.find(l => l.name == selected.color).darker : theme.palette.text.primary,
                        fontSize: 12, 
                        width: '100%', 
                        textAlign: 'left', 
                        fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
                        fontWeight: 600,
                      }}
                      variant='body2'
                  >
                      {selected.name}
                  </Typography>
              </div>
          )
        }
        else {
          return (
              <>
                  {
              selected.type == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                  size="small"
                  style={{width: 16, height: 16, color: theme.palette.text.secondary, marginLeft: 0, marginRight: 4}}
                                                />
              :
              selected.type == "multiSelect" ? <ListRoundedIcon
                  size="small"
                  style={{width: 16, height: 16, color: theme.palette.text.secondary, marginLeft: 0, marginRight: 4}}
                                               />
              :
              null
            }
                  <Typography
                      noWrap
                      style={{
                        color: theme.palette.mode === "dark" ? theme.palette.text.primary : labelColors.findIndex(l => l.name == selected.color) > -1 ? labelColors.find(l => l.name == selected.color).darker : theme.palette.text.primary,
                        width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600,}}
                      
                      variant='body2'
                  >
                      {selected.name}
                  </Typography>
              </>
          )
        }

      }}
                style={{maxHeight: 200}}
            //displayEmpty={true}

                value={isTag ? options.find(o => o._id == selected) ? options.find(o => o._id == selected) : "" : options.find(o => o.value == selected) ? options.find(o => o.value == selected) : ""}
                variant="standard"
            >
                {
          options && options.length > 0 ?
          options.map((o, i) => {
            if(isTag){
              const bg = labelColors.findIndex(l => l.name == o.color) > -1 ? hexToRgb(labelColors.find(l => l.name == o.color).hex) : '#C3C3C3'
              return(
                  <MenuItem
                      dense
                      key={i}
                      style={{width: 175}}
                      value={o}
                  >
                      <div
                          style={{
                      backgroundColor: bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                      //backgroundColor: solidColor,
                      paddingTop: 3,
                      paddingBottom: 2,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 2,
                      marginBottom: 0,
                      display: 'inline-block',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: 125
                    }}
                      >
                          <Typography
                              noWrap
                              style={{color: labelColors.findIndex(l => l.name == selected.color) > -1 ? labelColors.find(l => l.name == selected.color).darker : theme.palette.text.secondary, fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600,}}
                              variant='body2'
                          >
                              {o.name}
                          </Typography>
                      </div>
                  </MenuItem>
              )
            } else {
              return (

                  <MenuItem
                      dense
                      key={i}
                      style={{textOverflow: "ellipsis", overflow: "hidden", maxWidth: 400,}}
                      value={o}
                  >
                      {
                    o && o.type ?
                      o.type == "singleSelect" ? <ArrowDropDownCircleRoundedIcon
                          size="small"
                          style={{width: 16, height: 16, color: theme.palette.text.primary, marginLeft: 0, marginRight: 4}}
                                                 />
                      :
                      o.type == "multiSelect" ? <ListRoundedIcon
                          size="small"
                          style={{width: 16, height: 16, color: theme.palette.text.primary, marginLeft: 0, marginRight: 4}}
                                                />
                      :
                      null
                    :
                    null
                  }
                      <Typography
                          noWrap
                          variant="inherit"
                      >
                          {o.name}
                      </Typography>
                  </MenuItem>
              )
            }
          })
          :
          <MenuItem
              dense
              key={0}
              value=""
          >
              <Typography
                  noWrap
                  style={{color: labelColors.findIndex(l => l.name == selected.color) > -1 ? labelColors.find(l => l.name == selected.color).darker : theme.palette.text.secondary, fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600,}}
                  variant='body2'
              >
                  No properties
              </Typography>
          </MenuItem>
        }
            </Select>
        </FormControl>
    );
  } else {
    return (
      null
    )
  }

}

export default React.memo(CustomFieldSelect)

//<InputLabel id="demo-customized-select-label">Age</InputLabel>
