import React from "react";
import { useTheme, styled } from "@mui/material/styles";

import EmojiComponent from "../Emojis/EmojiComponent";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { Typography, InputBase, MenuItem, Popper, ClickAwayListener } from "@mui/material";

import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import useGlobal from "../../GlobalState/Store/Store";
import useWorkspaces from "../../Hooks/useWorkspaces";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.palette.text.secondary,
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 4,
    paddingBottom: 4,
    overflowX: "hidden",
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4,
      fontSize: 14,
      minWidth: 0,
      // borderBottom: `1px solid  ${
      //   theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      // }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}
const StyledPopper = styled(Popper)(({ theme }) => ({
  overflow: "hidden",
  border: `1px solid ${theme.palette.divider}`,
  // boxShadow: `0 8px 24px ${
  //   theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  // }`,
  boxShadow: theme.palette.shadows.large,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.background.paper,
  marginTop: 12,
}));

export default function SaveSessionMenu({ anchorEl, handleClose, handleSaveAsCollection, sessionMenuClickPosition }) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  const open = Boolean(anchorEl); // props.open

  //console.log({anchorEl, open})

  const { workspacesData, isLoadingWorkspaces } = useWorkspaces(globalState.isAuth, { revalidateOnMount: false });
  let workspaces = workspacesData?.workspaces || [];
  let loading = isLoadingWorkspaces;

  const handleAutoCompleteKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    if (charCode == 13 || charCode === 27 || charCode === 38 || charCode === 40) {
      // keep current escape, and arrow key behaviour, ENTER
      event.stopPropagation();
    }
  };

  const id = open ? "save-as-collection" : undefined;
  const offsetY = sessionMenuClickPosition !== null && sessionMenuClickPosition.mouseY ? -36 : 4;

  // console.log({sessionMenuClickPosition, offsetY})
  return (
    <StyledPopper
      anchorEl={anchorEl}
      id={id}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, offsetY],
          },
        },
      ]}
      open={open}
      placement="bottom-end"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Autocomplete
          PopperComponent={PopperComponent}
          fullWidth
          getOptionLabel={(option) => option.name}
          loading={loading}
          noOptionsText="No workspaces"
          onChange={(event, selected, reason) => handleSaveAsCollection(event, selected)}
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleClose(event);
            }
          }}
          onKeyDown={handleAutoCompleteKeyPress}
          open={open}
          options={workspaces}
          renderInput={(params) => (
            <BootstrapInput
              autoFocus
              fullWidth
              inputProps={params.inputProps}
              placeholder="Save to..."
              ref={params.InputProps.ref}
              style={{
                padding: 10,
                width: "100%",
                borderBottom: `1px solid ${theme.palette.divider}`,
                "& input": {
                  borderRadius: 4,
                  backgroundColor: theme.palette.background.paper,
                  padding: 8,
                },
              }}
            />
          )}
          renderOption={(props, workspace, { selected }) => (
            <MenuItem {...props} key={workspace._id}>
              <div
                style={{
                  borderRadius: 4,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 4,
                  marginRight: 4,
                  fontSize: 14,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "24px",
                    // height: '24px',
                    borderRadius: "2px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                    //border: '0.5px solid'
                  }}
                >
                  {
                    //EMOJI
                    workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ? (
                      <EmojiComponent size={18} symbol={workspace.icon.emoji.emoji} />
                    ) : //COLOR
                    workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ? (
                      <div style={{ width: 12, height: 12, backgroundColor: workspace.icon.colorIcon.hex, borderRadius: "100%" }} />
                    ) : (
                      <FolderOpenRoundedIcon fontSize="small" />
                    )
                  }
                </div>
                <Typography noWrap sx={{ color: "text.primary", marginBottom: 3, margin: 0 }} variant="body2">
                  {workspace.name}
                </Typography>
              </div>
            </MenuItem>
          )}
          renderTags={() => null}
        />
      </ClickAwayListener>
    </StyledPopper>
  );
}
