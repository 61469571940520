/* eslint-disable no-undef */
import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import "./index.css";
//import App from './App';
import Root from "./Root";
//import * as serviceWorker from './serviceWorker';
import axios from "axios";

// import * as FullStory from "@fullstory/browser";

const isDev = process.env.NODE_ENV == "development" || process.env.REACT_APP_RENDER_DEV === "true" ? true : false; //can add this as an env variable in the render dev.partizion.io if you want to test dev
// console.log(process.env.REACT_APP_RENDER_DEV, process.env.REACT_APP_RENDER_DEV === "true", process.env.REACT_APP_RENDER_DEV === true);

// if (!isDev) FullStory.init({ orgId: "ZMVJ7" });

//console.log("ISDEV: " + isDev)
axios.defaults.withCredentials = true;
axios.defaults.baseURL = isDev ? "http://localhost:5000/api/v1" : "https://www.api.partizion.io/api/v1/"; //'https://partizion-test-server.onrender.com/api/v1/' //

// console.log({ isDev }, process.env.REACT_APP_RENDER_DEV);

//'https://partizion-test-server.onrender.com/api/v1/'

//'https://partizion-prod-server-v0.onrender.com/api/v1/'

//'https://api.partizion.io/api/v1/'

//'http://localhost:5000/api/v1'

//'https://partizion-dev-server.herokuapp.com/api/v1'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const chromeLocation = urlParams.get("popup");
const gettingStarted = urlParams.get("gettingstarted");
const installReason = urlParams.get("installreason");


const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <CookiesProvider>
        <Root
            appLocation={chromeLocation}
            gettingStarted={gettingStarted}
            isDev={isDev}
        />
    </CookiesProvider>
);

