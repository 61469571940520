import { Avatar, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

import Robin from "../../Assets/Avatars/robin.jpeg";
import Don from "../../Assets/Avatars/don.jpeg";
import Joshua from "../../Assets/Avatars/joshua.jpg";
import Piyali from "../../Assets/Avatars/piyali.jpg";
import Killian from "../../Assets/Avatars/Killian.jpeg";
import Mitja from "../../Assets/Avatars/Mitja.jpeg";
import Sue from "../../Assets/Avatars/Sue.jpeg";
import Julian from "../../Assets/Avatars/Julian.jpeg";
import Ryan from "../../Assets/Avatars/ryan.jpeg";

import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

const CustomerReviews = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 60,
        paddingBottom: 48,
      }}
    >
      <div style={{ width: "100%", maxWidth: 800, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="h3">And loved by many more</Typography>
        <div
          style={{
            marginTop: 48,
            width: "100%",
            gap: 12,
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px,1fr))",
          }}
        >
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              //   flex: "1 0 40%",
              gridColumn: "span 1",
              gridRow: "span 2",

              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: 18,
            }}
            variant="outlined"
          >
            <Typography>
              Learned about this extension from a friend and it's absolutely genius. What I love about it is that it keeps my computer from crashing when I have
              the inevitable "too many tabs" syndrome. On top of that, though, it helps me stay focused on the several different projects I am balancing on any
              given day.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Robin Arcega" src={Robin} />
              <Typography color={theme.palette.text.secondary}>- Robin Arcega</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>I replaced the great extender with Partizion. Love it!</Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Sue" src={Sue} />
              <Typography color={theme.palette.text.secondary}>- Sue</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>I love this so much! It's helped me finally organize the nightmare that was my open tabs.</Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Killian Perez Garcia" src={Killian} />
              <Typography color={theme.palette.text.secondary}>- Killian Perez Garcia</Typography>
            </div>
          </Card>

          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 2",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              Partizion performs WAY past what I expected... it's robust, uncomplicated and reliable. Definitely the best solution I've found for managing
              workspaces in Chrome. Fantastic support, too. Was pleasantly surprised to have the creator respond personally to a question about a feature. Very
              cool! Highly recommend.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Don Jennings" src={Don} />
              <Typography color={theme.palette.text.secondary}>- Don Jennings</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>Really smooth and simple but very sticky and handy tool, first time i've paid for a google plugin and well worth it!</Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Joshua Uwadiae" src={Joshua} />
              <Typography color={theme.palette.text.secondary}>- Joshua Uwadiae</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              Partizion - something that I have been looking for quite some time now. It helped me to reduce clutter and context switching for my Chrome tabs
              and increase productivity. Awesome extension, loving it!
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Piyali Banerjee" src={Piyali} />
              <Typography color={theme.palette.text.secondary}>- Piyali Banerjee</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              Money well spent on a great addition to Chrome. I have tried many tab managers but none have all the features and are as easy to use as Partizion.
              Developer is very responsive, open to suggestions, and always looking for ways to improve.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Mike Roberts">M</Avatar>
              <Typography color={theme.palette.text.secondary}>- Mike Roberts</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              A Tab herder's dream. Perfect for managing my work and home tabs and also for keeping track of all the sites I visit and my HomeLab URLs. Works
              from every device and syncs perfectly. Great customer support so far as well, a project worth supporting.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Max">M</Avatar>
              <Typography color={theme.palette.text.secondary}>- Max</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 2",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              I never leave reviews, but this is worth my time! Once The Great Suspender got shut down, I needed a new way, and I needed it to just work. I'm a
              former tab hoarder, to the tune of 600+ "suspended" tabs that I kept meaning to go back and organize. Partizion makes it so simple with plenty of
              granular control. I'm down to just one window - I can't believe it. Matthew is also incredibly responsive by email and always takes suggestions
              into consideration. Thanks for helping me regain my browser sanity!
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Julian Rad" src={Ryan} />
              <Typography color={theme.palette.text.secondary}>- Ryan Neary</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              A Tab herder's dream. Perfect for managing my work and home tabs and also for keeping track of all the sites I visit and my HomeLab URLs. Works
              from every device and syncs perfectly. Great customer support so far as well, a project worth supporting.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Mitja Simcic" src={Mitja} />
              <Typography color={theme.palette.text.secondary}>- Mitja Simcic</Typography>
            </div>
          </Card>
          <Card
            style={{
              padding: 20,
              background: theme.palette.collection.default,
              gridColumn: "span 1",
              gridRow: "span 1",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: 12,
            }}
            variant="outlined"
          >
            <Typography>
              OK. Partizion is giving me a lot more control over my Chrometabitis and is exceeding my expectations. So much more than I could do with bookmarks
              or Raindrop.
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: 12 }}>
              <Avatar alt="Julian Rad" src={Julian} />
              <Typography color={theme.palette.text.secondary}>- Julian Rad</Typography>
            </div>
          </Card>
        </div>
        <div style={{ marginTop: 24, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 24, gap: 8 }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "center", padding: 2, gap: 4 }}>
            <StarRateRoundedIcon style={{ color: "#F9C941" }} />
            <StarRateRoundedIcon style={{ color: "#F9C941" }} />
            <StarRateRoundedIcon style={{ color: "#F9C941" }} />
            <StarRateRoundedIcon style={{ color: "#F9C941" }} />
            <div style={{ width: 13, overflow: "hidden" }}>
              <StarRateRoundedIcon style={{ color: "#F9C941" }} />
            </div>
          </div>
          <Typography style={{ color: theme.palette.text.primary }}>4.8 Stars on the Chrome Web Store</Typography>
          <Link
            style={{ color: theme.palette.text.secondary }}
            target="_blank"
            to="https://chromewebstore.google.com/detail/partizion/ldimfpkkjopddckaglpeakpaepclcljn/reviews"
          >
            Read more reviews ↗
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
