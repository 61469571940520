import React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,

  Typography,
  Box,

} from '@mui/material';

export default function ConfirmCloseWindowDialog(props) {
  const theme = useTheme()

  const open = props.open
  const handleClick = props.handleClick
  const handleClose = props.handleClose
  const confirm = props.confirm

  const state = props.state

  function CurrentWindowCard(props){


    const tabs = state.window && state.window.tabs ? state.window.tabs : []
    const index = state.index

    function extractHostname(url) {
      var hostname;
      if(url && url !== undefined){
        var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false

        if(isUrl){
          //---- OLD FULL REMOVAL METHOD ----
          //find & remove protocol (http, ftp, etc.) and get hostname
          // if (url.indexOf("//") > -1) {
          //   hostname = url.split('/')[2];
          // }
          // else {
          //   //console.log("found / ", hostname)
          //   hostname = url.split('/')[0];
          // }
          // //removes a remaing http or www.
          // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
          //---------------------//
          if(url.indexOf("https://") > -1){
            hostname = url.split('https://')[1];
          }
          else if(url.indexOf("http://") > -1){
            hostname = url.split('http://')[1];
          } else {
            hostname = url
          }
          // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
          if(hostname){
            hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")//.split('/')[0];
          }

        } else {
          return url
        }
      }
      return hostname;
    }
    let justDomains = tabs.map((t, i) => {
      var comma = i !== tabs.length -1 && tabs.length > 1 ? ", " : " "
      var urlString = extractHostname(t.url) + comma

      return urlString
    })
    //rmeove duplicates
    let hostNames = [... new Set(justDomains)]

    return(
        <div
            style={{
              width: '100%',
              maxWidth: '100%',

              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.collection.hover,
              boxShadow: theme.palette.shadows.small,
              borderRadius: 4,

              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 8,
              paddingLeft: 8,
              marginTop: 6,
            }}
        >
            <div style={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'start',
               justifyContent: 'flex-start',
               marginLeft: '10px',
               width: '88%',
            }}
            >
                <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: 5 }}>
                    <Typography
                        m={0}
                        noWrap
                        style={{
                          fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
                          fontWeight: 600, 
                          color: theme.palette.text.primary,
                          marginRight: 4, 
                          display: 'flex', 
                          flexDirection: 'row', 
                          alignItems: 'flex-end',
                          lineHeight: 1.25,
    fontSize:             14,
                        }}
                        variant="subtitle2"
                    >
                        Window 
                        {' '}
                        {state.index}

                        <Box style={{fontWeight: 400, color: '#6E6E6E'}}>
                &nbsp;| 
                            {' '}
                            {tabs.length}
                            {' '}
                            tabs
                        </Box>
                    </Typography>
                </div>
                <Typography
                    m={0}
                    noWrap
                    style={{
                      textOverflow: "ellipsis", 
                      overflow: "hidden", 
                      width: '100%', 
                      fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", 
                      fontWeight: 400, 
                      color: theme.palette.text.secondary,
                      lineHeight: 1,
                      fontSize: 11,
                    }}
                    variant="caption"
                >
                    {
                hostNames && hostNames.length > 0 ?
                hostNames.map(t => t)
                :
                null
              }
                </Typography>
            </div>
        </div>
    )
  }

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode
    //console.log("key " + charCode + " pressed")
    if(charCode == 13){
      //console.log("Enter key pressed")
       //if enter is pressed login
      confirm()
    }
  }

  return (
      <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleClose}
          open={open}
          style={{padding: 20}}
      >
          <DialogContent style={{maxWidth: 350, width: 350}}>
              <DialogContentText
                  id="alert-dialog-description"
                  style={{marginBottom: 25, color: theme.palette.text.primary}}
              >
                  Are you sure you want to close this window?
              </DialogContentText>
              <CurrentWindowCard />
              <Divider style={{marginTop: 15, marginBottom: 15}} />
              <Button
                  autoFocus
                  color="primary"
                  fullWidth
                  onClick={() => confirm()}
                  style={{marginBottom: 10}}
                  variant="contained"
              >
                  {state.confirmText}
              </Button>
              <Button
                  color="error"
                  fullWidth
                  onClick={handleClose}
                  style={{marginBottom: 10}}
                  variant="outlined"
              >
                  {state.cancelText}
              </Button>
          </DialogContent>
      </Dialog>
  );
}
