import { useState } from 'react';
import useSWR from 'swr'
import axios from 'axios'

const fetcher = url => axios.get(url, {withCredentials: true}).then(res => res.data)

function useWorkspaces (isAuth, options) {
  const { data, error, isValidating } = useSWR(isAuth ? `/workspaces` : null, fetcher, options ? options : null)

  return {
    workspacesData: data,
    isLoadingWorkspaces: !error && !data,
    isWorkspacesError: error,
    isValidatingWorkspaces: isValidating
  }
}

export default useWorkspaces;
