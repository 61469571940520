import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";

import { Typography } from "@mui/material";

import { colors as labelColors } from "../../../Utils/StandardColors.js";

const emptyArray = [];

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function CollectionPropertyDiv(props) {
  const theme = useTheme();
  const currentProperties =
    props.collection && props.collection.properties
      ? props.collection.properties
      : emptyArray;
  const propertyFromWorkspace = props.property;
  const index = currentProperties.findIndex(
    (p) => p.propertyId == propertyFromWorkspace._id
  );
  const values =
    index > -1 && propertyFromWorkspace.values
      ? propertyFromWorkspace.values.filter(
          (wsPropValue) =>
            wsPropValue &&
            currentProperties[index].values &&
            currentProperties[index].values.indexOf(wsPropValue._id) > -1
        )
      : emptyArray;
  const properties =
    values && values.length > 0
      ? propertyFromWorkspace.type == "singleSelect"
        ? values.slice(0, 1)
        : values
      : values;

  return (
    <>
      {index > -1 && values && values.length > 0 ? (
        <div
          style={{
            marginTop: props.inList ? 0 : 8,
            marginRight: props.inList ? 4 : 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            overflowX: "hidden",
          }}
        >
          {properties.map((val, i) => {
            if (propertyFromWorkspace.type == "singleSelect") {
              const bg =
                labelColors.findIndex((l) => l.name == val.color) > -1
                  ? hexToRgb(labelColors.find((l) => l.name == val.color).hex)
                  : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
              return (
                <div
                  key={val._id}
                  style={{
                    backgroundColor:
                      bg && bg.r && theme.palette.mode === "dark"
                        ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)`
                        : bg && bg.r
                        ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)`
                        : bg,
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 2,
                    marginRight: 4,
                    display: "inline-block",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.text.primary
                          : labelColors.findIndex((l) => l.name == val.color) >
                            -1
                          ? labelColors.find((l) => l.name == val.color).darker
                          : "#191919",
                      fontSize: 12,
                      width: "100%",
                      textAlign: "left",
                      fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                    }}
                    variant="body2"
                  >
                    {val.name}
                  </Typography>
                </div>
              );
            } else if (propertyFromWorkspace.type == "multiSelect") {
              const bg =
                labelColors.findIndex((l) => l.name == val.color) > -1
                  ? hexToRgb(labelColors.find((l) => l.name == val.color).hex)
                  : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
              //console.log(bg)
              return (
                <div
                  key={val._id}
                  style={{
                    backgroundColor:
                      bg && bg.r && theme.palette.mode === "dark"
                        ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)`
                        : bg && bg.r
                        ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)`
                        : bg,
                    paddingTop: 2,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 2,
                    marginRight: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.text.primary
                          : labelColors.findIndex((l) => l.name == val.color) >
                            -1
                          ? labelColors.find((l) => l.name == val.color).darker
                          : "#191919",
                      fontSize: 12,
                      width: "100%",
                      textAlign: "left",
                      fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                    }}
                    variant="body2"
                  >
                    {val.name}
                  </Typography>
                </div>
              );
            }
          })}
        </div>
      ) : null}
    </>
  );
}

export default React.memo(CollectionPropertyDiv);
