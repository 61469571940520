import React from 'react';
const EmojiComponent = props => (
        <span
            className="emoji"
            role="img"
            aria-label={props.label ? props.label : ""}
            aria-hidden={props.label ? "false" : "true"}
            style={{
                display: 'inline-block', 
                // fontSize: '4em',
                // transform: 'scale(.25) translateY(1.1em)',
                // margin: '-1em -.333em',
                fontSize: props.size ? props.size : 26,
                 
                //height: props.size && props.isWorkspaceListItem ? props.size + 6 : props.size, 
                //width: props.size && props.isWorkspaceListItem ? props.size + 6 : props.size, 
                //border: '0.5px solid',
                // height: '100%',
                // width: '100%',
                // maxHeight: '100%',
                // maxWidth: '100%',
                textAlign: 'center',
                verticalAlign: 'baseline',
            }}
        >
            {props.symbol}
        </span> 
);
export default EmojiComponent;


//  <div
//         style={{
//             cursor: 'pointer',
//             display: 'flex',
//             flexDirection: 'vertical',
//             alignItems: 'center',
//             justifyContent: 'center',
//             minHeight: props.size + 6, //30,
//             maxHeight: props.size + 6, //30,
//             minWidth: props.size + 6, //30,
//             maxWidth: props.size + 6, //30,      
//             paddingTop: 4,
//             margin: 2,
//         }}
//     >
//         <span
//             className="emoji"
//             //role="img"
//             aria-label={props.label ? props.label : ""}
//             aria-hidden={props.label ? "false" : "true"}
//             style={{
//                 display: 'inline-block', 
//                 // fontSize: '4em',
//                 // transform: 'scale(.25) translateY(1.1em)',
//                 // margin: '-1em -.333em',
//                 fontSize: props.size ? props.size : 26, 
//                 //height: props.size ? props.size : 26, 
//                 //width: props.size ? props.size : 26, 
//                 //border: 'solid',
//             }}
//         >
//             {props.symbol}
//         </span> 
//     </div>