import React, { useState, useEffect, useCallback } from 'react'
import useGlobal from '../../../GlobalState/Store/Store.js';

import CurTab from './CurTab.js';

const style = {
  width: '100%',
  display: 'grid',
  flexDirection: 'row',
  flexWrap: 'wrap',
}
const TabsGrid = (props) => {
  {
    const [globalState, globalActions] = useGlobal()
    const [tabs, setTabs] = useState([])

    const SelectTabs= props.SelectTabs
    const selectedTabs= props.selectedTabs

    //const tabs = props.tabs
    const [dragActive, setDragActive] = useState(false)
    const parentWindow = props.parentWindow


    //ENSURING MOUNTING AND UNMOUNTING
    const [mountTabs, setMountTabs] = useState(false)
    useEffect(() => {
      //console.log("tabs props changed")
      //console.log(props.tabs)
      let isComponentMounted = true;

      if(isComponentMounted){
        setMountTabs(true)
        setTabs(props.tabs)
      }

      // cancel subscription to useEffect
      return () => {
        //cleanup
        isComponentMounted = false
      }

    }, [props.tabs])

    const renderListItem = (tab, index) => {
      //only render if the url exists to prevent rendering a blank curtab component
      if(tab.url || tab.pendingUrl && tab.title){
        return (
            <CurTab
                SelectTabs={SelectTabs}
                dragActive={dragActive}
                id={tab.id}
                index={index}
                key={index}

                parentWindow={parentWindow}
                selectedTabs={selectedTabs}
                setDragActive={setDragActive}
                tab={tab}
                tabsOrder={tabs}
            />
        )
      }
    }

    return (
        <div>
            {
          mountTabs ?
          tabs.map((tab, index) => (
            renderListItem(tab, index)
          ))
          :
          null
        }
        </div>
    )
  }
}

export default React.memo(TabsGrid);
