import React, { useEffect, useRef} from 'react'
import useGlobal from '../../../GlobalState/Store/Store.js';

import { useDrop } from 'react-dnd'
import ItemTypes from '../../DND/ItemTypes'

import {
  Typography,
  Button,
} from '@mui/material';

import InboxRoundedIcon from '@mui/icons-material/InboxRounded';

import {colors as labelColors} from '../../../Utils/StandardColors.js';

import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

function HiddenColumnButton(props){
  const [globalState, globalActions] = useGlobal()

  const bg = props.bg
  const col = props.column
  const handleOpenEditValue= props.handleOpenEditValue
  const columnDragActive= props.columnDragActive
  const groupBy = props.groupBy
  const moveCollectionCardInKanban = props.moveCollectionCardInKanban


  const hiddenColumnRef = useRef(null)

  const [{isOver}, drop] = useDrop({
    accept: [ItemTypes.COLLECTIONCARD, ItemTypes.CURTAB, ItemTypes.PASTTAB, ItemTypes.CURWINDOW, ItemTypes.PASTSESSION],
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor && hiddenColumnRef.current ? monitor.canDrop() : false,
    }),
    hover(monitor) {
      // isHovered: monitor.isOver()

      //console.log(item.type, isHoverColumn, showCreateCollectionWithDrag !== group._id)
      //console.log(item.type + " is hovering", {isHoverColumn, showCreateCollectionWithDrag})
      //console.log({group, groupBy}, props.id)

      if (!hiddenColumnRef.current) {
        return
      }

    },
    drop(item, monitor){
      //setShowCreateCollectionWithDrag(null)
      const didDropInColumn = monitor && !monitor.didDrop() ? true : false

      //console.log(item.type + " droppped in kanabn", didDropInColumn)
      const dragIndex = item.index
      if(item.type === 'collectioncard' && col.collections && didDropInColumn){ //&& group.collections.length == 0

        let properDestIndex = col && col.collections && col.collections.length ? col.collections.length : 0 //this is the case where there are no collections in the column
        moveCollectionCardInKanban(null, item.collection, col, item.kanbanGroup._id, dragIndex, properDestIndex) //(collectionId, destColumnId, curColumnIndex, destCollectionIndex, curCollectionIndex)
      } else if(item.type === "curtab" || item.type === "pasttab" || item.type === "curwindow" || item.type === "pastsession"){
        //console.log(item.type + " dropped in KANBAN")
        //console.log(item)
        var tabsToAdd = []
        if(item.selectedTabs && item.selectedTabs.length > 0){
          tabsToAdd = item.selectedTabs
        } else if (item.tabs && item.tabs.length > 0){
          tabsToAdd = item.tabs
        }
        else {
          tabsToAdd.push(item.tab)
        }
        //pass this value to be created as the
        // properties: [...]
        // in the new collection
        let properties = [
          {
            propertyId: groupBy._id,
            values: [col.propertyValue._id]
          }
        ]
        if(didDropInColumn) props.CreateCollection(tabsToAdd, item.type, item, properties, groupBy)

        //console.log({group, tabsToAdd, properties})
      }
    }
  })
  
  // drop(hiddenColumnRef)

  useEffect(() => {
    drop(hiddenColumnRef)
    return () => {
      //cleanup
      drop(null);
    };
  }, [drop, hiddenColumnRef]);

  return (
      <PlainButton
          disableFocusRipple
          disableRipple
          disabled={columnDragActive || col.propertyValue._id == "justcreated" ? true : false}
          fullWidth
          key={col._id}
          onMouseUp={(e) => handleOpenEditValue(e, col.propertyValue)}
        //style={{minWidth: 0}}
          ref={hiddenColumnRef}
          size="small"
          style={{
            display: 
            'flex', flexDirection: 'row', 
            flexWrap: 'noWrap', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10, paddingRight: 10, 
            backgroundColor: isOver ? '#EFEFEF' : null
          }}
      >
          <div
              style={{
            backgroundColor: col._id == "none" ? 'transparent' : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
            margin: 0,
            paddingTop: 2,
            paddingBottom: 1,
            paddingLeft: 4,
            paddingRight: 4,
            minWidth: 10,
            borderRadius: 2,

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
              {
            col._id == "none" ?
                <InboxRoundedIcon style={{width: 18, height: 18, marginRight: 4}} />
            :
            null
          }
              <Typography
                  noWrap
                  style={{color: col._id == "none"? '#000' : labelColors.findIndex(l => l.name == col.propertyValue.color) > -1 ? labelColors.find(l => l.name == col.propertyValue.color).darker : "#191919", fontSize: 12, width: '100%', textAlign: 'left', fontFamily: "\"Dm Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 600}}
                  variant='body2'
              >
                  {
              col.propertyValue && col._id != "none" ?
              col.propertyValue.name
              :
              col._id == "none" ?
              "No " + col.propertyValue.name
              :
              'None'
            }
              </Typography>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 4, marginLeft: 4, color: '#C4C4C4'}}>
              <Typography
                  color="textSecondary"
                  noWrap
                  style={{margin: 0}}
                  variant="body2"
              >
                  {
            col && col && col.collections && col.collections.length > 0 ?
            col.collections.length
            :
            0
          }
              </Typography>
          </div>
      </PlainButton>
  )
}

export default React.memo(HiddenColumnButton)
