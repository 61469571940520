import React from "react";
import PropTypes from "prop-types";

import { Typography, Badge } from "@mui/material";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import defaultFavIcon from "../../Assets/noFavIcon2.png";

import { useTheme } from "@mui/material/styles";

const TabsDragPreview = ({ item, tabs, canDrop, type, tabWidthRef }) => {
  const tabWidth = tabWidthRef && tabWidthRef.current ? tabWidthRef.current.getBoundingClientRect().width : 0;
  const theme = useTheme();

  if (tabs && tabs.length > 1) {
    return (
      <div style={{ opacity: type === "tabcard" ? 0.5 : 0.9 }}>
        {tabs && tabs.length > 0 ? <Badge badgeContent={tabs.length} color="primary" style={{ position: "relative", top: 0, right: 0 }} /> : null}
        {tabs && tabs.length > 0
          ? tabs.slice(0, 3).map((tab, i) => {
              const FaviconURL =
                tab.favIconUrl === "" || tab.favIconUrl === " " || tab.favIconUrl === undefined || tab.favIconUrl === null
                  ? defaultFavIcon
                  : tab.favIconUrl.replace(/^http:\/\//, "https://");
              const isSelected = tabs && tabs.findIndex((t) => t.id === tab.id) > -1 ? true : false;

              return (
                <div
                  className="card card-dragged"
                  key={tab.id}
                  style={{
                    zIndex: tabs.length - i,
                    transform: `rotateZ(${-i * 2}deg)`,
                    marginTop: -50,
                    width: tabWidth > 0 ? tabWidth : 250,
                    opacity: 0.9,
                  }}
                >
                  <div>
                    <div
                      style={
                        type == "tabcard"
                          ? {
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",

                              padding: 6,
                              flexGrow: 1,
                              width: "100%",
                              maxWidth: "100%",
                              border: `1px solid ${theme.palette.divider}`,
                              backgroundColor: theme.palette.background.paper,
                              boxShadow: theme.palette.shadows.small,
                              borderRadius: 4,
                              cursor: "grabbing",
                              paddingTop: 8,
                            }
                          : {
                              position: "relative",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: 4,
                              paddingLeft: 8,
                              marginTop: 6,
                              border: `1px solid ${theme.palette.divider}`,
                              backgroundColor: theme.palette.background.paper,
                              boxShadow: theme.palette.shadows.small,
                              borderRadius: 4,
                              cursor: "grabbing",
                              paddingTop: 8,
                            }
                      }
                    >
                      {type == "curtab" ||
                        (type == "pasttab" && (
                          <DragIndicatorIcon
                            fontSize="small"
                            style={{
                              color: theme.palette.text.tertiary,
                              marginRight: "6px",
                              opacity: 0.75,
                              cursor: "grab",
                            }}
                          />
                        ))}
                      {FaviconURL ? <img height={type == "tabcard" ? 24 : 20} mode="fit" src={FaviconURL} width={type == "tabcard" ? 24 : 20} /> : null}
                      <div
                        style={
                          item && item.type == "tabcard"
                            ? {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "flex-start",
                                marginLeft: "10px",
                                //minWidth: '100%',//'calc(100% - 0px)', //used to be - 80px
                                maxWidth: "calc(100% - 24px)",
                              }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "flex-start",
                                marginLeft: "10px",
                                width: "80%",
                              }
                        }
                      >
                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: "95%" }}>
                          <Typography
                            m={0}
                            noWrap
                            style={
                              item && item.type == "tabcard"
                                ? {
                                    lineHeight: 1.25,
                                    fontSize: 14,
                                    width: "100%",
                                    marginBottom: "-3px",
                                    fontWeight: 500,
                                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                                    color: theme.palette.text.primary,
                                  }
                                : type == "curtab" || type == "pasttab"
                                  ? {
                                      lineHeight: 1,
                                      fontSize: 13,
                                      marginBottom: "-3px",
                                      fontWeight: 500,
                                      color: theme.palette.text.primary,
                                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                                    }
                                  : {
                                      lineHeight: 1,
                                      fontSize: 12,
                                      marginBottom: "-2px",
                                      fontWeight: 500,
                                      fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                                      color: theme.palette.text.primary,
                                    }
                            }
                            variant="subtitle2"
                          >
                            {tab.title}
                          </Typography>
                          <Typography
                            m={0}
                            noWrap
                            style={
                              item && item.type == "tabcard"
                                ? {
                                    color: theme.palette.text.secondary,
                                    lineHeight: 1.25,
                                    width: "100%",
                                    margin: 0,
                                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                  }
                                : {
                                    color: theme.palette.text.secondary,
                                    lineHeight: 1,
                                    width: "100%",
                                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                  }
                            }
                            variant="caption"
                          >
                            {tab.url}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  } else {
    const FaviconURL =
      item && item.favIconUrl
        ? !item.favIconUrl || item.favIconUrl === "" || item.favIconUrl === " " || item.favIconUrl === undefined || item.favIconUrl === null
          ? defaultFavIcon
          : item.favIconUrl.replace("http://", "https://")
        : defaultFavIcon;
    const isSelected = item && item.selectedTabs && item.selectedTabs.findIndex((t) => t.id === item.id) > -1 ? true : false;
    return (
      <div
        style={{
          width: tabWidth > 0 ? tabWidth : 250,
          transform: canDrop ? null : `rotateZ(-${2.5}deg)`,
          opacity: type === "tabcard" ? 0.5 : 0.9,
        }}
      >
        <div
          style={
            type == "tabcard"
              ? {
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                  padding: 6,
                  flexGrow: 1,
                  width: "100%",
                  maxWidth: "100%",
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.small,
                  borderRadius: 4,
                  cursor: "grabbing",
                  paddingTop: 8,
                }
              : {
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 4,
                  paddingLeft: 8,
                  marginTop: 6,
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.small,
                  borderRadius: 4,
                  cursor: "grabbing",
                  paddingTop: 8,
                }
          }
        >
          {type == "curtab" ||
            (type == "pasttab" && (
              <DragIndicatorIcon fontSize="small" style={{ color: theme.palette.text.tertiary, marginRight: "6px", opacity: 0.75, cursor: "grab" }} />
            ))}
          {FaviconURL ? <img height={20} mode="fit" src={FaviconURL} width={20} /> : null}
          <div
            style={
              type == "tabcard"
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                    //minWidth: '100%',//'calc(100% - 0px)', //used to be - 80px
                    maxWidth: "calc(100% - 24px)",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                    width: "80%",
                  }
            }
          >
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", width: "95%" }}>
              <Typography
                m={0}
                noWrap
                style={
                  item && item.type == "tabcard"
                    ? {
                        lineHeight: 1.25,
                        fontSize: 14,
                        width: "100%",
                        marginBottom: "-3px",
                        fontWeight: 500,
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        color: theme.palette.text.primary,
                      }
                    : type == "curtab" || type == "pasttab"
                      ? {
                          lineHeight: 1,
                          fontSize: 13,
                          marginBottom: "-3px",
                          fontWeight: 500,
                          color: theme.palette.text.primary,
                          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        }
                      : {
                          lineHeight: 1,
                          fontSize: 12,
                          marginBottom: "-2px",
                          fontWeight: 500,
                        }
                }
                variant="subtitle2"
              >
                {item.title}
              </Typography>
              <Typography
                m={0}
                noWrap
                style={
                  item && item.type == "tabcard"
                    ? {
                        color: theme.palette.text.secondary,
                        lineHeight: 1.25,
                        width: "100%",
                        margin: 0,
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }
                    : {
                        color: theme.palette.text.secondary,
                        lineHeight: 1,
                        width: "100%",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                      }
                }
                variant="caption"
              >
                {item.url}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

TabsDragPreview.propTypes = {
  tabs: PropTypes.array,
};

export default TabsDragPreview;
