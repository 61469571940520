/* eslint-disable react/no-multi-comp */
import React, { useRef, useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "../DND/ItemTypes";
import useGlobal from "../../GlobalState/Store/Store.js";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";

import WorkspaceMenu from "../Menus/WorkspaceMenu.js";

import { Typography, IconButton, ClickAwayListener, InputBase, Box } from "@mui/material";

import MoreVert from "@mui/icons-material/MoreVert";

import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";

//motion
import { motion, useMotionValue } from "framer-motion"; //useInvertedScale

import update from "immutability-helper";
import useWorkspaces from "../../Hooks/useWorkspaces.js";
import { mutate } from "swr";
import Skeleton from "@mui/material/Skeleton";
import EmojiComponent from "../Emojis/EmojiComponent";
import RegularInput from "../Inputs/RegularInput.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.text.tertiary}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.palette.text.secondary,
    "&:focus": {
      border: `2px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.primary,
    },
  },
}));

const AutoFocusTextField = (props) => {
  const inputRef = React.useRef();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <RegularInput inputRef={inputRef} {...props} />;
};

const WorkspaceListItem = (props) => {
  const theme = useTheme();
  const id = props.id;
  const index = props.index;
  const moveWorkspace = props.moveWorkspace;

  const SaveWorkspaceOrder = props.SaveWorkspaceOrder;

  const initWorkspaces = props.initWorkspaces;
  const handleClickSelectWorkspace = props.handleClickSelectWorkspace;
  const workspace = props.workspace;
  const pathnames = props.pathnames;

  const workspaceMenuAnchorEl = props.workspaceMenuAnchorEl;
  const handleWorkspaceMenuClose = props.handleWorkspaceMenuClose;
  const openDeleteWorkspaceDialog = props.openDeleteWorkspaceDialog;
  const handleWorkspaceMenuClick = props.handleWorkspaceMenuClick;
  const clickPosition = props.clickPosition;
  const workspaceOrder = props.workspaceOrder;
  const dragActive = props.dragActive;
  const setDragActive = props.setDragActive;

  const openEmojiMenu = props.openEmojiMenu;

  const [globalState, globalActions] = useGlobal();
  const { workspacesData } = useWorkspaces(globalState.isAuth);

  const lastWorkspace = workspacesData && workspacesData.workspaces && workspacesData.workspaces.length > 1 ? false : true;

  const ref = useRef(null);
  //workspace name editing
  // const [refCallback, elem] = useDoubleClick(DoubleClickEdit); //edit on double click
  const [editWorkSpaceName, setEditWorkspaceName] = useState(""); // change these three to one
  const [newWorkspaceName, setNewWorkspaceName] = useState(""); // change these three to one
  const [newNameReq, setNewNameReq] = useState({
    isLoading: false,
    isError: null,
  }); // change these three to one
  const editTitle = newWorkspaceName !== null && editWorkSpaceName ? true : false;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.WORKSPACELISTITEM,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isReadytoDrop: monitor.isReadytoDrop,
    }),
    hover(item, monitor) {
      // isHovered: monitor.isOver()
      // console.log(isHovered)
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveWorkspace(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item) {
      const targetIndex = item.index;
      const curIndex = initWorkspaces.findIndex((w) => w._id === item.id);

      var persistActiveIndex = workspaceOrder.findIndex((ws) => ws._id === globalState.activeWorkspace._id);
      var persistActiveWS = workspaceOrder.find((ws) => ws._id === globalState.activeWorkspace._id);
      //console.log(persistActiveIndex)
      globalActions.saveActiveWorkspaceIndex(persistActiveIndex);
      localStorage.setItem("activeWorkspaceIndex", persistActiveIndex);
      globalActions.saveActiveWorkspace(persistActiveWS);
      //globalActions.saveActiveWorkspaceIndex(persistActiveIndex)
      // globalActions.saveActiveWorkspace(workspace)
      //handleClickSelectWorkspace(index, workspace)
      setDragActive(false);
      SaveWorkspaceOrder(targetIndex, curIndex, workspace);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.WORKSPACELISTITEM, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin() {
      //hideIcon()
      //setHover(false)
      setDragActive(true);
    },
    end() {
      // const targetIndex = item.index
      // const curIndex = globalState.workspaces.findIndex((w, i) => w._id === item.id)
      //
      // var persistActiveIndex = workspaceOrder.findIndex(ws => ws._id === globalState.activeWorkspace._id)
      // var persistActiveWS = workspaceOrder.find(ws => ws._id === globalState.activeWorkspace._id)
      // //console.log(persistActiveIndex)
      // globalActions.saveActiveWorkspaceIndex(persistActiveIndex)
      // localStorage.setItem("activeWorkspaceIndex", persistActiveIndex)
      // globalActions.saveActiveWorkspace(persistActiveWS)
      // //globalActions.saveActiveWorkspaceIndex(persistActiveIndex)
      // // globalActions.saveActiveWorkspace(workspace)
      // //handleClickSelectWorkspace(index, workspace)
      setDragActive(false);
      // SaveWorkspaceOrder(targetIndex, curIndex, workspace)
    },
    canDrag() {
      return !editTitle;
    },
  });

  //drag(drop(ref))

  useEffect(() => {
    drag(drop(ref));
    return () => {
      //cleanup
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  //EDITING WORKSPACE NAME
  //const workspaceInputRef = React.useRef(null)
  const updateWorkspaceName = async (event, workspaceId, newName) => {
    // console.log({workspaceId, newName}, workspace)

    if (newName == workspace.name || newName == null || newName == "" || newName == " " || !workspace || !workspaceId) {
      console.log("not a valid rename");
      resetEditName();
      setHover(false);
    } else {
      //update local state immediately

      const workspaceIndex = workspacesData.workspaces.findIndex((w) => w._id == workspaceId);
      const newData = workspaceIndex > -1 ? update(workspacesData, { workspaces: { [workspaceIndex]: { name: { $set: newName } } } }) : null;

      if (workspaceIndex > -1) {
        mutate("/workspaces", { ...workspacesData, workspaces: newData.workspaces }, false);
        resetEditName();
      }

      // send a request to the API to update the source
      try {
        await axios.post("/workspaces/update", { workspaceId: workspaceId, newName: newName });

        resetEditName();
      } catch (error) {
        // Handle an error while updating the user here
      }
      // trigger a revalidation (refetch) to make sure our local data is correct
      mutate("/workspaces");
    }
  };
  const setEditWorkspace = (workspace) => {
    setEditWorkspaceName(true);
    setNewWorkspaceName(workspace.name);
  };
  const resetEditName = () => {
    setEditWorkspaceName(false);
    setNewWorkspaceName("");
  };
  const handleNewWorkspaceName = (e) => {
    setNewWorkspaceName(e.target.value);
  };
  const handleWorkspaceNameClickAway = (event, index, workspace, newName) => {
    updateWorkspaceName(workspace, newName);
  };
  const hanldeKeyPress = (event, index, workspace) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log("key " + charCode + " pressed")
    if (charCode == 13) {
      //console.log("Enter key pressed")
      //if enter is pressed save name
      //handleWorkspaceNameClickAway(index, workspace, event.target.value)
      updateWorkspaceName(workspace, newWorkspaceName);
    }
  };

  //FOR FRAMER MOTION
  const scaleX = useMotionValue(0);
  const scaleY = useMotionValue(0);
  const dragOriginY = useMotionValue(0);
  //const dragOriginX = useMotionValue(0);
  const onTop = { zIndex: 1 };
  const flat = {
    zIndex: 0,
    transition: { delay: 0.3 },
  };
  // const inverted = useInvertedScale({ scaleX, scaleY })

  //styling
  const isSelected = globalState.activeWorkspaceIndex == index && dragActive === false && pathnames[0] !== "account"; //&& dragActive === false
  const isActive = dragActive === workspace._id ? true : false; //: isSelected ? true :
  const [hover, setHover] = useState(false);
  const isHover = hover && isActive === false && canDrop === false; //!isSelected && && canDrop === false
  //const opacity = isDragging ? 0.1 : isHover ? 1 : 0.5
  //const color = activeWorkspace === index && pathnames[0] !== "account" ? '#3D5AFE' : 'inherit'

  //console.log(index + " - active: " + isActive)
  //console.log(index + " - selected: " + isSelected)
  //console.log(globalState.activeWorkspaceIndex)
  //console.log(index)

  var subscription = globalState.userTrial.subscription;

  const disabled = (workspaceOrder.length > 3 && index >= 3 && !subscription && !globalState.userTrial.trialing) || globalState.emojiPicker.open ? true : false; //workspaceOrder.length > 5 && subscription === false ? true : false

  const [emojiHovered, setEmojiHovered] = useState(false);
  const toggleHoverEmoji = (boolean) => {
    setEmojiHovered(boolean);
  };
  const handleEmojiClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log(event.pageY)
    //console.log(workspace)
    //console.log("window height: " + window.innerHeight)
    //console.log("target top: " + event.pageY)
    var spaceToBottom = window.innerHeight - event.pageY;
    //console.log(spaceToBottom)
    if (spaceToBottom < 460) {
      //console.log("Not a lot of bottom space")
    }

    openEmojiMenu(event, workspace);
  };

  //SCROLL TO ACTIVE WORKSPACE ITEM ON LOAD
  const workspaceListItemRef = useRef(null);
  // const executeScroll = () => workspaceListItemRef.current.scrollIntoView()

  // function ScrollToItemOnMount() {
  //   useEffect(() => {
  //     if(globalState.activeWorkspace && globalState.activeWorkspace._id == workspace._id && workspaceListItemRef && workspaceListItemRef.current) {
  //       //console.log("SCROLL TO ME " + workspace.name)
  //       //executeScroll()
  //     }
  //   }, []);

  //   return null;
  // }
  //then paste this in component -> <ScrollToItemOnMount />

  const handleWorkspaceMiddleClick = (event, workspace) => {
    console.log({ event });
    if (event.button === 1) {
      //middle mouse button click
      event.metaKey = true;
      // eslint-disable-next-line no-undef
      let baseDomain = process.env.NODE_ENV == "development" ? "http://localhost:3001" : "https://www.app.partizion.io";
      let workspaceURL = `${baseDomain}/${workspace._id}`;
      window.open(workspaceURL);
    }
  };

  return (
    <div id="workspacebutton" ref={workspaceListItemRef} style={disabled ? { pointerEvents: "none", opacity: 0.4 } : { width: "99%" }}>
      <motion.div
        animate={isDragging ? onTop : flat}
        dragElastic={10}
        dragOriginY={dragOriginY}
        initial={false}
        layout
        layoutTransition={{ type: "spring", damping: 1000, stiffness: 1000, mass: 1, velocity: 1 }}
        ref={ref}
        style={{
          // scaleX,
          // scaleY,
          zIndex: isDragging ? 1 : 0,
          position: "relative",
          // scale: isDragging ? 1.03 : 1,
          WebkitTouchCallout: "none" /* iOS Safari */,
          WebkitUserSelect: "none" /* Safari */,
          KhtmlUserSelect: "none" /* Konqueror HTML */,
          MozUserSelect: "none" /* Old versions of Firefox */,
          msUserSelect: "none" /* Internet Explorer/Edge */,
          userSelect: "none",
          backgroundColor: "action.selected",
        }}
      >
        <Box
          onClick={() => (editTitle ? null : handleClickSelectWorkspace(index, workspace))}
          onContextMenu={(e) => handleWorkspaceMenuClick(e, index, workspace, "rightclick")}
          onMouseDown={(e) => handleWorkspaceMiddleClick(e, workspace)}
          onMouseLeave={() => (editTitle ? null : setHover(false))}
          onMouseOver={() => (editTitle ? null : setHover(true))}
          selected={globalState.activeWorkspace === index && isDragging === false && pathnames[0] !== "account" && isOver === false}
          sx={{
            width: "95%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "4px",
            height: editTitle ? "45px" : "32px",
            color: isSelected ? "text.primary" : "text.secondary", //#3D5AFE'
            backgroundColor: isSelected || isDragging || isActive ? "action.selected" : isHover ? "action.hover" : "transparent", //blue -> rgba(61, 90, 254, 0.1) //isSelected || isActive ? 'rgba(216, 216, 216, 1)' : isHover  ? 'rgba(216, 216, 216, 0.5)' : 'rgba(216, 216, 216, 0)', //216,216,216
            zIndex: isDragging ? "999" : "0",
            opacity: isDragging ? 0 : 1,
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 2,
            paddingRight: 2,
            marginBottom: 0.5,
            // paddingLeft: isHover && !editTitle || isDragging && !editTitle ? 0 : 18
          }}
        >
          {newNameReq.isLoading ? (
            <Skeleton animation="wave" height={16} style={{ marginBottom: 1, backgroundColor: theme.palette.action.selected }} variant="rounded" width="100%" />
          ) : editTitle ? (
            <div style={{ width: "100%" }}>
              <AutoFocusTextField
                edit={editWorkSpaceName}
                fullWidth
                key={workspace._id}
                newName={newWorkspaceName}
                objectId={workspace._id}
                originalName={workspace && workspace.name ? workspace.name : null}
                renameFunction={updateWorkspaceName}
                selectRangeOnFocus
                setEdit={setEditWorkspaceName}
                setNewName={setNewWorkspaceName}
                style={{
                  fontSize: 14,
                }}
              />
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textOverflow: "ellipsis", overflow: "hidden", width: "94%" }}>
              <div
                onClick={(e) => handleEmojiClicked(e)}
                onMouseEnter={() => toggleHoverEmoji(true)}
                onMouseLeave={() => toggleHoverEmoji(false)}
                style={{
                  opacity: isSelected ? 1 : 1,
                  width: 24,
                  height: 24,
                  minWidth: 24,
                  minHeight: 24,
                  backgroundColor:
                    workspace.icon && workspace.icon.id
                      ? emojiHovered
                        ? isSelected
                          ? "rgba(110, 110, 110, 0.2)"
                          : "rgba(110, 110, 110, 0.2)"
                        : "rgba(110, 110, 110, 0)"
                      : emojiHovered
                      ? "rgba(110, 110, 110, 0.2)"
                      : "rgba(110, 110, 110, 0)",
                  marginRight: 6,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {
                  //EMOJI
                  workspace.icon && workspace.icon.emoji && workspace.icon.emoji.emoji ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "vertical",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 24,
                        maxHeight: 24,
                        minWidth: 24,
                        maxWidth: 24,
                        //paddingTop: 4,
                      }}
                    >
                      <EmojiComponent isWorkspaceListItem size={18} symbol={workspace.icon.emoji.emoji} />
                    </div>
                  ) : //COLOR
                  workspace.icon && workspace.icon.colorIcon && workspace.icon.colorIcon.hex ? (
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: "rgba(110, 110, 110, 0)",
                        borderRadius: "2px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: 10, height: 10, backgroundColor: workspace.icon.colorIcon.hex, borderRadius: "100%" }} />
                    </div>
                  ) : (
                    //<div style={{width: 12, height: 12, borderRadius: '100%', backgroundColor: '#888', opacity: isSelected ? 0.75 : isHover || emojiHovered ? 0.25 : 0.15}} />
                    <FolderOpenRoundedIcon fontSize="small" style={{ opacity: 0.5 }} />
                  )
                }
              </div>
              <Typography style={{ textOverflow: "ellipsis", overflow: "hidden" }} variant="body2">
                {" "}
                {workspace.name}{" "}
              </Typography>
            </div>
          )}
          {isHover && !isDragging && !dragActive && !editTitle ? (
            <div style={{ position: "absolute", right: "8px" }}>
              <WorkspaceMenu
                anchor={workspaceMenuAnchorEl}
                clickPosition={clickPosition}
                handleClick={handleWorkspaceMenuClick}
                handleClose={handleWorkspaceMenuClose}
                lastWorkspace={lastWorkspace}
                openDelete={openDeleteWorkspaceDialog}
                setEditWorkspace={setEditWorkspace}
                workspace={workspace}
              />
              <IconButton
                aria-label="workspaceMenu"
                onClick={(event) => handleWorkspaceMenuClick(event, index, workspace)}
                size="small"
                style={{
                  margin: 3,
                  borderRadius: 4,
                  padding: 3,
                }}
              >
                <MoreVert fontSize="inherit" />
              </IconButton>
            </div>
          ) : null}
        </Box>
      </motion.div>
    </div>
  );
};

// export default WorkspaceListItem
const MotionListitem = React.memo(WorkspaceListItem);
export default MotionListitem;
