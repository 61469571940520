/*global chrome*/
import React, {} from 'react'
import { useTheme } from '@mui/material/styles';

import {
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';



import CurTabsGrid from './Collections/tabs/CurTabsGrid.js';
import SelectLinkCard from './SelectLinkCard.js';

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

function WindowComponent(props){
  const theme = useTheme()
  const window = props.window
  const index = props.index
  const isCreateCollection = props.isCreateCollection
  //const [tabs, setTabs] = useState([])
  const tabs = window ? FilterOutDashboard(window) : []

  const selected = props.selected
  const setSelected = props.setSelected
  const isSelected = window && window.id && selected && selected.length > 0 && !!selected.find(w => w.id == window.id) && selected.find(w => w.id == window.id).isSelected ? true : false
  //const [activeWindow, setActiveWindow] = useState(false)

  //console.log("window " + window.id + " is " + isSelected)
  //load tabs on render from //chrome
  //console.log(window)

  function FilterOutDashboard(window){
    return window.tabs.filter((tab, index) => {
      let isDashboard  = tab.url.match(/https:\/\/(www\.)?app.partizion\.io/gm) && index == 0 && tab.pinned ? true : false
      return !isDashboard
    });
  }

  // useEffect(() => {
  //   console.log(window)
  //   if(window && window.tabs && window.tabs.length > 0){
  //     setTabs(FilterOutDashboard(window))
  //   } else {
  //     setTabs([])
  //   }
  // }, [window])

  //SELECTING ALL
  function selectAllTabs(){
    setSelected(window.id, null, FilterOutDashboard(window))
  }

  const labledIndex = window && props.originalwindoworder && props.originalwindoworder.findIndex(w => w.id == window.id) >= 0 ?
  props.originalwindoworder.findIndex(w => w.id == window.id) + 1 
  : index + 1
  //props.originalwindoworder && props.originalwindoworder.length > 1 ? props.originalwindoworder.findIndex(w => w.id == window.id)

  return(
      <div
          style={{
            cursor: isCreateCollection ? "default" : "pointer",
            borderRadius: '4px',
            marginBottom: '10px',
            padding: 8,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.collection.default,
            boxShadow: theme.palette.shadows.small,
          }}
      >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
              <div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                  >
                      <Typography
                          style={{color: '#6E6E6E'}}
                          variant="body2"
                      >
                          {' '}
                          Window
                          {' '}
                          {labledIndex}
                      </Typography>
                      {
              window && window.focused ?
                  <Typography
                      style={{backgroundColor: 'rgba(61, 90, 254, 0.20)', color: 'rgba(61, 90, 254, 0.85)', fontSize: 12, marginLeft: '4px', paddingLeft: '3px', paddingRight: '3px', borderRadius: '2px'}}
                      variant="body2"
                  >
                      {' '}
                      Active
                      {' '}
                  </Typography>
              :
              null
            }
                  </div>
                  {
            tabs && tabs.length > 0 && (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isSelected}
                        color="primary"
                        name="checkedB"
                        onChange={selectAllTabs}
                        size="small"
                    />
                }
                label={tabs && tabs.length ? `Select all ${tabs.length} tabs` : "Select all"}
                style={{marginLeft: 2, marginTop: 6, marginBottom: -5, color: '#6E6E6E'}}
            />
            )
          }
              </div>
              <div />
          </div>
          <div>
              {
          isCreateCollection ?
              tabs && tabs.length > 0 ?
                tabs.map((tab, index) =>(
                    <SelectLinkCard
                        isCreateCollection
                        key={index}
                        selected={selected}
                        setSelected={setSelected}
                        showHover
                        tab={tab}
                        window={window}
                    />
                ))
                :
                <div style={{width: '100%', minHeight: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography
                        align="center"
                        noWrap
                        style={{ color: '#6E6E6E', marginBottom: 10}}
                        variant="body2"
                    >
                        😮&nbsp; No tabs in this window
                    </Typography>
                </div>
          :
                <DndProvider backend={HTML5Backend}>
                    <div>
                        <CurTabsGrid tabs={tabs} />
                    </div>
                </DndProvider>

        }
          </div>
      </div>
  )
}

export default WindowComponent

//{tabs.length} tab{tabs.length > 1 ? 's' : null}


//Removed from window so tou cannot select windows
// onClick={() => setSelected(window.id, null)}


//different link linkCardSelected
// isCreateCollection ?
// <SelectLinkCard key={index} window={window} tab={tab} showHover={true} selected={selected} setSelected={setSelected} isCreateCollection={true}/>
// :
// <LinkCard key={index} window={window} tab={tab} showHover={true} selected={selected} setSelected={setSelected} isCreateCollection={false}/>
