export default {
  WORKSPACELISTITEM: 'workspacelistitem',
  COLLECTIONCARD: 'collectioncard',
  CARD: 'card',
  CURTAB: 'curtab',
  TABCARD: 'tabcard',
  COLLECTIONTAB: 'collectiontab',
  CURWINDOW: 'curwindow',
  COLLECTIONSCONTAINER: 'collectionscontainer',
  PASTSESSION: 'pastsession',
  PASTTAB: 'pasttab',
  KANBANCOLUMN: 'kanbancolumn'
}
