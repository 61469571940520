import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import { ListItem, ListItemIcon, Typography, Divider, InputBase, MenuItem, Box, Popover } from "@mui/material";

import { DefaultMenu } from "./DefaultMenu.js";

import useGlobal from "../../GlobalState/Store/Store.js";

import { DeleteOutlineRounded, SubdirectoryArrowRightRounded } from "@mui/icons-material";

import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";

import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";

import axios from "axios";

import { mutate } from "swr";
import update from "immutability-helper";
import MoveTabMenu from "./MoveTabMenu.js";
import PlainSnackBar from "../Notistack/PlainSnackBar.js";
import { useSnackbar } from "notistack";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: theme.palette.text.secondary,
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

export default function LinkMenu(props) {
  const collectionsData = props.collectionsData;
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = props.handleClick;
  const handleCloseMenu = props.handleClose;

  const handleClose = (event) => {
    props.handleCloseMoveTabMenu(event);
    handleCloseMenu(event);
  };
  //const anchorEl = props.anchor
  const [anchorEl, setAnchorEl] = useState(props.anchor);
  const collection = props.collection;
  const DeleteTab = props.DeleteTab;
  const tab = props.tab;
  const setEditTabTitle = props.setEditTabTitle;

  const [globalState, globalActions] = useGlobal();

  const ChangeTabName = props.ChangeTabName;
  const ChangeTabURL = props.ChangeTabURL;

  const resetOldTabOrder = props.resetOldOrder;

  const [moveTabsAnchor, setMoveTabsAnchor] = useState(null);

  const pinTab = async (e, boolean) => {
    e.preventDefault();
    e.stopPropagation();

    tab.pinned = boolean;

    const data = {
      collectionId: collection._id,
      tab: tab,
    };

    const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;
    const tabIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].tabs.findIndex((t) => t._id == tab._id) : -1;

    //collections: {[collectionIndex]: {tabs: {$set: collectionsData.collections[collectionIndex].tabs.sort(SortTabs) }}},
    // tabs: {
    //   $set: collectionsData.collections[collectionIndex].tabs.sort(SortTabs)
    // }
    const newData =
      tabIndex > -1
        ? update(collectionsData, {
            collections: {
              [collectionIndex]: {
                tabs: {
                  [tabIndex]: { pinned: { $set: boolean } },
                },
              },
            },
          })
        : null;

    //console.log(tabIndex, newData.collections[collectionIndex].tabs)
    if (tabIndex > -1) {
      mutate(
        [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
        newData,
        false
      );

      // if(params && params.collectionid){
      //   mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null], newData[collectionIndex], false)
      // }
      mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);

      handleClose();
      //resetOldTabOrder()
    }

    // send a request to the API to update the source
    try {
      await axios.post("/collections/pintab", data);
      resetOldTabOrder();
      handleClose();
    } catch (error) {
      // Handle an error while updating the user here
    }
    // trigger a revalidation (refetch) to make sure our local data is correct
    mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
    // if(params && params.collectionid) mutate([`/collections/getbyid`, globalState.activeWorkspace._id, params && params.collectionid ? params.collectionid : null])
    mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
  };

  const removeTab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    DeleteTab();
  };

  //Editing tab properties
  // function extractHostname(url) {
  //   let hostname;
  //   let urlEnding;
  //   let scheme;
  //
  //   if(url && url !== undefined){
  //     var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 ? true : false
  //
  //     // if(isUrl){
  //       let domain
  //       //---- OLD FULL REMOVAL METHOD ----
  //       //find & remove protocol (http, ftp, etc.) and get hostname
  //       // if (url.indexOf("//") > -1) {
  //       //   hostname = url.split('/')[2];
  //       // }
  //       // else {
  //       //   //console.log("found / ", hostname)
  //       //   hostname = url.split('/')[0];
  //       // }
  //       // //removes a remaing http or www.
  //       // hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
  //       //---------------------//
  //       if(url.indexOf("https://") > -1){
  //         scheme = "https://"
  //         domain = url.split('https://')[1];
  //       }
  //       else if(url.indexOf("http://") > -1){
  //         scheme = "http://"
  //         domain = url.split('http://')[1];
  //       }
  //       else {
  //         domain = url
  //       }
  //
  //       if(hostname && url.indexOf("www") > -1){
  //         domain = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")//.split('/')[0];
  //         hostname = domain.split("/")[0] + "/"
  //       } else {
  //         hostname = domain
  //       }
  //
  //       function splitOnce(s, on) {
  //          let [first, ...rest] = s.split(on)
  //          return [first, rest.length > 0? rest.join(on) : null]
  //       }
  //       urlEnding = splitOnce(domain, "/")[1] //domain.split("/")[1]
  //
  //     // } else {
  //     //   return url
  //     // }
  //   }
  //   return { scheme, hostname, urlEnding }
  // }
  // const { scheme, hostname, urlEnding } = tab && tab.url ? extractHostname(tab.url) : extractHostname('https://example.com/ending')
  // function constructURL(ending){
  //
  //   const newURLEnding = ending.replace(/\s/g,'') //trims all spaces //"/" +
  //   //return tab.url.replace(/\/[^\/]*$/, newURLEnding)
  //   const domain = hostname && hostname != null && hostname.replace(/\s/g, '').length ? hostname : tab && tab.url ? new URL(tab.url).hostname : ""
  //   const s = scheme ? scheme : 'https://'
  //
  //
  //   if(!urlEnding && tab.url){
  //     const includesSlash = tab.url.charAt(tab.url.length -1) == "/"
  //     if(includesSlash){
  //       return tab.url + newURLEnding
  //     } else {
  //       return tab.url + "/" + newURLEnding
  //     }
  //   } else {
  //     return s + domain + newURLEnding
  //   }
  //
  // }

  const [tabTitle, setTabTitle] = useState(tab.title);
  const [URLpath, setURLPath] = useState(tab.url);

  const handleKeyPressTabTitle = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //setTabTitle(event.target.value)

    if (charCode == 13) {
      //console.log("Enter key pressed")
      //if enter is pressed save name
      setEditTabTitle(false);
      //ChangeTabName(event.target.value)
      //event.target.blur() //callign blur will trigger an update
      handleTabDetailsClosed();
    } else if (charCode === 27 || charCode === 38 || charCode === 40) {
      // keep current escape, and arrow key behaviour
    } else {
      //prevent menu from focusing menu items
      event.stopPropagation();
    }
  };

  const open = Boolean(anchorEl);

  const handleTabDetailsClosed = (event) => {
    var charCode = event ? event.which || event.charCode || event.keyCode : "";

    if (charCode == 27) {
      //ESC Key
      handleClose();
      return;
    }

    const newURLEnding = URLpath ? URLpath.replace(/\s/g, "") : tab.url; //trims all spaces
    if (tabTitle && tabTitle != null && tabTitle != "" && tabTitle != tab.title) {
      ChangeTabName(tabTitle, newURLEnding);
    }
    if (newURLEnding && newURLEnding != tab.url) {
      ChangeTabURL(newURLEnding, tabTitle);
    }
    handleClose();
  };

  //COPY URL
  const copyURLToClipBoard = async () => {
    //let urls = ""
    try {
      await navigator.clipboard.writeText(tab.url);
      let message = "Copied link to clipboard";
      // eslint-disable-next-line no-inner-declarations
      function success() {
        handleClose();
        enqueueSnackbar(message, {
          content: (key, message) => <PlainSnackBar id={key} message={message} />,
        });
      }
      return success();
      //setCopySuccess('Copied!');
    } catch (err) {
      return false;
      //setCopySuccess('Failed to copy!');
    }
  };

  //MOVING A TAB
  const moveTabsOpen = Boolean(props.moveTabsAnchorEl);
  const [options, setOptions] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const loading = moveTabsOpen && options.length === 0;
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await axios.get("/collections/allcollections");
      //console.log(res)

      let workspaces = res && res.data && res.data.workspaces ? res.data.workspaces : -1;
      let collections =
        workspaces && workspaces != -1
          ? workspaces.reduce((merged, block) => {
              if (block && block.collections && block.collections.length > 0) merged.push(...block.collections.filter((c) => c._id != props.collectionId));
              return merged;
            }, [])
          : [];
      //let collections = workspaces.map(w => w.collections)
      //console.log(collections)
      if (active) {
        setWorkspaces(workspaces);
        setOptions(collections); //(Object.keys(workspaces).map((key) => workspaces[key].item[0]));
        const ws = workspaces.map((w) => {
          return { title: w.name, _id: w._id };
        });
        setAllMatches(collections.concat(ws));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  useEffect(() => {
    if (!moveTabsOpen) {
      setOptions([]);
    }
  }, [moveTabsOpen]);

  return (
    <div>
      <DefaultMenu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        anchorPosition={
          props.clickPosition !== undefined
            ? props.clickPosition.mouseY !== null && props.clickPosition.mouseX !== null
              ? { top: props.clickPosition.mouseY, left: props.clickPosition.mouseX }
              : undefined
            : null
        }
        anchorReference={props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "anchorPosition" : "anchorEl"}
        id="move-tab-menu"
        keepMounted
        onClose={handleTabDetailsClosed}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "left" : "right" }}
      >
        {props.moveTabsMenuOpen ? (
          <div>
            <MoveTabMenu
              activeWorkspace={globalState.activeWorkspace}
              allMatches={allMatches}
              anchorEl={props.moveTabsAnchorEl}
              collectionId={props.collectionId}
              handleClose={props.handleCloseMoveTabMenu}
              handleMoveTabs={props.handleMoveTabs}
              loading={loading}
              moveTabsMenuOpen={props.moveTabsMenuOpen}
              options={options}
              secondClickPosition={props.secondClickPosition}
              workspaces={workspaces}
            />
          </div>
        ) : (
          <div>
            <Box
              sx={{
                outline: "none",
                "&:focus": {
                  outline: "none",
                },
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                marginBottom: -1,
              }}
            >
              <Typography style={{ color: "#6E6E6E" }} variant="caption">
                {" "}
                Tab title{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                outline: "none",
                "&:focus": {
                  outline: "none",
                },
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                paddingBottom: 1,
              }}
            >
              <BootstrapInput
                autoFocus
                fullWidth
                id="tab-title-input"
                label="Tab title"
                maxRows={4}
                multiline
                onChange={(e) => setTabTitle(e.target.value)}
                onFocus={(e) => e.target.setSelectionRange(999999999, 9999999999)}
                onKeyDown={handleKeyPressTabTitle}
                placeholder="Tab title"
                size="small"
                value={tabTitle}
                //onBlur={(e) => ChangeTabName(e.target.value)}

                variant="outlined"
              />
            </Box>
            <div>
              <Box
                sx={{
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                  paddingTop: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  marginBottom: -1,
                }}
              >
                <Typography noWrap style={{ color: "#6E6E6E", maxWidth: "95%" }} variant="caption">
                  {" "}
                  URL{" "}
                </Typography>
              </Box>
              <Box
                sx={{
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <BootstrapInput
                  fullWidth
                  id="tab-url-input"
                  label="link"
                  maxRows={4}
                  multiline
                  onChange={(e) => setURLPath(e.target.value)}
                  onFocus={(e) => e.target.setSelectionRange(999999999, 9999999999)}
                  onKeyDown={handleKeyPressTabTitle}
                  size="small"
                  value={URLpath}
                  //onBlur={(e) => ChangeTabURL(e.target.value)}
                  variant="outlined"
                />
              </Box>
            </div>

            <Divider style={{ marginBottom: 8 }} />
            <MenuItem
              button
              disabled={collection.tabs && collection.tabs.length ? false : true}
              onClick={(e) => copyURLToClipBoard(e)}
              style={{ minWidth: 350 }}
            >
              <ListItemIcon>
                <InsertLinkRoundedIcon fontSize="small" style={{ transform: "rotate(-45deg)" }} />
              </ListItemIcon>
              Copy Link
            </MenuItem>
            {tab.pinned ? (
              <MenuItem button onClick={(e) => pinTab(e, false)} selected>
                <ListItemIcon>
                  <PushPinRoundedIcon color="primary" fontSize="small" />
                </ListItemIcon>
                Unpin Tab
              </MenuItem>
            ) : (
              <MenuItem button onClick={(e) => pinTab(e, true)}>
                <ListItemIcon>
                  <PushPinRoundedIcon fontSize="small" />
                </ListItemIcon>
                Pin Tab
              </MenuItem>
            )}
            <MenuItem button onClick={props.openMoveTabsMenu}>
              <ListItemIcon>
                <SubdirectoryArrowRightRounded fontSize="small" />
              </ListItemIcon>
              Move Tab
            </MenuItem>
            <Divider />
            <MenuItem button onClick={(e) => removeTab(e)} style={{ color: "#E91E43", marginTop: 8 }}>
              <ListItemIcon>
                <DeleteOutlineRounded fontSize="small" style={{ color: "#E91E43" }} />
              </ListItemIcon>
              Remove Tab from Collection
            </MenuItem>
          </div>
        )}
      </DefaultMenu>
    </div>
  );
}

// <MenuItem component={RouterLink} to="/">Edit Title</MenuItem>
// <Divider/>
