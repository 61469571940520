/*global chrome*/
import React, {useEffect, useRef} from 'react'
import useGlobal from '../GlobalState/Store/Store';

import { useTheme } from '@mui/material/styles';

import {
  Typography,
  IconButton,
  Tooltip,
  Paper,
  Divider} from '@mui/material';

import CloseRounded from '@mui/icons-material/CloseRounded';
import CallMadeRounded from '@mui/icons-material/CallMadeRounded';


import CurTabsGrid from './Collections/tabs/CurTabsGrid.js';

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useDrag } from 'react-dnd'
import ItemTypes from './DND/ItemTypes'


import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ConfirmCloseWindowDialog from './Dialogs/ConfirmCloseWindowDialog.js';

//FOR browser extension APIs
const browser = (function () {
  return window.msBrowser ||
    window.browser ||
    window.chrome;
})();

function CurWindowComponent(props){
  const theme = useTheme();
  const ref = useRef(null)
  const [globalState, globalActions] = useGlobal();
  const window = props.window
  const allWindows = props.allWindows
  const index = props.index
  const isCreateCollection = props.isCreateCollection
  //const [tabs, setTabs] = useState([])
  const tabs = window.tabs
  const selected = props.selected
  const setSelected = props.setSelected
  const isSelected = false //selected.find(w => w.id == window.id) && selected.find(w => w.id == window.id).isSelected ? true : false
  //const [activeWindow, setActiveWindow] = useState(false)
  const id = window.id
  const SelectTabs = props.SelectTabs
  const selectedTabs = props.selectedTabs

  //for Dragging
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.CURWINDOW,
      id,
      index,
      tabs: tabs,
      window: window,
      windows: allWindows
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin(monitor){
      //setInterval(() => {console.log(shiftKeyActive)}, 100)

    },
    end: (dropResult, monitor) => {

    },
    // canDrag(props) {
    //   return !editTitle
    // },

  })

  // drag(ref)
  useEffect(() => {
    drag(ref);
    return () => {
      //cleanup
      drag(null);
    };
  }, [drag, ref]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    
    return () => {
      //cleanup
      preview(null)
    }
  }, []);

  function switchToWindow(e){
    if(globalState.extensionConnection.isConnected){
      globalState.extensionConnection.port.postMessage({type: "switchToWindow", windowId: window.id})
    }
  }

  function closeWindow() {
    if(globalState.extensionConnection.isConnected){
      globalState.extensionConnection.port.postMessage({type: "closeWindow", window: window})
    }
  }

  //CONFIRM CLOSE WINDOW
  const [confirmClose, setConfirmClose] = React.useState(false);
  const confirmCloseState = {
    confirmText: "Yes, close this window",
    cancelText: 'Cancel',
    window: window,
    index: index
  }
  const handleOpenConfirmClose = () => {
    setConfirmClose(true);
  };
  const handleCloseConfirmClose = () => {
    //console.log("CANCEL reorder")
    setConfirmClose(false);
  };
  const ConfirmCloseWindow = () => {
    //console.log("SORT REMOVE confirmed")
    closeWindow()
    setConfirmClose(false);
  };






  return(
      <div>

          <Paper
              elevation={0}
              ref={ref}
              style={{cursor: isCreateCollection ? "default" : "pointer", opacity: isDragging ? 0 : 1}}
              sx={
                isSelected ?
                {
                  backgroundColor: theme.palette.collection.hover,
                  borderRadius: '4px',
                  marginBottom: '10px',
                  padding: 1,
                  border: 'solid',
                  borderColor: '#3D5AFE',
                  cursor: 'pointer',
                }
                :
                {
                  backgroundColor: theme.palette.collection.hover,
                  borderRadius: '4px',
                  marginBottom: '10px',
                  padding: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: theme.palette.shadows.small,
                }
              }
          >
              <div 
                  style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                  <div
                      style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                      <DragIndicatorIcon
                          fontSize="small"
                          style={{color: theme.palette.text.tertiary, marginRight: '6px', cursor: 'grab'}}
                      />
                      <div>
                          <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                              }}
                          >
                              <Typography
                                  style={{color: theme.palette.text.secondary}}
                                  variant="body2"
                              >
                                  {' '}
                                  Window
                                  {' '}
                                  {index + 1}
                              </Typography>
                              {
                  window.focused ?
                      <Typography
                          style={{backgroundColor: 'rgba(61, 90, 254, 0.20)', color: 'rgba(61, 90, 254, 0.85)', fontSize: 12, marginLeft: '4px', paddingLeft: '3px', paddingRight: '3px', borderRadius: '2px'}}
                          variant="body2"
                      >
                          {' '}
                          Active
                          {' '}
                      </Typography>
                  :
                  null
                }
                          </div>
                          <div
                              style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                              <Typography
                                  style={{color: theme.palette.text.secondary, fontSize: 12}}
                                  variant="body2"
                              > 
                                  {' '}
                                  { tabs ?  tabs.length > 1 ? tabs.length + " tabs" : tabs.length + " tab" : null}
                              </Typography>
                          </div>
                      </div>
                  </div>
                  <div>
                      {
            window.focused ?
            null
            :
            isCreateCollection ?
              null
              :
              <Tooltip
                  aria-label="add"
                  disableInteractive
                  title="Switch to window"
              >
                  <IconButton
                      aria-label="delete"
                      onClick={(e) => switchToWindow(e)}
                      size="small"
                      sx={{
                        margin: 0.5
                      }}
                  >
                      <CallMadeRounded fontSize="inherit" />
                  </IconButton>
              </Tooltip>
          }
                      {
            isCreateCollection ?
            null
            :
            <Tooltip
                aria-label="add"
                disableInteractive
                title="Close Window"
            >
                <IconButton
                    aria-label="delete"
                    onClick={(e) => handleOpenConfirmClose()}
                    size="small"
                    sx={{
                      margin: 0.5
                    }}
                >
                    <CloseRounded fontSize="inherit" />
                </IconButton>
            </Tooltip>
          }
                  </div>
              </div>
              <div>
                  <DndProvider backend={HTML5Backend}>
                      <div>
                          <CurTabsGrid
                              SelectTabs={SelectTabs}
                              parentWindow={window}
                              selectedTabs={selectedTabs}
                              tabs={tabs}
                          />
                      </div>
                  </DndProvider>
              </div>
          </Paper>
  

          {
      index + 1 !== allWindows.length ?
          <Divider style={{marginBottom: '15px', marginTop: '15px'}} />
      :
      null
    }
          {
      confirmClose ?
          <ConfirmCloseWindowDialog
              confirm={ConfirmCloseWindow}
              handleClose={handleCloseConfirmClose}
              open={confirmClose}
              state={confirmCloseState}
          />
      :
      null
    }
      </div>
  )
}

export default React.memo(CurWindowComponent)
