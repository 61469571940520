import { Button, Divider, Paper, Typography } from "@mui/material";
import Box from "@mui/system/Box";

import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import useGlobal from "../../GlobalState/Store/Store";
import Icon from "@mui/material/Icon";

import useCustomerPortal from "../../Hooks/useCustomerPortal";
import UsedBy from "../../Pages/Common/UsedBy";
import CustomerReviews from "../../Pages/Common/CustomerReviews";
import WhyPay from "../../Pages/Common/WhyPay";
import FAQs from "../../Pages/Common/FAQs";

const PricingCards = ({ theme, loadingCheckout, pricingIds, handleCheckout }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Paper
        style={{
          flexGrow: 1,
          width: "100%",
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
          padding: 24,
        }}
        variant="outlined"
      >
        <Typography color={theme.palette.primary.main} fontWeight={500} variant="overline">
          Monthly
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <Typography color={theme.palette.text.secondary} fontSize={18} style={{ textDecoration: "line-through", marginRight: 4 }} variant="body1">
            $10
          </Typography>
          <Typography color={theme.palette.text.primary} fontSize={24} variant="body1">
            $6
          </Typography>
          <Typography color={theme.palette.text.secondary} fontSize={18} style={{}} variant="body1">
            /mo
          </Typography>
        </div>
        <Typography color={theme.palette.text.secondary} fontSize={12} variant="body1">
          billed as $6 per month
        </Typography>
        <div style={{ marginTop: 32, flexGrow: 1 }}>
          <Typography color="#0DB47F" fontSize={12} variant="body1">
            ✦ BETA pricing (lock in this price for life)
          </Typography>
          <Button
            disabled={loadingCheckout || !pricingIds.proMonthlyId}
            fullWidth
            onClick={() => handleCheckout(pricingIds.proMonthlyId)}
            size="large"
            style={{ marginTop: 8 }}
            variant="contained"
          >
            Get Started
          </Button>
        </div>
      </Paper>
      <Paper
        style={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 24,
        }}
        variant="outlined"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography color={theme.palette.primary.main} fontWeight={500} variant="overline">
            Yearly
          </Typography>
          <div
            style={{
              borderRadius: 4,
              backgroundColor: theme.palette.collection.hover,
              paddingLeft: 6,
              paddingRight: 6,
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            <Typography color={theme.palette.text.secondary} variant="body2">
              Save $40 a year
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <Typography color={theme.palette.text.secondary} fontSize={18} style={{ textDecoration: "line-through", marginRight: 4 }} variant="body1">
            $8
          </Typography>
          <Typography color={theme.palette.text.primary} fontSize={24} variant="body1">
            $5
          </Typography>
          <Typography color={theme.palette.text.secondary} fontSize={18} style={{}} variant="body1">
            /mo
          </Typography>
        </div>
        <Typography color={theme.palette.text.secondary} fontSize={12} variant="body1">
          billed as $60 per year
        </Typography>
        <div style={{ marginTop: 32, flexGrow: 1 }}>
          <Typography color="#0DB47F" fontSize={12} variant="body1">
            ✦ BETA pricing (lock in this price for life)
          </Typography>
          <Button
            disabled={loadingCheckout || !pricingIds.proYearlyId}
            fullWidth
            onClick={() => handleCheckout(pricingIds.proYearlyId)}
            size="large"
            style={{ marginTop: 8 }}
            variant="contained"
          >
            Get Started
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
  },
}));

const Checkout = () => {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();

  const customerEmail = globalState.user.email;

  const [status, setStatus] = useState("none");
  const loadingCheckout = status === "loading";
  const { stripeCustomerId, stripeActiveSubscription, cancelPending, cancelsAt, customerPortalStatus, GoToCustomerPortal } = useCustomerPortal();

  const [pricingIds, setPricingIds] = useState({
    proMonthlyId: null,
    proYearlyId: null,
  });

  //load the pricing config (ids)
  useEffect(() => {
    let isMounted = true;

    const fetchPricingConfig = async () => {
      try {
        const res = await axios.get("/stripe/pricing");
        if (res.status === 200) {
          const { proMonthlyId, proYearlyId } = res.data;
          // console.log({ res, proMonthlyId, proYearlyId });
          if (isMounted) {
            setPricingIds({
              proMonthlyId: proMonthlyId,
              proYearlyId: proYearlyId,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPricingConfig();

    // cancel any future `setData`
    return () => (isMounted = false);
  }, []);

  const handleCheckout = async (priceId) => {
    setStatus("loading");

    try {
      const res = await axios.post("/stripe/create-checkout-session", {
        priceId: priceId,
        customerEmail: customerEmail,
      });
      //   console.log({ res });
      if (res.status === 200) {
        setStatus("none");
        // console.log("Request sent successfully");

        // Fetch the session URL from the response
        const url = res.data.url;

        // Redirect the user to the Stripe Checkout page
        window.location.href = url;
      } else {
        setStatus("error");
        console.log("Request failed");
      }
    } catch (error) {
      setStatus("error");
      console.log("Error occurred during the request", error);
    }
  };

  const features = [
    {
      title: "100% Private",
      description:
        "Your data is your data. We don’t show Ads. There is zero tracking. We don’t — and will never — sell or share your data. We're backed by our users and your privacy and happiness is our priority, not the whims of investors or advertisors.",
      icon: "shield",
    },
    {
      title: "Unlimited cloud-synced sessions",
      description:
        "Upgrade to the most advanced session manager — Locally cached and cloud-synced sessions so that even if you forget to save something or your browser crashes, Partizion makes sure your tabs and sessions are restortable.",
      icon: "history",
    },
    {
      title: "Unlimited collections and tabs",
      description:
        "Enjoy unlimited space with the PRO plan. We'll make sure your tabs, collections, and sessions are always secure and fast. Bypass any future limitations — unlimited forever.",
      icon: "category_rounded",
    },
    {
      title: "Auto-updating collections",
      description: "Collections that auto-save and sync to the cloud. When you open or close tabs, Partizion automatically saves the changes for you.",
      icon: "bolt_rounded",
    },
    {
      title: "Access to all new features",
      description:
        "With the beta PRO plan, you lock in this price for life and get access to all new features: We plan on adding tab groups support, tagging, advanced search, and more. We'll raise the price once we're out of beta.",
      // icon: "star_rounded",
      span: 2,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh",
        height: "100%",
        overflow: "hidden",
        padding: 12,
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: "100%",
          height: "100%",
          minHeight: 0,
          padding: 32,
          paddingTop: 48,
          paddingBottom: 60,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <PlainButton
          component={RouterLink}
          startIcon={<KeyboardBackspace />}
          style={{
            color: theme.palette.text.secondary,
            position: "absolute",
            left: 15,
            top: 15,
            zIndex: 999,
          }}
          to="/"
        >
          Back
        </PlainButton>

        {cancelPending ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: 0,
              padding: 32,
              paddingTop: 48,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 24,
            }}
          >
            <div>
              <Typography
                style={{
                  color:
                    globalState.user.stripeSubscription.status === "past_due" || globalState.user.stripeSubscription.status === "unpaid"
                      ? "#F50057"
                      : theme.palette.text.primary,
                }}
                variant="h5"
              >
                You cancelled your subscription. You have access until {cancelsAt}.
              </Typography>
            </div>
            <Button
              color="neutral"
              disabled={customerPortalStatus === "loading" || !stripeCustomerId}
              onClick={() => GoToCustomerPortal()}
              size="medium"
              variant="contained"
            >
              Renew Subscription
            </Button>
          </div>
        ) : stripeActiveSubscription ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              minHeight: 0,
              padding: 32,
              paddingTop: 48,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 24,
            }}
          >
            <div>
              <Typography
                style={{
                  color:
                    globalState.user.stripeSubscription.status === "past_due" || globalState.user.stripeSubscription.status === "unpaid"
                      ? "#F50057"
                      : theme.palette.text.primary,
                }}
                variant="h5"
              >
                {globalState.user.stripeSubscription.status === "past_due" || globalState.user.stripeSubscription.status === "unpaid"
                  ? "Your invoice is unpaid. Update your payment method."
                  : "You are subscribed to the PRO plan."}
              </Typography>
            </div>
            <Button
              color="neutral"
              disabled={customerPortalStatus === "loading" || !stripeCustomerId}
              onClick={() => GoToCustomerPortal()}
              size="medium"
              variant="outlined"
            >
              Manage Subscription
            </Button>
          </div>
        ) : (
          <div style={{ width: "100%", height: "100%", maxWidth: 800 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 24,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 4,
                }}
              >
                <Typography fontSize={32} fontWeight={600} variant="h3">
                  Partizion
                </Typography>
                <div
                  style={{
                    paddingLeft: 6,
                    paddingTop: 3,
                    paddingRight: 6,
                    paddingBottom: 3,
                    borderRadius: 4,
                    color: "#FFF",
                    backgroundColor: "#3D5AFE",
                    boxShadow: `-2px 2px 0px 0px rgba(61, 90, 254,0.95)`,
                  }}
                >
                  PRO
                </div>
              </div>
              <Typography color={theme.palette.text.secondary} fontSize={18} fontWeight={500} variant="body1">
                Unlock the ultimate browsing companion. Partizion PRO will elevate your browsing experience so that you never lose tabs and can find anything in
                an instant.
              </Typography>
              <Divider style={{ width: "100%" }} />
              <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginBottom: 24, marginTop: 24 }}>
                {features.map((feature) => (
                  <div
                    key={feature.title}
                    style={{ flexBasis: feature?.span === 2 ? "100%" : "50%", display: "flex", flexDirection: "row", alignItems: "start", gap: 8, padding: 16 }}
                  >
                    {!!feature?.icon ? (
                      <Icon style={{ fontSize: 20, marginTop: 2 }}>{feature.icon}</Icon>
                    ) : (
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",

                          fontSize: 20,
                        }}
                      >
                        ✦
                      </div>
                    )}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2 }}>
                      <Typography style={{ fontSize: 16, fontWeight: 500 }} variant="body1">
                        {feature.title}
                      </Typography>
                      <Typography color={theme.palette.text.secondary} variant="body2">
                        {feature.description}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
              {/* <Divider style={{ width: "100%" }} /> */}
              <div
                style={{
                  color: "#099669",
                  backgroundColor: theme.palette.background.paper, //"rgba(238, 255, 248, 0.25)",
                  border: "2px solid #0DB47F",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  flexGrow: 1,
                  width: "100%",
                  fontFamily: "Work sans",
                }}
              >
                <Typography fontSize={14} variant="body1">
                  ✦ We’re working on some special features — In the meantime, we’re offering{" "}
                  <span style={{ fontWeight: "bold" }}>limited time Beta pricing</span>
                </Typography>
              </div>
              <PricingCards handleCheckout={handleCheckout} loadingCheckout={loadingCheckout} pricingIds={pricingIds} theme={theme} />
            </div>
            <UsedBy />
            <CustomerReviews />
            <WhyPay />
            <div>
              <div
                style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", gap: 12, marginTop: 48, marginBottom: 24 }}
              >
                <Typography style={{ fontSize: 32, fontWeight: 600, color: theme.palette.text.primary }} variant="h3">
                  Upgrade now to save
                </Typography>
                <Typography style={{ fontSize: 18, fontWeight: 500, color: theme.palette.text.secondary }} variant="body1">
                  As we launch more features, we’ll be increasing our pricing. Lock in special pricing now.
                </Typography>
              </div>
              <PricingCards handleCheckout={handleCheckout} loadingCheckout={loadingCheckout} pricingIds={pricingIds} theme={theme} />
            </div>
            <FAQs />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Checkout;
