import React, { useState, useEffect } from 'react';
// import useGlobal from '../../GlobalState/Store/Store.js';

import  * as emojiDataJSON  from './unicode-emoji-13.json'

import {matchSorter} from 'match-sorter'
import { useHotkeys } from 'react-hotkeys-hook';

import EmojiSearchbar from './EmojiSearchbar';

//virtualization
import AutoSizer from 'react-virtualized-auto-sizer';

import { VariableSizeGrid } from 'react-window';

import {colors as labelColors} from '../../Utils/StandardColors.js';

import {
Popover,
  Paper,
  Box,
  Button,
  Divider,
  Typography,
  ListItem,
} from '@mui/material';


import SelectEmoji from './SelectEmoji.js'
import { useTheme, styled } from '@mui/material/styles';

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  '&:hover': {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
  
}));


export default function CustomEmojiPicker(props){
  const theme = useTheme()
  const [active, setActive] = useState(0)
  const emojiGridRef = React.createRef();
  
  //MENU STATE
  const open = props.open
  const anchorEl = props.anchor
  const handleOpen = props.handleOpen
  const placement = props.placement || 'bottom-start'
  const handleClose = props.handleClose
  //--- END MENU STATE -----

  //Initialize color
  useEffect(() => {
    if(props.workspace && props.workspace.icon){
      if(props.workspace.icon && props.workspace.icon.colorIcon && props.workspace.icon.colorIcon.hex){
        setValColor(props.workspace.icon.colorIcon)
        setActive(1)
      }
    }
  }, [])

  //FORMAT EMOJI DATA
  function groupEmojis(emojis, groupBy) {
    const allowedFields = ['category', 'group', 'subgroup', 'version'];
    if (!groupBy || typeof groupBy !== 'string' || !allowedFields.includes(groupBy)) {
      return emojis
    } else {
      const groupedEmojis = {};
      for (const emoji of emojis) {
        //console.log(emoji.emoji, emoji.emoji == "🫠")
        if(emoji.emoji == "🫠"){
          //filter out unkown emojis: "🏼"
          console.log(emoji)
        } else {
          if (!(emoji[groupBy] in groupedEmojis)) {
            groupedEmojis[emoji[groupBy]] = {category: toTitleCase(emoji[groupBy]), emojis: []};
          }
          groupedEmojis[emoji[groupBy]].emojis.push(emoji);
        }
        
      }
      return groupedEmojis;
    }
  }
  const toTitleCase = (s) => {
    return s.toUpperCase().replace(/([-_][a-z])/ig, ($1) => {
      return $1
        .replace('-', ' AND ')
        .replace('_', ' AND ');
    });
  };
  const [emojiData, setEmojiData] = useState([])
  const [emojiSearchArray, setEmojiSearchArray] = useState([]) //used to as one big [] to easily search through, const so it can search every emoji
  const [emojiStateArray, setEmojiStateArray] = useState([]) //used to as one big [] that is filtered with a search query, so that emoji's can be selected / highlighted
  const [emojiDataMatches, setEmojiDataMatches] = useState([])
  useEffect(() => {
    if(emojiDataJSON && emojiDataJSON.default && emojiDataJSON.default.emojis){
      const emojiObject = groupEmojis(emojiDataJSON.default.emojis, "category")

      
      let oneDstate = []
      let twoDstate = []
      var size = Object.keys(emojiObject).length;
      let count = 0
      for (var key in emojiObject){
        //create 2D array of emojis
        // const numCols = colCount
        // const oneD = emojiObject[key].emojis
        // const twoD = [];
        // while(oneD.length) twoD.push(oneD.splice(0, numCols));
        // emojiObject[key].emojis = twoD

        // make one stringed array so it can be virtualized
        twoDstate.push({emoji: "TITLE", title: emojiObject[key].category, keywords: [], description: ""})
        oneDstate.push({emoji: "TITLE", title: emojiObject[key].category, keywords: [], description: ""}) //push the category title as an object
        emojiObject[key].emojis.forEach(element => {
          oneDstate.push(element)
        }) // push all the emojis after that <- 1D
        while(emojiObject[key].emojis.length) twoDstate.push(emojiObject[key].emojis.splice(0, colCount));

        //state.push(emojiObject[key])
        count ++
        if(count == size){
          setEmojiSearchArray(oneDstate)
          setEmojiStateArray(oneDstate)
          setEmojiData(twoDstate)
          setEmojiDataMatches(twoDstate)
        }
      }
      
    }
  }, [emojiDataJSON])


  function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : null;
    }
  const [valColor, setValColor] = useState(null) //labelColors[6]
  const handleChangeValueColor = (color) => {
    //console.log({color})
    setValColor(color)
    // setWorkspace({
    //   ...workspace,
    //   emoji: {},
    //   color: valColor
    // })
    props.chooseEmoji(null, color)
  }

  //SEARCHING
  const convertTo2D = (array) => {
    var twoDstate = []
    while(array.length) twoDstate.push(array.splice(0, colCount));
    return twoDstate
  }
  const handleChangeQuery = (e) => {
    const query = e ? e.target.value : ''
    //console.log(windows)
    //&& charCode != 38 && charCode != 40
    //console.log({emojiSearchArray})
    const trimmed = query.trim()
    if(trimmed && trimmed != null && trimmed != "" && trimmed.length && trimmed.length > 0 ){
      const keys = ['description', 'keywords'] //['emojis.*.description', 'emojis.*.keywords']

      const splitQuery = query.split(" ").filter(Boolean)
      //console.log({splitQuery})
      const trimmed = query.trim()

      const tempMatches = matchSorter(emojiSearchArray, trimmed, {keys: keys})
      setEmojiStateArray(tempMatches.slice())
      setEmojiDataMatches(convertTo2D(tempMatches))
    } else {
      //setWindowMatches(exampleWindows)
      setEmojiStateArray(emojiSearchArray)
      setEmojiDataMatches(emojiData)
    }
  }
  
  //when the query changes, set the first emoji as the selected emoji
  useEffect(() => {
    if(emojiStateArray && emojiStateArray[0] && emojiStateArray[0].emoji){
      setHighlightedEmoji({index: 0, emoji: emojiStateArray[0].emoji})
    }
  }, [emojiStateArray])

  const searchFieldRef = React.useRef()
  const [searchInput, setSearchInput] = useState('')
  const handleInputKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode
    //console.log({charCode, highlightedEmoji, emojiStateArray})
    if(charCode == 37){
      event.preventDefault()
      event.stopPropagation()
      handleHighlightEmoji({shortcut: "left"})
    } else if(charCode == 39){
      event.preventDefault()
      event.stopPropagation()
      handleHighlightEmoji({shortcut: "right"})
    } else if(charCode == 38){
      event.preventDefault()
      event.stopPropagation()
      handleHighlightEmoji({shortcut: "up"})
    } else if(charCode == 40){
      event.preventDefault()
      event.stopPropagation()
      handleHighlightEmoji({shortcut: "down"})
    }
     else if(charCode == 13 && highlightedEmoji && highlightedEmoji.index >= 0 && emojiStateArray){
      handleSelectEmoji(emojiStateArray[highlightedEmoji.index])
    }
    if(charCode == 27 && searchInput != null && searchInput!= ''){
      event.preventDefault()
      event.stopPropagation()
      setSearchInput('')
      handleChangeQuery()
      if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
    }
  }
  const clearSearch = () => {
    setSearchInput('')
    handleChangeQuery()
    if(searchFieldRef && searchFieldRef.current) searchFieldRef.current.focus()
  }


  //SELECTING EMOJI
  const handleSelectEmoji = (emojiObject) => {
    //console.log(emojiObject)
    // setWorkspace({
    //   ...workspace,
    //   emoji: emojiObject,
    //   color: null
    // })
    props.chooseEmoji(emojiObject, null)
  }
  const [highlightedEmoji, setHighlightedEmoji] = useState(null)


  const highlightEmoji = (index, emoji, mouse) => {
    //console.log(index)
    if(mouse){
      setHighlightedEmoji({index: index, emoji: emoji, mouse: true})
    } else if (index && emoji) {
      setHighlightedEmoji({index: index, emoji: emoji})
    } else {
      setHighlightedEmoji(null)
    }
  }

  const handleHighlightEmoji = (event) => {
    let direction = event.shortcut
    let emojiState

    //console.log(event)

    if(searchFieldRef && searchFieldRef.current && searchFieldRef.current !== document.activeElement) searchFieldRef.current.focus()
    setEmojiStateArray(curState => {
      emojiState = curState
      return curState
    })
    setHighlightedEmoji(prevVal => {
      //console.log(prevVal)
      if (direction == "enter"){
        if(prevVal != null) handleSelectEmoji(emojiState[prevVal.index])
      }
      else if(prevVal == null || prevVal.index < 0){
        //return 0
        return emojiState && emojiState[0] && emojiState[0].emoji ? {index: 0, emoji: emojiState[0].emoji} : null
      } 
      else if (direction == "right"){
        //return prevVal + 1
        let newIndex = emojiState && emojiState[prevVal.index + 1] && emojiState[prevVal.index + 1].emoji && emojiState[prevVal.index + 1].emoji == "TITLE" ? prevVal.index + 2 : prevVal.index + 1
        //console.log(direction, newIndex)
        return emojiState && emojiState[prevVal.index + 1] && emojiState[prevVal.index + 1].emoji ? {index: newIndex, emoji: emojiState[newIndex].emoji} : null
      }
      else if (direction == "left"){
        //return prevVal - 1
        let newIndex = emojiState && emojiState[prevVal.index - 1] && emojiState[prevVal.index - 1].emoji && emojiState[prevVal.index - 1].emoji == "TITLE" ? prevVal.index - 2 : prevVal.index - 1
        
        if(newIndex < 0){
          return emojiState && emojiState[0] && emojiState[0].emoji ? {index: 0, emoji: emojiState[0].emoji} : null
        } else {
          //console.log(direction, newIndex)
          return emojiState && emojiState[prevVal.index - 1] && emojiState[prevVal.index - 1].emoji ? {index: newIndex, emoji: emojiState[newIndex].emoji} : null
        }
        
      }
      else if (direction == "up"){
        //return prevVal - 10
        //let newIndex = prevVal.index - colCount
        let nextChunk = emojiState && emojiState.length> 0 ? emojiState.slice(prevVal.index - colCount, prevVal.index) : null
        const nextSectionIndex = nextChunk && nextChunk.length > 0 ? nextChunk.findIndex(e => e && e.emoji && e.emoji == "TITLE") : -1
        //console.log({nextChunk, nextSectionIndex})

        let newIndex = nextSectionIndex > -1 ? (prevVal.index - colCount) + (nextSectionIndex - 1) : emojiState && emojiState[prevVal.index - colCount] && emojiState[prevVal.index - colCount].emoji && emojiState[prevVal.index - colCount].emoji == "TITLE" ? prevVal.index - colCount - 1 : prevVal.index - colCount
        //console.log(newIndex)
        if(newIndex < 0){
          //stay at cur index
          return prevVal
        } else {
          return emojiState && emojiState[prevVal.index - colCount] && emojiState[prevVal.index - colCount].emoji ? {index: newIndex, emoji: emojiState[newIndex].emoji} : null
        }
        
      }
      else if (direction == "down"){
        
        //return prevVal + 10
       // let newIndex = prevVal.index + colCount
       let nextChunk = emojiState && emojiState.length> 0 ? emojiState.slice(prevVal.index, prevVal.index + colCount) : null
       const nextSectionIndex = nextChunk && nextChunk.length > 0 ? nextChunk.findIndex(e => e && e.emoji && e.emoji == "TITLE") : -1

       let newIndex = nextSectionIndex > -1 ? prevVal.index + nextSectionIndex + 1 : emojiState && emojiState[prevVal.index + colCount] && emojiState[prevVal.index + colCount].emoji && emojiState[prevVal.index + colCount].emoji == "TITLE" ? prevVal.index + colCount + 1 : prevVal.index + colCount

        if(newIndex > emojiState.length){
          //go to top of list
          return emojiState && emojiState[0] && emojiState[0].emoji ? {index: 0, emoji: emojiState[0].emoji} : null
        } else if (emojiState && emojiState[prevVal.index] && emojiState[prevVal.index].emoji && emojiState[prevVal.index].emoji == "TITLE"){
          //if cur selected is a title object, move to firts emoji
          return emojiState && emojiState[prevVal.index + 1] && emojiState[prevVal.index + 1].emoji ? {index: prevVal.index + 1, emoji: emojiState[prevVal.index + 1].emoji} : null
        } else {
          //console.log(direction, newIndex)
          return emojiState && emojiState[prevVal.index + colCount] && emojiState[prevVal.index + colCount].emoji ? {index: newIndex, emoji: emojiState[newIndex].emoji} : null
        }
      }
    })
  }

  var nodes = document.getElementsByClassName('selectableEmoji')
  //console.log(nodes)

  //SCROLL THE EMOJI LIST ON ARROW KEY SELECT
  useEffect(() => {
    let rowIndex = highlightedEmoji && highlightedEmoji.index ? Math.floor(highlightedEmoji.index / colCount) : null

    //console.log({rowIndex}, scrollLimits.findIndex(num => num === rowIndex) > -1)

    //if(rowIndex != null && scrollLimits.findIndex(num => num === rowIndex) > -1 && emojiGridRef && emojiGridRef.current) scrollToEmoji(rowIndex)
    // let el = highlightedEmoji && highlightedEmoji.index ? nodes[highlightedEmoji.index] : null
    var el = highlightedEmoji && highlightedEmoji.emoji ? document.getElementById(highlightedEmoji.emoji) : null

    let wasMouse = highlightedEmoji && highlightedEmoji.mouse ? true : false

    if(el && !wasMouse){
      if(el.offsetTop + el.clientHeight - el.offsetParent.scrollTop >= 288 || el.offsetTop - el.clientHeight < el.offsetParent.scrollTop) //288 = viewport height
        scrollToEmoji(rowIndex)
    } 
    

  }, [highlightedEmoji])

  const scrollToEmoji = (rowIndex) => {
    emojiGridRef.current.scrollToItem({
      align: 'start', //center, end, auto
      columnIndex: 0,
      rowIndex: rowIndex,
    });
  };

  const arrowSelectEmojiEnabled = true
  useHotkeys('left, right, up, down, enter', (KeyboardEvent, HotkeysEvent) => {
    //console.log(HotkeysEvent)
    handleHighlightEmoji(HotkeysEvent)
  }, {
    arrowSelectEmojiEnabled
  })

  //Virtuaklization
  //const rowCount = emojiDataMatches ? emojiDataMatches.length : 0;
  const colCount = 11
  const cellHeight = 34.5;
  const cellWidth = cellHeight;
  const rowCount = emojiDataMatches ? emojiDataMatches.length : 0

  const headerStyle = {width: 250}

  const Cell = ({ columnIndex, key, rowIndex, style }) => {
    //console.log(rowIndex, columnIndex)
    //{position: 'sticky', top: 0, zIndex: 999, backgroundColor: '#FFF', border: 'solid',}
    if(emojiDataMatches && emojiDataMatches[rowIndex] && columnIndex == 0 && emojiDataMatches[rowIndex].emoji == "TITLE"){
      return (
          <div
              key={key}
              style={{...style, ...headerStyle}}
          >
              <Typography
                  style={{color: "#B1B1B1", marginTop: rowIndex != 0 ? 12 : 12, marginBottom: 18, fontWeight: 600, fontSize: 12,}}
                  variant="body2"
              >
                  {emojiDataMatches[rowIndex].title}
              </Typography>
          </div>
      )
    } else if (emojiDataMatches && emojiDataMatches[rowIndex] && emojiDataMatches[rowIndex][columnIndex] && emojiDataMatches[rowIndex][columnIndex].emoji){
      return(
          <div
              id={emojiDataMatches[rowIndex][columnIndex].emoji}
              key={key}
              style={{...style}}
          >
              <SelectEmoji
                  emojiStateArray={emojiStateArray}
                  externalHover={highlightedEmoji}
                  handleSelectEmoji={handleSelectEmoji}
                  highlightEmoji={highlightEmoji}
                  index={columnIndex * rowIndex}
                  object={emojiDataMatches[rowIndex][columnIndex]}
                  parentStyle={style}
                  scrollToEmoji={scrollToEmoji}
              />
          </div>
      )
    } 
    else {
      return null
    }
  };
  //{emojiDataMatches[0].name}
  //<SelectEmoji object={emojiDataMatches[rowIndex].emojis[columnIndex]} key={key} index={key} handleSelectEmoji={handleSelectEmoji} externalHover={highlightedEmoji}/>

  
  return (
      <Popover
          PaperProps={{
            sx: {
              boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              border: `1px solid ${theme.palette.action.hover}`,
              borderRadius: 1,
            }
          }} 
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} 
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
          <Box 
              style={{
              padding: 15, 
              paddingRight: 0, 
              paddingBottom: 0, 
              width: 414, 
              height: 400, 
              maxHeight: 400, 
              boxSizing: 'border-box', 
              display: 'flex', flexDirection: 'column', overflow: 'hidden',
              
              
            }}
          >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 15,}}>
                  <div>
                      <Button
                          color="inherit"
                          disableFocusRipple
                          onClick={() => {
                        setActive(0)
                        clearSearch()
                      }}
                          style={{fontSize: 12, paddingTop: 2, paddingBottom: 0, marginRight: 2, color: theme.palette.text.primary, borderBottom: active == 0 ? '2px solid' : '2px solid transparent', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover
                            }
                          }}
                      >
                          Emoji
                      </Button>
                      <Button
                          color="inherit"
                          disableFocusRipple
                          onClick={() => {
                        setActive(1)
                        clearSearch()
                      }}
                          style={{fontSize: 12, paddingTop: 2, paddingBottom: 0, marginRight: 2, color: theme.palette.text.primary, borderBottom: active == 1 ? '2px solid' : '2px solid transparent', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover
                            }
                          }}
                      >
                          Color
                      </Button>
                      {/* <Button
                      disableFocusRipple={true}
                      onClick={() => setActive(2)}
                      style={{fontSize: 12, paddingTop: 2, paddingBottom: 0, marginRight: 2, color:"#181818", borderBottom: active == 2 ? '2px solid' : '2px solid transparent', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}
                    >
                      Upload
                    </Button> */}
                  </div>

                  <PlainButton
                      disableFocusRipple
                      onClick={(e) => props.chooseEmoji(null, null)}
                      style={{fontSize: 12, paddingTop: 2, paddingBottom: 0, marginRight: 2, color:"#808080", borderBottom: '2px solid transparent',}}
                  >
                      Remove
                  </PlainButton>
              </div>

              <Divider style={{marginTop: 0, marginBottom: 10, marginRight: 15,}} />
              {
                  active == 0 && (
                  <div style={{display: 'flex', flexDirection: 'column', paddingRight: 15,}}>
                      <div style={{paddingBottom: 10}}>
                          <EmojiSearchbar
                              clearSearch={clearSearch}
                              handlePreventHotKey={(e) => handleInputKeyPress(e)}
                              onChange={handleChangeQuery}
                              searchFieldRef={searchFieldRef}
                              searchInput={searchInput}
                              setSearchInput={setSearchInput}
                          />
                      </div>
                  </div>
                )}

              <div style={{overflowY: 'auto', minHeight: '75%', paddingBottom: 15, flexGrow: 1,}}>
                  {
                    active == 0 ?
                    emojiDataMatches && emojiDataMatches.length > 0 ?
                        <AutoSizer disableWidth>
                            {({width, height}) =>
                            (<VariableSizeGrid
                                className="Grid"
                                columnCount={colCount}
                                columnWidth={index => cellWidth}
                                height={height}
                                ref={emojiGridRef}
                                rowCount={rowCount}
                                rowHeight={rowIndex => cellHeight} //rowIndex => emojiDataMatches && emojiDataMatches[rowIndex] && emojiDataMatches[rowIndex].emoji == "TITLE" && rowIndex == 0 ? 100 : cellHeight
                                style={{overflowX: 'hidden'}}

                                width={399}
                            >
                            
                                {Cell}
                            </VariableSizeGrid>)
                        }
                        </AutoSizer> 
                      :
                        <Typography
                            style={{color: "#B1B1B1", marginBottom: 8, fontWeight: 600, fontSize: 12}}
                            variant="body2"
                        >
                            No Results
                        </Typography>
                    :
                    active == 1 ?
                        <div style={{paddingRight: 15,}}>
                            {
                        labelColors.map(color => {
                          //border: isSelected ? '2px solid' : null
                          let isSelected = valColor && valColor.name == color.name ? true : false
                          return(
                              <ListItem
                                  button
                                  key={color.name}
                                  onClick={() => handleChangeValueColor(color)}
                                  selected={isSelected}
                                  style={{borderRadius: 6}}
                              >
                                  <div style={{width: 12, height: 12, backgroundColor: color.hex, borderRadius: '100%',}} />
                                  <div style={{marginLeft: 8}}>
                                      <Typography variant="body2"> 
                                          {' '}
                                          {color.name}
                                          {' '}
                                      </Typography>
                                  </div>
                              </ListItem>
                          )
                        })
                      }
                        </div>
                    :
                    null
                  }
              </div>
          </Box>
      </Popover>
  )
}