import React, { useState } from "react";
import useGlobal from "../../GlobalState/Store/Store.js";

import { useTheme, styled } from "@mui/material/styles";

import useCollectionById from "../../Hooks/useCollectionById.js";

import axios from "axios";

import { Link as RouterLink } from "react-router-dom";

import { Button, Dialog, DialogContent, Typography, IconButton, Tooltip, Box, Divider, CircularProgress } from "@mui/material/";

import sessionTimeString from "../../Utils/sessionTimeString";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Repeat from "@mui/icons-material/Repeat";
import MoreVert from "@mui/icons-material/MoreVert";
import Tab from "@mui/icons-material/Tab";

import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";

import TabsGrid from "./tabs/TabsGrid.js";

import CollectionMenu from "../Menus/CollectionMenu.js";
import CreatePropertyMenu from "../Menus/CreatePropertyMenu.js";

import DeleteCollectionDialogue from "../Dialogs/DeleteCollectionDialog.js";
import CollectionEditPropertyDiv from "./Properties/CollectionEditPropertyDiv.js";

import CollectionToolBar from "./CollectionToolBar.js";

import MoveCollectionMenu from "../Menus/MoveCollectionMenu.js";
import { useNavigate } from "react-router-dom";

import update from "immutability-helper";
import { mutate } from "swr";

import HotkeyChip from "../Hotkey/HotkeyChip.js";
import BigInput from "../Inputs/BigInput.js";

import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import { isCollectionCurrentlyOpen } from "../../Utils/isCollectionCurrentlyOpen.js";
import CurrentlyOpenBadge from "./CurrentlyOpenBadge.js";
import useCollections from "../../Hooks/useCollections.js";
import useEncodeCollectionURL from "../../Hooks/useEncodeCollectionURL.js";
import { BoltRounded } from "@mui/icons-material";

const InfoIconButton = styled(IconButton)(({ theme }) => ({
  // backgroundColor: theme.palette.text.tertiary,
  color: theme.palette.text.secondary,
  padding: 3,
  minHeight: 0,
  fontSize: 12,
  fontWeight: 500,
  marginLeft: 24,

  "&:hover": {
    color: "rgba(255,255,255, 0.6)",
    // color: theme.palette.text.primary,
    backgroundColor: "rgba(255,255,255, 0.15)",
  },
  "&:active": {
    color: "rgba(255,255,255, 0.6)",
    backgroundColor: "rgba(255,255,255, 0.15)",
    // color: theme.palette.text.primary,
  },
}));

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

function CollectionDialog(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const open = props.open;
  const tabsMenuOpen = globalState.tabsMenuOpen;
  let navigate = useNavigate();
  // const lastUpdatedString= props.lastUpdatedString
  // const createdOnString= props.createdOnString

  const handleCloseDialog = props.handleCloseDialog;
  const handleOpenTabs = props.handleOpenTabs;

  const GetWorkspacebyID = props.GetWorkspacebyID;

  let collectionDialogMounted = true;
  const handleAddSnackbarToStack = props.handleAddSnackbarToStack;

  //const collection = props.collectionState
  const collectionID = props.collectionState._collectionId; //urlId;

  // const workspaceID = props.collectionState.workspace;
  const { collectionData, isLoadingCollection, mutateCollection } = useCollectionById(
    globalState.isAuth,
    // workspaceID ? workspaceID : globalState.activeWorkspace._id,
    collectionID ? collectionID : null
  );
  // const { collectionData } = useCollectionById(globalState.isAuth, params && params.workspaceid ? globalState.activeWorkspace._id : null, collectionID ? collectionID : null)
  const collection = collectionData && collectionData.collection ? collectionData.collection : { tabs: [] };

  // const LinkToCollection = collection && globalState.activeWorkspace != undefined ? `/${paramCase(globalState.activeWorkspace._id)}/${collection.urlId}` : "/";
  const { getLinkToCollection } = useEncodeCollectionURL();
  const LinkToCollection = getLinkToCollection(collection?._collectionId, collection?.name);

  //LAST EDITED DATE STRING
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //console.log(collection.lastEdited)
  let lastEditedBaseDate =
    collection && collection.lastEdited ? new Date(collection.lastEdited) : collection && collection.createdOn ? new Date(collection.createdOn) : new Date();
  let shortMonth = months[lastEditedBaseDate.getMonth()];
  let date = lastEditedBaseDate.getDate();
  let year = lastEditedBaseDate.getFullYear();
  let time = lastEditedBaseDate.toLocaleTimeString([], { timeStyle: "short" });
  let lastUpdatedString = shortMonth + " " + date + ", " + year + " " + time; //"Last updated " +

  let createdOnBaseDate = collection && collection.createdOn ? new Date(collection.createdOn) : new Date();
  let shortMonthUpdate = months[createdOnBaseDate.getMonth()];
  let dateUpdate = createdOnBaseDate.getDate();
  let yearUpdate = createdOnBaseDate.getFullYear();
  let timeUpdate = createdOnBaseDate.toLocaleTimeString([], {
    timeStyle: "short",
  });
  let createdOnString = shortMonthUpdate + " " + dateUpdate + ", " + yearUpdate + " " + timeUpdate;

  const createdProperty = {
    name: "Created on",
    value: createdOnString,
    type: "date",
    fixed: true,
  };
  const updatedProperty = {
    name: "Last edited",
    value: lastUpdatedString,
    type: "date",
    fixed: true,
  };

  //AUTO-UPDATE TABS --------
  const { collectionsData, mutateCollections } = useCollections(
    globalState.activeWorkspace._id,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null
  );

  let { collectionCurrentlyOpen, collectionInSync } = isCollectionCurrentlyOpen(collection?._id);
  // const collectionCurrentlyOpen = isCollectionCurrentlyOpen(collection?._id);
  const toggleAutoUpdate = async (c) => {
    //this function updates the server / db
    let boolean = !c.autoUpdateTabs;
    let state = "disabled";
    if (boolean) {
      state = "enabled";
    }

    const data = {
      collection: c,
      state: state,
    };
    try {
      const updatedCollection = await axios.post("/collections/toggleautoupdatetabs", data);
      return updatedCollection;
    } catch (error) {
      return error;
      // Handle an error while updating the user here
    }
  };

  const handleToggleAutoUpdateTabs = (event, collection) => {
    //this is the optimisic UI function
    event.preventDefault();
    event.stopPropagation();

    //optimistically update the individual collection
    const optimisticState = {
      ...collectionData,
      collection: {
        ...collection,
        autoUpdateTabs: !collection.autoUpdateTabs,
      },
    };

    mutateCollection(toggleAutoUpdate(collection), {
      optimisticData: optimisticState,
      populateCache: false,
    });

    //ALSO optimistically update the collections in the background
    const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id == collection._id) : -1;

    if (collectionIndex > -1) {
      // {...collectionsData, collections: [...collectionsData.collections, [collectionIndex]: {...collectionsData.collections[collectionIndex], autoUpdateTabs: !collection.autoUpdateTabs}]}
      const optimisticState = {
        ...collectionsData,
        collections: [
          ...collectionsData.collections.slice(0, collectionIndex),
          { ...collectionsData.collections[collectionIndex], ...{ autoUpdateTabs: !collection.autoUpdateTabs } },
          ...collectionsData.collections.slice(collectionIndex + 1),
        ],
      };

      mutateCollections(toggleAutoUpdate(collection), {
        optimisticData: optimisticState, //[...collectionsData, "New Item"],
        populateCache: false,
        // revalidate: false,
      });
    } else {
      console.log("Cannot find that collection in the local state to updates it's autoupdate tabs property");
    }

    //update the collection sync in the extension
    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port.postMessage({
        type: "toggleAutoUpdateTabs",
        state: !collection.autoUpdateTabs,
        collection: collection,
      });
    }
  };
  //-----------

  //CHANGING COLLECTION NAME
  const collectionInputRef = React.useRef(null);
  const [editCollectionName, setEditCollectionName] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const RenameCollection = (e, collectionId, newName) => {
    //console.log(e.path)
    var wasWSButtonClick = e.path
      ? e.path.findIndex((obj) => {
          return obj.id === "workspacebutton";
        })
      : -1;

    if (wasWSButtonClick !== -1) {
      //console.log("CANCEL")
      //cancel req
      setEditCollectionName(false);
    } else {
      setEditCollectionName(false);
      //update the name in local state immediately
      mutate(
        [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
        async (collectionsData) => {
          const updatedCollectionIndex =
            collectionsData && collectionsData.collections ? collectionsData.collections.findIndex((c) => c._id === collectionId) : -1;
          if (updatedCollectionIndex > -1) {
            const updatedCollections = update(collectionsData.collections, {
              [updatedCollectionIndex]: {
                $set: { ...collection, name: newCollectionName },
              },
            });
            return { ...collectionsData, collections: updatedCollections };
          } else {
            return collectionsData;
          }
        },
        false
      );

      mutate(
        [`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null],
        async (collectionData) => {
          const updatedCollection = update(collectionData, {
            $set: { ...collection, name: newCollectionName },
          });
          return { ...collectionData, collection: updatedCollection };
        },
        false
      );

      setEditCollectionName(false);

      axios
        .post("/collections/updatename", {
          collectionId: collectionId,
          newName: newName,
        })
        .then((res) => {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        })
        .catch((err) => {
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
          console.log(err);
        });
    }
  };

  const handleSaveAndCloseCollection = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (globalState.extensionConnection.isConnected) {
      globalState.extensionConnection.port
        .postMessage({
          type: "saveAndCloseCollection",
          windowId: collectionInSync.windowId,
        })
        .then(() => {
          //trigger a revalidation
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        });
    }
  };

  const CollectionNavbar = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <Box boxShadow={0}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "transparent",
            paddingBottom: 8,
            paddingTop: 8,
            paddingRight: 12,
            paddingLeft: 12,
          }}
        >
          <Button component={RouterLink} size="small" startIcon={<OpenInFullRoundedIcon size="small" />} to={LinkToCollection} variant="outlined">
            Open full page
          </Button>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // justifyContent: 'flex-end',
            }}
          >
            {collectionCurrentlyOpen && <CurrentlyOpenBadge collectionInSync={collectionInSync} />}
            <Tooltip
              title={
                <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                  <div>
                    Auto-update is {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
                    .
                    <br />
                    <span style={{ color: "rgba(255,255,255, 0.5)" }}>
                      {collection && collection?.autoUpdateTabs ? "Click to  Disable" : "Click to  Enable"}.
                    </span>{" "}
                    <br />
                  </div>
                  <InfoIconButton href="https://www.partizion.io/guide/auto-updating-collections" size="small" target="_blank">
                    <HelpOutlineRoundedIcon />
                  </InfoIconButton>
                </div>
              }
            >
              <Button
                disabled={!globalState.extensionConnection.isConnected}
                onClick={(e) => handleToggleAutoUpdateTabs(e, collection)}
                size="small"
                startIcon={<BoltRoundedIcon style={{ marginRight: -5 }} />}
                style={{ marginRight: 4 }}
                variant={collection && collection.autoUpdateTabs ? "autoupdateenabled" : "outlined"}
              >
                Auto-Update {collection && collection.autoUpdateTabs ? "ON" : "OFF"}
              </Button>
            </Tooltip>

            {!collectionCurrentlyOpen ? (
              <Tooltip
                disableFocusListener
                disableInteractive
                title={globalState.extensionConnection.isConnected ? "" : "Connect to the Partizion extension to use this feature"}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    aria-label="add"
                    disableFocusListener
                    // classes={{ tooltip: classes.customWidthTooltip }}
                    disableInteractive
                    title={
                      !globalState.extensionConnection.isConnected ? (
                        ""
                      ) : collection.tabs.length < 1 ? (
                        "No tabs in this Collection"
                      ) : (
                        <React.Fragment>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            Open tabs in current window{" "}
                            <Typography
                              style={{
                                fontSize: 10,
                                marginTop: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                              variant="caption"
                            >
                              Hold
                              <HotkeyChip dense keys={["SHIFT"]} />
                              to open in new window
                            </Typography>
                          </div>
                        </React.Fragment>
                      )
                    }
                  >
                    <span>
                      <Button
                        aria-label="addtowindow"
                        // color="primary"
                        disabled={collection.tabs.length < 1 || !globalState.extensionConnection.isConnected}
                        onClick={(e) => handleOpenTabs(e, collection, "add")}
                        size="small"
                        startIcon={<Tab fontSize="inherit" />}
                        style={{ marginTop: 0, marginRight: 4 }}
                        variant="outlined"
                      >
                        Open
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    aria-label="open"
                    disableFocusListener
                    // classes={{ tooltip: classes.customWidthTooltip }}
                    disableInteractive
                    title={
                      !globalState.extensionConnection.isConnected
                        ? ""
                        : collection.tabs.length < 1
                          ? "No tabs in this Collection"
                          : "Close current tabs and open these"
                    }
                  >
                    <span>
                      <Button
                        aria-label="replace tabs"
                        // color="primary"
                        disabled={collection.tabs.length < 1 || !globalState.extensionConnection.isConnected}
                        onClick={(e) => handleOpenTabs(e, collection, "replace")}
                        size="small"
                        startIcon={<Repeat fontSize="inherit" />}
                        style={{ marginTop: 0, marginRight: 4 }}
                        variant="outlined"
                      >
                        Switch to
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </Tooltip>
            ) : (
              <Button
                aria-label="saveandclose"
                disableRipple
                disabled={!globalState.extensionConnection.isConnected}
                onClick={handleSaveAndCloseCollection}
                size="small"
                style={{ marginRight: 6 }}
                variant="outlined"
              >
                Save and Close
              </Button>
            )}

            <IconButton aria-label="delete" onClick={(e) => handleClick(e)} size="small" variant="outlined">
              <MoreVert fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <Divider />
      </Box>
    );
  };

  //CREATING A PROPERTY
  const [createPropertyAnchor, setCreatePropertyAnchor] = useState(false);
  const handleOpenCreatePropertyMenu = (event) => {
    setCreatePropertyAnchor(event.currentTarget);
  };
  const handleCloseCreatePropertyMenu = () => {
    setCreatePropertyAnchor(null);
  };

  //COllECTION MENU
  const collectionMenuButtonRef = React.useRef(null);
  const [collectionSelected, setCollectionSelected] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const collectionMenuOpen = Boolean(menuAnchor);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [clickPosition, setClickPosition] = useState(initialState);
  const handleClick = (event, trigger) => {
    //console.log("Attempting to open collection menu")
    // event.preventDefault()
    // event.stopPropagation()
    // if(trigger && trigger == "rightclick"){
    //   //console.log("setting click pos", event.clientX, event.clientY)
    //   setClickPosition({
    //     mouseX: event.clientX - 2,
    //     mouseY: event.clientY - 4,
    //   });
    // }
    //setMenuAnchor(event.currentTarget);

    //console.log(event.currentTarget)

    //console.log(collectionMenuButtonRef)
    setMenuAnchor(collectionMenuButtonRef.current);
  };
  const handleClose = (event, keepSelected) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    //console.log("Attempting to CLOSE collection menu", keepSelected)
    if (collectionDialogMounted) {
      setMenuAnchor(null);
    }
    setClickPosition(initialState);
  };

  //MOVING COLLECTION
  const [selectWorkspaceAnchorEl, setSelectWorkspaceAnchorEl] = React.useState(null); //the anchor for the workspace select menu
  let selectWorkspaceOpen = Boolean(selectWorkspaceAnchorEl);
  const handleOpenWorkspaceMenu = (event) => {
    setSelectWorkspaceAnchorEl(menuAnchor);
    handleClose(event, true);
  };

  const handleCloseMoveCollectionMenu = (event) => {
    //console.log("CLOSE the move collection menu")
    handleClose(event, true);
    event.preventDefault();
    event.stopPropagation();
    setSelectWorkspaceAnchorEl(null);
    //if(handleCloseDialog) handleCloseDialog()
  };

  const handleMoveCollection = (event, workspace) => {
    //console.log({event, workspace, index})
    const data = {
      collectionId: collection._id,
      workspaceId: workspace._id,
    };

    // update the local data immediately, but disable the revalidation
    mutate(
      [`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null],
      async (collectionsData) => {
        // filter the list, and return it without the moved collection
        if (collectionsData && collectionsData.collections) {
          const filteredCollections = collectionsData.collections.filter((c) => c._id !== collection._id);
          return { ...collectionsData, collections: filteredCollections };
        } else {
          return collectionsData;
        }
      },
      false
    );

    // mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null], async collectionData => {
    //   const updatedCollection = update(collectionData, {$set: {...collection, workspace: workspace._id} })
    //   return {...collectionData, collection: updatedCollection}
    // }, false)

    // send a request to the API to update the source
    axios
      .post("/collections/move", data)
      .then(() => {
        // trigger a revalidation (refetch) to make sure our local data is correct
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);

        mutate(`/workspaces`);
        // mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null])
      })
      .then(() => {
        handleClose(event);
        handleCloseMoveCollectionMenu(event);

        navigate(LinkToCollection);
      })
      .catch((err) => {
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        // mutate([`/collections/getbyid`, globalState.activeWorkspace._id, collection && collection.urlId ? collection.urlId : null])
        console.log(err);
      });
  };

  const openDelete = () => {
    //onsole.log("Attempting to open collection delete")
    if (collectionDialogMounted) {
      setDeleteAlertOpen(true);
    }
  };
  const closeDelete = () => {
    //console.log("Attempting to CLOSE collection delete")
    if (collectionDialogMounted) {
      setDeleteAlertOpen(false);
      handleCloseDialog();
    }
  };

  return (
    <div>
      <Dialog
        aria-labelledby="max-width-dialog-title"
        classes={{ paper: { minHeight: "90vh" } }}
        disableEnforceFocus
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        open={open}
        scroll="paper"
        style={{
          marginLeft: 40,
          marginRight: 40,
        }}
      >
        <CollectionNavbar id="max-width-dialog-title" />
        <DialogContent
          ref={collectionMenuButtonRef}
          style={{
            overflow: "hidden",
            backgroundColor: theme.palette.collection.default,
            overflowY: "auto",
            paddingBottom: 50,
            paddingTop: 25,
          }}
        >
          {isLoadingCollection ? (
            <div
              style={{
                width: "100%",
                minWidth: "100%",
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={24} style={{ margin: 10, color: theme.palette.text.tertiary }} />
            </div>
          ) : (
            <>
              <BigInput
                edit={editCollectionName}
                fullWidth
                inputRef={collectionInputRef}
                key={collection._id}
                newName={newCollectionName}
                objectId={collection._id}
                originalName={collection && collection.name ? collection.name : null}
                renameFunction={RenameCollection}
                setEdit={setEditCollectionName}
                setNewName={setNewCollectionName}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  //width: '100%',
                  marginTop: 2,
                }}
              >
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    marginRight: 12,
                  }}
                  variant="body2"
                >
                  <span style={{ color: theme.palette.text.secondary }}>Created</span> {sessionTimeString(collection.createdOn)}
                </Typography>
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                    textAlign: "left",
                    fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    marginRight: 12,
                  }}
                  variant="body2"
                >
                  <span style={{ color: theme.palette.text.secondary }}>Edited</span> {sessionTimeString(collection.lastEdited)}
                </Typography>
                {collection.lastUpdated && collection.lastUpdated !== collection.createdOn && collection?.autoUpdateTabs && (
                  <>
                    <BoltRounded style={{ fontSize: 12, color: theme.palette.text.secondary }} />
                    <Typography
                      noWrap
                      style={{
                        color: theme.palette.text.primary,
                        fontSize: 12,
                        textAlign: "left",
                        fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        marginRight: 12,
                      }}
                      variant="body2"
                    >
                      <span style={{ color: theme.palette.text.secondary }}>Auto-Updated</span> {sessionTimeString(collection?.lastUpdated)}
                    </Typography>
                  </>
                )}
              </div>

              <div style={{ marginTop: 25 }} />

              {/* <CollectionEditPropertyDiv fixed property={createdProperty} />
              <CollectionEditPropertyDiv fixed property={updatedProperty} /> */}

              {globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0
                ? globalState.activeWorkspace.collectionProperties.map((property, index) => (
                    <CollectionEditPropertyDiv GetWorkspacebyID={GetWorkspacebyID} collection={collection} key={property._id} property={property} />
                  ))
                : null}
              <PlainButton
                disableFocusRipple
                onClick={handleOpenCreatePropertyMenu}
                size="small"
                startIcon={<AddRoundedIcon />}
                style={{
                  color: theme.palette.text.secondary,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginRight: 4,
                  height: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  marginTop: 10,
                }}
              >
                Add property
              </PlainButton>
              <CreatePropertyMenu anchorEl={createPropertyAnchor} handleClose={handleCloseCreatePropertyMenu} />
              <div style={{ marginTop: 24 }}>
                <CollectionToolBar
                  collection={collection}
                  collectionCurrentlyOpen={collectionCurrentlyOpen}
                  openAddTabsDialog={props.openAddTabsDialog}
                  tabsMenuOpen={tabsMenuOpen}
                />
              </div>
              <Divider style={{ marginBottom: 15, marginTop: 5 }} />

              <TabsGrid
                collection={collection}
                collectionCurrentlyOpen={collectionCurrentlyOpen}
                handleAddSnackbarToStack={handleAddSnackbarToStack}
                inCollectionDialog
                openAddTabsDialog={props.openAddTabsDialog}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
      <CollectionMenu
        activeWorkspace={globalState.activeWorkspace}
        anchor={menuAnchor}
        clickPosition={clickPosition}
        collection={collection}
        collectionCurrentlyOpen={collectionCurrentlyOpen}
        editNameDisabled
        handleClick={handleClick}
        handleClose={handleClose}
        handleCloseDialog={handleCloseDialog}
        handleOpenWorkspaceMenu={handleOpenWorkspaceMenu}
        // moveCollectionAnchorEl={selectWorkspaceAnchorEl}
        // moveWorkspaceOpen={selectWorkspaceOpen}
        inDialog
        openDelete={openDelete}
        setSelected={setCollectionSelected}
      />

      {/* {
                  selectWorkspaceOpen ?
                      <MoveCollectionMenu
                          anchorEl={selectWorkspaceAnchorEl}
                          handleClose={handleCloseMoveCollectionMenu}
                          handleMoveCollection={handleMoveCollection}
                      />
                    :
                    null
                } */}
      <MoveCollectionMenu anchorEl={selectWorkspaceAnchorEl} handleClose={handleCloseMoveCollectionMenu} handleMoveCollection={handleMoveCollection} inDialog />
      {deleteAlertOpen ? (
        <DeleteCollectionDialogue
          collection={collection}
          handleClose={closeDelete}
          handleOpen={openDelete}
          open={deleteAlertOpen}
          workspace={globalState.activeWorkspace}
        />
      ) : null}
    </div>
  );
}

export default CollectionDialog;

// <Typography color="textSecondary">
//   {lastUpdatedString} — {collection.tabs.length} total tabs
// </Typography>

// {
//   menuOpen ?
//   <CollectionMenu
//     handleClick={handleOpenMenu}
//     anchor={anchorEl}
//     // clickPosition={props.clickPosition}
//     handleClose={handleCloseMenu}
//     setSelected={props.overrideSelected}
//     collection={props.collection}
//     activeWorkspace={props.activeWorkspace}
//     openDelete={openDelete}
//     // toggleEdit={props.handleEditCollectionName}

//
//   />
//   :
//   null
// }
// {
//   deleteAlertOpen ?
//   <DeleteCollectionDialogue open={deleteAlertOpen} handleOpen={openDelete} handleClose={closeDelete} workspace={props.activeWorkspace} collection={collection}/>
//   :
//   null
// }
