import React from 'react'

import {
    Typography,
  } from '@mui/material';

import CollectionResultObjectAlt from './ResultObjects/CollectionResultObjectAlt.js';
import TabResultObject from './ResultObjects/TabResultObject.js';

export default function ResultsWrapper(props){
    const type = props.type
    const results = props.results

    //console.log({results})

    return(
        <div style={{width: '100%', paddingLeft: props.outerPadding || 24, paddingRight: props.outerPadding || 24}}>
            <Typography
                style={{color: "#B1B1B1", fontWeight: 600, fontSize: 12, marginLeft: 0}}
                variant="body2"
            >
                {props.title.toUpperCase()}
            </Typography>
            {results && props.type == "collection" && (
                results.map((r, i) => (
                    <CollectionResultObjectAlt
                        allMatches={props.allMatches}
                        focusSelected={props.focusSelected}
                        focusedRef={props.focusedRef}
                        handleMouseSelected={props.handleMouseSelected}
                        index={i}
                        key={i}
                        openCollection={props.openCollection}
                        result={r}
                    />
                ))
            )}
            {results && props.type == "tab" && (
                results.map((r, i) => (
                    <TabResultObject
                        allMatches={props.allMatches}
                        focusSelected={props.focusSelected}
                        focusedRef={props.focusedRef}
                        handleMouseSelected={props.handleMouseSelected}
                        handleOpenTab={props.handleOpenTab}
                        index={i}
                        key={i}
                        openCollection={props.openCollection}
                        result={r}
                    />
                ))
            )}
        </div>
    )
}