
export default function extractHostname(url) {
    var hostname;
    
    if(url && url !== undefined){
      try {
          var link = new URL(url);
          hostname = link.hostname;
          //find & remove www
          hostname = hostname.replace('www.','');
      } catch (error) {
          console.log('Invalid URL, trying secondary method');
          var isUrl = url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www") > -1 ? true : false

        if(isUrl){
          if(url.indexOf("https://") > -1){
            hostname = url.split('https://')[1];
          }
          else if(url.indexOf("http://") > -1){
            hostname = url.split('http://')[1];
          } else {
            hostname = url
          }
          // console.log(hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ""))
          if(hostname){
            hostname = hostname.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
            const parts = hostname.split('.');
            if (parts.length > 2) {
                parts.shift();
            }
            
            const hostnameWithoutTLD = parts.join('.');
            hostname = hostnameWithoutTLD
            //const hostnameRegex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/im;
            //hostname = url.match(hostnameRegex)[1];           
          }

        } else {
          return url
        }
          
      }
    }
    return hostname;
  }