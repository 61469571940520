import { useState, version } from 'react';
// import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import axios from 'axios'

const getKey = (pageIndex, previousPageData, sessionId) => {
  //console.log(previousPageData)
  // reached the end
  if (previousPageData && !previousPageData.cursor) {
    //console.log("end of data")
    return null
  }

  // first page, we don't have `previousPageData`
  if (pageIndex === 0) return `/sessions/history?sessionId=${sessionId}` //`/users?limit=10`

  // add the cursor to the API endpoint
  return `/sessions/history?sessionId=${sessionId}&cursor=${previousPageData.cursor}`
}

const fetcher = url => axios.get(url, {withCredentials: true}).then(res => res.data)

function useSessionHistory (isAuth, sessionId, options) {

  const { data, error, isValidating, size, setSize, mutate } = useSWRInfinite(isAuth && sessionId !== null ? (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, sessionId) : null, fetcher, options ? options : null)

  let allHistory = data && data.length ? data.reduce((acc, item) => acc.concat(item.sessionHistory), []) : []
  let hasMoreHistory = data && data.length ? !!data[data.length -1].cursor : false

  //data && data.length ? console.log(data[data.length -1].cursor) : 'no cursor'

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined") ? true : false
  const isEmpty = data?.[0]?.length === 0;


  //version numbers aren't reliable, but _ids and lastUPdated is, so we just set the version to the index
  let sessionHistory = allHistory.map((h, index) => {
    return {...h, version: allHistory?.length - 1 - index}
  })
  return {
    sessionHistory: sessionHistory,
    hasMoreHistory: hasMoreHistory,
    isLoadingHistory: !error && !data,
    isLoadingMoreHistory: isLoadingMore,
    isValidatingHistory: isValidating,
    isHistoryError: error,
    historyPageSize: size,
    setHistoryPageSize: setSize,
    mutate: mutate
  }
}



export default useSessionHistory;