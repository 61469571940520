/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import Autocomplete, { autocompleteClasses, createFilterOptions } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import EditValuePopover from "./EditValuePopover.js";
import { mutate } from "swr";
import update from "immutability-helper";
// import { DefaultPopover } from '../../Menus/DefaultPopover.js';

import { useTheme, styled } from "@mui/material/styles";
import { colors as labelColors } from "../../../Utils/StandardColors.js";
import MoreVert from "@mui/icons-material/MoreVert";
import useGlobal from "../../../GlobalState/Store/Store.js";
import { DefaultPopover } from "../../Menus/DefaultPopover.js";

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
    borderRadius: 0,
    border: "none",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      // padding: 8,
      // border: `1px solid  ${
      //   theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      // }`,
      marginBottom: 4,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} style={{ width: "100%", borderRadius: 0, padding: 8 }} />;
}

const Listbox = styled("ul")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  //boxShadow: theme.shadows[8], //'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  //border: `1px solid ${theme.palette.action.hover}`,
  // borderRadius: 6,
  zIndex: 1,
  //position: 'absolute',
  //top: -12,

  // paddingBottom: 8,
  // paddingTop: 8,
  // paddingLeft: 8,
  // paddingRight: 8,
  margin: 0,
  width: "100%",

  "& li": {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 4,
    marginRight: 4,
    fontSize: 14,

    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    '& li[aria-selected="true"]': {
      backgroundColor: theme.palette.action.hover,
    },

    "& span": {
      flexGrow: 1,
    },
  },

  // "& li.Mui-focused": {
  //   backgroundColor: theme.palette.action.hover,
  // },

  "& .MuiMenuItem-root": {
    borderRadius: 4,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 4,
    marginRight: 4,
    fontSize: 14,
  },
}));

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 4,
  paddingTop: 4,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,
  minWidth: 0,
  width: "100%",

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:focused": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },

  "& input": {
    fontSize: "14px",
    backgroundSolor: "transparent",
    boxSizing: "border-box",
    padding: "4px 6px",
    width: 0,
    minWidth: "30px",
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },
}));

const Tag = ({ label, bg, focused, value, property, workspace, collection, selected, setSelected, onDelete, ...props }) => {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const removeValue = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log("remove " + value.name + " from ")

    const data = {
      collectionIds: [collection._id],
      workspaceId: workspace._id,
      propertyId: property._id,
      propertyValues: [value._id],
    };

    if (property.type == "multiSelect") {
      setSelected(selected.filter((v) => v._id != value._id));
    } else {
      setSelected([]);
    }

    // mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView._id], async collectionsData => {
    //
    //  const collectionIndex = collectionsData && collectionsData.collections ? collectionsData.collections.findIndex(c => c._id == collection._id) : -1
    //  const propertyIndex = collectionIndex > -1 ? collectionsData.collections[collectionIndex].properties.findIndex(p => p.propertyId == propertyId) : -1
    //
    //  if(propertyIndex > -1){
    //     let updatedCollections = update(collectionsData.collections, {
    //       [collectionIndex]: {tabs: {
    //         [tabIndex]: {
    //           url: {$set: newURL}
    //         }
    //       }}
    //     })
    //
    //     // console.log({updatedCollections})
    //     // console.log(updatedCollections[collectionIndex].tabs[tabIndex])
    //    return {...collectionsData, collections: updatedCollections}
    //  } else {
    //    return collectionsData
    //  }
    // }, false)

    //console.log(data)
    axios
      .post("/collections/properties/values/deselect", data)
      .then((res) => {
        // mutate("/workspaces");
        // mutate([
        //   `/collections`,
        //   globalState.activeWorkspace._id,
        //   globalState.activeView && globalState.activeView._id
        //     ? globalState.activeView._id
        //     : null,
        // ]);
        // mutate([
        //   `/collections/getbyid`,
        //   globalState.activeWorkspace._id,
        //   collection && collection.urlId ? collection.urlId : null,
        // ]);
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        // mutate("/workspaces");
        // mutate([
        //   `/collections`,
        //   globalState.activeWorkspace._id,
        //   globalState.activeView && globalState.activeView._id
        //     ? globalState.activeView._id
        //     : null,
        // ]);
        // mutate([
        //   `/collections/getbyid`,
        //   globalState.activeWorkspace._id,
        //   collection && collection.urlId ? collection.urlId : null,
        // ]);
        console.log(err);
      });
  };

  return (
    <div
      style={{
        backgroundColor:
          theme.palette.mode === "dark" && bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
        //backgroundColor: bg,
        paddingTop: 3,
        paddingBottom: 2,
        paddingLeft: 5,
        paddingRight: focused ? 3 : 5,
        borderRadius: 2,
        marginBottom: 2,
        marginTop: 2,
        marginRight: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <Typography
        noWrap
        style={{
          color:
            theme.palette.mode === "dark"
              ? theme.palette.text.primary
              : labelColors.findIndex((l) => l.name == value.color) > -1
                ? labelColors.find((l) => l.name == value.color).darker
                : "#191919",
          fontSize: 12,
          width: "100%",
          textAlign: "left",
          fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
          fontWeight: 600,
        }}
        variant="body2"
      >
        {label}
      </Typography>
      {focused ? <CloseIcon onClick={(e) => removeValue(e)} style={{ width: 14, height: 14, marginLeft: 6, cursor: "pointer" }} /> : null}
    </div>
  );
};

export default function GitHubLabel({
  collection,
  externalTriggerOpen,
  // open,
  selected,
  setSelected,
  property,
  properties,
}) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const [selected, setSelected] = React.useState(properties || []);
  const [possibleValues, setPossibleValues] = useState(property && property.values ? property.values : []);
  const [pendingValue, setPendingValue] = React.useState([]); //used to temportily show what is selected in the select

  const buttonRef = useRef(null);

  const handleClick = (event) => {
    setPendingValue(selected);
    let target = event && event !== undefined ? event.currentTarget : buttonRef.current;
    setAnchorEl(target);
  };

  const handleClose = () => {
    // setSelected(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverOpen = open;
  const id = open ? "github-label" : undefined;

  //CUSTOM
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const [inputValue, setInputValue] = React.useState("");
  const [createColorSelect, setCreateColorSelect] = useState("default");
  const handleChooseCreateColor = (event, color) => {
    event.preventDefault();
    event.stopPropagation();

    setCreateColorSelect(color);
  };

  const filter = createFilterOptions();

  const original = properties || [];
  const handleCloseSelect = (event, bypass) => {
    // const { ref } = getInputProps()
    // if(ref && ref.current) ref.current.blur()

    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    let notChanged = arraysEqual(original, selected);
    console.log({ notChanged, bypass, editValueOpen });

    if ((!notChanged && !editValueOpen) || bypass) {
      mutate("/workspaces");
      mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
      mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
    } else if (!editValueOpen) {
      // setPopupOpen(false)
      handleClose();
    }
  };

  const handleSelectValues = (event, option, isSelected) => {
    //console.log(event, option)
    //const isSelected = value.findIndex(v => v._id == option._id) > -1 ? true : false
    const data = {
      collectionIds: [collection._id],
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      propertyValues: [option._id],
    };

    let apiString = isSelected ? "deselect" : "select";

    let index = selected.findIndex((v) => v._id == option._id);

    if (isSelected && index > -1) {
      if (property.type == "multiSelect") {
        // let prev = selected
        // prev.splice(index, 1)
        // console.log(prev)
        setSelected(selected.filter((v) => v._id != option._id));
        // setSelected(prevArray => {
        //   prevArray.splice(index, 1)
        //   let sliced = prevArray
        //   return sliced
        // })
        //console.log("remove single value")
      } else {
        setSelected([]);
        //console.log("set to empty array")
      }
    } else {
      if (property.type == "multiSelect") {
        setSelected((prevSelected) => [...prevSelected, option]);
        //console.log("push single value to multiSelect")
      } else {
        setSelected([option]);
        // setPopupOpen(false)
        handleClose();
        //console.log("set to single value")
      }
    }

    axios
      .post(`/collections/properties/values/${apiString}`, data)
      .then((res) => {
        mutate("/workspaces");
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        if (property.type == "multiSelect") {
          //do nothing
          handleCloseSelect(null, true);
        } else {
          handleCloseSelect(event, true);
          // setPopupOpen(false)
          handleClose();
        }
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  const createOption = (event, inputValue) => {
    event.preventDefault();
    event.stopPropagation();
    let newValue = {
      creating: true,
      name: inputValue,
      color: createColorSelect,
      value: inputValue,
    };
    setInputValue("");
    const data = {
      collectionIds: [collection._id],
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      newValue: newValue,
    };

    if (property.type == "multiSelect") {
      setSelected((prevSelected) => [...prevSelected, newValue]);
      //console.log("push single value to multiSelect")
    } else {
      setSelected([newValue]);
      // setPopupOpen(false)
      handleClose();
      //console.log("set to single value")
    }

    axios
      .post(`/collections/properties/values/create`, data)
      .then((res) => {
        //console.log(res)
        //console.log(props.property.type)
        if (res && res.data && res.data.newValue) {
          let option = res.data.newValue;
          if (property.type == "multiSelect") {
            // const { ref } = getInputProps()
            // if(ref && ref.current) ref.current.focus()
            //delete last item in array and add new option
            setSelected(
              update(selected, {
                $splice: [[selected.length, 1, option]],
              })
            );
            //setSelected(prevSelected => [...prevSelected.filter(v => !v.pending), option])
            mutate("/workspaces");
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
            mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
          } else {
            //console.log("Created single select option")
            mutate("/workspaces");
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
            mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
            setSelected([option]);
            // setPopupOpen(false)
            handleClose();
            handleCloseSelect(event, true);
          }

          //also add the value to possible values
          setPossibleValues((prevValues) => [...prevValues, option]);
        }
      })
      .catch((err) => {
        //console.log("Could not create option value")
        console.log(err);
      });
    //console.log(inputValue + " created")
  };
  const deleteOption = (event, option) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      valueId: option._id,
    };
    let index = selected.findIndex((v) => v._id == option._id);

    if (index > -1) {
      if (property.type == "multiSelect") {
        setSelected(selected.filter((v) => v._id != option._id));
      } else {
        setSelected([]);
        //console.log("set to empty array")
      }
    }

    axios
      .post(`/workspaces/collectionProperties/value/delete`, data)
      .then((res) => {
        //console.log(res)
        if (index > -1) {
          if (property.type == "multiSelect") {
            setSelected(selected.filter((v) => v._id != option._id));
            //console.log("remove single value")
          } else {
            setSelected([]);
            //console.log("set to empty array")
          }
        }

        mutate("/workspaces");
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
        mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
        handleCloseEditValue();
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  //EDITING PROPERTY VALUE
  const [editValueAnchor, setEditValueAnchor] = React.useState(null);
  const [valueToEdit, setValueToEdit] = useState(null);

  //the actual update function that calls the apiz
  const updateValue = (updatedValue) => {
    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      updatedProperty: {
        values: [
          {
            _id: updatedValue._id,
            color: updatedValue.color,
            name: updatedValue.name,
            value: updatedValue.name,
          },
        ],
      },
    };

    if (updatedValue && updatedValue._id) {
      axios
        .post(`/workspaces/collectionProperties/update`, data)
        .then((res) => {
          mutate("/workspaces");
          mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
          handleCloseEditValue();
        })
        .catch((err) => {
          //console.log("Could not create option value")
          console.log(err);
        });
    }
  };

  const handleOpenEditValue = (event, value) => {
    event.preventDefault();
    event.stopPropagation();

    //console.log("editign ", value)
    setEditValueAnchor(event.currentTarget);
    setValueToEdit(value);
  };
  const handleCloseEditValue = (ableToUpdate, updatedValue) => {
    setEditValueAnchor(null);
    // console.log({ ableToUpdate, updatedValue });
    if (ableToUpdate && updateValue) {
      if (updateValue && !updateValue.name) updatedValue.name = null; //remove the name if it is passed an empty string
      updateValue(updatedValue);
    }
  };

  const editValueOpen = Boolean(editValueAnchor);

  //THESE functions update the state locally
  const handleChangeValueColor = (color) => {
    const valueIndex = valueToEdit && possibleValues ? possibleValues.findIndex((v) => v._id === valueToEdit._id) : -1;
    const valueSelectedIndex = valueToEdit && selected ? selected.findIndex((s) => s._id === valueToEdit._id) : -1;
    if (valueIndex > -1) {
      //console.log(valueIndex, possibleValues[valueIndex])
      setPossibleValues(
        update(possibleValues, {
          [valueIndex]: { color: { $set: color.name } },
        })
      );
    }
    if (valueSelectedIndex > -1) {
      setSelected(
        update(selected, {
          [valueSelectedIndex]: { color: { $set: color.name } },
        })
      );
    }
  };
  const handleChangeValueName = (name) => {
    const valueIndex = valueToEdit && possibleValues ? possibleValues.findIndex((v) => v._id === valueToEdit._id) : -1;
    const valueSelectedIndex = valueToEdit && selected ? selected.findIndex((s) => s._id === valueToEdit._id) : -1;
    if (valueIndex > -1) {
      setPossibleValues(
        update(possibleValues, {
          [valueIndex]: { name: { $set: name } },
        })
      );
    }
    if (valueSelectedIndex > -1) {
      setSelected(
        update(selected, {
          [valueSelectedIndex]: { name: { $set: name } },
        })
      );
    }
  };

  useEffect(() => {
    if (property) {
      //console.log(props.externalTriggerOpen)
      if (externalTriggerOpen === property._id) {
        // setPopupOpen(true)
        handleClick();
      }
    }
  }, [externalTriggerOpen]);

  return (
    <React.Fragment>
      <PlainButton aria-describedby={id} disableRipple fullWidth onClick={handleClick} ref={buttonRef} style={{ minHeight: 42 }}>
        <div
          style={{
            width: "100%",
            overflowY: "auto",
            maxHeight: 200,
            padding: 4,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {selected && selected.length > 0 ? (
            property.type == "singleSelect" ? (
              selected.slice(0, 1).map((option, index) => {
                const bg =
                  labelColors.findIndex((l) => l.name == option.color) > -1
                    ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                    : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                return (
                  <Tag
                    bg={bg}
                    collection={collection}
                    key={option._id}
                    // focused={popoverOpen}
                    label={option.name}
                    property={property}
                    selected={selected}
                    setSelected={setSelected}
                    value={option}
                    workspace={globalState.activeWorkspace}
                  />
                );
              })
            ) : (
              selected.map((option, index) => {
                const bg =
                  labelColors.findIndex((l) => l.name == option.color) > -1
                    ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                    : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                return (
                  <Tag
                    bg={bg}
                    collection={collection}
                    key={option._id}
                    // focused={popoverOpen}
                    label={option.name}
                    property={property}
                    selected={selected}
                    setSelected={setSelected}
                    value={option}
                    workspace={globalState.activeWorkspace}
                  />
                );
              })
            )
          ) : !popoverOpen ? (
            <Typography noWrap style={{ color: theme.palette.text.secondary, marginRight: 6 }} variant="body2">
              Empty
            </Typography>
          ) : null}
        </div>
      </PlainButton>
      <DefaultPopover
        PaperProps={{
          style: {
            width: (anchorEl && anchorEl.offsetWidth) || null,
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={id}
        onClose={handleClose}
        open={open}
        placement="bottom-start"
      >
        <Autocomplete
          ListboxComponent={Listbox}
          PopperComponent={PopperComponent}
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                isCreate: true,
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => option.name}
          inputValue={inputValue}
          multiple
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleClose();
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          open
          options={possibleValues}
          renderInput={(params) => (
            <div
              style={{
                overflowY: "auto",
                maxHeight: 200,
                padding: 12,
                paddingBottom: 12,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                backgroundColor: theme.palette.action.hover,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {selected && selected.length > 0 ? (
                property.type == "singleSelect" ? (
                  selected.slice(0, 1).map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={collection}
                        focused
                        key={option._id}
                        label={option.name}
                        property={property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                      />
                    );
                  })
                ) : (
                  selected.map((option, index) => {
                    const bg =
                      labelColors.findIndex((l) => l.name == option.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                        : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
                    //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

                    return (
                      <Tag
                        bg={bg}
                        collection={collection}
                        focused
                        key={option._id}
                        label={option.name}
                        property={property}
                        selected={selected}
                        setSelected={setSelected}
                        value={option}
                        workspace={globalState.activeWorkspace}
                      />
                    );
                  })
                )
              ) : !popoverOpen ? (
                <Typography
                  noWrap
                  style={{
                    color: theme.palette.text.secondary,
                    marginRight: 6,
                  }}
                  variant="body2"
                >
                  Empty
                </Typography>
              ) : null}
              <InputBase
                autoFocus
                inputProps={params.inputProps}
                placeholder="Filter options"
                ref={params.InputProps.ref}
                style={{
                  cursor: "pointer",
                  // border: 'solid',//'transparent',
                  outline: "none",

                  flexGrow: 1,
                  backgroundColor: "transparent",
                  fontWeight: 500,
                  fontSize: 14,
                  marginLeft: 4,
                }}
              />
            </div>
          )}
          renderOption={(props, option) => {
            const isSelected = selected.findIndex((v) => v._id == option._id) > -1 ? true : false;

            const bg =
              labelColors.findIndex((l) => l.name == option.color) > -1
                ? hexToRgb(labelColors.find((l) => l.name == option.color).hex)
                : "rgba(195, 195, 195, 0.3)"; //'#C3C3C3'
            //const solidColor = labelColors.findIndex(l => l.name == option.color) > -1 ? labelColors.find(l => l.name == option.color).solidHex : '#C3C3C3'

            if (option.isCreate) {
              return (
                <MenuItem
                  button
                  disableRipple
                  key="create-option"
                  onClick={(e) => createOption(e, inputValue)}
                  style={{
                    padding: 6,
                    margin: 0,
                    paddingLeft: 8,
                    marginBottom: 6,
                  }}
                >
                  <Typography
                    noWrap
                    style={{
                      color: theme.palette.text.secondary,
                      marginRight: 6,
                    }}
                    variant="body2"
                  >
                    Create
                  </Typography>
                  <div
                    style={{
                      backgroundColor: labelColors.find((l) => l.name == createColorSelect)
                        ? `rgba(${hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).r}, ${
                            hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).g
                          }, ${hexToRgb(labelColors.find((l) => l.name == createColorSelect).hex).b}, 0.5)`
                        : "rgba(195, 195, 195, 0.3)", //'#C3C3C3',
                      //backgroundColor: theme.palette.mode === 'dark' && bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)` : bg && bg.r ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)` : bg,
                      paddingTop: 2,
                      paddingBottom: 1,
                      paddingLeft: 5,
                      paddingRight: 5,
                      borderRadius: 2,
                      marginLeft: 4,
                      marginBottom: 0,
                      display: "inline-block",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "75%",
                      marginRight: 10,
                    }}
                  >
                    <Typography
                      noWrap
                      style={{
                        color:
                          theme.palette.mode === "dark"
                            ? theme.palette.text.primary
                            : labelColors.findIndex((l) => l.name == createColorSelect) > -1
                              ? labelColors.find((l) => l.name == createColorSelect).darker
                              : "#191919",
                        fontSize: 12,
                        width: "100%",
                        textAlign: "left",
                        fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                        fontWeight: 500,
                      }}
                      variant="body2"
                    >
                      {inputValue}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {labelColors.map((color) => {
                      //border: isSelected ? '2px solid' : null
                      let isSelected = createColorSelect == color.name ? true : false;
                      return (
                        <div
                          key={color.solidHex}
                          onClick={(e) => handleChooseCreateColor(e, color.name)}
                          style={{
                            marginRight: 6,
                            width: 14,
                            height: 14,
                            backgroundColor: color.solidHex,
                            borderRadius: "100%",
                            border: isSelected ? `2px solid ${theme.palette.text.primary}` : null,
                          }}
                        />
                      );
                    })}
                  </div>
                </MenuItem>
              );
            } else {
              return (
                <li
                  {...props}
                  key={option._id}
                  onClick={(e) => handleSelectValues(e, option, isSelected)}
                  style={{
                    padding: 2,
                    margin: 0,
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {isSelected ? <CheckIcon fontSize="small" /> : <CheckIcon fontSize="small" style={{ opacity: 0 }} />}

                  <span style={{ margin: 0, width: "100%" }}>
                    <div
                      style={{
                        backgroundColor:
                          theme.palette.mode === "dark" && bg && bg.r
                            ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.5)`
                            : bg && bg.r
                              ? `rgba(${bg.r}, ${bg.g}, ${bg.b}, 0.3)`
                              : bg,
                        //backgroundColor: solidColor,
                        paddingTop: 3,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        borderRadius: 2,
                        marginLeft: 4,
                        marginBottom: 0,
                        marginTop: 6,
                        display: "inline-block",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "95%",
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? theme.palette.text.primary
                              : labelColors.findIndex((l) => l.name == option.color) > -1
                                ? labelColors.find((l) => l.name == option.color).darker
                                : theme.palette.text.primary,
                          fontSize: 12,
                          width: "100%",
                          textAlign: "left",
                          fontFamily: '"Dm Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 600,
                        }}
                        variant="body2"
                      >
                        {option.name}
                      </Typography>
                    </div>
                  </span>
                  <IconButton
                    id="optionsbutton"
                    onMouseDown={(e) => handleOpenEditValue(e, option)}
                    size="small"
                    //onMouseDown={() => alert("clicked")}
                    // style={{margin: 0}}
                    style={{
                      margin: 3,
                      borderRadius: 4,
                      padding: 3,
                    }}
                  >
                    <MoreVert fontSize="inherit" />
                  </IconButton>
                </li>
              );
            }
          }}
          renderTags={() => null}
        />
      </DefaultPopover>
      {editValueOpen ? (
        <EditValuePopover
          anchorEl={editValueAnchor}
          deleteOption={deleteOption}
          handleChangeColor={handleChangeValueColor}
          handleChangeName={handleChangeValueName}
          handleClose={handleCloseEditValue}
          labelColors={labelColors}
          valueToEdit={valueToEdit}
        />
      ) : null}
    </React.Fragment>
  );
}
