import React, { useEffect, useState } from "react";
import axios from "axios";
import useGlobal from "../../../GlobalState/Store/Store.js";

import { Button, Divider, Typography } from "@mui/material";

import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import TabRoundedIcon from "@mui/icons-material/TabRounded";

import CollectionPropertySelect from "./CollectionPropertySelect.js";
import PropertyMenu from "../../Menus/PropertyMenu.js";

import { mutate } from "swr";

import { styled, useTheme } from "@mui/material/styles";
import GitHubLabel from "./GitHubLabel.js";
const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,
  minWidth: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

const emptyArray = [];

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function CollectionEditPropertyDiv(props) {
  const [globalState, globalActions] = useGlobal();
  const propertyFromWorkspace = props.property;
  const fixed = props.fixed;
  const [properties, setProperties] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    if (props.collection && props.collection.properties) {
      const currentProperties = props.collection && props.collection.properties ? props.collection.properties : emptyArray;
      const index = currentProperties.findIndex((p) => p.propertyId == propertyFromWorkspace._id);
      const values =
        index > -1 ? propertyFromWorkspace.values.filter((wsPropValue) => currentProperties[index].values.indexOf(wsPropValue._id) > -1) : emptyArray;
      let selectedProperties = values && values.length > 0 ? (propertyFromWorkspace.type == "singleSelect" ? values.slice(0, 1) : values) : values;
      //console.log(selectedProperties)
      setProperties(selectedProperties);
    }
  }, [props.collection, propertyFromWorkspace]);

  const [propertyMenuAnchor, setPropertyMenuAnchor] = useState(false);
  const [editProperty, setEditProperty] = useState(null);

  const handleOpenPropertyMenu = (event, property) => {
    setPropertyMenuAnchor(event.currentTarget);
    //console.log(property)
    setEditProperty(property);
  };

  const handleClosePropertyMenu = () => {
    setPropertyMenuAnchor(null);
    setEditProperty(null);
  };

  const [selectOpen, setSelectOpen] = useState(false);

  const handleOpenSelect = () => {
    setSelectOpen(true);
  };

  const [editPropertyReq, setEditPropertyReq] = useState({
    property: null,
    isLoading: false,
    isError: false,
  });

  const handleStopLoading = (error) => {
    setEditPropertyReq({
      property: null,
      isLoading: false,
      isError: error ? error : false,
    });
  };

  const handleUpdateProperty = (property, name, propertyType) => {
    setEditPropertyReq({
      property: property,
      isLoading: true,
      isError: false,
    });

    let updatedProperty = {};
    if (property.name != name) {
      //console.log("property name was updated")
      updatedProperty.name = name;
    }
    if (property.type != propertyType) {
      //console.log("property type was updated")
      updatedProperty.type = propertyType;
    }

    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
      updatedProperty: updatedProperty,
    };

    //console.log(data)

    axios
      .post("/workspaces/collectionProperties/update", data)
      .then((res) => {
        //console.log(res)
        if (res.data && res.data.workspace) {
          let curWorkspace = {
            ...globalState.activeWorkspace,
            collectionProperties: res.data.workspace.collectionProperties,
          };
          //console.log({curWorkspace})
          //curWorkspace.collectionProperties = res.data.workspace.collectionProperties
          //globalActions.saveActiveWorkspace(res.data.workspace)
          globalActions.saveActiveWorkspace(curWorkspace);

          if (res.data.multiToSingle) {
            console.log("UPDAT ECOLLECTIONS");
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
          }
        }
        handleStopLoading();
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
        handleStopLoading(err);
      });
  };

  const [externalTriggerOpenSelect, setExternalTriggerOpenSelect] = useState(null);
  const handleExternalTriggerOpenSelect = (property) => {
    setExternalTriggerOpenSelect(property._id);

    setTimeout(() => {
      setExternalTriggerOpenSelect(null);
    }, 100);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "start",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 4,
        }}
      >
        <div style={{ width: 150, height: "100%" }}>
          <PlainButton
            disabled={(editPropertyReq.isLoading && editPropertyReq.property._id == propertyFromWorkspace._id) || propertyFromWorkspace.fixed}
            fullWidth
            onClick={(e) => handleOpenPropertyMenu(e, propertyFromWorkspace)}
            size="small"
            startIcon={
              propertyFromWorkspace.type == "date" ? (
                <AccessTimeRoundedIcon style={{ marginLeft: 4 }} />
              ) : propertyFromWorkspace.type == "tabCount" ? (
                <TabRoundedIcon style={{ marginLeft: 4 }} />
              ) : propertyFromWorkspace.type == "singleSelect" ? (
                <ArrowDropDownCircleRoundedIcon style={{ marginLeft: 4 }} />
              ) : propertyFromWorkspace.type == "multiSelect" ? (
                <ListRoundedIcon style={{ marginLeft: 4 }} />
              ) : null
            }
            style={
              editPropertyReq.isLoading && editPropertyReq.property._id == propertyFromWorkspace._id
                ? {
                    color: theme.palette.text.secondary,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: 6,
                    marginRight: 6,
                    height: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    padding: 6,
                    opacity: 0.25,
                  }
                : {
                    color: theme.palette.text.secondary,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: 6,
                    marginRight: 6,
                    height: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    padding: 6,
                  }
            }
          >
            <Typography noWrap style={{ maxWidth: "80%" }} variant="body2">
              {propertyFromWorkspace.name}
            </Typography>
          </PlainButton>

          <PropertyMenu
            anchorEl={propertyMenuAnchor}
            handleClose={handleClosePropertyMenu}
            handleExternalTriggerOpenSelect={handleExternalTriggerOpenSelect}
            handleOpenSelect={handleOpenSelect}
            handleUpdateProperty={handleUpdateProperty}
            property={editProperty}
          />
        </div>
        <Divider flexItem orientation="vertical" />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "calc(100% - 155px)",
            paddingLeft: fixed ? 4 : null,
          }}
        >
          {fixed ? (
            <Typography color="textSecondary">{propertyFromWorkspace.value}</Typography>
          ) : (
            // <CollectionPropertySelect
            //     collection={props.collection}
            //     externalTriggerOpen={externalTriggerOpenSelect}
            //     open={selectOpen}
            //     properties={properties}
            //     property={propertyFromWorkspace}
            // />
            <GitHubLabel
              collection={props.collection}
              externalTriggerOpen={externalTriggerOpenSelect}
              // key={properties}
              open={selectOpen}
              properties={properties}
              property={propertyFromWorkspace}
              selected={properties}
              setSelected={setProperties}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: 1,
          marginTop: 4,
          borderBottom: "1px solid rgb(196, 196, 196, 0.25)",
        }}
      />
    </>
  );
}

export default React.memo(CollectionEditPropertyDiv);
