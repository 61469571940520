import React, { useState, useEffect, useCallback } from "react";
import useGlobal from "../../GlobalState/Store/Store.js";

import update from "immutability-helper";
import axios from "axios";

import MotionListItem from "./MotionListItem";

import useWorkspaces from "../../Hooks/useWorkspaces.js";
import { mutate } from "swr";

const WorkspaceList = (props) => {
  {
    const [globalState, globalActions] = useGlobal();
    const { workspacesData } = useWorkspaces(globalState.isAuth, { revalidateOnMount: false });

    const initWorkspaces = workspacesData && workspacesData.workspaces ? workspacesData.workspaces : [];
    const [workspaces, setWorkSpaces] = useState(initWorkspaces); //its faster to keep this state local to this list, then mutate all state when position is saved

    //Any time global workspaces is changed update the list
    useEffect(() => {
      //console.log("set ws")
      if (workspacesData.workspaces) {
        setWorkSpaces(workspacesData.workspaces);
      }

      return () => {
        //cleanup
        setWorkSpaces([]);
        setDragActive(false);
      };
    }, [workspacesData]);

    const moveWorkspace = useCallback(
      (dragIndex, hoverIndex, workspace) => {
        //console.log("we want to move " + dragIndex + " to " + hoverIndex)
        const dragWorkspace = workspaces[dragIndex];
        //hideIcon()
        setWorkSpaces(
          update(workspaces, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragWorkspace],
            ],
          })
        );
      },
      [workspaces]
    );

    const SaveWorkspaceOrder = (index, curIndex, workspace) => {
      var newOrder = [];
      workspaces.map((ws) => {
        newOrder.push({ name: ws.name, _id: ws._id });
      });
      //console.log({index, curIndex})
      if (index === curIndex) {
        //console.log("SAME ORDER DONT CALL API")
        return;
      }
      //console.log(newOrder)
      axios
        .post("/workspaces/reorder", { workspaceId: workspace._id, newOrder: newOrder })
        .then((res) => {
          mutate("/workspaces", { ...workspacesData, workspaces: workspaces }, false);
        })
        .catch((err) => {
          //console.log("Could not update workspace name")
          console.log(err);
        });
    };

    //props from MainContainer
    const handleClickSelectWorkspace = props.handleClickSelectWorkspace;
    const pathnames = props.pathnames;
    const activeWorkspace = props.activeWorkspace;
    const editWorkSpaceName = props.editWorkSpaceName;
    const handleWorkspaceNameClickAway = props.handleWorkspaceNameClickAway;
    const handleNewWorkspaceName = props.handleNewWorkspaceName;
    const hanldeKeyPress = props.hanldeKeyPress;

    const workspaceMenuAnchorEl = props.workspaceMenuAnchorEl;
    const handleWorkspaceMenuClose = props.handleWorkspaceMenuClose;
    const openDeleteWorkspaceDialog = props.openDeleteWorkspaceDialog;
    const setEditWorkspace = props.setEditWorkspace;
    const handleWorkspaceMenuClick = props.handleWorkspaceMenuClick;
    const newWorkspaceName = props.newWorkspaceName;

    const [dragActive, setDragActive] = useState(false);

    const renderListItem = (workspace, index) => {
      return (
          <MotionListItem
              SaveWorkspaceOrder={SaveWorkspaceOrder}
              clickPosition={props.wsMenuClickPosition}
              dragActive={dragActive}
              editWorkSpaceName={editWorkSpaceName}
              handleClickSelectWorkspace={handleClickSelectWorkspace}
              handleNewWorkspaceName={handleNewWorkspaceName}
              handleWorkspaceMenuClick={handleWorkspaceMenuClick}
              handleWorkspaceMenuClose={handleWorkspaceMenuClose}
              handleWorkspaceNameClickAway={handleWorkspaceNameClickAway}
              hanldeKeyPress={hanldeKeyPress}
              id={workspace._id}
              index={index}
              initWorkspaces={initWorkspaces}
              key={workspace._id}
              moveWorkspace={moveWorkspace}
              newWorkspaceName={newWorkspaceName}
              openDeleteWorkspaceDialog={openDeleteWorkspaceDialog}
              openEmojiMenu={props.openEmojiMenu}
              pathnames={pathnames}
              setDragActive={setDragActive}
              setEditWorkspace={setEditWorkspace}
              text={workspace.name}
              workspace={workspace}
              workspaceMenuAnchorEl={workspaceMenuAnchorEl}
              workspaceOrder={workspaces}
              workspacelistitem
          />
      );
    };
    return (<div style={{ width: "100%", paddingBottom: 20, paddingTop: 12 }}>
        {workspaces.map((workspace, i) => renderListItem(workspace, i))}
    </div>);
  }
};
export default WorkspaceList;
