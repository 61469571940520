/* eslint-disable no-redeclare */
import React, { useState, useEffect, useCallback, useRef } from "react";
import useGlobal from "../../../GlobalState/Store/Store.js";
import update from "immutability-helper";
import { useDrop } from "react-dnd";
import ItemTypes from "../../DND/ItemTypes";

import { useTheme } from "@mui/material/styles";

import { useParams } from "react-router-dom";

import axios from "axios";

import { Typography, Grid } from "@mui/material";

import Add from "@mui/icons-material/Add";

import TabCard from "./TabCard.js";

import useCollections from "../../../Hooks/useCollections.js";
import useCollectionById from "../../../Hooks/useCollectionById.js";

import { mutate } from "swr";
import { useSnackbar } from "notistack";
import PlainSnackBar from "../../Notistack/PlainSnackBar.js";

function TabsGrid(props) {
  const [globalState, globalActions] = useGlobal();
  const { enqueueSnackbar } = useSnackbar();
  let params = useParams();
  const theme = useTheme();

  //A tabs grid can exist in many places, using the global collections and unique link collection ensures the tabs grid is always up to date
  const { collectionsData } = useCollections(
    globalState.activeWorkspace._id,
    globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null,
    { revalidateOnMount: false }
  );
  const { collectionData } = useCollectionById(
    globalState.isAuth,
    // params && params.workspaceid
    //   ? params.workspaceid
    //   : globalState.activeWorkspace && globalState.activeWorkspace._id !== undefined
    //   ? globalState.activeWorkspace._id
    //   : null,
    params && params.collectionid ? params.collectionid : null
  );

  // const collection = props.collection && params && params.collectionid && collectionData && collectionData.collection ?
  //   collectionData.collection
  //   : collectionsData && collectionsData.collections && props.collection ?
  //   collectionsData.collections.find(collection => collection._id == props.collection._id)
  //   : {tabs: []} //collectionsData && collectionsData.collections && props.collection ? collectionsData.collections.find(collection => collection._id == props.collection._id) : props.collection ? props.collection : {tabs: []} //props.collection && props.collection.tabs ? props.collection : {tabs: []}

  const [tabs, setTabs] = useState([]);
  const [collection, setCollection] = useState({ tabs: [] });
  //everytime the workspace changes make sure to update collections
  useEffect(() => {
    if (props.collection && params && params.collectionid && collectionData && collectionData.collection) {
      if (isGridMounted) {
        setCollection(collectionData.collection);
        setTabs(collectionData.collection.tabs.sort(SortTabs));
      }
    } else if (collectionsData && collectionsData.collections && props.collection) {
      let colid = collectionsData.collections.findIndex((collection) => collection._id == props.collection._id);
      if (colid > -1 && isGridMounted) {
        setCollection(collectionsData.collections[colid]);
        setTabs(collectionsData.collections[colid].tabs.sort(SortTabs));
      }
    }

    return () => {
      //cleanup

      isGridMounted = false;
    };
  }, [collectionsData, collectionData]);

  const tabsMenuOpen = globalState.tabsMenuOpen;

  let isGridMounted = true; //use for cleanup

  const handleAddSnackbarToStack = props.handleAddSnackbarToStack;
  //console.log(handleAddSnackbarToStack)

  const inCollectionDialog = props.inCollectionDialog;
  const inAccordian = props.inAccordian;

  const [dragActive, setDragActive] = useState(false);
  const [hoverItem, setHoverItem] = useState({});

  const clearSelectedTabs = props.clearSelectedTabs;

  const tabsLayout = props.tabsLayout;

  const collectionCurrentlyOpen = props.collectionCurrentlyOpen;

  //for dropping
  const ref = useRef(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.CURTAB, ItemTypes.CURWINDOW, ItemTypes.PASTSESSION, ItemTypes.PASTTAB, ItemTypes.TABCARD],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isReadytoDrop: monitor.isReadytoDrop,
    }),
    hover(item) {
      if (item.type === "curtab" || item.type === "curwindow" || item.type === "pastsession" || item.type === "pasttab") {
        if (item && item.selectedTabs && item.selectedTabs.length > 1) {
          item.tabs = item.selectedTabs;
        }
        setHoverItem(item);
      }
    },
    drop(item) {
      if (inAccordian) return; //handled by the colletion component
      if (item.type === "curtab") {
        //console.log("curtab was dropped in collection")
        var tabToAdd = [];
        tabToAdd.push(item.tab);
        AddTabsToCollection(tabToAdd, item.type, item.shiftKeyActive);
      }
      if (item.type === "pasttab") {
        //console.log("curtab was dropped in collection")
        var tabToAdd = [];
        tabToAdd.push(item.tab);
        AddTabsToCollection(tabToAdd, item.type, item.shiftKeyActive);
      }
      if (item.type === "curwindow") {
        AddTabsToCollection(item.tabs, item.type, item.shiftKeyActive);
      }
      if (item.type === "pastsession") {
        AddTabsToCollection(item.tabs, item.type, item.shiftKeyActive);
      }
      setHoverItem({});
    },
  });
  //drop(ref)

  useEffect(() => {
    drop(ref);
    return () => {
      //cleanup
      drop(null);
    };
  }, [drop, ref]);

  function AddTabsToCollection(tabs, type, shiftKeyActive) {
    var addTabs;
    if (tabs.length && tabs.length > 0) {
      addTabs = tabs;
    } else {
      //console.log("not addding any tabs...")
      //console.log(tabs)
    }
    //console.log("Trying to add " + addTabs.length + " tabs to collection " + collection._id)
    //console.log(shiftKeyActive)
    // setReq({
    //   loading: true,
    //   isError: false,
    //   error: null
    // })
    //don't call api if the tabs are the same
    const uniqueTabs = (tabToAdd) => {
      let notUnique = collection.tabs.findIndex((existingTab) => existingTab.url === tabToAdd.url);
      //console.log(tabToAdd.url + " is unique: " + notUnique)
      const prodExtensionUrl = "app.partizion.io";
      const devExtensionUrl = "localhost:3001";
      let notNewTab = tabToAdd.url !== "chrome://newtab/" && !tabToAdd.url.includes(prodExtensionUrl) && !tabToAdd.url.includes(devExtensionUrl) ? true : false;

      if (notUnique === -1 && notNewTab) {
        return true;
      } else {
        return false;
      }
    };

    var methodString = "Dragged a " + type + " into collection page";
    const data = {
      collectionId: collection._id,
      updateType: "add",
      newTabs: addTabs,
      method: methodString,
    };

    if (addTabs.some(uniqueTabs)) {
      if (addTabs.length > 0) {
        axios
          .post("/collections/updatetabs", data)
          .then((res) => {
            //console.log("Added tabs")
            // setReq({
            //   loading: false,
            //   isError: false,
            //   error: null
            // })
            mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null]);
            if (collection && collection._collectionId) {
              mutate([`/collections/getbyid`, collection && collection._collectionId ? collection._collectionId : null]);
            }

            //check if duplicate error is present
            // if (res.data.code === 206) {
            //   props.handleOpenDupSnackbar(res.data.message)
            // }

            if (res.status === 200) {
              //console.log("Saving tab was a success")
              // globalActions.setGlobalSnackbarOpen(true)
              // globalActions.setGlobalSnackbarState({
              //   status: "success",
              //   message: "🙌 tab saved to collection ",
              //   closeTime: 3000
              // })

              //after saving the tab close it
              // if(process.env.NODE_ENV !== 'development') {
              //   //if it was succesfully saved and user is NOT holding shift
              //     if(type === "curwindow" || type === "curtab"){
              //       if(addTabs.length === 1){
              //         browser.tabs.remove(addTabs[0].id)
              //       }
              //     }
              // }

              //console.log("Close all except new tab")
              var inActiveTabs = addTabs.filter(function (tab) {
                return tab.active === false;
              });
              let closeTabs = inActiveTabs.map((tab) => tab.id);

              let message = addTabs.length > 1 ? `✅ Saved tabs` : "✅ Saved tab";
              if (type !== "pasttab" && type !== "pastsession") {
                enqueueSnackbar(message, {
                  content: (key, message) => <PlainSnackBar id={key} message={message} showCloseTabsButton tabsToClose={closeTabs} />,
                });
              }

              clearSelectedTabs();
            }
          })
          .catch((err) => {
            //console.log("add tab error")
            //console.log(err.status)
            //globalActions.setGlobalSnackbarOpen(true)
            // globalActions.setGlobalSnackbarState({
            //   open: true,
            //   status: "error",
            //   message: "🛑 Cannot save the new tab page or duplicates ",
            //   closeTime: 4500
            // })
            var message = "🛑  Cannot save the Partizion Dashboard or duplicates";
            enqueueSnackbar(message, {
              content: (key, message) => <PlainSnackBar id={key} message={message} />,
            });
          });
      }
    } else {
      //globalActions.setGlobalSnackbarOpen(true)
      // globalActions.setGlobalSnackbarState({
      //   open: true,
      //   status: "error",
      //   message: "🛑 Cannot save the new tab page or duplicates ",
      //   closeTime: 4500
      // })
      var message = "🛑  Cannot save the Partizion Dashboard or duplicates";
      enqueueSnackbar(message, {
        content: (key, message) => <PlainSnackBar id={key} message={message} />,
      });
    }
  }

  function ResetTabs(oldtaborder) {
    //console.log("RESET tabs order")
    setTabs(props.collection.tabs.sort(SortTabs));
  }

  const moveTab = useCallback(
    (dragIndex, hoverIndex) => {
      //console.log("move item")
      //console.log("we want to move " + dragIndex + " to " + hoverIndex)
      const dragTab = tabs[dragIndex];
      setTabs(
        update(tabs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragTab],
          ],
        })
      );
    },
    [tabs]
  );

  function SortTabs(a, b) {
    //console.log("TAB A" + a.url + '\n' + a.indexPosition)
    //console.log("TAB B" + b.url + '\n' + b.indexPosition)

    if (a.pinned && !b.pinned) {
      //console.log(a.title + " (" + a.pinned + ") should go BEFORE " + b.title + " (" +b.pinned + ")")
      //console.log("--------------------------------------")
      return -1; //move a ahead of b
    } else if (!a.pinned && b.pinned) {
      //console.log(a.title + " (" + a.pinned + ") should go AFTER " + b.title + " (" +b.pinned + ")")
      //console.log("--------------------------------------")
      return 1; //move b ahead of a
    } else if (a.pinned && b.pinned) {
      //they are both pinned which means we just want to order by indexPosiiton first - not last edited (could have moved a pinned tab back)
      if (a.indexPosition < b.indexPosition) {
        //console.log("Both are pinned... " + a.title + " should go BEFORE " + b.title + "(indexPosition)")
        //console.log("--------------------------------------")
        return -1;
      } else if (a.indexPosition > b.indexPosition) {
        //console.log("Both are pinned... " + a.title + " should go AFTER " + b.title + "(indexPosition)")
        //console.log("--------------------------------------")
        return 1;
      } else {
        //console.log("index a and b are equal or DNE")
        if (a.indexUpdatedOn < b.indexUpdatedOn) {
          //console.log("index a was updated before b")
          //console.log("Both pinned - " + a.title + " should go AFTER " + b.title + "(updated on)")
          //console.log("--------------------------------------")
          return 1;
        } else if (a.indexUpdatedOn > b.indexUpdatedOn) {
          //console.log("index a was updated after b")
          //console.log("Both pinned - " + a.title + " should go BEFORE " + b.title + "(updated on)")
          //console.log("--------------------------------------")
          return -1;
        } else {
          //console.log("index does not exist")
          if (a.createdOn < b.createdOn) {
            //console.log("a was created before b")
            return -1;
          } else if (a.createdOn > b.createdOn) {
            //console.log("a was created after b")
            return 1;
          } else {
            //console.log("dont change pos")
            return 0;
          }
        }
      }
    } else {
      //NEITHER TAB IS PINNED
      if (a.indexPosition < b.indexPosition) {
        //console.log("index a is less than b")
        return -1; //a before b
      } else if (a.indexPosition > b.indexPosition) {
        //console.log("index a is greater than b")
        return 1;
      } else {
        //console.log("index a and b are equal or DNE")
        if (a.indexUpdatedOn < b.indexUpdatedOn) {
          //console.log("index a was updated before b")
          return 1;
        } else if (a.indexUpdatedOn > b.indexUpdatedOn) {
          //console.log("index a was updated after b")
          return -1;
        } else {
          //console.log("index does not exist")
          if (a.createdOn < b.createdOn) {
            //console.log("a was created before b")
            return -1;
          } else if (a.createdOn > b.createdOn) {
            //console.log("a was created after b")
            return 1;
          } else {
            //console.log("dont change pos")
            return 0;
          }
        }
      }
    }
  }

  const [hover, setHover] = useState(null);

  const renderListItem = (tab, index) => {
    return (
      <TabCard
        ResetTabs={ResetTabs}
        SortTabs={SortTabs}
        collection={collection}
        collectionsData={collectionsData}
        dragActive={dragActive}
        id={tab._id}
        inAccordian={inAccordian}
        inCollectionDialog={inCollectionDialog}
        index={index}
        key={tab._id}
        moveTab={moveTab}
        params={params}
        setDragActive={setDragActive}
        setTabs={setTabs}
        tab={tab}
        tabsLayout={tabsLayout}
        tabsOrder={tabs}
      />
    );
  };

  const isDropTab =
    (isOver && hoverItem && hoverItem.type === "curtab") ||
    (isOver && hoverItem && hoverItem.type === "curwindow") ||
    (isOver && hoverItem && hoverItem.type === "pastsession") ||
    (isOver && hoverItem && hoverItem.type === "pasttab")
      ? true
      : false;

  return (
    <Grid
      ref={ref}
      style={{
        height: "100%",
        minHeight: inAccordian ? null : "70vh",
        maxWidth: "100%",
      }}
    >
      <Grid container spacing={2}>
        {tabs.map((tab, index) => renderListItem(tab, index))}

        {!collectionCurrentlyOpen && (
          <Grid
            item
            lg={inAccordian && tabsLayout && tabsLayout == "List" ? 12 : tabsMenuOpen || inCollectionDialog ? 4 : 3}
            md={inAccordian && tabsLayout && tabsLayout == "List" ? 12 : tabsMenuOpen || inCollectionDialog ? 6 : 4}
            sm={(inAccordian && tabsLayout && tabsLayout == "List") || tabsMenuOpen ? 12 : 6}
            xl={inAccordian && tabsLayout && tabsLayout == "List" ? 12 : inCollectionDialog ? 4 : tabsMenuOpen ? 3 : 2}
            xs={12}
          >
            <div
              onClick={(e) => props.openAddTabsDialog(e, collection)}
              onMouseLeave={() => setHover(false)}
              onMouseOver={() => setHover(true)}
              style={{
                borderRadius: 4,
                border: isDropTab && !inAccordian ? "2px solid #3d5AFE" : `1px solid ${theme.palette.divider}`,
                backgroundColor: globalState.extensionConnection.isConnected
                  ? isDropTab && !inAccordian
                    ? "rgb(61, 90, 254, 0.20)"
                    : hover
                      ? theme.palette.collection.hover
                      : "transparent"
                  : theme.palette.collection.hover,
                opacity: globalState.extensionConnection.isConnected ? 1 : 0.5,
                //borderColor: isOver ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
                width: "100%",
                height: "100%",
                minHeight: 50,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                cursor: globalState.extensionConnection.isConnected ? "pointer" : undefined,
              }}
            >
              {globalState.extensionConnection.isConnected && (
                <Add
                  color="disabled"
                  style={{
                    marginRight: 8,
                    color: isDropTab && !inAccordian ? "#3d5AFE" : null,
                  }}
                />
              )}
              {isDropTab && !inAccordian && globalState.extensionConnection.isConnected ? (
                hoverItem && hoverItem.tabs && hoverItem.tabs.length > 1 ? (
                  <Typography style={{ color: "#3d5AFE", fontSize: 14 }}>
                    {" "}
                    Add
                    {hoverItem.tabs.length} tabs to collection{" "}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#3d5AFE", fontSize: 14 }}> Add tab to collection </Typography>
                )
              ) : (
                <Typography color="textSecondary" style={{ opacity: 0.75, fontSize: globalState.extensionConnection.isConnected ? 14 : 12 }}>
                  {globalState.extensionConnection.isConnected ? "Add tabs to collection" : "Connect to the Extension to add tabs"}
                </Typography>
              )}
            </div>
          </Grid>
        )}

        {/* ) : (
          <Grid item xs={12}>
            <div
              style={{
                borderRadius: 4,
                // border: `1px solid ${theme.palette.divider}`,
                backgroundColor: "rgba(239, 239, 239, 0.5)",
                opacity: 0.5,
                width: "100%",
                height: "100%",
                minHeight: 45,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 12,
              }}
            >
              <Typography color="textSecondary" style={{ opacity: 0.75, fontSize: 14 }}>
                {" "}
                Connect to the Extension to Add tabs{" "}
              </Typography>
            </div>
          </Grid> */}
      </Grid>
    </Grid>
  );
}
export default TabsGrid;
