/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useCallback } from "react";
import useGlobal from "../../GlobalState/Store/Store.js";

import axios from "axios";

import CollectionComponent from "./CollectionComponent";

import EditValueMenu from "./Properties/EditValueMenu.js";

import KanbanColumn from "./Kanban/KanbanColumn.js";
import HiddenColumnButton from "./Kanban/HiddenColumnButton.js";
import update from "immutability-helper";
import flowRight from "lodash.flowright";

import { colors as labelColors } from "../../Utils/StandardColors.js";

import { useHotkeys } from "react-hotkeys-hook";

import { Typography, Button, ClickAwayListener, Grid, CircularProgress, Paper, InputBase } from "@mui/material";

import Add from "@mui/icons-material/Add";

import ConfirmDeletePropertyDialog from "../Dialogs/ConfirmDeleteProperty.js";

import CollectionDialog from "./CollectionDialog.js";

import useWorkspaces from "../../Hooks/useWorkspaces.js";
import { mutate } from "swr";
import { useTheme, styled } from "@mui/material/styles";

const PlainButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.button.plain,
  paddingBottom: 2,
  paddingTop: 2,
  paddingLeft: 8,
  paddingRight: 8,
  minHeight: 0,

  "&:hover": {
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
  "&:active": {
    backgroundColor: theme.palette.action.active,
    // color: theme.palette.text.primary,
  },
}));

const CollectionList = (props) => {
  {
    const [globalState, globalActions] = useGlobal();
    const { workspacesData } = useWorkspaces(globalState.isAuth, {
      revalidateOnMount: false,
    });

    const theme = useTheme();

    const {
      openCollectionDialog,
      tabsMenuOpen,
      createDragCollection,
      CreateCollection, //for the kanban drop handler only
      GetWorkspacebyID,
      collections,
      handleToggleAutoUpdateTabs,
      moveCollectionCard,
      clearSelectedTabs,
      handleAddSnackbarToStack,
      createReq,
    } = props;

    const [dragActive, setDragActive] = useState(false);

    //const windowSize = props.windowSize

    //VIEWS
    const activeView = globalState.activeView; //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

    const [sizePreference, setSizePreference] = useState("medium"); //= activeView && activeView.properites ? activeView.properites.size : "medium"
    const [tabPreview, setTabPreview] = useState(true); //= activeView && activeView.properites ? activeView.properites.preview == "tabs" ? true : false : true
    const [previewMax, setPreviewMax] = useState("3");
    const maxTabsInList = collections ? Math.max(...collections.map((c) => c.tabs.length)) : 1;
    //const [maxTabsInList, setMaxTabsInList] = useState(1)

    const [cardTitlePosition, setCardTitlePosition] = useState("bottom");
    const [viewType, setViewType] = useState("Grid"); //= activeView ? activeView.viewType : "Grid"
    const [showLastUpdated, setShowLastUpdated] = useState(false);
    const [showCreatedOn, setShowCreatedOn] = useState(false);
    const [showTabCount, setShowTabCount] = useState(true);
    const [tabsLayout, setTabsLayout] = useState("Grid");

    const columnWidth = sizePreference == "large" ? 500 : sizePreference == "medium" ? 400 : 300;

    //DEFAULT PROPERTIES
    useEffect(() => {
      let isListMounted = true;
      if (isListMounted) {
        if (globalState.activeView && globalState.activeView.properties) {
          //SIZE
          if (globalState.activeView.properties.hasOwnProperty("size")) {
            setSizePreference(globalState.activeView.properties.size);
          } else {
            setSizePreference("medium");
          }
          //TAB PREVIEW
          if (globalState.activeView.properties.preview == "tabs") {
            setTabPreview(true);
          } else {
            setTabPreview(false);
          }
          //PREVIEW MAX
          if (globalState.activeView.properties.hasOwnProperty("previewMax")) {
            setPreviewMax(globalState.activeView.properties.previewMax);
          } else {
            setPreviewMax("3");
          }
          //CARD TITLE POSITION
          if (globalState.activeView.properties.hasOwnProperty("cardTitlePosition")) {
            setCardTitlePosition(globalState.activeView.properties.cardTitlePosition);
          } else {
            setCardTitlePosition("bottom");
          }
          //SHOW TAB COUNT
          if (globalState.activeView.properties.hasOwnProperty("showTabCount")) {
            setShowTabCount(globalState.activeView.properties.showTabCount);
          } else {
            setShowTabCount(true);
          }
          //SHOW CREATED ON
          if (globalState.activeView.properties.hasOwnProperty("showCreatedOn")) {
            setShowCreatedOn(globalState.activeView.properties.showCreatedOn);
          } else {
            setShowCreatedOn(false);
          }
          //SHOW LAST UPDATED
          if (globalState.activeView.properties.hasOwnProperty("showLastUpdated")) {
            setShowLastUpdated(globalState.activeView.properties.showLastUpdated);
          } else {
            setShowLastUpdated(true);
          }
          //VIEW TYPE
          if (globalState.activeView.viewType) {
            setViewType(globalState.activeView.viewType);
          } else {
            setViewType("Grid");
          }
        } else {
          //no active view reset to defauly properties
          //console.log("No active view - setting to default")
          setSizePreference("medium");
          setTabPreview(true);
          setShowTabCount(true);
          setShowLastUpdated(true);
          setShowCreatedOn(false);
          setViewType("Grid");
          setTabsLayout("Grid");
        }
      }

      //Full view
      if (globalState.activeView && globalState.activeView.fullViewProperties && globalState.activeView.fullViewProperties.tabsLayout) {
        if (isListMounted) setTabsLayout(globalState.activeView.fullViewProperties.tabsLayout);
      } else {
        if (isListMounted) setTabsLayout("Grid");
      }

      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeView]);

    //CUSTOM PROPERTIES
    //set custom view properties
    const [showCustomProperties, setShowCustomProperties] = useState({});

    useEffect(() => {
      let isListMounted = true;
      if (
        globalState.activeView &&
        globalState.activeWorkspace &&
        globalState.activeWorkspace.collectionProperties &&
        globalState.activeWorkspace.collectionProperties.length > 0
      ) {
        //console.log(globalState.activeWorkspace.collectionProperties)

        if (globalState.activeView.customProperties) {
          //the view has values set
          let initState = {};
          globalState.activeWorkspace.collectionProperties.forEach((property, index) => {
            initState[property._id] = true;

            if (globalState.activeView.customProperties.hasOwnProperty(property._id)) {
              initState[property._id] = globalState.activeView.customProperties[property._id];
            } else {
              initState[property._id] = true; //defaults to true to show the custom properties
            }

            if (index == globalState.activeWorkspace.collectionProperties.length - 1) {
              if (isListMounted) setShowCustomProperties(initState);
            }
          });
        } else {
          //the view doesn't have any values set yet
          let initState = {};
          globalState.activeWorkspace.collectionProperties.forEach((property, index) => {
            initState[property._id] = true;

            if (index == globalState.activeWorkspace.collectionProperties.length - 1) {
              if (isListMounted) setShowCustomProperties(initState);
            }
          });
        }
      }
      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeWorkspace.collectionProperties, globalState.activeView]);

    //CARD SIZES
    var x = window.innerWidth; //windowSize.width
    var dashBoardWidth = tabsMenuOpen ? x - (300 + props.drawerWidth) : x - props.drawerWidth;

    var cardMinWidth = sizePreference == "large" ? 400 : sizePreference == "medium" ? 320 : 250;

    var allSizes = [1, 2, 3, 4, 5, 6]; // [1, 2, 3, 4, 5, 6]

    let tolerance = x >= 1920 ? 10 : x >= 1280 ? 20 : x >= 960 ? 25 : x >= 600 ? 30 : 35;
    var correctedCoeff = function (coeff) {
      var smallSide = dashBoardWidth / allSizes[coeff];
      var largeSide = allSizes[coeff] * cardMinWidth;
      //console.log(smallSide - tolerance + " < " + cardMinWidth)
      //console.log(largeSide + tolerance + " >= " + dashBoardWidth)

      if (smallSide - tolerance <= cardMinWidth || largeSide + tolerance >= dashBoardWidth) {
        //too small, decrement
        //console.log(coeff + " is too small for width: " + dashBoardWidth + " and preference: " + sizePreference)
        if (coeff - 1 >= 0) {
          return correctedCoeff(coeff - 1);
        } else {
          //corrected = coeff
          return coeff;
        }
      } else {
        //corrected = coeff
        return coeff;
      }

      // if (number <= 0) { // terminal case
      //   return 1;
      // } else { // block to execute
      //   return (number * factorial(number - 1));
      // }
    };

    var correctCoeff = correctedCoeff(5);

    var cardSizeDecimal = allSizes[correctCoeff];
    var cardSizeInt = (1 / cardSizeDecimal) * 100;
    var cardSizePercentage = `${cardSizeInt}%`;

    //COLLECTION DIALOG
    const [collectionDialogState, setCollectionDialogState] = useState(null);
    const collectionDialogOpen = Boolean(collectionDialogState);
    const handleOpenCollectionDialog = (collection) => {
      setCollectionDialogState(collection);
      //setCreateCollectionnHotkeyEnabled(false)
    };
    const handleCloseCollectionDialog = () => {
      setCollectionDialogState(null);
      //setCreateCollectionnHotkeyEnabled(true)
    };

    const handleOpenTabs = (event, collection, type) => {
      event.stopPropagation();
      event.preventDefault();
      const workspace = globalState.workspaces.find((w) => w._id === collection.workspace);
      //console.log(workspace)
      if (globalState.extensionConnection.isConnected) {
        var shiftKey = event.shiftKey ? true : false;
        globalState.extensionConnection.port.postMessage({
          type: "openCollection",
          shiftKey: shiftKey,
          newtabs: collection.tabs,
          openType: type,
          windowId: globalState.extensionConnection.windowId,
          tabId: globalState.extensionConnection.tabId,
          collection: collection,
          workspace: workspace,
        });
      }
    };

    const renderListItem = (collection, index, group, showDropInColumn) => {
      if (collection && props.collections && props.collections.length > 0) {
        return (
          <CollectionComponent
            GetWorkspacebyID={GetWorkspacebyID}
            cardMinWidth={cardMinWidth}
            cardSizePercentage={cardSizePercentage}
            cardSizePreference={sizePreference}
            cardTitlePosition={cardTitlePosition}
            clearSelectedTabs={clearSelectedTabs}
            collection={collection}
            collectionsOrder={props.collections}
            dragActive={dragActive}
            expandedCollectionIds={expandedCollectionIds}
            handleAddSnackbarToStack={handleAddSnackbarToStack}
            handleOpenCollectionDialog={handleOpenCollectionDialog}
            //view settings
            handleToggleAutoUpdateTabs={handleToggleAutoUpdateTabs}
            id={collection._id}
            index={index}
            kanbanCollectionOrder={resourceOrder}
            kanbanGroup={group}
            key={collection._id}
            maxTabsInList={maxTabsInList}
            moveCollectionCard={moveCollectionCard}
            moveCollectionCardInKanban={moveCollectionCardInKanban}
            openAddTabsDialog={props.openAddTabsDialog}
            previewMax={previewMax}
            resetCollectionOrder={props.resetCollectionOrder}
            setDragActive={setDragActive}
            setShowCreateCollectionWithDrag={setShowCreateCollectionWithDrag}
            //kanban
            setShowDropInColumn={setShowDropInColumn}
            showCreatedOn={showCreatedOn}
            showCustomProperties={showCustomProperties}
            showDropInColumn={showDropInColumn}
            showLastUpdated={showLastUpdated}
            showTabCount={showTabCount}
            tabPreview={tabPreview}
            tabsLayout={tabsLayout}
            //full view
            toggleCollectionExpanded={toggleCollectionExpanded}
            updateCollectionOrder={props.updateCollectionOrder}
            viewType={viewType}
          />
        );
      } else {
        return null;
      }
    };

    const [hover, setHover] = useState(false);

    //** START OF FULL VIEW ** //
    const [expandedCollectionIds, setExpandedCollectionIds] = useState([]);

    //initate expanded collections
    useEffect(() => {
      let isListMounted = true;
      if (globalState.activeView && globalState.activeView.fullViewProperties && globalState.activeView.fullViewProperties.expandedCollections) {
        if (isListMounted) setExpandedCollectionIds(globalState.activeView.fullViewProperties.expandedCollections);
      }

      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeView]);

    const toggleCollectionExpanded = async (e, collectionId, state) => {
      //console.log("Set " + collectionId + " to " + state)
      e.preventDefault();
      e.stopPropagation();

      let updatedIds;
      let reset = [...expandedCollectionIds];

      if (state === true) {
        updatedIds = [...expandedCollectionIds, collectionId];
        setExpandedCollectionIds(
          update(expandedCollectionIds, {
            $push: [collectionId],
          })
        );
      } else {
        updatedIds = expandedCollectionIds.filter((id) => id !== collectionId);
        setExpandedCollectionIds((ids) => ids.filter((id) => id !== collectionId));
      }

      const updatedView = {
        ...globalState.activeView,
        fullViewProperties: { expandedCollections: updatedIds },
      };
      globalActions.setActiveView(updatedView);

      const data = {
        viewId: globalState.activeView._id,
        expandedCollections: updatedIds,
      };
      const result = await axios.post("/views/setexpandedcollections", data);

      if (!result || (result && result.status && result.status != 200)) {
        const resetView = {
          ...globalState.activeView,
          fullViewProperties: { expandedCollections: reset },
        };
        globalActions.setActiveView(resetView);
        setExpandedCollectionIds(reset);
      }
    };

    //********************** END OF FULL VIEW *************************** //

    //********************* START OF KANBAN **************************** //
    const [columnOrder, setColumnOrder] = useState(false);
    const [resourceOrder, setResourceOrder] = useState(false);
    const [groupedCollections, setGroupedCollections] = useState([]);

    const hexToRgb = (hex) => {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    };

    //Editing property in kanban
    const [editValueAnchor, setEditValueAnchor] = React.useState(null);
    const [valueToEdit, setValueToEdit] = useState(null);
    const saveUpdatedValue = (updatedValue) => {
      if (updatedValue.name == null || updatedValue.name == "" || updatedValue.name == " " || !updatedValue.name.replace(/\s/g, "").length)
        updatedValue.name = valueToEdit.name;
      let data;
      if (updatedValue && updatedValue._id == "none") {
        data = {
          workspaceId: globalState.activeWorkspace._id,
          propertyId: groupBy._id,
          updatedProperty: {
            name: updatedValue.name,
          },
        };
      } else {
        data = {
          workspaceId: globalState.activeWorkspace._id,
          propertyId: groupBy._id,
          updatedProperty: {
            values: [
              {
                _id: updatedValue._id,
                color: updatedValue.color,
                name: updatedValue.name,
                value: updatedValue.name,
              },
            ],
          },
        };
      }

      if (updatedValue && updatedValue._id && data) {
        axios
          .post(`/workspaces/collectionProperties/update`, data)
          .then(() => {
            //console.log(res)
            GetWorkspacebyID(globalState.activeWorkspace, true, null);
          })
          .catch((err) => {
            //console.log("Could not update property value")
            console.log(err);
            GetWorkspacebyID(globalState.activeWorkspace, false, null);
          });
      }
    };
    const updateValue = (event, updatedValue) => {
      //console.log({updatedValue})
      // console.log(groupedCollections)

      let editedValueIndex = groupedCollections.findIndex((g) => g._id === updatedValue._id);
      // const globalStateIndex = globalState.activeWorkspace ? globalState.activeWorkspace.collectionProperties.findIndex(p => p._id === updatedValue.propertyId) : -1
      // const globalStateValueIndex = globalStateIndex > -1 ? globalState.activeWorkspace.collectionProperties[globalStateIndex].values.findIndex(v => v._id === updatedValue._id) : -1

      //console.log(updatedValue.propertyId, globalState.activeWorkspace.collectionProperties, globalStateIndex, globalStateValueIndex)
      if (editedValueIndex > -1) {
        setGroupedCollections(
          update(groupedCollections, {
            [editedValueIndex]: { property: { $set: updatedValue } },
          })
        );
        //groupedCollections[editedValueIndex].property = updatedValue
      }
    };

    const handleOpenEditValue = (event, value) => {
      event.preventDefault();
      event.stopPropagation();
      //console.log(value)
      setEditValueAnchor(event.currentTarget);
      setValueToEdit(value);
    };
    const handleCloseEditValue = (ableToUpdate, updatedValue) => {
      setEditValueAnchor(null);
      //console.log({ableToUpdate, updatedValue})
      if (ableToUpdate && updateValue) {
        saveUpdatedValue(updatedValue);
      }
    };

    //sort the groups (columns) if needed
    const mapOrderColumns = (array, order) => {
      //console.log("orderign kanabn columns", order)
      //filters out just delteed collection so as not to call a regroup twice (As opposed to filtering out the id in the column order after deleting)
      // if(groupBy && groupBy.values){
      //   console.log("filtering out column order", groupBy.values)
      //   order = order.filter(cId => groupBy.values.findIndex(val => val._id == cId) < 0)
      // }
      if (!order || (order && order.length < 1)) order = ["none"];

      array.sort(function (a, b) {
        var A = a._id,
          B = b._id; //this will be an "ID" or "none" Object.keys(b)[0]
        //console.log(order.indexOf(A), order.indexOf(B))

        if (order.indexOf(A) == -1 && order.indexOf(B) != -1) {
          return 1;
        } else if (order.indexOf(A) != -1 && order.indexOf(B) == -1) {
          return -1;
        } else if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else if (order.indexOf(A) < order.indexOf(B)) {
          return -1;
        } else {
          return 1;
        }
      });

      return array;
    };
    //sort the resources (collections etc.) if needed
    const mapOrderResources = (array, order) => {
      //console.log("orderign collections within a column", order)
      if (!order || (order && order.length < 1)) return array;
      array.sort(function (a, b) {
        var A = a._id,
          B = b._id; //this will be an "ID"
        //console.log(order.indexOf(A), order.indexOf(B))

        if (order.indexOf(A) == -1 && order.indexOf(B) != -1) {
          return 1;
        } else if (order.indexOf(A) != -1 && order.indexOf(B) == -1) {
          return -1;
        } else if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else if (order.indexOf(A) < order.indexOf(B)) {
          return -1;
        } else {
          return 1;
        }
      });

      return array;
    };
    //itertae through grouped and order resources using above function
    // let resourceOrder = [
    //   {
    //     propertyValueId: "5fee073a359766df4ae77320",
    //     order: ['5ff7325f5ac0591af25e39d6', '5ff72905533f3c1a06097cc9']
    //   },
    // ]
    const CheckResourceOrder = (grouped, providedOrder) => {
      let order = !!providedOrder && providedOrder.length > 0 ? providedOrder : resourceOrder;
      //console.log({providedOrder, resourceOrder})
      //also check that no sorts are currently in the view
      let noActiveSorts =
        (globalState.activeView && !globalState.activeView.sort) ||
        (globalState.activeView && globalState.activeView.sort && globalState.activeView.sort.length < 1)
          ? true
          : false;
      if (order && order.length > 0 && grouped && noActiveSorts) {
        return grouped.map((column) => {
          let collectionOrderInColumn = order.find((columnOrder) => columnOrder && !!columnOrder.propertyValueId && columnOrder.propertyValueId === column._id);
          //console.log({collectionOrderInColumn})
          if (
            column.collections &&
            column.collections.length > 0 &&
            collectionOrderInColumn &&
            collectionOrderInColumn.order &&
            collectionOrderInColumn.order.length > 0
          ) {
            //console.log("reodering column with collections", column.collections)
            return {
              ...column,
              collections: mapOrderResources(column.collections, collectionOrderInColumn.order),
            };
          } else {
            return column;
          }
        });
      } else {
        return grouped;
      }
    };

    const GroupCollections = (collections) => {
      //console.log({collections, groupBy, values})
      //console.log("regrouping columns")
      let columnGroupBy =
        globalState.activeView &&
        globalState.activeView.kanbanProperties &&
        globalState.activeView.kanbanProperties.groupBy &&
        globalState.activeWorkspace &&
        globalState.activeWorkspace.collectionProperties &&
        globalState.activeWorkspace.collectionProperties.findIndex((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id) > -1 &&
        globalState.activeWorkspace.collectionProperties.find((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id).type ==
          "singleSelect"
          ? globalState.activeWorkspace.collectionProperties.find((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id)
          : groupBy;

      //? globalState.activeView.kanbanProperties.groupBy : groupBy
      //initialize grouped object

      // let groupedBy = groupBy && groupBy != null && groupBy != undefined ?
      // groupBy
      // :
      // globalState.activeWorkspace &&  globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0 ?
      // globalState.activeView && globalState.activeView.kanbanProperties && globalState.activeView.kanbanProperties.groupBy
      // && globalState.activeWorkspace.collectionProperties.findIndex(property => property._id == globalState.activeView.kanbanProperties.groupBy._id) > -1 ?
      // globalState.activeView.kanbanProperties.groupBy
      // :
      // globalState.activeWorkspace.collectionProperties[0] ?
      // globalState.activeWorkspace.collectionProperties[0]
      // : null
      // :
      // null

      let grouped = [
        {
          _id: "none",
          none: true,
          //property: groupBy,
          property: {
            name: columnGroupBy && columnGroupBy ? columnGroupBy.name : "None",
            color: "default",
            value: "none",
            _id: "none",
          },
          collections: [],
        },
      ];
      let hidden = {
        _id: "hidden",
        property: {
          _id: "hidden",
        },
        columns: [],
      };
      hiddenColumnIds.forEach((id) =>
        hidden.columns.push({
          _id: id,
          collections: [],
        })
      );

      //initialize the grouping
      if (columnGroupBy && columnGroupBy.values) {
        columnGroupBy.values.forEach((v) => {
          //esnure value is still valid
          //if(globalState.activeWorkspace.collectionProperties)
          grouped.push({
            _id: v._id,
            property: v,
            collections: [],
          });
        });
      }

      //filter out deleted collections and collections that don't match the activeView filter
      collections = collections.filter((c) => justDeletedCollections.indexOf(c._id) < 0);

      //place the collections in groups
      collections.map((collection) => {
        const propertyIndex =
          collection && collection.properties && columnGroupBy ? collection.properties.findIndex((p) => p.propertyId == columnGroupBy._id) : -1;
        const noneIndex = grouped.findIndex((group) => group._id === "none");

        if (propertyIndex > -1) {
          if (collection.properties[propertyIndex].values && collection.properties[propertyIndex].values.length > 0) {
            //console.log({hiddenColumnIds})
            // collection.properties[propertyIndex].values.forEach(v => { <- this method is for labels (multiselects) it pushes the same collection to multiple columns

            let v = collection.properties[propertyIndex].values[0];
            const i = grouped.findIndex((group) => group._id == v);
            //console.log(v)
            //remove hidden collections
            if (hiddenColumnIds.indexOf(v) > -1) {
              //console.log(v + ' IS A HIDDEN COLUMN', hiddenColumnIds.indexOf(v))

              let hiddenColumnIndex = hidden.columns.findIndex((col) => col._id == v);
              if (hiddenColumnIndex > -1) {
                hidden.columns[hiddenColumnIndex].collections.push(collection);
              } else {
                hidden.columns.push({
                  _id: v,
                  collections: [collection],
                });
              }
            } else {
              if (i > -1) {
                grouped[i].collections.push(collection);
              } else {
                grouped[noneIndex].collections.push(collection);
              }
            }
          } else if (hiddenColumnIds.indexOf("none") > -1) {
            //first check if this is the none column hidden
            let hiddenColumnIndex = hidden.columns.findIndex((col) => col._id == "none");
            if (hiddenColumnIndex > -1) {
              hidden.columns[hiddenColumnIndex].collections.push(collection);
            } else {
              hidden.columns.push({
                _id: "none",
                collections: [collection],
              });
            }
          } else {
            grouped[noneIndex].collections.push(collection);
          }
        } else {
          if (hiddenColumnIds.indexOf("none") > -1) {
            //console.log("NONE is hidden")
            let hiddenColumnIndex = hidden.columns.findIndex((col) => col._id == "none");
            if (hiddenColumnIndex > -1) {
              hidden.columns[hiddenColumnIndex].collections.push(collection);
            } else {
              hidden.columns.push({
                _id: "none",
                collections: [collection],
              });
            }
          } else {
            grouped[noneIndex].collections.push(collection);
          }
        }
      });

      // setHiddenColumns(hidden) -> moved to below where groupoed is ordered and set

      //Initialize resource order
      let localResourceOrder;
      if (
        globalState.activeView &&
        globalState.activeView.kanbanProperties &&
        globalState.activeView.kanbanProperties.resourceOrder &&
        globalState.activeView.kanbanProperties.resourceOrder.length > 0
      ) {
        if (!resourceOrder) {
          //console.log("using DB resource order", globalState.activeView.kanbanProperties.resourceOrder)
          localResourceOrder = globalState.activeView.kanbanProperties.resourceOrder.filter(
            (r) =>
              (!!r &&
                !!r.propertyValueId &&
                columnGroupBy &&
                columnGroupBy.values &&
                r.order &&
                r.order.length > 1 &&
                columnGroupBy.values.findIndex((v) => v._id === r.propertyValueId) > -1) ||
              (!!r && !!r.propertyValueId && columnGroupBy && columnGroupBy.values && r.order && r.order.length > 1 && r.propertyValueId === "none")
          );
          //console.log({localResourceOrder})

          if (localResourceOrder && localResourceOrder.length > 0) {
            //use the DB value
            setResourceOrder(localResourceOrder);
            grouped = CheckResourceOrder(grouped, localResourceOrder);
            if (hidden && hidden.columns) {
              setHiddenColumns({
                ...hidden,
                columns: CheckResourceOrder(hidden.columns, localResourceOrder),
              });
            } else setHiddenColumns(hidden);
          } else {
            //not valid, use current order
            setHiddenColumns(hidden);

            //console.log("DB order exists but not valid")
            localResourceOrder = grouped.map((col) => {
              if (col && col._id && col.collections && col.collections.length > 1) {
                let obj = {
                  propertyValueId: col._id,
                  order: col.collections.map((collection) => collection._id),
                };

                return obj;
              } else {
                return null;
              }
            });
            let hiddenColumnResourceOrder =
              hiddenColumns && hiddenColumns.columns && hiddenColumns.columns.length > 0
                ? hiddenColumns.columns.map((col) => {
                    if (
                      col &&
                      col._id &&
                      col.collections &&
                      col.collections.length > 1 &&
                      localResourceOrder.findIndex((column) => column && column.propertyValueId == col._id) == -1
                    ) {
                      let obj = {
                        propertyValueId: col._id,
                        order: col.collections.map((collection) => collection._id),
                      };

                      return obj;
                    } else {
                      return null;
                    }
                  })
                : [];

            let fullResourceOrder = localResourceOrder.concat(hiddenColumnResourceOrder);
            fullResourceOrder = [...new Set([...localResourceOrder, ...hiddenColumnResourceOrder])];
            setResourceOrder(fullResourceOrder);
          }
        } else {
          //console.log("using already set local resource order", resourceOrder)
          grouped = CheckResourceOrder(grouped, resourceOrder);
          if (hidden && hidden.columns) {
            setHiddenColumns({
              ...hidden,
              columns: CheckResourceOrder(hidden.columns, resourceOrder),
            });
          } else setHiddenColumns(hidden);
        }
      } else {
        if (grouped && grouped.length > 0 && !resourceOrder) {
          //console.log("initializing resource order with current order")
          localResourceOrder = grouped.map((col) => {
            if (col && col._id && col.collections && col.collections.length > 1) {
              let obj = {
                propertyValueId: col._id,
                order: col.collections.map((collection) => collection._id),
              };

              return obj;
            } else {
              return null;
            }
          });
          let hiddenColumnResourceOrder =
            hiddenColumns && hiddenColumns.columns && hiddenColumns.columns.length > 0
              ? hiddenColumns.columns.map((col) => {
                  if (
                    col &&
                    col._id &&
                    col.collections &&
                    col.collections.length > 1 &&
                    localResourceOrder.findIndex((column) => column && column.propertyValueId == col._id) == -1
                  ) {
                    let obj = {
                      propertyValueId: col._id,
                      order: col.collections.map((collection) => collection._id),
                    };

                    return obj;
                  } else {
                    return null;
                  }
                })
              : [];
          let fullResourceOrder = localResourceOrder.concat(hiddenColumnResourceOrder);
          fullResourceOrder = [...new Set([...localResourceOrder, ...hiddenColumnResourceOrder])];
          setResourceOrder(fullResourceOrder);
        } else {
          //console.log("resource order is set or no grouped obj yet", resourceOrder)
        }
        setHiddenColumns(hidden);
      }

      //return grouped collectiosn with column order
      if (
        globalState.activeView &&
        globalState.activeView.kanbanProperties &&
        globalState.activeView.kanbanProperties.columnOrder &&
        globalState.activeView.kanbanProperties.columnOrder.length > 0 &&
        columnGroupBy &&
        columnGroupBy.values
      ) {
        if (!columnOrder) {
          //console.log("using DB order")
          let validatedOrder = globalState.activeView.kanbanProperties.columnOrder.filter(
            (c) => columnGroupBy.values.findIndex((v) => v._id === c) > -1 || c === "none"
          );
          grouped = CheckResourceOrder(grouped);
          //[...new Set()]
          setColumnOrder(validatedOrder);
          return mapOrderColumns(grouped, globalState.activeView.kanbanProperties.columnOrder);
        } else {
          grouped = CheckResourceOrder(grouped);
          //&& hiddenColumnIds.indexOf(v._id) > -1
          //console.log("Using", columnOrder)
          return mapOrderColumns(grouped, columnOrder);
        }
      } else {
        // let order = ["5fe20882ca8136729ef5a96d", "5fd4048b6720d27fd12913e8"]
        if (grouped && grouped.length > 1 && !columnOrder) {
          //console.log("initializing column order")
          setColumnOrder(grouped.map((col) => col._id)); //.filter(id => id != "none")) keep so they can choose where 'none' goes
          let order = ["none"];
          grouped = CheckResourceOrder(grouped);
          return mapOrderColumns(grouped, order);
        } else {
          grouped = CheckResourceOrder(grouped);
          //console.log("using", columnOrder)
          return mapOrderColumns(grouped, columnOrder);
        }
      }
    };

    const [groupBy, setGroupBy] = useState();
    let noGroupByField =
      !groupBy === null ||
      (groupBy && !groupBy._id) ||
      groupBy === null ||
      (globalState.activeWorkspace && !globalState.activeWorkspace.collectionProperties) ||
      (globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties.length < 1) ||
      (globalState.activeWorkspace &&
        globalState.activeWorkspace.collectionProperties &&
        globalState.activeWorkspace.collectionProperties.find((property) => property.type == "singleSelect") == undefined)
        ? true
        : false;

    useEffect(() => {
      let isListMounted = true;
      if (globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0) {
        if (
          globalState.activeView &&
          globalState.activeView.kanbanProperties &&
          globalState.activeView.kanbanProperties.groupBy &&
          globalState.activeWorkspace.collectionProperties.findIndex((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id) > -1 &&
          globalState.activeWorkspace.collectionProperties.find((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id).type ==
            "singleSelect"
        ) {
          //console.log("using active view", globalState.activeView.kanbanProperties.groupBy)
          //console.log(globalState.activeWorkspace.collectionProperties.find(property => property._id == globalState.activeView.kanbanProperties.groupBy._id))

          // setGroupBy(globalState.activeView.kanbanProperties.groupBy)
          //use the actual collection properties for most up to date values
          if (isListMounted)
            setGroupBy(
              globalState.activeWorkspace.collectionProperties.find((property) => property._id == globalState.activeView.kanbanProperties.groupBy._id)
            );
        } else if (globalState.activeWorkspace.collectionProperties.find((property) => property.type == "singleSelect")) {
          //console.log('found single select', globalState.activeWorkspace.collectionProperties.find(property => property.type == "singleSelect"))
          if (isListMounted) setGroupBy(globalState.activeWorkspace.collectionProperties.find((property) => property.type == "singleSelect"));
        }
      }

      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeWorkspace, globalState.activeView]);

    //HIDDEN COLUMNS
    const [hiddenColumnIds, setHiddenColumnIds] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState({});
    //INITIALIZING / UPDATING HIDDEN COLUMNS
    useEffect(() => {
      let isListMounted = true;
      if (
        globalState.activeView &&
        globalState.activeView.kanbanProperties &&
        globalState.activeView.kanbanProperties.hiddenColumns &&
        globalState.activeView.kanbanProperties.hiddenColumns.length > 0
      ) {
        if (isListMounted) setHiddenColumnIds(globalState.activeView.kanbanProperties.hiddenColumns);
      } else {
        if (isListMounted) setHiddenColumnIds([]);
      }

      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeView]);

    const ToggleHideColumn = (column, state) => {
      //console.log({column, state})
      let resetArray = hiddenColumnIds;
      let tempHiddenIds;

      // if(hiddenColumnIds.indexOf(column._id) > -1){
      //   setHiddenColumnIds(ids => ids.filter(id => id !== column._id))
      //   tempHiddenIds = hiddenColumnIds.filter(id => id !== column._id)
      // } else {
      //   setHiddenColumnIds(update(hiddenColumnIds, {
      //     $push: [column._id]
      //   }))
      //   tempHiddenIds = hiddenColumnIds.concat(column._id)
      // }
      if (state === true) {
        //the column is hidden (true)
        setHiddenColumnIds((ids) => ids.filter((id) => id !== column._id));
        tempHiddenIds = hiddenColumnIds.filter((id) => id !== column._id);
      } else {
        setHiddenColumnIds(
          update(hiddenColumnIds, {
            $push: [column._id],
          })
        );
        tempHiddenIds = hiddenColumnIds.concat(column._id);
      }

      SaveHiddenColumns(tempHiddenIds, resetArray);
      handleCloseEditValue(false, null);
    };
    const SaveHiddenColumns = (updatedArray, resetArray) => {
      const data = {
        viewId: activeView._id,
        kanbanProperties: {
          hiddenColumns: updatedArray,
        },
      };

      axios
        .post("/views/boardproperties", data)
        .then((res) => {
          //console.log(res)
          globalActions.setActiveView(res.data.view);
          syncActiveViewWithCache(res.data.view);
        })
        .catch((err) => {
          //console.log("Could not update workspace name")
          setHiddenColumnIds(resetArray);
          console.log(err);
        });
    };

    //UPDATE THE GROUPINGS as needed
    useEffect(() => {
      let isListMounted = true;
      //&& props.collections.length > 0
      if (viewType === "Kanban" && props.collections) {
        //&& props.collections && props.collections.length > 0
        if (globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0) {
          if (isListMounted) setGroupedCollections(GroupCollections(props.collections));
        } else {
          //console.log("No properties to group by", groupBy)
        }
      }

      return () => {
        //cleanup
        isListMounted = false;
      };
    }, [globalState.activeWorkspace, props.collections, globalState.activeView, columnOrder, hiddenColumnIds, viewType, groupBy]); //[globalState.activeWorkspace.collectionProperties, props.collections, globalState.activeView.kanbanProperties, columnOrder, hiddenColumnIds, viewType])

    //CREATE GROUPING PROPERTY
    const [createPropReq, setCreatePropReq] = useState({
      isLoading: false,
      isError: false,
    });
    const [createPropertyAnchor, setCreatePropertyAnchor] = useState(false);
    const handleCreateTemplateProperty = () => {
      setCreatePropReq({
        isLoading: true,
        isError: false,
      });

      const data = {
        workspaceId: globalState.activeWorkspace._id,
        type: "singleSelect",
        name: "Status",
        values: [
          {
            name: "📋 TODO",
            color: "red",
            value: "📋 TODO",
          },
          {
            name: "🚧 In Progress",
            color: "yellow",
            value: "🚧 In Progress",
          },
          {
            name: "✔️ Done",
            color: "green",
            value: "✔️ Done",
          },
        ],
      };

      function StopLoadingCreateProp() {
        setCreatePropReq({
          isLoading: false,
          isError: false,
        });
      }

      axios
        .post("/workspaces/collectionProperties/create", data)
        .then(() => {
          //console.log(res)
          // if(res.data && res.data.workspace){
          //   globalActions.saveActiveWorkspace(res.data.workspace)
          // }
          // if(res.data && res.data.workspace && res.data.workspace.)
          GetWorkspacebyID(globalState.activeWorkspace, false, StopLoadingCreateProp);
        })
        .catch((err) => {
          //console.log("Could not update workspace name")
          console.log(err);
          StopLoadingCreateProp();
        });
    };

    //DELETING COLUMNS
    const [justDeletedCollections, setJustDeletedCollections] = useState([]);
    const [confirmDeleteColumnOpen, setConfirmDeleteColumnOpen] = useState(false);
    const handleOpenConfirmDelete = (event, value) => {
      setEditValueAnchor(null);
      setColumnToDelete(value);
      setConfirmDeleteColumnOpen(true);
    };
    const handleCloseConfirmDelete = () => {
      setConfirmDeleteColumnOpen(false);
    };
    const [deleteColumnReqLoading, setDeleteColumnReqLoading] = useState(false);
    const [columnToDelete, setColumnToDelete] = useState();
    const deleteColumn = (event) => {
      setDeleteColumnReqLoading(true);

      event.preventDefault();
      event.stopPropagation();

      //console.log(value, groupBy)

      if (columnToDelete && columnToDelete._id != "none" && groupBy && groupBy.values.findIndex((v) => v._id == columnToDelete._id) > -1) {
        const data = {
          workspaceId: globalState.activeWorkspace._id,
          propertyId: groupBy._id,
          valueId: columnToDelete._id,
          deleteCollections: true, //will delete all collections in column too
        };

        //delete column and collections from local state
        let resetColumns = groupedCollections;
        let columnIndex = groupedCollections.findIndex((g) => g._id === columnToDelete._id);

        if (columnIndex > -1) {
          setJustDeletedCollections(groupedCollections[columnIndex].collections.map((c) => c._id));
          setGroupedCollections(
            update(groupedCollections, {
              $splice: [[columnIndex, 1]],
            })
          );
          //setColumnOrder(columnOrder => columnOrder.filter(c => c !== value._id))
          handleCloseEditValue(false, null);
        }

        //console.log(groupBy.values)

        // const regroupCallback = () => {
        //   setJustDeletedCollections([])
        //   //setColumnOrder(columnOrder => columnOrder.filter(c => c !== value._id))
        //   //setGroupedCollections(GroupCollections(props.collections, groupBy))
        // }
        const StopLoadingDeleteProp = () => {
          //console.log("sstop loading called")

          setDeleteColumnReqLoading(false);
          handleCloseConfirmDelete();
        };

        axios
          .post(`/workspaces/collectionProperties/value/delete`, data)
          .then(() => {
            //console.log(res)
            StopLoadingDeleteProp();
            GetWorkspacebyID(globalState.activeWorkspace, true, StopLoadingDeleteProp);
            //collections are not removed from state until the call to collections is made
            //so remove them now and the call to collections will confirm this
            //props.removeCollectionsFromState(deletedCollections) -> see justdelted collections for where it filters the collections out
            //also need to remove the property value from state
          })
          .catch((err) => {
            //console.log("Could not update workspace name")
            console.log(err);
            setGroupedCollections(resetColumns);
            setJustDeletedCollections([]);
            StopLoadingDeleteProp();
            setConfirmDeleteColumnOpen(false);
          });
      }
    };

    //CREATING A COLUMN
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      "label + &": {
        marginTop: 24,
      },
      "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.text.light}`,
        fontSize: 14,
        lineHeight: 1.45,
        paddingBottom: 4,
        paddingTop: 4,
        paddingLeft: 6,
        paddingRight: 6,
        //transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: theme.palette.text.secondary,
        "&:hover": {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.collection.bgLight,
        },
        "&:focus": {
          border: `2px solid ${theme.palette.text.tertiary}`,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.collection.bgLight,
        },
      },
    }));

    const [createValueColumn, setCreateValueColumn] = useState(false);
    const [newColumnName, setNewColumnName] = useState("");
    const handleChangeNewColumnName = (event) => {
      setNewColumnName(event.target.value);
    };
    const [createColorSelect, setCreateColorSelect] = useState("default");
    const handleChooseCreateColor = (event, color) => {
      event.preventDefault();
      event.stopPropagation();

      setCreateColorSelect(color);
    };

    const toggleCreateValueInKanban = () => {
      setCreateValueColumn(!createValueColumn);
    };
    const createColumn = () => {
      const data = {
        workspaceId: globalState.activeWorkspace._id,
        propertyId: groupBy._id,
        updatedProperty: {
          values: [
            {
              color: createColorSelect,
              name: newColumnName,
              value: newColumnName,
            },
          ],
        },
      };
      //create in local state
      setGroupedCollections(
        update(groupedCollections, {
          $push: [
            {
              _id: "justcreated",
              property: {
                color: createColorSelect,
                name: newColumnName,
                value: newColumnName,
              },
              collections: [],
            },
          ],
        })
      );

      // this is not using the updated workspace properties
      // function RegroupCallback(){
      //   let afterCreate = GroupCollections(props.collections, groupBy)
      //   console.log(afterCreate)
      //   setGroupedCollections(afterCreate)
      // }
      axios
        .post(`/workspaces/collectionProperties/update`, data)
        .then(() => {
          GetWorkspacebyID(globalState.activeWorkspace, true, null); //RegroupCallback()
          setNewColumnName("");
          setCreateColorSelect("default");
        })
        .catch((err) => {
          //console.log("Could not update property value")
          console.log(err);
          GetWorkspacebyID(globalState.activeWorkspace, false, null); //RegroupCallback()
          setNewColumnName("");
          setCreateColorSelect("default");
        });
    };
    const handleCreateColumnClickAway = () => {
      setCreateValueColumn(false);

      if (newColumnName && newColumnName !== "" && newColumnName !== " ") {
        createColumn();
      } else {
        setNewColumnName("");
        setCreateColorSelect("default");
      }
    };
    const handleNewGroupKeypress = (e) => {
      var charCode = e.which || e.charCode || e.keyCode;
      if (charCode == 13) {
        handleCreateColumnClickAway();
      }
    };

    //DRAGGING COLLECTIONS IN KANBAN
    const [columnDragActive, setColumnDragActive] = useState(false);
    const moveColumn = useCallback(
      (dragIndex, hoverIndex, dragId) => {
        //console.log("move column " + dragIndex + " to " + hoverIndex, dragId, hoverId)
        //console.log(groupedCollections)
        //console.log(groupBy.values)
        // let fullOrder = groupedCollections && columnOrder
        // && groupedCollections.length == columnOrder.length
        // ?
        //columnOrder.filter(c => groupBy.values.findIndex(v => v._id === c) > -1 || c === "none")
        // : mapOrderColumns(groupedCollections.map(col => col._id), columnOrder)
        //let fullOrder = [...new Set([...columnOrder,...groupedCollections.map(col => col._id)])].filter(c => groupBy.values.findIndex(v => v._id === c) > -1 || c === "none")

        let currentOrder = columnOrder.filter((c) => groupBy.values.findIndex((v) => v._id === c) > -1 || c === "none");
        let currentState = groupedCollections.map((col) => col._id).concat(groupBy.values.map((v) => v._id)); //groupedCollections.map(col => col._id)
        let fullOrder = currentOrder.concat(currentState);
        fullOrder = [...new Set([...currentOrder, ...currentState])].filter((c) => hiddenColumnIds.findIndex((id) => id === c) < 0); //.filter(c => justDeletedCollections.findIndex(v => v._id === c) < 0)

        //let fullOrder = columnOrder.filter(c => groupBy.values.findIndex(v => v._id === c) > -1 || c === "none")//mapOrderColumns(groupedCollections.map(col => col._id), columnOrder).filter(c => groupBy.values.findIndex(v => v._id === c) > -1 || c === "none")//mapOrderColumns(groupedCollections.map(col => col._id), columnOrder)
        //console.log({groupBy})
        //console.log({fullOrder})

        const dragColumn = dragId && dragId !== "" && dragId !== " " ? dragId : fullOrder[dragIndex];
        //console.log({dragColumn})
        setColumnOrder(
          update(fullOrder, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragColumn],
            ],
          })
        );
      },
      [columnOrder]
    );
    const SaveColumnPosition = () => {
      const data = {
        viewId: activeView._id,
        kanbanProperties: {
          columnOrder: columnOrder,
        },
      };

      axios
        .post("/views/boardproperties", data)
        .then((res) => {
          //console.log(res)
          globalActions.setActiveView(res.data.view);
          syncActiveViewWithCache(res.data.view);
        })
        .catch((err) => {
          //console.log("Could not update workspace name")
          console.log(err);
        });
    };

    const renderColumn = (order, i, group, bg, columnWidth, id) => {
      if (id) {
        return (
          <KanbanColumn
            CreateCollection={CreateCollection}
            SaveColumnPosition={SaveColumnPosition}
            bg={bg}
            collectionDragActive={dragActive}
            columnDragActive={columnDragActive}
            columnIndex={i}
            columnWidth={columnWidth}
            createDragCollection={createDragCollection}
            createReq={createReq}
            group={group}
            groupBy={groupBy}
            groupedCollections={groupedCollections}
            handleOpenEditValue={handleOpenEditValue}
            id={id}
            key={id}
            moveCollectionCardInKanban={moveCollectionCardInKanban}
            moveColumn={moveColumn}
            openCollectionDialog={openCollectionDialog}
            reateDragCollection={createDragCollection}
            renderListItem={renderListItem}
            setColumnDragActive={setColumnDragActive}
            setShowCreateCollectionWithDrag={setShowCreateCollectionWithDrag}
            setShowDropInColumn={setShowDropInColumn}
            showCreateCollectionWithDrag={showCreateCollectionWithDrag}
            showDropInColumn={showDropInColumn}
          />
        );
      }
    };

    //FOR dropping in an empty column
    const [showDropInColumn, setShowDropInColumn] = useState(null);
    const [showCreateCollectionWithDrag, setShowCreateCollectionWithDrag] = useState(null);

    const moveCollectionCardInKanban = (removeSorts, collection, destColumn, curColumnId, dragIndex, destIndex) => {
      //update the collection property if necessary
      //console.log(destColumn)
      //console.log({removeSorts, collection, destColumn, curColumnId, dragIndex, destIndex})
      if (destColumn) {
        //rename for dropping on hiddencolumn button which uses .propertyValue instead of .property
        destColumn = Object.keys(destColumn).reduce((objAcc, key) => {
          const value = destColumn[key];
          return Object.assign({}, objAcc, key === "propertyValue" ? { property: value } : { [key]: value });
        }, {});
      }

      //console.log({collection, destColumn, curColumnId})

      if (dragIndex === destIndex && destColumn && destColumn._id === curColumnId) return;
      //console.log('Dragged ' + collection.name + " from " + dragIndex + " to " + destIndex + ' in ' + destColumn._id)
      if (curColumnId !== destColumn._id) {
        //console.log(collection.properties)
        if (collection && collection.properties) {
          let propertyId = collection.properties.findIndex((p) => p.propertyId === groupBy._id);
          //console.log(propertyId)
          if (propertyId > -1) {
            if (destColumn._id == "none") {
              collection.properties[propertyId].values = [];
            } else {
              collection.properties[propertyId].values = [destColumn.property._id];
            }
          } else {
            collection.properties.push({
              propertyId: groupBy._id,
              values: [destColumn.property._id],
            });
          }
        } else {
          if (destColumn._id == "none") {
            collection.properties = [];
          } else {
            collection.properties = [
              {
                propertyId: groupBy._id,
                values: [destColumn.property._id],
              },
            ];
          }
        }
      }
      setGroupedCollections((groups) =>
        groups.map((column) => ({
          ...column,
          //collections: []
          collections: flowRight(
            //flowright just performs  the functions provided from right to left
            // 2) If this is the destination column, insert the cardId.
            (collecs) => (column._id === destColumn._id ? [...collecs.slice(0, destIndex), collection, ...collecs.slice(destIndex)] : collecs),
            // 1) Remove the cardId for all columns
            (collecs) => collecs.filter((col) => col._id !== collection._id)
          )(column.collections),
        }))
      );
      // let localResourceOrder = resourceOrder.map(column => {
      //   if(column && column.order){
      //     return ({
      //       ...column,
      //       order: flowRight(
      //         //flowright just performs  the functions provided from right to left
      //         // 2) If this is the destination column, insert the cardId.
      //         //column && !!column.propertyValueId && column.propertyValueId === destColumn._id
      //         collecIds =>
      //           column.propertyValueId === destColumn._id
      //             ? [...collecIds.slice(0, destIndex), collection._id, ...collecIds.slice(destIndex)]
      //             : collecIds,
      //         // 1) Remove the cardId for all columns
      //         collecIds => collecIds.filter(collecId => collecId !== collection._id)
      //       )(column.order),
      //
      //     })
      //   } else {
      //     //console.log("no order in this column", column)
      //     return {
      //       propertyValueId: destColumn._id,
      //       order: [collection._id]
      //     }
      //   }
      // })

      //regardless of the DB state, we want to use the actual local state, so that we can adjust and save that position.
      // this cleanses the DB state and ensures accuracy
      let localResourceOrder = groupedCollections.map((col) => {
        if (col && col._id && col.collections && col.collections.length > 0) {
          let obj = {
            propertyValueId: col._id,
            order: col.collections.map((collection) => collection._id),
          };

          return obj;
        } else {
          return null;
        }
      });
      let hiddenColumnResourceOrder =
        hiddenColumns && hiddenColumns.columns && hiddenColumns.columns.length > 0
          ? hiddenColumns.columns.map((col) => {
              if (
                col &&
                col._id &&
                col.collections &&
                col.collections.length > 1 &&
                localResourceOrder.findIndex((column) => column && column.propertyValueId == col._id) == -1
              ) {
                let obj = {
                  propertyValueId: col._id,
                  order: col.collections.map((collection) => collection._id),
                };

                return obj;
              } else {
                return null;
              }
            })
          : [];

      let fullResourceOrder = localResourceOrder.concat(hiddenColumnResourceOrder);
      fullResourceOrder = [...new Set([...localResourceOrder, ...hiddenColumnResourceOrder])];
      let validatedResourceOrder = fullResourceOrder
        ? fullResourceOrder.filter((column) => {
            if (!!column && column.propertyValueId && column.order && column.order.length > 0) return true;
            else return false;
          })
        : [];

      let pkg = {
        collection: collection,
        differentColumn: destColumn._id !== curColumnId,
        propertyId: groupBy._id,
        valueId: destColumn._id != "none" ? destColumn._id : [],
        removeSorts: removeSorts,
      };

      //use the actual local state for updating to sync with call to DB
      // setResourceOrder(localResourceOrder => (
      //     localResourceOrder.map(column => {
      //       if(column && column.order){
      //         return ({
      //           ...column,
      //           order: flowRight(
      //             //flowright just performs  the functions provided from right to left
      //             // 2) If this is the destination column, insert the cardId.
      //             //column && !!column.propertyValueId && column.propertyValueId === destColumn._id
      //             collecIds =>
      //               column.propertyValueId === destColumn._id
      //                 ? [...collecIds.slice(0, destIndex), collection._id, ...collecIds.slice(destIndex)]
      //                 : collecIds,
      //             // 1) Remove the cardId for all columns
      //             collecIds => collecIds.filter(collecId => collecId !== collection._id)
      //           )(column.order),
      //
      //         })
      //       } else {
      //         //console.log("no order in this column", column)
      //         return {
      //           propertyValueId: destColumn._id,
      //           order: [collection._id]
      //         }
      //       }
      //     }
      //   )
      // ))

      //make changes, update locally, and pass to DB
      let newOrderedState = validatedResourceOrder.map((column) => {
        if (column && column.order) {
          return {
            ...column,
            order: flowRight(
              //flowright just performs  the functions provided from right to left
              // 2) If this is the destination column, insert the cardId.
              //column && !!column.propertyValueId && column.propertyValueId === destColumn._id
              (collecIds) =>
                column.propertyValueId === destColumn._id ? [...collecIds.slice(0, destIndex), collection._id, ...collecIds.slice(destIndex)] : collecIds,
              // 1) Remove the cardId for all columns
              (collecIds) => collecIds.filter((collecId) => collecId !== collection._id)
            )(column.order),
          };
        } else {
          //console.log("no order in this column", column)
          return {
            propertyValueId: destColumn._id,
            order: [collection._id],
          };
        }
      });
      setResourceOrder(newOrderedState);
      saveResourceOrder(newOrderedState, pkg);
    };

    const saveResourceOrder = (localResourceOrder, pkg) => {
      //console.log({localResourceOrder, pkg})
      //FIRST if the collection was dragged to a different column update that
      //THEN save the resource order

      //filter out columns with less than 2 collections in the order
      localResourceOrder =
        localResourceOrder && localResourceOrder.length > 0 ? localResourceOrder.filter((column) => column.order && column.order.length > 1) : [];

      if (pkg && pkg.differentColumn) {
        const data = {
          collectionIds: [pkg.collection._id],
          workspaceId: globalState.activeWorkspace._id,
          propertyId: pkg.propertyId,
          propertyValues: [pkg.valueId],
        };

        // if(pkg.removeSorts){
        //   data.viewId = globalState.activeView._id
        //   data.removeSorts = true
        // }

        axios
          .post("/collections/properties/values/update", data)
          .then(() => {
            if (localResourceOrder) saveOrder(localResourceOrder);
          })
          .catch((err) => {
            //console.log("Could not update workspace name")
            console.log(err);
          });
      } else if (localResourceOrder) {
        saveOrder(localResourceOrder);
      }

      function saveOrder(order) {
        const data = {
          viewId: activeView._id,
          kanbanProperties: {
            resourceOrder: order,
          },
          removeSorts: pkg.removeSorts,
        };

        axios
          .post("/views/boardproperties", data)
          .then((res) => {
            //console.log(res)
            globalActions.setActiveView(res.data.view);
            syncActiveViewWithCache(res.data.view);

            // var newActiveViewId = res.data.workspace.activeView
            // var updatedWS = globalState.activeWorkspace
            // updatedWS.activeView = newActiveViewId
            // globalActions.saveActiveWorkspace(updatedWS)
            //
            // setViewChangeReq({
            //   isLoading: false,
            //   isError: false
            // })
          })
          .catch((err) => {
            //console.log("Could not update workspace name")
            console.log(err);
          });
      }
    };

    //**************************** END OF KANBAN **************************** //

    const syncActiveViewWithCache = (view) => {
      //when updating the active view, should also sync this data with the workspaces cache
      if (view && view._id && view.workspaceId) {
        const workspaceIndex = workspacesData && workspacesData.workspaces ? workspacesData.workspaces.findIndex((w) => w._id == view.workspaceId) : -1;
        const viewIndex =
          workspaceIndex > -1 && workspacesData.workspaces[workspaceIndex].views
            ? workspacesData.workspaces[workspaceIndex].views.findIndex((v) => v._id == view._id)
            : -1;
        // console.log("SYNCING WITH CACHE " + workspaceIndex, viewIndex)
        if (workspaceIndex > -1 && viewIndex > -1) {
          const updatedData = update(workspacesData, {
            workspaces: {
              [workspaceIndex]: { views: { [viewIndex]: { $set: view } } },
            },
          });
          //console.log(updatedData.workspaces[workspaceIndex])
          mutate(`/workspaces`, updatedData, false);
        }
      }
    };

    //HOTKEYS
    const createCollectionHotkeyEnabled = () => {
      if (collectionDialogState === null) return true;
      else return false;
    };
    useHotkeys(
      "c",
      (HotkeysEvent) => {
        //console.log(HotkeysEvent)
        HotkeysEvent.preventDefault();
        HotkeysEvent.stopPropagation();

        console.log("C key hit ");
        openCollectionDialog();
      },
      {
        enabled: createCollectionHotkeyEnabled(),
      },
      [collectionDialogState]
    );

    return (
      <>
        {viewType == "Kanban" ? (
          <div
            // style={{
            //   width: '100%',
            //   height: '100%',
            //   minHeight: '100%',
            //   display: 'flex',
            //   flexDirection: 'row',
            //   alignItems: 'stretch',
            //   justifyContent: 'stretch',
            //   flexWrap: 'noWrap',
            //   border: 'solid blue',
            //
            // }}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              justifyContent: "flex-start",
              flexWrap: "noWrap",
              paddingTop: 8,
            }}
          >
            {noGroupByField ? (
              <div
                style={{
                  width: "100%",
                  minWidth: "100%",
                  minHeight: 500,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {createPropReq.isLoading ? (
                  <CircularProgress size={24} style={{ margin: 10, color: theme.palette.text.tertiary }} />
                ) : (
                  <>
                    <Typography
                      align="center"
                      style={{
                        color: theme.palette.text.secondary,
                        marginBottom: 10,
                      }}
                      variant="h6"
                    >
                      {" "}
                      Choosing a grouping field
                    </Typography>
                    <Typography
                      align="center"
                      style={{
                        color: theme.palette.text.secondary,
                        marginBottom: 25,
                        maxWidth: 450,
                      }}
                      variant="subtitle1"
                    >
                      {" "}
                      In order to create a Kanban view, you need to choose a property to group your collections by{" "}
                    </Typography>
                    <Typography variant="h6"> </Typography>

                    <Button
                      onClick={handleCreateTemplateProperty}
                      startIcon={<Add fontSize="small" style={{ width: 18, marginRight: -2 }} />}
                      // onClick={handleOpenCreatePropertyMenu}
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        marginRight: 8,
                        marginBottom: 25,
                        color: "#000",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          maxWidth: "95%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        variant="button"
                      >
                        Create an example property to group by
                      </Typography>
                    </Button>
                  </>
                )}
              </div>
            ) : groupedCollections ? (
              // groupedCollections.map((order, i) => {
              //   let group = order
              Object.keys(groupedCollections).map((order, i) => {
                let group = groupedCollections[order];
                group.columnIndex = i;
                const bg =
                  group && group.property && labelColors.findIndex((l) => l.name == group.property.color) > -1
                    ? hexToRgb(labelColors.find((l) => l.name == group.property.color).hex)
                    : hexToRgb(labelColors.find((l) => l.name == "default").hex);
                let id = group && group._id ? group._id : order;

                if (hiddenColumnIds.indexOf(id) > -1) {
                  //remove hidden columns
                  return null;
                } else {
                  return renderColumn(order, i, group, bg, columnWidth, id);
                }
              })
            ) : null}
            {groupedCollections && collectionDialogOpen ? (
              <CollectionDialog
                collectionState={collectionDialogState}
                handleAddSnackbarToStack={handleAddSnackbarToStack}
                handleCloseDialog={handleCloseCollectionDialog}
                handleOpenTabs={handleOpenTabs}
                key={collectionDialogState}
                open={collectionDialogOpen}
                openAddTabsDialog={props.openAddTabsDialog}
              />
            ) : null}
            {!noGroupByField &&
            hiddenColumns &&
            hiddenColumns.columns &&
            hiddenColumns.columns.filter((col) => groupBy.values.findIndex((v) => v._id === col._id) > -1 || col._id == "none").length > 0 &&
            groupBy &&
            groupBy.values &&
            groupBy.values.length > 0 ? (
              <div
                style={{
                  //width: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
                  minWidth: 250, //columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
                  maxWidth: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
                  marginRight: 20, //columnIndex == groupedCollections.length -1 ? 20 : 0,
                  height: "100%",

                  top: 0,
                  position: "sticky",
                }}
              >
                <div
                  style={{
                    height: 40,
                    width: "100%",

                    //backgroundColor: 'transparent',
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "noWrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    zIndex: 999,
                    color: theme.palette.text.tertiary,

                    backgroundColor: theme.palette.background.default,
                    //zIndex: 1,
                  }}
                >
                  Hidden columns
                </div>
                {hiddenColumns.columns
                  .filter((col) => groupBy.values.findIndex((v) => v._id === col._id) > -1 || col._id == "none")
                  .map((col, i) => {
                    col.propertyValue = groupBy.values.find((v) => v._id === col._id)
                      ? groupBy.values.find((v) => v._id === col._id)
                      : groupedCollections.find((group) => group._id == "none").property;
                    //let propertyValue = col//groupBy.values.find(v => v._id === id)
                    col.propertyValue.hidden = true;
                    const bg =
                      col && col.propertyValue && labelColors.findIndex((l) => l.name == col.propertyValue.color) > -1
                        ? hexToRgb(labelColors.find((l) => l.name == col.propertyValue.color).hex)
                        : hexToRgb(labelColors.find((l) => l.name == "default").hex);

                    return (
                      <HiddenColumnButton
                        CreateCollection={CreateCollection}
                        bg={bg}
                        column={col}
                        columnDragActive={columnDragActive}
                        groupBy={groupBy}
                        handleOpenEditValue={handleOpenEditValue}
                        key={i}
                        moveCollectionCardInKanban={moveCollectionCardInKanban}
                      />
                    );
                  })}
              </div>
            ) : null}

            <div
              style={{
                height: 40,
                marginTop: createValueColumn ? 6 : 0,
                minWidth: 260,
                paddingRight: 75,
                display: "flex",
                flexDirection: "row",
                flexWrap: "noWrap",
                alignItems: createValueColumn ? "flex-start" : "center",

                width: "100%",
                position: "sticky",
                top: 0,

                backgroundColor: theme.palette.background.default,
                zIndex: 1,
              }}
            >
              {!noGroupByField ? (
                createValueColumn ? (
                  <ClickAwayListener onClickAway={handleCreateColumnClickAway}>
                    <div>
                      <BootstrapInput
                        autoComplete="none"
                        autoFocus
                        fullWidth
                        onChange={handleChangeNewColumnName}
                        onKeyPress={handleNewGroupKeypress}
                        //onBlur={handleCreateColumnClickAway}
                        placeholder="Column name"
                        value={newColumnName}
                      />
                      <Paper
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: 6,
                          marginTop: 4,
                        }}
                      >
                        {labelColors.map((color) => {
                          //border: isSelected ? '2px solid' : null
                          let isSelected = createColorSelect == color.name ? true : false;
                          return (
                            <div
                              key={color.name}
                              onClick={(e) => handleChooseCreateColor(e, color.name)}
                              style={{
                                marginRight: 6,
                                width: 14,
                                height: 14,
                                backgroundColor: color.solidHex,
                                borderRadius: "100%",
                                border: isSelected ? `2px solid ${theme.palette.text.primary}` : null,
                              }}
                            />
                          );
                        })}
                      </Paper>
                    </div>
                  </ClickAwayListener>
                ) : (
                  <PlainButton
                    disableFocusRipple
                    disableRipple
                    disabled={columnDragActive ? true : false}
                    onMouseUp={toggleCreateValueInKanban}
                    size="small"
                    startIcon={<Add />}
                    style={{ color: theme.palette.text.tertiary }}
                  >
                    Add column
                  </PlainButton>
                )
              ) : null}
            </div>

            {valueToEdit && editValueAnchor ? (
              <EditValueMenu
                ToggleHideColumn={ToggleHideColumn}
                anchor={editValueAnchor}
                deleteColumn={handleOpenConfirmDelete}
                groupBy={groupBy}
                handleClose={handleCloseEditValue}
                hiddenColumnIds={hiddenColumnIds}
                inKanban
                saveUpdatedValue={saveUpdatedValue}
                updateValue={updateValue}
                valueToEdit={valueToEdit}
              />
            ) : null}
            {confirmDeleteColumnOpen ? (
              <ConfirmDeletePropertyDialog
                confirm={deleteColumn}
                handleClose={handleCloseConfirmDelete}
                isLoading={deleteColumnReqLoading}
                open={confirmDeleteColumnOpen}
                property={columnToDelete}
                state={{ confirmText: "Delete property", isDeleteColumn: true }}
              />
            ) : null}
          </div>
        ) : (
          <Grid
            alignItems="stretch"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={0}
            style={{
              marginBottom: 50,
              gridAutoRows: "1fr",
              paddingTop: viewType == "Full" ? 16 : 8,
            }}
          >
            {props.collections && props.collections.length > 0
              ? props.collections.map((collection, i) => (collection ? renderListItem(collection, i) : null))
              : null}
            {props.collections && props.collections.length > 0 && collectionDialogOpen ? (
              <CollectionDialog
                collectionState={collectionDialogState}
                handleAddSnackbarToStack={handleAddSnackbarToStack}
                handleCloseDialog={handleCloseCollectionDialog}
                handleOpenTabs={handleOpenTabs}
                key={collectionDialogState}
                open={collectionDialogOpen}
                openAddTabsDialog={props.openAddTabsDialog}
              />
            ) : null}

            {!createReq.isLoading ? (
              viewType == "List" ? (
                <Grid
                  item
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    padding: 0,
                    marginBottom: 0,
                    height: 40,
                  }}
                >
                  <div
                    onClick={props.openCollectionDialog}
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      border: createDragCollection ? "2px solid #3D5AFE" : "2px solid transparent",
                      borderRadius: createDragCollection ? 6 : 4,
                      //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.20)" : hover ? theme.palette.collection.hover : "transparent",

                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                      paddingLeft: 10,
                      paddingRight: 12,
                      paddingTop: 4,
                      paddingBottom: 4,
                    }}
                  >
                    <Add
                      color="disabled"
                      style={{
                        marginRight: 8,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    />
                    <Typography
                      color="textSecondary"
                      style={{
                        opacity: 0.75,
                        fontSize: 14,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    >
                      {" "}
                      Create new collection{" "}
                    </Typography>
                  </div>
                </Grid>
              ) : viewType == "Full" ? (
                <Grid
                  item
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    padding: 4,
                    marginBottom: 20,
                    height: "1fr",
                  }}
                >
                  <div
                    onClick={props.openCollectionDialog}
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      border: createDragCollection ? "2px solid #3D5AFE" : `1px solid ${theme.palette.divider}`,
                      borderRadius: createDragCollection ? 6 : 4,
                      //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.20)" : hover ? theme.palette.collection.hover : "transparent",
                      minHeight: 75,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      paddingLeft: 10,
                      paddingRight: 12,
                      paddingTop: 4,
                      paddingBottom: 4,
                    }}
                  >
                    <Add
                      color="disabled"
                      style={{
                        marginRight: 8,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    />
                    <Typography
                      color="textSecondary"
                      style={{
                        opacity: 0.75,
                        fontSize: 14,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    >
                      {" "}
                      Create new collection{" "}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  style={{
                    padding: 8,
                    minWidth: cardMinWidth,
                    maxWidth: cardSizePercentage,
                    width: cardSizePercentage,
                    height: "1fr",
                  }}
                >
                  <div
                    onClick={props.openCollectionDialog}
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      border: createDragCollection ? "2px solid #3D5AFE" : `1px solid ${theme.palette.divider}`,
                      borderRadius: createDragCollection ? 6 : 4,
                      //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.2)" : hover ? theme.palette.collection.hover : "transparent",
                      width: "100%",
                      height: "100%",
                      minHeight: 75,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Add
                      color="disabled"
                      style={{
                        marginRight: 8,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    />
                    <Typography
                      color="textSecondary"
                      style={{
                        opacity: 0.75,
                        fontSize: 14,
                        color: createDragCollection ? "#3d5AFE" : null,
                      }}
                    >
                      {" "}
                      Create new collection{" "}
                    </Typography>
                  </div>
                </Grid>
              )
            ) : null}

            {createReq.isLoading ? (
              viewType == "List" ? (
                <Grid
                  item
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    padding: 0,
                    marginBottom: 0,
                    height: "1fr",
                  }}
                >
                  <div
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      borderRadius: 4,
                      border: "1px solid rgba(196, 196, 196, 0.45)",
                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.20)" : hover ? "#EFEFEF" : "#F9F9F9",
                      //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      padding: 4,
                    }}
                  >
                    <CircularProgress color="primary" size={24} style={{ margin: 10 }} />
                  </div>
                </Grid>
              ) : viewType == "Full" ? (
                <Grid
                  item
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    padding: 4,
                    marginBottom: 20,
                    height: "1fr",
                  }}
                >
                  <div
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      borderRadius: createDragCollection ? 6 : 4,
                      border: createDragCollection ? "2px solid #3D5AFE" : "1px solid rgba(196, 196, 196, 0.45)",
                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.20)" : hover ? "#EFEFEF" : "#F9F9F9",
                      //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CircularProgress color="primary" size={24} style={{ margin: 10 }} />
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  style={{
                    padding: 8,
                    minWidth: cardMinWidth,
                    maxWidth: cardSizePercentage,
                    width: cardSizePercentage,
                    height: "1fr",
                  }}
                >
                  <div
                    onMouseLeave={() => setHover(false)}
                    onMouseOver={() => setHover(true)}
                    style={{
                      borderRadius: createDragCollection ? 6 : 4,
                      border: createDragCollection ? "2px solid #3D5AFE" : "1px solid rgba(196, 196, 196, 0.45)",
                      backgroundColor: createDragCollection ? "rgb(61, 90, 254, 0.20)" : hover ? "#EFEFEF" : "#F9F9F9",
                      //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CircularProgress color="primary" size={24} style={{ margin: 10 }} />
                  </div>
                </Grid>
              )
            ) : null}
          </Grid>
        )}
      </>
    );
  }
};

// {
//   viewType == "Kanban" ?
//       <div
//       // style={{
//       //   width: '100%',
//       //   height: '100%',
//       //   minHeight: '100%',
//       //   display: 'flex',
//       //   flexDirection: 'row',
//       //   alignItems: 'stretch',
//       //   justifyContent: 'stretch',
//       //   flexWrap: 'noWrap',
//       //   border: 'solid blue',
//       //
//       // }}
//           style={{
//         width: '100%',
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'stretch',
//         justifyContent: 'flex-start',
//         flexWrap: 'noWrap',
//         paddingTop: 8,
//       }}
//       >
//           {
//         noGroupByField ?
//             <div style={{width: '100%', minWidth: '100%', minHeight: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
//                 {
//             createPropReq.isLoading ?
//                 <CircularProgress
//                     size={24}
//                     style={{margin: 10, color: '#C4C4C4'}}
//                 />
//               :
//                 <>
//                     <Typography
//                         align="center"
//                         style={{color: '#6E6E6E', marginBottom: 10}}
//                         variant="h6"
//                     >
//                         {' '}
//                         Choosing a grouping field
//                     </Typography>
//                     <Typography
//                         align="center"
//                         style={{color: '#6E6E6E', marginBottom: 25, maxWidth: 450}}
//                         variant="subtitle1"
//                     >
//                         {' '}
//                         In order to create a Kanban view, you need to choose a property to group your collections by
//                         {' '}
//                     </Typography>
//                     <Typography variant="h6" > </Typography>

//                     <Button
//                         onClick={handleCreateTemplateProperty}
//                         startIcon={<Add
//                             fontSize="small"
//                             style={{width: 18, marginRight: -2}}
//                                    />}
//                   // onClick={handleOpenCreatePropertyMenu}
//                         style={{paddingTop: 2, paddingBottom: 2, marginRight: 8,  marginBottom: 25, color: "#000", textOverflow: "ellipsis", overflow: "hidden"}}
//                     >
//                         <Typography
//                             noWrap
//                             style={{maxWidth: '95%', textOverflow: "ellipsis", overflow: "hidden",}}
//                             variant="button"
//                         >
//                             Create an example property to group by
//                         </Typography>
//                     </Button>
//                 </>
//           }
//             </div>
//         :
//         groupedCollections ?
//         // groupedCollections.map((order, i) => {
//         //   let group = order
//         Object.keys(groupedCollections).map((order, i) => {
//           let group = groupedCollections[order]
//           group.columnIndex = i
//           const bg = group && group.property && labelColors.findIndex(l => l.name == group.property.color) > -1 ? hexToRgb(labelColors.find(l => l.name == group.property.color).hex) : hexToRgb(labelColors.find(l => l.name == 'default').hex)
//           let id = group && group._id ? group._id : order

//           if(hiddenColumnIds.indexOf(id) > -1){
//             //remove hidden columns
//             return null
//           } else {
//             return renderColumn(order, i, group, bg, columnWidth, id)
//           }

//         })
//         :
//         null
//       }
//           {
//         groupedCollections && isListMounted && collectionDialogOpen ?
//             <CollectionDialog
//                 collectionState={collectionDialogState}

//                 handleAddSnackbarToStack={handleAddSnackbarToStack}
//                 handleCloseDialog={handleCloseCollectionDialog}
//                 handleOpenTabs={handleOpenTabs}
//                 open={collectionDialogOpen}
//                 openAddTabsDialog={props.openAddTabsDialog}
//             />
//         :
//         null
//       }
//           {
//         !noGroupByField && hiddenColumns && hiddenColumns.columns && hiddenColumns.columns.filter(col => groupBy.values.findIndex(v => v._id === col._id) > -1 || col._id == 'none').length > 0 && groupBy && groupBy.values && groupBy.values.length > 0 ?
//             <div
//                 style={{
//             //width: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
//             minWidth: 250,//columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
//             maxWidth: columnWidth, //columnIndex == groupedCollections.length -1 ? columnWidth + 100 : columnWidth,
//             marginRight: 20, //columnIndex == groupedCollections.length -1 ? 20 : 0,
//             height: '100%',

//             top: 0,
//             position: 'sticky',
//           }}
//             >
//                 <div
//                     style={{
//             height: 40,
//             width: '100%',

//             //backgroundColor: 'transparent',
//             display: 'flex',
//             flexDirection: 'row',
//             flexWrap: 'noWrap',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             paddingLeft: 10,
//             paddingRight: 10,
//             zIndex: 999,
//             color: '#C4C4C4',

//             backgroundColor: '#F9F9F9',
//             //zIndex: 1,
//           }}
//                 >
//                     Hidden columns
//                 </div>
//                 {
//             hiddenColumns.columns.filter(col => groupBy.values.findIndex(v => v._id === col._id) > -1 || col._id == 'none').map((col, i) => {
//               col.propertyValue = groupBy.values.find(v => v._id === col._id) ? groupBy.values.find(v => v._id === col._id) : groupedCollections.find(group => group._id == 'none').property
//               //let propertyValue = col//groupBy.values.find(v => v._id === id)
//               col.propertyValue.hidden = true
//               const bg = col && col.propertyValue && labelColors.findIndex(l => l.name == col.propertyValue.color) > -1 ? hexToRgb(labelColors.find(l => l.name == col.propertyValue.color).hex) : hexToRgb(labelColors.find(l => l.name == 'default').hex)

//               return(
//                   <HiddenColumnButton
//                       CreateCollection={CreateCollection}
//                       bg={bg}
//                       column={col}
//                       columnDragActive={columnDragActive}
//                       groupBy={groupBy}
//                       handleOpenEditValue={handleOpenEditValue}
//                       key={i}
//                       moveCollectionCardInKanban={moveCollectionCardInKanban}
//                   />
//               )

//             })
//           }
//             </div>
//         :
//         null
//       }

//           <div
//               style={{
//           height: 40, marginTop: createValueColumn ? 6 : 0, minWidth: 260, paddingRight: 75,
//           display: 'flex',
//           flexDirection: 'row',
//           flexWrap: 'noWrap',
//           alignItems: createValueColumn ? 'flex-start' : 'center',

//           width: '100%',
//           position: 'sticky',
//           top: 0,

//           backgroundColor: '#F9F9F9',
//           zIndex: 1,
//         }}
//           >
//               {
//         !noGroupByField ?
//         createValueColumn ?
//             <ClickAwayListener onClickAway={handleCreateColumnClickAway}>
//                 <div>
//                     <BootstrapInput
//                         autoComplete='none'
//                         autoFocus
//                         onChange={handleChangeNewColumnName}
//                         onKeyPress={handleNewGroupKeypress}

//               //onBlur={handleCreateColumnClickAway}
//                         placeholder="Column name"
//                         value={newColumnName}
//                     />
//                     <Paper className={classes.colorSelectDiv}>
//                         {
//                 labelColors.map(color => {
//                   //border: isSelected ? '2px solid' : null
//                   let isSelected = createColorSelect == color.name ? true : false
//                   return(
//                       <div
//                           key={color.name}
//                           onClick={(e) => handleChooseCreateColor(e, color.name)}
//                           style={{marginRight: 6, width: 14, height: 14, backgroundColor: color.solidHex, borderRadius: '100%', border: isSelected ? '2px solid #333' : null}}
//                       />
//                   )
//                 })
//               }
//                     </Paper>
//                 </div>
//             </ClickAwayListener>
//         :
//             <Button
//                 disableFocusRipple
//                 disableRipple
//                 disabled={columnDragActive ? true : false}
//                 onMouseUp={toggleCreateValueInKanban}
//                 size="small"
//                 startIcon={<Add />}
//                 style={{color: '#C4C4C4'}}
//             >
//                 Add column
//             </Button>
//         :
//         null
//       }
//           </div>

//           {
//         valueToEdit && editValueAnchor ?
//             <EditValueMenu
//                 ToggleHideColumn={ToggleHideColumn}
//                 anchor={editValueAnchor}
//                 deleteColumn={handleOpenConfirmDelete}
//                 groupBy={groupBy}
//                 handleClose={handleCloseEditValue}
//                 hiddenColumnIds={hiddenColumnIds}
//                 inKanban
//                 saveUpdatedValue={saveUpdatedValue}
//                 updateValue={updateValue}
//                 valueToEdit={valueToEdit}
//             />
//         :
//         null
//       }
//           {
//         confirmDeleteColumnOpen ?
//             <ConfirmDeletePropertyDialog
//                 confirm={deleteColumn}
//                 handleClose={handleCloseConfirmDelete}
//                 isLoading={deleteColumnReqLoading}
//                 open={confirmDeleteColumnOpen}
//                 property={columnToDelete}
//                 state={{confirmText: 'Delete property', isDeleteColumn: true }}
//             />
//         :
//         null
//       }
//       </div>
//   :
//       <Grid
//           alignItems="stretch"
//           container
//           direction="row"
//           justifyContent="flex-start"
//           spacing={0}

//     //style={{marginBottom: 50, gridTemplateColumns: `repeat(${cardSizeDecimal}, 1fr)`,}}
//           style={{marginBottom: 50, gridAutoRows: '1fr', paddingTop: viewType == "Full" ? 16 : 8}}
//       >

//           {
//       props.collections && props.collections.length > 0 && isListMounted ?
//       props.collections.map((collection, i) => collection ? renderListItem(collection, i) : null)
//       :
//       null
//     }
//           {
//       props.collections && props.collections.length > 0 && isListMounted && collectionDialogOpen ?
//           <CollectionDialog
//               collectionState={collectionDialogState}
//               handleAddSnackbarToStack={handleAddSnackbarToStack}
//               handleCloseDialog={handleCloseCollectionDialog}
//               handleOpenTabs={handleOpenTabs}
//               open={collectionDialogOpen}
//               openAddTabsDialog={props.openAddTabsDialog}
//           />
//       :
//       null
//     }

//           {
//       !createReq.isLoading ?
//         viewType == "List" ?
//             <Grid
//                 item
//                 style={{minWidth: '100%', maxWidth: '100%', width: '100%', padding: 0, marginBottom: 0, height: 40}}
//             >
//                 <div
//                     onClick={props.openCollectionDialog}
//                     onMouseLeave={() => setHover(false)}
//                     onMouseOver={() => setHover(true)}
//                     style={{
//               border: createDragCollection ? '2px solid #3D5AFE' : '2px solid transparent',
//               borderRadius: createDragCollection ? 6 : 4,
//               //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

//               backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.20)' : hover ? '#EFEFEF' : 'transparent',

//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               flexDirection: 'row',
//               alignItems: 'center',
//               justifyContent: 'flex-start',
//               cursor: 'pointer',
//               paddingLeft: 10,
//               paddingRight: 12,
//               paddingTop: 4,
//               paddingBottom: 4,
//             }}
//                 >
//                     <Add
//                         color="disabled"
//                         style={{marginRight: 8, color: createDragCollection ? '#3d5AFE' : null}}
//                     />
//                     <Typography
//                         color="textSecondary"
//                         style={{opacity: 0.75, fontSize: 14, color: createDragCollection ? '#3d5AFE' : null}}
//                     >
//                         {' '}
//                         Create new collection
//                         {' '}
//                     </Typography>
//                 </div>
//             </Grid>
//         :
//         viewType == "Full" ?
//             <Grid
//                 item
//                 style={{minWidth: '100%', maxWidth: '100%', width: '100%', padding: 4, marginBottom: 20, height: '1fr'}}
//             >
//                 <div
//                     onClick={props.openCollectionDialog}
//                     onMouseLeave={() => setHover(false)}
//                     onMouseOver={() => setHover(true)}
//                     style={{
//               border: createDragCollection ? '2px solid #3D5AFE' : '1px solid rgba(196, 196, 196, 0.45)',
//               borderRadius: createDragCollection ? 6 : 4,
//               //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

//               backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.20)' : hover ? '#EFEFEF' : 'transparent',
//               minHeight: 75,
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               flexDirection: 'row',
//               alignItems: 'center',
//               justifyContent: 'center',
//               cursor: 'pointer',
//               paddingLeft: 10,
//               paddingRight: 12,
//               paddingTop: 4,
//               paddingBottom: 4,
//             }}
//                 >
//                     <Add
//                         color="disabled"
//                         style={{marginRight: 8, color: createDragCollection ? '#3d5AFE' : null}}
//                     />
//                     <Typography
//                         color="textSecondary"
//                         style={{opacity: 0.75, fontSize: 14, color: createDragCollection ? '#3d5AFE' : null}}
//                     >
//                         {' '}
//                         Create new collection
//                         {' '}
//                     </Typography>
//                 </div>
//             </Grid>
//         :
//             <Grid
//                 item
//                 style={{padding: 8, minWidth: cardMinWidth, maxWidth: cardSizePercentage, width: cardSizePercentage, height: '1fr',}}
//             >
//                 <div
//                     onClick={props.openCollectionDialog}
//                     onMouseLeave={() => setHover(false)}
//                     onMouseOver={() => setHover(true)}
//                     style={{
//               border: createDragCollection ? '2px solid #3D5AFE' : '1px solid rgba(196, 196, 196, 0.45)',
//               borderRadius: createDragCollection ? 6 : 4,
//               //borderColor: createDragCollection ? '#3D5AFE' : 'rgb(196, 196, 196, 0.45)',

//               backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.2)' : hover ? '#EFEFEF' : 'transparent',
//               width: '100%',
//               height: '100%',
//               minHeight: 75,
//               display: 'flex',
//               flexDirection: 'row',
//               alignItems: 'center',
//               justifyContent: 'center',
//               cursor: 'pointer',
//             }}
//                 >
//                     <Add
//                         color="disabled"
//                         style={{marginRight: 8, color: createDragCollection ? '#3d5AFE' : null}}
//                     />
//                     <Typography
//                         color="textSecondary"
//                         style={{opacity: 0.75, fontSize: 14, color: createDragCollection ? '#3d5AFE' : null}}
//                     >
//                         {' '}
//                         Create new collection
//                         {' '}
//                     </Typography>
//                 </div>
//             </Grid>
//       :
//       null
//     }

//           {
//       createReq.isLoading ?
//       viewType == "List" ?
//           <Grid
//               item
//               style={{minWidth: '100%', maxWidth: '100%', width: '100%', padding: 0, marginBottom: 0, height: '1fr'}}
//           >
//               <div
//                   onMouseLeave={() => setHover(false)}
//                   onMouseOver={() => setHover(true)}
//                   style={{
//             borderRadius: 4,
//             border: '1px solid rgba(196, 196, 196, 0.45)',
//             backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.20)' : hover ? '#EFEFEF' : '#F9F9F9',
//             //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
//             width: '100%',
//             height: '100%',
//             display: 'flex',
//             flexDirection: 'row',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer',
//             padding: 4
//           }}
//               >
//                   <CircularProgress
//                       color="primary"
//                       size={24}
//                       style={{margin: 10}}
//                   />
//               </div>
//           </Grid>
//       :
//       viewType == "Full" ?
//           <Grid
//               item
//               style={{minWidth: '100%', maxWidth: '100%', width: '100%', padding: 4, marginBottom: 20, height: '1fr'}}
//           >
//               <div
//                   onMouseLeave={() => setHover(false)}
//                   onMouseOver={() => setHover(true)}
//                   style={{
//             borderRadius: createDragCollection ? 6 : 4,
//             border: createDragCollection ? '2px solid #3D5AFE' : '1px solid rgba(196, 196, 196, 0.45)',
//             backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.20)' : hover ? '#EFEFEF' : '#F9F9F9',
//             //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
//             width: '100%',
//             height: '100%',
//             display: 'flex',
//             flexDirection: 'row',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer'
//           }}
//               >
//                   <CircularProgress
//                       color="primary"
//                       size={24}
//                       style={{margin: 10}}
//                   />
//               </div>
//           </Grid>
//       :
//           <Grid
//               item
//               style={{padding: 8, minWidth: cardMinWidth, maxWidth: cardSizePercentage, width: cardSizePercentage, height: '1fr',}}
//           >
//               <div
//                   onMouseLeave={() => setHover(false)}
//                   onMouseOver={() => setHover(true)}
//                   style={{
//             borderRadius: createDragCollection ? 6 : 4,
//             border: createDragCollection ? '2px solid #3D5AFE' : '1px solid rgba(196, 196, 196, 0.45)',
//             backgroundColor: createDragCollection ? 'rgb(61, 90, 254, 0.20)' : hover ? '#EFEFEF' : '#F9F9F9',
//             //borderColor: createDragCollection ? 'rgb(61, 90, 254, 0.4)' : 'rgb(196, 196, 196, 0.45)',
//             width: '100%',
//             height: '100%',
//             display: 'flex',
//             flexDirection: 'row',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer'
//           }}
//               >
//                   <CircularProgress
//                       color="primary"
//                       size={24}
//                       style={{margin: 10}}
//                   />
//               </div>
//           </Grid>
//       :
//       null
//     }
//       </Grid>
// }

export default React.memo(CollectionList);
