import React from 'react'

import {
  Paper,
  Typography,

} from '@mui/material';

import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import EmojiComponent from '../../Emojis/EmojiComponent';

import { paramCase } from 'change-case';

export default function CollectionResultObject(props) { 
  //console.log(props.result)

  const LinkToCollection = props.result.workspace && props.result.collection ? `/${paramCase(props.result.workspace._id)}/${props.result.collection.urlId}` : '/'
  const focused = props.focusSelected == props.allMatches.findIndex(r => r && r.collection && r.collection._id == props.result.collection._id)


  return (
      <>
          {
        props.result && props.result.collection && (
        <Paper
            className="selectableResultListItem"
            elevation={0}
            onClick={(e) => props.openCollection(props.result.collection, props.result.workspace, e.metaKey || e.ctrlKey, e.shiftKey)}
            onMouseMove={(e) => {
              if (!focused) {
                //console.log(props.allMatches.findIndex(r => r && r.tab && r.tab._id == props.result.tab._id && r.collection._id == props.result.collection._id))
                props.handleMouseSelected(props.handleMouseSelected(props.allMatches.findIndex(r => r && r.collection && r.collection._id == props.result.collection._id)))
              }
            }}
            ref={focused ? props.focusedRef : null}
            style={{
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif",
            }}

            sx={{
              backgroundColor: focused? 'action.hover' : 'transparent'
            }}
        >
            {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(216, 216, 216, 0.75)'}}>
                tehee
              </div> */}
            <div style={{ display: 'flex', flexDirection: 'column', textOverflow: "ellipsis", overflow: "hidden", width: '100%', }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden", width: '100%', }}>
                    <Typography
                        noWrap
                        style={{ fontWeight: 500 }}
                    >
                        {props.result.collection.name}
                    </Typography>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0 }}>
                    <Typography
                        m={0}
                        noWrap
                        style={{ fontFamily: "\"Work Sans\", \"Helvetica\", \"Arial\", sans-serif", fontWeight: 400, color: '#585858', lineHeight: '25px', }}
                        variant="caption"
                    >
                        {
                    props.result.collection.tabs.length > 0 ?
                      props.result.collection.tabs.length !== 1 ?
                        props.result.collection.tabs.length + " tabs"
                        :
                        props.result.collection.tabs.length + " tab"
                      :
                      "0 tabs"
                  }
                    </Typography>
                    <Typography
                        noWrap
                        style={{ color: '#C4C4C4', fontWeight: 500, marginLeft: 4, marginRight: 4 }}
                        variant='subtitle2'
                    >
                        —
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 0, maxWidth: '80%', }}>
                        <div
                            style={{
                      width: '18px',
                      height: '18px',
                      backgroundColor: 'rgba(110, 110, 110, 0)',
                      borderRadius: '2px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 2,
                      //border: '0.5px solid'
                    }}
                        >
                            {
                      //EMOJI
                      props.result.workspace.icon && props.result.workspace.icon.emoji && props.result.workspace.icon.emoji.emoji ?
                          <EmojiComponent
                              size={16}
                              symbol={props.result.workspace.icon.emoji.emoji}
                          />
                        :
                        //COLOR
                        props.result.workspace.icon && props.result.workspace.icon.colorIcon && props.result.workspace.icon.colorIcon.hex ?
                            <div style={{ width: 10, height: 10, backgroundColor: props.result.workspace.icon.colorIcon.hex, borderRadius: '100%', }} />
                          :
                            <FolderOpenRoundedIcon style={{ fontSize: 14 }} />
                    }

                        </div>
                        <Typography
                            noWrap
                            style={{ color: '#C4C4C4', fontWeight: 500, fontSize: 13, }}
                            variant='subtitle2'
                        >
                            {props.result.workspace && props.result.workspace.name}
                        </Typography>
                    </div>
                </div>
            </div>
        </Paper>
        )
      }
      </>
  )
}