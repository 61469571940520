import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import useRecentSessions from "../../Hooks/useRecentSessions";
import useGlobal from "../../GlobalState/Store/Store.js";

//CUSTOM COMPONENTS
import SessionsList from "./SessionList/SessionsList";
import SessionWindow from "./SessionWindow";
import { useHotkeys } from "react-hotkeys-hook";
import SessionHistoryList from "./SessionList/SessionHistoryList";
import { Collapse } from "@mui/material";
import useSessionHistory from "../../Hooks/useSessionHistory";
import { useSWRConfig } from "swr";
import usePageMeta from "../../Utils/usePageMeta";

function SessionsContainer({ sessionsOpen, handleOpenSettingsDialog }) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  usePageMeta("Sessions | Partizion");

  // ————————— DATA —————————————————//
  const { allSessions, hasMoreSessions, isLoadingMore, pageSize, setPageSize, isLoadingSessions, isSessionsError, mutate } = useRecentSessions(
    globalState.isAuth,
    { revalidateAll: true }
  );

  function groupSessionsbyDate(objects) {
    //console.log("Group sessions")
    const groups = [
      {
        _id: "TODAY",
        name: "TODAY",
        isHeading: true,
        sessions: [],
      },
      {
        _id: "YESTERDAY",
        name: "YESTERDAY",
        isHeading: true,
        sessions: [],
      },
      {
        _id: "PAST WEEK",
        name: "PAST WEEK",
        isHeading: true,
        sessions: [],
      },
      {
        _id: "PAST 30 DAYS",
        name: "PAST 30 DAYS",
        isHeading: true,
        sessions: [],
      },
      {
        _id: "PAST 30 DAYS",
        name: "PAST 30 DAYS",
        isHeading: true,
        sessions: [],
      },
    ];

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setDate(today.getDate() - 30);
    const lastYear = new Date(today);
    lastYear.setDate(today.getFullYear() - 1);

    for (const object of objects) {
      if (!object || (object && !object._id)) return;

      const lastUpdated = new Date(object.lastUpdated);
      const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"];

      if (lastUpdated.getDate() == today.getDate() && lastUpdated.getMonth() == today.getMonth() && lastUpdated.getFullYear() == today.getFullYear()) {
        groups[0].sessions.push(object);
      } else if (
        lastUpdated.getDate() == yesterday.getDate() &&
        lastUpdated.getMonth() == yesterday.getMonth() &&
        lastUpdated.getFullYear() == yesterday.getFullYear()
      ) {
        groups[1].sessions.push(object);
      } else if (lastUpdated < yesterday && lastUpdated >= lastWeek) {
        groups[2].sessions.push(object);
      } else if (lastUpdated < lastWeek && lastUpdated >= lastMonth) {
        groups[3].sessions.push(object);
      } else if (lastUpdated > lastMonth && lastUpdated < lastYear) {
        //START grouping into months
        // console.log("MONTHS")
        let month = monthNames[lastUpdated.getMonth()];
        // console.log({ month });
        let monthGroup = groups.findIndex((g) => g._id === month);
        if (monthGroup > -1) {
          groups[monthGroup].sessions.push(object);
        } else {
          let init = [];
          init.push(object);
          groups.push({
            _id: month,
            name: month,
            isHeading: true,
            sessions: init,
          });
        }
      } else {
        //START grouping into years / months
        //console.log("YEAR-MONTHS")
        let month = monthNames[lastUpdated.getMonth()];
        //console.log({month})
        let year = lastUpdated.getFullYear();
        let monthGroup = groups.findIndex((g) => g._id === `${month} ${year}`);
        if (monthGroup > -1) {
          groups[monthGroup].sessions.push(object);
        } else {
          let init = [];
          init.push(object);
          groups.push({
            _id: `${month} ${year}`,
            name: `${month} ${year}`,
            isHeading: true,
            sessions: init,
          });
        }
      }
    }

    //now sort the groups

    //sort the whole sections array
    //  let sorted = sections.sort((sectionA, sectionB) => {
    //     if (sectionA.sessions[0].lastUpdated > sectionB.sessions[0].lastUpdated) {
    //         return -1;
    //       }
    //       if (sectionA.sessions[0].lastUpdated < sectionB.sessions[0].lastUpdated) {
    //         return 1;
    //       }
    //       // a must be equal to b
    //       return 0;
    // })
    //  //sort the nested sessions
    //  sorted = sorted.map(group => {
    //     return {
    //         ...group,
    //         sessions: group.sessions.sort((sessionA, sessionB) => {
    //             if (sessionA.lastUpdated > sessionB.lastUpdated) {
    //                 return -1;
    //               }
    //               if (sessionA.lastUpdated < sessionB.lastUpdated) {
    //                 return 1;
    //               }
    //               // a must be equal to b
    //               return 0;
    //         })
    //     }
    //  })

    return groups.filter((g) => g && g.sessions && g.sessions.length > 0);
  }
  // console.time('memod calc');
  const sessionSections = useMemo(() => {
    //OLD
    //this could be find, but I see it running lots in the console, so I'll try memoizing it instead
    //other state changes are making it recalculate on every change
    // console.time('sessions grouping');
    // let sessionSections1 =  allSessions && allSessions.length > 0 ? groupSessionsbyDate(allSessions) : []
    // console.timeEnd('sessions grouping');
    //console.log({allSessions})
    if (allSessions && allSessions.length > 0)
      return groupSessionsbyDate(allSessions); // Skipped if allSessions hasen't changed
    else return [];
  }, [allSessions]);
  // console.timeEnd('memod calc');

  let firstSessionId = allSessions && allSessions.length > 0 ? allSessions[0]._id : null;
  const [selectedSessionId, setSelectedSessionId] = useState(firstSessionId);
  const selectedSession = allSessions.find((item) => item._id === selectedSessionId);

  //initializes / resets the selected session
  useEffect(() => {
    //because there is no other way to gaurantee that the first session is selected on the first render (with data)
    if (firstSessionId !== null && selectedSessionId === null) setSelectedSessionId(firstSessionId);
  }, [firstSessionId]);

  //console.log({data, allSessions})

  // ————————— EVENT HANDLERS —————————————————//
  const [showSessionHistory, setShowSessionHistory] = useState(null);
  const toggleShowSessionHistory = (id) => {
    // let selectedSessionState = session || selectedSession
    // console.log(selectedSessionState)
    // if(!selectedSessionState || selectedSessionState && !selectedSessionState._sessionId) return
    setShowSessionHistory((prev) => {
      if (prev !== null || id === undefined) {
        clearSessionHistoryCache();
        return null;
      } else {
        return id;
      }
    });
  };
  const clearSessionHistoryCache = () => {
    // console.log({cache})
    let cursorKeys = [...cache.keys()]?.filter((key) => typeof key === "string" && key.startsWith("/sessions/history?sessionId") && key.includes("cursor"));
    // console.log({cursorKeys})
    if (cursorKeys && cursorKeys.length) {
      //   cursorKeys.forEach(k => console.log(k, cache.delete(k)))
      cursorKeys.forEach((k) => cache.delete(k));
    }
    let infKeys = [...cache.keys()]?.filter((key) => typeof key === "string" && key.startsWith("$inf$/sessions/history?sessionId"));
    if (infKeys && infKeys.length) {
      infKeys.forEach((k) => {
        let infCache = cache.get(k);
        if (infCache && infCache.data) {
          // console.log(infCache)
          // console.log([...infCache.data.slice(0, 1)])
          cache.set(k, { ...infCache, data: [{ ...infCache.data[0], cursor: undefined }] }); //, cursor: undefined
        }
      });
    }
    setHistoryPageSize(1);
  };

  //SELECT SESSIONS
  const handleSelectSession = (sessionId) => {
    setSelectedSessionId(sessionId);
  };
  const handleSelectNext = () => {
    setSelectedSessionId((prevId) => {
      let currentIndex = allSessions.findIndex((session) => session._id === selectedSessionId);
      if (currentIndex === -1 || currentIndex === allSessions.length - 1) {
        return prevId;
      }
      scrollToItem(allSessions[currentIndex + 1]._id);
      return allSessions[currentIndex + 1]._id;
    });
  };
  const handleSelectPrevious = () => {
    if (focusSelected && focusSelected !== selectedSession._id) return;
    setSelectedSessionId((prevId) => {
      //   console.log(prevId, allSessions?.length);
      let currentIndex = allSessions.findIndex((session) => session._id === selectedSessionId);
      //   console.log(currentIndex);
      if (currentIndex > 0) {
        // console.log("Return -1");
        scrollToItem(allSessions[currentIndex - 1]._id);
        return allSessions[currentIndex - 1]._id;
      } else {
        // console.log("Set to 0");
        //just set to the first session
        if (allSessions && allSessions.length && allSessions[0] && allSessions[0]._id) return allSessions[1]._id;
        else return null;
      }
    });
  };
  const handleHotkeyIncrement = (hotkey) => {
    // console.log("SESSION", { hotkey });
    if (selectedSession) {
      switch (hotkey) {
        case "up":
        case "k":
          setSelectedSessionId((prevId) => {
            let currentIndex = allSessions.findIndex((session) => session._id === prevId);
            if (currentIndex === -1 || currentIndex === 0) {
              return prevId;
            }
            scrollToItem(allSessions[currentIndex - 1]._id);
            return allSessions[currentIndex - 1]._id;
          });
          break;
        case "down":
        case "j":
          setSelectedSessionId((prevId) => {
            let currentIndex = allSessions.findIndex((session) => session._id === prevId);
            if (currentIndex === -1 || currentIndex === allSessions.length - 1) {
              return prevId;
            }
            scrollToItem(allSessions[currentIndex + 1]._id);
            return allSessions[currentIndex + 1]._id;
          });
          break;
      }
    } else {
      return;
    }
  };

  // -- Refs
  const sessionListRootRef = useRef(null);
  const listRefs = useRef(null);
  function getListRefsMap() {
    if (!listRefs.current) {
      // Initialize the Map on first usage.
      listRefs.current = new Map();
    }
    return listRefs.current;
  }

  const virtuosoRef = useRef(null);

  const scrollToItem = (id, scrollTop) => {
    let align = "start";
    let behavior = "instant";

    let index = allSessions.findIndex((sesh) => sesh && sesh._id === id);

    if (scrollTop) {
      virtuosoRef.current.scrollToIndex({
        index: 0,
        align,
        behavior,
      });
    } else {
      virtuosoRef.current.scrollIntoView({
        index: index,
        align,
        behavior,
      });
    }
  };
  //custom
  // const scrollToItem = (id, scrollTop) => {
  // const map = getListRefsMap();
  // const node = map.get(id);

  //     console.log({id, node, scrollTop})
  //     if(node){
  //         console.log("NODE XISTS")

  //         //lol
  //         let parent = node.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement

  //         SmoothScroll(parent, node, 200, scrollTop)
  //    }
  // }

  //Infinite scroll
  const loadMoreSessions = () => setPageSize(pageSize + 1);
  useEffect(() => {
    setPageSize(1); //reset cache when sessions page opens/closes. used in conjunction with clearSessionCache on MainContainer
    // The reason it's here and not in main container is because there is no way to call setPagheSize from the main container without
    // Pre loading sessions in the main container (we don't want this because it means we call /sessions/recent on every dashboard render)
  }, [sessionsOpen]);

  //--- SESSION HISTORY
  //belongs here because I lifted state up from SessionHistoryList
  const {
    sessionHistory,
    hasMoreHistory,
    historyPageSize,
    setHistoryPageSize,
    isLoadingHistory,
    isLoadingMoreHistory,
    isValidatingHistory,
    isHistoryError,
    mutate: mutateSessionHistory,
  } = useSessionHistory(globalState.isAuth, selectedSession && selectedSession._sessionId && showSessionHistory ? selectedSession._sessionId : null);
  const loadMoreHistory = () => setHistoryPageSize(historyPageSize + 1);
  const { cache } = useSWRConfig();

  let firstSessionHistoryId = sessionHistory && sessionHistory.length > 0 ? sessionHistory[0]._id : null;
  const [selectedSessionHistoryId, setSelectedSessionHistoryId] = useState(firstSessionHistoryId);
  const selectedSessionHistory = sessionHistory.find((item) => item._id === selectedSessionHistoryId);

  //initializes / resets the selected session history
  useEffect(() => {
    //because there is no other way to gaurantee that the first session is selected on the first render (with data)
    if (firstSessionHistoryId !== null && selectedSessionHistoryId === null) setSelectedSessionHistoryId(firstSessionHistoryId);
    else if (selectedSessionHistoryId !== null && !showSessionHistory) setSelectedSessionHistoryId(null);
  }, [firstSessionHistoryId, showSessionHistory]);

  const handleSelectSessionHistory = (sessionHistoryId) => {
    setSelectedSessionHistoryId(sessionHistoryId);
  };
  const handleSelectNextHistory = () => {
    setSelectedSessionHistoryId((prevId) => {
      let currentIndex = sessionHistory.findIndex((session) => session._id === selectedSessionHistoryId);
      if (currentIndex === -1 || currentIndex === sessionHistory.length - 1) {
        return prevId;
      }
      scrollToHistoryItem(sessionHistory[currentIndex + 1]._id);
      return sessionHistory[currentIndex + 1]._id;
    });
  };
  const handleSelectPreviousHistory = () => {
    setSelectedSessionHistoryId((prevId) => {
      let currentIndex = sessionHistory.findIndex((session) => session._id === selectedSessionHistoryId);
      if (currentIndex === -1 || currentIndex === 0) {
        return prevId;
      }
      scrollToHistoryItem(sessionHistory[currentIndex - 1]._id);
      return sessionHistory[currentIndex - 1]._id;
    });
  };
  const handleHotkeyIncrementHistory = (hotkey) => {
    // console.log("HISTORY", { hotkey });
    if (selectedSessionHistory) {
      switch (hotkey) {
        case "up":
        case "k":
          setSelectedSessionHistoryId((prevId) => {
            let currentIndex = sessionHistory.findIndex((session) => session._id === prevId);
            // console.log({ currentIndex });
            if (currentIndex === -1 || currentIndex === 0) {
              return prevId;
            }
            scrollToHistoryItem(sessionHistory[currentIndex - 1]._id);
            return sessionHistory[currentIndex - 1]._id;
          });
          break;
        case "down":
        case "j":
          // console.log("NEXT HISTORY", { selectedSessionHistory });
          setSelectedSessionHistoryId((prevId) => {
            let currentIndex = sessionHistory.findIndex((session) => session._id === prevId);
            // console.log({ currentIndex });
            if (currentIndex === -1 || currentIndex === sessionHistory.length - 1) {
              return prevId;
            }
            const nextIndex = currentIndex + 1;
            scrollToHistoryItem(sessionHistory[currentIndex + 1]._id);
            return sessionHistory[nextIndex]._id;
          });
          break;
      }
    } else {
      return;
    }
  };

  const [keyHeldDown, setkeyHeldDown] = useState(null); // the key being held down
  const [keyHeldIntervalId, setKeyHeldIntervalId] = useState(null);
  const [keyHeldTimeoutId, setKeyHeldTimeoutId] = useState(null);

  useHotkeys(
    "j, down, k, up",
    (e, handler) => {
      let hotkey = handler.keys[0];
      if (e.type === "keydown") {
        if (showSessionHistory) {
          handleHotkeyIncrementHistory(hotkey);
        } else {
          handleHotkeyIncrement(hotkey);
        }
        setkeyHeldDown(hotkey);
        const id = setTimeout(() => {
          const intervalId = setInterval(() => {
            // console.log(`${keyHeldDown} (${hotkey}) key is being held down`);
            if (showSessionHistory) {
              handleHotkeyIncrementHistory(hotkey);
            } else {
              handleHotkeyIncrement(hotkey);
            }
          }, 100);
          setKeyHeldIntervalId(intervalId);
        }, 250);
        setKeyHeldTimeoutId(id);
      }

      if (e.type === "keyup") {
        clearTimeout(keyHeldTimeoutId);
        clearInterval(keyHeldIntervalId);
        setKeyHeldIntervalId(null);
        setKeyHeldTimeoutId(null);
        setkeyHeldDown(false);
      }
    },
    { keyup: true, keydown: true },
    [
      keyHeldIntervalId,
      setKeyHeldIntervalId,
      keyHeldTimeoutId,
      setKeyHeldTimeoutId,
      keyHeldDown,
      setkeyHeldDown,
      selectedSessionId,
      selectedSessionHistoryId,
      showSessionHistory,
      handleHotkeyIncrementHistory,
      handleHotkeyIncrement,
    ]
  );

  //DELETE SESSION AND IT's HISTORY
  const DeleteSession = async (event, _sessionId) => {
    event.preventDefault();

    //helper
    function findIndices(arr, sessionId) {
      const initialIndex = arr.findIndex((innerArr) => innerArr.sessions.findIndex((obj) => obj._sessionId === sessionId) !== -1);
      if (initialIndex === -1) return [-1, -1];
      const nestedIndex = arr[initialIndex].sessions.findIndex((obj) => obj._sessionId === sessionId);
      return [initialIndex, nestedIndex];
    }
    handleSelectPrevious();

    // console.log(_sessionId)
    mutate(
      async (sessionsData) => {
        // remove the collection

        if (sessionsData) {
          // console.log({ sessionsData });
          const [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId);

          //console.log({initialIndex, nestedIndex})

          if (nestedIndex > -1) {
            const updatedNestedArray = [
              ...sessionsData[initialIndex].sessions.slice(0, nestedIndex),
              ...sessionsData[initialIndex].sessions.slice(nestedIndex + 1),
            ];
            const updatedArray = [
              ...sessionsData.slice(0, initialIndex),
              { ...sessionsData[initialIndex], sessions: updatedNestedArray },
              ...sessionsData.slice(initialIndex + 1),
            ];

            // console.log({updatedNestedArray, updatedArray})

            return updatedArray;
          } else {
            console.log("didn't find session");
            return sessionsData;
          }
        } else {
          // console.log("no mutate on delete")
          return sessionsData;
        }
      },
      { revalidate: false }
    );

    const data = {
      _sessionId: _sessionId,
    };

    axios
      .post("/sessions/delete", data)
      .then(() => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutate();
      })
      .catch((err) => {
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutate();
        console.log(err);
      });
  };

  useHotkeys(
    "backspace",
    (e, _handler) => {
      if (!!selectedSession?._sessionId && !showSessionHistory) {
        DeleteSession(e, selectedSession._sessionId);
      }
    },
    {},
    [DeleteSession, selectedSession, showSessionHistory]
  );

  //OPEN OR SWITCH TO SESSION
  const handleOpenTabs = (event, type, forceShiftKey) => {
    event.stopPropagation();
    event.preventDefault();

    if (globalState.extensionConnection.isConnected) {
      var shiftKey = event.shiftKey || forceShiftKey ? true : false;
      globalState.extensionConnection.port.postMessage({
        type: "openSession",
        shiftKey: shiftKey,
        session: selectedSession,
        openType: type,
        windowId: globalState.extensionConnection.windowId,
      });
    }
  };

  const [openKeyPressed, setOpenKeyPressed] = useState(false);
  const timeoutOpenKeyPressed = useRef(0);

  const onOKeyPressed = useCallback((value) => {
    //set to o key pressed = true
    if (!openKeyPressed) setOpenKeyPressed(value);

    //delay setting to false so that we can listen for other keys
    if (timeoutOpenKeyPressed.current) {
      clearTimeout(timeoutOpenKeyPressed.current);
    }
    timeoutOpenKeyPressed.current = setTimeout(() => setOpenKeyPressed(false), 500);
  });

  useHotkeys(
    "o, shift+o",
    (e, handler) => {
      //only fire event if O key was pressed before hand
      if (openKeyPressed && !!selectedSession?._sessionId) {
        // (O then O ) open in current window
        // console.log(`(O then O ) open in current window`);
        const forceShiftKey = handler.keys && handler.keys.length >= 1 ? handler.keys.includes("shift") : false;
        // console.log({ forceShiftKey });
        handleOpenTabs(e, "add", forceShiftKey);
      } else {
        onOKeyPressed(true);
      }
    },
    { enabled: !showSessionHistory },
    [openKeyPressed, handleOpenTabs, selectedSession]
  );
  useHotkeys(
    "n",
    (e, _handler) => {
      //only fire event if O key was pressed before hand
      if (openKeyPressed && !!selectedSession?._sessionId) {
        // (O then N) open in new window
        // console.log(`(O then N) open in new window`);
        handleOpenTabs(e, "add", true);
      }
    },
    { enabled: !showSessionHistory },
    [openKeyPressed, handleOpenTabs, selectedSession]
  );
  useHotkeys(
    "s",
    (e, _handler) => {
      //only fire event if O key was pressed before hand
      if (openKeyPressed && !!selectedSession?._sessionId) {
        // (O then s) switch to
        // console.log(`(O then s) switch to`);
        handleOpenTabs(e, "replace");
      }
    },
    { enabled: !showSessionHistory },
    [openKeyPressed, handleOpenTabs, selectedSession]
  );

  // -- Refs
  const historyListRefs = useRef(null);
  function getHistoryListRefsMap() {
    if (!historyListRefs.current) {
      // Initialize the Map on first usage.
      historyListRefs.current = new Map();
    }
    return historyListRefs.current;
  }

  const virtuosoHistoryRef = useRef(null);
  const scrollToHistoryItem = (id, scrollTop) => {
    let align = "start";
    let behavior = "instant";

    let index = sessionHistory.findIndex((sesh) => sesh && sesh._id === id);

    if (scrollTop) {
      virtuosoHistoryRef.current.scrollToIndex({
        index: 0,
        align,
        behavior,
      });
    } else {
      virtuosoHistoryRef.current.scrollIntoView({
        index: index,
        align,
        behavior,
      });
    }
  };

  //SESSION MENU
  const [sessionMenuAnchorEl, setSessionMenuAnchorEl] = React.useState(null);
  const handleOpenSessionMenu = (event) => {
    // console.log(event.currentTarget);
    setSessionMenuAnchorEl(event.currentTarget);
  };
  const handleCloseSessionMenu = (event) => {
    setSessionMenuAnchorEl(null);
    setSessionMenuClickPosition(null);
  };

  //RIGHT CLICKING SESSION MENU
  const [sessionMenuClickPosition, setSessionMenuClickPosition] = useState(null);
  const [focusSelected, setFocusSelected] = useState(null);

  //----------- END session history
  // const sessionWindowPropsKey = showSessionHistory && selectedSessionHistory && selectedSessionHistory._id ? selectedSessionHistory._id : selectedSession && selectedSession._id ? selectedSession._id : "session-window-ui"
  const sessionWindowPropsKey = !showSessionHistory
    ? selectedSessionHistory && selectedSessionHistory._id
      ? selectedSessionHistory._id
      : selectedSession && selectedSession._id
        ? selectedSession._id
        : new Date().getTime() //'session-window-ui' // new Date().getTime()
    : new Date().getTime(); //'session-history-window-ui' // new Date().getTime()

  // const sessionWindowPropsKey = showSessionHistory && selectedSessionHistoryId ? selectedSessionHistoryId :selectedSessionId ? selectedSessionId : "session-window-ui"
  const listWidth = 400;

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        // border: '2px solid blue',
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Collapse
        in={!showSessionHistory}
        orientation="horizontal"
        style={
          {
            //border: '2px solid blue'
          }
        }
        timeout={350}
      >
        <div
          style={{
            width: listWidth, //update in virtualized session list if you change this
            display: "flex",
            flexDirection: "column",
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            height: "100%",

            // width: !showSessionHistory ? 400 : 0,
            // transform: !showSessionHistory ? "none" : 'translateX(-100%)',
            // visibility: !showSessionHistory ? 'visible' : 'hidden',
            // transition: 'transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1), visibility 0.5s cubic-bezier(0.4, 0.0, 0.2, 1)',
          }}
        >
          <SessionsList
            allSessions={allSessions}
            focusSelected={focusSelected}
            getListRefsMap={getListRefsMap}
            handleOpenSessionMenu={handleOpenSessionMenu}
            handleOpenSettingsDialog={handleOpenSettingsDialog}
            handleSelectSession={handleSelectSession}
            hasMoreSessions={hasMoreSessions}
            isError={isSessionsError}
            isLoading={isLoadingSessions}
            isLoadingMore={isLoadingMore}
            // ref={sessionListRootRef}
            listRefs={listRefs}
            listWidth={listWidth}
            loadMoreSessions={loadMoreSessions}
            sections={sessionSections}
            selectedSession={selectedSession}
            sessionListRootRef={sessionListRootRef}
            setFocusSelected={setFocusSelected}
            setSessionMenuClickPosition={setSessionMenuClickPosition}
            virtuosoRef={virtuosoRef}
          />
        </div>
      </Collapse>
      <SessionWindow
        focusSelected={focusSelected}
        handleCloseMenu={handleCloseSessionMenu}
        handleOpenMenu={handleOpenSessionMenu}
        handleSelectNext={handleSelectNext}
        handleSelectPrevious={handleSelectPrevious}
        handleSelectSession={handleSelectSession}
        isError={isSessionsError}
        isLoading={isLoadingSessions}
        // key={selectedSession || selectedSessionHistory}
        // key={selectedSessionId}
        // key={sessionWindowPropsKey} //passed to virtuoso so it knows to update
        keyId={sessionWindowPropsKey}
        menuAnchorEl={sessionMenuAnchorEl}
        selectedSession={selectedSession}
        selectedSessionHistory={selectedSessionHistory}
        sessionHistory={sessionHistory}
        sessionMenuClickPosition={sessionMenuClickPosition}
        sessions={allSessions}
        setFocusSelected={setFocusSelected}
        setSessionMenuClickPosition={setSessionMenuClickPosition}
        showSessionHistory={showSessionHistory}
        toggleShowSessionHistory={toggleShowSessionHistory}
      />

      <Collapse
        in={showSessionHistory}
        orientation="horizontal"
        style={
          {
            //border: '2px solid blue'
          }
        }
        timeout={350}
        unmountOnExit
      >
        <SessionHistoryList
          getHistoryListRefsMap={getHistoryListRefsMap}
          handleSelectSessionHistory={handleSelectSessionHistory}
          hasMoreHistory={hasMoreHistory}
          isLoadingHistory={isLoadingHistory}
          isLoadingMoreHistory={isLoadingMoreHistory}
          loadMoreHistory={loadMoreHistory}
          open={showSessionHistory}
          scrollToSession={scrollToItem}
          selectedSessionHistory={selectedSessionHistory}
          session={selectedSession}
          sessionHistory={sessionHistory}
          showSessionHistory={showSessionHistory}
          toggleShowSessionHistory={toggleShowSessionHistory}
          virtuosoRef={virtuosoHistoryRef}
        />
      </Collapse>
    </div>
  );
}

export default SessionsContainer;
