import React from 'react';
import EmojiComponent from './EmojiComponent.js';


import {
    Tooltip,
    Typography,
    Box
  } from '@mui/material';

  const SelectEmoji = React.memo(function SelectEmoji(props) {
    const handleSelectEmoji = props.handleSelectEmoji
    const externalHover = props.externalHover
    const [hover, setHover] = React.useState(false)
    const emojiRef = React.createRef()

    const stateIndex = props.emojiStateArray && props.object && props.object.emoji ? props.emojiStateArray.findIndex(e => e.emoji === props.object.emoji) : null

    // useEffect(() => {
    //   if(externalHover && externalHover.emoji && externalHover.emoji == props.object.emoji){
    //     //var el = highlightedEmoji && highlightedEmoji.emoji ? document.getElementById(highlightedEmoji.emoji) : null
    //     console.log({emojiRef})
    //     if(emojiRef.current){
    //       if(emojiRef.current.parentElement.offsetTop + emojiRef.current.parentElement.clientHeight - emojiRef.current.parentElement.offsetParent.scrollTop >= 288 || emojiRef.current.parentElement.offsetTop - emojiRef.current.parentElement.clientHeight < emojiRef.current.parentElement.offsetParent.scrollTop) //288 = viewport height
    //         props.scrollToEmoji(stateIndex)
    //     } 
    //   }
    // }, [externalHover])
    
    return (
        <>
            {
        props.object && props.object.emoji ?
            <Tooltip
                PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '0px, -80px'
                  }
                }
              }
            }}
                disableInteractive
                enterDelay={500}
                enterNextDelay={250}
                leaveDelay={0}
            //TransitionComponent={React.Fragment}
            // onOpen={event => {
            //   console.log(event)
            // }}
                placement="top"
                title={
                  props.object && props.object.description && (
                  <React.Fragment>
                      <Typography
                          color="inherit"
                          style={{fontSize: 12}}
                          variant="subtitle2"
                      >
                          {props.object.description}
                      </Typography>
                  </React.Fragment>
                  )
                }
            >
                <Box
                    onClick={(e) => handleSelectEmoji(props.object)}   
                    onMouseLeave={() => {
                      setHover(false)
                    }}
                //className={`${classes.emojiSelectWrapper} selectableEmoji`}  
                    onMouseOver={() => {
                  //console.log('cur hover' + props.object.emoji)
                      if(stateIndex != null && props.object && props.object.emoji && externalHover && externalHover.emoji && externalHover.emoji != props.object.emoji){
                        //console.log("SET HOVER " + props.object.emoji)
                        props.highlightEmoji(stateIndex, props.object.emoji, true)
                      }
                    }}

                //onMouseOver={() => setHover(true)}
                    ref={emojiRef}
                    style={{backgroundColor: externalHover && externalHover.emoji && externalHover.emoji == props.object.emoji || hover ? 'rgba(196, 196, 196, 0.4)' : 'rgba(196, 196, 196, 0)',}}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'vertical',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 30,
                      maxHeight: 30,
                      minWidth: 30,
                      maxWidth: 30,      
                      paddingTop: 0.5,
                      margin: 0.25,
                      borderRadius: 1,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      },
                    }}
                >
                    <EmojiComponent
                        size={24}
                        symbol={props.object.emoji}
                    />
                </Box>
            </Tooltip>
        :
        null
      }
        </>
    )});
    
export default SelectEmoji;
