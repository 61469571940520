import React, {useState} from 'react';
import axios from 'axios'

import {useNavigate} from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';

import Add from '@mui/icons-material/Add';

import useGlobal from '../../GlobalState/Store/Store.js';

import { mutate } from 'swr'

export default function CreateWorkSpaceDialog(props) {
  const [globalState, globalActions] = useGlobal()
  let navigate = useNavigate();
  
  const open = props.open
  const handleClose = props.handleClose

  const initName = props.oldName || ""
  const isUpdate = props.isUpdate
  const workspace = props.workspace
  const title = isUpdate && workspace != null && workspace != undefined ? "Rename the workspace " + workspace.name : "Name your new Workspace"
  const [name, setName] = useState(initName)
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null
  })
  let disableButton = name === "" || name === " " || req.loading ? true : false


  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const closeDialog = () => {
    setName("")
    setReq({
      loading: false,
      isError: false,
      error: null
    })
    handleClose()
  }

  const handleKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode
    //console.log("key " + charCode + " pressed")
    if(charCode == 13){
      //console.log("Enter key pressed")
       //if enter is pressed save name
       if(!disableButton){
         CreateWorkSpace(event)
         // CreateWSButton.click()

       }
    }
  }

  function CreateWorkSpace(event){
    setReq({
      loading: true,
      isError: false,
      error: null
    })
    // event.preventDefault()
    //event.stopPropagation()
    //event.target.focus()

    //blur list button
    // if (document && document.activeElement){
    //   console.log(document.activeElement)
    //   document.activeElement.blur()
    // }

    // var RootDOM = document.getElementById('RootDOM')
    // console.log(RootDOM)
    // RootDOM.focus()

    var CreateWSButton = document.getElementById("AddWorkspaceButton")

    const data = {
      name: name
    }

    function StopLoading(){
      setReq({
        loading: false,
        isError: false,
        error: null
      })
      setName("")
      closeDialog()
    }

    axios
      .post("/workspaces/create", data)
      .then(res => {
        //console.log("Created Workspace", res.data)

        if(res.data && res.data.workspace){
          globalActions.saveActiveWorkspace(res.data.workspace)
            navigate(`/${res.data.workspace._id}`)
        }
        StopLoading()
        mutate(`/workspaces`)
      })
      .then(() => {
        if(CreateWSButton) CreateWSButton.blur()
      })
      .catch(err => {
        //console.log("Create workspace failed")
        setReq({
          loading: false,
          isError: true,
          error: err.response ? err.response.data : { code: 5, message: "Internal Server Error"}
        })
      });
  }

  return (
      <div>
          <Dialog
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              onClose={() => closeDialog()}
              onKeyPress={(event) => handleKeyPress(event)}
              open={open}
          >
              <DialogTitle id="alert-dialog-title">
                  {title}
              </DialogTitle>

              <DialogContent style={{width: "500px", paddingTop: '0px'}}>
                  <Typography
                      gutterBottom
                      style={{color: "#6E6E6E"}}
                      variant="body2"
                  > 
                      {' '}
                      A workspace is an area to separate the things you work on (i.e Projects, Work, School, a side hustle, etc.)
                      {' '}
                  </Typography>
                  {
            req.isError ?
                <Typography
                    color="secondary"
                    gutterBottom
                    variant="body2"
                > 
                    {' '}
                    {req.error.message}
                    {' '}
                </Typography>
            :
            null
          }
                  {
            req.loading ?
                <div style={{minHeight: 100, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
                    <Typography style={{color: "#C4C4C4"}}>
                        {' '}
                        Creating workspace...
                        {' '}
                    </Typography>
                </div>
            :
                <div style={{minHeight: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <TextField
                        autoComplete='off'
                        autoFocus
                        fullWidth
                        id="workspacename"
                        label="Workspace Name"
                        margin="dense"
                        onChange={handleChangeName}
                        style={{minHeight: 50}}
                        type="string"
                        value={name}
                        variant="outlined"
                    />
                </div>
          }
              </DialogContent>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'flex-end',}}>
                  <Button
                      color="secondary"
                      onClick={() => closeDialog()}
                  >
                      Cancel
                  </Button>
                  <div 
                      style={{
                        margin: 24,
                      position: 'relative',
                    }}
                  >
                      <Button
                          autoFocus
                          color="primary"
              // ref={(button) => {CreateWSButton=button}}
                          disableFocusRipple
                          disabled={disableButton}
                          fullWidth
                          onClick={() => CreateWorkSpace()}
                          startIcon={<Add />}
                          style={{height: 40, maxHeight: 40, minHeight: 40}}
                          variant="contained"
                      >
                          Create new workspace
                      </Button>
                      {req.loading && <CircularProgress
                          size={24}
                          style={{position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: -12,
                          marginLeft: -12,}}
                                      />}
                  </div>
              </div>
          </Dialog>
      </div>
  );
}

// <DialogContentText>
//   To subscribe to this website, please enter your email address here. We will send updates
//   occasionally.
// </DialogContentText>
