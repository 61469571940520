import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Popover } from "@mui/material";

export const DefaultPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(0.5),
    marginLeft: 0,
    minWidth: 250,
    color: theme.palette.text.primary, //color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    // boxShadow:
    //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    border: `1px solid ${theme.palette.action.hover}`,
    borderRadius: 6,

    //menus and menu items
    "& .MuiMenu-list": {
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& .MuiMenuItem-root": {
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4,
      fontSize: 14,
      minWidth: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.primary,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 1,
        marginRight: 6,
      },
      "&:active": {
        backgroundColor: theme.palette.action.selected,
      },
    },

    //lists and list items
    "& .MuiList-root": {
      paddingTop: 4,
      paddingBottom: 4,
      padding: 8,
      display: "flex",
      flexDirection: "column",
    },
    "& .MuiListItem-root": {
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: 4,
      marginRight: 4,
      fontSize: 14,

      minWidth: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.primary,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 1,
        marginRight: 6,
      },
      "&:active": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  },
}));

// '&:active': {
//   backgroundColor: alpha(
//     theme.palette.primary.main,
//     theme.palette.action.selectedOpacity,
//   ),
// },
