export default function toReadableDate(isoDate){
    let baseDate = new Date(isoDate)
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    let shortMonth = shortMonths[baseDate.getMonth()]
    let date = baseDate.getDate()
    let day = days[baseDate.getDay()]
    let year = baseDate.getFullYear()
    let time = baseDate.toLocaleTimeString([], {timeStyle: 'short'})

    let diffToToday = (new Date() - baseDate) / 1000 //returns difference in seconds
    const diffMins = Math.floor(diffToToday / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffYears = Math.floor(diffDays / 365);
    
    if(diffMins < 1) {
        return "Just now"
    }else if(diffMins == 1) {
        return diffMins + " min" + " ago"
    } else if(diffMins > 1 && diffMins < 60) {
        return diffMins + " mins" + " ago"
    } else if(diffHours == 1) {
        return diffHours + " hour ago"
    } else if(diffHours >= 1 && diffHours <= 12) {
        return diffHours + " hours ago"
    } else if(diffHours >= 12 && diffHours < 24) {
        return "Today at " + time
    } else if(diffDays >= 1 && diffDays < 2) {
        return "Yesterday at " + time
    } else if(diffDays >= 2 && diffDays <= 7) {
        return "Last " + day + " at " + time
    } else if(diffYears < 1){
        return shortMonth + " " + date 
        //+ " — " + time    
    }
    else if (diffYears >= 1) {
        return shortMonth + " " + date + ", " + year
    }
    else{
        return shortMonth + " " + date + ", " + year + " " + time     
    } 

}


//LEET CODE

// (function main() {
//     console.log('Hello World!');
    
//     const returnDate = (isoDate) => {
//         let baseDate = new Date(isoDate)
//         const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
//         const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        
//         let shortMonth = shortMonths[baseDate.getMonth()]
//         let date = baseDate.getDate()
//         let day = days[baseDate.getDay()]
//         let year = baseDate.getFullYear()
//         let time = baseDate.toLocaleTimeString([], {timeStyle: 'short'})

//         let diffToToday = (new Date() - baseDate) / 1000 //returns difference in seconds
//         const diffMins = Math.floor(diffToToday / 60);
//         const diffHours = Math.floor(diffMins / 60);
//         const diffDays = Math.floor(diffHours / 24);
//         const diffYears = Math.floor(diffDays / 365);
        
//        if(diffMins < 1) {
//             return "Just now"
//         }else if(diffMins == 1) {
//             return diffMins + " min" + " ago"
//         } else if(diffMins > 1 && diffMins < 60) {
//             return diffMins + " mins" + " ago"
//         } else if(diffHours == 1) {
//             return diffHours + " hour ago"
//         } else if(diffHours >= 1 && diffHours <= 3) {
//             return diffHours + " hours ago"
//         } else if(diffHours >= 3 && diffHours <= 12) {
//             return "Today at " + time
//         } else if(diffDays >= 1 && diffDays < 2) {
//             return "Yesterday at " + time
//         } else if(diffDays >= 2 && diffDays <= 7) {
//             return "Last " + day + " at " + time
//         }  
        
//         else if (diffYears >= 1) {
//             return shortMonth + " " + date + ", " + year
//         }
//         else{
//             return shortMonth + " " + date + ", " + year + " " + time     
//         }
//     }
    
//     var twoHoursBefore = new Date();
//     twoHoursBefore.setHours(twoHoursBefore.getHours() - 2);
//      var earlierToday = new Date();
//     earlierToday.setHours(earlierToday.getHours() - 8);
//     var yesterday = new Date();
//     yesterday.setHours(yesterday.getHours() - 28);
//     var lastWeek = new Date();
//     lastWeek.setHours(lastWeek.getHours() - 73);
//     var minbefore = new Date();
//     minbefore.setMinutes(twoHoursBefore.getMinutes() - 58);
    
//     console.log(returnDate(new Date())) //now
//     console.log(returnDate(minbefore)) //1 min earlier
//     console.log(returnDate(twoHoursBefore)) //2 hours earlier
//     console.log(returnDate(earlierToday)) // earlier
//     console.log(returnDate(yesterday)) // yesterday
//     console.log(returnDate(lastWeek)) // lastWeek
//     console.log(returnDate(new Date("2022-06-28"))) //1 month ago
//     console.log(returnDate(new Date("2020-12-31"))) //2 years ago
    
    
// }());