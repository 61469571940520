import React from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';

import { DefaultMenu } from './DefaultMenu';

import {
  EditRounded,
  DeleteOutlineRounded,
} from '@mui/icons-material';


//for reordering


export default function WorkspaceMenu(props) {
  const handleClick = props.handleClick
  const handleClose = props.handleClose
  const anchorEl = props.anchor
  const collection = props.collection
  const setEditWorkspace = props.setEditWorkspace
  const openDelete = props.openDelete
  const workspace = props.workspace

  //const editUrl = `/workspace/${paramCase(globalState.workspaces[activeWorkspace].name)}/${collection.urlId}`

  const options = [
    {
      name: "view collection",
      status: 'normal',
    },
    {
      name: "no Icon",
      status: 'danger'
    },
  ] //props.options
  function setEdit(e){
    e.preventDefault()
    e.stopPropagation()
    handleClose(e)
    setEditWorkspace(workspace)
  }
  function OpenDeleteMenu(e){
    e.preventDefault()
    e.stopPropagation()
    handleClose(e)
    openDelete()
  }

  const lastWorkspace = props.lastWorkspace

  return (
      <div>
          <DefaultMenu
              MenuListProps={{ dense: true }}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              anchorPosition={
                props.clickPosition !== undefined ?
                props.clickPosition.mouseY !== null && props.clickPosition.mouseX !== null
                  ? { top: props.clickPosition.mouseY, left: props.clickPosition.mouseX}
                  : undefined
                :
                null
              }
              anchorReference={props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "anchorPosition" : "anchorEl"}
              id="workspace-menu"

              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}
              transformOrigin={{ vertical: "top", horizontal: props.clickPosition !== undefined && props.clickPosition.mouseY !== null ? "left" : "left"}}
          >
              <MenuItem onClick={(e) => setEdit(e)}>
                  <ListItemIcon>
                      <EditRounded fontSize="small" />
                  </ListItemIcon>
                  Edit Workspace name
              </MenuItem>
              <MenuItem
                  disabled={lastWorkspace}
                  onClick={(e) => OpenDeleteMenu(e)}
                  style={{color: '#f44336'}}
              >
                  <ListItemIcon >
                      <DeleteOutlineRounded
                          fontSize="small"
                          style={{color: '#f44336'}}
                      />
                  </ListItemIcon>
                  Delete Workspace
              </MenuItem>
          </DefaultMenu>
      </div>
  );
}
