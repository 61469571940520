/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from "react";

import { useTheme } from "@mui/material/styles";

//MUI COMPONENTS
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material";

import HotkeyChip from "../../../Components/Hotkey/HotkeyChip";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Skeleton from "@mui/material/Skeleton";

import useSessionHistory from "../../../Hooks/useSessionHistory";
import useGlobal from "../../../GlobalState/Store/Store";
import extractHostname from "../../../Utils/extractHostname";
import sessionHistoryTimeString from "../../../Utils/sessionHistoryTimeString";
import { useIntersectionObserver } from "../infiniteScroll/useIntersectionObserver";
import useRecentSessions from "../../../Hooks/useRecentSessions";
import axios from "axios";
import { useSnackbar } from "notistack";
import PlainSnackBar from "../../../Components/Notistack/PlainSnackBar";
import { Virtuoso } from "react-virtuoso";

const SessionHistoryListItem = ({ session, sessionHistory, selectedSessionHistory, handleSelectSessionHistory, getHistoryListRefsMap }) => {
  const theme = useTheme();
  // const { session, handleSelectSession, selectedSession } = props
  let isSelected = selectedSessionHistory && selectedSessionHistory._id === session._id ? true : false;

  //we don't rely on versions from the history items (restoring messes things up)
  //we convert the index to a version in the list
  // console.log(session.version);

  const URLsString = (tabs) => {
    let arr = tabs.map((t) => {
      return extractHostname(t.url);
    });

    return arr.join(", ");
  };

  //console.log(session)
  if (session && session._id) {
    return (
      <Box
        key={session._id}
        onClick={() => handleSelectSessionHistory(session._id)}
        ref={(node) => {
          const map = getHistoryListRefsMap();
          if (node) {
            map.set(session._id, node);
          } else {
            map.delete(session._id);
          }
        }}
        style={{
          borderRadius: 4,
          marginBottom: 8,
          marginLeft: 12,
          marginRight: 12,
          fontFamily: '"Work Sans", "Helvetica", "Arial", sans-serif',
          paddingTop: 12,
          paddingLeft: 12,
          paddingRight: 12,
          paddingBottom: 12,
          cursor: "pointer",
        }}
        sx={{
          backgroundColor: isSelected ? "action.selected" : "background.paper",
          "&:hover": {
            backgroundColor: isSelected ? "action.selected" : "action.hover",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 4,
          }}
        >
          <Typography
            noWrap
            style={{ fontWeight: 600, fontSize: 15 }} //color: isSelected ? "rgba(255, 255, 255, 1)" : theme.palette.text.primary,
            variant="body1"
          >
            {sessionHistoryTimeString(session.lastUpdated)}
          </Typography>
          <Typography
            noWrap
            style={{ fontSize: 13, fontWeight: 500, flexShrink: 0, marginLeft: 4 }} //color: isSelected ? "rgba(255, 255, 255, 0.75)" : theme.palette.text.secondary,
            variant="caption"
          >
            {session.tabs ? (session.tabs.length > 1 ? session.tabs.length + " tabs" : session.tabs.length + " tab") : null}
          </Typography>
        </div>
        <Typography
          noWrap
          style={{ fontSize: 13, fontWeight: 400, lineHeight: 1.15, margin: 0 }} //color: isSelected ? "rgba(255, 255, 255, 0.75)" : theme.palette.text.secondary,
          variant="body1"
        >
          {URLsString(session.tabs)}
        </Typography>
        {/* <Divider style={{marginTop: 8, borderColor: isSelected || lastSession ? "transparent" : theme.palette.collection.hover}} /> */}
      </Box>
    );
  } else return null;
};

function SessionHistoryList({
  session,
  sessionHistory,
  hasMoreHistory,
  loadMoreHistory,
  selectedSessionHistory,
  isLoadingHistory,
  isLoadingMoreHistory,
  open,
  toggleShowSessionHistory,
  handleSelectSessionHistory,
  scrollToSession,
  getHistoryListRefsMap,
  virtuosoRef,
}) {
  const [globalState, globalActions] = useGlobal();
  const theme = useTheme();
  const { mutate: mutateSessions } = useRecentSessions(globalState.isAuth); //{revalidateAll: true}
  const { mutate: mutateSessionHistory } = useSessionHistory(globalState.isAuth, session && session._sessionId ? session._sessionId : null);
  const { enqueueSnackbar } = useSnackbar();
  //INFINITE SCROLL
  const loadMoreSentinelRef = useRef(null);

  //trigger when the sentienl is in view
  const loadMoreTrigger = useIntersectionObserver(
    loadMoreSentinelRef,
    {
      threshold: 0,
    },
    false
  );
  //const loadMoreTrigger = useOnScreen(loadMoreSentinelRef)
  //console.log({loadMoreTrigger})
  useEffect(() => {
    let isMounted = true;
    //console.log({loadMoreTrigger, hasMoreHistory, isLoadingMoreHistory})
    if (loadMoreTrigger && hasMoreHistory && isMounted && !isLoadingMoreHistory) {
      //console.log("LOAD MORE")
      loadMoreHistory();
    } else {
      //console.log("no more loady")
    }

    return () => (isMounted = false);
  }, [loadMoreTrigger, sessionHistory]);

  //RESTORING SESSION VERSION
  const [restoreLoading, setRestoreLoading] = useState(false);
  const RestoreVersion = async (event) => {
    event.preventDefault();
    setRestoreLoading(true);
    let _sessionId = selectedSessionHistory._sessionId;
    let newTabs = selectedSessionHistory.tabs;

    //helper
    function findIndices(arr, sessionId) {
      if (!arr || !arr.length) return [-1, -1];
      const initialIndex = arr.findIndex((innerArr) => innerArr.sessions.findIndex((obj) => obj._sessionId === sessionId) !== -1);
      if (initialIndex === -1) return [-1, -1];
      const nestedIndex = arr[initialIndex].sessions.findIndex((obj) => obj._sessionId === sessionId);
      return [initialIndex, nestedIndex];
    }

    const data = {
      _sessionId: _sessionId,
      newTabs: newTabs,
    };

    // console.log(_sessionId)
    mutateSessions(
      async (sessionsData) => {
        // remove the collection
        // console.log({ sessionsData });
        if (sessionsData) {
          let [initialIndex, nestedIndex] = findIndices(sessionsData, _sessionId);
          if (nestedIndex > -1) {
            const updatedNestedArray = [
              ...sessionsData[initialIndex].sessions.slice(0, nestedIndex),
              { ...sessionsData[initialIndex].sessions[nestedIndex], tabs: newTabs },
              ...sessionsData[initialIndex].sessions.slice(nestedIndex + 1),
            ];
            const updatedArray = [
              ...sessionsData.slice(0, initialIndex),
              { ...sessionsData[initialIndex], sessions: updatedNestedArray },
              ...sessionsData.slice(initialIndex + 1),
            ];
            // console.log({ updatedNestedArray, updatedArray });

            return updatedArray;
          } else {
            // console.log("didn't find session on restore");
            return sessionsData;
          }
        } else {
          // console.log("no mutate on restore");
          return sessionsData;
        }
      },
      { revalidate: false }
    );

    axios.post("/sessions/restore", data).then((result) => {
      //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
      setRestoreLoading(false);
      mutateSessions();
      mutateSessionHistory();

      toggleShowSessionHistory();

      // console.log("time⏰");
      if (result && result.data) {
        let message = "⏱ Restored Session";
        enqueueSnackbar(message, {
          content: (key, message) => <PlainSnackBar id={key} message={message} />,
        });
      }
    });

    //wait a little while before calling scroll
    //scrollToSession(session._id) //this doesn't work, but since the session will be most recently updated, we can just scroll to the top of the list
    scrollToSession(session._id, true) //true just means scroll to the top
      .then(() => {})
      .catch((err) => {
        setRestoreLoading(false);
        //mutate((key) => typeof key === 'string' && key.startsWith('/sessions/recent'), globalState.isAuth)
        mutateSessions();
        mutateSessionHistory();
        console.log(err);
      });
  };

  const isCurrentVersion = selectedSessionHistory && sessionHistory && sessionHistory[0].version === selectedSessionHistory.version;

  const LoadingFooter = () => {
    return (
      <div
        style={{
          width: "100%",
          minWidth: "100%",
          minHeight: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "end",
          paddingBottom: 12,
        }}
      />
    );
  };

  return (
    <div
      style={{
        width: 400,
        flexShrink: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderLeft: `1px solid ${theme.palette.divider}`,
        // width: open ? 400 : 0,
        // transform: open ? "translateX(0)" : 'translateX(100%)',
        // visibility: open ? 'visible' : 'hidden',
        // transition: 'transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1),',
      }}
    >
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
          height: 50,
          minHeight: 50,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          backgroundColor: alpha(theme.palette.background.paper, 0.75),
          backdropFilter: "blur(8px)",
          boxShadow: theme.palette.shadows.small,
        }}
      >
        <Tooltip
          disableInteractive
          enterDelay={450}
          title={
            <div style={{ display: "flex", flexDirection: "row" }}>
              Close session history
              <HotkeyChip keys={["esc"]} />
            </div>
          }
        >
          <span style={{ position: "absolute", top: 8, left: 12 }}>
            <IconButton disableRipple onClick={toggleShowSessionHistory} variant="outlined">
              <KeyboardArrowRightRoundedIcon />
            </IconButton>
          </span>
        </Tooltip>
        {/* <Typography style={{margin: 0}}>
                    {sessionHistory.length}
                    {' '}
                    Versions
                </Typography> */}
        <Typography style={{ margin: 0 }}>Version History</Typography>
      </div>

      {isLoadingHistory ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
          }}
        >
          <Skeleton height={48} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
          <Skeleton height={48} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
          <Skeleton height={48} style={{ marginBottom: 8, backgroundColor: theme.palette.action.hover }} variant="rounded" width="100%" />
        </Box>
      ) : sessionHistory && sessionHistory.length ? (
        <div style={{ paddingTop: 12, flexGrow: 1 }}>
          {/* {sessionHistory.map((h, historyIndex) => (
                            <SessionHistoryListItem 
                                getHistoryListRefsMap={getHistoryListRefsMap}
                                handleSelectSessionHistory={handleSelectSessionHistory}
                                selectedSessionHistory={selectedSessionHistory}
                                session={h}
                                sessionHistory={sessionHistory}
                            />
                        ))} */}
          <Virtuoso
            components={{ Footer: LoadingFooter }}
            data={sessionHistory || []}
            itemContent={(index) => {
              let h = sessionHistory[index]; //{...sessionHistory[index], version: sessionHistory?.length - 1 - index}
              return (
                <SessionHistoryListItem
                  getHistoryListRefsMap={getHistoryListRefsMap}
                  handleSelectSessionHistory={handleSelectSessionHistory}
                  selectedSessionHistory={selectedSessionHistory}
                  session={h}
                  sessionHistory={sessionHistory}
                />
              );
            }}
            overscan={500}
            ref={virtuosoRef}
            style={{ height: "100%" }}
          />

          <div
            key="sessions-loading-sentinel"
            ref={loadMoreSentinelRef}
            style={{
              backgroundColor: "#7BFD15",
              height: 100,
              minHeight: 100,
              border: "1px solid #000",
              color: "#000",
              width: "100%",
              padding: 24,
              //marginTop: -300, //this will make the intersection happen BEFORE the end of the list, preempictively loading data
              display: isLoadingMoreHistory ? "none" : "block",
              visibility: "hidden",
            }}
          />
        </div>
      ) : (
        "No history"
      )}
      <div
        style={{
          paddingLeft: 16,
          zIndex: 1300, //places it over the FAB but tooltips are still higher
          paddingRight: 16,
          paddingTop: 16,
          paddingBottom: 16,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "fixed",
          width: 400,
          bottom: 0,
          backgroundColor: alpha(theme.palette.background.paper, 0.75),
          backdropFilter: "blur(8px)",
          boxShadow: theme.palette.shadows.small,
        }}
      >
        <Tooltip disableInteractive placement="top" title={isCurrentVersion ? "Cannot restore the current version" : "Restore your session to this version"}>
          <div style={{ width: "100%", marginRight: 16 }}>
            <Button disabled={isCurrentVersion || restoreLoading} fullWidth loadingPosition="start" onClick={RestoreVersion} variant="contained">
              Restore tabs
            </Button>
          </div>
        </Tooltip>
        <Button fullWidth onClick={toggleShowSessionHistory} variant="outlined">
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default SessionHistoryList;
