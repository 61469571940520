import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import {
  Typography,
  MenuItem,
  ListItem,
  Popover,
  InputBase,
  Divider,
  ListSubheader,
} from "@mui/material";

import { DefaultMenu } from "../../Menus/DefaultMenu.js";

import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

import { colors as labelColors } from "../../../Utils/StandardColors.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

function EditValueMenu(props) {
  const inKanban = props.inKanban;
  const valueToEdit = props.valueToEdit;
  const anchor = props.anchor;
  const open = Boolean(props.anchor);
  const handleClose = props.handleClose;
  const ToggleHideColumn = props.ToggleHideColumn;

  const saveUpdatedValue = props.saveUpdatedValue;

  let hidden =
    props.hiddenColumnIds &&
    props.hiddenColumnIds.length > 0 &&
    props.hiddenColumnIds.indexOf(valueToEdit._id) > -1
      ? true
      : false;

  //console.log(valueToEdit)

  //VALUE NAME
  const selectInputText = (event) => {
    if (valueToEdit && event.target.value == valueToEdit.name)
      event.target.select();
  };
  const [valName, setValName] = useState(valueToEdit ? valueToEdit.name : "");
  const handleChangeName = (event) => {
    //console.log(event)
    setValName(event.target.value);
    if (inputEl && inputEl.current) inputEl.current.focus();

    let updatedValue = {
      propertyId: valueToEdit.propertyId,
      _id: valueToEdit._id,
      name: event.target.value,
      color: valColor,
    };
    props.updateValue(event, updatedValue);
    // let valueIndex = selected.findIndex(v => v._id == valueToEdit._id)
    // console.log(valueIndex)
    // if(valueIndex > -1){
    //   let updated = selected
    //   updated[valueIndex].name = valName
    //   setSelected(updated)
    // }
  };

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  const [valColor, setValColor] = useState(
    valueToEdit ? valueToEdit.color : "default"
  );
  const handleChangeValueColor = (color) => {
    //console.log({color})
    setValColor(color.name);
    if (inputEl && inputEl.current) inputEl.current.focus();

    let updatedValue = {
      propertyId: valueToEdit.propertyId,
      _id: valueToEdit._id,
      name: valName,
      color: color.name,
    };

    props.updateValue(null, updatedValue);
  };

  useEffect(() => {
    if (valueToEdit) {
      if (valueToEdit.name) {
        setValName(valueToEdit.name);
      }
      if (valueToEdit.color) {
        setValColor(valueToEdit.color);
      }
    }
  }, []);

  let ableToUpdate =
    (valueToEdit && valueToEdit.name != valName) ||
    (valueToEdit && valueToEdit.color != valColor)
      ? true
      : false;

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;
    //console.log("key " + charCode + " pressed")
    if (charCode == 13 && ableToUpdate) {
      //console.log("Enter key pressed")
      //if enter is pressed login
      let updatedValue = {
        propertyId: valueToEdit.propertyId,
        _id: valueToEdit._id,
        name: valName,
        color: valColor,
      };
      handleCloseEditValueMenu(ableToUpdate, updatedValue);
    }
  };

  const handleCloseEditValueMenu = () => {
    let updatedValue = {
      propertyId: valueToEdit.propertyId,
      _id: valueToEdit._id,
      name: valName,
      color: valColor,
    };
    handleClose(ableToUpdate, updatedValue);
  };

  const handleKeyPress = (e) => {
    var charCode = e.which || e.charCode || e.keyCode;
    if (charCode == 13) {
      handleCloseEditValueMenu();
    }
  };

  const inputEl = React.useRef(null);

  return (
    <DefaultMenu
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: inKanban ? "left" : "right",
      }}
      id="mouse-over-popover"
      onClose={handleCloseEditValueMenu}
      onKeyDown={handleKeyPress}
      open={open}
      // disableRestoreFocus
      transformOrigin={{
        vertical: "top",
        horizontal: inKanban ? "left" : "right",
      }}
    >
      <div key={0} style={{ padding: 8 }}>
        <BootstrapInput
          // ref={inputEl}
          // defaultValue={valName}
          autoFocus
          disabled={valueToEdit && valueToEdit.fixed}
          fullWidth
          id="kanban-column-name"
          inputProps={{
            autoComplete: "new-password",
          }}
          label="property name"
          onChange={handleChangeName}
          onFocus={selectInputText}
          placeholder="Name property"
          size="small"
          value={valName}
          variant="outlined"
        />
      </div>
      <Divider style={{ marginBottom: 8 }} />
      {inKanban ? (
        <MenuItem button onClick={() => ToggleHideColumn(valueToEdit, hidden)}>
          {hidden ? (
            <VisibilityRoundedIcon fontSize="small" style={{ width: 18 }} />
          ) : (
            <VisibilityOffRoundedIcon fontSize="small" style={{ width: 18 }} />
          )}
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              {hidden ? "Show column" : "Hide column"}{" "}
            </Typography>
          </div>
        </MenuItem>
      ) : null}

      {valueToEdit._id !== "none" ? (
        <MenuItem
          button
          key={1}
          onClick={(e) =>
            inKanban
              ? props.deleteColumn(e, props.valueToEdit)
              : props.deleteOption(e, valueToEdit)
          }
        >
          <DeleteOutlineRounded
            fontSize="small"
            style={{ width: 18, color: "#f44336" }}
          />
          <div style={{ marginLeft: 8 }}>
            <Typography
              style={{ marginBottom: 0, color: "#f44336" }}
              variant="body2"
            >
              {" "}
              Delete{" "}
            </Typography>
          </div>
        </MenuItem>
      ) : null}
      <Divider />
      {valueToEdit._id !== "none" ? (
        <>
          <ListSubheader style={{ backgroundColor: "transparent" }}>
            <Typography
              style={{
                color: "#B1B1B1",
                marginTop: 8,
                marginBottom: 6,
                fontWeight: 600,
                fontSize: 12,
              }}
              variant="body2"
            >
              COLOR
            </Typography>
          </ListSubheader>
          {labelColors.map((color) => {
            //border: isSelected ? '2px solid' : null
            let isSelected =
              valueToEdit && color.name == valColor ? true : false;
            return (
              <MenuItem
                button
                key={color.name}
                onClick={() => handleChangeValueColor(color)}
                selected={isSelected}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: color.hex,
                    borderRadius: "100%",
                  }}
                />
                <div style={{ marginLeft: 8 }}>
                  <Typography variant="body2"> {color.name} </Typography>
                </div>
              </MenuItem>
            );
          })}
        </>
      ) : !hidden ? (
        <Typography
          color="textSecondary"
          style={{ padding: 8, maxWidth: 200 }}
          variant="body2"
        >
          Any items without a{" "}
          <strong style={{ color: "#181818" }}>{props.groupBy.name}</strong>{" "}
          property will go here.
        </Typography>
      ) : null}
    </DefaultMenu>
  );
}

export default React.memo(EditValueMenu);

// {
//    inKanban ?
//    null
//    :
//    <ListItem onKeyPress={hanldeKeyPress} key={2}>
//      <Button
//        //onClick={() => updateValue({ _id: valueToEdit._id, name: valName, color: valColor})}
//        color="primary"
//        disabled={!ableToUpdate}
//        fullWidth
//        variant="contained"
//        size="small"
//      >
//        Update
//      </Button>
//    </ListItem>
// }
