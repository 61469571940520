import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import { Divider, Typography, MenuItem, InputBase } from "@mui/material";

import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";

import { DefaultPopover } from "../../Menus/DefaultPopover.js";
import RegularInput from "../../Inputs/RegularInput.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.light}`,
    fontSize: 14,
    lineHeight: 1.45,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 6,
    paddingRight: 6,
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.text.tertiary}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.collection.bgLight,
    },
  },
}));

function EditValuePopover(props) {
  const valueToEdit = props.valueToEdit;
  const anchorEl = props.anchorEl;
  const labelColors = props.labelColors;
  const handleChangeName = props.handleChangeName;
  const handleChangeColor = props.handleChangeColor;
  const deleteOption = props.deleteOption;

  const originalValue = { ...valueToEdit };
  const inputRef = React.useRef();

  const editValueInternalOpen = Boolean(anchorEl);
  //console.log("mounted")

  //VALUE NAME
  const selectInputText = (event) => {
    if (valueToEdit && event.target.value == valueToEdit.name) event.target.select();
  };
  const [editName, setEditName] = useState(false);
  const [valName, setValName] = useState(valueToEdit ? valueToEdit.name : "");
  const handleChangeValueName = (event) => {
    //console.log(event)
    setValName(event.target.value);
    //handleChangeName(event.target.value)
  };

  //VALUE COLOR
  const [valColor, setValColor] = useState(valueToEdit ? valueToEdit.color : "default");
  const handleChangeValueColor = (color) => {
    setValColor(color.name);
    handleChangeColor(color);
    if (inputRef && inputRef.current) inputRef.current.focus();
  };

  let ableToUpdate =
    (valName && originalValue && originalValue.name != valName) || (valColor && originalValue && originalValue.color != valColor) ? true : false;

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;

    if (charCode == 13 && ableToUpdate) {
      //if enter is pressed login
      let updatedValue = {
        _id: valueToEdit._id,
        name: valName,
        color: valColor,
      };
      handleCloseEditValueMenu(ableToUpdate, updatedValue);
    } else if (charCode == 27) {
      handleCloseEditValueMenu(event, "escapeKeyDown");
    }
  };

  const handleCloseEditValueMenu = (event, reason) => {
    // console.log({ event, reason, ableToUpdate, valName, valueToEdit });
    if (reason && reason === "escapeKeyDown") props.handleClose(false, null);

    let updatedValue = {
      _id: valueToEdit._id,
      name: valName ?? valueToEdit.name,
      color: valColor,
    };
    if (ableToUpdate && valName) handleChangeName(valName);

    props.handleClose(ableToUpdate, updatedValue);
  };

  const handleUpdateName = (event, objectId, typedName) => {
    handleChangeName(typedName);
    handleCloseEditValueMenu(event, "updated name");
  };

  return (
    <div onKeyDown={hanldeKeyPress}>
      <DefaultPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="mouse-over-popover"
        onClose={handleCloseEditValueMenu}
        open={editValueInternalOpen}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div onKeyDown={(e) => e.stopPropagation()} style={{ padding: 8 }}>
          <RegularInput
            // autoFocus
            edit={editName}
            inputRef={inputRef}
            newName={valName}
            objectId={valueToEdit._id}
            originalName={valueToEdit.name}
            renameFunction={handleUpdateName}
            // selectRangeOnFocus
            setEdit={setEditName}
            setNewName={setValName}
            style={{ fontSize: 14 }}
          />
        </div>
        <Divider style={{ marginBottom: 8 }} />

        <MenuItem button onClick={(e) => deleteOption(e, valueToEdit)}>
          <DeleteOutlineRounded fontSize="small" style={{ width: 18, color: "#f44336" }} />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0, color: "#f44336" }} variant="body2">
              {" "}
              Delete{" "}
            </Typography>
          </div>
        </MenuItem>
        <Divider />
        <Typography
          sx={{
            color: "text.tertiary",
            marginTop: 1,
            marginBottom: 0.5,
            fontWeight: 600,
            fontSize: 12,
            paddingLeft: 1.5,
            paddingRight: 1.5,
          }}
          variant="body2"
        >
          COLOR
        </Typography>
        <div style={{ paddingBottom: 8 }}>
          {labelColors.map((color) => {
            //border: isSelected ? '2px solid' : null
            let isSelected = valueToEdit && color.name == valColor ? true : false;
            return (
              <MenuItem button key={color.hex} onClick={() => handleChangeValueColor(color)} selected={isSelected}>
                <div
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: color.hex,
                    borderRadius: "100%",
                  }}
                />
                <div style={{ marginLeft: 8 }}>
                  <Typography variant="body2"> {color.name} </Typography>
                </div>
              </MenuItem>
            );
          })}
        </div>
      </DefaultPopover>
    </div>
  );
}

export default React.memo(EditValuePopover);
