import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import twilio from "../../Assets/Companies/twilio.svg";
import shopify from "../../Assets/Companies/shopify.svg";
import google from "../../Assets/Companies/google.svg";
import gitlab from "../../Assets/Companies/gitlab.svg";
import intuit from "../../Assets/Companies/intuit.svg";
import meta from "../../Assets/Companies/meta.svg";
import okta from "../../Assets/Companies/okta.svg";
import rippling from "../../Assets/Companies/rippling.svg";
import netflix from "../../Assets/Companies/netflix.svg";
import linkedin from "../../Assets/Companies/linkedin.svg";
import spotify from "../../Assets/Companies/spotify.svg";
import dropbox from "../../Assets/Companies/dropbox.svg";

const UsedBy = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        marginTop: 48,
        width: "100%",
        borderTop: "1px solid #E0E0E0",
        borderBottom: "1px solid #E0E0E0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 48,
        paddingBottom: 48,
      }}
    >
      <div style={{ width: "100%", maxWidth: 800, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography style={{ fontSize: 16, color: theme.palette.text.secondary }} variant="body2">
          Used by professionals on these teams
        </Typography>
        <div
          style={{
            // display: "grid",
            // alignItems: "center",
            // justifyContent: "space-evenly",
            // justifyItems: "center",
            // alignContent: "space-evenly",
            // gridTemplateColumns: "repeat(5, 1fr)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: 32,
            gap: 12,
            width: "100%",
            filter: "grayscale(100%) brightness(1) sepia(100%) hue-rotate(180deg) saturate(0)",
          }}
        >
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Google" src={google} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="LinkeIn" src={linkedin} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Shopify" src={shopify} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Twilio" src={twilio} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Netflix" src={netflix} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Meta" src={meta} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Okta" src={okta} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "1 0 20%",
            }}
          >
            <img alt="Intuit" src={intuit} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "0 0 20%",
            }}
          >
            <img alt="GitLab" src={gitlab} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "0 0 20%",
            }}
          >
            <img alt="Rippling" src={rippling} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "0 0 20%",
            }}
          >
            <img alt="Dropbox" src={dropbox} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
          <div
            style={{
              paddingBottom: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //   width: "15%",
              flex: "0 0 20%",
            }}
          >
            <img alt="Spotify" src={spotify} style={{ maxWidth: 125, maxHeight: 100, fill: "#C4C4C4" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsedBy;
