import { useState } from "react";
import useGlobal from "../GlobalState/Store/Store.js";
import axios from "axios";

const useCustomerPortal = () => {
    const [globalState, globalActions] = useGlobal();
    const stripeCustomerId = globalState?.user?.stripeSubscription?.stripeCustomerId ?? undefined; //"cus_PNhTS2O403vFvi"
    const stripeActiveSubscription = globalState?.user?.stripeSubscription && globalState?.user?.stripeSubscription?.status.toLowerCase() === "active";

    const cancelPending = globalState?.user?.stripeSubscription && !!globalState?.user?.stripeSubscription?.cancelAt && globalState?.user?.stripeSubscription?.status.toLowerCase() !== "canceled";
    const cancelsAt = new Date(globalState?.user?.stripeSubscription?.cancelAt).toDateString()


    const [customerPortalStatus, setCustomerPortalStatus] = useState("none");
    const GoToCustomerPortal = async (flowType) => {

      setCustomerPortalStatus("loading");
        if(!!stripeCustomerId){
            try {
              const res = await axios.post("/stripe/customer-portal", {
                stripeCustomerId: stripeCustomerId,
                // flowType: !!flowType ? flowType : null
                //just going to send them to the portal where they can update info
              });
              // console.log({ res });
              if (res.status === 200) {
                setCustomerPortalStatus("none");
                // console.log("Request sent successfully");
        
                // Fetch the session URL from the response
                const url = res.data.url;
                // console.log({ url });
        
                // Redirect the user to the Stripe Checkout page
                window.location.href = url;
              } else {
                setCustomerPortalStatus("error");
                console.log("Request failed");
              }
            } catch (error) {
              setCustomerPortalStatus("error");
              console.log("Error occurred during the request", error);
            }
        }
    };

    return {stripeCustomerId, stripeActiveSubscription, cancelPending,
      cancelsAt, customerPortalStatus, GoToCustomerPortal}
}

export default useCustomerPortal