/* eslint-disable no-inner-declarations */
/* eslint-disable no-self-assign */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import useGlobal from '../../GlobalState/Store/Store.js';
import axios from 'axios'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Button,
  IconButton,
  Typography,
  Box,
  ListItem,
  MenuItem,
  ListItemIcon,
  Divider,
  Tooltip,
  Grid,
  CircularProgress,

} from '@mui/material';

import { DefaultMenu } from '../Menus/DefaultMenu.js';

import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import ViewDayRoundedIcon from '@mui/icons-material/ViewDayRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Add from '@mui/icons-material/Add';

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import CloseRounded from '@mui/icons-material/CloseRounded';

import CustomSelect from '../Selects/CustomSelect.js';
import CustomFieldSelect from '../Selects/CustomFieldSelect.js';
import CustomDateSelect from '../Selects/CustomDateSelect.js';
import CustomTextField from '../Selects/CustomTextField.js';

import { mutate } from 'swr'


import { styled } from '@mui/material/styles';
const PlainButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.palette.button.plain,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 0,

    '&:hover': {
      // color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.active,
      // color: theme.palette.text.primary,
    },
    
}));

export default function FilterButton(props){
  const [globalState, globalActions] = useGlobal()

  const GetWorkspacebyID = props.GetWorkspacebyID

  //MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  //VIEWS
  const workspace = props.workspace
  const views = workspace && workspace.views ? workspace.views : null
  const activeView = globalState.activeView //workspace && workspace.views ? workspace.activeView ? workspace.views.find(v => v._id === workspace.activeView) :  workspace.views[0] : null

  const currentView = props.currentView

  const filterActive = activeView && activeView.filter && activeView.filter.filters && activeView.filter.filters.length > 0 ? true : false
  const ButtonText =  filterActive ? activeView.filter.filters.length > 1 ? activeView.filter.filters.length + " Filters" : activeView.filter.filters.length + " Filter"  : "Filter"

  //HELP
  const helpIcon = null

  //FILTER SELECT OPTIONS
  const filterConditions = [
    {
      name: "and",
      value: "and"
    },
    {
      name: "or",
      value: "or"
    },
  ]
  const [filterOnFields, setFilterOnFields] =   useState([
      // {
      //   name: "Name",
      //   value: "name"
      // },
      {
        name: "Created",
        value: "created"
      },
      {
        name: "Last updated",
        value:"updated"
      },
      {
        name: "# of tabs",
        value:"tabsCount"
      },
    ])

  const filterStringOperations = [
    {
      name: "Is",
      value: "equals"
    },
    {
      name: "Is not",
      value: "doesNotEqual"
    },
    {
      name: "Contains",
      value: "contains"
    },
    {
      name: "Does not contain",
      value: "doesNotContain"
    },
  ]
  const filterDateOperations = [
    {
      name: "Equals",
      value: "equals"
    },
    {
      name: "Is before",
      value: "before"
    },
    {
      name: "Is on or before",
      value: "onOrBefore"
    },
    {
      name: "Is after",
      value: "after"
    },
    {
      name: "Is on or after",
      value: "onOrAfter"
    },
  ]
  const filterNumberOperations = [
    {
      name: "Equals",
      value: "equals"
    },
    {
      name: "Is greater than",
      value: "greater"
    },
    {
      name: "Is greater or equal to",
      value: "greaterOrEqual"
    },
    {
      name: "Is less than",
      value: "less"
    },
    {
      name: "Is less or equal to",
      value: "lessOrEqual"
    },
  ]
  const filterSingleSelectOperations = [
    {
      name: "Is",
      value: "is"
    },
    {
      name: "Is not",
      value: "isNot"
    },
    {
      name: "Is empty",
      value: "empty"
    },
    {
      name: "Is not empty",
      value: "notEmpty"
    },
  ]
  const filterMutliSelectOperations = [
    {
      name: "Contains",
      value: "contains"
    },
    {
      name: "Does not contain",
      value: "doesNotContain"
    },
    {
      name: "Is empty",
      value: "empty"
    },
    {
      name: "Is not empty",
      value: "notEmpty"
    },
  ]
  const filterDateValues = [
    {
      name: "Today",
      value: "today"
    },
    {
      name: "Yesterday",
      value: "yesterday"
    },
    {
      name: "One week ago",
      value: "weekAgo"
    },
    {
      name: "One month Ago",
      value: "monthAgo"
    },
    {
      name: "Exact date",
      value: "exactDate"
    },
  ]

  //Handling filters
  const [filterReq, setFilterReq] = useState({
    isLoading: false,
    isError: false,
    error: null
  })

  useEffect(() => {
    //add any custom fields to the filter options

    if(globalState.activeWorkspace && globalState.activeWorkspace.collectionProperties && globalState.activeWorkspace.collectionProperties.length > 0){
      //the view doesn't have any values set yet
      //console.log(globalState.activeWorkspace.collectionProperties)
      let start = [
        {
        name: "Created",
        value: "created"
        },
        {
          name: "Last updated",
          value:"updated"
        },
        {
          name: "# of tabs",
          value:"tabsCount"
        },
      ]
      globalState.activeWorkspace.collectionProperties.forEach((property, index) => {
        start.push(
          {
            name: property.name,
            type: property.type,
            value: property._id,
            possibleValues: property.values
          }
        )

        if(index == globalState.activeWorkspace.collectionProperties.length -1){
          //console.log({start})
          setFilterOnFields(start)
          //setFilterOnFields(blank)
        }
      })
      //console.log({filterOnFields})
    } else {
      //console.log("no properties in workspace", globalState.activeWorkspace, workspace)
    }
  }, [globalState.activeView])

  const [filterValues, setFilterValues] = useState([])
  useEffect(() => {
    if(globalState.activeView && globalState.activeView.filter && globalState.activeView.filter.filters){
      var filters = []
      if(filterActive){
        activeView.filter.filters.forEach(f => {
          filters.push(f)
        })
      }
      setFilterValues(filters)
    }
  }, [globalState.activeWorkspace])

  function AddFilter(){
    const data = {
      viewId: activeView._id,
      action: 'add',
      filter: {
        condition: 'and',
        field: {
          type: 'number',
          value: 'tabsCount'
        },
        operation: 'equals',
        value: {
          type: 'number',
          value: ''
        }
      }
    }
    // console.log({data})
    axios
      .post("/views/filter", data)
      .then(res => {
        //console.log(res)
        globalActions.setActiveView(res.data.view)
        setFilterReq({
          isLoading: false,
          isError: false,
          error: null
        })
        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
        GetWorkspacebyID(workspace, false)

        // setViewChangeReq({
        //   isLoading: false,
        //   isError: false
        // })
      })
      .catch(err => {
        //console.log("Could not update workspace name")
        setFilterReq({
          isLoading: false,
          isError: true,
          error: err
        })
        console.log(err)
        // setNewNameReq({
        //   loading: null,
        //   isError: index
        // })
        // setEditWorkspaceName(false);
        // setNewWorkspaceName("")
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: true
        // })
      });
  }
  function RemoveFilter(filterId){
    var arr = []
    arr.push(filterId)
    const data = {
      viewId: activeView._id,
      action: 'remove',
      removeFilters: arr
    }
    axios
      .post("/views/filter", data)
      .then(res => {
        //console.log(res)
        globalActions.setActiveView(res.data.view)

        mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: false
        // })
      })
      .catch(err => {
        //console.log("Could not update workspace name")
        console.log(err)
        // setNewNameReq({
        //   loading: null,
        //   isError: index
        // })
        // setEditWorkspaceName(false);
        // setNewWorkspaceName("")
        // setViewChangeReq({
        //   isLoading: false,
        //   isError: true
        // })
      });
  }

  function handleChangeFilters(event, filter, key, backupValue, error) {
    // setFilterReq({
    //   isLoading: true,
    //   isError: false,
    //   error: null
    // })
    //console.log("CHANGE FILYER called " + error)
    //console.log(backupValue)
    let eventValue = event ? event.target.value.value : backupValue
    // console.log(filter)
    // console.log(eventValue)
    // console.log("key: " + key)
    // console.log(filterDateValues.findIndex(v => v.value === eventValue))

   let values = [...filterValues];
   let index = values.findIndex(f => f._id === filter._id)
   //console.log(index)
   // values[index][key] = {
   //   type: filter.field.type,
   //   value: eventValue
   // }
   //console.log(filterOnFields.find(property => property.value === filter.field.value).possibleValues, filterOnFields)
   //console.log({filter, key, eventValue, backupValue})
   if(index !== -1){
     if(filter.hasOwnProperty(key)){
       if(key === "condition"){
          values[index][key] = eventValue

          filter[key] = eventValue
       }
       else if (key === "field"){
         if(eventValue === "name"){
           values[index][key] = {
             type: 'string',
             value: eventValue
           }
           values[index]["operation"] = filterStringOperations[0].value
           values[index]["value"] = {
             type: "string",
             value: ""
           }

           filter[key] = {
             type: 'string',
             value: eventValue
           }
           filter["operation"] = filterStringOperations[0].value
           filter["value"] = {
             type: "string",
             value: ""
           }
         }
         else if (eventValue === "created" || eventValue === "updated") {
           values[index][key] = {
             type: 'date',
             value: eventValue
           }

           filter[key] = {
             type: 'date',
             value: eventValue
           }

           if(filter.value.type === "dateString"){
             values[index]["value"] = {
               type: "dateString",
               value: filter.value.value
             }
             values[index]["operation"] = filter.operation

             filter["value"] = {
               type: "dateString",
               value: filter.value.value
             }
             filter["operation"] = filter.operation
           } else {
             values[index]["value"] = {
               type: "date",
               dateValue: filter.value.dateValue ? filter.value.dateValue : new Date()
             }
             values[index]["operation"] = filter.operation //filterDateOperations[0].value

             filter["value"] = {
               type: "date",
               dateValue: filter.value.dateValue ? filter.value.dateValue : new Date()
             }
             filter["operation"] = filter.operation //filterDateOperations[0].value
           }

         }
         else if(backupValue && backupValue.type == "singleSelect" || backupValue && backupValue.type == "multiSelect"){
           //console.log("Changing to single or muti select")
           values[index]["operation"] = backupValue.type == "singleSelect" ? filterSingleSelectOperations[0].value : filterMutliSelectOperations[0].value
           values[index]["value"] = {
             type: "string",
             value: ""
           }
           values[index][key] = {
             type: backupValue.type,
             value: eventValue
           }

           filter["operation"] = backupValue.type == "singleSelect" ? filterSingleSelectOperations[0].value : filterMutliSelectOperations[0].value
           filter["value"] = {
             type: "string",
             value: ""
           }
           filter[key] = {
             type: backupValue.type,
             value: eventValue
           }
         }
         else {
           values[index][key] = {
             type: 'number',
             value: eventValue
           }
           values[index]["operation"] = filterNumberOperations[0].value
           values[index]["value"] = {
             type: "number",
             value: ""
           }

           filter[key] = {
             type: 'number',
             value: eventValue
           }
           filter["operation"] = filterNumberOperations[0].value
           filter["value"] = {
             type: "number",
             value: ""
           }
         }
       }
       else if (key === "operation"){
         values[index][key] = eventValue

         filter[key] = eventValue
       }
       else if (key === "value"){
         //console.log(eventValue)
         if(filter.field.type === 'date' && eventValue === 'exactDate' || filter.field.type === 'date' && filterDateValues.findIndex(v => v.value === eventValue) === -1){
           //console.log("is exact date")
           values[index][key] = {
             type: filter.field.type,
             dateValue: new Date(backupValue)
           }

           filter[key] = {
             type: filter.field.type,
             dateValue: new Date(backupValue)
           }
         } else if(filterDateValues.findIndex(v => v.value === eventValue) !== -1) {
           //console.log(eventValue + ' is allowed')
           values[index][key] = {
             type: "dateString",
             value: eventValue
           }

           filter[key] = {
             type: "dateString",
             value: eventValue
           }
         } else if(backupValue && backupValue.type == "singleSelect" || backupValue && backupValue.type == "multiSelect"){
           values[index][key] = {
             type: backupValue.type,
             value: eventValue
           }

           filter[key] = {
             type: backupValue.type,
             value: eventValue
           }
         } else {
           values[index][key] = {
             type: "array",
             value: [eventValue]
           }

           filter[key] = {
             type: "array",
             value: [eventValue]
           }
         }

       }
       setFilterValues(values)

       const data = {
         viewId: activeView._id,
         action: "update",
         filter: filter
       }
       axios
         .post("/views/filter", data)
         .then(res => {
           //console.log(res)
           if(res && res.data && res.data.view){
             globalActions.setActiveView(res.data.view)
             // if(values[index].value.value !== null && values[index].value.value !== undefined && values[index].value.value !== "" && values[index].value.value !== " " || values[index].value.dateValue !== null && values[index].value.dateValue !== undefined && values[index].value.dateValue !== "" && values[index].value.dateValue !== " "){
             //
             // }
             mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
           }
           setFilterReq({
             isLoading: false,
             isError: false,
             error: null
           })

         })
         .catch(err => {
           //console.log("Could not update workspace name")
           setFilterReq({
             isLoading: false,
             isError: true,
             error: err
           })
           console.log(err)
         });

      }
   } else {
     setFilterReq({
       isLoading: false,
       isError: false,
       error: null
     })
   }
  }

  function handleChangeCustomFieldFilters(event, filter, key, backupValue, isTag) {
    // setFilterReq({
    //   isLoading: true,
    //   isError: false,
    //   error: null
    // })

    //console.log(backupValue)
    let eventValue = event ? event.target.value.value : backupValue
    // console.log(filter)
    // console.log(eventValue)
    // console.log("key: " + key)
    // console.log(filterDateValues.findIndex(v => v.value === eventValue))

   let values = [...filterValues];
   let index = values.findIndex(f => f._id === filter._id)
   //console.log(index)
   values[index][key] = {
     type: filter.field.type,
     value: eventValue
   }
   //console.log(filterOnFields.find(property => property.value === filter.field.value).possibleValues, filterOnFields)
   //console.log({filter, key, eventValue, backupValue, isTag})
   if(index !== -1){
     if(filter.hasOwnProperty(key)){
       if(key === "condition"){
          values[index][key] = eventValue

          filter[key] = eventValue
       }
       else if (key === "field"){
         if(eventValue === "name"){

           values[index][key] = {
             type: 'string',
             value: eventValue
           }
           values[index]["operation"] = filterStringOperations[0].value
           values[index]["value"] = {
             type: "string",
             value: ""
           }

           filter[key] = {
             type: 'string',
             value: eventValue
           }
           filter["operation"] = filterStringOperations[0].value
           filter["value"] = {
             type: "string",
             value: ""
           }
         }
         else if (eventValue === "created" || eventValue === "updated") {

           values[index][key] = {
             type: 'date',
             value: eventValue
           }
           values[index]["operation"] = filterDateOperations[0].value //filter.operation

           filter[key] = {
             type: 'date',
             value: eventValue
           }
           filter["operation"] = filterDateOperations[0].value //filter.operation

           if(filter.value.type === "dateString"){
             values[index]["value"] = {
               type: "dateString",
               value: filter.value.value
             }
             values[index]["operation"] = filter.operation

             filter["value"] = {
               type: "dateString",
               value: filter.value.value
             }
             filter["operation"] = filter.operation
           } else {
             values[index]["value"] = {
               type: "date",
               dateValue: filter.value.dateValue ? filter.value.dateValue : new Date()
             }
             values[index]["operation"] = filter.operation //filterDateOperations[0].value

             filter["value"] = {
               type: "date",
               dateValue: filter.value.dateValue ? filter.value.dateValue : new Date()
             }
             filter["operation"] = filter.operation //filterDateOperations[0].value
           }

         }
         else if(backupValue && backupValue.type == "singleSelect" || backupValue && backupValue.type == "multiSelect"){

           values[index]["operation"] = backupValue.type == "singleSelect" ? filterSingleSelectOperations[0].value : filterMutliSelectOperations[0].value
           values[index]["value"] = {
             type: "string",
             value: ""
           }
           values[index][key] = {
             type: backupValue.type,
             value: eventValue
           }

           filter["operation"] = backupValue.type == "singleSelect" ? filterSingleSelectOperations[0].value : filterMutliSelectOperations[0].value
           filter["value"] = {
             type: "string",
             value: ""
           }
           filter[key] = {
             type: backupValue.type,
             value: eventValue
           }
         }
         else {

           values[index][key] = {
             type: 'number',
             value: eventValue
           }
           values[index]["operation"] = filterNumberOperations[0].value
           values[index]["value"] = {
             type: "number",
             value: ""
           }

           filter[key] = {
             type: 'number',
             value: eventValue
           }
           filter["operation"] = filterNumberOperations[0].value
           filter["value"] = {
             type: "number",
             value: ""
           }
         }
       }
       else if (key === "operation"){
         values[index][key] = eventValue

         filter[key] = eventValue
       }
       else if (key === "value"){
         if(isTag){

           //means its a single or multi select
           values[index][key] = {
             type: "string",
             value: backupValue._id
           }

           filter[key] = {
             type: "string",
             value: backupValue._id
           }
         }
         else {
           if(filter.field.type === 'date' && eventValue === 'exactDate' || filter.field.type === 'date' && filterDateValues.findIndex(v => v.value === eventValue) === -1){
             //console.log("is exact date")
             values[index][key] = {
               type: filter.field.type,
               dateValue: new Date(backupValue)
             }

             filter[key] = {
               type: filter.field.type,
               dateValue: new Date(backupValue)
             }
           }
           else if(filterDateValues.findIndex(v => v.value === eventValue) !== -1){
             //console.log(eventValue + ' is allowed')
             values[index][key] = {
               type: "dateString",
               value: eventValue
             }

             filter[key] = {
               type: "dateString",
               value: eventValue
             }
           }
           else if(backupValue && backupValue.type == "singleSelect" || backupValue && backupValue.type == "multiSelect"){
             values[index][key] = {
               type: "string",
               value: eventValue
             }

             filter[key] = {
               type: "string",
               value: eventValue
             }
           }
           else {
             values[index][key] = {
               type: "string",
               value: eventValue
             }

             filter[key] = {
               type: "string",
               value: eventValue
             }
           }
         }
       }
      }
   setFilterValues(values)

   const data = {
     viewId: activeView._id,
     action: "update",
     filter: filter
   }

   axios
     .post("/views/filter", data)
     .then(res => {

       if(res && res.data && res.data.view){
         globalActions.setActiveView(res.data.view)
         // if(values[index].value.value !== null && values[index].value.value !== undefined && values[index].value.value !== "" && values[index].value.value !== " " || values[index].value.dateValue !== null && values[index].value.dateValue !== undefined && values[index].value.dateValue !== "" && values[index].value.dateValue !== " "){
         //
         // }
         mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
       }
       setFilterReq({
         isLoading: false,
         isError: false,
         error: null
       })

     })
     .catch(err => {
       //console.log("Could not update workspace name")
       setFilterReq({
         isLoading: false,
         isError: true,
         error: err
       })
       console.log(err)
     });

    }
    else {
     setFilterReq({
       isLoading: false,
       isError: false,
       error: null
     })
   }
  }

  //Only for custom text field
  //const [checkCancelToken, setCancelToken] = useState(undefined)
  let checkCancelToken
  //let typeTimer
  const [typeTimer, setTypeTimer] = useState(null)
  function handleInputChange(event, filter, key, backupValue, error) {
    setFilterReq({
      isLoading: true,
      isError: false,
      error: null
    })

    let eventValue = event ? event.target.value : backupValue
    // console.log(filter)
    // console.log("key: " + key)
    // console.log(filterDateValues.findIndex(v => v.value === eventValue))
    //Check if there are any previous pending requests
    //console.log("Hanlde input change", checkCancelToken)
    if (typeof checkCancelToken != typeof undefined) {
      //console.log("Should cancel req")
      checkCancelToken.cancel("Operation canceled due to new request.");
      //setCancelToken(undefined)
    }

    //Save the cancel token for the current request
    checkCancelToken = 14//axios.CancelToken.source();
    //setCancelToken(axios.CancelToken.source())

   let values = [...filterValues];
   let index = values.findIndex(f => f._id === filter._id)

   if(index !== -1){
     values[index][key] = {
       type: filter.field.type,
       value: eventValue
     }
     filter[key] = {
       type: filter.field.type,
       value: eventValue
     }
     setFilterValues(values)

     const data = {
       viewId: activeView._id,
       action: "update",
       filter: filter
     }

     //If its a text field, check for invalid values, don't call api if invalid
     function IsInt(num){
       var result =  num % 1
       if(result != 0){
         return false
       } else {
         return true
       }
     }
     var numberError = filter && filter.value && filter.value.type === "number" ? isNaN(filter.value.value) ? true : !IsInt(filter.value.value) ? true : filter.value.value !== null && filter.value.value.includes(".") ? true : false : true
     if(numberError){
       //don't call api
       //console.log("invalid value, not calling api")
     } else {
       //console.log("starting filter req timer...", typeTimer)

       //DELAY CALLING API UNTIL STOPPED TYPING
       const WAIT_INTERVAL = 1000
       clearTimeout(typeTimer);
       setTypeTimer(setTimeout(callApi, WAIT_INTERVAL))

       function callApi(){
         //console.log('Called api')
         return axios
           .post("/views/filter", data)
           .then(res => {
             //console.log(res)
             globalActions.setActiveView(res.data.view)
             mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
             setFilterReq({
               isLoading: false,
               isError: false,
               error: null
             })
           })
           .catch(err => {
             setFilterReq({
               isLoading: false,
               isError: false,
               error: error
             })
             console.log("Could not update filter")
             console.log(err)
           });
       }

     }
   } else {
     setFilterReq({
       isLoading: false,
       isError: false,
       error: null
     })
   }
  }

  function handleChangeFilterCondition(event, filter) {
    // console.log(filter)
    //console.log(event.target.value)
    let values = [...filterValues];
    let index = values.findIndex(f => f._id === filter._id)

    if(index !== -1){
      values[index]["condition"] = event.target.value.value
    }
    setFilterValues(values)

     const data = {
       viewId: activeView._id,
       action: "changeCondition",
       condition: event.target.value.value
     }

     axios
       .post("/views/filter", data)
       .then(res => {
         //console.log(res)
         globalActions.setActiveView(res.data.view)
         mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
         setFilterReq({
           isLoading: false,
           isError: false,
           error: null
         })
       })
       .catch(err => {
         //console.log("Could not update workspace name")
         console.log(err)
       });
  }

  //EDITING DEFAULT VIEW
  var isEditDefault = !activeView || !views || views && views.length < 1 ? true : false


  const EditDefaultView = (event, filter, key) => {
    //console.log("EDIT DEFUALT VIEW")
    var changedFilter = {
      condition: 'and',
      field: {
        type: 'number',
        value: 'tabsCount'
      },
      operation: 'equals',
      value: {
        type: 'number',
        value: ''
      }
    }
    const viewObj = {
      name: "Grid View",
      viewType: "Grid",
      properites: {},
      sort: [],
      filter: {
        condition: 'and',
        filters: [changedFilter]
      }
    }
    //console.log({viewObj})
    props.EditDefaultView(viewObj) //handleClose
  }


  return (<>
      <PlainButton
          
          disableFocusRipple
          disableRipple
          onClick={openMenu}
          startIcon={<FilterListRoundedIcon
              fontSize="small"
              style={{width: 18, marginRight: -2,}}
              sx={{
                color: filterActive ? 'text.primary' : "button.plain",
              }}
                     />}
          style={{textOverflow: "ellipsis", overflow: "hidden"}}
      >
          <Typography
              noWrap
              sx={{
                color: filterActive ? 'text.primary' : "button.plain",
              }}
              variant="button"
          >
              {ButtonText}
          </Typography>
      </PlainButton>

      <DefaultMenu
          anchorEl={anchorEl}
          anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
          onClose={handleClose}
          open={open}
          style={{zIndex: 999, maxHeight: 500, minWidth: 500, marginTop: 5}}
          transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
      >
          <Box style={{ minWidth: 500, maxWidth: 675 }}>
              <div
                  style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 6,
          textOverflow: "ellipsis",
          overflow: "hidden",
          marginBottom: 6
        }}
              >
                  <Typography
                      color='textSecondary'
                      noWrap
                      style={{ width: 90, display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden"}}
                      variant="subtitle2"
                  >
                      Filters for
                      <Box
                          color="theme.primary"
                          style={{marginLeft: 4, display: 'flex', flexDirection: 'row', alignItems: 'center',}}
                      >
                          {
              activeView && activeView.viewType === "List" ?
                  <MenuRoundedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
              activeView && activeView.viewType === "Kanban" ?
                  <ViewKanbanOutlinedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
              activeView && activeView.viewType === "Full" ?
                  <ViewDayRoundedIcon style={{width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
              :
                  <GridViewRoundedIcon style={{color: 'theme.primary', width: 15, height: 15, margin: 0, marginRight: 3, marginLeft: 2}} />
            }
                      </Box>
                  </Typography>
                  <Typography
                      color='textSecondary'
                      noWrap
                      style={{maxWidth: 'calc(100% - 90px)', display: 'flex', flexDirection: 'row', alignItems: 'center', textOverflow: "ellipsis", overflow: "hidden"}}
                      variant="subtitle2"
                  >
                      <Box
                          color="theme.primary"
                          style={{textOverflow: "ellipsis", overflow: "hidden"}}
                      >
                          {activeView ? activeView.name : "Default view"}
                      </Box>
                  </Typography>
                  {
          helpIcon && helpIcon.url ?
              <IconButton
                  aria-label="delete"
                  href={helpIcon.url}
                  size="small"
                  target="_blank"
              >
                  <HelpOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
          :
          null
        }
              </div>
              <Divider style={{marginTop: 4, marginBottom: 6}} />
              {
        activeView && activeView.filter && activeView.filter.filters && activeView.filter.filters.length > 0 && activeView.filter.filters.every(f => f && !!f) ?
          activeView.filter.filters.map((f, index) => {
            var isFirstFilter = index === 0 ? true : false
            var isSecondFilter = index === 1 ? true : false

            return (
                <ListItem
                    key={index}
                    style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
                >
                    <div style={{width: 80, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginRight: 4}}>
                        {
                  isFirstFilter ?
                      <Typography
                          color='textSecondary'
                          noWrap
                          variant="subtitle2"
                      >
                          {' '}
                          Where
                          {' '}
                      </Typography>
                  :
                  isSecondFilter ?
                      <CustomSelect
                          handleChange={handleChangeFilterCondition}
                          item={f}
                          itemKey="condition"
                          options={filterConditions}
                          selected={f.condition}
                          smallSelect
                      />
                  :
                      <Typography
                          color='textSecondary'
                          noWrap
                          variant="subtitle2"
                      > 
                          {' '}
                          {f.condition}
                          {' '}
                      </Typography>
                }
                    </div>
                    <Grid
                        alignItems="center"
                        container
                        direction="row"
                        justifyContent="flex-start"
                        spacing={1}
                        style={{marginRight: 0, maxWidth: 'calc(100% - 120px)'}}
                        wrap="nowrap"
                    >
                        {
                  //CUSTOM FIELDS
                  f.field.type == "singleSelect" || f && f.field &&  f.field.type == "multiSelect" ?
                      <>

                          <Grid
                              item
                              style={{minWidth: 125}}
                              xs={f.operation !== 'empty' && f.operation !== 'notEmpty' ? 4 : 6}
                          >
                              <CustomFieldSelect
                                  handleChange={handleChangeCustomFieldFilters}
                                  item={f}
                                  itemKey="field"
                                  options={filterOnFields}
                                  selected={f.field.value}
                              />
                          </Grid>
                          <Grid
                              item
                              style={{minWidth: 125}}
                              xs={f.operation !== 'empty' && f.operation !== 'notEmpty' ? 3 : 6}
                          >
                              <CustomFieldSelect
                                  handleChange={handleChangeCustomFieldFilters}
                                  item={f}
                                  itemKey="operation"
                                  options={f.field.type === "singleSelect" ? filterSingleSelectOperations : filterMutliSelectOperations}
                                  selected={f.operation}
                              />
                          </Grid>
                          {
                      f.operation !== 'empty' && f.operation !== 'notEmpty' ?
                          <Grid
                              item
                              style={{minWidth: 150}}
                              xs={5}
                          >
                              <CustomFieldSelect
                                  handleChange={handleChangeCustomFieldFilters}
                                  isTag
                                  item={f}//{filterOnFields.find(property => property.value === f.field.value)!== undefined ? filterOnFields.find(property => property.value === f.field.value).possibleValues.find(value => value._id === f.value.value) !== undefined ? filterOnFields.find(property => property.value === f.field.value).possibleValues.find(value => value._id === f.value.value) : "" : ""}
                                  itemKey="value"
                                  options={filterOnFields.find(property => property.value === f.field.value)!== undefined ? filterOnFields.find(property => property.value === f.field.value).possibleValues : []}
                                  selected={f.value.value}
                              />
                          </Grid>
                      :
                       null
                    }

                      </>


                  :
                  //REGULAR FIXED FIELDS
                      <>
                          <Grid
                              item
                              style={{minWidth: 125,}}
                              xs={4}
                          >
                              <CustomSelect
                                  handleChange={handleChangeFilters}
                                  item={f}
                                  itemKey="field"
                                  options={filterOnFields}
                                  selected={f.field.value}
                              />
                          </Grid>
                          <Grid
                              item
                              style={{minWidth: 125,}}
                              xs={4}
                          >
                              <CustomSelect
                                  handleChange={handleChangeFilters}
                                  item={f}
                                  itemKey="operation"
                                  options={f.field.type === "date" ? filterDateOperations : f.field.type === "number" ? filterNumberOperations : filterStringOperations}
                                  selected={f.operation}
                              />
                          </Grid>
                          {
                        f.value.type === "dateString" ?
                            <Grid
                                item
                                style={{minWidth: 125,}}
                                xs={4}
                            >
                                <CustomSelect
                                    handleChange={handleChangeFilters}
                                    item={f}
                                    itemKey="value"
                                    options={f.value.type === "dateString" ? filterDateValues : f.value.type === "number" ? filterDateValues : filterDateValues}
                                    selected={f.value.value}
                                />
                            </Grid>
                        :
                        f.value.type === "date" ?
                            <>
                                <Grid
                                    item
                                    style={{minWidth: 125,}}
                                    xs={4}
                                >
                                    <CustomSelect
                                        handleChange={handleChangeFilters}
                                        item={f}
                                        itemKey="value"
                                        options={f.value.type === "dateString" ? filterDateValues : f.value.type === "number" ? filterDateValues : filterDateValues}
                                        selected="exactDate"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    style={{minWidth: 125,}}
                                    xs={4}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <CustomDateSelect
                                            handleChange={handleChangeFilters}
                                            item={f}
                                            itemKey="value"
                                            options={f.value.type === "dateString" ? filterDateValues : f.value.type === "number" ? filterDateValues : filterDateValues}
                                            selected={f.value.value}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </>
                        :
                            <Grid
                                item
                                style={{minWidth: 125,}}
                                xs={4}
                            >
                                <CustomTextField
                                    handleChange={handleInputChange}
                                    item={f}
                                    itemKey="value"
                                    value={f.value.value}
                                />
                            </Grid>
                      }
                      </>
                }

                    </Grid>
                    <Tooltip
                        disableInteractive
                        placement="top-end"
                        title="Remove filter"
                    >
                        <IconButton
                            aria-label="delete"
                            onClick={() => RemoveFilter(f._id)}
                            size="small"
                            style={{marginLeft: 4}}
                        >
                            <CloseRounded fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            );

          })
        :
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 12}}>
              <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
              >
                  {' '}
                  No filters have been applied.
              </Typography>
              <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
              >
                  {' '}
                  Use filters to only show collections you want to see.
              </Typography>
          </div>
      }

              <Divider style={{marginBottom: 4, marginTop: 4}} />
              <MenuItem
                  button
                  disabled={filterReq.isLoading}
                  onClick={(e) => isEditDefault ? EditDefaultView(e) : AddFilter(e)}
                  style={{color: '#3D5AFE'}}
              >
                  <ListItemIcon style={{color: '#3D5AFE'}}>
                      <Add />
                  </ListItemIcon>
                  Add a filter
                  {
          filterReq && filterReq.isLoading ?
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                  <CircularProgress
                      size={12}
                      style={{color: '#C4C4C4', marginRight: 6}}
                  />
                  <Typography
                      style={{color: '#C4C4C4'}}
                      variant='caption'
                  >
                      {' '}
                      saving
                      {' '}
                  </Typography>
              </div>
          :
          filterReq && filterReq.isError ?
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                  <Typography
                      style={{color: '#f44336'}}
                      variant='caption'
                  >
                      {' '}
                      Oops! There was an error updating filters. Please try again.
                      {' '}
                  </Typography>
              </div>
          :
          null
        }
              </MenuItem>
          </Box>
      </DefaultMenu>
          </>);
}

//<ViewModuleRoundedIcon style={{color: '#000', width: 14, marginRight: 2}}/>
