import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const FAQs = () => {
  const theme = useTheme();

  const faqs = [
    {
      question: "What is this Beta plan?",
      answer:
        "We're actively improving Partizion. The beta plan is our way of saying thank you to our users — those who subscribe to the beta pro plan now will lock in this price for life AND get unlimited access to all new features. That's our promise.",
    },
    {
      question: "What is a collection?",
      answer:
        "A collection is a set of tabs. You can save your whole window, select tabs, or any other configuration of tabs to a collection in Partizion and open it later.",
    },
    {
      question: "Why isn't Partizion free?",
      answer: `No. Partizion is paid. the tl;dr is this:
      “Free” tab managers are never actually free — You pay with your data or ads
      “Free” tab managers aren’t sustainable, they are not maintained and eventually die, along with all your tabs and sessions 
      We believe in paying for high quality software. We don’t like paying for subscriptions just like the next person, but if it means we get a high quality tab and session manager extension that we can trust and is actively maintained, we’re more than happy to pay for it.`,
    },
    {
      question: "Can I cancel my plan at anytime?",
      answer: `You can cancel at anytime. You can change your plan or view invoices at anytime. Your data is your data, if you want to stop using Partizion we will happily delete or export your data for you.`,
    },
    {
      question: "How can I trust Partizion",
      answer: `- We don't track you in any way
      - We use full encryption and the most modern security protocols
      - We don't know wich websites you visit
      - We don't sell or share your data
      - We don't show ads

      We're here to build a great product. That's why we ask you to support us with a subscription instead of selling your data or ads. Don't take our word for it, trust the 4 years in business and millions of tabs saved.
      `,
    },
  ];
  return (
    <div
      style={{
        marginTop: 48,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 48,
        paddingBottom: 48,
      }}
    >
      <div style={{ width: "100%", maxWidth: 800, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 24 }}>
        <Typography style={{ textAlign: "center", color: theme.palette.text.primary, fontSize: 32, fontWeight: 600, marginBottom: 24 }} variant="h3">
          FAQs
        </Typography>
        <div
          style={{
            marginBottom: 24,
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: 12,
          }}
        >
          {faqs.map((question, i) => (
            <div
              key={i}
              style={{
                marginBottom: 24,
                width: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                gap: 6,
                paddingBottom: 24,
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <Typography style={{ fontSize: 18, fontWeight: 600, color: theme.palette.text.primary }} variant="body1">
                {question.question}
              </Typography>
              <Typography style={{ fontSize: 18, color: theme.palette.text.secondary }} variant="body1">
                {question.answer.split("\n").map((item, j) => (
                  <p key={j}>{item}</p>
                ))}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
