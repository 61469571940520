import React, {useState, useEffect} from 'react';
import axios from 'axios'
import useGlobal from '../../GlobalState/Store/Store.js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';

import StringCut from '../../Utils/stringTooLong.js';

import useCollections from '../../Hooks/useCollections.js'


import { useNavigate, useLocation } from "react-router-dom";

import { mutate } from 'swr'

export default function DeleteCollectionDialog(props) {
  const [globalState, globalActions] = useGlobal()

  let location = useLocation();
  let navigate = useNavigate();

  const open = props.open
  const handleClose = props.handleClose
  const collection = props.collection
  const [req, setReq] = useState({
    loading: false,
    isError: false,
    error: null
  })

  //console.log(props)

  // useEffect(() => {
  //   console.log(props)
  // }, [props])

  function DeleteCollection(event){
    event.preventDefault()

    setReq({
      loading: true,
      isError: false,
      error: null
    })

    const data = {
      workspaceId: collection.workspace,
      collectionId: collection._id
    }

     // update the local data immediately, but disable the revalidation
   mutate([`/collections`, globalState.activeWorkspace._id,  globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null], async collectionsData => {
    // remove the collection
    if(collectionsData && collectionsData.collections){
      const filteredCollections = collectionsData.collections.filter(c => c._id !== collection._id)
      return {...collectionsData, collections: filteredCollections}
    } else {
      return collectionsData
    }
  }, false)

  axios.post('/collections/delete', data)
    .then(() => {
      setReq({
        loading: false,
        isError: false,
        error: null
      })
     mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
     mutate(`/workspaces`)
      //var createdCollection = res.data.collection
      //console.log(res)
      if(location && location.pathname && location.length == 1){
        //stay here
      } else {
        navigate(`/${collection.workspace}`)
      }
    })
    .catch((err) => {
     mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
      console.log(err)
    })

  //  mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null], async collectionsData => {
  //     //remove the coll
  //     // make the call to remove the collection
  //     await axios.post("/collections/delete", data)

  //     // filter the list, and return it with the updated item
  //     setReq({
  //       loading: false,
  //       isError: false,
  //       error: null
  //     })

  //     if(collectionsData && collectionsData.collections){
  //       const filteredCollections = collectionsData.collections.filter(c => c._id !== collection._id)
  //       return {...collectionsData, collections: filteredCollections}
  //     } else {
  //       return collectionsData
  //     }
  //   }, false)

   
    // function stopLoading(){
    //   setReq({
    //     loading: false,
    //     isError: false,
    //     error: null
    //   })
    // }
    //mutate([`/collections`, globalState.activeWorkspace._id, globalState.activeView && globalState.activeView._id ? globalState.activeView._id : null])
    if(open) handleClose()
  }

  return (
      <div>
          <Dialog
              aria-describedby="alert-dialog-description"
              aria-labelledby="alert-dialog-title"
              onClose={handleClose}
              open={open}
          >
              <DialogTitle
                  id="alert-dialog-title"
                  style={{marginBottom: '-15px'}}
              >
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'noWrap'}}>
                      <Typography variant="h5">
                          Delete collection:
                      </Typography>
                      <Typography
                          style={{textDecoration: "underline", textDecorationColor: '#3D5AFE', marginLeft: '8px'}}
                          variant="h5"
                      >
                          {props.collection ? " " + StringCut(props.collection.name, 25) : ""}
                      </Typography>
                  </div>
              </DialogTitle>
              <DialogContent>
                  {req.isError ?
                      <DialogContentText
                          color="secondary"
                          id="alert-dialog-description"
                      >
                          {req.error.message}
                      </DialogContentText>
            :
                      <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this collection?
                      </DialogContentText>
          }
              </DialogContent>
              <DialogActions>
                  <Button
                      color="secondary"
                      onClick={handleClose}
                  >
                      Cancel
                  </Button>
                  <Button
                      autoFocus
                      color="error"
                      disabled={req.loading}
                      onClick={DeleteCollection}
                      startIcon={<Delete />}
                      variant="contained"
                  >
                      Delete Collection
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
  );
}
