import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import useGlobal from "../../GlobalState/Store/Store.js";

import { Popover, Typography, MenuItem, ListItem, Divider, InputBase, ListSubheader } from "@mui/material";

import { DefaultMenu } from "./DefaultMenu.js";

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";
// import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
// import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import TabRoundedIcon from "@mui/icons-material/TabRounded";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import ConfirmDeletePropertyDialog from "../Dialogs/ConfirmDeleteProperty.js";

import { mutate } from "swr";

function PropertyMenu(props) {
  const theme = useTheme();
  const [globalState, globalActions] = useGlobal();
  const property = props.property;

  //console.log(property)

  const open = Boolean(props.anchorEl);
  const id = open ? "simple-popover" : undefined;

  const mount = props.mount ? props.mount : "bottom";

  //CHOOSE PROPERTY TYPE POPOVER
  const [propertyType, setPropertyType] = useState(props.property ? props.property.type : "singleSelect");
  const handleChangePropertyType = (type) => {
    setPropertyType(type);
    handleCloseChooseType();
  };
  const [typeAnchorEl, setTypeAnchorEl] = React.useState(null);
  const handleOpenChooseType = (event) => {
    //console.log("open choose property type")
    setTypeAnchorEl(event.currentTarget);
  };
  const handleCloseChooseType = () => {
    setTypeAnchorEl(null);
  };
  const chooseTypeOpen = Boolean(typeAnchorEl);

  function SelectTypePopover() {
    //console.log("mounted")
    return (
      <DefaultMenu
        anchorEl={typeAnchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        classes={{
          paper: {
            paddingTop: 8,
            paddingBottom: 8,
          },
        }}
        id="mouse-over-popover"
        onClose={handleCloseChooseType}
        open={chooseTypeOpen}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem
          button
          disabled={propertyType == "singleSelect"}
          onClick={() => handleChangePropertyType("singleSelect")}
          selected={propertyType == "singleSelect"}
        >
          <ArrowDropDownCircleRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              Single select{" "}
            </Typography>
          </div>
        </MenuItem>

        <MenuItem
          button
          disabled={propertyType == "multiSelect"}
          onClick={() => handleChangePropertyType("multiSelect")}
          selected={propertyType == "multiSelect"}
        >
          <ListRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0 }} variant="body2">
              {" "}
              Multi-select{" "}
            </Typography>
          </div>
        </MenuItem>
      </DefaultMenu>
    );
  }

  //INPUT
  //const inputRef = React.useRef(null)
  const selectInputText = (event) => {
    // console.log(event)
    // console.log(event.type)
    // console.log(event.detail)
    // console.log(event.eventPhase)
    if (property && event.target.value == property.name) event.target.select();
    // if(inputRef && inputRef.current){
    //   inputRef.current.select()
    // }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: 24,
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.text.light}`,
      fontSize: 14,
      lineHeight: 1.45,
      paddingBottom: 4,
      paddingTop: 4,
      paddingLeft: 6,
      paddingRight: 6,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: theme.palette.text.secondary,
      "&:focus": {
        border: `2px solid ${theme.palette.text.tertiary}`,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.collection.bgLight,
      },
    },
  }));

  function handleCloseMenu() {
    const curView = props.editView;
    if (curView) {
      // if(view !== curView.viewType || name !== curView.name){
      //   //console.log({view, name, curView})
      //   props.UpdateView(curView, view, name)
      // }
    }

    props.handleClose();
    handleCloseChooseType();
    if (name != null && name != "" && name != " ") {
      if (name !== property.name || propertyType != property.type) {
        //something was updated
        props.handleUpdateProperty(property, name, propertyType);
      }
    }
  }

  const [name, setName] = useState(property ? property.name : "");
  useEffect(() => {
    //console.log(property)
    if (props.property && props.property.name) {
      setName(props.property.name);
    }
    if (props.property && props.property.type) {
      setPropertyType(props.property.type);
    }
  }, [props.property]);

  const handleChangeName = (event) => {
    //console.log(event)
    setName(event.target.value);
  };

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const handleOpenConfirmDelete = () => {
    setConfirmDeleteOpen(true);
  };
  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const [deleteReqLoading, setDeleteReqLoading] = useState(false);

  const handleDeleteProperty = () => {
    setDeleteReqLoading(true);
    const data = {
      workspaceId: globalState.activeWorkspace._id,
      propertyId: property._id,
    };
    axios
      .post("/workspaces/collectionProperties/delete", data)
      .then((res) => {
        //console.log(res)
        // if(res.data && res.data.workspace){
        //   globalActions.saveActiveWorkspace(res.data.workspace)
        // }
        function StopLoading() {
          setDeleteReqLoading(false);
          handleCloseConfirmDelete();
          props.handleClose();
        }

        mutate("/workspaces");
        //GetWorkspacebyID(globalState.activeWorkspace, true, StopLoading)
      })
      .catch((err) => {
        //console.log("Could not update workspace name")
        console.log(err);
      });
  };

  const handleOpenConfigureOptions = () => {
    props.handleExternalTriggerOpenSelect(property);
    handleCloseMenu();
  };

  const hanldeKeyPress = (event) => {
    var charCode = event.which || event.charCode || event.keyCode;

    if (charCode == 13) {
      //if enter is pressed login
      handleCloseMenu();
    }
  };

  return (
    <>
      <DefaultMenu
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: mount == "side" ? "center" : mount == "top" ? "center" : "bottom",
          horizontal: mount == "side" ? "right" : mount == "top" ? "right" : "left",
        }}
        id={id}
        onClose={handleCloseMenu}
        open={open}
        style={{ marginTop: mount == "bottom" ? 5 : 0 }}
        transformOrigin={{
          vertical: mount == "side" ? "center" : mount == "top" ? "top" : "top",
          horizontal: mount == "side" ? "left" : mount == "top" ? "left" : "left",
        }}
      >
        <div onClick={handleCloseChooseType} onKeyDown={(e) => e.stopPropagation()} style={{ padding: 8 }}>
          <BootstrapInput
            autoComplete="none"
            autoFocus
            disabled={property && property.fixed}
            fullWidth
            id="outlined-basic"
            label="property name"
            onChange={handleChangeName}
            onFocus={selectInputText}
            onKeyDown={hanldeKeyPress}
            placeholder="Name property"
            size="small"
            value={name}
            variant="outlined"
          />
        </div>
        <Divider />

        {property && property.type && !property.fixed ? (
          <>
            <Typography
              sx={{ color: "text.tertiary", marginTop: 1, marginBottom: 0.5, fontWeight: 600, fontSize: 12, paddingLeft: 1.5, paddingRight: 1.5 }}
              variant="body2"
            >
              PROPERTY TYPE
            </Typography>
            <MenuItem
              button
              // onMouseEnter={handleOpenChooseType}
              // onMouseLeave={handleCloseChooseType}
              onClick={handleOpenChooseType}
              selected={chooseTypeOpen}
            >
              {propertyType == "date" ? (
                <AccessTimeRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
              ) : propertyType == "tabCount" ? (
                <TabRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
              ) : propertyType == "singleSelect" ? (
                <ArrowDropDownCircleRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
              ) : propertyType == "multiSelect" ? (
                <ListRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
              ) : null}
              <div style={{ marginLeft: 8 }}>
                <Typography style={{ marginBottom: 0 }} variant="body2">
                  {propertyType == "multiSelect" ? "Multi-select" : propertyType == "singleSelect" ? "Single select" : propertyType}{" "}
                </Typography>
              </div>
              <ArrowForwardIosRoundedIcon fontSize="small" style={{ width: 14, color: theme.palette.text.primary, position: "absolute", right: 6 }} />
            </MenuItem>
          </>
        ) : null}
        <SelectTypePopover />

        {props.handleExternalTriggerOpenSelect && props.handleExternalTriggerOpenSelect !== null && props.handleExternalTriggerOpenSelect !== undefined ? (
          <MenuItem button onClick={handleOpenConfigureOptions} onMouseEnter={handleCloseChooseType}>
            <SettingsRoundedIcon fontSize="small" style={{ width: 18, color: theme.palette.text.primary }} />
            <div style={{ marginLeft: 8 }}>
              <Typography style={{ marginBottom: 0 }} variant="body2">
                {" "}
                Configure options{" "}
              </Typography>
            </div>
          </MenuItem>
        ) : null}
        <Divider />

        <MenuItem
          button
          disabled={property && property.fixed}
          onClick={handleOpenConfirmDelete}
          onMouseEnter={handleCloseChooseType}
          style={{ marginTop: 6, marginBottom: 4 }}
        >
          <DeleteOutlineRounded fontSize="small" style={{ width: 18, color: "#f44336" }} />
          <div style={{ marginLeft: 8 }}>
            <Typography style={{ marginBottom: 0, color: "#f44336" }} variant="body2">
              {" "}
              Delete property
            </Typography>
          </div>
        </MenuItem>
      </DefaultMenu>
      {confirmDeleteOpen ? (
        <ConfirmDeletePropertyDialog
          confirm={handleDeleteProperty}
          handleClose={handleCloseConfirmDelete}
          isLoading={deleteReqLoading}
          open={confirmDeleteOpen}
          property={property}
          state={{ confirmText: "Delete property" }}
        />
      ) : null}
    </>
  );
}

export default React.memo(PropertyMenu);

//HIDING AND DUPLICATING
// <Divider />
// <ListItem button onClick={() => console.log(props.editView)} onMouseEnter={handleCloseChooseType}>
//   {
//     false ?
//     <VisibilityRoundedIcon fontSize="small" style={{width: 18, color: theme.palette.text.primary}}/>
//     :
//     <VisibilityOffRoundedIcon fontSize="small" style={{width: 18, color: theme.palette.text.primary}}/>
//   }
//   <div style={{marginLeft: 8}}>
//     <Typography variant="body2" style={{marginBottom: 0}}> {false ? "Un-hide property" : "Hide Property"} </Typography>
//   </div>
// </ListItem>
// <ListItem button onClick={() => console.log(props.editView)} onMouseEnter={handleCloseChooseType} disabled={property && property.fixed}>
//   <FileCopyOutlinedIcon fontSize="small" style={{width: 18, color: theme.palette.text.primary}}/>
//   <div style={{marginLeft: 8}}>
//     <Typography variant="body2" style={{marginBottom: 0}}> Duplicate property </Typography>
//   </div>
// </ListItem>

// <ListItem button onClick={handleOpenSelectMenu} onMouseEnter={handleCloseChooseType}>
//   <SettingsRoundedIcon fontSize="small" style={{width: 18, color: theme.palette.text.primary}}/>
//   <div style={{marginLeft: 8}}>
//     <Typography variant="body2" style={{marginBottom: 0}}> Configure options </Typography>
//   </div>
// </ListItem>
